import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { injectIntl, defineMessages } from 'react-intl';
import styled from 'styled-components';
import Modal from 'src/components/Modal';
import { HISTORY_TABS_KEYS } from 'src/utils/graphsUtils/graphConstants';
import ContinuousTabContent from '../containers/continuousContainer';
import SpotTabContent from '../containers/spotContainer';
import HriTabContent from '../containers/hriContainer';
import AppConfig from '../../../../config/AppConfig';

const StyledTabs = styled(Tabs)`
  min-height: 70vh;
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${props => props.theme.colors.activeTabs};
  }
  .ant-tabs-tab {
    font-size: 0.7rem;
    padding: 0.4rem 0.8rem !important;
  }
`;

const TabItem = styled.span`
  font-size: 0.7rem;
  padding: 0.4rem 0.8rem !important;
`;

const HistoryModal = ({
  intl,
  isModalVisible,
  setIsModalVisible,
  patientId,
  patientUniqueId,
  patientName,
}) => {
  const items = [
    {
      key: HISTORY_TABS_KEYS.HRI,
      label: <span>{intl.formatMessage(messages.hriTabTitle)}</span>,
      disabled: !AppConfig.HRI_ENABLED,
      children: (
        <TabItem>
          <HriTabContent patientId={patientUniqueId} />
        </TabItem>
      ),
    },
    {
      key: HISTORY_TABS_KEYS.SPOT,
      label: <span>{intl.formatMessage(messages.spotTabTitle)}</span>,
      children: (
        <TabItem>
          <SpotTabContent patientId={patientUniqueId} />
        </TabItem>
      ),
    },
    {
      key: HISTORY_TABS_KEYS.CONTINUOUS,
      label: <span>{intl.formatMessage(messages.continuousTabTitle)}</span>,
      children: (
        <TabItem>
          <ContinuousTabContent patientId={patientUniqueId} />
        </TabItem>
      ),
    },
  ];

  return (
    <Modal
      title={`${patientId}, ${patientName}`}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      centered
      withCloseButton
      footer={null}
      width="90%"
      destroyOnClose
    >
      <StyledTabs
        defaultActiveKey={HISTORY_TABS_KEYS.CONTINUOUS}
        type="card"
        centered
        items={items}
      />
    </Modal>
  );
};

const messages = defineMessages({
  spotTabTitle: {
    defaultMessage: 'Spot',
  },
  continuousTabTitle: {
    defaultMessage: 'Continuous',
  },
  hriTabTitle: {
    defaultMessage: 'HRI',
  },
});

HistoryModal.propTypes = {
  intl: PropTypes.object.isRequired,
  patientId: PropTypes.string,
  patientUniqueId: PropTypes.string,
  patientName: PropTypes.string,
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
};

HistoryModal.defaultProps = {
  patientId: '',
  patientUniqueId: '',
  patientName: '',
};
export default injectIntl(HistoryModal);
