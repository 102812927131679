import React from 'react';

import { SVGProps } from '../types';

const PatietnOutOfDeviceRange = ({
  width = '45',
  height = '21',
  color = '#FDEDE9',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect x="0.5" y="0.5" width="44" height="20" rx="5.5" fill={color} />
    <rect x="0.5" y="0.5" width="44" height="20" rx="5.5" stroke="#CC3311" />
    <path
      d="M7 9.98523H20M20 9.98523L17.0286 6.5M20 9.98523L17.0286 14.5"
      stroke="#252525"
      strokeWidth="1.5"
    />
    <circle cx="33.5" cy="10.5" r="8" fill={color} stroke="#CC3311" />
    <mask
      id="mask0_2033_64443"
      maskUnits="userSpaceOnUse"
      x="25"
      y="2"
      width="17"
      height="17"
    >
      <circle
        cx="33.4996"
        cy="10.5001"
        r="7.15"
        fill="#FAF3E2"
        stroke="#FFAD0D"
      />
    </mask>
    <g mask="url(#mask0_2033_64443)">
      <path
        d="M33.5693 6.75002H33.5593C32.7064 6.75002 31.9372 7.26388 31.6109 8.05159L33.5693 6.75002ZM33.5693 6.75002C34.1247 6.75266 34.6569 6.9747 35.0508 7.36761C35.4461 7.76382 35.6685 8.30015 35.6685 8.85914C35.6685 9.71194 35.1546 10.4812 34.3669 10.8075L33.5693 6.75002ZM32.0679 10.3505C31.4646 9.7473 31.2844 8.84078 31.6109 8.05171L34.3667 10.8076C33.5778 11.134 32.6713 10.9538 32.0679 10.3505Z"
        fill="#CC3311"
        stroke="#CC3311"
      />
      <path
        d="M39.8002 19.4251C39.8002 23.6926 36.9025 27.0001 33.5002 27.0001C30.0979 27.0001 27.2002 23.6926 27.2002 19.4251C27.2002 15.1576 30.0979 11.8501 33.5002 11.8501C36.9025 11.8501 39.8002 15.1576 39.8002 19.4251Z"
        fill="#CC3311"
        stroke="#CC3311"
      />
    </g>
  </svg>
);

export default PatietnOutOfDeviceRange;
