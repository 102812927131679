import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 1.25rem;
`;

export const Header = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const StyledCounter = styled.div`
  font-weight: 600;
  font-size: 0.65rem;
`;

export const CardsWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1rem;
`;
