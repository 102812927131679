import { MeasurementsState, SpotMeasurementsState } from './measurements';
import { DateUTC, SerialNumber, UUID } from './utility';

export type DeviceConnectionState = {
  deviceId: SerialNumber;
  connectionStatus: 'connected' | 'disconnected';
  timestamp: number;
  currentTime: DateUTC;
};

export type DeviceCredentials = {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  expiration: DateUTC;
};

export type RawDevice = {
  name: string;
  manufacturerId: SerialNumber;
  tenantId: UUID;
  certificateExists: boolean;
  connectionStatus: {
    connected: boolean;
    lastModifiedTimestamp: number;
    sessionId: UUID | null;
    ipAddress: string | null;
  };
  inf?: {
    deviceId: SerialNumber;
    json: string;
    idx: number;
    patientId: string;
    sessionId: string;
    hwVersion: string;
    fwVersion: string;
    versionId: string;
    applicationId: string;
    serialNumber: SerialNumber;
    state: string;
    wirelessHWVersion: string;
    wirelessVersionId: string;
    ssid: string;
    ipAddress: string;
    macAddress: string;
    id: UUID;
    tenantId: UUID;
    creationTime: DateUTC;
    lastModifiedTime: DateUTC;
  };
};

export type DeviceIdWithRoomAndBed = {
  deviceId: SerialNumber;
  roomName?: string;
  bedName?: string;
};

export type DeviceWithRoomAndBed = RawDevice & {
  roomName?: string;
  bedName?: string;
};

export enum DeviceMonitorStatus {
  INACTIVE = 'INACTIVE',
  STARTING = 'STARTING',
  ACTIVE = 'ACTIVE',
  STOPPING = 'STOPPING',
}

export type MonitoredDevice = {
  patientId: UUID | null;
  sessionId: UUID | null;
  time: {
    startTime: DateUTC;
    currentTime: DateUTC;
  } | null;
  status: DeviceMonitorStatus;
  continuousData: MeasurementsState;
  spotData: Record<UUID, SpotMeasurementsState>;
  hriData: Record<string, never>;
};

export type DeviceDisconnectionOverview = {
  manufacturerId: SerialNumber;
  lastConnectedAt: DateUTC | null;
};

export type TenantConnectionInfo = {
  connectedAssigned: number;
  connectedUnassigned: number;
  disconnectedAssigned: number;
  disconnectedUnassigned: number;
};
export type MTMDeviceConnectioninfo = Record<UUID, TenantConnectionInfo>;

export type TenantAssignedDevices = {
  sessionsByDeviceId: Record<SerialNumber, UUID>;
};
export type MTMAssignedDevices = Record<UUID, TenantAssignedDevices>;
