import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import { injectIntl, defineMessages } from 'react-intl';

const StopConfirmationModal = props => {
  const {
    isVisible,
    onChangeStopConfirmVisibleModal,
    selectedDevice,
    onClickStopContinuous,
    intl,
  } = props;
  return (
    <ConfirmationModal
      isModalVisible={isVisible}
      setIsModalVisible={() => {
        onChangeStopConfirmVisibleModal(false);
      }}
      onOk={() =>
        onClickStopContinuous({ deviceId: selectedDevice?.manufacturerId })
      }
      message={intl.formatMessage(messages.stopAlert)}
      title={intl.formatMessage(messages.stopAlertTitle)}
    />
  );
};
StopConfirmationModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  selectedDevice: PropTypes.object,
  intl: PropTypes.object.isRequired,
  onChangeStopConfirmVisibleModal: PropTypes.func.isRequired,
  onClickStopContinuous: PropTypes.func.isRequired,
};
StopConfirmationModal.defaultProps = {
  selectedDevice: null,
};

const messages = defineMessages({
  stopAlert: {
    defaultMessage:
      'In order to get HRI data the session must continue for at least 5 minutes. Are you sure you would like to stop?',
  },
  stopAlertTitle: {
    defaultMessage: 'Alert',
  },
});
export default injectIntl(StopConfirmationModal);
