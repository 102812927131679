import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import {
  getLocaleFromUrlPath,
  getLanguageByLocale,
  getLanguageMessagesByLanguage,
  DEFAULT_LANGUAGE,
} from '../../../utils/languageUtils';

export const LanguageProvider = props => {
  const { children } = props;
  const locale = getLocaleFromUrlPath(window.location.pathname);
  const language = getLanguageByLocale(locale);

  return (
    <IntlProvider
      key={language}
      locale={language}
      defaultLocale={DEFAULT_LANGUAGE}
      messages={getLanguageMessagesByLanguage(language)}
    >
      {React.Children.only(children)}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LanguageProvider;
