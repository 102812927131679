import React from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';
import DisconnectionAlertsSettingsForm from 'src/components/AlertSettingsComponents/DisconnectionAlertsSettingsForm';
import Connector, { Props } from './Connector';
import { StyledWrapper, TechnicalAlertSettingsTitle } from './styled';
import { TenantSubscriptionDetails } from 'src/routes/GMDashboard/modules/types';

const ConnectivityAlertsTab = ({
  subscriptionDetails,
  setSubscriptionDetails,
  setErrors,
}: Props & {
  intl: IntlShape;
  subscriptionDetails: TenantSubscriptionDetails;
  setSubscriptionDetails: React.Dispatch<
    React.SetStateAction<TenantSubscriptionDetails>
  >;
}): JSX.Element => {
  return (
    <StyledWrapper>
      <TechnicalAlertSettingsTitle>
        <FormattedMessage {...messages.technicalAlerts} />
      </TechnicalAlertSettingsTitle>
      <DisconnectionAlertsSettingsForm
        technicalSubscriptionDetails={subscriptionDetails.TECHNICAL}
        handleSubscriptionDetails={technicalSubscriptionDetails => {
          setSubscriptionDetails(prevState => ({
            ...prevState,
            TECHNICAL: technicalSubscriptionDetails,
          }));
        }}
        setHasErrors={isError =>
          setErrors(prevState => ({
            ...prevState,
            technicalAlerts: isError,
          }))
        }
      />
    </StyledWrapper>
  );
};

const messages = defineMessages({
  medicalAlerts: {
    defaultMessage: 'Medical Alerts',
  },
  technicalAlerts: {
    defaultMessage: 'Technical Alerts (Disconnected Devices)',
  },
  withPatientDetails: {
    defaultMessage: `With patient's personal details`,
  },
  notificationInfo: {
    defaultMessage: 'Send Me Alert Notification by:',
  },
  email: {
    defaultMessage: 'Email',
  },
  sms: {
    defaultMessage: 'SMS',
  },
});

export default Connector(injectIntl(ConnectivityAlertsTab));
