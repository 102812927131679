import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import { MainTitle, PageWrapper } from 'src/components/styled';
import { hasAnyPermissions } from 'src/utils/permissions';
import permissions from 'src/permissions';
import Table from 'src/components/CrudCommonComponents/Table';
import {
  USERS_TABLE_ACTIONS_KEYS,
  userFormModes,
  getUserPageColumns,
  usersActionKeysArray,
  USER_STATUS,
  systemAdmin,
} from '../../../components/UserCrudComponents/constants';
import UserModal from '../../../components/UserCrudComponents/UserModal';
import { USER_TYPES } from 'src/utils/constants';

const UsersPage = ({
  fetchAllUsers,
  usersList,
  getTenantsList,
  isLoading,
  inviteUser,
  updateUser,
  deleteUser,
  intl,
  modalStatus,
  setModalStatus,
  sendMessageForResetPassword,
  resendInvitation,
  deleteInvitation,
  loggedInUserType,
  loggedInUserId,
}) => {
  const [activeRow, setActiveRow] = useState({});
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState(false);
  const [tableData, setTableData] = useState([]);
  const columns = getUserPageColumns(intl);
  const userType = loggedInUserType?.name;
  const loggedInUserIsTA = userType === USER_TYPES.TENANT_ADMIN;
  const activeRowIsTA = activeRow?.userType?.name === USER_TYPES.TENANT_ADMIN;
  const activeRowIsCurrentUser = activeRow?.id === loggedInUserId;

  const filterdActionsByStatusUserMap = {
    [USER_STATUS.ACTIVE]: usersActionKeysArray.filter(
      action => action !== USERS_TABLE_ACTIONS_KEYS.RESEND_INVITE,
    ),
    [USER_STATUS.PENDING]: usersActionKeysArray.filter(
      action => action !== USERS_TABLE_ACTIONS_KEYS.RESET_PASSWORD,
    ),
  };

  useEffect(() => {
    if (hasAnyPermissions(permissions.VIEW_DEVICES_TENANT_COLUMN)) {
      getTenantsList();
    }
    fetchAllUsers();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setTableData(
        usersList.map(user => ({
          key: user.id,
          actions: filterdActionsByStatusUserMap[user.status],
          ...user,
        })),
      );
    }
  }, [usersList, isLoading]);

  const actions = [
    {
      key: USERS_TABLE_ACTIONS_KEYS.EDIT,
      name: intl.formatMessage(messages.edit),
      onClick: (_, { record }) => {
        setActiveRow(record);
        setIsEditModalVisible(true);
      },
    },
    {
      key: USERS_TABLE_ACTIONS_KEYS.DELETE,
      name: intl.formatMessage(messages.delete),
      onClick: (_, { record }) => {
        setActiveRow(record);
        setIsDeleteConfirmationVisible(true);
      },
      isDisabled: ({ record }) => record.id === loggedInUserId,
    },
    {
      key: USERS_TABLE_ACTIONS_KEYS.RESET_PASSWORD,
      name: intl.formatMessage(messages.resetPassword),
      onClick: (_, { record }) => {
        setActiveRow(record);
        sendMessageForResetPassword({ email: record?.email });
      },
    },
    {
      key: USERS_TABLE_ACTIONS_KEYS.RESEND_INVITE,
      name: intl.formatMessage(messages.resendInvite),
      onClick: (_, { record }) => {
        setActiveRow(record);
        resendInvitation(record);
      },
    },
  ];
  const COLUMNS_PERMISSION_MAP = {
    [permissions.VIEW_DEVICES_TENANT_COLUMN]: [
      {
        title: intl.formatMessage(messages.tenant),
        dataIndex: 'tenantName',
        key: 'tenantName',
        sorter: (a, b) => a.tenantName?.localeCompare(b.tenantName),
      },
    ],
  };
  const columnsMap = {
    [systemAdmin]: columns,
  };
  const columnsMapHandler = {
    get(columnsMapCreator, userTypeProperty) {
      return columnsMapCreator[userTypeProperty] || columns;
    },
  };

  const proxyColumnsMap = new Proxy(columnsMap, columnsMapHandler);

  const cols = proxyColumnsMap[userType];
  Object.keys(COLUMNS_PERMISSION_MAP).forEach(permission => {
    const hasPermission = hasAnyPermissions(permission);
    if (hasPermission) {
      cols.splice(2, 0, ...COLUMNS_PERMISSION_MAP[permission]);
    }
  });

  return (
    <PageWrapper>
      <MainTitle>
        <FormattedMessage {...messages.users} />
      </MainTitle>
      <Table
        loading={isLoading}
        data={tableData}
        columns={columns}
        withSearch
        searchPlaceholder={intl.formatMessage(messages.searchPlaceholder)}
        addButtonText={intl.formatMessage(messages.invite)}
        addButtonPermission={permissions.ORGANIZATION_INVITATIONS_CREATE}
        addButtonOnClick={() => setIsInviteModalVisible(true)}
        actions={actions}
      />
      <UserModal
        isModalVisible={isInviteModalVisible}
        setIsModalVisible={value => setIsInviteModalVisible(value)}
        onSubmit={invitedUserData => {
          setIsInviteModalVisible(false);
          inviteUser(invitedUserData);
        }}
        mode={userFormModes.INVITE}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        fields={{
          firstName: true,
          lastName: true,
          email: true,
          userType: true,
        }}
      />
      <ConfirmationModal
        isModalVisible={isDeleteConfirmationVisible}
        setIsModalVisible={value => setIsDeleteConfirmationVisible(value)}
        onOk={() => {
          activeRow.status === USER_STATUS.ACTIVE
            ? deleteUser(activeRow.id)
            : deleteInvitation(activeRow.id);
        }}
        message={intl.formatMessage(messages.deleteConfirmation)}
      />
      <UserModal
        isModalVisible={isEditModalVisible}
        setIsModalVisible={value => setIsEditModalVisible(value)}
        onSubmit={editedUserData => {
          setIsEditModalVisible(false);
          updateUser({ userId: activeRow.id, data: editedUserData });
        }}
        currentUser={activeRow}
        mode={userFormModes.EDIT}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        fields={{
          firstName: true,
          lastName: true,
          email: true,
          userType: true,
          phone: {
            disabled:
              !!loggedInUserIsTA && activeRowIsTA && !activeRowIsCurrentUser,
          },
          mfaPhone: true,
        }}
      />
    </PageWrapper>
  );
};
const messages = defineMessages({
  users: {
    defaultMessage: 'Users',
  },
  searchPlaceholder: {
    defaultMessage: 'Search user by name or email',
  },
  invite: {
    defaultMessage: 'Invite',
  },
  edit: {
    defaultMessage: 'Edit',
  },
  delete: {
    defaultMessage: 'Delete',
  },
  deleteConfirmation: {
    defaultMessage: 'Are you sure you want to delete?',
  },
  resetPassword: {
    defaultMessage: 'Reset Password',
  },
  resendInvite: {
    defaultMessage: 'Resend invite',
  },
  tenant: {
    defaultMessage: 'Tenant',
  },
});

UsersPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  usersList: PropTypes.array.isRequired,
  fetchAllUsers: PropTypes.func.isRequired,
  inviteUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  sendMessageForResetPassword: PropTypes.func.isRequired,
  resendInvitation: PropTypes.func.isRequired,
  deleteInvitation: PropTypes.func.isRequired,
  getTenantsList: PropTypes.func.isRequired,
  loggedInUserType: PropTypes.object.isRequired,
  loggedInUserId: PropTypes.string,
};

export default injectIntl(UsersPage);
