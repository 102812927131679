import { CacheAxiosResponse } from 'axios-cache-interceptor/src/cache/axios';
import { CacheRequestConfig } from 'axios-cache-interceptor';
import { Method } from 'axios/index';

const SLASHES_REGEX = /^\/|\/$/g;

export const getHeaderInterpreter = (
  headers?: CacheAxiosResponse['headers'],
) => {
  if (headers && headers['x-my-custom-header']) {
    const seconds = Number(headers['x-my-custom-header']);
    if (seconds < 1) {
      return 'dont cache';
    }
    return seconds;
  }

  return { cache: 10000, stale: 10000 };
  // return 'not enough headers';
};

export const getBuildGeneratorKey = ({
  baseURL = '',
  url = '',
  method = 'get',
  params,
  data,
  headers,
}: CacheRequestConfig) => {
  // Remove trailing slashes to avoid generating different keys for the "same" final url.
  const formattedBaseUrl = baseURL && baseURL.replace(SLASHES_REGEX, '');
  const formattedUrl = url && url.replace(SLASHES_REGEX, '');
  const formattedMethod = method && (method.toLowerCase() as Method);

  return {
    url:
      formattedBaseUrl +
      (formattedBaseUrl && formattedUrl ? '/' : '') +
      formattedUrl,
    method: formattedMethod,
    params: params as unknown,
    // add authorization in key in order to detect another request for different sub-tenant
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    authorization: headers?.Authorization,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    data,
  };
};
