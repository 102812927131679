import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { SerialNumber } from 'src/types/utility';
import { FormBed, FormRoom } from './types';
import { Label, StyledInfo } from './styled';

type Props = {
  deviceId: SerialNumber;
  selectedRoom?: FormRoom;
  selectedBed?: FormBed;
};

const LocationForm = ({
  deviceId,
  selectedRoom,
  selectedBed,
}: Props): JSX.Element => {
  return (
    <>
      <Label>
        <FormattedMessage {...messages.manufacturerId} />
      </Label>
      <StyledInfo>{deviceId ?? '-'}</StyledInfo>
      <Label>
        <FormattedMessage {...messages.roomLabel} />
      </Label>
      <StyledInfo> {selectedRoom?.name ?? '-'} </StyledInfo>
      <Label>
        <FormattedMessage {...messages.bedLabel} />
      </Label>
      <StyledInfo> {selectedBed?.name ?? '-'} </StyledInfo>
    </>
  );
};

const messages = defineMessages({
  manufacturerId: {
    defaultMessage: 'Manufacturer Id',
  },
  roomLabel: {
    defaultMessage: 'Room',
  },
  bedLabel: {
    defaultMessage: 'Bed',
  },
});

export default LocationForm;
