import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  TenantStatusFilterOption,
  DISPLAY_STATUS_FILTERS,
} from 'src/routes/GMDashboard/modules/filterUtils';
import Connector, { PropsFromRedux } from './Connector';
import {
  StyledWrapper,
  StyledTitle,
  OptionsWrapper,
  StyledOption,
  StyledCheckbox,
  StyledFooter,
  StyledResetBtn,
} from './styled';

type Props = PropsFromRedux;

const StatusFilterSelector = ({
  appliedFilters,
  applyFilter,
  removeFilter,
  resetFilters,
}: Props): JSX.Element => {
  const handleOnCheck = (value: TenantStatusFilterOption) =>
    appliedFilters.includes(value)
      ? removeFilter({ filter: 'status', value })
      : applyFilter({ filter: 'status', value });

  return (
    <StyledWrapper>
      <StyledTitle>
        <FormattedMessage {...messages.title} />
      </StyledTitle>
      <OptionsWrapper>
        {DISPLAY_STATUS_FILTERS.sort(
          (a, b) => a.displayOrder - b.displayOrder,
        ).map(({ value, label }) => (
          <StyledOption key={value}>
            <StyledCheckbox
              type="checkbox"
              checked={appliedFilters.includes(value)}
              onChange={() => handleOnCheck(value)}
            />
            <FormattedMessage {...label} />
          </StyledOption>
        ))}
      </OptionsWrapper>
      <StyledFooter>
        <StyledResetBtn onClick={() => resetFilters('status')}>
          <FormattedMessage {...messages.reset} />
        </StyledResetBtn>
      </StyledFooter>
    </StyledWrapper>
  );
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Select tenant filters:',
  },
  reset: {
    defaultMessage: 'Reset',
  },
});

export default Connector(StatusFilterSelector);
