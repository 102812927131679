import React from 'react';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import styled from 'styled-components';
import { RefSelectProps } from 'antd/lib/select';

export type MultiSelectItem = {
  label: string;
  value: string;
};

const StyledMultiSelect: typeof Select = styled(Select)`
  .ant-select-selection-placeholder {
    font-size: 0.75rem;
  }

  .ant-select-selection-item {
    font-size: 0.75rem;
  }
`;

type Props<T extends MultiSelectItem> = SelectProps<T['value'][], T> & {
  selectedItems: T['value'][];
  options: T[];
  handleChange: (newValues: T['value'][]) => void;
  customOptionRender?: (item: T) => JSX.Element;
  selectRef?: React.Ref<RefSelectProps> | undefined;
};

const MultiSelect = <T extends MultiSelectItem>({
  selectedItems,
  options,
  handleChange,
  placeholder,
  customOptionRender,
  selectRef,
  ...rest
}: Props<T>): JSX.Element => {
  const selectProps: SelectProps<T['value'][], T> = {
    mode: 'multiple',
    style: { width: '100%' },
    value: selectedItems,
    onChange: handleChange,
    placeholder,
    maxTagCount: 'responsive',
    filterOption: (input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
    ...rest,
    ...(customOptionRender ? { optionLabelProp: 'label' } : { options }),
  };

  if (customOptionRender) {
    return (
      <div>
        <StyledMultiSelect ref={selectRef} {...selectProps}>
          {options.map(option => customOptionRender(option))}
        </StyledMultiSelect>
      </div>
    );
  }

  return (
    <div>
      <StyledMultiSelect ref={selectRef} {...selectProps} />
    </div>
  );
};

export default MultiSelect;
