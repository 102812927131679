import React, { useEffect, useRef } from 'react';

import {
  AlertSettingsBoldText,
  CheckboxesWrapper,
  FieldsOnSameRow,
  StyledQuestionTooltip,
  StyledTimePicker,
} from './styled';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Divider, Form, Input, Select } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import InputWithIcon from 'src/components/general-ui/InputWithIcon';
import editIcon from 'src/components/AlertSettingsComponents/AlertThresholdsForm/resources/edit-icon.svg';
import { deepEqual } from 'src/utils/comparators';
import { ActivityAlertFields } from './constants';
import {
  AlertSettingsSubtitle,
  CheckboxStyledLabel,
} from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/styled';
import MultiSelect from 'src/components/general-ui/MultiSelect';
import { SubtenantItem } from 'src/routes/GMDashboard/modules/types';
import {
  StyledOptionLabel,
  StyledOptionLabelTag,
} from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/CombinedSettings/styled';
import {
  typeToColor,
  typeToMessage,
} from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/CombinedSettings/constants';
import CheckboxForm from 'src/components/general-ui/CheckboxForm';
import {
  ActivityAlertComponentProps,
  SelectedLevelAlertSettings,
} from 'src/components/AlertSettingsComponents/ActivityAlerts/types';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Checkbox from 'src/components/general-ui/Checkbox';
import AccessControl from 'src/components/AccessControl';
import {
  continuousHoursValidator,
  datepickerFromValidator,
  datepickerToValidator,
  maxDurationValidator,
  minDurationValidator,
  minRepetitionValidator,
} from 'src/components/AlertSettingsComponents/ActivityAlerts/validators';

const ActivityAlertsForm = ({
  intl,
  activityAlertSettings,
  subtenantOptions,
  subscriptionDetails,
  setSelectedSubtenants,
  areSelectedTenantsSettingsEquals = { equals: true },
  setActivityAlertSettings,
  setSubscriptionDetails,
  displaySubtenantSelector = false,
  hasSubscriptions = true,
  setIsFormValid,
  editablePermissions = [],
  isFormEnabled = true,
}: ActivityAlertComponentProps): JSX.Element => {
  const ref = useRef<RefSelectProps>(null);
  const [form] = Form.useForm();
  const { Option } = Select;
  const { equals: equalSelectedTenantsSettings } =
    areSelectedTenantsSettingsEquals;

  const customOptionRender = ({ value, label, type }: SubtenantItem) => (
    <Option value={value} label={label} key={value}>
      <StyledOptionLabel>{label}</StyledOptionLabel>
      <StyledOptionLabelTag color={typeToColor[type]}>
        <FormattedMessage {...typeToMessage[type]} />
      </StyledOptionLabelTag>
    </Option>
  );
  Form.useWatch('tenantIds', form);

  useEffect(() => {
    if (!activityAlertSettings || !form) {
      return;
    }
    form.setFieldsValue(activityAlertSettings);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    form.validateFields([
      ActivityAlertFields.CONTINUOUS_OUT_OF_BED_MINUTES,
      ActivityAlertFields.REPEATING_STEP_OUT_MIN_REPETITION,
      ActivityAlertFields.REPEATING_STEP_OUT_MIN_DURATION,
      ActivityAlertFields.REPEATING_STEP_OUT_MAX_DURATION,
      ActivityAlertFields.SHORTLY_FROM,
      ActivityAlertFields.SHORTLY_TO,
    ]);
  }, [form, activityAlertSettings]);

  useEffect(() => {
    if (areSelectedTenantsSettingsEquals?.equals) {
      return;
    }
    form.resetFields([
      ActivityAlertFields.SHORTLY_FROM,
      ActivityAlertFields.SHORTLY_TO,
    ]);
    form.setFieldValue(
      [...ActivityAlertFields.CONTINUOUS_OUT_OF_BED_MINUTES],
      0,
    );
    form.setFieldValue(
      [...ActivityAlertFields.REPEATING_STEP_OUT_MIN_REPETITION],
      0,
    );
    form.setFieldValue(
      [...ActivityAlertFields.REPEATING_STEP_OUT_MIN_DURATION],
      0,
    );
    form.setFieldValue(
      [...ActivityAlertFields.REPEATING_STEP_OUT_MAX_DURATION],
      0,
    );
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    form.validateFields([
      ActivityAlertFields.CONTINUOUS_OUT_OF_BED_MINUTES,
      ActivityAlertFields.REPEATING_STEP_OUT_MIN_REPETITION,
      ActivityAlertFields.REPEATING_STEP_OUT_MIN_DURATION,
      ActivityAlertFields.REPEATING_STEP_OUT_MAX_DURATION,
      ActivityAlertFields.SHORTLY_FROM,
      ActivityAlertFields.SHORTLY_TO,
    ]);
  }, [areSelectedTenantsSettingsEquals, form]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(
          _changedValues,
          allValues: SelectedLevelAlertSettings,
        ) => {
          setActivityAlertSettings(allValues);
        }}
        scrollToFirstError
      >
        {(_values, formInstance) => {
          setIsFormValid?.(
            !formInstance
              .getFieldsError()
              .some(({ errors }) => !!errors.length),
          );
          return (
            <CheckboxesWrapper>
              {displaySubtenantSelector && (
                <MultiSelect
                  selectRef={ref}
                  selectedItems={(
                    form.getFieldValue('tenantIds') as SubtenantItem[]
                  )?.map(s => s.value)}
                  options={subtenantOptions}
                  handleChange={ids => {
                    setSelectedSubtenants(
                      subtenantOptions.filter(s => ids.includes(s.value)),
                    );
                  }}
                  placeholder={intl.formatMessage(
                    messages.multiselectPlaceholder,
                  )}
                  customOptionRender={customOptionRender}
                />
              )}
              <Form.Item hidden name={'tenantIds'}>
                <Input type="hidden" />
              </Form.Item>
              <AccessControl permissions={[...editablePermissions]}>
                <AlertSettingsSubtitle style={{ fontWeight: 'normal' }}>
                  <FormattedMessage {...messages.notifyWhenLabel} />
                </AlertSettingsSubtitle>
                <AlertSettingsBoldText>
                  <FormattedMessage {...messages.outOfBedAlertTitle} />
                </AlertSettingsBoldText>
                <FieldsOnSameRow>
                  <Form.Item
                    noStyle
                    name={[...ActivityAlertFields.SHORTLY_NOTIFICATION]}
                    valuePropName="checked"
                  >
                    {/* @ts-ignore Form.Item component will take care of passing props in from */}
                    <CheckboxForm
                      checked={
                        !!form.getFieldValue(
                          ActivityAlertFields.SHORTLY_NOTIFICATION,
                        )
                      }
                      label={intl.formatMessage(messages.shortlyAfter)}
                      disabled={!isFormEnabled}
                    />
                  </Form.Item>
                  <StyledQuestionTooltip
                    title={
                      <FormattedMessage {...messages.shortlyAfterTooltip} />
                    }
                  >
                    <QuestionCircleOutlined />
                  </StyledQuestionTooltip>
                </FieldsOnSameRow>
                <Divider style={{ margin: '8px 0' }} />
                <FormattedMessage {...messages.selectTime} />
                <FieldsOnSameRow>
                  <FormattedMessage {...messages.from} />
                  <StyledQuestionTooltip
                    title={
                      !equalSelectedTenantsSettings ? (
                        <FormattedMessage {...messages.settingsAreNotEqual} />
                      ) : undefined
                    }
                  >
                    <Form.Item
                      name={ActivityAlertFields.SHORTLY_FROM}
                      rules={datepickerFromValidator(
                        equalSelectedTenantsSettings,
                      )}
                    >
                      <StyledTimePicker
                        use12Hours
                        format="hh A"
                        showNow={false}
                        size={'small'}
                        disabled={!isFormEnabled}
                      />
                    </Form.Item>
                  </StyledQuestionTooltip>
                  <FormattedMessage {...messages.to} />
                  <StyledQuestionTooltip
                    title={
                      !equalSelectedTenantsSettings ? (
                        <FormattedMessage {...messages.settingsAreNotEqual} />
                      ) : undefined
                    }
                  >
                    <Form.Item
                      name={[...ActivityAlertFields.SHORTLY_TO]}
                      rules={datepickerToValidator(
                        equalSelectedTenantsSettings,
                      )}
                    >
                      <StyledTimePicker
                        use12Hours
                        format="hh A"
                        showNow={false}
                        disabled={!isFormEnabled}
                      />
                    </Form.Item>
                  </StyledQuestionTooltip>
                  <StyledQuestionTooltip
                    title={
                      <FormattedMessage {...messages.timeIntervalTooltip} />
                    }
                  >
                    <QuestionCircleOutlined />
                  </StyledQuestionTooltip>
                </FieldsOnSameRow>
                <AlertSettingsBoldText>
                  <FormattedMessage {...messages.exitNoReturn} />
                </AlertSettingsBoldText>
                <FieldsOnSameRow>
                  <Form.Item
                    noStyle
                    name={[
                      ...ActivityAlertFields.CONTINUOUS_OUT_OF_BED_ENABLED,
                    ]}
                    valuePropName="checked"
                  >
                    {/* @ts-ignore Form.Item component will take care of passing props in from */}
                    <CheckboxForm
                      label={intl.formatMessage(
                        messages.continuousCheckboxLabel,
                      )}
                      checked={
                        !!form.getFieldValue(
                          ActivityAlertFields.CONTINUOUS_OUT_OF_BED_ENABLED,
                        )
                      }
                      disabled={!isFormEnabled}
                    />
                  </Form.Item>
                  <StyledQuestionTooltip
                    title={
                      !equalSelectedTenantsSettings ? (
                        <FormattedMessage {...messages.settingsAreNotEqual} />
                      ) : undefined
                    }
                  >
                    <Form.Item
                      dependencies={[
                        ActivityAlertFields.CONTINUOUS_OUT_OF_BED_ENABLED,
                        ActivityAlertFields.SHORTLY_TO,
                        ActivityAlertFields.SHORTLY_FROM,
                      ]}
                      name={[
                        ...ActivityAlertFields.CONTINUOUS_OUT_OF_BED_MINUTES,
                      ]}
                      rules={continuousHoursValidator(
                        equalSelectedTenantsSettings,
                      )}
                    >
                      <InputWithIcon
                        width={'60px'}
                        iconSrc={editIcon}
                        type="number"
                        min={0}
                        max={66}
                        disabled={
                          !form.getFieldValue(
                            ActivityAlertFields.CONTINUOUS_OUT_OF_BED_ENABLED,
                          ) || !isFormEnabled
                        }
                      />
                    </Form.Item>
                  </StyledQuestionTooltip>
                  <CheckboxStyledLabel>
                    <FormattedMessage {...messages.hoursLabel} />
                  </CheckboxStyledLabel>
                </FieldsOnSameRow>
                <AlertSettingsBoldText>
                  <FormattedMessage {...messages.nightDisruption} />
                </AlertSettingsBoldText>
                <FieldsOnSameRow>
                  <Form.Item
                    noStyle
                    name={[...ActivityAlertFields.REPEATING_STEP_OUT_ENABLED]}
                    valuePropName="checked"
                  >
                    {/* @ts-ignore Form.Item component will take care of passing props in from */}
                    <CheckboxForm
                      label={intl.formatMessage(messages.repeatingStepOutLabel)}
                      checked={
                        !!form.getFieldValue(
                          ActivityAlertFields.REPEATING_STEP_OUT_ENABLED,
                        )
                      }
                      disabled={!isFormEnabled}
                    />
                  </Form.Item>

                  <StyledQuestionTooltip
                    title={
                      !equalSelectedTenantsSettings ? (
                        <FormattedMessage {...messages.settingsAreNotEqual} />
                      ) : undefined
                    }
                  >
                    <Form.Item
                      name={[
                        ...ActivityAlertFields.REPEATING_STEP_OUT_MIN_REPETITION,
                      ]}
                      dependencies={[
                        ActivityAlertFields.REPEATING_STEP_OUT_ENABLED,
                      ]}
                      rules={minRepetitionValidator(
                        equalSelectedTenantsSettings,
                      )}
                    >
                      <InputWithIcon
                        disabled={
                          !form.getFieldValue(
                            ActivityAlertFields.REPEATING_STEP_OUT_ENABLED,
                          ) || !isFormEnabled
                        }
                        type="number"
                        iconSrc={editIcon}
                        width={'60px'}
                      />
                    </Form.Item>
                  </StyledQuestionTooltip>
                  <CheckboxStyledLabel>
                    <FormattedMessage {...messages.repeatingDuration} />
                  </CheckboxStyledLabel>
                  <StyledQuestionTooltip
                    title={
                      !equalSelectedTenantsSettings ? (
                        <FormattedMessage {...messages.settingsAreNotEqual} />
                      ) : undefined
                    }
                  >
                    <Form.Item
                      name={[
                        ...ActivityAlertFields.REPEATING_STEP_OUT_MIN_DURATION,
                      ]}
                      dependencies={[
                        ActivityAlertFields.REPEATING_STEP_OUT_ENABLED,
                        ActivityAlertFields.REPEATING_STEP_OUT_MAX_DURATION,
                      ]}
                      rules={minDurationValidator(equalSelectedTenantsSettings)}
                    >
                      <InputWithIcon
                        disabled={
                          !form.getFieldValue(
                            ActivityAlertFields.REPEATING_STEP_OUT_ENABLED,
                          ) || !isFormEnabled
                        }
                        type="number"
                        iconSrc={editIcon}
                        width={'60px'}
                      />
                    </Form.Item>
                  </StyledQuestionTooltip>
                  <CheckboxStyledLabel>
                    <FormattedMessage {...messages.minutesLabel} />
                  </CheckboxStyledLabel>
                  <StyledQuestionTooltip
                    title={
                      <FormattedMessage
                        {...messages.repeatingDurationTooltip}
                      />
                    }
                  >
                    <QuestionCircleOutlined />
                  </StyledQuestionTooltip>
                </FieldsOnSameRow>
                <FieldsOnSameRow>
                  <CheckboxStyledLabel>
                    <FormattedMessage {...messages.repeatingMaxDuration} />
                  </CheckboxStyledLabel>
                  <StyledQuestionTooltip
                    title={
                      !equalSelectedTenantsSettings ? (
                        <FormattedMessage {...messages.settingsAreNotEqual} />
                      ) : undefined
                    }
                  >
                    <Form.Item
                      dependencies={[
                        ActivityAlertFields.REPEATING_STEP_OUT_ENABLED,
                        ActivityAlertFields.REPEATING_STEP_OUT_MIN_DURATION,
                      ]}
                      name={[
                        ...ActivityAlertFields.REPEATING_STEP_OUT_MAX_DURATION,
                      ]}
                      rules={maxDurationValidator(equalSelectedTenantsSettings)}
                    >
                      <InputWithIcon
                        disabled={
                          !form.getFieldValue(
                            ActivityAlertFields.REPEATING_STEP_OUT_ENABLED,
                          ) || !isFormEnabled
                        }
                        type="number"
                        iconSrc={editIcon}
                        width={'60px'}
                      />
                    </Form.Item>
                  </StyledQuestionTooltip>
                  <CheckboxStyledLabel>
                    <FormattedMessage {...messages.minutesLabel} />
                  </CheckboxStyledLabel>
                </FieldsOnSameRow>
                {/*Commented out based on https://neteera.atlassian.net/browse/NPD-936*/}
                {/*<AlertSettingsSubtitle>
                    <FormattedMessage {...messages.inBedAlertTitle} />
                  </AlertSettingsSubtitle>
                  <FormattedMessage {...messages.inBedNotificationLabel} />
                  <FieldsOnSameRow>
                    <Form.Item
                      noStyle
                      name={[...ActivityAlertFields.IN_BED_ENABLED]}
                      valuePropName="checked"
                    >
                       @ts-ignore Form.Item component will take care of passing props in from
                      <CheckboxForm
                        label={intl.formatMessage(
                          messages.repeatingStepOutLabel,
                        )}
                        checked={
                          !!form.getFieldValue(
                            ActivityAlertFields.IN_BED_ENABLED,
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      dependencies={[ActivityAlertFields.IN_BED_ENABLED]}
                      name={[...ActivityAlertFields.IN_BED_PERCENT_CHANGE]}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value: string) {
                            if (
                              !getFieldValue(
                                ActivityAlertFields.IN_BED_ENABLED,
                              ) ||
                              (parseInt(value) > 19 && parseInt(value) < 101)
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              intl.formatMessage(
                                messages.inBedPercentageValidation,
                              ),
                            );
                          },
                        }),
                      ]}
                    >
                      <InputWithIcon
                        disabled={
                          !form.getFieldValue(
                            ActivityAlertFields.IN_BED_ENABLED,
                          )
                        }
                        type="number"
                        iconSrc={editIcon}
                        width={'60px'}
                      />
                    </Form.Item>
                    <CheckboxStyledLabel>
                      <FormattedMessage
                        {...messages.inBedCheckboxDescription}
                      />
                    </CheckboxStyledLabel>
                    <StyledQuestionTooltip
                      title={<FormattedMessage {...messages.repeatingDurationSecondTooltip} />}
                    >
                      <QuestionCircleOutlined />
                    </StyledQuestionTooltip>
                  </FieldsOnSameRow>
                  <FieldsOnSameRow>
                    <CheckboxStyledLabel>
                      <FormattedMessage
                        {...messages.inBedDurationBaselineLabel}
                      />
                    </CheckboxStyledLabel>
                    <Form.Item
                      dependencies={[ActivityAlertFields.IN_BED_ENABLED]}
                      name={[...ActivityAlertFields.IN_BED_BASELINE_DAYS]}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value: string) {
                            if (
                              !getFieldValue(
                                ActivityAlertFields.IN_BED_ENABLED,
                              ) ||
                              (parseInt(value) > 2 && parseInt(value) < 8)
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              intl.formatMessage(
                                messages.inBedBaselineDaysValidation,
                              ),
                            );
                          },
                        }),
                      ]}
                    >
                      <InputWithIcon
                        disabled={
                          !form.getFieldValue(
                            ActivityAlertFields.IN_BED_ENABLED,
                          )
                        }
                        type="number"
                        iconSrc={editIcon}
                        width={'60px'}
                      />
                    </Form.Item>
                    <CheckboxStyledLabel>
                      <FormattedMessage {...messages.daysLabel} />
                    </CheckboxStyledLabel>
                    <StyledQuestionTooltip
                      title={
                        <FormattedMessage {...messages.inBedTooltip} />
                      }
                    >
                      <QuestionCircleOutlined />
                    </StyledQuestionTooltip>
                  </FieldsOnSameRow>
                  <FieldsOnSameRow>
                    <CheckboxStyledLabel>
                      <FormattedMessage
                        {...messages.inBedDeviationPeriodLabel}
                      />
                    </CheckboxStyledLabel>
                    <Form.Item
                      dependencies={[ActivityAlertFields.IN_BED_ENABLED]}
                      name={[...ActivityAlertFields.IN_BED_DEVIATION_PERIOD]}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value: string) {
                            if (
                              !getFieldValue(
                                ActivityAlertFields.IN_BED_ENABLED,
                              ) ||
                              (parseInt(value) > 0 && parseInt(value) < 8)
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              intl.formatMessage(
                                messages.inBedDeviationPeriodValidation,
                              ),
                            );
                          },
                        }),
                      ]}
                    >
                      <InputWithIcon
                        disabled={
                          !form.getFieldValue(
                            ActivityAlertFields.IN_BED_ENABLED,
                          )
                        }
                        type="number"
                        iconSrc={editIcon}
                        width={'60px'}
                      />
                    </Form.Item>
                    <CheckboxStyledLabel>
                      <FormattedMessage {...messages.daysLabel} />
                    </CheckboxStyledLabel>
                    <StyledQuestionTooltip
                      title={
                        <FormattedMessage {...messages.inBedDurationTooltip} />
                      }
                    >
                      <QuestionCircleOutlined />
                    </StyledQuestionTooltip>
                  </FieldsOnSameRow>*/}
                <AlertSettingsSubtitle>
                  <FormattedMessage {...messages.timezoneNotification} />
                </AlertSettingsSubtitle>
              </AccessControl>
            </CheckboxesWrapper>
          );
        }}
      </Form>
      {hasSubscriptions && (
        <CheckboxesWrapper>
          <FormattedMessage {...messages.sendMeNotificationBy} />
          <FieldsOnSameRow>
            <Checkbox
              disabled={!isFormEnabled}
              label={intl.formatMessage(messages.email)}
              checked={subscriptionDetails.ACTIVITY.EMAIL.isAssigned}
              handleChange={value =>
                setSubscriptionDetails({
                  ...subscriptionDetails,
                  ACTIVITY: {
                    ...subscriptionDetails.ACTIVITY,
                    EMAIL: {
                      ...subscriptionDetails.ACTIVITY.EMAIL,
                      isAssigned: value,
                    },
                  },
                })
              }
            />
            <StyledQuestionTooltip
              title={<FormattedMessage {...messages.inBedDeviationTooltip} />}
            >
              <QuestionCircleOutlined />
            </StyledQuestionTooltip>
          </FieldsOnSameRow>
          <Checkbox
            disabled={!isFormEnabled}
            label={intl.formatMessage(messages.sms)}
            checked={subscriptionDetails.ACTIVITY.SMS.isAssigned}
            handleChange={value =>
              setSubscriptionDetails({
                ...subscriptionDetails,
                ACTIVITY: {
                  ...subscriptionDetails.ACTIVITY,
                  SMS: {
                    ...subscriptionDetails.ACTIVITY.SMS,
                    isAssigned: value,
                  },
                },
              })
            }
          />
        </CheckboxesWrapper>
      )}
    </>
  );
};

const messages = defineMessages({
  outOfBedAlertTitle: {
    defaultMessage: 'BedExit',
  },
  notifyWhenLabel: {
    defaultMessage: 'Notify when a patient is out of bed:',
  },
  shortlyAfter: {
    defaultMessage: 'Shortly after (BedExit)',
  },
  selectTime: {
    defaultMessage: 'Select a time frame:',
  },
  exitNoReturn: {
    defaultMessage: 'BedExit duration',
  },
  nightDisruption: {
    defaultMessage: 'BedExit Frequency',
  },
  from: {
    defaultMessage: 'From',
  },
  to: {
    defaultMessage: 'to',
  },
  continuousCheckboxLabel: {
    defaultMessage: 'For more than continuous',
  },
  repeatingStepOutLabel: {
    defaultMessage: 'At least',
  },
  repeatingDuration: {
    defaultMessage: 'times for more than',
  },
  minutesLabel: {
    defaultMessage: 'minutes',
  },
  daysLabel: {
    defaultMessage: 'days',
  },
  hoursLabel: {
    defaultMessage: 'hour(s)',
  },
  repeatingMaxDuration: {
    defaultMessage: 'and less than',
  },
  inBedAlertTitle: {
    defaultMessage: 'In Bed Alert',
  },
  inBedNotificationLabel: {
    defaultMessage:
      'Notify when a patient`s daily average is deviating from its baseline by:',
  },
  inBedCheckboxDescription: {
    defaultMessage: '% more/less in bed',
  },
  inBedDurationBaselineLabel: {
    defaultMessage: 'In Bed Duration Baseline',
  },
  inBedDeviationPeriodLabel: {
    defaultMessage: 'Deviation Period',
  },

  multiselectPlaceholder: {
    defaultMessage: 'Choose Tenant...',
  },
  sendMeNotificationBy: {
    defaultMessage: 'Send me Notification by:',
  },
  email: {
    defaultMessage: 'Email',
  },
  sms: {
    defaultMessage: 'SMS',
  },
  selectEmails: {
    defaultMessage: 'Select emails',
  },
  addNewEmail: {
    defaultMessage: 'Add new email',
  },
  extraEmailList: {
    defaultMessage: 'Send notification by email also to:',
  },
  settingsDoNoMatch: {
    defaultMessage:
      'Selected subtenants settings do no match. Please select subtenants with matching settings in order to edit them.',
  },
  shortlyAfterTooltip: {
    defaultMessage:
      'Activate a BedExit for all patients in the selected group. Once checked, each time a patient leaves bed, a notification will be sent shortly after.',
  },
  timeIntervalTooltip: {
    defaultMessage:
      'Set a time range, like night hours, for expected patient bed occupancy. If the bed is unoccupied for a specified duration within this range, you’ll be notified. For instance, an alert will be sent if a patient leaves their bed between 8pm and 8am for at least an hour.',
  },
  repeatingDurationTooltip: {
    defaultMessage:
      'Define number and length of bed exits and be notified. That may suggest of a disorder such as urinary tract infection.',
  },
  repeatingDurationSecondTooltip: {
    defaultMessage:
      'Define a deviation in patients’ bedtime to be notified for (might be an indication for pain/ restlessness or general\n' +
      'weakness). Baseline and deviation are calculated individually per patient. For example, if a patient is used to staying 10\n' +
      'hours a day in bed, and in the last 3 days he/she spends 15 hours a day in bed, the deviation value is 50%',
  },
  inBedTooltip: {
    defaultMessage:
      'The baseline is an average of the bed occupancy. It is calculated individually per patient. Generally, longer period\n' +
      'baselines (such as 7 days) fits more stable patients, while shorter ones (such as 3 days) provides more sensitivity to\n' +
      'patients that we expect changes in their status',
  },
  inBedDurationTooltip: {
    defaultMessage:
      'Please define the alert sensitivity. Shorter periods will probably trigger more alerts while longer periods will provide\n' +
      'less',
  },
  inBedDeviationTooltip: {
    defaultMessage:
      'Patients are not identified in alerts sent by email or SMS. You will receive a password-protected link with patient\n' +
      'details in the alert body',
  },
  timezoneNotification: {
    defaultMessage:
      '*Settings will apply according to the selected tenant’s local time zone',
  },
  settingsAreNotEqual: {
    defaultMessage:
      'Multiple Values. Please change the selection or override the settings',
  },
});
export default injectIntl(
  React.memo(ActivityAlertsForm, (oldProps, newProps) =>
    deepEqual(oldProps, newProps),
  ),
);
