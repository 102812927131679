import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { selectors } from 'src/routes/IndividualDashboard/modules/dashboard/slice';
import { selectors as alertSelectors } from 'src/redux/data/alerts';
import { selectAlertLogItems } from 'src/routes/IndividualDashboard/modules/utils/selectors';
import { UUID } from 'src/types/utility';
import {
  ALERT_METRIC_ENUM,
  ALERT_METRIC_PREPOSITION_ENUM,
} from 'src/redux/data/constants';

type OwnProps = {
  patientId: UUID;
};

const mapThresholds = (state: RootState) => {
  const thresholds = alertSelectors.selectThresholds(state);

  return {
    hr: {
      above:
        thresholds.find(
          th =>
            th.enable &&
            th.metric === ALERT_METRIC_ENUM.HR &&
            th.preposition === ALERT_METRIC_PREPOSITION_ENUM.ABOVE,
        )?.value || null,
      below:
        thresholds.find(
          th =>
            th.enable &&
            th.metric === ALERT_METRIC_ENUM.HR &&
            th.preposition === ALERT_METRIC_PREPOSITION_ENUM.BELOW,
        )?.value || null,
    },
    rr: {
      above:
        thresholds.find(
          th =>
            th.enable &&
            th.metric === ALERT_METRIC_ENUM.RR &&
            th.preposition === ALERT_METRIC_PREPOSITION_ENUM.ABOVE,
        )?.value || null,
      below:
        thresholds.find(
          th =>
            th.enable &&
            th.metric === ALERT_METRIC_ENUM.RR &&
            th.preposition === ALERT_METRIC_PREPOSITION_ENUM.BELOW,
        )?.value || null,
    },
  };
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  data: selectors.selectMainGraphMetricData(state),
  metadata: selectors.selectMainGraphMetadata(state),
  thresholds: mapThresholds(state),
  alerts: selectAlertLogItems(state, ownProps.patientId),
});

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);
export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type IProps = PropsFromRedux & OwnProps;

export default Connector;
