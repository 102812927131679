import { actions as managmentActions } from 'src/redux/data/managment/modules/slice';
import { intl, messages } from '../messages';
import { MANAGMENT_NOTIFICATION_TYPES } from '../../../redux/data/managment/modules/constants';

export const managmentActionToErrorNotificationMessagesMap = {
  [managmentActions.onDownloadSession.type]: (sessionId, _) => ({
    message: intl.formatMessage(messages.downloadSessionError),
    description: `${intl.formatMessage(
      messages.downloadSessionError,
    )} ${sessionId}`,
  }),
  [MANAGMENT_NOTIFICATION_TYPES.NO_DATA_AVAILABLE]: (_, error) => ({
    message: intl.formatMessage(messages.downloadSessionError),
    description: `${intl.formatMessage(messages.noDataAvailable)}`,
  }),
};
