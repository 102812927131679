import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { RootState } from 'src/redux/store';
import { AlertType } from 'src/types/alerts';
import { Subscriber } from 'src/types/subscribers';
import { DateUTC, UUID } from 'src/types/utility';
import {
  UpdateSubscribersListPayload,
  UpdateSubscribersPayload,
  UpdateSubscribersResponse,
} from 'src/services/types';
import { DATA_FETCHING_STATUS, DATA_STATE_KEY } from 'src/redux/data/constants';
import { SubscriberStatus } from './types';
import { mergeArraysWithUniqueIds } from '../../dataUtils';

export const STATE_KEY = 'subscribers';
type LoadingStatus = 'LOADING' | 'SUCCESS' | 'ERROR';
export const INITIAL_STATE: {
  subscribers: Subscriber[];
  isSettingsModalVisible: boolean;
  hasAnyActiveSubscription: boolean;
  subscribersStatus: LoadingStatus;
  subscriberStatus: Partial<{
    mediaAlertsEnabled: boolean;
    suppressStartTime: DateUTC | null;
    suppressDuration: number;
  }> & { items: Subscriber[] };
} = {
  subscribers: [],
  isSettingsModalVisible: false,
  hasAnyActiveSubscription: false,
  subscribersStatus: DATA_FETCHING_STATUS.LOADING,
  subscriberStatus: {
    mediaAlertsEnabled: false,
    suppressStartTime: null,
    suppressDuration: 0,
    items: [],
  },
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    getSubscriberStatusSuccess: (
      state,
      action: PayloadAction<SubscriberStatus>,
    ) => {
      // TODO: Check needs to be updated to include bed exit as well
      const { sms: subscriberSMSStatus, email: subscriberEmailStatus } =
        action.payload;

      const subscriberStatus = subscriberSMSStatus || subscriberEmailStatus;

      if (!subscriberStatus) {
        state.hasAnyActiveSubscription = false;
        state.subscriberStatus = INITIAL_STATE.subscriberStatus;

        return;
      }

      state.hasAnyActiveSubscription = true;
      state.subscriberStatus = {
        mediaAlertsEnabled: subscriberStatus.mediaAlertsEnabled,
        suppressStartTime: subscriberStatus.suppressStartTime,
        suppressDuration: parseInt(subscriberStatus.suppressDuration),
        items: [],
      };
    },
    updateSubscribersSuccess: (
      _state,
      action: PayloadAction<UpdateSubscribersResponse>,
    ) => {
      // TODO: See what is the logic here
      console.log('Subscribers updated:', action);
    },
    setSubscribersStatus: (state, action: PayloadAction<LoadingStatus>) => {
      state.subscribersStatus = action.payload;
    },
    fetchSubscribersListSuccess: (
      state,
      action: PayloadAction<Subscriber[]>,
    ) => {
      state.subscribers = mergeArraysWithUniqueIds(
        state.subscribers,
        action.payload,
      );
      state.subscribersStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    updateSubscribersListSuccess: (
      state,
      action: PayloadAction<{ subscribers: Subscriber[]; tenantId: UUID }>,
    ) => {
      const { subscribers, tenantId } = action.payload;

      state.subscribers = mergeArraysWithUniqueIds(
        state.subscribers.filter(s => s.tenantId !== tenantId),
        subscribers,
      );
    },
    setIsSettingsModalVisible(state, action: PayloadAction<boolean>) {
      state.isSettingsModalVisible = action.payload;
    },
  },
  extraReducers: {},
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  selectPermissionStatus: createSelector(
    getState,
    state => state.hasAnyActiveSubscription,
  ),
  selectSubscribersAlertsEnabled: createSelector(
    getState,
    state => state.subscriberStatus.mediaAlertsEnabled,
  ),
  selectSubscribersSuppressStartTime: createSelector(
    getState,
    state => state.subscriberStatus.suppressStartTime,
  ),
  selectSubscribersSuppressDuration: createSelector(
    getState,
    state => state.subscriberStatus.suppressDuration,
  ),
  selectSubscribersSuppressedUntil: createSelector(getState, state => {
    if (state.subscriberStatus.suppressStartTime) {
      const start = dayjs(state.subscriberStatus.suppressStartTime);
      const end = start.add(state.subscriberStatus.suppressDuration || 0, 'h');

      return end.format();
    } else {
      return null;
    }
  }),
  selectIsSettingsModalVisible: createSelector(
    getState,
    state => state.isSettingsModalVisible,
  ),
  selectSubscribersList: createSelector(getState, state => state.subscribers),
  getSubscribersStatus: createSelector(
    getState,
    state => state.subscribersStatus,
  ),
};

const extraActions = {
  getSubscriberStatus: createAction<{
    userId: UUID;
    alertType: AlertType;
  }>(`${STATE_KEY}/getSubscriberStatus`),
  updateSubscribers: createAction<{
    userId: UUID;
    data: UpdateSubscribersPayload;
    includeNotification: boolean;
  }>(`${STATE_KEY}/updateSubscribers`),
  fetchSubscribersList: createAction(`${STATE_KEY}/fetchSubscribersList`),
  updateSubscribersList: createAction<UpdateSubscribersListPayload>(
    `${STATE_KEY}/updateSubscribersList`,
  ),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
