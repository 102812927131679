import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import SelectBox from 'src/components/general-ui/SelectBox';
import { UUID } from 'src/types/utility';

type Props = {
  tenantSelectOptions: {
    value: UUID;
    label: string;
  }[];
  onChangeTenantId(id: UUID | null): UUID;
};

const SelectTenantBox = ({
  tenantSelectOptions,
  onChangeTenantId,
}: Props): JSX.Element => {
  const onSelectTenant = (tenantId: UUID) => {
    onChangeTenantId(tenantId);
  };

  return (
    <div style={{ marginBottom: '0.5rem' }}>
      <FormattedMessage {...messages.chooseTenant} />
      <SelectBox
        options={tenantSelectOptions}
        handleChange={(id: UUID) => {
          onSelectTenant(id);
        }}
      />
    </div>
  );
};

const messages = defineMessages({
  chooseTenant: {
    defaultMessage: 'Choose Tenant',
  },
});

export default SelectTenantBox;
