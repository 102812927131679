import React from 'react';
import CardTooltip from './CardTooltip';

import {
  StatisticsCardWrapper,
  CardIcon,
  CardContent,
  CardTitle,
  CardValue,
} from './styled';

type Props = {
  title: string;
  tooltip?: string;
  icon: string;
  value: string;
  isAltDisplay?: boolean;
};

const StatisticsCard = ({
  title,
  icon,
  value,
  tooltip,
  isAltDisplay,
}: Props): JSX.Element =>
  tooltip ? (
    <CardTooltip placement="top" title={tooltip}>
      <StatisticsCardWrapper>
        <CardIcon src={icon} alt="statistics-card-icon" />
        <CardContent>
          <CardTitle>{title}</CardTitle>
          <CardValue isAltDisplay={isAltDisplay}>{value}</CardValue>
        </CardContent>
      </StatisticsCardWrapper>
    </CardTooltip>
  ) : (
    <StatisticsCardWrapper>
      <CardIcon src={icon} alt="statistics-card-icon" />
      <CardContent>
        <CardTitle>{title}</CardTitle>
        <CardValue isAltDisplay={isAltDisplay}>{value}</CardValue>
      </CardContent>
    </StatisticsCardWrapper>
  );

export default StatisticsCard;
