import styled from 'styled-components';

import { Row, SubTitle } from 'src/components/styled';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 17%;
  column-gap: 2%;
`;

export const Card = styled(Row)`
  border: ${props =>
    `1px solid ${props.theme.colors.individualDashboardHeaderBorder}`};
  align-items: center;
  border-radius: 8px;
  line-height: 1;
  width: 100%;
  background-color: ${props =>
    props.theme.colors.measurementCardBackgroundColor};
  margin-right: 0;
  height: 100%;
  padding: 0.25rem 0.35rem;
`;

export const CardTitle = styled(SubTitle)`
  display: block;
  color: ${props => props.theme.colors.individualDashboardCardTitle};
  font-weight: 600;
  white-space: break-spaces;
  margin-bottom: 0;
  height: 50%;
  font-size: 0.7rem;
  align-items: center;
`;

export const MeasurementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0.25rem;
  height: 90%;
`;

export const MeasurementValue = styled.div<{
  isHighQuality?: boolean;
  isSmallText?: boolean;
}>`
  font-weight: 400;
  display: inline-block;
  white-space: break-spaces;
  color: ${props =>
    props.isHighQuality
      ? props.theme.colors.defaultTextColor
      : props.theme.colors.invidualDashboardCardLowQualityValue};
  font-size: ${props => (props.isSmallText ? '0.7rem' : '1.5rem')};
  margin-top: 0.2rem;
`;

export const MeasurementUnit = styled.div<{ isHidden: boolean }>`
  display: ${props => (props.isHidden ? 'none' : 'inline-block')};
  color: ${props => props.theme.colors.individualdDashboardHeaderTextColor};
  font-weight: 300;
  font-size: 0.5rem;
  margin-top: 0.75rem;
  margin-left: 2px;
`;

export const MeasurementGroup = styled(Row)`
  white-space: pre;
  height: 50%;
  align-items: center;
`;

export const MeasurementLogo = styled.img`
  width: 2.6rem;
  margin-left: 0.2rem;
`;

export const StyledMeasurementCard = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50px;
  width: 100%;
`;
