import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const parseDateWithFormat = (
  date: string | number,
  format?: string,
): Dayjs => (format ? dayjs(date, format) : dayjs(date));

export const createImage = (src: string): HTMLImageElement => {
  const img = new Image();
  img.src = src;

  return img;
};
