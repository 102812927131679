import React from 'react';
import styled from 'styled-components';

import TypedCustomTooltip from 'src/components/TypedCustomTooltip';
import NoteItem from './NoteItem';
import NotesForm from './NotesForm';
import { Props } from './Connector';
import permissions from 'src/permissions';
import AccessControl from 'src/components/AccessControl';

const NotesTooltip = styled(TypedCustomTooltip)`
  &.ant-tooltip {
    max-width: unset;
  }
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: #ffffff;
  }
  div.ant-tooltip-inner {
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 600;
    font-size: 0.6rem;
    line-height: 0.75rem;
    color: #252525;
    padding: 0.75rem 0.65rem;

    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.0946402);
    border-radius: 3px;
    max
  }
`;

const NotesWall = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const getTooltipMessage = ({
  deviceId,
  patientId,
  tenantId,
  notes,
  createNote,
}: Props) => (
  <NotesWall>
    {notes.map(n => (
      <NoteItem key={n.id} noteId={n.id} patientId={n.patientId} />
    ))}
    <AccessControl permissions={[permissions.ALERT_CREATE_PATIENT_ALERT_NOTE]}>
      <NotesForm
        deviceId={deviceId}
        patientId={patientId}
        tenantId={tenantId}
        createNote={createNote}
      />
    </AccessControl>
  </NotesWall>
);

export default NotesTooltip;
