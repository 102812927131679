import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  IntlShape,
} from 'react-intl';

import { apiStatusEnum, emailPattern } from 'src/utils/constants';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import {
  CenteredContainer,
  FormItem,
  AuthButton,
  MessageArea,
  ErrorText,
  SuccessText,
  SmallNavigationLink,
} from '../components/styled';
import PasswordLayout, {
  ADDITIONAL_CONTENT_POSITION,
} from '../../../components/PasswordForm/PasswordLayout';
import Connector, { PropsFromRedux } from './Connector';

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const ForgotPasswordPage = ({
  intl,
  forgotPasswordStatus,
  onForgotPasswordUnmount,
  forgotPassword,
}: Props): JSX.Element => {
  const onFinish = (values: { email: string }) => {
    forgotPassword(values);
  };

  useEffect(() => {
    return () => {
      onForgotPasswordUnmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = forgotPasswordStatus === apiStatusEnum.LOADING;
  const isOk = forgotPasswordStatus === apiStatusEnum.OK;

  return (
    <CenteredContainer>
      <PasswordLayout
        formComponent={
          <Form layout="vertical" onFinish={onFinish}>
            <FormItem
              label={intl.formatMessage(messages.email)}
              name="email"
              hasFeedback
              rules={[
                {
                  pattern: emailPattern,
                  required: true,
                  message: intl.formatMessage(messages.invalidEmail),
                },
              ]}
            >
              <Input />
            </FormItem>
            <Form.Item>
              <AuthButton isLoading={isLoading} disabled={isOk}>
                <FormattedMessage {...messages.submit} />
              </AuthButton>
            </Form.Item>
            <MessageArea>
              {forgotPasswordStatus && !isOk && !isLoading && (
                <ErrorText>
                  <FormattedMessage {...messages.errorText} />
                </ErrorText>
              )}
              {isOk && !isLoading && (
                <SuccessText>
                  <FormattedMessage {...messages.success} />
                </SuccessText>
              )}
            </MessageArea>
          </Form>
        }
        additionalContentComponent={
          <SmallNavigationLink to={`${appRoutes.LOGIN}`}>
            <FormattedMessage {...messages.backToLogin} />
          </SmallNavigationLink>
        }
        innerTitle={messages.infoText}
        additionalContentPosition={ADDITIONAL_CONTENT_POSITION.END}
      />
    </CenteredContainer>
  );
};

const messages = defineMessages({
  email: {
    defaultMessage: 'Email',
  },
  infoText: {
    defaultMessage:
      "Type in your email address below and we'll send you an email with a link to create a new password",
  },
  errorText: {
    defaultMessage: 'Could not send reset password email',
  },
  success: {
    defaultMessage: 'Email sent, go check your mailbox!',
  },
  invalidEmail: {
    defaultMessage: 'Invalid email address',
  },
  submit: {
    defaultMessage: 'Reset Password',
  },
  backToLogin: {
    defaultMessage: 'Back to login',
  },
});

export default Connector(injectIntl(ForgotPasswordPage));
