import {
  Alert,
  BaselineAlert,
  OccupancyAlert,
  VsAlert,
} from 'src/types/alerts';
import { UUID } from 'src/types/utility';

export type PatientAlertsByType = {
  HR?: VsAlert;
  RR?: VsAlert;
  HR_BASELINE?: BaselineAlert;
  RR_BASELINE?: BaselineAlert;
  BED_EXIT?: OccupancyAlert;
  BED_TIME_BASELINE?: OccupancyAlert;
  LONG_OUT_OF_BED?: OccupancyAlert;
  BED_EXIT_FREQUENCY?: OccupancyAlert;
};

export type AlertsMap = {
  [patientId: UUID]: PatientAlertsByType;
};

export type PatientAlertsMap = {
  [patientId: UUID]: Alert[];
};

export type PacientIdPayload = UUID;

export enum AlertSoundStatus {
  NO_ALERT = 'NO_ALERT',
  NEED_TO_PLAY = 'NEED_TO_PLAY',
  SOUND_PLAYED = 'SOUND_PLAYED',
}

export enum AlertSoundSeverity {
  NO_ALERT = 0,
  SIMPLE_ALERT = 1,
  STRONG_ALERT = 2,
}

export type AlertSoundState = {
  status: AlertSoundStatus;
  severity: AlertSoundSeverity;
};

export type AlertSounds = Partial<{
  HR: AlertSoundState;
  RR: AlertSoundState;
  HR_BASELINE: AlertSoundState;
  RR_BASELINE: AlertSoundState;
  BED_EXIT: AlertSoundState;
  ALL_DEVICES_DISCONNECTED?: AlertSoundState;
  DEVICE_DISCONNECTED?: AlertSoundState;
}>;

export type AlertSoundsMap = {
  [patientId: UUID]: AlertSounds;
};

export type SoundsToBePlayed = {
  severity: AlertSoundSeverity;
  soundsData: AlertSoundsMap;
};
