import { createSlice, createSelector, createAction } from '@reduxjs/toolkit';
import { apiStatusEnum } from 'src/utils/constants';
import {
  METRIC_ENUM,
  digitsRoundByMetricType,
} from 'src/utils/graphsUtils/graphConstants';
import { splitDataPointsListToMultipleLists } from 'src/utils/graphsUtils/graphsParser';
import {
  parseGetAllSessionResponse,
  parseGetSpotMeasurementsResponse,
  parseHriDataResponse,
} from './parser';

export const STATE_KEY = 'historyModal';
export const INITIAL_STATE = {
  isModalVisible: false,
  sessionsData: null, // { [patientId]: { data: [  {sessionId, startTime, endTime, patientId, deviceId, status} ], status: DATA_FETCHING_STATUS.LOADING} }
  sessionGraphs: null, // { [sessionId]: {RR: {data: {}, status: LOADING}, HR: {data: {}, status: LOADING} } } }
  spotMeasurementsData: {},
  hriData: {},
};

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    openModal: state => {
      state.isModalVisible = true;
    },
    closeModal: () => INITIAL_STATE,
    // get all sessions
    getAllSessions: (state, action) => {
      const { patientId } = action.payload;
      if (!state.sessionsData?.[patientId]) {
        state.sessionsData = {
          ...state.sessionsData,
          [patientId]: reducerHelperFunctions.getInitializedSessionDataObject(),
        };
      }
    },
    getAllSessionsSuccess: (state, action) => {
      const { patientId, data } = action.payload;
      state.sessionsData[patientId] = {
        data: parseGetAllSessionResponse(data),
        status: apiStatusEnum.OK,
      };
    },
    getAllSessionsFail: (state, action) => {
      const { patientId } = action.payload;
      state.sessionsData[patientId] = {
        data: null,
        status: apiStatusEnum.ERROR,
      };
    },
    // spot
    onSpotHistoryMount: (state, action) => {
      const { patientId } = action.payload;
      state.spotMeasurementsData[patientId] = {
        data: null,
        status: apiStatusEnum.LOADING,
      };
    },
    getSpotMeasurementsSuccess: (state, action) => {
      const { patientId, data } = action.payload;
      state.spotMeasurementsData[patientId] = {
        data: parseGetSpotMeasurementsResponse(data),
        status: apiStatusEnum.OK,
      };
    },
    getSpotMeasurementsFail: (state, action) => {
      const { patientId } = action.payload;
      state.spotMeasurementsData[patientId] = {
        data: null,
        status: apiStatusEnum.ERROR,
      };
    },
    // graphs per session
    viewSessionGraphs: (state, action) => {
      const { sessionId, patientId } = action.payload;
      if (!state.sessionGraphs?.[sessionId]) {
        const sessionData = state.sessionsData[patientId]?.data?.find(
          s => s.sessionId === sessionId,
        );
        const startTime = sessionData?.startTime;
        const endTime = sessionData?.endTime;
        const updatedSessionGraphs = {
          ...state.sessionGraphs,
          [sessionId]: {
            ...reducerHelperFunctions.getInitializedGraphsObject(),
            startTime,
            endTime,
          },
        };
        state.sessionGraphs = updatedSessionGraphs;
      }
    },
    getSessionGraphPointsSuccess: (state, action) => {
      const { sessionId, metric, data } = action.payload;
      state.sessionGraphs[sessionId] = {
        ...state.sessionGraphs[sessionId],
        [METRIC_ENUM[metric]]: {
          data: splitDataPointsListToMultipleLists(
            data?.continuousMeasurements,
            digitsRoundByMetricType[metric],
          ),
          status: apiStatusEnum.OK,
        },
      };
    },
    getSessionGraphPointsFail: (state, action) => {
      const { sessionId, metric } = action.payload;
      state.sessionGraphs[sessionId] = {
        ...state.sessionGraphs[sessionId],
        [METRIC_ENUM[metric]]: { data: null, status: apiStatusEnum.ERROR },
      };
    },
    onHriParamsChanged: (state, action) => {
      const { patientId } = action.payload;
      state.hriData[patientId] = {
        data: null,
        status: apiStatusEnum.LOADING,
      };
    },
    getHriDataSuccess: (state, action) => {
      const { patientId, data } = action.payload;
      state.hriData[patientId] = {
        data: parseHriDataResponse(data),
        status: apiStatusEnum.OK,
      };
    },
    getHriDataFail: (state, action) => {
      const { patientId } = action.payload;
      state.hriData[patientId] = {
        data: null,
        status: apiStatusEnum.ERROR,
      };
    },
  },
});
/* eslint-enable no-param-reassign */

const emptyPatientSessionDataObject = {
  data: null,
  status: apiStatusEnum.LOADING,
};
const emptyMetricObject = { data: null, status: apiStatusEnum.LOADING };
const initializedGraphsObject = {
  [METRIC_ENUM.RR]: emptyMetricObject,
  [METRIC_ENUM.HR]: emptyMetricObject,
  [METRIC_ENUM.IE]: emptyMetricObject,
  [METRIC_ENUM.RA_bin]: emptyMetricObject,
};
const reducerHelperFunctions = {
  getInitializedSessionDataObject: () => emptyPatientSessionDataObject,
  getInitializedGraphsObject: () => initializedGraphsObject,
  getInitializedDateTimeGraphsObject: (startDateTime, endDateTime) => ({
    startDateTime,
    endDateTime,
    graphs: initializedGraphsObject,
  }),
};
const getState = state => state[STATE_KEY] || INITIAL_STATE;

export const selectors = {
  getPatientSessionData: createSelector(
    getState,
    (_, patientId) => patientId, // in order to pass arguments (ignores state)
    (state, patientId) => state.sessionsData?.[patientId]?.data,
  ),
  getPatientSessionDataStatus: createSelector(
    getState,
    (_, patientId) => patientId, // in order to pass arguments (ignores state)
    (state, patientId) => state.sessionsData?.[patientId]?.status,
  ),
  getAllSessionsGraphsPoints: createSelector(
    getState,
    state => state.sessionGraphs,
  ),
  getIsModalVisible: createSelector(getState, state => state.isModalVisible),
  getPatientSpotMeasurementsData: createSelector(
    getState,
    (_, patientId) => patientId, // in order to pass arguments (ignores state)
    (state, patientId) => state.spotMeasurementsData?.[patientId]?.data,
  ),
  getPatientSpotMeasurementsStatus: createSelector(
    getState,
    (_, patientId) => patientId, // in order to pass arguments (ignores state)
    (state, patientId) => state.spotMeasurementsData?.[patientId]?.status,
  ),
  getPatientHriData: createSelector(
    getState,
    (_, patientId) => patientId, // in order to pass arguments (ignores state)
    (state, patientId) => state.hriData?.[patientId]?.data || [],
  ),
  getPatientHriStatus: createSelector(
    getState,
    (_, patientId) => patientId, // in order to pass arguments (ignores state)
    (state, patientId) => state.hriData?.[patientId]?.status,
  ),
};

const extraActions = {
  downloadHri: createAction(`${STATE_KEY}/downloadHri`),
};

export const actions = {
  ...slice.actions,
  ...extraActions,
};

const { reducer } = slice;
export default reducer;
