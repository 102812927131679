import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { selectors, actions } from 'src/routes/GMDashboard';

const mapStateToProps = (state: RootState) => ({
  appliedFilters: selectors.selectFilters(state).status,
});

const mapActionCreators = {
  applyFilter: actions.applyFilter,
  removeFilter: actions.removeFilter,
  resetFilters: actions.resetFilters,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;

export default Connector;
