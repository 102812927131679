import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { actions, selectors } from 'src/routes/NursesStation';

const mapStateToProps = (state: RootState) => ({
  soundStatus: selectors.selectAlertSoundStatus(state),
});

const mapActionCreators = {
  changeAlertSoundStatus: actions.changeAlertSoundStatus,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
