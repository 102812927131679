import React from 'react';

import { SVGProps } from '../types';

const DownRightArrowLongIcon = ({
  width = '13',
  height = '12',
  color = '#252525',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path d="M1 0.75L11.5 11.25" stroke={color} strokeWidth="1.5" />
    <path
      d="M6.83301 11.25H11.4997V6.58333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);

export default DownRightArrowLongIcon;
