import { notification } from 'antd';
import {
  actionToSuccessNotificationMessagesMap,
  actionToErrorNotificationMessagesMap,
  actionWarningNotificationMessagesMap,
} from './mappers';

export const notificationsPlacementTypes = {
  TOP_LEFT: 'topLeft',
  TOP_RIGHT: 'topRight',
  BOTTOM_LEFT: 'bottomLeft',
  BOTTOM_RIGHT: 'bottomRight',
};
const notificationsPlacementDefault = notificationsPlacementTypes.BOTTOM_RIGHT;

export const actionSuccessNotification = (
  actionType,
  data,
  notificationsPlacement = notificationsPlacementDefault,
) => {
  const getNotificationMessages =
    actionToSuccessNotificationMessagesMap[actionType];
  const notificationMessages =
    getNotificationMessages && getNotificationMessages(data);

  notificationMessages &&
    notification.success({
      ...notificationMessages,
      placement: notificationsPlacement,
    });
};

export const actionWarningNotification = (
  actionType,
  data,
  notificationsPlacement = notificationsPlacementDefault,
) => {
  const getNotificationMessages =
    actionWarningNotificationMessagesMap[actionType];
  const notificationMessages =
    getNotificationMessages && getNotificationMessages(data);
  notificationMessages &&
    notification.warning({
      ...notificationMessages,
      placement: notificationsPlacement,
    });
};

export const notifyUserByActionTypeAndCode = (
  actionType,
  data,
  error,
  notificationsPlacement = notificationsPlacementDefault,
) => {
  const getNotificationMessages =
    actionToErrorNotificationMessagesMap[actionType] ||
    actionToErrorNotificationMessagesMap.GENERAL;
  const notificationMessages =
    getNotificationMessages &&
    getNotificationMessages(data, error?.response?.data?.code || error);
  notificationMessages &&
    notification.error({
      ...notificationMessages,
      placement: notificationsPlacement,
    });
};
