import dayjs from 'dayjs';

export const defaultActivityFormData = {
  tenantIds: [],
  outOfBedAlertSettings: {
    shortlyAfterBedExitAlertEnabled: false,
    bedtimeExitAlertSettings: {
      startTime: dayjs('00:00', 'HH'),
      endTime: dayjs('00:00', 'HH'),
      continuousOutOfBedAlertSettings: {
        thresholdDuration: 0,
        enabled: false,
      },
      repeatingStepOutOfBedAlertSettings: {
        minimumRepetitions: 0,
        minimumDuration: 0,
        maximumDuration: 0,
        enabled: false,
      },
    },
  },
  inBedAlertSettings: {
    percentChange: 0,
    percentChangeInBedExits: 0,
    durationBaselineDays: 0,
    deviationPeriodDays: 0,
    enabled: false,
  },
};
