import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { UpdateSubscribersListPayload } from 'src/services/types';
import {
  Subscriber,
  UsersSubscriptionsByTargetMediaAndAlertCategory,
} from 'src/types/subscribers';
import { DATA_STATE_KEY, DATA_FETCHING_STATUS } from '../../constants';
import { extractUserSubscriptionsFromSubscribers } from '../../subscribers/modules/utils';

export const STATE_KEY = 'SMSEmailAlerts';

export const INITIAL_STATE: {
  SMSEmailAlertsStatus: keyof typeof DATA_FETCHING_STATUS;
  userSubscriptionsList: UsersSubscriptionsByTargetMediaAndAlertCategory;
} = {
  SMSEmailAlertsStatus: DATA_FETCHING_STATUS.LOADING,
  userSubscriptionsList: {
    SMS: {
      MEDICAL: [],
      TECHNICAL: [],
      ACTIVITY: [],
    },
    EMAIL: {
      MEDICAL: [],
      TECHNICAL: [],
      ACTIVITY: [],
    },
  },
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setStatus(state, action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>) {
      state.SMSEmailAlertsStatus = action.payload;
    },
    fetchSubscribersListSuccess: (
      state,
      action: PayloadAction<Subscriber[]>,
    ) => {
      state.userSubscriptionsList = extractUserSubscriptionsFromSubscribers(
        action.payload,
      );
      state.SMSEmailAlertsStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    fetchSubscribersListFail: state => {
      state.SMSEmailAlertsStatus = DATA_FETCHING_STATUS.ERROR;
    },
    updateSubscribersListSuccess: state => {
      state.SMSEmailAlertsStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    updateSubscribersListFail: state => {
      state.SMSEmailAlertsStatus = DATA_FETCHING_STATUS.ERROR;
    },
  },
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  getUserSubscriptions: createSelector(
    getState,
    state => state.userSubscriptionsList,
  ),
  getStatus: createSelector(getState, state => state.SMSEmailAlertsStatus),
};

const extraActions = {
  updateSubscribersList: createAction<{
    data: UpdateSubscribersListPayload;
    callback: () => void;
  }>(`${STATE_KEY}/updateSubscribersList`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
