import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/redux/store';
import { selectors as deviceSelectors } from 'src/redux/data/device';
import { selectors as tenantSelectors } from 'src/redux/data/tenant';

const mapStateToProps = (state: RootState) => {
  const tenantsCounters = tenantSelectors.getTenantsAlertCounters(state);
  const lastDayAlertEvents =
    tenantsCounters?.totals.currentAlertsCount +
    tenantsCounters?.totals.attentionListAlertsCount;

  const deviceInfoData = deviceSelectors.selectMTMDeviceStatistics(state);

  return {
    totalDevices: deviceInfoData.totalDevices,
    connectedAssignedDevices: deviceInfoData.connectedAssignedDevices,
    connectedUnassignedDevices: deviceInfoData.connectedUnassignedDevices,
    disconnectedAssignedDevices: deviceInfoData.disconnectedAssignedDevices,
    disconnectedUnassignedDevices: deviceInfoData.disconnectedUnassignedDevices,
    lastDayAlertEvents: lastDayAlertEvents,
  };
};

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
