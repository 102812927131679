import React, { useState } from 'react';
import { styled } from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';

import Connector, { PropsFromRedux } from './Connector';
import SelectTenantBox from './components/SelectTenantBox';
import TenantUsersTable from './components/TenantUsersTable';
import UserModal from 'src/components/UserCrudComponents/UserModal';
import { userFormModes } from 'src/components/UserCrudComponents/constants';

import { MainTitle } from 'src/components/styled';
import ActionButton from 'src/components/general-ui/Buttons/ActionButton';
import permissions from 'src/permissions';
import AccessControl from 'src/components/AccessControl';
import { UUID } from 'src/types/utility';

type UserFormData = {
  name: string;
  email: string;
  userType: UUID;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: ${props => props.theme.fonts.primary};
  background: #e3f3f5;
  color: #252525;
  padding: 1.4rem 1.25rem;
`;

type Props = PropsFromRedux;

const GMSettingsPage = ({
  inviteUserByTenantId,
  selectedTenantId,
  loggedInGroupManagerId,
  modalStatus,
  setModalStatus,
  userTypesInvitedByGm,
  inviteUser,
}: Props): JSX.Element => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const isGmTenantSelect = selectedTenantId === loggedInGroupManagerId;
  const {
    multiTenantUserTypeId,
    healthProfessionalTypeId,
    tenantAdminTypeId,
    multiTenantReadOnlyUserTypeId,
    healthProfessionalReadOnlyTypeId,
  } = userTypesInvitedByGm;

  const externalUserTypes = isGmTenantSelect
    ? [multiTenantUserTypeId, multiTenantReadOnlyUserTypeId]
    : [
        healthProfessionalTypeId,
        tenantAdminTypeId,
        healthProfessionalReadOnlyTypeId,
      ];

  return (
    <Wrapper>
      <MainTitle>
        <FormattedMessage {...messages.users} />
      </MainTitle>
      <Row align="middle" justify="space-around" gutter={10}>
        <Col span={15}>
          <SelectTenantBox />
        </Col>
        <Col span={9}>
          <AccessControl
            permissions={[permissions.ORGANIZATION_INVITATIONS_CREATE]}
          >
            <ActionButton
              onClick={() => {
                setModalVisible(true);
              }}
            >
              <FormattedMessage {...messages.inviteUser} />
            </ActionButton>
          </AccessControl>
        </Col>
      </Row>
      <TenantUsersTable />
      <UserModal
        isModalVisible={modalVisible}
        setIsModalVisible={(value: boolean) => setModalVisible(value)}
        onSubmit={(invitedUserData: UserFormData) => {
          if (!selectedTenantId) {
            return;
          }
          const formData = { ...invitedUserData };
          setModalVisible(false);

          isGmTenantSelect
            ? // @ts-ignore inviteUser slice to be refactored to TS
              inviteUser(formData)
            : inviteUserByTenantId({
                formData,
                selectedTenantId,
              });
        }}
        fields={{
          firstName: true,
          lastName: true,
          email: true,
          phone: true,
          userType: false,
          externalUserTypes: [...externalUserTypes],
        }}
        modalMessage={messages.inviteUser}
        mode={userFormModes.INVITE}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        currentUser={undefined}
      />
    </Wrapper>
  );
};

const messages = defineMessages({
  users: {
    defaultMessage: 'Users',
  },
  inviteUser: {
    defaultMessage: 'Invite User',
  },
});

export default Connector(GMSettingsPage);
