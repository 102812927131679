import {
  Alert,
  AlertLog,
  AlertWithPersonalDetails,
  AllTenantDevicesDisconnectedAlert,
  AverageAlertWithPersonalDetails,
  BaselineAlert,
  BaselineAlertWithPersonalDetails,
  GenericThresholdMetrics,
  MedicalAlert,
  MedicalAlertLog,
  TechnicalAlertLog,
  VsAlert,
} from 'src/types/alerts';
import {
  ALERT_METRIC_ENUM,
  medicalALertThresholdMetricsArray,
} from '../../constants';

export const isAverageAlert = (
  thresholdMetric: keyof GenericThresholdMetrics,
): boolean =>
  thresholdMetric === ALERT_METRIC_ENUM.HR ||
  thresholdMetric === ALERT_METRIC_ENUM.RR;

export const isBaselineAlert = (
  thresholdMetric: keyof GenericThresholdMetrics,
): boolean =>
  thresholdMetric === ALERT_METRIC_ENUM.HR_BASELINE ||
  thresholdMetric === ALERT_METRIC_ENUM.RR_BASELINE;

export const isVacancyAlert = (
  thresholdMetric: keyof GenericThresholdMetrics,
): boolean =>
  thresholdMetric === ALERT_METRIC_ENUM.BED_EXIT ||
  thresholdMetric === ALERT_METRIC_ENUM.LONG_OUT_OF_BED;

export const isAbnormalAlert = (
  thresholdMetric: keyof GenericThresholdMetrics,
): boolean => thresholdMetric === ALERT_METRIC_ENUM.BED_EXIT_FREQUENCY;

export const isVacancyAlertDetails = (alert: AlertWithPersonalDetails) =>
  alert.alertType === ALERT_METRIC_ENUM.BED_EXIT ||
  alert.alertType === ALERT_METRIC_ENUM.LONG_OUT_OF_BED;

export const isHrAlert = (alert: VsAlert): boolean =>
  alert.thresholdMetric === ALERT_METRIC_ENUM.HR;

export const isHrBaselineAlert = (alert: BaselineAlert): boolean =>
  alert.thresholdMetric === ALERT_METRIC_ENUM.HR_BASELINE;

export const isAverageAlertDetails = (
  alert: AlertWithPersonalDetails,
): alert is { timeZoneId: string | null } & AverageAlertWithPersonalDetails =>
  alert.alertType === ALERT_METRIC_ENUM.HR ||
  alert.alertType === ALERT_METRIC_ENUM.RR;

export const isBaselineAlertDetails = (
  alert: AlertWithPersonalDetails,
): alert is { timeZoneId: string | null } & BaselineAlertWithPersonalDetails =>
  alert.alertType === ALERT_METRIC_ENUM.HR_BASELINE ||
  alert.alertType === ALERT_METRIC_ENUM.RR_BASELINE;

export const isHrAlertDetails = (
  alert: AverageAlertWithPersonalDetails,
): boolean => alert.alertType === ALERT_METRIC_ENUM.HR;

export const isHrBaselineAlertDetails = (
  alert: BaselineAlertWithPersonalDetails,
): boolean => alert.alertType === ALERT_METRIC_ENUM.HR_BASELINE;

export const isMedicalAlert = (alert: Alert): alert is MedicalAlert =>
  medicalALertThresholdMetricsArray.includes(alert.thresholdMetric);

export const isTechnicalAlert = (
  thresholdMetric: keyof GenericThresholdMetrics,
): boolean =>
  thresholdMetric === ALERT_METRIC_ENUM.DEVICE_DISCONNECTED ||
  thresholdMetric === ALERT_METRIC_ENUM.ALL_DEVICES_DISCONNECTED;

export const isMedicalAlertLog = (
  alertLog: AlertLog,
): alertLog is MedicalAlertLog =>
  medicalALertThresholdMetricsArray.includes(alertLog.thresholdMetric);

export const isTechnicalAlertLog = (
  alertLog: AlertLog,
): alertLog is TechnicalAlertLog =>
  alertLog.thresholdMetric === ALERT_METRIC_ENUM.DEVICE_DISCONNECTED ||
  alertLog.thresholdMetric === ALERT_METRIC_ENUM.ALL_DEVICES_DISCONNECTED;

export const isMassDisconnectionAlert = (
  alert: Alert,
): alert is AllTenantDevicesDisconnectedAlert =>
  alert.thresholdMetric === ALERT_METRIC_ENUM.ALL_DEVICES_DISCONNECTED;
