import styled from 'styled-components';

export const StyledInstructions = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
`;
export const StyledListInstructions = styled.ul`
  text-align: left;
  display: inline-block;
`;
