import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Checkbox } from 'antd';

import { StatisticsMetricGraphType } from 'src/routes/IndividualDashboard/modules/statistics/types';

const StylesMeasurementsSelection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

const StyledCheckboxesContainer = styled.div`
  width: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  font-family: ${props => props.theme.fonts.secondary};
  color: #252525;
  flex: 1;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #63b7c3;
    border-color: #63b7c3;
  }
`;

type Props = {
  displayMetrics: Record<StatisticsMetricGraphType, boolean>;
  onCheckMetric: (metric: StatisticsMetricGraphType) => void;
};

const MeasurementsSelection = ({
  displayMetrics,
  onCheckMetric,
}: Props): JSX.Element => {
  return (
    <StylesMeasurementsSelection>
      <StyledCheckboxesContainer>
        <StyledCheckbox
          checked={displayMetrics.HR}
          onClick={() => onCheckMetric('HR')}
        >
          <FormattedMessage {...messages.HR} />
        </StyledCheckbox>
        <StyledCheckbox
          checked={displayMetrics.IE}
          onClick={() => onCheckMetric('IE')}
        >
          <FormattedMessage {...messages.IE} />
        </StyledCheckbox>
        <StyledCheckbox
          checked={displayMetrics.RR}
          onClick={() => onCheckMetric('RR')}
        >
          <FormattedMessage {...messages.RR} />
        </StyledCheckbox>
        <StyledCheckbox
          checked={displayMetrics.RA_bin}
          onClick={() => onCheckMetric('RA_bin')}
        >
          <FormattedMessage {...messages.RA_bin} />
        </StyledCheckbox>
      </StyledCheckboxesContainer>
    </StylesMeasurementsSelection>
  );
};

const messages = defineMessages({
  HR: {
    defaultMessage: 'HR',
  },
  RR: {
    defaultMessage: 'RR',
  },
  IE: {
    defaultMessage: 'I/E',
  },
  RA_bin: {
    defaultMessage: 'RA',
  },
});

export default MeasurementsSelection;
