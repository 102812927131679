import React, { useState } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';

import { nowLocal } from 'src/utils/timeUtils';
import TimeButton from './TimeButton';
import {
  TIME_BUTTONS,
  INITIAL_TIME_INTERVAL,
  CUSTOM_SELECTOR_KEY,
  TimeBtnProps,
  ActiveBtnKey,
} from './constants';
import { ButtonsWrapper, StyledRangePicker } from './styled';

type Props = {
  handleChange: (startDate: Dayjs, endDate: Dayjs) => void;
  intl: IntlShape;
};

const TimeIntervalSelector = ({ intl, handleChange }: Props): JSX.Element => {
  const [isActiveKey, setIsActiveKey] = useState<ActiveBtnKey>(
    INITIAL_TIME_INTERVAL.key,
  );

  const onPredefinedDateSelect = ({ key, value, unit }: TimeBtnProps) => {
    setIsActiveKey(key);
    const endDate = nowLocal();
    const startDate = endDate.subtract(value, unit);

    handleChange(startDate, endDate);
  };

  const onCustomDateSelect = (dates: [moment.Moment, moment.Moment]) => {
    const [startDate, endDate] = dates;
    setIsActiveKey(CUSTOM_SELECTOR_KEY);
    // @ts-ignore Refactor later
    handleChange(dayjs(startDate), dayjs(endDate));
  };

  return (
    <ButtonsWrapper>
      {TIME_BUTTONS.map(item => (
        <TimeButton
          key={item.key}
          isActive={item.key === isActiveKey}
          label={intl.formatMessage(messages[item.key])}
          onClick={() => onPredefinedDateSelect(item)}
        />
      ))}
      <StyledRangePicker
        isActive={isActiveKey === CUSTOM_SELECTOR_KEY}
        popupClassName="range-picker"
        // @ts-ignore Refactor later
        onChange={onCustomDateSelect}
        // @ts-ignore Refactor later
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
        getCalendarContainer={triggerNode => triggerNode.parentNode}
        disabledDate={currentMoment => {
          // @ts-ignore Refactor later
          const current = dayjs(currentMoment);
          return current && current.isAfter(dayjs().endOf('day'));
        }}
      />
    </ButtonsWrapper>
  );
};

const messages = defineMessages({
  last24Hours: {
    defaultMessage: '24 hours',
  },
  last72Hours: {
    defaultMessage: '72 hours',
  },
  last7days: {
    defaultMessage: '7 days',
  },
  custom: {
    defaultMessage: 'Custom',
  },
});

export default injectIntl(TimeIntervalSelector);
