import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import dataActions from 'src/redux/data/dataActions';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { selectors as idbSelectors } from '../../modules/dashboard';
import { actions, selectors } from '../../modules/statistics';
import { selectors as patientsSelectors } from 'src/redux/data/patient';

const mapStateToProps = (state: RootState) => ({
  patientId: idbSelectors.getCurrentPatientId(state),
  data: selectors.getPatientDateTimeGraphsPoints(state)?.graphs,
  timezone: loggedInUserSelectors.getUserTenantTimezone(state),
  isStatisticsPageLoading: patientsSelectors.isPatientListLoading(state),
});

const mapActionCreators = {
  onLoadStatistics: dataActions.onLoadStatistics,
  clearStatisticsData: actions.clearStatisticsData,
  viewPatientDateTimeGraphs: actions.viewPatientDateTimeGraphs,
  onChangeSelectedDate: actions.onChangeSelectedDate,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
