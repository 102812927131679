import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { BoxWrapper, BoxTitle, BoxContent, BoxValue } from '../styled';

type Props = {
  dataPointsCount: number;
};

const DataPointsBox = ({ dataPointsCount }: Props): JSX.Element => (
  <BoxWrapper>
    <BoxTitle>
      <FormattedMessage {...messages.boxTitle} />
    </BoxTitle>
    <BoxContent>
      <BoxValue>{dataPointsCount.toLocaleString()}</BoxValue>
    </BoxContent>
  </BoxWrapper>
);

const messages = defineMessages({
  boxTitle: {
    defaultMessage: 'Data points',
  },
});

export default DataPointsBox;
