import styled from 'styled-components';

export const StyledWrapper = styled.div`
  padding: 0.5rem;
  width: 10rem;
`;

export const StyledTitle = styled.div`
  margin-bottom: 0.8rem;
  font-weight: 600;
  font-size: 0.6rem;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const StyledOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 0.65rem;
`;

export const StyledCheckbox = styled.input`
  width: 1rem;
  height: 1rem;
  accent-color: #304e64;
  margin-right: 0.75rem;
  cursor: pointer;
`;

export const StyledFooter = styled.div`
  margin-top: 0.8rem;
`;

export const StyledResetBtn = styled.span`
  font-weight: 500;
  font-size: 0.65rem;
  color: #767881;
  cursor: pointer;
`;
