import { styled } from 'styled-components';
import { Layout, Input } from 'antd';

import { media } from 'src/utils/mediaQueryUtils';

const { Search } = Input;

export const StyledLayout: typeof Layout = styled(Layout)`
  min-height: 100vh;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SpacedRow = styled(Row)`
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
  color: ${props => props.theme.colors.defaultTextColor};
`;

export const MainTitle = styled(Title)`
  font-size: 1.4rem;
  margin-bottom: 1.4rem;
`;

export const SubTitle = styled(Title)`
  font-size: 1rem;
`;

export const Searchbar = styled(Search)`
  width: ${props => props.width};
  input {
    border-radius: 0.2rem 0 0 0.2rem !important;
  }

  .ant-btn {
    border-radius: 0 0.2rem 0.2rem 0 !important;
  }
`;
export const DarkChartBox = styled.div`
  canvas {
    background-color: ${props => props.theme.colors.graphBackground};
    width: 100% !important;
  }
`;
export const WideWrapper = styled.div`
  ${media.extraSmall`
    display: none;
  `}
`;

export const NarrowWrapper = styled.div`
  ${media.small`
    display: none;
  `}
`;

export const SquareListIcon = styled.div`
  float: left;
  height: 10px;
  width: 10px;
  clear: both;
  margin: 5px;
  background-color: ${props => props.color};
`;

export const BoldItem = styled.span`
  font-weight: bold;
`;

export const ThinItem = styled.span`
  font-weight: 400;
`;

export const PageWrapper = styled.div`
  padding: 1.3rem;
`;

export const InfoFieldsWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  p {
    padding-left: 0.3rem;
  }
`;
