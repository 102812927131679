import React, { Dispatch, SetStateAction } from 'react';

import BaselineAlertSettingsForm, {
  TenantBaselineThresholdsState,
} from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';
import { deepEqual } from 'src/utils/comparators';

type Props = {
  // setOnSave: (onSave: () => void) => void;
  setIsSaveBtnDisabled: (isDisabled: boolean) => void;
  baselineThresholdsData: TenantBaselineThresholdsState;
  setBaselineThresholdData: Dispatch<
    SetStateAction<TenantBaselineThresholdsState>
  >;
};

const BaselineThresholdSettings = ({
  baselineThresholdsData,
  setBaselineThresholdData,
  setIsSaveBtnDisabled,
}: Props): JSX.Element => {
  return (
    <BaselineAlertSettingsForm
      baselineThresholds={baselineThresholdsData}
      setBaselineThresholdData={setBaselineThresholdData}
      setHasErrors={setIsSaveBtnDisabled}
    />
  );
};

export default React.memo(BaselineThresholdSettings, (oldProps, newProps) =>
  deepEqual(oldProps, newProps),
);
