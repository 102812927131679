import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import {
  actions as individualDashboardActions,
  selectors as individualDashboardSelectors,
} from 'src/routes/IndividualDashboard/modules/dashboard/slice';

const mapStateToProps = (state: RootState) => ({
  histogramData: individualDashboardSelectors.selectHistogramData(state),
  patient: individualDashboardSelectors.getPatientDetails(state),
  isHistogramDataLoading:
    individualDashboardSelectors.isHistogramDataLoading(state),
});

const mapActionCreators = {
  getHistogramData: individualDashboardActions.getHistogramData,
  clearHistogramData: individualDashboardActions.clearHistogramData,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
