import React from 'react';

import { StyledTimeBtn } from './styled';

type Props = {
  label: string;
  isActive: boolean;
  onClick: () => void;
};

const TimeButton = ({ label, isActive, onClick }: Props): JSX.Element => (
  <StyledTimeBtn isActive={isActive} onClick={onClick}>
    {label}
  </StyledTimeBtn>
);

export default TimeButton;
