import React from 'react';
import styled, { css } from 'styled-components';

import { media } from 'src/utils/mediaQueryUtils';
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from 'react-intl';

export const ADDITIONAL_CONTENT_POSITION = Object.freeze({
  END: 'END',
  START: 'START',
});

const StyledContent = styled.div`
  display: flex;
`;
const Container = styled.div`
  margin: 0% 10% 0 10%;
  gap: 30px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
`;
const FormContent = styled.div`
  flex: 1.5;
`;
const additionalContentPositionCss = css`
  align-self: flex-end;
  text-align: end;
`;

const FormAdditionalContent = styled.div<{
  place: keyof typeof ADDITIONAL_CONTENT_POSITION;
}>`
  flex: 1;
  ${props =>
    props.place === ADDITIONAL_CONTENT_POSITION.END &&
    additionalContentPositionCss}
`;
const StylesTitle = styled.div`
  ${media.extraSmall`
    font-size: 90%;
  `}
  font-size: 20px;
`;

type Props = {
  formComponent: React.ReactNode;
  additionalContentComponent?: React.ReactNode;
  additionalContentPosition?: keyof typeof ADDITIONAL_CONTENT_POSITION;
  innerTitle?: MessageDescriptor;
};

const PasswordLayout = ({
  formComponent,
  additionalContentComponent = null,
  additionalContentPosition = ADDITIONAL_CONTENT_POSITION.START,
  innerTitle = messages.innerTitle,
}: Props): JSX.Element => (
  <Container>
    <StylesTitle data-cy="password-layout-inner-title">
      <FormattedMessage {...innerTitle} />
    </StylesTitle>
    <StyledContent>
      <FormContent>
        {formComponent}
        <FormAdditionalContent place={additionalContentPosition}>
          {additionalContentComponent}
        </FormAdditionalContent>
      </FormContent>
    </StyledContent>
  </Container>
);

const messages = defineMessages({
  innerTitle: {
    defaultMessage:
      'Set up your password to begin using Neteera HealthGate cloud platform.',
  },
});

export default PasswordLayout;
