import { defineMessages } from 'react-intl';

export const alertsMessages = defineMessages({
  createAlertsError: {
    defaultMessage: 'Could not create Alerts Thresholds',
  },
  createAlertsSuccess: {
    defaultMessage:
      'Successfully create Alerts Thresholds, selected alert values will apply within up to one minute',
  },
  editAlertsSuccess: {
    defaultMessage:
      'Successfully update Alerts Thresholds, selected alert values will apply within up to one minute',
  },
  editAlertsError: {
    defaultMessage: 'Could not update Alerts Thresholds',
  },
  settingsError: {
    defaultMessage: 'Could not update settings',
  },
  updateSMSEmailAlertSettingsSuccess: {
    defaultMessage: 'Successfully update SMS/Email alerts settings',
  },
});
