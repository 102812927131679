import { actions as userDataActions } from 'src/redux/data/user/modules/slice';
import { actions as gmSettingsActions } from 'src/routes/GMSettings/modules/slice';
import { intl, messages } from '../messages';

export const userErrorCodes = {
  USERNAME_ALREADY_EXIST: 'USERNAME_ALREADY_EXIST',
};
export const userActionToErrorNotificationMessagesMap = {
  [userDataActions.deleteUser.type]: (data, errorCode) => ({
    message: intl.formatMessage(messages.deleteUserError),
    description: intl.formatMessage(messages.deleteUserError),
  }),
  [userDataActions.updateUser.type]: (data, _) => ({
    message: intl.formatMessage(messages.updateUserError),
    description: `${intl.formatMessage(messages.updateUserError)} ${
      data.firstName
    } ${data.lastName}`,
  }),
  [userDataActions.updateMfaUserInfo.type]: () => ({
    message: intl.formatMessage(messages.updateMfaUserError),
    description: `${intl.formatMessage(messages.updateMfaUserErrorDescription)}`,
  }),
  [userDataActions.inviteUser.type]: (data, errorCode) => {
    const notificationMessageForErrorCode = {
      [userErrorCodes.USERNAME_ALREADY_EXIST]: {
        message: intl.formatMessage(messages.inviteUserError),
        description: `${intl.formatMessage(
          messages.inviteUserNameAlreadyExist,
        )} ${data.firstName} ${data.lastName}`,
      },
    };
    const notificationMessageForErrorCodeHandler = {
      get(mapTarget, error) {
        return (
          mapTarget[error] || {
            message: intl.formatMessage(messages.inviteUserError),
            description: `${intl.formatMessage(messages.inviteUserError)} ${
              data.firstName
            } ${data.lastName}`,
          }
        );
      },
    };
    const proxyNotificationMessageForErrorCode = new Proxy(
      notificationMessageForErrorCode,
      notificationMessageForErrorCodeHandler,
    );
    return proxyNotificationMessageForErrorCode[errorCode];
  },
  [userDataActions.sendMessageForResetPassword.type]: (data, __) => ({
    message: intl.formatMessage(messages.sendingResetPasswordEmailFailed),
    description: `${intl.formatMessage(
      messages.sendingResetPasswordEmailFailedDescription,
    )} ${data?.email}`,
  }),
  [userDataActions.resendInvitation.type]: (data, __) => ({
    message: intl.formatMessage(messages.resendInvitationError),
    description: `${intl.formatMessage(messages.resendInvitationError)} ${
      data.firstName
    } ${data.lastName}`,
  }),

  [userDataActions.deleteInvitation.type]: (data, errorCode) => ({
    message: intl.formatMessage(messages.deleteInvitationError),
    description: `${intl.formatMessage(
      messages.deleteInvitationError,
    )} ${data}`,
  }),
};

export const userActionToSuccessNotificationMessagesMap = {
  [userDataActions.inviteUser.type]: data => ({
    message: intl.formatMessage(messages.inviteUserSuccess),
    description: `${data.firstName} ${data.lastName} ${intl.formatMessage(
      messages.inviteUserSuccess,
    )}`,
  }),
  [userDataActions.updateUserPhone.type]: () => ({
    message: intl.formatMessage(messages.updatePhoneNumberSuccess),
  }),
  [userDataActions.sendMessageForResetPassword.type]: _ => ({
    message: intl.formatMessage(messages.sendingResetPasswordEmailSuccess),
    description: intl.formatMessage(
      messages.sendingResetPasswordEmailSuccessDescription,
    ),
  }),
  [userDataActions.resendInvitation.type]: data => ({
    message: intl.formatMessage(messages.resendInvitationSuccess),
    description: `${data.firstName} ${data.lastName} ${intl.formatMessage(
      messages.resendInvitationSuccess,
    )}`,
  }),
};

export const gmSettingsToErrorNotificationMessagesMap = {
  [gmSettingsActions.deleteUserByTenantId.type]: () => ({
    message: intl.formatMessage(messages.deleteUserError),
    description: intl.formatMessage(messages.deleteContactTenantError),
  }),
  [gmSettingsActions.deleteInvitationByTenantId.type]: () => ({
    message: intl.formatMessage(messages.deleteUserError),
    description: intl.formatMessage(messages.deleteContactTenantError),
  }),
};
