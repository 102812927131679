import {
  HriMessage,
  Quality,
  SpotMessage,
  Vs1Message,
  Vs2Message,
  Vs3Message,
  Vs4Message,
} from './types';

export const isQuality = (qualityVS: Quality) =>
  qualityVS === undefined ? true : qualityVS === 1;

export const toFixedNumber = (value: number) =>
  value > 0 ? String(Number(value).toFixed(1)) : String(value);

export const extractVs1Data = (vsData: Vs1Message) => {
  const { pid, sid, data } = vsData;
  const { vs1, state } = data;
  const { hrt, rsp } = vs1;
  const { hr } = hrt;
  const { rr, ra, rabin } = rsp;

  return {
    patientId: pid,
    sessionId: sid,
    state,
    hr,
    rr,
    ra,
    rabin,
  };
};

export const extractVs2Data = (vsData: Vs2Message) => {
  const { pid, sid, data } = vsData;
  const { vs2, state } = data;
  const { hrt, rsp } = vs2;
  const { hri } = hrt;
  const { rr, avdur } = rsp;
  const { i, e } = avdur;

  return {
    patientId: pid,
    sessionId: sid,
    state,
    hri,
    rr,
    i,
    e,
  };
};

export const extractVs3Data = (vsData: Vs3Message) => {
  const { pid, sid, data } = vsData;
  const { vs, state } = data;
  const { hrt, rsp } = vs;
  const { hr } = hrt;
  const { rr, ra, rabin, ie } = rsp;

  return {
    patientId: pid,
    sessionId: sid,
    state,
    hr,
    rr,
    ra,
    rabin,
    ie,
  };
};

export const extractVs4Data = (vsData: Vs4Message) => {
  const { pid, sid, data } = vsData;
  const { length } = data;

  const { array } = data;

  return array.map(msg => {
    const { vs, state } = msg;
    const { hrt, rsp } = vs;
    const { hr } = hrt;
    const { rr, ra, rabin, ie } = rsp;

    return {
      patientId: pid,
      sessionId: sid,
      state,
      hr,
      rr,
      ra,
      rabin,
      ie,
      length,
    };
  });
};

export const extractSpotData = (spotData: SpotMessage) => {
  const { pid, sid, data } = spotData;
  const { vs, state } = data;
  const { hrt, rsp } = vs;

  return {
    patientId: pid,
    sessionId: sid,
    state,
    hrt,
    rsp,
  };
};

export const extractHriData = (hriData: HriMessage) => {
  const { pid, sid, data } = hriData;
  const { sdnn, rmssd, statusCode, error } = data;

  return {
    patientId: pid,
    sessionId: sid,
    sdnn,
    rmssd,
    statusCode,
    error,
  };
};
