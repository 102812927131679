import { connect } from 'react-redux';
import {
  actions as patientActions,
  selectors as patientSelectors,
} from 'src/redux/data/patient/modules/slice';
import Component from '../components/Header';
import { actions, selectors } from '../modules/slice';
import { selectors as deviceSelectors } from '../../../redux/data/device';
import { selectors as monitorSelectors } from '../../../redux/data/monitor';

const mapStateToProps = state => {
  const selectedDeviceId = selectors.getDevice(state)?.manufacturerId;
  return {
    isDeviceConnected: deviceSelectors.getIsDeviceConnected(
      state,
      selectedDeviceId,
    ),
    searchFocusStatus: selectors.getSearchFocusStatus(state),
    shouldStart:
      monitorSelectors.getIsActive(state, selectedDeviceId) ||
      monitorSelectors.getIsStartContinuousLoading(state, selectedDeviceId) ||
      monitorSelectors.getIsStopContinuousLoading(state, selectedDeviceId),
  };
};

const mapActionCreators = {
  getPatients: patientActions.fetchAllPatients,
  onStartWithNoPatient: actions.setSearchFocusStatus,
};

export default connect(mapStateToProps, mapActionCreators)(Component);
