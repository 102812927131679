type StringFilter<T> = (value: string, record: T) => boolean;

export const stringFilter =
  <T>(stringFilter: StringFilter<T>) =>
  (value: string | number | boolean, record: T) => {
    if (typeof value !== 'string') {
      return false;
    }

    return stringFilter(value, record);
  };
