import React from 'react';
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
  IntlShape,
} from 'react-intl';
import { Form, Input } from 'antd';

import {
  tokenStatusEnum,
  TOKEN_STATUS_COMPONENT_MAPPER,
} from 'src/utils/constants';
import { FormItem, AuthButton } from '../../routes/Auth/components/styled';
import PasswordLayout from './PasswordLayout';
import { StyledInstructions, StyledListInstructions } from './styled';
import { fieldNames, DEFAULT_VALIDATION_REGEX } from './constants';
import queryString, { IParseOptions } from 'qs';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { phoneCountry, validatePhoneNumber } from './utils';

type Props = {
  status: keyof typeof tokenStatusEnum;
  statusComponentMapper: ReturnType<typeof TOKEN_STATUS_COMPONENT_MAPPER>;
  onFinish: (values: { password: string; phone?: string }) => void;
  isLoading: boolean;
  isDisabled: boolean;
  submitMessage: MessageDescriptor;
  validationRegex?: RegExp;
  intl: IntlShape;
  decoder?: IParseOptions['decoder'];
};

const renderComponentByToken = (
  status: keyof typeof tokenStatusEnum,
  statusComponentMapper: ReturnType<typeof TOKEN_STATUS_COMPONENT_MAPPER>,
): JSX.Element | null => {
  if (
    status === tokenStatusEnum.LOADING ||
    status === tokenStatusEnum.VALIDATED
  ) {
    return null;
  }

  return statusComponentMapper[status];
};

const PasswordForm = ({
  onFinish,
  status,
  statusComponentMapper,
  isDisabled,
  isLoading,
  validationRegex = DEFAULT_VALIDATION_REGEX,
  intl,
  submitMessage,
  decoder = val => val,
}: Props): JSX.Element => {
  const { mfa_enabled } = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
    decoder,
  });
  return (
    <PasswordLayout
      formComponent={
        // @ts-ignore Disabled is not a prop of form, to be tested and validated
        <Form layout="vertical" onFinish={onFinish} disabled={isDisabled}>
          <FormItem
            label={intl.formatMessage(messages.newPassword)}
            name={fieldNames.passwordField}
            hasFeedback
            rules={[
              {
                required: true,
                message: intl.formatMessage(messages.requiredPassword),
              },
              {
                pattern: validationRegex,
                message: intl.formatMessage(messages.invalidPassword),
              },
            ]}
          >
            <Input.Password disabled={isDisabled} />
          </FormItem>
          <FormItem
            label={intl.formatMessage(messages.confirmNewPassword)}
            name={fieldNames.confirmPasswordField}
            dependencies={[fieldNames.passwordField]}
            hasFeedback
            rules={[
              {
                required: true,
                message: intl.formatMessage(messages.pleaseConfirmPassword),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !value ||
                    getFieldValue(fieldNames.passwordField) === value
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(intl.formatMessage(messages.passwordsNotMatch)),
                  );
                },
              }),
            ]}
          >
            <Input.Password disabled={isDisabled} />
          </FormItem>
          {mfa_enabled === 'true' && (
            <FormItem
              label={intl.formatMessage(messages.insertPhoneNo)}
              name={fieldNames.mfaPhone}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(messages.requiredPhoneNo),
                },
              ]}
            >
              <PhoneInput
                inputStyle={{ width: '100%' }}
                placeholder={intl.formatMessage(messages.phonePlaceholder)}
                // @ts-ignore Ignore for now
                autoComplete="off"
                isValid={(value, country, countries) =>
                  validatePhoneNumber(
                    value,
                    country as phoneCountry,
                    countries as phoneCountry[],
                  )
                }
              />
            </FormItem>
          )}
          <Form.Item>
            <AuthButton disabled={isDisabled} isLoading={isLoading}>
              <FormattedMessage {...submitMessage} />
            </AuthButton>
          </Form.Item>
          {renderComponentByToken(status, statusComponentMapper)}
        </Form>
      }
      additionalContentComponent={
        <StyledInstructions>
          <FormattedMessage {...messages.passwordInstructions} />
          <StyledListInstructions>
            <li>
              <FormattedMessage {...messages.upperCaseInstructions} />
            </li>
            <li>
              <FormattedMessage {...messages.lowerCaseInstructions} />
            </li>
            <li>
              <FormattedMessage {...messages.numbersInstructions} />
            </li>
            <li>
              <FormattedMessage {...messages.specialInstructions} />
            </li>
          </StyledListInstructions>
        </StyledInstructions>
      }
    />
  );
};

const messages = defineMessages({
  newPassword: {
    defaultMessage: 'New Password',
  },
  confirmNewPassword: {
    defaultMessage: 'Confirm New Password',
  },
  errorText: {
    defaultMessage: 'Could not reset password',
  },
  requiredPassword: {
    defaultMessage: 'Password is required',
  },
  pleaseConfirmPassword: {
    defaultMessage: 'Please confirm your password!',
  },
  requiredPhoneNo: {
    defaultMessage: 'Phone number is required!',
  },
  passwordsNotMatch: {
    defaultMessage: 'The two passwords that you entered do not match!',
  },
  insertPhoneNo: {
    defaultMessage: 'Insert Phone Number',
  },
  invalidPassword: {
    defaultMessage: 'Invalid password',
  },
  passwordInstructions: {
    defaultMessage:
      'Password must be 12 characters long and should contain the following:',
  },
  phonePlaceholder: {
    defaultMessage: 'Phone Number',
  },
  upperCaseInstructions: {
    defaultMessage: 'Upper characters (A-Z)',
  },
  lowerCaseInstructions: {
    defaultMessage: 'Lower characters (a-z)',
  },
  numbersInstructions: {
    defaultMessage: 'Numbers (0-9)',
  },
  specialInstructions: {
    defaultMessage: 'Special characters (!@#$%)',
  },
});

export default injectIntl(PasswordForm);
