import { all, call, put, select, takeLatest } from 'typed-redux-saga/macro';

import { actions, getState } from './slice';
import { DATA_FETCHING_STATUS } from '../../constants';
import BackendService from 'src/services/BackendService';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';

function* getCustomersList() {
  try {
    yield* put(actions.setCustomersStatus(DATA_FETCHING_STATUS.LOADING));
    const { data } = yield* call(BackendService.getCustomers);

    yield* put(
      actions.setCustomersList({
        data: data?.customers,
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
  } catch (e) {
    console.error('error in fetchCustomersList: ', e);
    yield* put(
      actions.setCustomersList({
        data: null,
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
  }
}

function* createCustomer(action: ReturnType<typeof actions.createCustomer>) {
  const { customerForm } = yield* select(getState);

  try {
    yield* put(actions.setFormStatus(DATA_FETCHING_STATUS.LOADING));
    const newCustomer = yield* call(
      BackendService.createCustomer,
      customerForm,
    );
    yield* all([
      put(actions.setFormStatus(DATA_FETCHING_STATUS.SUCCESS)),
      put(actions.resetFormAndCurrentCustomer()),
    ]);
    if (newCustomer.data.emrMappings)
      yield* put(actions.setCurrentCustomer(newCustomer.data));
    yield* call(getCustomersList);
  } catch (e) {
    console.error('error in fetchCusomtersList: ', e);
    notifyUserByActionTypeAndCode(action.type, e, e);
  }
}

function* editCustomer(action: ReturnType<typeof actions.editCustomer>) {
  const { customerForm, currentCustomer } = yield* select(getState);

  const customerId = currentCustomer?.id;

  try {
    yield* put(actions.setFormStatus(DATA_FETCHING_STATUS.LOADING));
    if (customerForm && customerId)
      yield* call(BackendService.updateCustomer, customerId, customerForm);
    yield* all([
      put(actions.setFormStatus(DATA_FETCHING_STATUS.SUCCESS)),
      put(actions.resetFormAndCurrentCustomer()),
    ]);
    yield* call(getCustomersList);
  } catch (e) {
    console.error('error in fetchCusomtersList: ', e);
    notifyUserByActionTypeAndCode(action.type, e, e);
  }
}

function* deleteCustomer(
  action: ReturnType<typeof actions.deleteCustomerById>,
) {
  const customerId = action.payload;
  try {
    yield* call(BackendService.deleteCustomer, customerId);
    yield* call(getCustomersList);
  } catch (e) {
    console.error('error in fetchCusomtersList: ', e);
    notifyUserByActionTypeAndCode(action.type, e, e);
  }
}

export default function* watchCusomterActions() {
  yield* all([
    takeLatest(actions.getCustomersList, getCustomersList),
    takeLatest(actions.createCustomer, createCustomer),
    takeLatest(actions.editCustomer, editCustomer),
    takeLatest(actions.deleteCustomerById, deleteCustomer),
  ]);
}
