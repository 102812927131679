import React from 'react';

import { SVGProps } from '../types';

const WifiDisconnectedAlertIcon = ({
  width = '26',
  height = '18',
  color = '#CC3311',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <mask
      id="path-1-outside-1_1445_18408"
      maskUnits="userSpaceOnUse"
      x="-0.603516"
      y="-0.0292969"
      width="21"
      height="7"
      fill="black"
    >
      <rect fill="white" x="-0.603516" y="-0.0292969" width="21" height="7" />
      <path d="M0.533479 5.0066C0.35082 5.18926 0.35082 5.48886 0.533479 5.67249C0.716137 5.85514 1.01573 5.85514 1.19936 5.67249C6.21241 0.659436 14.3691 0.659436 19.3841 5.67249C19.4759 5.7643 19.5967 5.81069 19.7185 5.81069C19.8393 5.81069 19.9611 5.76333 20.0529 5.67249C20.2356 5.48983 20.2356 5.19023 20.0529 5.0066C14.6687 -0.374596 5.91458 -0.374596 0.53338 5.0066H0.533479Z" />
    </mask>
    <path
      d="M0.533479 5.0066C0.35082 5.18926 0.35082 5.48886 0.533479 5.67249C0.716137 5.85514 1.01573 5.85514 1.19936 5.67249C6.21241 0.659436 14.3691 0.659436 19.3841 5.67249C19.4759 5.7643 19.5967 5.81069 19.7185 5.81069C19.8393 5.81069 19.9611 5.76333 20.0529 5.67249C20.2356 5.48983 20.2356 5.19023 20.0529 5.0066C14.6687 -0.374596 5.91458 -0.374596 0.53338 5.0066H0.533479Z"
      fill="#CC3311"
    />
    <path
      d="M0.533479 5.0066C0.35082 5.18926 0.35082 5.48886 0.533479 5.67249C0.716137 5.85514 1.01573 5.85514 1.19936 5.67249C6.21241 0.659436 14.3691 0.659436 19.3841 5.67249C19.4759 5.7643 19.5967 5.81069 19.7185 5.81069C19.8393 5.81069 19.9611 5.76333 20.0529 5.67249C20.2356 5.48983 20.2356 5.19023 20.0529 5.0066C14.6687 -0.374596 5.91458 -0.374596 0.53338 5.0066H0.533479Z"
      stroke="#CC3311"
      strokeWidth="0.4"
      mask="url(#path-1-outside-1_1445_18408)"
    />
    <mask
      id="path-2-outside-2_1445_18408"
      maskUnits="userSpaceOnUse"
      x="2.20703"
      y="3.93652"
      width="16"
      height="5"
      fill="black"
    >
      <rect fill="white" x="2.20703" y="3.93652" width="16" height="5" />
      <path d="M16.9139 8.61677C17.0347 8.61677 17.1565 8.56941 17.2483 8.47856C17.431 8.2959 17.431 7.99631 17.2483 7.81268C13.4134 3.9778 7.17593 3.9778 3.34403 7.81268C3.16137 7.99534 3.16137 8.29494 3.34403 8.47856C3.52668 8.66122 3.82628 8.66122 4.00991 8.47856C7.47664 5.01184 13.1169 5.01184 16.5834 8.47856C16.6713 8.57038 16.7921 8.61677 16.9139 8.61677H16.9139Z" />
    </mask>
    <path
      d="M16.9139 8.61677C17.0347 8.61677 17.1565 8.56941 17.2483 8.47856C17.431 8.2959 17.431 7.99631 17.2483 7.81268C13.4134 3.9778 7.17593 3.9778 3.34403 7.81268C3.16137 7.99534 3.16137 8.29494 3.34403 8.47856C3.52668 8.66122 3.82628 8.66122 4.00991 8.47856C7.47664 5.01184 13.1169 5.01184 16.5834 8.47856C16.6713 8.57038 16.7921 8.61677 16.9139 8.61677H16.9139Z"
      fill={color}
    />
    <path
      d="M16.9139 8.61677C17.0347 8.61677 17.1565 8.56941 17.2483 8.47856C17.431 8.2959 17.431 7.99631 17.2483 7.81268C13.4134 3.9778 7.17593 3.9778 3.34403 7.81268C3.16137 7.99534 3.16137 8.29494 3.34403 8.47856C3.52668 8.66122 3.82628 8.66122 4.00991 8.47856C7.47664 5.01184 13.1169 5.01184 16.5834 8.47856C16.6713 8.57038 16.7921 8.61677 16.9139 8.61677H16.9139Z"
      stroke={color}
      strokeWidth="0.4"
      mask="url(#path-2-outside-2_1445_18408)"
    />
    <mask
      id="path-3-outside-3_1445_18408"
      maskUnits="userSpaceOnUse"
      x="5.01074"
      y="7.90405"
      width="10"
      height="4"
      fill="black"
    >
      <rect fill="white" x="5.01074" y="7.90405" width="10" height="4" />
      <path d="M14.1066 11.4224C14.2274 11.4224 14.3491 11.375 14.441 11.2842C14.6236 11.1015 14.6236 10.8019 14.441 10.6183C12.1553 8.33264 8.43357 8.33264 6.14774 10.6183C5.96508 10.801 5.96508 11.1006 6.14774 11.2842C6.3304 11.4668 6.62999 11.4668 6.81362 11.2842C8.73395 9.36385 11.8556 9.36385 13.776 11.2842C13.864 11.376 13.9848 11.4224 14.1065 11.4224H14.1066Z" />
    </mask>
    <path
      d="M14.1066 11.4224C14.2274 11.4224 14.3491 11.375 14.441 11.2842C14.6236 11.1015 14.6236 10.8019 14.441 10.6183C12.1553 8.33264 8.43357 8.33264 6.14774 10.6183C5.96508 10.801 5.96508 11.1006 6.14774 11.2842C6.3304 11.4668 6.62999 11.4668 6.81362 11.2842C8.73395 9.36385 11.8556 9.36385 13.776 11.2842C13.864 11.376 13.9848 11.4224 14.1065 11.4224H14.1066Z"
      fill={color}
    />
    <path
      d="M14.1066 11.4224C14.2274 11.4224 14.3491 11.375 14.441 11.2842C14.6236 11.1015 14.6236 10.8019 14.441 10.6183C12.1553 8.33264 8.43357 8.33264 6.14774 10.6183C5.96508 10.801 5.96508 11.1006 6.14774 11.2842C6.3304 11.4668 6.62999 11.4668 6.81362 11.2842C8.73395 9.36385 11.8556 9.36385 13.776 11.2842C13.864 11.376 13.9848 11.4224 14.1065 11.4224H14.1066Z"
      stroke={color}
      strokeWidth="0.4"
      mask="url(#path-3-outside-3_1445_18408)"
    />
    <mask
      id="path-4-outside-4_1445_18408"
      maskUnits="userSpaceOnUse"
      x="7.86523"
      y="11.1797"
      width="4"
      height="4"
      fill="black"
    >
      <rect fill="white" x="7.86523" y="11.1797" width="4" height="4" />
      <path d="M11.7163 13.6042C11.7163 14.3919 11.0774 15.0297 10.2907 15.0297C9.50405 15.0297 8.86523 14.3919 8.86523 13.6042C8.86523 12.8175 9.50405 12.1797 10.2907 12.1797C11.0774 12.1797 11.7163 12.8175 11.7163 13.6042Z" />
    </mask>
    <path
      d="M11.7163 13.6042C11.7163 14.3919 11.0774 15.0297 10.2907 15.0297C9.50405 15.0297 8.86523 14.3919 8.86523 13.6042C8.86523 12.8175 9.50405 12.1797 10.2907 12.1797C11.0774 12.1797 11.7163 12.8175 11.7163 13.6042Z"
      fill={color}
    />
    <path
      d="M11.7163 13.6042C11.7163 14.3919 11.0774 15.0297 10.2907 15.0297C9.50405 15.0297 8.86523 14.3919 8.86523 13.6042C8.86523 12.8175 9.50405 12.1797 10.2907 12.1797C11.0774 12.1797 11.7163 12.8175 11.7163 13.6042Z"
      stroke={color}
      strokeWidth="0.2"
      mask="url(#path-4-outside-4_1445_18408)"
    />
    <circle cx="18.793" cy="11" r="6.5" fill="#FDEDE9" stroke={color} />
    <mask
      id="mask0_1445_18408"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="11"
      y="4"
      width="15"
      height="14"
    >
      <circle cx="18.793" cy="11" r="6.5" fill="#FFAD0D" stroke="#252525" />
    </mask>
    <g mask="url(#mask0_1445_18408)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7928 7.5C19.1794 7.5 19.4928 7.86562 19.4928 8.31667V11.5833C19.4928 12.0344 19.1794 12.4 18.7928 12.4C18.4062 12.4 18.0928 12.0344 18.0928 11.5833V8.31667C18.0928 7.86562 18.4062 7.5 18.7928 7.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4928 13.8001C19.4928 13.4135 19.1794 13.1001 18.7928 13.1001C18.4062 13.1001 18.0928 13.4135 18.0928 13.8001C18.0928 14.1867 18.4062 14.5001 18.7928 14.5001C19.1794 14.5001 19.4928 14.1867 19.4928 13.8001Z"
        fill={color}
      />
    </g>
  </svg>
);

export default WifiDisconnectedAlertIcon;
