import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';

import { RootState } from 'src/redux/store';
import { StatisticsMeasurement } from 'src/types/individual-dashboard';
import { UUID } from 'src/types/utility';
import { API_STATUS } from 'src/utils/api-constants';
import {
  METRIC_ENUM,
  digitsRoundByMetricType,
} from 'src/utils/graphsUtils/graphConstants';
import { splitDataPointsListToMultipleListsWithTimezone } from 'src/utils/graphsUtils/graphsParser';
import { GraphState, StatisticsMetricGraphType } from './types';
import { emptyMetricObject, initializedGraphsObject } from './utils';

export const STATE_KEY = 'statistics';

export const INITIAL_STATE: {
  dateTimeGraphs: {
    startDateTime: string | Dayjs;
    endDateTime: string | Dayjs;
    graphs: {
      HR: GraphState;
      RR: GraphState;
      IE: GraphState;
      RA_bin: GraphState;
    };
  };
} = {
  dateTimeGraphs: {
    startDateTime: '',
    endDateTime: '',
    graphs: { ...initializedGraphsObject },
  },
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    viewPatientDateTimeGraphs: (
      state,
      action: PayloadAction<{
        patientId: UUID;
        startTime: Dayjs;
        endTime: Dayjs;
        metrics: StatisticsMetricGraphType[];
      }>,
    ) => {
      const { startTime, endTime } = action.payload;

      if (startTime && endTime) {
        state.dateTimeGraphs = {
          startDateTime: startTime,
          endDateTime: endTime,
          graphs: { ...initializedGraphsObject },
        };
      }
    },
    getDateTimeGraphPointsSuccess: (
      state,
      action: PayloadAction<{
        patientId: UUID;
        startTime: string;
        endTime: string;
        metric: StatisticsMetricGraphType;
        data: StatisticsMeasurement[];
        timezone: string;
      }>,
    ) => {
      const { metric, data, timezone } = action.payload;
      const newData: GraphState = {
        data: splitDataPointsListToMultipleListsWithTimezone(
          data,
          timezone,
          digitsRoundByMetricType[metric],
        ),
        status: API_STATUS.OK,
      };

      state.dateTimeGraphs = {
        ...state.dateTimeGraphs,
        graphs: {
          ...state.dateTimeGraphs.graphs,
          [metric]: newData,
        },
      };
    },
    getDateTimeGraphPointsFail: (
      state,
      action: PayloadAction<{
        patientId: UUID;
        startTime: string;
        endTime: string;
        metric: StatisticsMetricGraphType;
      }>,
    ) => {
      const { metric } = action.payload;
      const newData: GraphState = {
        ...emptyMetricObject,
        status: API_STATUS.ERROR,
      };

      state.dateTimeGraphs = {
        ...state.dateTimeGraphs,
        graphs: {
          ...state.dateTimeGraphs.graphs,
          [metric]: newData,
        },
      };
    },
    onChangeSelectedDate: state => {
      state.dateTimeGraphs.graphs =
        reducerHelperFunctions.getGraphsWithLoadingStatus(
          state.dateTimeGraphs.graphs,
        );
    },
    clearStatisticsData: state => {
      state.dateTimeGraphs = { ...INITIAL_STATE.dateTimeGraphs };
    },
  },
});

const reducerHelperFunctions = {
  getGraphsWithLoadingStatus: (
    graphs: (typeof INITIAL_STATE)['dateTimeGraphs']['graphs'],
  ) => ({
    [METRIC_ENUM.RR]: {
      ...graphs[METRIC_ENUM.RR],
      status: API_STATUS.LOADING,
    },
    [METRIC_ENUM.HR]: {
      ...graphs[METRIC_ENUM.HR],
      status: API_STATUS.LOADING,
    },
    [METRIC_ENUM.IE]: {
      ...graphs[METRIC_ENUM.IE],
      status: API_STATUS.LOADING,
    },
    [METRIC_ENUM.RA_bin]: {
      ...graphs[METRIC_ENUM.RA_bin],
      status: API_STATUS.LOADING,
    },
  }),
};

const getState = (state: RootState) => state[STATE_KEY] || INITIAL_STATE;

export const selectors = {
  getPatientDateTimeGraphsPoints: createSelector(
    getState,
    state => state.dateTimeGraphs,
  ),
};

export const actions = {
  ...slice.actions,
};

const { reducer } = slice;
export default reducer;
