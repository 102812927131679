import styled, { css } from 'styled-components';
import { Divider, Select } from 'antd';
import { SpacedRow, Row, SubTitle } from 'src/components/styled';
import { media } from 'src/utils/mediaQueryUtils';
import ActionButton from '../../../components/general-ui/Buttons/ActionButton';
import NegativeActionButton from '../../../components/general-ui/Buttons/NegativeActionButton';

export const HeaderLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  label,
  button {
    font-size: 16px !important;
  }
`;
export const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: ${props =>
      props.theme.layout.defaultBorderRadius} !important;
  }
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: 50px;
`;

export const RadioButtonsArea = styled.div``;

export const ButtonsArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FooterButtonsArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5px;
`;

const MonitorButtonStyle = css`
  font-weight: 600;
  width: 100px;

  :disabled {
    cursor: not-allowed; // FIXME: consider using a less terrifying cursor indicator
  }
`;

export const StartButton = styled(ActionButton)`
  ${MonitorButtonStyle}
`;

export const StopButton = styled(NegativeActionButton)`
  ${MonitorButtonStyle}
`;

export const ContinuousLayout = styled(SpacedRow)`
  overflow: hidden;
  height: calc(100% - 60px);
  background-color: ${props => props.theme.colors.monitorGraphBackground};
`;

export const MonitorsLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const SpotLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
  margin: 20px 0;
`;

export const Title = styled(Row)`
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: ${props => props.theme.colors.monitorPageSubTitleColor};
`;

export const DateLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  margin-top: 4px;
`;

export const TitleRow = styled(SpacedRow)`
  flex: 0;
`;

export const ContentWrapper = styled.div`
  height: 76vh;
  margin-top: -10px;

  .ant-collapse-arrow {
    top: 8px !important;
  }

  .ant-collapse {
    border-radius: 5px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
    margin: 12px;
  }

  .ant-collapse-content-box {
    padding: 0px 20px;
  }
`;

export const ContinuousMeasurementCardsLayout = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
  grid-row-gap: 20px;
`;

export const Card = styled(SpacedRow)`
  border: ${props =>
    `1px solid ${props.theme.colors.spotMeasurementCardBorderColor}`};
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  line-height: 1;
  width: 200px;
  height: 85px;
  background-color: ${props =>
    props.theme.colors.measurementCardBackgroundColor};
`;

export const CardTitle = styled(SubTitle)`
  color: ${props => props.theme.colors.spotMeasurementCardTitleColor};
  font-weight: 500;
  margin-bottom: 0;
  font-size: 14px;
`;

export const StyledRightSideContinuousContent = styled.div`
  width: calc(100% - 520px);
  margin-top: -10px;
  height: calc(100vh - 193px);
  ${media.extraExtraLarge`
    width: calc(100% - 665px);
  `}
`;

export const BedExitLogo = styled.img`
  display: block;
  width: 32px;
  height: 32px;
  margin-right: 5px;
`;

export const BedExitAlertWrapper = styled.div`
  width: 100%;
  display: block;
  border: ${props => `1px solid ${props.theme.colors.hoverColor}`};
  padding: 10px 0;
  height: 100%;
  padding: 16%;
`;

export const BannerBedExitLogo = styled.img`
  display: block;
  width: 250px;
  height: 250px;
  margin: auto;
  /* animation: blinker 1s linear infinite;

  @keyframes blinker {
    100% {
      opacity: 0;
    }
  } */
`;

export const BedExitTitle = styled.div`
  font-size: 50px;
  font-weight: 600;
  color: ${props => props.theme.colors.alertOnColor};
  text-align: center;
  margin-bottom: 8px;
`;
