import Axios from 'axios';

import { ValidateProps, ExecuteProps } from './types';

export const validate = ({
  baseURL,
  entityId,
  operation,
  token,
}: ValidateProps) =>
  Axios.request({
    baseURL,
    url: `v1/tokens/${token}`,
    method: 'GET',
    // @ts-ignore Cannot handle extra params typesafe here.
    requireAuth: false,
    params: { entityId, operation },
  });

export const execute = ({
  baseURL,
  entityId,
  operation,
  token,
  operationData,
}: ExecuteProps) =>
  Axios.request({
    baseURL,
    url: `v1/tokens/${token}`,
    method: 'POST',
    // @ts-ignore Cannot handle extra params typesafe here.
    requireAuth: false,
    params: { entityId, operation },
    data: { operationData },
  });
