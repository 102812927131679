import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { UUID } from 'src/types/utility';
import {
  actions as notesActions,
  selectors as notesSelectors,
} from 'src/redux/data/notes';
import { selectors as userSelectors } from 'src/redux/data/user';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { Note } from 'src/types/notes';
import { User } from 'src/types/users';

type OwnProps = {
  noteId: UUID;
  patientId: UUID;
};

type DisplayNote = Note & {
  createdByName: string;
};

const selectDisplayNote = (
  state: RootState,
  { patientId, noteId }: OwnProps,
): DisplayNote | undefined => {
  const note = notesSelectors.selectPatientNote(state, patientId, noteId);
  // @ts-ignore Delete with users slice migration
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const user = userSelectors.selectUser(state, note?.createdBy) as
    | User
    | undefined;

  const { firstName, lastName } = user || {};

  return (
    note && {
      ...note,
      createdByName: `${firstName || ''} ${lastName || ''}`,
    }
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  noteItem: selectDisplayNote(state, ownProps),
  timezone: loggedInUserSelectors.getUserTenantTimezone(state),
});

const mapActionCreators = {
  fetchPatientNotes: notesActions.fetchPatientNotes,
  createNote: notesActions.createNote,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type Props = PropsFromRedux & OwnProps;

export default Connector;
