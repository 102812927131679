import { connect } from 'react-redux';
import { selectors as deviceDataSelectors } from 'src/redux/data/device';
import { selectors, actions } from 'src/redux/data/managment';

import DataActions from '../../../redux/data/dataActions';
import Component from '../components/Component';

const mapStateToProps = state => ({
  devicesList: deviceDataSelectors.getDevicesList(state),
  deviceId: selectors.getDeviceID(state),
  timeRange: selectors.getTimeRange(state),
  sessionsList: selectors.getSessionsList(state),
  sessionsStatus: selectors.getSessionsStatus(state),
});

const mapActionCreators = {
  onLoadManagment: DataActions.onLoadManagment,
  onSelectDevice: actions.onSelectDevice,
  onSelectTimesTamp: actions.onSelectTimesTamp,
  onApplyDevice: actions.onApplyDevice,
  setRangeTimeForSession: actions.setRangeTimeForSession,
  onDownloadSession: actions.onDownloadSession,
};

export default connect(mapStateToProps, mapActionCreators)(Component);
