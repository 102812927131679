import React from 'react';

import AlertSettings from './AlertSettings';
import DeviceSearch from './DeviceSearch';
import DeviceStatus from './DeviceStatus';
import DeviceLegend from './DeviceLegend';
import { DeviceSettingsWrapper } from './styled';

const DeviceSettings = (): JSX.Element => {
  return (
    <DeviceSettingsWrapper>
      <DeviceSearch />
      <DeviceStatus />
      <AlertSettings />
      <DeviceLegend />
    </DeviceSettingsWrapper>
  );
};

export default React.memo(DeviceSettings);
