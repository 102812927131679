import { actions as appActions } from 'src/redux/data/app/modules/slice';
import { intl, messages } from '../messages';

export const appActionToErrorNotificationMessagesMap = {
  [appActions.appStart.type]: (_, __) => ({
    message: intl.formatMessage(messages.appStartFailedMessage),
    description: intl.formatMessage(messages.appStartFailedDescription),
  }),
  GENERAL: (_, __) => ({
    message: intl.formatMessage(messages.somethingWentWrong),
    description: intl.formatMessage(messages.somethingWentWrong),
  }),
};

export const appActionToSuccessNotificationMessagesMap = {};
