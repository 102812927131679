import { defineMessages } from 'react-intl';

export const SMSEmailAlertsMessages = defineMessages({
  // success
  updatePhoneNumberSuccess: {
    defaultMessage: 'Successfully updated user phone number',
  },
  updateSubscribersListSuccess: {
    defaultMessage: 'Successfully updated SMS/Email settings',
  },

  //error

  validationError: {
    defaultMessage:
      'Validation token missing or expired. Please contact your tenant admin.',
  },
  generalErrorMessage: {
    defaultMessage:
      'Something went wrong. Please try again or contact your admin.',
  },
});
