import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import appConfig from 'src/config/AppConfig';
import TokenOperation from 'src/libs/js-token-operation';
import ControlSMSEmailAlertsComponent from 'src/components/ControlSMSEmailAlerts';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import history from 'src/utils/history';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import { ALERTS_TARGET_MEDIAS } from 'src/utils/constants';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';

const OPERATIONS = {
  GET_USER_ALERTS_DATA: 'get_suppress_info',
  UPDATE_USER_ALERTS_DATA: 'update_suppress_info',
};

const defaultAlertType = ALERT_METRIC_ENUM.HR;

const ControlSMSEmailAlerts = ({ location }) => {
  const [shouldExecute, setShouldExecute] = useState(false);
  const [operationAction, setOperationAction] = useState(
    OPERATIONS.GET_USER_ALERTS_DATA,
  );
  const [requestData, setRequestData] = useState({});
  const [mediaAlertsEnabled, setMediaAlertsEnabled] = useState(false);
  const [suppressedUntil, setSuppressedUntil] = useState('');
  const [suppressDuration, setSuppressDuration] = useState(0);
  const [operationData, setOperationData] = useState();
  const [alertSettingsSuccessPage, setAlertSettingsSuccessPage] =
    useState(false);
  const [initialDataFetchCompleted, setInitialDataFetchCompleted] =
    useState(false);

  useEffect(() => {
    setRequestData({
      [OPERATIONS.GET_USER_ALERTS_DATA]: {
        alertType: defaultAlertType,
        targetMedia: ALERTS_TARGET_MEDIAS.SMS,
      },
    });
    setShouldExecute(true);
  }, []);

  useEffect(() => {
    if (operationData) {
      const formattedCalculatedSuppressUntil = dayjs(
        operationData.suppressStartTime,
      )
        .add(parseInt(operationData.suppressDuration) || 0, 'h')
        .format();
      const formattedSuppressedUntil = operationData.suppressStartTime
        ? formattedCalculatedSuppressUntil
        : null;

      setMediaAlertsEnabled(operationData.mediaAlertsEnabled);
      setSuppressedUntil(formattedSuppressedUntil);
      setSuppressDuration(parseInt(operationData.suppressDuration));
    }
  }, [operationData]);

  const onExecuteSuccess = data => {
    setShouldExecute(false);
    if (operationAction === OPERATIONS.UPDATE_USER_ALERTS_DATA) {
      setAlertSettingsSuccessPage(true);
    } else if (!initialDataFetchCompleted) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { operationData } = data?.data;
      if (operationData) {
        setOperationData(operationData);
        setInitialDataFetchCompleted(true);
      } else {
        setRequestData({
          [OPERATIONS.GET_USER_ALERTS_DATA]: {
            alertType: defaultAlertType,
            targetMedia: ALERTS_TARGET_MEDIAS.EMAIL,
          },
        });
        setShouldExecute(true);
        setInitialDataFetchCompleted(true);
      }
    } else {
      notifyUserByActionTypeAndCode('VALIDATION_ERROR', {});
      history.push({
        pathname: `/${appRoutes.LOGIN}`,
      });
    }
  };

  const onExecuteFail = error => {
    setShouldExecute(false);
    notifyUserByActionTypeAndCode('EXECUTION_FAILED_ERROR', error);
    history.push({
      pathname: `/${appRoutes.LOGIN}`,
    });
  };

  const onValidateFail = error => {
    setShouldExecute(false);
    notifyUserByActionTypeAndCode('VALIDATION_ERROR', error);
    history.push({
      pathname: `/${appRoutes.LOGIN}`,
    });
  };

  const handleApply = (areAlertsActive, suppressOption) => {
    setOperationAction(OPERATIONS.UPDATE_USER_ALERTS_DATA);

    const now = dayjs().format();
    const data = {
      alertType: defaultAlertType,
      targetMedia: operationData.targetMedia,
      mediaAlertsEnabled: areAlertsActive,
      suppressDuration: (suppressOption || 0).toString(),
      suppressStartTime: now,
    };

    setRequestData({ [OPERATIONS.UPDATE_USER_ALERTS_DATA]: data });
    setShouldExecute(true);
  };

  return (
    <>
      <TokenOperation
        baseURL={`${appConfig.API_URL}/alert`}
        shouldExecute={shouldExecute}
        onExecuteSuccess={onExecuteSuccess}
        onExecuteFail={onExecuteFail}
        onValidateFail={onValidateFail}
        body={requestData}
      />
      {alertSettingsSuccessPage ? (
        <FormattedMessage {...messages.successfullyUpdatedAlertSettings} />
      ) : (
        <ControlSMSEmailAlertsComponent
          location={location}
          handleApply={(areAlertsActive, suppressOption) =>
            handleApply(areAlertsActive, suppressOption)
          }
          mediaAlertsEnabled={mediaAlertsEnabled}
          suppressedUntil={suppressedUntil}
          suppressDuration={suppressDuration}
        />
      )}
    </>
  );
};

const messages = defineMessages({
  successfullyUpdatedAlertSettings: {
    defaultMessage: 'Successfully updated alert settings!',
  },
});

ControlSMSEmailAlerts.defaultProps = {
  location: {
    pathname: '',
  },
};

ControlSMSEmailAlerts.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default injectIntl(ControlSMSEmailAlerts);
