import { differenceInYears, parse } from 'date-fns';

export const calculatePatientAge = (
  dateOfBirth: string | undefined,
): string => {
  if (!dateOfBirth) {
    return '-';
  }

  const date = parse(dateOfBirth, 'yyyy-MM-dd', new Date());
  const age = differenceInYears(new Date(), date);

  return age.toString();
};
