import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/redux/store';
import {
  actions as alertActions,
  selectors as alertSelectors,
} from 'src/redux/data/alerts';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { selectors as tenantSelectors } from 'src/redux/data/tenant';
import {
  actions as subscriberActions,
  selectors as subscriberSelectors,
} from 'src/redux/data/subscribers';
import {
  actions as gmActions,
  selectors as gmSelectors,
} from 'src/redux/data/groupManager';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import { mapThresholdsArrayToState } from 'src/components/AlertSettingsComponents/AlertThresholdsForm';
import {
  computeSettingsType,
  getGMSubtenantSubscriptionDetails,
  getGMUserSubscriptionDetails,
  getSubtenantBaselineThresholds,
  getSubtenantThresholds,
} from 'src/routes/GMDashboard/modules/settingsUtils';
import { SubtenantItem } from 'src/routes/GMDashboard/modules/types';
import { mapBaselineThresholdsArrayToState } from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';
import { UUID } from 'src/types/utility';
import { SETTINGS_TYPE } from 'src/routes/GMDashboard/modules/constants';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';

const mapStateToProps = (state: RootState) => {
  const gmUserId = loggedInUserSelectors.getUserId(state);
  const gmTenantId = loggedInUserSelectors.getUserTenantId(state);
  const activityAlertSettingsFromDb =
    alertSelectors.selectActivityAlertSettings(state);
  const allThresholds = alertSelectors.selectThresholds(state);
  const allBaselineThresholds = alertSelectors.selectBaselineThresholds(state);
  const allSubscribers = subscriberSelectors.selectSubscribersList(state);
  const thresholdsDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.ALERT_THRESHOLDS,
  );
  const baselineThresholdsDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.BASELINE_ALERT_THRESHOLDS,
  );

  const gmThresholds = mapThresholdsArrayToState(
    allThresholds.filter(threshold => !thresholdsDict[threshold.id]),
  );
  const gmBaselineThresholds = mapBaselineThresholdsArrayToState(
    allBaselineThresholds.filter(
      threshold => !baselineThresholdsDict[threshold.id],
    ),
  );
  const subscribersDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.SUBSCRIBERS,
  );
  const gmSubscribers = allSubscribers.filter(
    subscriber => !subscribersDict[subscriber.id],
  );
  const gmSubscriptionDetails = getGMUserSubscriptionDetails(
    gmUserId ?? '',
    gmSubscribers,
  );
  const isTenantUnique = (id: UUID) => {
    return !!activityAlertSettingsFromDb?.uniqueChildTenantIds?.includes(id);
  };

  const subtenants: SubtenantItem[] = tenantSelectors
    .getTenantsList(state)
    .map(subtenant => ({
      label: subtenant.name,
      value: subtenant.id,
      type: isTenantUnique(subtenant.id)
        ? SETTINGS_TYPE.UNIQUE
        : computeSettingsType(
            {
              gm: gmThresholds,
              subtenant: getSubtenantThresholds(
                subtenant.id,
                allThresholds,
                thresholdsDict,
              ),
            },
            {
              gm: gmBaselineThresholds,
              subtenant: getSubtenantBaselineThresholds(
                subtenant.id,
                allBaselineThresholds,
                baselineThresholdsDict,
              ),
            },
            {
              gm: gmSubscriptionDetails,
              subtenant: getGMSubtenantSubscriptionDetails(
                gmUserId || '',
                subtenant.id,
                allSubscribers,
                subscribersDict,
              ),
            },
          ),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const isDataLoading =
    alertSelectors.getThresholdsStatus(state) ===
      DATA_FETCHING_STATUS.LOADING ||
    alertSelectors.getBaselineThresholdsStatus(state) ===
      DATA_FETCHING_STATUS.LOADING ||
    subscriberSelectors.getSubscribersStatus(state) ===
      DATA_FETCHING_STATUS.LOADING;

  return {
    gmThresholds,
    allThresholds,
    thresholdsDict,
    gmBaselineThresholds,
    allBaselineThresholds,
    baselineThresholdsDict,
    allSubscribers,
    gmSubscribers,
    gmUserId,
    gmTenantId,
    subtenants,
    gmSubscriptionDetails,
    subscribersDict,
    isDataLoading,
    activityAlertSettingsFromDb,
  };
};

const mapActionCreators = {
  fetchMTMThresholdList: alertActions.getThresholdsList,
  fetchMTMBaselineThresholdList: alertActions.getBaselineThresholdsList,
  fetchMTMSubscribersList: subscriberActions.fetchSubscribersList,
  fetchSubtenantData: gmActions.fetchSubtenantData,
  fetchMTMActivityAlertSettings: alertActions.fetchMTMActivityAlertSettings,
  updateSubscribersList: subscriberActions.updateSubscribersList,
  updateSubtenantSubscribersList: gmActions.updateSubtenantSubscribersList,
  updateMTMActivityAlertSettings: alertActions.updateMTMActivityAlertSettings,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
