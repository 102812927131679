import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { useSelector } from 'react-redux';
import { getLocation } from 'connected-react-router';

import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import { getNavigationLinks } from '../modules/navUtils';
import NavigationLink from './NavigationLink';
import { StyledNavigationMenu } from './styled';
import { UserType } from 'src/types/users';

interface Props {
  intl: IntlShape;
  loggedInUserType?: UserType;
}

const NavigationMenu = ({ intl, loggedInUserType }: Props): JSX.Element => {
  // @ts-ignore TODO: Fix later
  const { pathname } = useSelector(state => getLocation(state));
  const pathParts = pathname.split('/');
  const routingKey =
    pathParts.find(
      // @ts-ignore TODO: Fix later
      part => appRoutes[part.toUpperCase().replace(/-/g, '_')],
    ) || '';

  const navLinks = getNavigationLinks(routingKey, intl, loggedInUserType);

  return (
    <StyledNavigationMenu>
      {navLinks
        .filter(link => !link.isHidden)
        .sort((a, b) => a.order - b.order)
        .map(({ key, route, label, isCurrent }) => (
          <NavigationLink
            id={key}
            key={key}
            route={route}
            label={label}
            isCurrent={isCurrent}
          />
        ))}
    </StyledNavigationMenu>
  );
};

export default injectIntl(NavigationMenu);
