import equal from 'fast-deep-equal';

export const compareAlphanumerics = (
  a?: string | null,
  b?: string | null,
): number => {
  if (a === null || a === undefined) {
    return 1;
  } else if (b === null || b === undefined) {
    return -1;
  }
  return a.localeCompare(b, 'en', { numeric: true });
};

export const deepEqual = (a: unknown, b: unknown): boolean => equal(a, b);
