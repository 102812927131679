import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import styled from 'styled-components';
import Table from 'src/components/CrudCommonComponents/Table';
import { HRI_ACTIONS_KEYS } from 'src/utils/graphsUtils/graphConstants';
import DatesRange from '../../../../components/datesRange/DatesRange';

const StyledDateRange = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Container = styled.div`
  height: 70vh;
`;

const initialState = {
  datesRange: { from: null, to: null },
};

const HRI = ({
  patientId,
  onHriParamsChanged,
  hriData,
  isLoading,
  downloadHri,
  defaultDatesRange,
}) => {
  const intl = useIntl();
  const [datesRange, setDatesRange] = useState(initialState.datesRange);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      hriData.map((rowData, index) => ({
        ...rowData,
        key: `hri-row-${index}`,
      })),
    );
  }, [hriData]);

  useEffect(() => {
    onHriParamsChanged({
      patientId,
      startTime: datesRange.from,
      endTime: datesRange.to,
    });
  }, [onHriParamsChanged, patientId, datesRange]);
  const filterDataByDates = useCallback(
    (from, to) => {
      setDatesRange({ from, to });
    },
    [setDatesRange],
  );
  const columns = [
    {
      title: intl.formatMessage(messages.measurementDate),
      dataIndex: 'startTime',
      key: 'startTime',
      sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime),
      defaultSortOrder: 'descend',
    },
    {
      title: intl.formatMessage(messages.sdnn),
      dataIndex: 'sdnn',
      key: 'sdnn',
      sorter: (a, b) => true,
    },
    {
      title: intl.formatMessage(messages.rmssd),
      dataIndex: 'rmssd',
      key: 'rmssd',
      sorter: (a, b) => true,
    },
  ];
  const actions = [
    {
      key: HRI_ACTIONS_KEYS.DOWNLOAD,
      name: intl.formatMessage(messages.download),
      onClick: (event, { record }) => {
        event.stopPropagation(); // in order to prevent the history modal from opening
        downloadHri(record);
      },
    },
  ];
  return (
    <Container>
      <StyledDateRange>
        <DatesRange
          onApplyPressed={filterDataByDates}
          defaultDatesRange={defaultDatesRange}
        />
      </StyledDateRange>
      <Table
        data={tableData}
        columns={columns}
        actions={actions}
        loading={isLoading}
      />
    </Container>
  );
};
HRI.propTypes = {
  onHriParamsChanged: PropTypes.func.isRequired,
  hriData: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  patientId: PropTypes.string.isRequired,
  downloadHri: PropTypes.func.isRequired,
  defaultDatesRange: PropTypes.object,
};

HRI.defaultProps = {
  hriData: [],
  defaultDatesRange: {
    from: null,
    to: null,
  },
};

const messages = defineMessages({
  measurementDate: {
    defaultMessage: 'Measurement Date',
  },
  sdnn: {
    defaultMessage: 'SDNN',
  },
  rmssd: {
    defaultMessage: 'rMSSD',
  },
  download: {
    defaultMessage: 'download',
  },
});

export default HRI;
