import { createSlice, createAction } from '@reduxjs/toolkit';

export const STATE_KEY = 'language';

const INITIAL_STATE = {};

/* eslint-disable no-param-reassign */
const languageSlice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {},
});
/* eslint-enable no-param-reassign */

export const actions = {
  changeLanguage: createAction(`${STATE_KEY}/changeLanguage`, language => ({
    payload: { language },
  })),
};

const { reducer } = languageSlice;
export default reducer;
