import { styled } from 'styled-components';

export const SoundBtnWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.1rem 1.5rem 0 0;
  cursor: pointer;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 0.5rem;
`;

export const StyledImg = styled.img`
  width: 1.4rem;
  height: 1.4rem;
`;
