import React, { useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { deepEqual } from 'src/utils/comparators';
import {
  StyledAssignBtn,
  StyledFooter,
  StyledPrimaryBtn,
  StyledTextBtn,
} from '../styled';
import { useDetectClickOutside } from '../hooks';
import Connector, { Props } from './Connector';
import PatientSearchSelector from './PatientSearchSelector';
import { PatientTooltip, StyledInfo } from './styled';
import { MinimalPatient } from './types';
import permissions from 'src/permissions';
import AccessControl from 'src/components/AccessControl';

const PatientAssigner = ({
  patients,
  deviceId,
  startContinuousSession,
}: Props): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<MinimalPatient>();
  const ref = useRef<HTMLDivElement>(null);
  useDetectClickOutside(ref, isVisible, () => setIsVisible(false));

  const handleApply = (patient: MinimalPatient | undefined) => {
    if (!patient) {
      return;
    }

    startContinuousSession({
      deviceId,
      patientId: patient.id,
      saveRawData: false,
    });
    setIsVisible(false);
  };

  const renderTooltipContent = () => (
    <div ref={ref}>
      <PatientSearchSelector items={patients} onSelect={setSelectedPatient} />
      <StyledInfo>
        <FormattedMessage {...messages.info} />
      </StyledInfo>
      <StyledFooter>
        <StyledTextBtn onClick={() => setIsVisible(false)}>
          <FormattedMessage {...messages.cancel} />
        </StyledTextBtn>
        <AccessControl
          permissions={[permissions.TELEMETRY_START_CONTINUOUS_SESSION]}
        >
          <StyledPrimaryBtn
            onClick={() =>
              !selectedPatient?.deviceId && handleApply(selectedPatient)
            }
            isDisabled={!selectedPatient || !!selectedPatient.deviceId}
          >
            <FormattedMessage {...messages.apply} />
          </StyledPrimaryBtn>
        </AccessControl>
      </StyledFooter>
    </div>
  );

  return (
    <PatientTooltip
      title={renderTooltipContent()}
      placement="bottom"
      open={isVisible}
    >
      <StyledAssignBtn onClick={() => setIsVisible(true)}>
        <FormattedMessage {...messages.assignPatient} />
      </StyledAssignBtn>
    </PatientTooltip>
  );
};

const messages = defineMessages({
  assignPatient: {
    defaultMessage: 'Assign Patient',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  apply: {
    defaultMessage: 'Apply',
  },
  info: {
    defaultMessage: 'Start searching to get accurate results',
  },
});

const MemoisedPatientAssigned = React.memo(
  PatientAssigner,
  (oldProps, newProps) => deepEqual(oldProps, newProps),
);

export default Connector(MemoisedPatientAssigned);
