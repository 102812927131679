import { roundToNDecimals } from 'src/utils/dataUtils';
import { TRESHHOLDS_METRICS_ENUM } from 'src/redux/data/constants';
import { METRIC_ENUM } from 'src/utils/graphsUtils/graphConstants';
import { FetchMetricHistogramDataResponse } from 'src/services/types';

export const HR_HISTOGRAM_TRESHOLDS = Object.freeze([
  {
    id: '0',
    label: '<40',
    min: 0,
    max: 40,
  },
  {
    id: '1',
    label: '40-59',
    min: 40,
    max: 59,
  },
  {
    id: '2',
    label: '60-80',
    min: 60,
    max: 80,
  },
  {
    id: '3',
    label: '81-130',
    min: 81,
    max: 130,
  },
  {
    id: '4',
    label: '130<',
    min: 130,
    max: 1000,
  },
]);

export const RR_HISTOGRAM_TRESHOLDS = Object.freeze([
  {
    id: '5',
    label: '<11',
    min: 0,
    max: 11,
  },
  {
    id: '6',
    label: '12-15',
    min: 12,
    max: 15,
  },
  {
    id: '7',
    label: '16-22',
    min: 16,
    max: 22,
  },
  {
    id: '8',
    label: '23-25',
    min: 23,
    max: 25,
  },
  {
    id: '9',
    label: '26<',
    min: 26,
    max: 1000,
  },
]);

const HR_HISTOGRAM_TRESHOLDS_LABELS = HR_HISTOGRAM_TRESHOLDS.map(t => t.label);
const RR_HISTOGRAM_TRESHOLDS_LABELS = RR_HISTOGRAM_TRESHOLDS.map(t => t.label);

const DEFAULT_HR_HISTOGRAM_DATA_STATE: Record<string, number> =
  HR_HISTOGRAM_TRESHOLDS_LABELS.reduce(
    (acc, value) => ({ ...acc, [value]: 0 }),
    {},
  );
const DEFAULT_RR_HISTOGRAM_DATA_STATE: Record<string, number> =
  RR_HISTOGRAM_TRESHOLDS_LABELS.reduce(
    (acc, value) => ({ ...acc, [value]: 0 }),
    {},
  );

export const DEFAULT_FULL_HR_HISTOGRAM_DATA_STATE = Object.freeze({
  avg: 0,
  maxValue: 0,
  minValue: 0,
  histogram: {
    number: {
      total: 0,
      ...DEFAULT_HR_HISTOGRAM_DATA_STATE,
    },
    percentage: DEFAULT_HR_HISTOGRAM_DATA_STATE,
  },
});

export const DEFAULT_FULL_RR_HISTOGRAM_DATA_STATE = Object.freeze({
  avg: 0,
  maxValue: 0,
  minValue: 0,
  histogram: {
    number: {
      total: 0,
      ...DEFAULT_RR_HISTOGRAM_DATA_STATE,
    },
    percentage: DEFAULT_RR_HISTOGRAM_DATA_STATE,
  },
});

const computePercentages = (
  data: Record<string, number>,
  metric: keyof typeof METRIC_ENUM,
): Record<string, number> => {
  const initialState =
    metric === TRESHHOLDS_METRICS_ENUM.HR
      ? DEFAULT_HR_HISTOGRAM_DATA_STATE
      : DEFAULT_RR_HISTOGRAM_DATA_STATE;
  const metricTresholdRefference =
    metric === TRESHHOLDS_METRICS_ENUM.HR
      ? HR_HISTOGRAM_TRESHOLDS
      : RR_HISTOGRAM_TRESHOLDS;

  if (data.total === 0) {
    return initialState;
  }

  const tresholdsWithPercentage = metricTresholdRefference.reduce(
    (acc, item) => ({
      ...acc,
      [item.label]: roundToNDecimals(
        ((data[item.label] || 0) * 100) / (data.total || 1),
      ),
    }),
    {},
  );

  return tresholdsWithPercentage;
};

const findSuitableIntervalLabel = (
  item: { value: number; count: number },
  metric: keyof typeof METRIC_ENUM,
): string => {
  const metricTresholdRefference =
    metric === TRESHHOLDS_METRICS_ENUM.HR
      ? HR_HISTOGRAM_TRESHOLDS
      : RR_HISTOGRAM_TRESHOLDS;
  const res = metricTresholdRefference.find(
    t => t.min <= item.value && item.value <= t.max,
  )?.label;

  return res || '130<';
};

export const processHistogramData = (
  data: FetchMetricHistogramDataResponse,
) => {
  const rrData = data.metrics.RR.histogram;
  const hrData = data.metrics.HR.histogram;

  const rrNormalized = rrData.reduce(
    (acc: { total: number } & Record<string, number>, item) => {
      const suitableIntervalLabel = findSuitableIntervalLabel(
        item,
        METRIC_ENUM.RR,
      );

      return suitableIntervalLabel
        ? {
            ...acc,
            total: acc.total + item.count,
            [suitableIntervalLabel]:
              (acc[suitableIntervalLabel] || 0) + item.count,
          }
        : acc;
    },
    {
      total: 0,
      ...DEFAULT_RR_HISTOGRAM_DATA_STATE,
    },
  );

  const hrNormalized = hrData.reduce(
    (acc: { total: number } & Record<string, number>, item) => {
      const suitableIntervalLabel = findSuitableIntervalLabel(
        item,
        METRIC_ENUM.HR,
      );

      return {
        ...acc,
        total: acc.total + item.count,
        [suitableIntervalLabel]: (acc[suitableIntervalLabel] || 0) + item.count,
      };
    },
    {
      total: 0,
      ...DEFAULT_HR_HISTOGRAM_DATA_STATE,
    },
  );

  return {
    ...data,
    RR: {
      ...data.metrics.RR,
      histogram: {
        numeric: rrNormalized,
        percentage: computePercentages(rrNormalized, METRIC_ENUM.RR),
      },
    },
    HR: {
      ...data.metrics.HR,
      histogram: {
        numeric: hrNormalized,
        percentage: computePercentages(hrNormalized, METRIC_ENUM.HR),
      },
    },
  };
};

export const partitionHistogramData = ({
  lastDay,
  lastWeek,
}: {
  lastDay: ReturnType<typeof processHistogramData>;
  lastWeek: ReturnType<typeof processHistogramData>;
}) => ({
  RR: {
    lastDay: {
      histogram: lastDay.RR.histogram,
      avg: lastDay.metrics.RR.avg,
      minValue: lastDay.metrics.RR.minValue,
      maxValue: lastDay.metrics.RR.maxValue,
    },
    lastWeek: {
      histogram: lastWeek.RR.histogram,
      avg: lastWeek.metrics.RR.avg,
      minValue: lastWeek.metrics.RR.minValue,
      maxValue: lastWeek.metrics.RR.maxValue,
    },
  },
  HR: {
    lastDay: {
      histogram: lastDay.HR.histogram,
      avg: lastDay.metrics.HR.avg,
      minValue: lastDay.metrics.HR.minValue,
      maxValue: lastDay.metrics.HR.maxValue,
    },
    lastWeek: {
      histogram: lastWeek.HR.histogram,
      avg: lastWeek.metrics.HR.avg,
      minValue: lastWeek.metrics.HR.minValue,
      maxValue: lastWeek.metrics.HR.maxValue,
    },
  },
});

export type HistogramGraphData = ReturnType<typeof partitionHistogramData>;
export type DisplayHistogramGraphData = {
  id: string;
  value: number | undefined;
};
