import React from 'react';

import { SVGProps } from '../types';

const BedIconWithFrequencytValue = ({
  width = '175',
  height = '100',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    {...rest}
  >
    <g>
      <path
        fill="#fefdfd"
        d="M -0.5,-0.5 C 44.1667,-0.5 88.8333,-0.5 133.5,-0.5C 133.5,37.5 133.5,75.5 133.5,113.5C 88.8333,113.5 44.1667,113.5 -0.5,113.5C -0.5,75.5 -0.5,37.5 -0.5,-0.5 Z"
      />
    </g>
    <g>
      <path
        fill="#bb2f20"
        d="M 21.5,39.5 C 30.8333,40.8333 40.1667,40.8333 49.5,39.5C 50.4907,33.021 50.824,26.3543 50.5,19.5C 58.1667,19.5 65.8333,19.5 73.5,19.5C 86.0429,19.3386 97.7096,22.3386 108.5,28.5C 109.861,30.0284 111.028,31.6951 112,33.5C 112.499,28.8452 112.666,24.1785 112.5,19.5C 114.144,19.2865 115.644,19.6198 117,20.5C 117.667,35.1667 117.667,49.8333 117,64.5C 115.644,65.3802 114.144,65.7135 112.5,65.5C 112.5,61.8333 112.5,58.1667 112.5,54.5C 82.1667,54.5 51.8333,54.5 21.5,54.5C 21.5,58.1667 21.5,61.8333 21.5,65.5C 19.8561,65.7135 18.3561,65.3802 17,64.5C 16.3333,44.5 16.3333,24.5 17,4.5C 18.3561,3.61983 19.8561,3.28649 21.5,3.5C 21.5,15.5 21.5,27.5 21.5,39.5 Z"
      />
    </g>
    <g>
      <path
        fill="#ba2b1d"
        d="M 37.5,38.5 C 35.5,38.5 33.5,38.5 31.5,38.5C 26.0349,37.0206 23.0349,33.3539 22.5,27.5C 25.6673,17.0051 32.0006,14.3384 41.5,19.5C 45.6734,23.5384 46.5067,28.2051 44,33.5C 42.4421,36.0213 40.2755,37.688 37.5,38.5 Z"
      />
    </g>
    <g>
      <path
        fill="#d57e7a"
        d="M 73.5,19.5 C 65.8333,19.5 58.1667,19.5 50.5,19.5C 50.824,26.3543 50.4907,33.021 49.5,39.5C 49.5,32.5 49.5,25.5 49.5,18.5C 57.6841,18.1743 65.6841,18.5076 73.5,19.5 Z"
      />
    </g>
    <g>
      <path
        fill="#cd6764"
        d="M 31.5,38.5 C 33.5,38.5 35.5,38.5 37.5,38.5C 41.2984,39.4834 45.2984,39.8168 49.5,39.5C 40.1667,40.8333 30.8333,40.8333 21.5,39.5C 25.0419,39.8128 28.3752,39.4794 31.5,38.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fefefe"
        d="M 21.5,48.5 C 51.8407,48.1668 82.174,48.5002 112.5,49.5C 97.3333,49.8333 82.1667,50.1667 67,50.5C 51.485,50.4994 36.3183,49.8327 21.5,48.5 Z"
      />
    </g>
    <g>
      <path
        fill="#bc3022"
        d="M 60.5,77.5 C 62.1667,77.5 63.8333,77.5 65.5,77.5C 65.5,84.1667 65.5,90.8333 65.5,97.5C 66.8333,97.5 68.1667,97.5 69.5,97.5C 69.5,98.5 69.5,99.5 69.5,100.5C 68.1667,100.5 66.8333,100.5 65.5,100.5C 65.5,102.5 65.5,104.5 65.5,106.5C 64.5,106.5 63.5,106.5 62.5,106.5C 62.5,104.5 62.5,102.5 62.5,100.5C 57.5,100.5 52.5,100.5 47.5,100.5C 51.3608,92.6211 55.6941,84.9545 60.5,77.5 Z"
      />
    </g>
    <g>
      <path
        fill="#bd3425"
        d="M 74.5,77.5 C 75.5,77.5 76.5,77.5 77.5,77.5C 77.5,81.8333 77.5,86.1667 77.5,90.5C 82.8333,90.5 88.1667,90.5 93.5,90.5C 93.5,86.1667 93.5,81.8333 93.5,77.5C 94.8333,77.5 96.1667,77.5 97.5,77.5C 97.5,87.1667 97.5,96.8333 97.5,106.5C 96.1667,106.5 94.8333,106.5 93.5,106.5C 93.5,102.167 93.5,97.8333 93.5,93.5C 88.1667,93.5 82.8333,93.5 77.5,93.5C 77.5,97.8333 77.5,102.167 77.5,106.5C 76.5,106.5 75.5,106.5 74.5,106.5C 74.5,96.8333 74.5,87.1667 74.5,77.5 Z"
      />
    </g>
    <g>
      <path
        fill="#c03e30"
        d="M 31.5,82.5 C 32.8333,82.5 34.1667,82.5 35.5,82.5C 35.5,85.1667 35.5,87.8333 35.5,90.5C 38.1667,90.5 40.8333,90.5 43.5,90.5C 43.5,91.5 43.5,92.5 43.5,93.5C 40.8333,93.5 38.1667,93.5 35.5,93.5C 35.5,96.1667 35.5,98.8333 35.5,101.5C 34.1667,101.5 32.8333,101.5 31.5,101.5C 31.5,98.8333 31.5,96.1667 31.5,93.5C 28.8333,93.5 26.1667,93.5 23.5,93.5C 23.5,92.5 23.5,91.5 23.5,90.5C 26.1667,90.5 28.8333,90.5 31.5,90.5C 31.5,87.8333 31.5,85.1667 31.5,82.5 Z"
      />
    </g>
    <g>
      <path
        fill="#d68481"
        d="M 61.5,97.5 C 61.8195,92.6367 61.4861,87.97 60.5,83.5C 60.7828,82.7109 61.2828,82.0442 62,81.5C 62.8174,87.0164 62.6507,92.3497 61.5,97.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fcf6f6"
        d="M 60.5,83.5 C 61.4861,87.97 61.8195,92.6367 61.5,97.5C 58.4281,97.8174 55.4281,97.4841 52.5,96.5C 55.1935,92.1493 57.8602,87.8159 60.5,83.5 Z"
      />
    </g>
  </svg>
);

export default BedIconWithFrequencytValue;
