import React, { useEffect, useState } from 'react';
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  IntlShape,
} from 'react-intl';
import { Line } from 'react-chartjs-2';
import { Dayjs } from 'dayjs';

import hrLogo from '../../resources/HRlogo.svg';
import rrLogo from '../../resources/respirationLogo.svg';
import {
  METRIC_ENUM,
  chartOptionsByMetricType,
  baseDataSet,
  DATE_TYPE_ENUM,
  trendDatasetMap,
  TREND_LABELS,
} from 'src/utils/graphsUtils/graphConstants';
import { apiStatusEnum } from 'src/utils/constants';
import { calculateTrendLine } from 'src/utils/graphsUtils/graphsParser';
import DateTypePicker from './components/DateTypePicker';
import MeasurementsSelection from './components/MeasurementsSelection';
import TrendButton from './components/TrendButton';
import {
  convertToTimezone,
  nowLocal,
  parseInTimezone,
  toDayJs,
  toDisplayFormat,
} from 'src/utils/timeUtils';
import { deepEqual } from 'src/utils/comparators';
import {
  StatisticsDateUnitTypes,
  StatisticsGraphDataPoint,
  StatisticsGraphs,
  StatisticsMetricGraphType,
  StatisticsTrendType,
} from '../../modules/statistics/types';
import Connector, { PropsFromRedux } from './Connector';
import {
  ChartBox,
  StyledChartsLayout,
  StyledTrends,
  ChartUnit,
  Wrapper,
  StyledTitle,
  StyledHeader,
  StyledFooter,
  MultiChartContainer,
  MultiChartContainerHeader,
  MultiChartContainerContent,
  StyledLogo,
} from './styled';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';
import { checkIfOnlyOneChartVisible } from './utils';

type Props = PropsFromRedux & {
  intl: IntlShape;
};

// TODO: Hide graph data on loading
const StatisticsTab = ({
  patientId,
  data,
  viewPatientDateTimeGraphs,
  onChangeSelectedDate,
  onLoadStatistics,
  isStatisticsPageLoading,
  clearStatisticsData,
  timezone,
  intl,
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [dateType, setDateType] = useState<StatisticsDateUnitTypes>('date');
  const [rangeDates, setRangeDates] = useState({
    startDate: parseInTimezone(nowLocal().startOf('date'), timezone),
    endDate: parseInTimezone(nowLocal().endOf('date'), timezone),
  });
  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    parseInTimezone(nowLocal().startOf('date'), timezone),
  );
  const [displayMetrics, setDisplayMetrics] = useState<
    Record<StatisticsMetricGraphType, boolean>
  >({
    [METRIC_ENUM.RR]: true,
    [METRIC_ENUM.IE]: true,
    [METRIC_ENUM.HR]: true,
    [METRIC_ENUM.RA_bin]: true,
  });
  const [displayTrends, setDisplayTrends] = useState<
    Record<StatisticsTrendType, boolean>
  >({
    [TREND_LABELS.MAX_TREND]: false,
    [TREND_LABELS.MIN_TREND]: false,
    [TREND_LABELS.AVG_TREND]: false,
  });
  const [getDataTimeOut, setGetDataTimeOut] =
    useState<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    onLoadStatistics();
  }, [onLoadStatistics]);

  useEffect(() => {
    const supportedMetrics = Object.keys(displayMetrics).filter(
      metric => displayMetrics[metric as StatisticsMetricGraphType] === true,
    );
    let isLoadingStatus = false;

    for (const metric of supportedMetrics) {
      isLoadingStatus =
        isLoadingStatus ||
        data[metric as StatisticsMetricGraphType]?.status ===
          apiStatusEnum.LOADING;
    }
    setIsLoading(isLoadingStatus);
  }, [data, displayMetrics]);

  useEffect(() => {
    const { startDate, endDate } = rangeDates;
    if (startDate && endDate && patientId) {
      onChangeSelectedDate();
      clearTimeout(getDataTimeOut);
      setGetDataTimeOut(
        setTimeout(() => {
          viewPatientDateTimeGraphs({
            patientId,
            startTime: startDate,
            endTime: endDate,
            metrics: Object.keys(displayMetrics) as StatisticsMetricGraphType[],
          });
        }, 1000),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeDates, patientId]);

  useEffect(
    () => () => {
      clearStatisticsData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const trendDataSet = (
    points: StatisticsGraphDataPoint[],
    trendType: StatisticsTrendType,
  ) => ({
    data: calculateTrendLine(points),
    pointRadius: 0,
    pointHitRadius: 0,
    radius: 0,
    ...baseDataSet,
    ...trendDatasetMap[trendType],
  });

  const getDataPointsByDateType = (
    points: StatisticsGraphDataPoint[],
  ): StatisticsGraphDataPoint[] => {
    const map = {
      [DATE_TYPE_ENUM.MONTH]: points?.map(p => ({
        ...p,
        x: toDisplayFormat(p.x),
      })),
      [DATE_TYPE_ENUM.YEAR]: points?.map(p => ({
        ...p,
        x: toDisplayFormat(p.x),
      })),
    };

    // @ts-ignore Fix this on refactor
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return map[dateType] || points;
  };

  const avgDataSet = (points: StatisticsGraphs) =>
    points
      ? {
          label: intl.formatMessage(messages.avg),
          data: getDataPointsByDateType(points.avgPoints),
          borderColor: '#63B7C3',
          backgroundColor: '#63B7C3',
          ...baseDataSet,
          pointRadius: 4,
          hoverRadius: 5,
          pointBackgroundColor: '#63B7C3',
          pointBorderColor: '#63B7C3',
        }
      : {
          label: intl.formatMessage(messages.avg),
        };

  const barThicknessDateMap = {
    [DATE_TYPE_ENUM.MINUTE]: 6,
    [DATE_TYPE_ENUM.HOUR]: 6,
    [DATE_TYPE_ENUM.DATE]: 10,
    [DATE_TYPE_ENUM.MONTH]: 10,
    [DATE_TYPE_ENUM.YEAR]: 10,
  };

  const datasets = (points: StatisticsGraphs) => ({
    multisession: [
      avgDataSet(points),
      {
        type: 'bar',
        label: intl.formatMessage(messages.minMax),
        data: getDataPointsByDateType(points.minMaxPoints),
        borderColor: '#252525',
        backgroundColor: '#252525',
        fill: false,
        // @ts-ignore Fix this on refactor
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        barThickness: barThicknessDateMap[dateType],
        hidden: true,
      },
    ],
  });

  const trendLabelsToPointsTypeMap = Object.freeze({
    [TREND_LABELS.AVG_TREND]: 'avgPoints',
    [TREND_LABELS.MAX_TREND]: 'maxPoints',
    [TREND_LABELS.MIN_TREND]: 'minPoints',
  });

  const getDataSetWithTrends = (points: StatisticsGraphs) => {
    let myDataset = datasets(points).multisession;
    if (points) {
      const trends = Object.keys(displayTrends).filter(
        trend => displayTrends[trend as StatisticsTrendType] === true,
      ) as StatisticsTrendType[];
      trends.forEach(t => {
        myDataset = myDataset.concat(
          trendDataSet(points[trendLabelsToPointsTypeMap[t]], t),
        );
      });
    }

    return myDataset;
  };

  const dataSetForDateTypeMap = (metricData: StatisticsGraphs) => ({
    [DATE_TYPE_ENUM.HOUR]: [...datasets(metricData).multisession],
    [DATE_TYPE_ENUM.DATE]: getDataSetWithTrends(metricData),
  });

  const createMetricDataPoints = (metric: StatisticsMetricGraphType) => {
    const metricData = data[metric].data;

    return {
      labels: metricData?.avgPoints
        .slice()
        .map(x => convertToTimezone(x.x, timezone)),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      datasets:
        // @ts-ignore Fix this on refactor
        dataSetForDateTypeMap(metricData)[dateType] ||
        datasets(metricData).multisession,
    };
  };

  const rr = {
    data: createMetricDataPoints(METRIC_ENUM.RR),
    options: chartOptionsByMetricType(
      rangeDates.startDate,
      rangeDates.endDate,
      dateType,
    )[METRIC_ENUM.RR],
  };

  const hr = {
    data: createMetricDataPoints(METRIC_ENUM.HR),
    options: chartOptionsByMetricType(
      rangeDates.startDate,
      rangeDates.endDate,
      dateType,
    )[METRIC_ENUM.HR],
  };

  const IE = {
    data: createMetricDataPoints(METRIC_ENUM.IE),
    options: chartOptionsByMetricType(
      rangeDates.startDate,
      rangeDates.endDate,
      dateType,
    )[METRIC_ENUM.IE],
  };

  const RAbin = {
    data: createMetricDataPoints(METRIC_ENUM.RA_bin),
    options: chartOptionsByMetricType(
      rangeDates.startDate,
      rangeDates.endDate,
      dateType,
    )[METRIC_ENUM.RA_bin],
  };

  const _onChangeDate = (startDate: Dayjs, endDate: Dayjs) => {
    setSelectedDate(startDate);
    setRangeDates({ startDate, endDate });
  };
  const _onChangeDateType = (
    newDateType: StatisticsDateUnitTypes,
    date?: string,
  ) => {
    const setDate = date ? toDayJs(date) : selectedDate;

    const startDate = parseInTimezone(setDate.startOf(newDateType), timezone);
    const endDate = parseInTimezone(setDate.endOf(newDateType), timezone);

    setSelectedDate(startDate);
    setDateType(newDateType);
    setRangeDates({ startDate, endDate });
  };
  const _onCheckMetric = (metric: StatisticsMetricGraphType) => {
    setDisplayMetrics({ ...displayMetrics, [metric]: !displayMetrics[metric] });
  };

  const drillDownDateMap = Object.freeze({
    [DATE_TYPE_ENUM.DATE]: DATE_TYPE_ENUM.HOUR,
    [DATE_TYPE_ENUM.MONTH]: DATE_TYPE_ENUM.DATE,
    [DATE_TYPE_ENUM.YEAR]: DATE_TYPE_ENUM.MONTH,
  });

  const handleClickChart = (
    clickedData: { index: number }[],
    metric: StatisticsMetricGraphType,
  ) => {
    // @ts-ignore Fix this on refactor
    if (clickedData.length >= 1 && drillDownDateMap[dateType]) {
      const dataItem = clickedData[0];
      const { index } = dataItem || {};

      if (!index) {
        return;
      }

      const points = data[metric]?.data?.minMaxPoints;
      const selectedPoint = points[index];
      const time = selectedPoint?.x;

      // @ts-ignore Fix this on refactor
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      _onChangeDateType(drillDownDateMap[dateType], time);
    }
  };

  const onClickTrend = (trendType: StatisticsTrendType) => {
    setDisplayTrends({
      ...displayTrends,
      [trendType]: !displayTrends[trendType],
    });
  };

  const isSingleChartVisible = checkIfOnlyOneChartVisible(displayMetrics);

  return (
    <Wrapper
      data-cy={`statistics-page-loading=${isStatisticsPageLoading.toString()}`}
    >
      <StyledHeader>
        <StyledTitle>
          <FormattedMessage {...messages.title} />
        </StyledTitle>
        <DateTypePicker
          dateType={dateType}
          selectedDate={selectedDate}
          timezone={timezone}
          onChangeDate={_onChangeDate}
          onChangeDateType={_onChangeDateType}
        />
      </StyledHeader>

      <StyledChartsLayout>
        {isLoading && <LoadingOverlay />}

        <MultiChartContainer>
          {displayMetrics[METRIC_ENUM.HR] || displayMetrics[METRIC_ENUM.IE] ? (
            <MultiChartContainerHeader>
              <StyledLogo src={hrLogo} alt="hr-logo" />
              <FormattedMessage {...messages.heartRate} />
            </MultiChartContainerHeader>
          ) : null}
          <MultiChartContainerContent>
            {displayMetrics[METRIC_ENUM.HR] && (
              <ChartBox size={isSingleChartVisible ? '100%' : '45%'}>
                <ChartUnit>
                  <FormattedMessage {...messages.heartRateUnit} />
                </ChartUnit>
                <Line
                  data={hr.data}
                  // @ts-ignore Fix on refactor
                  height="125%"
                  options={hr.options}
                  redraw={false}
                  getElementAtEvent={clickedData => {
                    handleClickChart(
                      clickedData as unknown as { index: number }[],
                      METRIC_ENUM.HR,
                    );
                  }}
                />
              </ChartBox>
            )}
            {displayMetrics[METRIC_ENUM.IE] && (
              <ChartBox size={isSingleChartVisible ? '100%' : '45%'}>
                <ChartUnit>
                  <FormattedMessage {...messages.IETitle} />
                </ChartUnit>
                <Line
                  data={IE.data}
                  options={IE.options}
                  // @ts-ignore Fix on refactor
                  height="125%"
                  redraw={false}
                  getElementAtEvent={clickedData => {
                    handleClickChart(
                      clickedData as unknown as { index: number }[],
                      METRIC_ENUM.IE,
                    );
                  }}
                />
              </ChartBox>
            )}
          </MultiChartContainerContent>
        </MultiChartContainer>

        <MultiChartContainer>
          {displayMetrics[METRIC_ENUM.RR] ||
          displayMetrics[METRIC_ENUM.RA_bin] ? (
            <MultiChartContainerHeader>
              <StyledLogo src={rrLogo} alt="rr-logo" />
              <FormattedMessage {...messages.respirationRate} />
            </MultiChartContainerHeader>
          ) : null}
          <MultiChartContainerContent>
            {displayMetrics[METRIC_ENUM.RR] && (
              <ChartBox size={isSingleChartVisible ? '100%' : '45%'}>
                <ChartUnit>
                  <FormattedMessage {...messages.rrUnit} />
                </ChartUnit>
                <Line
                  data={rr.data}
                  options={rr.options}
                  redraw={false}
                  // @ts-ignore Fix on refactor
                  height="125%"
                  getElementAtEvent={clickedData => {
                    handleClickChart(
                      clickedData as unknown as { index: number }[],
                      METRIC_ENUM.RR,
                    );
                  }}
                />
              </ChartBox>
            )}

            {displayMetrics[METRIC_ENUM.RA_bin] && (
              <ChartBox size={isSingleChartVisible ? '100%' : '45%'}>
                <ChartUnit>
                  <FormattedMessage {...messages.RA_binTitle} />
                </ChartUnit>
                <Line
                  data={RAbin.data}
                  options={RAbin.options}
                  // @ts-ignore Fix on refactor
                  height="125%"
                  redraw={false}
                  getElementAtEvent={clickedData => {
                    handleClickChart(
                      clickedData as unknown as { index: number }[],
                      METRIC_ENUM.RA_bin,
                    );
                  }}
                />
              </ChartBox>
            )}
          </MultiChartContainerContent>
        </MultiChartContainer>
      </StyledChartsLayout>

      <StyledFooter>
        <MeasurementsSelection
          displayMetrics={displayMetrics}
          onCheckMetric={_onCheckMetric}
        />

        {dateType === DATE_TYPE_ENUM.DATE && (
          <StyledTrends>
            <TrendButton
              trendType={TREND_LABELS.MAX_TREND}
              isSelected={displayTrends[TREND_LABELS.MAX_TREND]}
              onClick={onClickTrend}
            />
            <TrendButton
              trendType={TREND_LABELS.AVG_TREND}
              isSelected={displayTrends[TREND_LABELS.AVG_TREND]}
              onClick={onClickTrend}
            />
            <TrendButton
              trendType={TREND_LABELS.MIN_TREND}
              isSelected={displayTrends[TREND_LABELS.MIN_TREND]}
              onClick={onClickTrend}
            />
          </StyledTrends>
        )}
      </StyledFooter>
    </Wrapper>
  );
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Statistics',
  },
  heartRate: {
    defaultMessage: 'Heart Rate',
  },
  minMax: {
    defaultMessage: 'Min/Max',
  },
  avg: {
    defaultMessage: 'Avg',
  },
  respirationRate: {
    defaultMessage: 'Respiration Rate',
  },
  IETitle: {
    defaultMessage: 'I/E RATIO',
  },
  RA_binTitle: {
    defaultMessage: 'RA',
  },
  heartRateUnit: {
    defaultMessage: 'BPM',
  },
  rrUnit: {
    defaultMessage: 'BRPM',
  },
});

export default Connector(
  injectIntl(
    React.memo(StatisticsTab, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
