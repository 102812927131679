import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/redux/store';

import { selectors, actions } from 'src/routes/GMDashboard';

const mapStateToProps = (state: RootState) => ({
  searchQuery: selectors.selectSearchQuery(state),
});

const mapActionCreators = {
  applySearchQuery: actions.applySearchQuery,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;

export default Connector;
