import styled from 'styled-components';

import { TimePicker as ReactTimePicker } from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

export const SummaryReportWrapper = styled.div`
  display: flex;
`;

export const RadioboxesWrapper = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .ant-radio-wrapper {
    font-size: 0.75rem;
    font-weight: 400;

    .ant-radio-inner {
      width: 1.25rem;
      height: 1.25rem;

      .ant-radio-inner::after {
        width: 1rem;
        height: 1rem;
      }
    }
  }
`;

export const CustomTimePicker = styled(ReactTimePicker)`
  width: 5rem;
  margin: 0 0.25rem;
  border: 0;
  border-bottom: 1px solid #333;
  font-weight: 700;

  .react-time-picker__wrapper {
    border: 0;
  }

  .react-time-picker__inputGroup__hour,
  .react-time-picker__inputGroup__minute {
    min-width: 1rem;

    &.react-time-picker__inputGroup__input--hasLeadingZero {
      min-width: 0.5rem;
    }
  }

  .react-time-picker__clear-button > svg {
    width: 1rem;
    height: auto;
  }

  input {
    &:focus {
      outline: none;
    }
  }
`;
