import React from 'react';

import { SVGProps } from '../types';

const HrAlertIcon = ({
  width = '17',
  height = '19',
  color = '#CC3311',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      d="M12.7327 6.02884C12.7327 6.02884 16.2667 6.44783 15.9839 9.80022C15.7011 13.1526 13.0154 16.9243 10.0469 17.9021C9.52854 18.0418 8.40094 18.0617 7.64376 17.7624C6.23025 17.2038 1.84809 14.2701 2.27216 9.80022C2.36608 8.81027 2.67031 8.10351 3.12037 7.61924C2.83955 7.09101 2.28996 6.67337 1.78953 6.39058C1.35536 6.14523 1.06916 5.66433 1.16212 5.17438L1.26037 4.65658C1.35401 4.16302 1.80468 3.81676 2.27897 3.98234C2.9428 4.21409 3.77904 4.68283 4.53395 5.52791C4.72243 5.13611 4.97059 4.81034 5.55882 4.37069L5.06251 3.55012C4.77241 3.0705 4.93314 2.44615 5.41876 2.16622L5.46391 2.14019C5.90149 1.88795 6.45847 2.0016 6.76224 2.40512L7.04435 2.77986C7.31498 3.13936 7.79239 3.27382 8.21087 3.10841L8.28365 3.07964C8.66531 2.92878 8.91605 2.56005 8.91605 2.14966V2C8.91605 1.44772 9.36377 1 9.91605 1H10.0364C10.5887 1 11.0364 1.44772 11.0364 2V2.39688C11.3663 2.60388 12.0825 3.13737 12.3086 3.61535C13.3213 3.3286 14.3249 3.25495 15.1387 3.2907C15.6283 3.31221 15.9839 3.7346 15.9839 4.22469L15.9839 4.48675C15.9839 5.08479 15.46 5.53742 14.8633 5.57749C14.2989 5.61538 13.6243 5.74163 12.7327 6.02884Z"
      fill={color}
    />
    <path
      d="M12.7327 6.02884C12.7327 6.02884 16.2667 6.44783 15.9839 9.80022C15.7011 13.1526 13.0154 16.9243 10.0469 17.9021C9.52854 18.0418 8.40094 18.0617 7.64376 17.7624C6.23025 17.2038 1.84809 14.2701 2.27216 9.80022C2.36608 8.81027 2.67031 8.10351 3.12037 7.61924M12.7327 6.02884C12.7327 6.02884 10.6124 6.86689 9.90556 7.84471M12.7327 6.02884C13.6243 5.74163 14.2989 5.61538 14.8633 5.57749C15.46 5.53742 15.9839 5.08479 15.9839 4.48675L15.9839 4.22469C15.9839 3.7346 15.6283 3.31221 15.1387 3.2907C14.3249 3.25495 13.3213 3.3286 12.3086 3.61535M9.90556 7.84471C9.48155 8.82252 9.34013 9.80022 9.34013 10.9179C9.34013 12.1751 9.7642 13.0132 9.90556 13.2926M9.90556 7.84471C9.17197 7.48782 8.90914 7.42089 8.06791 7.16216M9.90556 13.2926V14.9688M9.90556 13.2926L11.8846 12.8735M8.06791 7.16216C7.15307 6.8808 6.22283 6.70288 5.3821 6.72713M8.06791 7.16216C8.98478 5.11533 10.6588 4.08252 12.3086 3.61535M5.3821 6.72713C4.48307 6.75307 3.6864 7.0102 3.12037 7.61924M5.3821 6.72713C5.12304 6.26167 4.83496 5.86487 4.53395 5.52791M3.12037 7.61924C2.83955 7.09101 2.28996 6.67337 1.78953 6.39058C1.35536 6.14523 1.06916 5.66433 1.16212 5.17438L1.26037 4.65658C1.35401 4.16302 1.80468 3.81676 2.27897 3.98234C2.9428 4.21409 3.77904 4.68283 4.53395 5.52791M4.53395 5.52791C4.72243 5.13611 4.97059 4.81034 5.55882 4.37069L5.06251 3.55012C4.77241 3.0705 4.93314 2.44615 5.41876 2.16622L5.46391 2.14019C5.90149 1.88795 6.45847 2.0016 6.76224 2.40512L7.04435 2.77986C7.31498 3.13936 7.79239 3.27382 8.21087 3.10841L8.28365 3.07964C8.66531 2.92878 8.91605 2.56005 8.91605 2.14966V2C8.91605 1.44772 9.36377 1 9.91605 1H10.0364C10.5887 1 11.0364 1.44772 11.0364 2V2.39688C11.3663 2.60388 12.0825 3.13737 12.3086 3.61535"
      stroke="#FDEDE9"
      strokeLinecap="round"
    />
    <path
      d="M6.69 13.0175L7.25795 11.3514M7.25795 11.3514L9.23564 10.5261M7.25795 11.3514L5.41211 10.6572"
      stroke="#FDEDE9"
      strokeLinecap="round"
    />
  </svg>
);

export default HrAlertIcon;
