import { isEmpty } from 'lodash';

export const parseToFullName = (firstName: string, lastName: string): string =>
  `${firstName || ''} ${lastName || ''}`;

export const expandArrayItemsToHaveFullName = <T extends { id: string }>(
  arr: T[],
  fnFieldName: keyof T,
  lnFieldName: keyof T,
): Array<T & { key: string; fullName: string }> =>
  arr && arr.length
    ? arr.map(item => ({
        ...item,
        key: item.id,
        fullName: parseToFullName(
          item[fnFieldName] as string,
          item[lnFieldName] as string,
        ),
      }))
    : [];

export const convertAllEmptyToUndefined = (obj: object) => {
  const updatedObj = { ...obj };
  for (const [key, value] of Object.entries(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (value?.dataParser) {
      // @ts-ignore Try to remove
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      updatedObj[key] = value.dataParser(value?.data);
    } else {
      // @ts-ignore Try to remove
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      updatedObj[key] = isEmpty(value?.toString().trim()) ? undefined : value;
    }
  }
  return updatedObj;
};

export const convertAllEmptyToNull = (obj: object) => {
  const updatedObj = { ...obj };
  for (const [key, value] of Object.entries(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (value?.dataParser) {
      // @ts-ignore Try to remove
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      updatedObj[key] = value.dataParser(value?.data);
    } else {
      // @ts-ignore Try to remove
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      updatedObj[key] = isEmpty(value?.toString().trim()) ? null : value;
    }
  }
  return updatedObj;
};
