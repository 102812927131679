/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import styled, { withTheme } from 'styled-components';
import { notificationKey } from '../modules/constants';

const StyleDescription = styled.div`
  font-size: 28px;
  display: flex;
  height: 50px;
  align-items: center;
`;
const StyleTitle = styled.div`
  font-size: 20px;
`;
const StyledPatientName = styled.div`
  font-weight: bold;
`;
const StyledHriMessage = styled.div`
  font-size: 15px;
`;
const StyledNotificationDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const IndicationNotification = props => {
  const { isActive, selectedPatient, description, message, hriMessage } = props;
  useEffect(() => {
    if (isActive) {
      const notificationMessage = <StyleTitle>{message}</StyleTitle>;
      const notificationDescription = (
        <StyledNotificationDescription>
          <StyleDescription>
            {description}
            <div style={{ width: '6px' }} />
            <StyledPatientName>{` ${selectedPatient?.firstName} ${selectedPatient?.lastName} - ${selectedPatient?.patientId}`}</StyledPatientName>
          </StyleDescription>
          <StyledHriMessage>{hriMessage}</StyledHriMessage>
        </StyledNotificationDescription>
      );
      notification.open({
        message: notificationMessage,
        description: notificationDescription,
        duration: 5,
        style: {
          width: 700,
        },
        key: notificationKey,
      });
    }
  }, [isActive]);
  return null;
};
IndicationNotification.propTypes = {
  isActive: PropTypes.bool.isRequired,
  selectedPatient: PropTypes.object,
  theme: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  hriMessage: PropTypes.string,
};
IndicationNotification.defaultProps = {
  selectedPatient: null,
  hriMessage: null,
};
export default withTheme(IndicationNotification);
