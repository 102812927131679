import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { specialMeasurementValues } from 'src/redux/data/monitor/modules/constants';
import { displayMeasurement } from 'src/redux/data/monitor/modules/utils';
import { SubTitle, Row } from 'src/components/styled';
import { injectIntl, defineMessages } from 'react-intl';

const MeasurementValue = styled.div`
  font-size: ${props => (props.isSmallText ? '13px' : '24px')};
  align-self: ${props => (props.isSmallText ? 'center' : 'auto')};
  font-weight: bold;
  margin-left: 8px;
  white-space: nowrap;
  text-align: center;
  color: ${props =>
    props.isHighQuality
      ? props.theme.colors.spotMeasurementHighQualityColor
      : props.theme.colors.spotMeasurementLowQualityColor};
`;

const MeasurementUnit = styled.div`
  display: ${props => (props.isHidden ? 'none' : undefined)};
  margin-left: 8px;
  color: ${props => props.theme.colors.spotMeasurementCardTitleColor};
  align-self: center;
`;

const CardTitle = styled(SubTitle)`
  color: ${props => props.theme.colors.spotMeasurementCardTitleColor};
  font-weight: 500;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
`;

const MeasurementLogo = styled.img`
  width: 1rem;
  height: 1rem;
`;

const MeasurementGraphDisplay = ({
  logo,
  title,
  measurement,
  unit,
  quality,
  shouldDisplayHighQualityOnly,
  isSpotValueActive,
  intl,
  measurementType,
}) => {
  const isDataUnreliable =
    measurement === specialMeasurementValues.DATA_UNRELIABLE ||
    (shouldDisplayHighQualityOnly && !quality);
  return (
    <Row>
      <MeasurementLogo src={logo} alt="measurement-logo" />
      <CardTitle>
        {title}
        {isSpotValueActive && ` ${intl.formatMessage(messages.spot)}`}:
      </CardTitle>
      <MeasurementValue isSmallText={isDataUnreliable} isHighQuality={quality}>
        {displayMeasurement(
          measurement,
          quality,
          shouldDisplayHighQualityOnly,
          intl,
          measurementType,
        )}
      </MeasurementValue>
      <MeasurementUnit isHidden={isDataUnreliable || measurement === null}>
        {unit}
      </MeasurementUnit>
    </Row>
  );
};

MeasurementGraphDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  measurement: PropTypes.string,
  unit: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  quality: PropTypes.bool,
  shouldDisplayHighQualityOnly: PropTypes.bool,
  isSpotValueActive: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  measurementType: PropTypes.string,
};

MeasurementGraphDisplay.defaultProps = {
  measurement: null,
  measurementType: null,
  quality: true,
  shouldDisplayHighQualityOnly: false,
};

const messages = defineMessages({
  spot: {
    defaultMessage: 'Spot',
  },
});

export default injectIntl(MeasurementGraphDisplay);
