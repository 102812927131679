import styled from 'styled-components';
import Box from 'src/components/Box';

export const SpinBox = styled(Box)`
  height: 100vh;
`;

export const AppWrapper = styled.div`
  height: 100vh;
  min-width: 100vw;
  display: flex;
`;
