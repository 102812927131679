import { defineMessages } from 'react-intl';

import {
  VALID_MIN_HR,
  VALID_MAX_HR,
  VALID_MIN_RR,
  VALID_MAX_RR,
  VALID_MIN_IE,
  VALID_MAX_IE,
} from 'src/utils/constants';

export const onlineMonitorDataTypes = Object.freeze({
  CONTINUOUS: 'CONTINUOUS',
  SPOT: 'SPOT',
  HRI: 'HRI',
});

export const specialMeasurementValues = Object.freeze({
  NO_DATA: '-1',
  DATA_UNRELIABLE: '-3',
});

export const MEASUREMENT_TYPES = Object.freeze({
  HR: 'HR',
  RR: 'RR',
  IE: 'IE',
});

export const validContinuousValues = Object.freeze({
  [MEASUREMENT_TYPES.HR]: {
    min: VALID_MIN_HR,
    max: VALID_MAX_HR,
  },
  [MEASUREMENT_TYPES.RR]: {
    min: VALID_MIN_RR,
    max: VALID_MAX_RR,
  },
  [MEASUREMENT_TYPES.IE]: {
    min: VALID_MIN_IE,
    max: VALID_MAX_IE,
  },
});

export const messages = defineMessages({
  dataUnreliable: {
    defaultMessage: 'Data Unreliable',
  },
  noData: {
    defaultMessage: '--',
  },
  insufficientData: {
    defaultMessage: 'Insufficient Data',
  },
});

export const initMonitorActionType = 'initMonitor';
