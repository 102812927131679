import { connect, ConnectedProps } from 'react-redux';

import { actions, selectors } from 'src/redux/data/auth/modules/slice';
import { RootState } from 'src/redux/store';

const mapStateToProps = (state: RootState) => ({
  activeConsent: selectors.getActiveConsent(state),
  loginStatus: selectors.getLoginStatus(state),
});

const mapActionCreators = {
  onConfirmConsent: actions.onConfirmConsent,
  onCancelConsent: actions.onCancelConsent,
  logout: actions.logout,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
