import React, { useState } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { INDIVIDUAL_DASHBOARD_TABS } from '../../modules/tabsConfig';
import { PageContentWrapper, StyledTabs } from './styled';

type Props = {
  intl: IntlShape;
};

// TODO: Revert in 4.4.2

const PageContent = ({ intl }: Props): JSX.Element => {
  const [hasError, setHasError] = useState(false);
  return (
    <PageContentWrapper>
      <StyledTabs
        destroyInactiveTabPane
        items={INDIVIDUAL_DASHBOARD_TABS.map(
          ({ key, labelMessage, component: Component }) => ({
            key: key,
            disabled: hasError,
            label: <span>{intl.formatMessage(labelMessage)}</span>,
            children: <Component hasPageError={setHasError} />,
          }),
        )}
      />
    </PageContentWrapper>
  );
};

export default injectIntl(PageContent);
