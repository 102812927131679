import { connect } from 'react-redux';
import Component from '../components/StopConfirmationModal';
import { actions, selectors } from '../modules/slice';
import { actions as monitorActions } from '../../../redux/data/monitor';

const mapStateToProps = state => ({
  isVisible: selectors.getIsStopModalVisible(state),
  selectedDevice: selectors.getDevice(state),
});

const mapActionCreators = {
  ...actions,
  ...monitorActions,
};

export default connect(mapStateToProps, mapActionCreators)(Component);
