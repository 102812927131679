import React from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';
import { Line } from 'react-chartjs-2';

import theme from 'src/themes/theme';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';
import { useWindowSize } from 'src/utils/hooks/useWindowSize';
import { BREAKPOINT_SIZES } from 'src/utils/mediaQueryUtils';
import { truthy } from 'src/utils/fpUtils';
import { deepEqual } from 'src/utils/comparators';
import {
  getHorizontalLineDataset,
  getGraphOptions,
  getAlertMarkerDataset,
} from './graphUtils';
import { GraphWrapper, YAxisTitlesWrapper } from './styled';
import Connector, { IProps } from './Connector';
import { GraphDataProps } from './types';

const mapGraphData = ({
  isLoading,
  hrContinuousData,
  rrContinuousData,
  hrThresholdLines,
  rrThresholdLines,
  hrBaseline,
  rrBaseline,
  alerts,
  intl,
}: GraphDataProps) => ({
  datasets: [
    alerts,
    ...hrThresholdLines,
    {
      label: intl.formatMessage(messages.hrContinuousLegend),
      data: hrContinuousData,
      borderColor: theme.colors.continuousGraphHrContinuousColor,
      backgroundColor: theme.colors.continuousGraphHrContinuousColor,
      yAxisID: 'yLeft',
    },
    hrBaseline,
    ...rrThresholdLines,
    {
      label: intl.formatMessage(messages.rrContinuousLegend),
      data: rrContinuousData,
      borderColor: theme.colors.continuousGraphRrContinuousColor,
      backgroundColor: theme.colors.continuousGraphRrContinuousColor,
      yAxisID: 'yRight',
    },
    rrBaseline,
  ]
    .filter(truthy)
    .map(x => ({ ...x, hidden: isLoading })),
});

type Props = IProps & {
  intl: IntlShape;
};

const Graph = ({
  metadata: {
    isLoading,
    startTime,
    endTime,
    stepSize,
    unit,
    hrContinuousBaseline,
    rrContinuousBaseline,
  },
  data: { hrContinuousData, rrContinuousData },
  thresholds,
  alerts,
  intl,
}: Props): JSX.Element => {
  const [width] = useWindowSize();

  const hrThresholdLines = [
    getHorizontalLineDataset({
      threshold: thresholds.hr.above,
      startTime,
      endTime,
      label: intl.formatMessage(messages.hrThresholdAbove),
      color: '#FFC048',
      yAxisID: 'yLeft',
      isDashed: true,
    }),
    getHorizontalLineDataset({
      threshold: thresholds.hr.below,
      startTime,
      endTime,
      label: intl.formatMessage(messages.hrThresholdBelow),
      color: '#FFC048',
      yAxisID: 'yLeft',
      isDashed: true,
    }),
  ];
  const rrThresholdLines = [
    getHorizontalLineDataset({
      threshold: thresholds.rr.above,
      startTime,
      endTime,
      label: intl.formatMessage(messages.rrThresholdAbove),
      color: '#63B7C3',
      yAxisID: 'yRight',
      isDashed: true,
    }),
    getHorizontalLineDataset({
      threshold: thresholds.rr.below,
      startTime,
      endTime,
      label: intl.formatMessage(messages.rrThresholdBelow),
      color: '#63B7C3',
      yAxisID: 'yRight',
      isDashed: true,
    }),
  ];
  const hrBaseline = getHorizontalLineDataset({
    threshold: hrContinuousBaseline,
    startTime,
    endTime,
    label: intl.formatMessage(messages.hrContinuousBaseline),
    color: '#F08838',
    yAxisID: 'yLeft',
    isDashed: false,
  });
  const rrBaseline = getHorizontalLineDataset({
    threshold: rrContinuousBaseline,
    startTime,
    endTime,
    label: intl.formatMessage(messages.rrContinuousBaseline),
    color: '#59DDAA',
    yAxisID: 'yRight',
    isDashed: false,
  });

  return (
    <GraphWrapper data-cy={`periodic-graph-loading=${isLoading.toString()}`}>
      {isLoading && <LoadingOverlay />}
      <YAxisTitlesWrapper>
        <span>
          <FormattedMessage {...messages.bpm} />
        </span>
        <span>
          <FormattedMessage {...messages.brpm} />
        </span>
      </YAxisTitlesWrapper>
      <Line
        type="line"
        options={getGraphOptions({
          startTime,
          endTime,
          stepSize,
          unit,
          thresholds,
          messages,
          intl,
          isExtraLarge: width > BREAKPOINT_SIZES.XL,
        })}
        data={mapGraphData({
          isLoading,
          hrContinuousData,
          rrContinuousData,
          hrThresholdLines,
          rrThresholdLines,
          hrBaseline,
          rrBaseline,
          alerts: getAlertMarkerDataset(alerts),
          intl,
        })}
      />
    </GraphWrapper>
  );
};

const messages = defineMessages({
  bpm: {
    defaultMessage: '[BPM]',
  },
  brpm: {
    defaultMessage: '[BrPM]',
  },
  hrContinuousLegend: {
    defaultMessage: 'HR Continuous',
  },
  rrContinuousLegend: {
    defaultMessage: 'RR Continuous',
  },
  hrContinuousBaseline: {
    defaultMessage: 'HR Baseline',
  },
  rrContinuousBaseline: {
    defaultMessage: 'RR Baseline',
  },
  hrThresholdAbove: {
    defaultMessage: 'HR Max Threshold',
  },
  hrThresholdBelow: {
    defaultMessage: 'HR Min Threshold',
  },
  rrThresholdAbove: {
    defaultMessage: 'RR Max Threshold',
  },
  rrThresholdBelow: {
    defaultMessage: 'RR Min Threshold',
  },
});

export default Connector(
  injectIntl(
    React.memo(Graph, (oldProps, newProps) => deepEqual(oldProps, newProps)),
  ),
);
