import { isEmpty } from 'lodash';
import { addPatientFormFields } from '../../../../routes/Patients/modules/constants';
import { convertAllEmptyToUndefined } from '../../../../utils/parser';

const phoneParser = phone =>
  isEmpty(phone) ? undefined : phone.startsWith('+') ? phone : `+${phone}`;

export const parseFormDataToCreatePatientRequest = formData => {
  const formDataToRequestStructure = {
    email: formData[addPatientFormFields.EMAIL],
    phone: {
      data: formData[addPatientFormFields.PHONE],
      dataParser: phoneParser,
    },
    firstName: formData[addPatientFormFields.FIRST_NAME],
    lastName: formData[addPatientFormFields.LAST_NAME],
    address: {
      data: {
        address1: formData[addPatientFormFields.ADDRESS],
      },
      dataParser: convertAllEmptyToUndefined,
    },
    gender: formData[addPatientFormFields.GENDER],
    dateOfBirth: formData[addPatientFormFields.DATE_OF_BIRTH],
    patientId: formData[addPatientFormFields.PATIENT_ID],
  };
  return convertAllEmptyToUndefined(formDataToRequestStructure);
};

export const parseFormDataToUpdatePatientRequest = (id, formData) => ({
  id,
  data: { ...parseFormDataToCreatePatientRequest(formData) },
});
