import styled from 'styled-components';
import { DatePicker } from 'antd';

import { media } from 'src/utils/mediaQueryUtils';

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const StyledRangePicker = styled(DatePicker.RangePicker)<{
  isActive: boolean;
}>`
  width: 100%;

  ${media.small`
    width: 110px;
    height: 18px;
    border-radius: 4px;
  `}
  ${media.extraLarge`
    width: 230px;
    height: 31px;
    border-radius: 8px;
  `}

	.ant-picker-input > input {
    ${media.small`
      font-weight: 400;
      font-size: 8px;
    `}
    ${media.extraLarge`
      font-weight: 500;
      font-size: 12px;
    `}
  }
  .ant-picker-range {
    ${media.small`
      padding: 2px 7px 2px;
    `}
    ${media.extraLarge`
      padding: 4px 11px 4px;
    `}
  }
  .ant-picker-range-separator {
    ${media.small`
      padding: 0px !important;
      transform: scale(0.7);
    `}
    ${media.extraLarge`
      padding: 0 8px !important;
      transform: scale(1);
    `}
  }
  .ant-picker-suffix {
    ${media.small`
      transform: scale(0.7);
    `}
    ${media.extraLarge`
      transform: scale(1);
    `}
  }

  ${props =>
    props.isActive
      ? `background-color: ${props.theme.colors.individualDashboardTimeButtonBackgroundActive};`
      : ''}
`;

export const StyledTimeBtn = styled.div<{
  isActive: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${props =>
    props.isActive
      ? props.theme.colors.individualDashboardTimeButtonBackgroundActive
      : props.theme.colors.individualDashboardTimeButtonBackground};
  margin-bottom: 4px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 0.5rem;
  font-size: 0.6rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;

  ${media.small`
	  	border: ${
        // @ts-ignore Refactor later
        props =>
          // @ts-ignore Refactor later
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          props.isActive
            ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
              `1px solid ${props.theme.colors.individualDashboardTimeButtonBorder}`
            : 'none'
      };
  		height: 18px;
	`}
  ${media.extraLarge`
		border: ${
      // @ts-ignore Refactor later
      props =>
        // @ts-ignore Refactor later
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        props.isActive
          ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
            `2px solid ${props.theme.colors.individualDashboardTimeButtonBorder}`
          : 'none'
    };
		height: 31px;
    `}
`;
