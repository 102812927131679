import * as A from 'fp-ts/lib/Array';
import { fromEquals } from 'fp-ts/lib/Eq';
import * as R from 'fp-ts/lib/Record';

import { Session } from 'src/types/sessions';
import { SerialNumber, UUID } from 'src/types/utility';
import { EntityWithUUID, equalsEntity, objectMagma } from 'src/utils/fpUtils';

export const extractLatestPatientSession = (
  patientId: UUID,
  sessions: Session[],
): Session | undefined => {
  const patientSessions = sessions
    .filter(s => s.patientId === patientId)
    .sort((a, b) => a.startTime.localeCompare(b.startTime));

  if (!patientSessions.length) {
    return undefined;
  }

  return patientSessions[patientSessions.length - 1];
};

export const extractLatestDeviceSession = (
  deviceId: SerialNumber,
  sessions: Session[],
): Session | undefined => {
  const deviceSessions = sessions
    .filter(s => s.deviceId === deviceId)
    .sort((a, b) => a.startTime.localeCompare(b.startTime));

  if (!deviceSessions.length) {
    return undefined;
  }

  return deviceSessions[deviceSessions.length - 1];
};

export const mergeArraysWithUniqueIds = <T extends EntityWithUUID>(
  initial: T[],
  upcoming: T[],
): T[] => A.difference<T>(equalsEntity())(upcoming)(initial).concat(upcoming);

export const mergeArraysWithCustomUniqueIds = <T>(
  initial: T[],
  upcoming: T[],
  eq: (a: T, b: T) => boolean,
): T[] => A.difference<T>(fromEquals(eq))(upcoming)(initial).concat(upcoming);

export const mergeRecordsWithUniqueIds = <T>(
  initial: Record<string, T>,
  upcoming: Record<string, T>,
) => R.union(objectMagma<T>())(upcoming)(initial);
