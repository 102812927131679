import React, { Dispatch, SetStateAction } from 'react';
import { IntlShape } from 'react-intl';
import { ColumnsType } from 'antd/lib/table';

import { customersTableMessages, customersTableActions } from './constants';
import { CustomerModalType, CustomerType } from '../../modules/types';
import { TableActionsConfig } from 'src/types/table';
import { Dropdown, MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { PropsFromRedux } from './Connector';

export const items: MenuProps['items'] = [
  { key: '1', label: 'Tenant 1' },
  { key: '2', label: 'Tenant 2' },
];

export const getCustomersColumns = (
  intl: IntlShape,
): ColumnsType<CustomerType> => [
  {
    title: intl.formatMessage(customersTableMessages.customerName),
    dataIndex: 'name',
    key: 'customerName',
  },
  {
    title: intl.formatMessage(customersTableMessages.emr),
    dataIndex: 'integratedEMR',
    key: 'integratedEMR',
    render: (_, record) =>
      record?.emrMappings?.map(emr => {
        return (
          <p style={{ margin: 0 }} key={emr?.emrType}>
            {emr.emrType}
          </p>
        );
      }),
  },
  {
    title: intl.formatMessage(customersTableMessages.integrationType),
    dataIndex: 'integrationType',
    key: 'integrationType',
    render: (_, record) =>
      record?.emrMappings?.map(emr => {
        return (
          <p style={{ margin: 0 }} key={emr.integrationType}>
            {emr.integrationType}
          </p>
        );
      }),
  },
  {
    title: intl.formatMessage(customersTableMessages.numberOfTenants),
    dataIndex: 'tenantsCount',
    key: 'tenantsCount',
    render: (_, record) => record?.tenants?.count,
  },
  {
    title: intl.formatMessage(customersTableMessages.tenantsName),
    dataIndex: 'tenantsCount',
    key: 'tenantsCount',
    render: (_, record) => {
      if (record?.tenants?.shortList) {
        return (
          <>
            {record?.tenants?.shortList?.map(tenant => (
              <p key={tenant.id} style={{ margin: 0 }}>
                {tenant.name}
              </p>
            ))}
            <Dropdown menu={{ items }} trigger={['click']}>
              <a onClick={e => e.preventDefault()} style={{ color: '#87b7c5' }}>
                show all
                <PlusOutlined />
              </a>
            </Dropdown>
          </>
        );
      } else {
        return '-';
      }
    },
  },
  {
    title: intl.formatMessage(customersTableMessages.numberOfUsers),
    dataIndex: 'usersCount',
    key: 'usersCount',
    render: (_, record) => record.usersCount,
  },
  {
    title: intl.formatMessage(customersTableMessages.numberOfDevices),
    dataIndex: 'devicesCount',
    key: 'devicesCount',
    render: (_, record) => record.devicesCount,
  },
];

export const getTableActions = (
  intl: IntlShape,
  setModalState: Dispatch<SetStateAction<CustomerModalType>>,
  setCustomerForm: PropsFromRedux['setCustomerForm'],
  setCurrentCustomer: PropsFromRedux['setCurrentCustomer'],
  deleteCustomer: PropsFromRedux['deleteCustomer'],
): TableActionsConfig<CustomerType>[] => [
  {
    key: 'delete',
    name: intl.formatMessage(customersTableActions.delete),
    onClick: (_, { record }) => {
      deleteCustomer(record.id);
      setModalState({ mode: '', visible: false });
    },
  },
  {
    key: 'edit',
    name: intl.formatMessage(customersTableActions.edit),
    onClick: (_, { record }) => {
      setCurrentCustomer(record);
      setCustomerForm({
        name: record.name,
        contactId: record.contactId,
        emrMappings:
          record?.emrMappings &&
          record?.emrMappings.map(emr => ({
            organizationId: emr.organizationId,
            emrType: emr.emrType,
          })),
      });
      setModalState({ mode: 'EDIT', visible: true });
    },
  },
];
