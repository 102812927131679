import { DEFAULT_LOCALE } from 'src/utils/languageUtils';
import permissions from 'src/permissions';
import { messages } from './constants';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';

export const getActionPermissionsMap = (
  intl,
  history,
  setActiveActionRow,
  setIsDeleteConfirmationVisible,
  setIsEditModalVisible,
  setIsDetachConfirmationVisible,
  setIsAssignDeviceModalVisible,
  setisDeviceInfoModalVisible,
) => ({
  [permissions.VIEW_DEVICES_DELETE_DEVICE_ACTION]: [
    {
      key: 'delete device',
      name: intl.formatMessage(messages.deleteDevice),
      isDisabled: ({ record }) => record.tenantName,
      onClick: (_, { record }) => {
        setActiveActionRow(record);
        setIsDeleteConfirmationVisible(true);
      },
    },
  ],
  [permissions.VIEW_DEVICES_EDIT_DEVICE_ACTION]: [
    {
      key: 'edit device',
      name: intl.formatMessage(messages.editDevice),
      onClick: (_, { record }) => {
        setActiveActionRow(record);
        setIsEditModalVisible(true);
      },
    },
  ],
  [permissions.VIEW_DEVICES_TENANT_ACTIONS]: [
    {
      key: 'detach',
      name: intl.formatMessage(messages.detachTenant),
      isDisabled: ({ record }) => !record.tenantName,
      onClick: (_, { record }) => {
        setActiveActionRow(record);
        setIsDetachConfirmationVisible(true);
      },
    },
    {
      key: 'assign',
      name: intl.formatMessage(messages.assignTenant),
      isDisabled: ({ record }) => record.tenantName,
      onClick: (_, { record }) => {
        setActiveActionRow(record);
        setIsAssignDeviceModalVisible(true);
      },
    },
  ],
  [permissions.VIEW_DEVICES_MONITOR_ACTION]: [
    {
      key: 'dashboard',
      name: intl.formatMessage(messages.dashboard),
      onClick: (_, { record }) => {
        history.push({
          pathname: `/${DEFAULT_LOCALE}/${appRoutes.INDIVIDUAL_DASHBOARD}`,
          search: `?patientId=${record.assignedPatientId || ''}`,
        });
      },
    },
  ],
  [permissions.VIEW_DEVICE_INFO_ACTION]: [
    {
      key: 'info',
      name: intl.formatMessage(messages.info),
      onClick: (_, { record }) => {
        setActiveActionRow(record);
        setisDeviceInfoModalVisible(true);
      },
    },
  ],
});
