import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { selectors as alertsSelectors } from 'src/redux/data/alerts';
import { selectors as subscriberSelectors } from 'src/redux/data/subscribers';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';

const mapStateToProps = (state: RootState) => {
  const isDataLoading =
    alertsSelectors.getThresholdsStatus(state) ===
      DATA_FETCHING_STATUS.LOADING ||
    alertsSelectors.getBaselineThresholdsStatus(state) ===
      DATA_FETCHING_STATUS.LOADING ||
    subscriberSelectors.getSubscribersStatus(state) ===
      DATA_FETCHING_STATUS.LOADING;

  return {
    isDataLoading,
  };
};

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
