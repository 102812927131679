import { connect, ConnectedProps } from 'react-redux';
import * as A from 'fp-ts/lib/Array';
import * as O from 'fp-ts/lib/Option';

import {
  actions as alertActions,
  selectors as alertSelectors,
} from 'src/redux/data/alerts';
import { RootState } from 'src/redux/store';
import { UUID } from 'src/types/utility';
import { Alert } from 'src/types/alerts';
import { NoteCounterMetadata } from 'src/types/notes';

type AlertCardItem = {
  alertId: UUID;
  deviceId: UUID | null;
  patientId: UUID | null;
  notesCount?: NoteCounterMetadata;
};

const mapStateToProps = (state: RootState) => ({
  alertItems: A.filterMap<Alert, AlertCardItem>(alert =>
    ['SUPPRESSED', 'ON'].includes(alert.status) &&
    alert.thresholdMetric !== 'DEVICE_DISCONNECTED'
      ? O.some({
          alertId: alert.id,
          deviceId: alert.deviceId,
          patientId: alert.patientId,
        })
      : O.none,
  )(alertSelectors.selectAlerts(state)),
  areAlertsLoading: alertSelectors.areAlertsLoading(state),
});

const mapActionCreators = {
  suppressAlertType: alertActions.suppressAlertType,
  unSuppressAlertType: alertActions.unSuppressAlertType,
  clearAllPatientAlerts: alertActions.clearAllPatientAlerts,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;

export default Connector;
