import { styled } from 'styled-components';

import Icon from 'src/components/general-ui/Icon';

export const PatientStatusWrap = styled.div<{ itemsAlignment: string }>`
  position: relative;
  display: flex;
  flex-direction: ${props => props.itemsAlignment};
  align-items: center;
  div:nth-child(2) {
    svg {
      margin-top: 0;
    }
  }
`;

export const StyledIcon = styled(Icon)`
  min-width: 1rem;
  width: 1.5rem;
  height: 1rem;
  margin-top: 0.5rem;
  margin-left: 0.4rem;
`;

export const StyledGatherInfoIcon = styled(Icon)`
  width: 33px;
  height: 35px;
  margin-top: 0;
  margin-left: 0;
`;

export const StyledBellIconOutlined = styled(Icon)`
  position: absolute;
  top: 0;
  right: -0.3rem;
  width: 0.9rem;
  height: 1.2rem;
  opacity: ${props => props.opacity};
`;

export const StyledAlertIndicator = styled.p<{ color?: string }>`
  font-size: 0.6rem;
  font-weight: 700;
  line-height: 1;
  color: ${props => props.color ?? props.theme.colors.iconAlertOn};
  margin: 0 0 0 0.4rem;
  text-align: center;
`;
