import { useEffect, useState } from 'react';
import { INFINITE_SCROLL_PAGINATION } from 'src/utils/constants';
export const useInfiniteScrollProps = (
  visibleElements = INFINITE_SCROLL_PAGINATION,
): {
  noOfVisibleElements: number;
  fetchMoreData: () => void;
} => {
  const [noOfVisibleElements, setNoOfVisibleElements] = useState<number>(
    INFINITE_SCROLL_PAGINATION,
  );

  useEffect(() => {
    setNoOfVisibleElements(visibleElements);
  }, [visibleElements]);
  const fetchMoreData = () => {
    setNoOfVisibleElements(prevState => prevState + INFINITE_SCROLL_PAGINATION);
  };

  return {
    noOfVisibleElements,
    fetchMoreData,
  };
};
