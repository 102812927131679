import { defineMessages } from 'react-intl';
import theme from 'src/themes/theme';

export const HISTORY_TABS_KEYS = {
  SPOT: 'SPOT',
  CONTINUOUS: 'CONTINUOUS',
  HRI: 'HRI',
};

const HISTORY_INNER_TABS_KEYS = {
  TABLE: 'TABLE',
  GRAPHS: 'GRAPHS',
};

export const CONTINUOUS_TABS_KEYS = {
  ...HISTORY_INNER_TABS_KEYS,
};

export const SPOT_TABS_KEYS = {
  TABLE: 'SPOT_TABLE',
  GRAPHS: 'SPOT_GRAPHS',
};

const historyTableColumnsTitles = defineMessages({
  from: {
    defaultMessage: 'From',
  },
  to: {
    defaultMessage: 'To',
  },
  duration: {
    defaultMessage: 'Duration',
  },
  history: {
    defaultMessage: 'History',
  },
});

export const continuousTableColumnsTitles = historyTableColumnsTitles;

const HISTORY_TABLE_COLUMNS_KEYS = {
  FROM: 'FROM',
  TO: 'TO',
  DURATION: 'DURATION',
  HISTORY: 'HISTORY',
};

export const CONTINUOUS_TABLE_COLUMNS_KEYS = { ...HISTORY_TABLE_COLUMNS_KEYS };

export const continuousTableActionsTitles = defineMessages({
  view: {
    defaultMessage: 'View',
  },
});

export const METRIC_ENUM = Object.freeze({
  HR: 'HR',
  RR: 'RR',
  HRV: 'HRV',
  RD: 'RD',
  IE: 'IE',
  RA_bin: 'RA_bin',
});

export const historyMetricsToDisplay = Object.freeze([
  METRIC_ENUM.HR,
  METRIC_ENUM.RR,
  METRIC_ENUM.IE,
  METRIC_ENUM.RA_bin,
]);

export const DATE_TYPE_ENUM = Object.freeze({
  MINUTE: 'minute',
  HOUR: 'hour',
  DATE: 'date',
  MONTH: 'month',
  YEAR: 'year',
});
export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
const labelFontColor = '#818181';

const dateTypeToStepSize = {
  [DATE_TYPE_ENUM.MINUTE]: 3,
  [DATE_TYPE_ENUM.HOUR]: 3,
  [DATE_TYPE_ENUM.DATE]: 1,
  [DATE_TYPE_ENUM.MONTH]: 1,
  [DATE_TYPE_ENUM.YEAR]: 1,
};

const unitDisplayFormat = dateType => ({
  millisecond: 'YYYY-MM-DD HH:mm',
  second: 'ss',
  minute: 'mm',
  hour: 'h a',
  day: 'MM-DD',
  week: 'MM-DD',
  month: 'MM',
  quarter: 'YYYY-MM-DD HH:mm',
  year: 'YYYY-MM-DD HH:mm',
});
const regularDisplayFormat = {
  millisecond: 'YYYY-MM-DD',
  second: 'YYYY-MM-DD',
  minute: 'YYYY-MM-DD',
  hour: 'YYYY-MM-DD',
  day: 'YYYY-MM-DD',
  week: 'YYYY-MM-DD',
  month: 'YYYY-MM-DD',
  quarter: 'YYYY-MM-DD',
  year: 'YYYY-MM-DD',
};
export const TREND_LABELS = Object.freeze({
  AVG_TREND: 'AVG_TREND',
  MIN_TREND: 'MIN_TREND',
  MAX_TREND: 'MAX_TREND',
});
const trendLabelsArray = [
  TREND_LABELS.AVG_TREND,
  TREND_LABELS.MAX_TREND,
  TREND_LABELS.MIN_TREND,
];

export const trendDatasetMap = {
  [TREND_LABELS.AVG_TREND]: {
    label: TREND_LABELS.AVG_TREND,
    borderColor: theme.colors.avgTrendLine,
    backgroundColor: theme.colors.avgTrendLine,
  },
  [TREND_LABELS.MAX_TREND]: {
    label: TREND_LABELS.MAX_TREND,
    borderColor: theme.colors.maxTrendLine,
    backgroundColor: theme.colors.maxTrendLine,
  },
  [TREND_LABELS.MIN_TREND]: {
    label: TREND_LABELS.MIN_TREND,
    borderColor: theme.colors.minTrendLine,
    backgroundColor: theme.colors.maxTrendLine,
  },
};

const tooltipFormatMap = {
  [DATE_TYPE_ENUM.MONTH]: 'YYYY-MM-DD',
  [DATE_TYPE_ENUM.YEAR]: 'YYYY-MM',
};

const baseChartOptions = (startTime, endTime, dateType) => ({
  interaction: {
    mode: 'point',
  },
  stacked: true,
  plugins: {
    legend: {
      labels: {
        color: labelFontColor,
        filter(legendItem, chartData) {
          return !trendLabelsArray.includes(legendItem.text);
        },
      },
    },
  },
  scales: {
    xAxes: {
      type: 'time',
      time: {
        tooltipFormat: tooltipFormatMap[dateType] || null,
        displayFormats: dateType
          ? unitDisplayFormat(dateType)
          : regularDisplayFormat,
        stepSize: dateTypeToStepSize[dateType],
      },
      min: startTime && startTime.format(dateTimeFormat),
      max: endTime && endTime.format(dateTimeFormat),
      ticks: {
        autoSkip: true,
        maxTicksLimit: dateType ? null : 10,
        minRotation: 10,
        color: labelFontColor,
      },
      offset: false,
      grid: {
        offset: false,
      },
    },
    yAxes: {
      ticks: {
        color: labelFontColor,
      },
    },
  },
});
export const baseDataSet = {
  type: 'line',
  fill: false,
  pointRotation: 180,
  borderWidth: 2,
};
const HRChartOptions = (startTime, endTime, dateType) => {
  const baseOptions = baseChartOptions(startTime, endTime, dateType);
  return {
    ...baseOptions,
    scales: {
      ...baseOptions.scales,
      yAxes: {
        ...baseOptions.scales.yAxes,
        suggestedMin: 40,
        suggestedMax: 80,
        beginAtZero: false,
      },
    },
  };
};

const RRChartOptions = (startTime, endTime, dateType) => {
  const baseOptions = baseChartOptions(startTime, endTime, dateType);
  return {
    ...baseOptions,
    scales: {
      ...baseOptions.scales,
      yAxes: {
        ...baseOptions.scales.yAxes,
        suggestedMin: 0,
        suggestedMax: 20,
      },
    },
  };
};
const IEChartOptions = (startTime, endTime, dateType) => {
  const baseOptions = baseChartOptions(startTime, endTime, dateType);
  return {
    ...baseOptions,
    scales: {
      ...baseOptions.scales,
      yAxes: {
        ...baseOptions.scales.yAxes,
        suggestedMin: 0,
        suggestedMax: 5,
      },
    },
  };
};
const RAbinChartOptions = (startTime, endTime, dateType) => {
  const baseOptions = baseChartOptions(startTime, endTime, dateType);
  return {
    ...baseOptions,
    scales: {
      ...baseOptions.scales,
      yAxes: {
        ...baseOptions.scales.yAxes,
        suggestedMin: 0,
        suggestedMax: 5,
      },
    },
  };
};

export const chartOptionsByMetricType = (startTime, endTime, dateType) => ({
  [METRIC_ENUM.HR]: HRChartOptions(startTime, endTime, dateType),
  [METRIC_ENUM.RR]: RRChartOptions(startTime, endTime, dateType),
  [METRIC_ENUM.IE]: IEChartOptions(startTime, endTime, dateType),
  [METRIC_ENUM.RA_bin]: RAbinChartOptions(startTime, endTime, dateType),
});
export const digitsRoundByMetricType = Object.freeze({
  [METRIC_ENUM.HR]: 0,
  [METRIC_ENUM.RR]: 0,
  [METRIC_ENUM.IE]: 1,
  [METRIC_ENUM.RA_bin]: 0,
});

export const HRI_ACTIONS_KEYS = {
  DOWNLOAD: 'DOWNLOAD',
};

export const HRI_NOTIFICATION_TYPES = {
  DOWNLOAD: 'DOWNLOAD',
};
