import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import {
  selectors as alertDataSelectors,
  actions as alertDataActions,
} from 'src/redux/data/alerts';
import {
  selectors as measurementSettingsSelectors,
  actions as measurementSettingsDataActions,
} from 'src/redux/data/settings';
import dataActions from 'src/redux/data/dataActions';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { UUID } from 'src/types/utility';
import { actions } from './modules/slice';
import { AverageThresholdMetric, ThresholdPreposition } from 'src/types/alerts';

type DisplayThreshold = {
  id?: UUID;
  enable: boolean;
  preposition: ThresholdPreposition;
  value: number;
  updateAlert:
    | typeof alertDataActions.createAlert
    | typeof alertDataActions.editAlert;
};

export type DisplayMetricThresholds = {
  metric: AverageThresholdMetric;
  title: string;
  aboveThreshold: DisplayThreshold;
  belowThreshold: DisplayThreshold;
};

const mapStateToProps = (state: RootState) => ({
  alertThresholds: alertDataSelectors.selectThresholds(state),
  isLoadingSettings:
    measurementSettingsSelectors.getSettingsStatus(state) ===
    DATA_FETCHING_STATUS.LOADING,
  shouldDisplayHighQualityOnly:
    measurementSettingsSelectors.getShouldDisplayHighQualityOnly(state),
});

const mapActionCreators = {
  onUpdateMeasurementSettings:
    measurementSettingsDataActions.onUpdateMeasurementSettings,
  createAlertThreshold: alertDataActions.createAlert,
  editAlertThreshold: alertDataActions.editAlert,
  onSettingsPageMounted: actions.onSettingsPageMounted,
  onLoadSMSEmailAlert: dataActions.onLoadSMSEmailAlert,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
