import { defineMessages } from 'react-intl';

export const StatusMessages = defineMessages({
  title: {
    defaultMessage: 'Status Icons',
  },
  patientIsOutOfBed: {
    defaultMessage: 'Patient is out of bed',
  },
  patientInBedMonitored: {
    defaultMessage: 'Patient in bed, monitored',
  },
  patientInBedWithBedExitSetting: {
    defaultMessage: 'Patient in bed, marked for Vacancy Notification',
  },
  patientInBedActiveAlert: {
    defaultMessage: 'Patient in bed, with active alert',
  },
  patientInBedWithSuppressed: {
    defaultMessage: 'Patient in bed, with supressed alert',
  },
  patientInBedVacancyNotification: {
    defaultMessage: 'Patient in bed, marked for Vacancy Notification',
  },
  activeBedExitAlert: {
    defaultMessage: 'Active BedExit',
  },
  suppressededExitAlert: {
    defaultMessage: 'Suppressed BedExit',
  },
  bedExitFrequency: {
    defaultMessage: 'BedExit Frequency',
  },
  bedExitDuration: {
    defaultMessage: 'BedExit Duration',
  },
  assignPatientButton: {
    defaultMessage:
      'Device is connected & available {br} but not assigned to a patient',
  },
  patientOutOfDeviceRange: {
    defaultMessage: 'Patient is out of device field of view',
  },
  patientInOutBedAlertValue: {
    defaultMessage:
      'Patient in/out of bed time active alert, {br} alert value is indicated',
  },
  deviceIsDisconnected: {
    defaultMessage: 'No Icon - device is Disconnected',
  },
});

export const VitalSignsMessages = defineMessages({
  title: {
    defaultMessage: 'Vital Signs Box',
  },
  deviceConnected: {
    defaultMessage: 'Device is Connected',
  },
  activeAlert: {
    defaultMessage: 'Active Alert',
  },
  supressedAlert: {
    defaultMessage: 'Supressed Alert',
  },
  vitalSignsMeasurements: {
    defaultMessage: 'Vital Signs Measurements',
  },
  emptyBedNoData: {
    defaultMessage: 'Empty bed, No data',
  },
  noAvailableMeasurements: {
    defaultMessage: 'No available measurements',
  },
});

export const AlertIndicatorsMessages = defineMessages({
  title: {
    defaultMessage: 'Alert Indicators',
  },
  upperThresholdAlert: {
    defaultMessage: 'Upper Threshold Alert',
  },
  lowerThresholdAlert: {
    defaultMessage: 'Lower Threshold Alert',
  },
  highBaselineDeviationAlert: {
    defaultMessage: 'High Baseline Deviation Alert',
  },
  LowBaselineDeviationAlert: {
    defaultMessage: 'Low Baseline Deviation Alert',
  },
});
