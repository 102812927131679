import { messages, intl } from '../messages';
import { fileActions } from '../../constants';

export const fileActionToErrorNotificationMessagesMap = {
  [fileActions.DOWNLOAD]: (fileName, _) => ({
    message: intl.formatMessage(messages.downloadFileError),
    description: `${intl.formatMessage(
      messages.downloadFileError,
    )} ${fileName}`,
  }),
};

export const fileActionToSuccessNotificationMessagesMap = {};
