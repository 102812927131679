import React from 'react';

import { SVGProps } from '../types';

const PatientInBedIcon = ({
  width = '35',
  height = '22',
  color = '#252525',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      d="M9.625 11C12.0373 11 14 8.9438 14 6.41667C14 3.88953 12.0373 1.83333 9.625 1.83333C7.21273 1.83333 5.25 3.88953 5.25 6.41667C5.25 8.9438 7.21273 11 9.625 11ZM28.875 3.66667H16.625C16.1416 3.66667 15.75 4.07687 15.75 4.58333V12.8333H3.5V0.916667C3.5 0.410208 3.10844 0 2.625 0H0.875C0.391562 0 0 0.410208 0 0.916667V21.0833C0 21.5898 0.391562 22 0.875 22H2.625C3.10844 22 3.5 21.5898 3.5 21.0833V18.3333H31.5V21.0833C31.5 21.5898 31.8916 22 32.375 22H34.125C34.6084 22 35 21.5898 35 21.0833V10.0833C35 6.53927 32.258 3.66667 28.875 3.66667Z"
      fill={color}
    />
  </svg>
);

export default PatientInBedIcon;
