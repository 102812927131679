import React from 'react';

import { SVGProps } from '../types';

const PatientIcon = ({
  width = '21',
  height = '20',
  color = '#767881',
  backgroundcolor = '#fff',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <circle
      cx="10.4932"
      cy="10"
      r="9.5"
      fill={backgroundcolor}
      stroke={color}
    />
    <mask
      id="mask0_1154_8669"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width={width}
      height={height}
    >
      <circle cx="10.4932" cy="10" r="9.5" fill="white" stroke="#252525" />
    </mask>
    <g mask="url(#mask0_1154_8669)">
      <mask id="path-3-inside-1_1154_8669" fill="white">
        <path d="M10.5627 4.00003C9.32145 4.00003 8.20187 4.74785 7.72699 5.89459C7.25199 7.04242 7.51394 8.36199 8.3922 9.24013C9.27045 10.1183 10.59 10.3804 11.7377 9.90534C12.8845 9.43034 13.6323 8.31076 13.6323 7.06958C13.6323 6.25544 13.3084 5.47501 12.7334 4.89892C12.1573 4.32392 11.3769 4 10.5627 4L10.5627 4.00003Z" />
      </mask>
      <path
        d="M10.5627 4.00003C9.32145 4.00003 8.20187 4.74785 7.72699 5.89459C7.25199 7.04242 7.51394 8.36199 8.3922 9.24013C9.27045 10.1183 10.59 10.3804 11.7377 9.90534C12.8845 9.43034 13.6323 8.31076 13.6323 7.06958C13.6323 6.25544 13.3084 5.47501 12.7334 4.89892C12.1573 4.32392 11.3769 4 10.5627 4L10.5627 4.00003Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        mask="url(#path-3-inside-1_1154_8669)"
      />
      <path
        d="M17.9932 19.5C17.9932 24.5545 14.5582 28.5 10.4932 28.5C6.4281 28.5 2.99316 24.5545 2.99316 19.5C2.99316 14.4455 6.4281 10.5 10.4932 10.5C14.5582 10.5 17.9932 14.4455 17.9932 19.5Z"
        fill={color}
        stroke={color}
      />
    </g>
  </svg>
);

export default PatientIcon;
