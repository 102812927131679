import { actions as patientHistoryActions } from 'src/routes/Patients/HistoryModal/modules/slice';
import { HRI_NOTIFICATION_TYPES } from 'src/utils/graphsUtils/graphConstants';
import { intl, messages } from '../messages';

export const patientHistoryActionToErrorNotificationMessagesMap = {
  [patientHistoryActions.onSpotHistoryMount.type]: (patientId, _) => ({
    message: intl.formatMessage(messages.spotHistoryError),
    description: `${intl.formatMessage(
      messages.spotHistoryError,
    )} ${patientId}`,
  }),
  [HRI_NOTIFICATION_TYPES.DOWNLOAD]: (fileName, error) => ({
    message: intl.formatMessage(messages.downloadHriError),
    description: `${intl.formatMessage(messages.downloadHriDescription)}`,
  }),
};

export const patientHistoryActionToSuccessNotificationMessagesMap = {};
