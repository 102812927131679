import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { actions, extraSelectors } from '../../modules/slice';

const mapStateToProps = (state: RootState) => {
  const subtenants = extraSelectors.selectSubtenants(state);
  const loggedInGroupManager = subtenants.find(tenant => tenant.loggedInGm);

  return {
    loggedInGroupManager,
    subtenants,
  };
};

const mapActionCreators = {
  setSelectedTenantId: actions.setSelectedTenantId,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
