import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  SubtenantSettingItem,
  StyledDropdownIcon,
  TooltipWithoutArrow,
} from '../styled';
import StatusFilterSelector from './StatusFilterSelector';

const SubtenantStatus = () => {
  return (
    <TooltipWithoutArrow
      title={<StatusFilterSelector />}
      placement="bottom"
      overlayStyle={{ padding: '0' }}
      trigger="click"
    >
      <SubtenantSettingItem>
        <FormattedMessage {...messages.tenantStatus} />
        <StyledDropdownIcon type="dropdown-arrow-icon" />
      </SubtenantSettingItem>
    </TooltipWithoutArrow>
  );
};

const messages = defineMessages({
  tenantStatus: {
    defaultMessage: 'Filter',
  },
});

export default SubtenantStatus;
