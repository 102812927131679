import React, { useEffect, useState } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

import Connector, { IProps } from './Connector';
import { getDisconnectionOverviewColumns } from './utils';
import { StyledTable } from './styled';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import { filterDuplicates } from 'src/utils/dataUtils';
import { DEFAULT_PAGINATION } from 'src/utils/constants';

const DEFAULT_CONTINUOUS_DISC_SEC = 24 * 60 * 60; // 24h

type Props = IProps & {
  intl: IntlShape;
};

const DisconnectionOverviewTable = ({
  subtenantId,
  deviceDisconnectionTableItems,
  fetchSubtenantDisconnectionOverview,
  devicesDisconnectionOverviewMetadata,
  fetchSubtenantData,
  fetchMultitenantPatients,
  sessions,
  devicesDisconnectionOverview,
  isDataLoading,
  intl,
}: Props): JSX.Element => {
  const [tableParams, setTableParams] = useState({
    current: DEFAULT_PAGINATION.PAGE,
    pageSize: 20,
    showSizeChanger: false,
    total: 0,
  });
  const paginationChange = (page: number) => {
    setTableParams(prevState => ({
      ...prevState,
      current: page,
    }));
  };
  useEffect(() => {
    setTableParams(prevState => ({
      ...prevState,
      total: devicesDisconnectionOverviewMetadata?.totalResults || 0,
    }));
  }, [devicesDisconnectionOverviewMetadata?.totalResults]);

  useEffect(() => {
    fetchSubtenantData({
      dataKey: GM_DATA_KEYS.SESSIONS,
      subtenantIds: [subtenantId],
    });
  }, [subtenantId]);
  useEffect(() => {
    fetchSubtenantDisconnectionOverview({
      subtenantId,
      continuousDisconnectionSeconds: DEFAULT_CONTINUOUS_DISC_SEC,
      currentPage: tableParams?.current,
      pageSize: tableParams?.pageSize,
    });
  }, [tableParams?.current, tableParams?.pageSize, subtenantId]);

  useEffect(() => {
    const disconnectedDeviceIds = filterDuplicates(
      devicesDisconnectionOverview.map(device => device.manufacturerId),
    );
    const sessionPatientIds = sessions
      .filter(session => disconnectedDeviceIds.includes(session.deviceId))
      .map(session => session.patientId);

    fetchMultitenantPatients(sessionPatientIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions, devicesDisconnectionOverview]);

  const cols = getDisconnectionOverviewColumns(intl);
  return (
    <StyledTable
      data-cy={`disconnection-overview-loading=${isDataLoading.toString()}`}
      locale={{
        emptyText: intl.formatMessage(messages.noData),
      }}
      loading={isDataLoading}
      data={deviceDisconnectionTableItems}
      pagination={
        tableParams && tableParams.total > tableParams.pageSize
          ? {
              ...tableParams,
              onChange: paginationChange,
            }
          : false
      }
      columns={cols}
      scroll={{ y: '22rem' }}
    />
  );
};

const messages = defineMessages({
  noData: {
    defaultMessage: 'No currently disconnected devices in this tenant.',
  },
});

export default Connector(injectIntl(DisconnectionOverviewTable));
