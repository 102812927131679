import { styled } from 'styled-components';

import Icon from 'src/components/general-ui/Icon';

export const CardWrapper = styled.div`
  background: #fbfbfb;
  box-shadow: 0px 3px 2px rgba(78, 83, 96, 0.15);
  border-radius: 10px;
  padding: 0.5rem 0.6rem;
  color: #252525;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CardItems = styled.div``;

export const CardActions = styled.div<{ isTechnicalAlert?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: end;
  ${props =>
    props.isTechnicalAlert
      ? `
    pointer-events: none;
    opacity: 0.4;
  `
      : ''}
`;

export const PatientName = styled.div`
  & .ant-typography {
    font-family: ${props => props.theme.fonts.secondary};
    font-style: normal;
    font-weight: 400;
    font-size: 0.65rem;
    color: #767881;
    text-decoration-line: underline;
    cursor: pointer;
    width: 10rem;
    margin: 0.3rem 0 0 0;
  }
`;

export const StyledIcon = styled(Icon)`
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
  margin-right: 0.1rem;
  flex-shrink: 0;
`;

export const StyledDeleteIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;

export const CardContent = styled.div`
  margin-top: 0.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CardDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledTimestamp = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 0.65rem;
  margin-right: 0.8rem;
  white-space: nowrap;
`;

export const StyledThresholdMetric = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 0.7rem;
`;

export const StyledDisconnectionEventType = styled.div`
  font-weight: 700;
  font-size: 0.8rem;
  margin-bottom: 0.75rem;
`;

export const StyledTenantName = styled.div`
  padding: 0.15rem 0.5rem;
  background: #f4f6f8;
  border: 1px solid #e5e5e5;
  border-radius: 0.35rem;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 700;
  font-size: 0.6rem;
  color: #252525;
  width: fit-content;
  margin-bottom: 0.6rem;
`;

export const DeleteIconWrapper = styled.div`
  display: flex;
`;

export const AlertNotesWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 0.15rem;
`;

export const RoomDetails = styled.div`
  & .ant-typography {
    font-style: normal;
    font-weight: 500;
    font-size: 0.65rem;
    width: 10rem;
    margin: 0;
  }
`;

export const BoldItem = styled.span`
  font-weight: 800;
`;

export const ItemLabel = styled.span`
  font-weight: 400;
  padding-right: 0.2rem;
`;
