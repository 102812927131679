import React, { useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import SubtenantSettings from './SubtenantSettings';
import Connector, { PropsFromRedux } from './Connector';
import { CardsWrapper, Header, StyledCounter, Wrapper } from './styled';
import SubtenantCard from './SubtenantCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteScrollProps } from 'src/utils/hooks/useInfiniteScrollProps';
import { StyledText } from 'src/components/AppLayout/components/styled';

type Props = PropsFromRedux;

const SubtenantCards = ({
  subtenants,
  tenantsListMetadata,
  areSubtenantsLoading,
  filters,
  searchQuery,
}: Props): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);
  const [noOfTenantCardsInTheView, setNoOfTenantCardsInTheView] = useState(40);
  const MIN_CONTAINER_WIDTH = 2450;
  // Make this for larger screens in order to be sure infinite scroll it's working properly

  useEffect(() => {
    const tenantsCardContainerWidth = ref.current ? ref.current.offsetWidth : 0;
    if (tenantsCardContainerWidth > MIN_CONTAINER_WIDTH) {
      setNoOfTenantCardsInTheView(65);
    }
  }, [ref.current]);

  const totalTenantsNumber =
    searchQuery || !!filters?.status?.length
      ? subtenants.length
      : tenantsListMetadata.page.totalResults;
  const { noOfVisibleElements, fetchMoreData } = useInfiniteScrollProps(
    noOfTenantCardsInTheView,
  );
  return (
    <Wrapper>
      <Header>
        <StyledCounter>
          {totalTenantsNumber}
          &nbsp;
          {totalTenantsNumber === 1 ? (
            <FormattedMessage {...messages.tenant} />
          ) : (
            <FormattedMessage {...messages.tenants} />
          )}
        </StyledCounter>
        <SubtenantSettings />
      </Header>
      <CardsWrapper
        data-cy={`subtenants-list-loading=${areSubtenantsLoading.toString()}`}
        ref={ref}
        id="subtenantsListScrollableDiv"
      >
        {!!subtenants.length && (
          <InfiniteScroll
            dataLength={subtenants.slice(0, noOfVisibleElements).length} //This is important field to render the next data
            next={fetchMoreData}
            hasMore={subtenants.length > noOfVisibleElements}
            loader={
              <StyledText>
                <FormattedMessage {...messages.loadingOnScroll} />
              </StyledText>
            }
            scrollableTarget="subtenantsListScrollableDiv"
            style={{ gap: '1rem', flexWrap: 'wrap', flexDirection: 'row' }}
          >
            {subtenants.slice(0, noOfVisibleElements).map(subtenant => (
              <SubtenantCard key={subtenant.id} {...subtenant} />
            ))}
          </InfiniteScroll>
        )}
      </CardsWrapper>
    </Wrapper>
  );
};

const messages = defineMessages({
  tenant: {
    defaultMessage: 'Tenant',
  },
  tenants: {
    defaultMessage: 'Tenants',
  },
  loadingOnScroll: {
    defaultMessage: 'Loading...',
  },
});

export default Connector(SubtenantCards);
