import * as R from 'fp-ts/lib/Record';
import { trivial } from 'fp-ts/lib/Ord';

import { UUID } from 'src/types/utility';
import {
  DataTenantDict,
  SingleResponseProcessor,
  ArrayResponseProcessor,
} from './types';
import { INVALID_CACHE_ID } from './constants';

export const mapSingleItemResponse = <T>(
  tenantsData: Record<string, T>,
  processor: SingleResponseProcessor<T>,
) =>
  R.reduceWithIndex(trivial)<UUID, T, Record<UUID, UUID>>(
    {},
    (tenantId, acc, response) => ({
      ...acc,
      [processor(response)]: tenantId,
    }),
  )(tenantsData);

export const mapMultiItemResponse = <T>(
  tenantsData: Record<string, T>,
  processor: ArrayResponseProcessor<T>,
) =>
  R.reduceWithIndex(trivial)<UUID, T, Record<UUID, UUID>>(
    {},
    (tenantId, acc, response) => {
      const dict: DataTenantDict = processor(response).reduce(
        (acc, entityId) => ({
          ...acc,
          [entityId]: tenantId,
        }),
        {},
      );

      return {
        ...acc,
        ...dict,
      };
    },
  )(tenantsData);

export const isLatestData = (
  id: UUID,
  dataTenantDict: DataTenantDict,
): boolean => {
  const dataValue = dataTenantDict[id];

  return !!dataValue && dataValue !== INVALID_CACHE_ID;
};
