import React from 'react';
import { IntlShape, injectIntl, defineMessages } from 'react-intl';

import Checkbox from 'src/components/general-ui/Checkbox';
import RadioGroup from 'src/components/general-ui/RadioGroup';
import { SingleDeviceDisconnectionSettings } from '../../types';
import { RadioboxesWrapper } from './styled';
import { ALL_DISCONNECTIONS, ONLY_ASSIGNED_DEVICES } from '../../constants';

type Props = {
  settings: SingleDeviceDisconnectionSettings;
  disabled: boolean;
  handleChangeEnabled: (isEnabled: boolean) => void;
  handleChangeType: (
    type: SingleDeviceDisconnectionSettings['deviceDisconnectionType'],
  ) => void;
  intl: IntlShape;
};

const SingleDeviceDisconnection = ({
  settings,
  disabled,
  handleChangeEnabled,
  handleChangeType,
  intl,
}: Props): JSX.Element => {
  const { isEnabled, deviceDisconnectionType } = settings;

  const getDeviceConnectionAlertOptions = (intl: IntlShape) => [
    {
      id: 'allDisconnected',
      label: intl.formatMessage(messages.allDisconnectedDevices),
      value: ALL_DISCONNECTIONS,
    },
    {
      id: 'assignedDisconnected',
      label: intl.formatMessage(messages.assignedDisconnectedDevices),
      value: ONLY_ASSIGNED_DEVICES,
    },
  ];

  return (
    <>
      <Checkbox
        label={intl.formatMessage(messages.notificationPerDevice)}
        checked={isEnabled}
        handleChange={handleChangeEnabled}
        disabled={disabled}
      />
      <RadioboxesWrapper>
        <RadioGroup
          selectedOption={deviceDisconnectionType}
          onSelect={handleChangeType}
          optionsList={getDeviceConnectionAlertOptions(intl)}
          disabledOption={disabled || !isEnabled}
        />
      </RadioboxesWrapper>
    </>
  );
};

const messages = defineMessages({
  notificationPerDevice: {
    defaultMessage: 'Notification per device disconnection:',
  },
  allDisconnectedDevices: {
    defaultMessage: 'Assigned & Unassigned devices',
  },
  assignedDisconnectedDevices: {
    defaultMessage: 'Only assigned devices',
  },
});

export default injectIntl(SingleDeviceDisconnection);
