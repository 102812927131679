export const ALERT_STATUSES = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  SUPPRESSED: 'suppressed',
};

export const SUPPRESSION_OPTIONS = {
  oneHour: 1,
  twoHours: 2,
  sixHours: 6,
  twelveHours: 12,
  twentyFourHours: 24,
};
