import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Icon from 'src/components/general-ui/Icon';
import { styled } from 'styled-components';

import { DeviceSettingItem } from '../styled';
import AlertSettingsModal from './AlertSettingsModal';
import { Tooltip } from 'antd';
import { ToolTipMessages } from 'src/translations/messages/toolTipMessages';
import Connector from './Connector';

export const StyledSettingsIcon = styled(Icon)`
  width: 0.75rem;
  height: 0.8rem;
  margin-right: 0.4rem;
`;

const AlertSettings = ({
  areAlertSettingsDataLoading,
}: {
  areAlertSettingsDataLoading: boolean;
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  return (
    <div
      data-cy={`NS-alert-settings-loading=${areAlertSettingsDataLoading.toString()}`}
    >
      <Tooltip title={<FormattedMessage {...ToolTipMessages.alertSettings} />}>
        <DeviceSettingItem onClick={() => setIsModalVisible(true)}>
          <StyledSettingsIcon type="settings-icon" />
          <FormattedMessage {...messages.alertSettings} />
        </DeviceSettingItem>
      </Tooltip>
      {isModalVisible && (
        <AlertSettingsModal
          isModalVisible={isModalVisible}
          areAlertSettingsDataLoading={areAlertSettingsDataLoading}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </div>
  );
};

const messages = defineMessages({
  alertSettings: {
    defaultMessage: 'Alert Settings',
  },
});

export default Connector(AlertSettings);
