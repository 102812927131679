/**
 * Get / Set token expected object:
 * {
 *  token: <relevant_token>: String
 *  expirationTime: <timestamp>: Number.
 * }
 */

import { UserLoginData } from 'src/types/users';

const MAX_INT32 = 0x7fffffff;
const DEFAULT_TIME_BEFORE_REFRESH = 1000 * 60; // 1 min
const DEFAULT_MAX_TIMEOUT = 1000 * 60 * 60 * 24 * 2; // 2 days

type RefreshTokenFn = (data: {
  accessToken: string | undefined;
  refreshToken: string | undefined;
}) => Promise<UserLoginData>;

type AccessTokenItem = { accessToken: string; expirationTime: number };
type RefreshTokenItem = { refreshToken: string; expirationTime: number };

class TokenHandler {
  _refreshTokenObject: RefreshTokenItem | null;
  _accessTokenObject: AccessTokenItem | null;
  _refreshFunction: RefreshTokenFn;
  _timeBeforeRefresh: number;
  _maxTimeoutInMilliseconds: number;
  _currentTimeout: ReturnType<typeof setTimeout> | null;

  setConfig = ({
    onRefreshToken,
    timeBeforeRefresh = DEFAULT_TIME_BEFORE_REFRESH,
    maxTimeoutInMilliseconds = DEFAULT_MAX_TIMEOUT,
  }: {
    onRefreshToken: RefreshTokenFn;
    timeBeforeRefresh?: number;
    maxTimeoutInMilliseconds?: number;
  }) => {
    if (maxTimeoutInMilliseconds > MAX_INT32) {
      throw `maxTimeoutInMilliseconds cannot be larger than ${MAX_INT32}`;
    }

    this._refreshFunction = onRefreshToken;

    this._timeBeforeRefresh = timeBeforeRefresh;
    this._maxTimeoutInMilliseconds = maxTimeoutInMilliseconds;

    this._currentTimeout = null;
  };

  resetTokens = () => {
    this._removeTimeout();
    this._accessTokenObject = null;
    this._refreshTokenObject = null;
  };

  setAccessToken = ({
    token,
    expirationTime,
  }: {
    token: string;
    expirationTime: number;
  }) => {
    this._accessTokenObject = { accessToken: token, expirationTime };

    this._removeTimeout();

    if (!expirationTime || !token) {
      console.warn(
        'Expiration time or accessToken not set, not running refresh timeout.',
      );
      return;
    }

    const runTimeoutDate = expirationTime - this._timeBeforeRefresh;

    if (runTimeoutDate - new Date().getTime() <= 500) {
      console.warn(
        'Expiration time and timeBeforeRefresh are too close (less than 500 ms), canceling refresh token',
      );
      return;
    }

    this._currentTimeout = this._runAtDate(
      runTimeoutDate,
      this._refreshAccessToken,
    );
  };

  _runAtDate = (
    dateInMs: number,
    func: () => void,
  ): ReturnType<typeof setTimeout> => {
    const now = new Date().getTime();
    const then = dateInMs;
    const diff = Math.max(then - now, 0);

    if (diff > this._maxTimeoutInMilliseconds) {
      return setTimeout(() => {
        this._currentTimeout = this._runAtDate(dateInMs, func);
      }, this._maxTimeoutInMilliseconds);
    }

    return setTimeout(func, diff);
  };

  getAccessToken = (): AccessTokenItem | null => {
    // Returning duplicate so no one can change from the outside.
    return this._accessTokenObject || null;
  };

  setRefreshToken = ({
    token,
    expirationTime,
  }: {
    token: string;
    expirationTime: number;
  }) => {
    this._refreshTokenObject = { refreshToken: token, expirationTime };
  };

  getRefreshToken = (): RefreshTokenItem | null => {
    // Returning duplicate so no one can change from the outside.
    return this._refreshTokenObject || null;
  };

  _removeTimeout = () => {
    this._currentTimeout && clearTimeout(this._currentTimeout);
    this._currentTimeout = null;
  };

  _refreshAccessToken = () => {
    this._currentTimeout = null;

    if (!this._refreshFunction) {
      console.warn('Need to refresh token but refresh function not set.');
      return;
    }

    this._refreshFunction({
      accessToken: this.getAccessToken()?.accessToken,
      refreshToken: this.getRefreshToken()?.refreshToken,
    }).catch(e => console.error(e));
  };
}

export default TokenHandler;
