import { defineMessages } from 'react-intl';

export const monitorMessages = defineMessages({
  // error
  startFailedMessage: {
    defaultMessage: 'Start Failed',
  },
  startFailedDescription: {
    defaultMessage: 'Failed to start device session for device',
  },
  stopFailedMessage: {
    defaultMessage: 'Stop Failed',
  },
  stopFailedDescription: {
    defaultMessage: 'Failed to stop device session for device',
  },
  hriResultFailedMessage: {
    defaultMessage: 'Computing HRV Failed',
  },
  hriResultFailedDescription: {
    defaultMessage: 'Failed to compute HRV for this session on device',
  },

  // success
  startSuccessMessage: {
    defaultMessage: 'Start Succeeded',
  },
  startSuccessDescription: {
    defaultMessage:
      'Session for device: {deviceId} has been started successfully',
  },
  stopSuccessMessage: {
    defaultMessage: 'Stop Succeeded',
  },
  stopSuccessDescription: {
    defaultMessage:
      'Session for device: {deviceId} has been stopped successfully',
  },

  // warnings
  sessionInactiveAndReceivingDataMessage: {
    defaultMessage: 'Error',
  },
  sessionInactiveAndReceivingDataDescription: {
    defaultMessage:
      'It appears that a problem has occurred. Please try resetting the device.',
  },
});
