import React from 'react';

import history from 'src/utils/history';
import { DEFAULT_LOCALE } from 'src/utils/languageUtils';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import { SerialNumber, UUID } from 'src/types/utility';
import { StyledDeviceInfo } from './styled';

import { Typography } from 'antd';
import { deepEqual } from 'src/utils/comparators';

const onPatientNameClick = (
  hasAssignedPatient: boolean,
  patientId: UUID | undefined,
) => {
  hasAssignedPatient &&
    history.push({
      pathname: `/${DEFAULT_LOCALE}/${appRoutes.INDIVIDUAL_DASHBOARD}`,
      search: `?patientId=${patientId || ''}`,
    });
};

const { Paragraph } = Typography;

type Props = {
  deviceId: SerialNumber;
  patientId: UUID | undefined;
  patientName: string | undefined;
  hasAssignedPatient: boolean;
  hasAssignedLocation: boolean;
};

const DeviceInfo = ({
  deviceId,
  patientId,
  patientName,
  hasAssignedPatient,
  hasAssignedLocation,
}: Props): JSX.Element | null => {
  if (hasAssignedPatient) {
    return (
      <StyledDeviceInfo
        data-cy={patientId && `dashboard-btn-${patientId}`}
        hasAssignedPatient={hasAssignedPatient}
        onClick={() => onPatientNameClick(hasAssignedPatient, patientId)}
      >
        <Paragraph
          ellipsis={{
            rows: 1,
            tooltip: patientName,
          }}
        >
          {patientName || '--'}
        </Paragraph>
      </StyledDeviceInfo>
    );
  }

  if (hasAssignedLocation) {
    return null;
  }

  return (
    <StyledDeviceInfo>
      <Paragraph
        ellipsis={{
          rows: 1,
          tooltip: deviceId,
        }}
      >
        {deviceId}
      </Paragraph>
    </StyledDeviceInfo>
  );
};

export default React.memo(DeviceInfo, (oldProps, newProps) =>
  deepEqual(oldProps, newProps),
);
