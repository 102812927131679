import React from 'react';
import { Tooltip, TooltipProps } from 'antd';

type Props = TooltipProps & React.RefAttributes<unknown>;

const TypedCustomTooltip = (props: Props) => (
  <Tooltip overlayClassName={props.className} {...props}>
    {props.children}
  </Tooltip>
);

export default TypedCustomTooltip;
