import styled from 'styled-components';
import { Table, Checkbox } from 'antd';

import ActionButton from 'src/components/general-ui/Buttons/ActionButton';

export const StyledService = styled.div`
  padding: 1rem 0;
  min-height: 50vh;
`;

export const StyledTable: typeof Table = styled(Table)`
  min-height: 35vh;
  .ant-table-thead th {
    background-color: ${props => props.theme.colors.MainContentBackground};
  }
  table
    tr
    th.ant-table-cell.ant-table-selection-column.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
    display: inline-block;
  }
  table td.ant-table-cell {
    font-size: 0.6rem;
  }
  table th.ant-table-cell {
    font-size: 0.6rem;
    white-space: break-spaces;
  }
  .ant-table-cell {
    min-width: 9.375rem !important;
  }
  .ant-table-cell {
    padding: 0.2rem;
    div {
      height: 1.4rem !important;
      button {
        height: 1.4rem !important;
      }
      hr {
        height: 1.4rem !important;
        margin: 0 1.4rem;
      }
    }
  }
  .ant-form-item-control-input {
    min-height: 1rem !important;
  }
  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 0.5rem;
  }
  input {
    font-size: 0.5rem;
    padding: 0.2rem;
    width: calc(100% - 40px);
    margin-top: 0;
    position: absolute;
    top: 0;
  }
  .editable-cell {
    position: relative;
  }
  .editable-cell-value-wrap {
    padding: 0.2rem;
    cursor: pointer;
    min-height: 1.2rem;
  }
  .editable-row:hover .editable-cell-value-wrap {
    padding: 0.2rem;
    border: ${props => `1px solid ${props.theme.colors.hoverColor}`};
    border-radius: 2px;
  }
  .ant-form-item-explain-error {
    font-size: 0.3rem;
    margin-top: -0.1rem;
    margin-left: 0.2rem;
  }
`;

export const StyledColumnHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCheckbox: typeof Checkbox = styled(Checkbox)`
  margin-right: 10px;
`;

export const StyledActionButton = styled(ActionButton)`
  margin-top: 20px;
`;
