import { connect } from 'react-redux';
import Timer from 'src/components/Timer';
import { selectors } from '../modules/slice';
import {
  actions as monitorActions,
  selectors as monitorSelectors,
} from '../../../redux/data/monitor';

const mapStateToProps = state => {
  const selectedDeviceId = selectors.getDevice(state)?.manufacturerId;
  return {
    isTimerOn: monitorSelectors.getIsTimerOn(state, selectedDeviceId),
    startTime: monitorSelectors.getStartTime(state, selectedDeviceId),
    currentTime: monitorSelectors.getCurrentTime(state, selectedDeviceId),
  };
};

const mapActionCreators = {};

export default connect(mapStateToProps, mapActionCreators)(Timer);
