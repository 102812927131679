/**
 * Get / Set token expected object:
 * {
 *  token: <relevant_token>: String
 *  expirationTime: <timestamp>: Number.
 * }
 */

class TokenHandler {
  setConfig = ({ onRefreshToken, timeBeforeRefresh = 60 * 1000 }) => {
    this._refreshFunction = onRefreshToken;

    this._timeBeforeRefresh = timeBeforeRefresh;

    this._currentTimeout = null;
  };

  resetTokens = () => {
    this._removeTimeout();
    this._accessTokenObject = null;
    this._refreshTokenObject = null;
  };

  setAccessToken = ({ token, expirationTime }) => {
    this._accessTokenObject = { accessToken: token, expirationTime };

    if (!expirationTime || !token) {
      console.warn(
        'Expiration time or accessToken not set, not running refresh timeout.',
      );
      this._removeTimeout();
      return;
    }

    const expirationInMiliseconds = new Date(expirationTime) - new Date();
    if (expirationInMiliseconds - this._timeBeforeRefresh <= 500) {
      console.warn(
        'Expiration time and timeBeforeRefresh are too close (less than 500 ms), canceling refresh token',
      );
      this._removeTimeout();
      return;
    }

    if (this._currentTimeout) {
      this._removeTimeout();
    }

    this._currentTimeout = setTimeout(
      this._refreshAccessToken,
      expirationInMiliseconds - this._timeBeforeRefresh,
    );
  };

  getAccessToken = () => {
    // Returning duplicate so no one can change from the outside.
    if (this._accessTokenObject) {
      return this._accessTokenObject;
    } else {
      return null;
    }
  };

  setRefreshToken = ({ token, expirationTime }) => {
    this._refreshTokenObject = { refreshToken: token, expirationTime };
  };

  getRefreshToken = () => {
    // Returning duplicate so no one can change from the outside.
    if (this._refreshTokenObject) {
      return this._refreshTokenObject;
    } else {
      return null;
    }
  };

  _removeTimeout = () => {
    clearTimeout(this._currentTimeout);
    this._currentTimeout = null;
  };

  _refreshAccessToken = () => {
    this._currentTimeout = null;

    if (!this._refreshFunction) {
      console.warn('Need to refresh token but refresh function not set.');
      return;
    }

    this._refreshFunction({
      accessToken: this.getAccessToken()?.accessToken,
      refreshToken: this.getRefreshToken()?.refreshToken,
    });
  };
}

export default TokenHandler;
