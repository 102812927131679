import { DateUTC, UUID } from './utility';

export enum NoteType {
  GENERAL = 'GENERAL',
  IMPORTANT = 'IMPORTANT',
}

export type Note = {
  id: UUID;
  patientId: UUID;
  tenantId: UUID;
  note: string;
  type: NoteType;
  createdBy: string;
  creationTime: DateUTC;
};

export type NoteCounterMetadata = {
  regular: number;
  important: number;
};
