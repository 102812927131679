import { all, takeLatest, call, put, select } from 'typed-redux-saga/macro';

import {
  actionSuccessNotification,
  notifyUserByActionTypeAndCode,
} from 'src/utils/errorHandling/notifications';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import UmsLogicSdk from 'src/libs/ums-js-logic';
import BackendService from 'src/services/BackendService';
import dataActions from 'src/redux/data/dataActions';
import {
  parseFormDataToUpdateUserRequest,
  parseFormDataToInviteUserRequest,
  parseFormDataToUpdateUserPhoneRequest,
  parseFormDataToUpdateUserMfaRequest,
} from './parser';
import { actions, getState } from './slice';
import { USER_TYPES } from 'src/utils/constants';
import { User } from 'src/types/users';

export function* fetchUserTypes() {
  try {
    const response = yield* call(BackendService.getAllUserTypes, {});
    yield* put(actions.fetchUserTypesSuccess(response.data));
  } catch (e) {
    console.error('error in fetchUserTypes: ', e);
    yield* put(actions.fetchUserTypesFail());
  }
}

export function* fetchUsersAndInvitedUsersList() {
  try {
    yield* call(fetchUserTypes);

    const usersResponse = yield* call(BackendService.getAllUsers, {});
    const usersResponseWithMfaInfo = yield* call(
      BackendService.getMfaUsersData,
      {},
    );
    let userWithMfaInfo: User[] = [];
    const invitedUsersResponse = yield* call(BackendService.getInvitedUsers);
    usersResponse?.data?.data?.forEach(userData => {
      const mfaUser = usersResponseWithMfaInfo.data.data.filter(
        mfaUserData => mfaUserData.id === userData.id,
      );
      const mfaPhone = mfaUser[0]?.mfaPhone;
      const mfa = mfaUser[0]?.mfa;
      userWithMfaInfo = [...userWithMfaInfo, { ...userData, mfaPhone, mfa }];
    });
    yield* put(
      actions.fetchAllUsersSuccess({
        users: { ...usersResponse.data, data: userWithMfaInfo },
        invitedUsers: invitedUsersResponse.data,
      }),
    );
  } catch (e) {
    console.error('error in fetchUsersAndInvitedUsersList: ', e);
    yield* put(actions.fetchAllUsersFail());
  }
}

export function* fetchExistentUsers() {
  try {
    const usersResponse = yield* call(BackendService.getAllUsers, {});
    yield* put(
      actions.fetchExistentUsersSuccess({
        users: usersResponse.data,
      }),
    );
  } catch (e) {
    console.error('error in fetchExistentUsers: ', e);
    yield* put(actions.fetchExistentUsersFail());
  }
}

export function* fetchMtmUsers() {
  try {
    let finalUserTypes;

    const { userTypes } = yield* select(getState);

    finalUserTypes = userTypes.data;

    if (userTypes.data.length === 0) {
      yield* call(fetchUserTypes);
      const { userTypes } = yield* select(getState);
      finalUserTypes = userTypes.data;
    }

    const userTypeId =
      finalUserTypes.find(
        userType => userType.name === USER_TYPES.MULTI_TENANT_MANAGER,
      )?.id || '';

    const { data } = yield* call(BackendService.getUsersByUserType, userTypeId);

    yield* put(actions.fetchMtmUsersListSuccess(data));
  } catch (e) {
    console.error('error in fetchMtmUsersList: ', e);
    yield* put(actions.fetchMtmUsersListFail());
  }
}

function* inviteUser(action: ReturnType<typeof actions.inviteUser>) {
  const formData = action.payload;
  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));
    const request = parseFormDataToInviteUserRequest(formData);
    yield* call(BackendService.inviteUser, request);

    actionSuccessNotification(action.type, formData);

    yield* call(fetchUsersAndInvitedUsersList);
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
  } catch (error) {
    console.error('error in inviteUser: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));

    notifyUserByActionTypeAndCode(
      action.type,
      { firstName: formData?.firstName, lastName: formData?.lastName },
      error,
    );
  }
}

function* deleteUser(action: ReturnType<typeof actions.deleteUser>) {
  const userId = action.payload;
  try {
    yield* call(BackendService.deleteUser, userId);
    yield* put(actions.deleteUserSuccess(userId));
  } catch (error) {
    console.error('error in deleteUser: ', error);
    notifyUserByActionTypeAndCode(action.type, userId, error);
  }
}

function* updateUser(action: ReturnType<typeof actions.updateUser>) {
  const { userId, data } = action.payload;
  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));

    const request = { userId, data: parseFormDataToUpdateUserRequest(data) };

    yield* call(BackendService.updateUser, request);
    yield* put(actions.updateMfaUserInfo({ userId, data }));
    yield* call(fetchUsersAndInvitedUsersList);
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
  } catch (error) {
    console.error('error in updateUser: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));

    notifyUserByActionTypeAndCode(
      action.type,
      { firstName: data?.firstName, lastName: data?.lastName },
      error,
    );
  }
}

function* updateUserPhoneNumber(
  action: ReturnType<typeof actions.updateUserPhone>,
) {
  const { userId, data } = action.payload;

  try {
    const request = {
      userId,
      data: parseFormDataToUpdateUserPhoneRequest(data),
    };

    yield* call(BackendService.updateUser, request);
    yield* call(fetchUsersAndInvitedUsersList);

    actionSuccessNotification(action.type, null);
  } catch (error) {
    console.error('error in updateUserPhoneNumber: ', error);
    notifyUserByActionTypeAndCode(action.type, {}, error);
  }
}
function* updateMfaUserInfo(action: ReturnType<typeof actions.updateUser>) {
  const { userId, data } = action.payload;
  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));
    const request = { userId, data: parseFormDataToUpdateUserMfaRequest(data) };
    yield* call(BackendService.updateMfaUserInfo, request);
  } catch (error) {
    console.error('error in updateMfaUserInfo: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, error, error);
  }
}

function* sendMessageForResetPassword(
  action: ReturnType<typeof actions.sendMessageForResetPassword>,
) {
  const { email } = action.payload;
  try {
    yield* call([UmsLogicSdk, UmsLogicSdk.forgotPassword], { username: email });

    actionSuccessNotification(action.type);
  } catch (error) {
    console.error('Error in send message for reset password: ', error);
    notifyUserByActionTypeAndCode(action.type, action.payload, error);
  }
}

function* resendInvitation(
  action: ReturnType<typeof actions.resendInvitation>,
) {
  const record = action.payload;
  try {
    yield* call(BackendService.resendInvitation, record.id);
    actionSuccessNotification(action.type, record);
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, record, error);
  }
}

function* deleteInvitation(
  action: ReturnType<typeof actions.deleteInvitation>,
) {
  const userId = action.payload;
  try {
    yield* call(BackendService.deleteInvitation, userId);
    yield* put(actions.deleteInvitationSuccess(userId));
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, userId, error);
  }
}

export default function* watchUserActions() {
  yield* all([
    takeLatest(actions.fetchUserTypes, fetchUserTypes),
    takeLatest(actions.fetchAllUsers, fetchUsersAndInvitedUsersList),
    takeLatest(actions.fetchExistentUsers, fetchExistentUsers),
    takeLatest(actions.inviteUser, inviteUser),
    takeLatest(actions.deleteUser, deleteUser),
    takeLatest(actions.updateUser, updateUser),
    takeLatest(actions.updateMfaUserInfo, updateMfaUserInfo),
    takeLatest(
      actions.sendMessageForResetPassword,
      sendMessageForResetPassword,
    ),
    takeLatest(actions.resendInvitation, resendInvitation),
    takeLatest(actions.deleteInvitation, deleteInvitation),
    takeLatest(actions.updateUserPhone, updateUserPhoneNumber),
    takeLatest(dataActions.onLoadSMSEmailAlert, fetchUsersAndInvitedUsersList),
    takeLatest(actions.fetchMtmUsers, fetchMtmUsers),
  ]);
}
