import { defineMessages } from 'react-intl';

export const groupMessages = defineMessages({
  // error
  createGroupError: {
    defaultMessage: 'Could not create group',
  },
  deleteGroupError: {
    defaultMessage: 'Could not delete group',
  },
  editGroupError: {
    defaultMessage: 'Could not edit group',
  },
  updateVersionError: {
    defaultMessage: 'Could not update version for group',
  },
  editAppError: {
    defaultMessage: 'Could not update version for All Devices',
  },
  createGroupSuccess: {
    defaultMessage: 'Create Group successfully',
  },
  deleteGroupSuccess: {
    defaultMessage: 'Deleted group successfully',
  },
  editGroupSuccess: {
    defaultMessage: 'Updated group successfully',
  },
  updateVersionSuccess: {
    defaultMessage: 'Version published to group successfully',
  },
  editAppSuccess: {
    defaultMessage: 'Updated All Devices group successfully',
  },
});
