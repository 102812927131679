import React from 'react';
import { StyledLink } from './styled';
import { Tooltip } from 'antd';
import { sideBarLabelMessages } from '../../AlertSidebar/modules/tabConfig';
import { IntlShape, injectIntl } from 'react-intl';
import { ToolTipMessages } from 'src/translations/messages/toolTipMessages';
import { MTMsideBarLabelMessages } from '../../AlertSidebarGroupManager/modules/tabConfig';

interface Props<TKey extends string> {
  id: TKey;
  label: string;
  isCurrent: boolean;
  onClick: (id: TKey) => void;
  intl: IntlShape;
}

const TabLink = <TKey extends string>({
  intl,
  id,
  onClick,
  label,
  isCurrent,
}: Props<TKey>): JSX.Element => {
  const getTooltipText = (label: string) => {
    switch (label) {
      case intl.formatMessage(sideBarLabelMessages.currentAlertsTab):
        return intl.formatMessage(ToolTipMessages.currentAlertsTab);
      case intl.formatMessage(sideBarLabelMessages.eventLogTab):
        return intl.formatMessage(ToolTipMessages.eventLogTab);
      case intl.formatMessage(MTMsideBarLabelMessages.attentionList):
        return intl.formatMessage(ToolTipMessages.eventLogTab);
      default:
        return '';
    }
  };
  return (
    <StyledLink
      isCurrent={isCurrent}
      onClick={() => !isCurrent && onClick(id)}
      data-cy={`${id}-tab-link`}
    >
      <Tooltip title={getTooltipText(label)}>{label}</Tooltip>
    </StyledLink>
  );
};

export default injectIntl(TabLink);
