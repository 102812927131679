import { ConnectedProps, connect } from 'react-redux';

import {
  selectors as idbSelectors,
  selectors,
} from 'src/routes/IndividualDashboard/modules/dashboard';
import { RootState } from 'src/redux/store';
import { selectors as patientSelectors } from 'src/redux/data/patient';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import dataSelectors from 'src/redux/data/dataSelectors';

const mapStateToProps = (state: RootState) => ({
  isDashboardLoading: selectors.selectIsDashboardLoading(state),
  isLoading: selectors.selectIsDataLoading(state),
  isPatientListLoading:
    patientSelectors.getStatus(state) === DATA_FETCHING_STATUS.LOADING,
  patientsList: dataSelectors.selectAssignedDevicePatients(state),
  selectedPatientId: idbSelectors.getCurrentPatientId(state),
});

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
