import { connect } from 'react-redux';
import Continuous from '../components/Continuous';
import { actions, selectors } from '../modules/slice';

const mapStateToProps = (state, ownProps) => ({
  sessionsDataStatus: selectors.getPatientSessionDataStatus(
    state,
    ownProps.patientId,
  ),
  sessionsData: selectors.getPatientSessionData(state, ownProps.patientId),
  allSessionsGraphsPoints: selectors.getAllSessionsGraphsPoints(state),
});

const mapActionCreators = {
  getAllSessions: actions.getAllSessions,
  viewSessionGraphs: actions.viewSessionGraphs,
};

export default connect(mapStateToProps, mapActionCreators)(Continuous);
