import React from 'react';

import { SVGProps } from '../types';

const NoteIcon = ({
  width = '15',
  height = '15',
  color = '#767881',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      d="M0.96875 4.53125V13.4367C0.96875 14.0891 1.49868 14.625 2.15254 14.625H10.4688V11.061C10.4688 10.4011 11.0017 9.87494 11.6601 9.87494H14.625V4.53119L0.96875 4.53125ZM0.96875 3.9375V2.15699C0.96875 1.5009 1.50461 0.96875 2.15699 0.96875H13.4367C14.0928 0.96875 14.625 1.49868 14.625 2.1518V3.9375H0.96875ZM11.0625 14.625V11.064C11.0625 10.7352 11.3304 10.4688 11.6511 10.4688H14.6249L11.0625 14.625Z"
      fill={color}
    />
  </svg>
);

export default NoteIcon;
