import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { TenantThresholdsState } from 'src/components/AlertSettingsComponents/AlertThresholdsForm';
import { DataTenantDict } from 'src/redux/data/groupManager/modules/types';
import {
  ALL_TENANTS_OPTION,
  SETTINGS_TYPE,
} from 'src/routes/GMDashboard/modules/constants';
import {
  applyForEachThreshold,
  checkIfCollectiveChange,
  upsertMultiSubtenantThresholds,
  upsertSubtenantThresholds,
} from 'src/routes/GMDashboard/modules/settingsUtils';
import { SubtenantItem } from 'src/routes/GMDashboard/modules/types';
import { AlertThreshold } from 'src/types/alerts';
import Connector, { PropsFromRedux } from './Connector';
import { StyledAlertThresholdsForm } from './styled';
import { deepEqual } from 'src/utils/comparators';

type Props = PropsFromRedux & {
  subtenants: SubtenantItem[];
  selectedSubtenants: SubtenantItem[];
  allThresholds: AlertThreshold[];
  thresholdsDict: DataTenantDict;
  setOnSave: (onSave: () => void) => void;
  setIsSaveBtnDisabled: (isDisabled: boolean) => void;
  thresholdsData: TenantThresholdsState;
  setThresholdsData: Dispatch<SetStateAction<TenantThresholdsState>>;
};

const MTMAlertThresholdsForm = ({
  subtenants,
  selectedSubtenants,
  allThresholds,
  thresholdsDict,
  createGMAlertThreshold,
  editGMAlertThreshold,
  createSubtenantAlertThresholds,
  editSubtenantAlertThresholds,
  setOnSave,
  setIsSaveBtnDisabled,
  thresholdsData,
  setThresholdsData,
}: Props): JSX.Element => {
  useEffect(() => {
    setOnSave(() => {
      const isCollectiveChange = checkIfCollectiveChange(selectedSubtenants);

      if (!isCollectiveChange) {
        const selectedSubtenantId = selectedSubtenants[0]?.value;

        if (selectedSubtenants.length !== 1 || !selectedSubtenantId) {
          return;
        }

        upsertSubtenantThresholds(
          selectedSubtenantId,
          thresholdsData,
          createSubtenantAlertThresholds,
          editSubtenantAlertThresholds,
        );

        return;
      }

      const areAllTenantsSelected = selectedSubtenants.find(
        s => s.value === ALL_TENANTS_OPTION,
      );
      const subtenantsToUpdate = areAllTenantsSelected
        ? subtenants.filter(s => s.type !== SETTINGS_TYPE.UNIQUE)
        : selectedSubtenants;

      if (areAllTenantsSelected) {
        applyForEachThreshold(thresholdsData, threshold => {
          const { id, dirty, enable, value, metric, preposition } = threshold;

          if (!dirty) {
            return;
          }

          threshold.id
            ? editGMAlertThreshold({ id, enable, value })
            : createGMAlertThreshold({
                enable,
                metric,
                preposition,
                value,
              });
        });
      }

      upsertMultiSubtenantThresholds(
        subtenantsToUpdate,
        allThresholds,
        thresholdsDict,
        thresholdsData,
        createSubtenantAlertThresholds,
        editSubtenantAlertThresholds,
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thresholdsData, selectedSubtenants]);

  return (
    <StyledAlertThresholdsForm
      isFormEnabled={!!selectedSubtenants.length}
      thresholds={thresholdsData}
      setThresholdData={setThresholdsData}
      setHasErrors={hasErrors => setIsSaveBtnDisabled(hasErrors)}
    />
  );
};
export default Connector(
  React.memo(MTMAlertThresholdsForm, (oldProps, newProps) =>
    deepEqual(oldProps, newProps),
  ),
);
