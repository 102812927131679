import React from 'react';
import PropTypes from 'prop-types';
import theme from 'src/themes/theme';
import { injectIntl, defineMessages } from 'react-intl';

import { TRESHHOLDS_TYPES_ENUM } from 'src/redux/data/constants';
import heartRateLogo from '../resources/heartRate.svg';
import respirationRateLogo from '../resources/respirationRate.svg';
import AverageValuesCard from './AverageValuesCard';
import {
  StyledAverageValuesWrapper,
  StyledStatisticsTitles,
  StyledTimeFrame,
  StyledDeviceCard,
  MeasurementLogo,
  CardTitle,
  StyledStatisticsLabelGroup,
  StyledLogoGroup,
  FullHeightRow,
  AverageTitleBox,
  MeasurementUnitBox,
} from './styledAverageValues';
import { extractThresholdValues } from '../modules/utils';

const averageStatisticsXAxis = [
  {
    id: 'low',
    color: theme.colors.lowBarGraph,
  },
  {
    id: 'normal',
    color: theme.colors.normalBarGraph,
  },
  {
    id: 'high',
    color: theme.colors.highBarGraph,
  },
];

const mapXAxisLabels = labels =>
  averageStatisticsXAxis.map(item => ({
    ...item,
    name: labels[item.id] || '',
  }));

const extractPercentrageHistogramData = data => [
  {
    id: TRESHHOLDS_TYPES_ENUM.BELOW,
    value: data.histogram.percentage.below,
  },
  {
    id: TRESHHOLDS_TYPES_ENUM.BETWEEN,
    value: data.histogram.percentage.between,
  },
  {
    id: TRESHHOLDS_TYPES_ENUM.ABOVE,
    value: data.histogram.percentage.above,
  },
];

const barLabels = (thresholds, intl) => {
  const { rrBelowTreshold, rrAboveTreshold, hrBelowTreshold, hrAboveTreshold } =
    extractThresholdValues(thresholds);

  return {
    rrLabels: {
      low: rrBelowTreshold
        ? `<${rrBelowTreshold}`
        : intl.formatMessage(messages.notAvailable),
      high: rrAboveTreshold
        ? `>${rrAboveTreshold}`
        : intl.formatMessage(messages.notAvailable),
      normal: `${rrBelowTreshold || 5}-${rrAboveTreshold || 40}`,
    },
    hrLabels: {
      low: hrBelowTreshold
        ? `<${hrBelowTreshold}`
        : intl.formatMessage(messages.notAvailable),
      high: hrAboveTreshold
        ? `>${hrAboveTreshold}`
        : intl.formatMessage(messages.notAvailable),
      normal: `${hrBelowTreshold || 40}-${hrAboveTreshold || 160}`,
    },
  };
};

const AverageValues = ({ intl, hrData, rrData, thresholds }) => {
  const { rrLabels, hrLabels } = barLabels(thresholds, intl);

  const averageStatisticsTimeFrames = [
    {
      id: 'lastDay',
      name: intl.formatMessage(messages.last24hours),
      valueInH: 24,
    },
    {
      id: 'lastThreeDays',
      name: intl.formatMessage(messages.last72hours),
      valueInH: 72,
    },
    {
      id: 'lastWeek',
      name: intl.formatMessage(messages.last7days),
      valueInH: 168,
    },
  ];

  const _renderStatisticsLabelGroup = (logo, message, measurementUnit) => (
    <StyledStatisticsLabelGroup>
      <AverageTitleBox>{intl.formatMessage(messages.average)}:</AverageTitleBox>
      <StyledLogoGroup>
        <MeasurementLogo src={logo} alt="measurement-logo" />
        <CardTitle>{message}</CardTitle>
      </StyledLogoGroup>
      <MeasurementUnitBox>{measurementUnit}</MeasurementUnitBox>
    </StyledStatisticsLabelGroup>
  );

  return (
    <StyledAverageValuesWrapper>
      <StyledStatisticsTitles>
        {averageStatisticsTimeFrames.map(timeFrame => (
          <StyledTimeFrame key={timeFrame.id}>{timeFrame.name}</StyledTimeFrame>
        ))}
      </StyledStatisticsTitles>

      <StyledDeviceCard>
        <FullHeightRow>
          {_renderStatisticsLabelGroup(
            heartRateLogo,
            intl.formatMessage(messages.hr),
            intl.formatMessage(messages.bpm),
          )}
          {averageStatisticsTimeFrames.map(timeFrame => (
            <AverageValuesCard
              key={timeFrame.id}
              groupId="hr"
              averageValue={hrData[timeFrame.id].avg}
              measurementUnit={intl.formatMessage(messages.bpm)}
              data={extractPercentrageHistogramData(hrData[timeFrame.id])}
              averageStatisticsXAxis={mapXAxisLabels(hrLabels)}
            />
          ))}
        </FullHeightRow>
      </StyledDeviceCard>

      <StyledDeviceCard>
        <FullHeightRow>
          {_renderStatisticsLabelGroup(
            respirationRateLogo,
            intl.formatMessage(messages.rr),
            intl.formatMessage(messages.brpm),
          )}
          {averageStatisticsTimeFrames.map(timeFrame => (
            <AverageValuesCard
              key={timeFrame.id}
              groupId="rr"
              averageValue={rrData[timeFrame.id].avg}
              measurementUnit={intl.formatMessage(messages.brpm)}
              data={extractPercentrageHistogramData(rrData[timeFrame.id])}
              averageStatisticsXAxis={mapXAxisLabels(rrLabels)}
            />
          ))}
        </FullHeightRow>
      </StyledDeviceCard>
    </StyledAverageValuesWrapper>
  );
};

AverageValues.propTypes = {
  intl: PropTypes.object.isRequired,
  hrData: PropTypes.object.isRequired,
  rrData: PropTypes.object.isRequired,
  thresholds: PropTypes.array.isRequired,
};

const messages = defineMessages({
  hr: {
    defaultMessage: 'Heart Rate',
  },
  rr: {
    defaultMessage: 'Respiration Rate',
  },
  bpm: {
    defaultMessage: 'BPM',
  },
  brpm: {
    defaultMessage: 'BRPM',
  },
  last24hours: {
    defaultMessage: 'Last 24 hours',
  },
  last72hours: {
    defaultMessage: 'Last 72 hours',
  },
  last7days: {
    defaultMessage: 'Last 7 days',
  },
  average: {
    defaultMessage: 'Average',
  },
  notAvailable: {
    defaultMessage: 'N/A',
  },
});

export default injectIntl(AverageValues);
