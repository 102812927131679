import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { Select } from 'antd';

import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 1.7rem !important;
    line-height: 1.7rem !important;
    font-size: 0.7rem;
    margin-right: 0.2rem !important;
    border-color: ${props =>
      props.theme.colors.historyDateRangeBorder} !important;
    border-radius: ${props =>
      props.theme.layout.defaultBorderRadius} !important;
    input {
      height: 1.7rem !important;
    }
  }
  .ant-select-dropdown-empty {
    width: 13rem !important;
    min-width: 13rem !important;
    .ant-empty-image {
      height: 2rem;
    }
    .ant-empty-description {
      font-size: 0.7rem;
    }
  }
  .ant-select-selection-placeholder {
    line-height: 1.7rem !important;
  }
  .ant-select-arrow {
    line-height: 1.7rem !important;
    position: absolute;
    top: 0.9rem;
  }
  .rc-virtual-list-holder-inner div {
    font-size: 0.7rem;
    padding: 0.1rem 0.4rem;
  }
`;

const SearchDevice = ({
  devicesList,
  onSelectDevice,
  intl,
  selectedDevice,
}) => {
  const { Option } = Select;
  return (
    <StyledSelect
      value={selectedDevice}
      onSelect={onSelectDevice}
      getPopupContainer={trigger => trigger}
      showSearch
      style={{ width: 200 }}
      placeholder={intl.formatMessage(messages.searchHint)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.children
          ?.toLowerCase()
          .localeCompare(optionB.children?.toLowerCase())
      }
    >
      {devicesList.map(device => (
        <Option value={device.manufacturerId} key={device.manufacturerId}>
          {device.name}
        </Option>
      ))}
    </StyledSelect>
  );
};

const messages = defineMessages({
  searchHint: {
    defaultMessage: 'Search to Select Device',
  },
});

SearchDevice.propTypes = {
  intl: PropTypes.object.isRequired,
  devicesList: PropTypes.array,
  onSelectDevice: PropTypes.func.isRequired,
  selectedDevice: PropTypes.string,
};

SearchDevice.defaultProps = {
  devicesList: [],
  selectedDevice: null,
};
export default injectIntl(SearchDevice);
