import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import {
  actions as alertActions,
  selectors as alertsSelectors,
} from 'src/redux/data/alerts';
import { mapThresholdsArrayToState } from 'src/components/AlertSettingsComponents/AlertThresholdsForm';
import { mapBaselineThresholdsArrayToState } from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';

const mapStateToProps = (state: RootState) => ({
  thresholds: mapThresholdsArrayToState(
    alertsSelectors.selectThresholds(state),
  ),
  activityAlertSettingsFromDb:
    alertsSelectors.selectActivityAlertSettings(state),
  baselineThresholds: mapBaselineThresholdsArrayToState(
    alertsSelectors.selectBaselineThresholds(state),
  ),
});

const mapActionCreators = {
  createAlertThreshold: alertActions.createAlert,
  editAlertThreshold: alertActions.editAlert,
  getThresholdsList: alertActions.getThresholdsList,
  fetchActivityAlertSettings: alertActions.fetchActivityAlertSettings,
  updateActivityAlertSettings: alertActions.updateActivityAlertSettings,
  createBaselineThreshold: alertActions.createBaselineAlertThreshold,
  updateBaselineAlertThreshold: alertActions.updateBaselineAlertThreshold,
  getBaselineThresholdsList: alertActions.getBaselineThresholdsList,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
