import React from 'react';

import SelectBox from 'src/components/general-ui/SelectBox';
import { UUID } from 'src/types/utility';
import Connector, { PropsFromRedux } from '../Connector';
import { CustomerTenantType } from 'src/routes/Customers/modules/types';
import { ListTenant } from 'src/types/tenants';

const SelectBoxStyle = {
  width: '10rem',
};

type OptionsType = { value: UUID; label: string }[];

type Props = PropsFromRedux & {
  options: CustomerTenantType[] | ListTenant[];
  onChange: (tenantId: UUID) => void;
};

const SelectTenantBox = ({ options, onChange }: Props): JSX.Element => {
  const onSelectTenant = (tenantId: UUID) => {
    onChange(tenantId);
  };

  const optionList = options.map(tenant => ({
    value: tenant?.id,
    label: tenant?.name,
  })) as OptionsType;

  return (
    <SelectBox
      style={SelectBoxStyle}
      options={optionList}
      handleChange={(id: UUID) => {
        onSelectTenant(id);
      }}
    />
  );
};

export default Connector(SelectTenantBox);
