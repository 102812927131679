import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Table from 'src/components/CrudCommonComponents/Table';

import moment from 'moment';
import {
  continuousTableColumnsTitles,
  CONTINUOUS_TABLE_COLUMNS_KEYS,
} from 'src/utils/graphsUtils/graphConstants';
import { apiStatusEnum } from '../../../../utils/constants';

const ContinuousTable = ({ intl, data, status, onViewClicked }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (data) {
      setTableData(
        data.map((e, index) => ({
          key: `continuous-measurements-row-${index}`,
          duration: calcDuration(
            moment(new Date(e.endTime).getTime()),
            moment(new Date(e.startTime).getTime()),
          ),
          ...e,
        })),
      );
    }
  }, [data]);
  const calcDuration = (endTime, startTime) => {
    const diff = Math.abs(endTime.diff(startTime));
    const secNum = diff / 1000;
    let hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - hours * 3600) / 60);
    let seconds = secNum - hours * 3600 - minutes * 60;
    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${hours}:${minutes}:${seconds}`;
  };
  const columns = [
    {
      title: intl.formatMessage(continuousTableColumnsTitles.from),
      dataIndex: 'startTime',
      key: CONTINUOUS_TABLE_COLUMNS_KEYS.FROM,
      sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime),
      defaultSortOrder: 'descend',
    },
    {
      title: intl.formatMessage(continuousTableColumnsTitles.to),
      dataIndex: 'endTime',
      key: CONTINUOUS_TABLE_COLUMNS_KEYS.TO,
      sorter: (a, b) => new Date(a.endTime) - new Date(b.endTime),
    },
    {
      title: intl.formatMessage(continuousTableColumnsTitles.duration),
      dataIndex: 'duration',
      key: CONTINUOUS_TABLE_COLUMNS_KEYS.DURATION,
      sorter: (a, b) =>
        +a.duration.split(':')[0] * 60 * 60 +
        +a.duration.split(':')[1] * 60 +
        +a.duration.split(':')[2] -
        (+b.duration.split(':')[0] * 60 * 60 +
          +b.duration.split(':')[1] * 60 +
          +b.duration.split(':')[2]),
    },
    // {
    //   title: intl.formatMessage(continuousTableColumnsTitles.history),
    //   key: CONTINUOUS_TABLE_COLUMNS_KEYS.HISTORY,
    //   render: record => (
    //     <ActionButton onClick={() => onViewClicked(record)}>
    //       {intl.formatMessage(continuousTableActionsTitles.view)}
    //     </ActionButton>
    //   ),
    // },
  ];
  // const _onRowRender = (record, rowIndex) => ({
  //   onClick: () => {
  //     onViewClicked(record);
  //   },
  // });
  return (
    <Table
      data={tableData}
      columns={columns}
      loading={status === apiStatusEnum.LOADING}
      actionsTitle={intl.formatMessage(continuousTableColumnsTitles.history)}
      scroll={{ y: '30em' }}
      // onRow={_onRowRender}
    />
  );
};
ContinuousTable.propTypes = {
  data: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  onViewClicked: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ContinuousTable);
