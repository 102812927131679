import styled from 'styled-components';
import { Button, Col, Form } from 'antd';
import { Link } from 'react-router-dom';

import PrimaryButton from 'src/components/general-ui/Buttons/PrimaryButton';

export const CenteredContainer = styled(Col)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FormLayout: typeof Form = styled(Form)`
  width: 800px;
`;

const { Item } = Form;
export const FormItem = styled(Item)`
  .ant-input,
  .ant-input-password {
    background: ${props => props.theme.colors.layoutBackground};
  }
`;

export const AuthButton = styled(PrimaryButton)`
  width: 100%;
  background-color: ${props => props.theme.colors.authButtonBackground};
`;

export const MessageArea = styled.div`
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
  font-size: ${props => props.theme.fonts.smallFontSize};
`;

export const ErrorText = styled.span`
  color: ${props => props.theme.colors.errorText};
`;

export const SuccessText = styled.span`
  color: ${props => props.theme.colors.successText};
`;

export const SmallNavigationLink = styled(Link)`
  font-size: ${props => props.theme.fonts.normalFontSize};
  color: ${props => props.theme.colors.textColor};
  :hover {
    color: ${props => props.theme.colors.authNavigationLink};
  }
`;
export const SmallButtonLink = styled(Button)`
  font-size: ${props => props.theme.fonts.normalFontSize};
  color: ${props => props.theme.colors.textColor};
  :hover {
    color: ${props => props.theme.colors.authNavigationLink};
  }
  border: none;
  box-shadow: none;
`;
