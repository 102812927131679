import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';

import { MainTitle, PageWrapper } from 'src/components/styled';
import Table from 'src/components/CrudCommonComponents/Table';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import TenantModal, { tenantModalModes } from './TenantModal';
import { DEFAULT_PAGINATION } from 'src/utils/constants';
import { getTenantColumns, getTenantTableactions } from './utils';
import { MODAL_STATUS } from 'src/components/Modal/constants';

const TenantsPage = ({
  tenantsList,
  getPaginatedTenants,
  getTenantsList,
  getCustomers,
  customersList,
  mtmTenants,
  tenantsTotalCount,
  searchTenants,
  deleteTenant,
  createTenant,
  editTenant,
  excludedFromDeleteTenants,
  isLoading,
  modalStatus,
  setModalStatus,
  setSearchedTenants,
  intl,
}) => {
  const [isModalVisible, setisModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState(null);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [activeActionRow, setActiveActionRow] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableParams, setTableParams] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState([]);

  const columns = getTenantColumns(intl, messages);

  const actions = getTenantTableactions(
    intl,
    messages,
    excludedFromDeleteTenants,
    setIsConfirmationModalVisible,
    setActiveActionRow,
    setisModalVisible,
    setModalMode,
  );

  useEffect(() => {
    getPaginatedTenants({
      page: DEFAULT_PAGINATION.PAGE - 1,
      limit: DEFAULT_PAGINATION.LIMIT,
    });
  }, [getPaginatedTenants]);

  useEffect(() => {
    if (!isLoading) {
      setTableData(
        tenantsList.map(tenantData => ({
          key: tenantData.id,
          ...tenantData,
        })),
      );
    }
  }, [tenantsList, isLoading]);

  useEffect(() => {
    if (tenantsTotalCount) {
      setTableParams({
        current: DEFAULT_PAGINATION.PAGE,
        pageSize: DEFAULT_PAGINATION.LIMIT,
        total: tenantsTotalCount,
        showSizeChanger: false,
      });
    }
  }, [tenantsTotalCount]);

  const handleTableChange = pagination => {
    if (tableParams) {
      setTableParams(prevState => ({
        ...prevState,
        current: pagination.current,
      }));
      getPaginatedTenants({
        page: pagination.current - 1,
        limit: pagination.pageSize,
      });
    }
  };

  useEffect(() => {
    if (modalStatus === MODAL_STATUS.SUCCESS && searchKeyword) {
      searchTenants(searchKeyword);
    }
    if (modalStatus === MODAL_STATUS.SUCCESS) {
      getPaginatedTenants({
        page: tableParams.current - 1,
        limit: tableParams.pageSize,
      });
    }
  }, [
    modalStatus,
    getPaginatedTenants,
    searchKeyword,
    searchTenants,
    tableParams,
  ]);

  useEffect(() => {
    searchTenants(searchKeyword);
  }, [searchKeyword, searchTenants, setSearchedTenants]);

  useEffect(() => () => setSearchKeyword([]), []);

  return (
    <PageWrapper>
      <MainTitle>
        <FormattedMessage {...messages.tenants} />
      </MainTitle>
      <Table
        loading={isLoading}
        data={tableData}
        pagination={searchKeyword.length === 0 && tableParams}
        onChange={handleTableChange}
        columns={columns}
        actions={actions}
        actionsColumnIndex={2}
        withSearch
        searchFunction={setSearchKeyword}
        searchPlaceholder={intl.formatMessage(messages.searchPlaceholder)}
        withAddButton
        addButtonOnClick={() => {
          setActiveActionRow({});
          setisModalVisible(true);
          setModalMode(tenantModalModes.ADD);
        }}
      />
      <ConfirmationModal
        isModalVisible={isConfirmationModalVisible}
        setIsModalVisible={value => {
          setIsConfirmationModalVisible(value);
          getPaginatedTenants({
            page: tableParams.current - 1,
            limit: tableParams.pageSize,
          });
        }}
        onOk={() => {
          deleteTenant(activeActionRow.id);
        }}
      />
      {isModalVisible && (
        <TenantModal
          mtmTenants={mtmTenants}
          getTenantsList={getTenantsList}
          customersList={customersList}
          getCustomers={getCustomers}
          isModalVisible={isModalVisible}
          setIsModalVisible={value => {
            setisModalVisible(value);
          }}
          onOk={tenantData => {
            if (modalMode === tenantModalModes.ADD) {
              createTenant(tenantData);
              return;
            }
            editTenant({
              id: activeActionRow.id,
              data: tenantData,
            });
          }}
          mode={modalMode}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          tenantListRow={activeActionRow}
        />
      )}
    </PageWrapper>
  );
};

const messages = defineMessages({
  tenants: {
    defaultMessage: 'Tenants',
  },
  searchPlaceholder: {
    defaultMessage: 'Search by name',
  },
  loggedInToTenantAlert: {
    defaultMessage:
      'You are trying to delete the tenant you are logged in to. This operation is not allowed.',
  },
  name: {
    defaultMessage: 'Name',
  },
  enableCPX: {
    defaultMessage: 'CPX Enabled',
  },
  address: {
    defaultMessage: 'Address',
  },
  delete: {
    defaultMessage: 'Delete',
  },
  edit: {
    defaultMessage: 'Edit',
  },
  contactName: {
    defaultMessage: 'Contact name',
  },
  contactEmail: {
    defaultMessage: 'Contact Email',
  },
  contactPhone: {
    defaultMessage: 'Contact phone',
  },
});

TenantsPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tenantsList: PropTypes.array.isRequired,
  mtmTenants: PropTypes.array.isRequired,
  getTenantsList: PropTypes.func.isRequired,
  getCustomers: PropTypes.func,
  customersList: PropTypes.array,
  getPaginatedTenants: PropTypes.func.isRequired,
  searchTenants: PropTypes.func.isRequired,
  tenantsTotalCount: PropTypes.number,
  deleteTenant: PropTypes.func.isRequired,
  createTenant: PropTypes.func.isRequired,
  editTenant: PropTypes.func.isRequired,
  excludedFromDeleteTenants: PropTypes.arrayOf(PropTypes.string).isRequired,
  intl: PropTypes.object.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  setSearchedTenants: PropTypes.func.isRequired,
};

export default injectIntl(TenantsPage);
