import {
  BedOccupancy,
  ContinuousAggregatedMeasurement,
} from 'src/types/individual-dashboard';
import { displayInTimezone } from 'src/utils/timeUtils';
import { GraphTimeData, MainGraphDataPoint } from './types';
import { POINTS_DATE_FORMAT } from './utils';

export const processBedOccupancyData = (
  data: BedOccupancy[],
): {
  hoursInBed: GraphTimeData[];
  numberOfAlerts: GraphTimeData[];
} => {
  const hoursInBed = data.map(d => {
    const minutes = Math.floor(d.inBedSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes - Math.floor(hours * 60);
    //round upwards the hours if the minutes are more than half an hour
    const aproxHours = minutesLeft >= 30 ? hours + 1 : hours;

    const formattedHoursInBedData = {
      date: d.date,
      value: aproxHours,
    };
    return formattedHoursInBedData;
  });
  const numberOfAlerts = data.map(d => {
    const formattedNumberOfAlertsData = {
      date: d.date,
      value: d.noOfAlerts,
    };
    return formattedNumberOfAlertsData;
  });
  return {
    hoursInBed,
    numberOfAlerts,
  };
};

export const mapRawContinuousDataToGraphData = (
  rawContinuousData: ContinuousAggregatedMeasurement[],
  timezone: string,
): MainGraphDataPoint[] =>
  rawContinuousData.map(item => ({
    y: item.average,
    x: displayInTimezone(item.time, timezone, POINTS_DATE_FORMAT),
  }));

export const countDataPoints = (
  hrRawContinuousData: ContinuousAggregatedMeasurement[],
  rrRawContinuousData: ContinuousAggregatedMeasurement[],
): number =>
  [...hrRawContinuousData, ...rrRawContinuousData].reduce(
    (acc, entry) => acc + entry.dataPointsCount,
    0,
  );

export const computeIntervalAverage = (
  rawContinuousData: ContinuousAggregatedMeasurement[],
): number => {
  const sum = rawContinuousData.reduce((acc, item) => acc + item.average, 0);

  return sum / rawContinuousData.length;
};
