import axios, {
  AxiosInstance,
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import { UserLoginData } from 'src/types/users';
import { LoginResource, resources } from './resources';
import {
  buildKeyGenerator,
  CacheRequestConfig,
  setupCache,
} from 'axios-cache-interceptor';
import { SubtenantTknResponse } from 'src/services/types';

import { getBuildGeneratorKey, getHeaderInterpreter } from 'src/services/utils';

const DEFAULT_TIMEOUT = 10 * 1000;
interface Permissions {
  permissions: string[];
}
class UserManagementSdk {
  _axios: AxiosInstance;
  _axiosInstance: AxiosInstance;
  _umsBaseURL: string;
  _requestInterceptor: (
    request: InternalAxiosRequestConfig,
  ) => InternalAxiosRequestConfig;

  _handleError = (error: AxiosError): never => {
    throw error.response !== undefined
      ? error.response.data
      : { message: 'Connection Refused', code: 'CONNECTION_REFUSED' };
  };

  _addAxiosInterceptors = () => {
    this._axios.interceptors.request.use(
      this._addBearerToRequestInterceptor,
      this._handleError,
    );
  };

  // TODO: Reenable if required
  // _createRequestWithToken = (token, request) => ({
  //   request,
  //   token,
  // });

  _addBearerToRequestInterceptor = (
    request: InternalAxiosRequestConfig,
  ): InternalAxiosRequestConfig => {
    const interceptedRequest: InternalAxiosRequestConfig &
      Partial<{
        requireAuth: boolean;
        token: string;
      }> = this._requestInterceptor
      ? this._requestInterceptor(request)
      : request;

    if (interceptedRequest.requireAuth && interceptedRequest.token) {
      interceptedRequest.headers.setAuthorization(
        `Bearer ${interceptedRequest.token}`,
      );
    }

    return {
      ...request,
      ...interceptedRequest,
      // Remove the token from the request as it is not neccessary:
      // @ts-ignore TODO: Rethink this
      token: undefined,
    };
  };

  init = ({
    umsBaseURL,
    timeout = DEFAULT_TIMEOUT,
    requestInterceptor,
  }: {
    umsBaseURL: string;
    timeout?: number;
    requestInterceptor: (
      request: InternalAxiosRequestConfig,
    ) => InternalAxiosRequestConfig;
  }) => {
    this._umsBaseURL = umsBaseURL;
    this._requestInterceptor = requestInterceptor;
    this._axiosInstance = axios.create({
      baseURL: this._umsBaseURL,
      timeout,
    });
    this._axios = setupCache(this._axiosInstance, {
      // debug: console.log,
      headerInterpreter: getHeaderInterpreter,
      generateKey: buildKeyGenerator(
        ({
          baseURL = '',
          url = '',
          method = 'get',
          params,
          data,
          headers,
        }: CacheRequestConfig) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          getBuildGeneratorKey({ baseURL, url, method, params, data, headers }),
      ),
    });
    this._addAxiosInterceptors();
  };

  // TODO: Reenable when required
  // selfRegistration = async ({
  //   email,
  //   firstName,
  //   lastName,
  //   password,
  //   phone,
  // }) => {
  //   try {
  //     const response = await this._axios({
  //       ...resources.SELF_REGISTRATION,
  //       data: { email, firstName, lastName, password, phone },
  //     });

  //     return response.data;
  //   } catch (error) {
  //     this._handleError(error);
  //   }
  // };

  login = async ({
    username,
    password,
    code,
  }: {
    username: string;
    password: string;
    code?: string;
  }): Promise<UserLoginData> => {
    const responseData = await this.loginExternal({
      username,
      password,
      code,
      resource: resources.LOGIN,
    });
    return responseData;
  };

  loginExternal = async ({
    username,
    password,
    code,
    resource,
    providerName,
  }: {
    username: string;
    password: string;
    code?: string;
    resource: LoginResource;
    providerName?: string;
  }): Promise<UserLoginData> => {
    try {
      const response: AxiosResponse<UserLoginData> = await this._axios({
        ...resource,
        data: { username, password, code },
        params: { providerName },
      });

      return response.data;
    } catch (error) {
      this._handleError(error as AxiosError);
      // TODO: delete
      throw error;
    }
  };

  forgotPassword = async ({ username }: { username: string }) => {
    try {
      const response = await this._axios({
        ...resources.FORGOT_PASSWORD,
        data: { username },
      });

      // TODO: Fix later
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data;
    } catch (error) {
      this._handleError(error as AxiosError);
    }
  };

  // TODO Reenable when used
  // resetPassword = async ({ userId, forgotPasswordToken, password }) => {
  //   try {
  //     const response = await this._axios({
  //       ...resource_creators.RESET_PASSWORD(userId),
  //       data: { forgotPasswordToken, password },
  //     });

  //     return response.data;
  //   } catch (error) {
  //     this._handleError(error);
  //   }
  // };

  // TODO Reenable when used
  // changePassword = async ({ accessToken, oldPassword, newPassword }) => {
  //   try {
  //     const response = await this._axios({
  //       token: accessToken,
  //       ...resources.CHANGE_USER_PASSWORD,
  //       data: { oldPassword, newPassword },
  //     });

  //     return response.data;
  //   } catch (error) {
  //     this._handleError(error);
  //   }
  // };

  // TODO Reenable when used
  // loginByPhoneNumber = async ({ tenantId, code, phone }) => {
  //   try {
  //     const response = await this._axios({
  //       ...resources.LOGIN_BY_PHONE_NUMBER,
  //       params: { tenantId },
  //       data: { code, phone },
  //     });

  //     return response.data;
  //   } catch (error) {
  //     this._handleError(error);
  //   }
  // };

  // TODO Reenable when used
  // selfRegistrationByPhoneNumber = async ({ firstName, lastName, phone }) => {
  //   try {
  //     const response = await this._axios({
  //       ...resources.SELF_REGISTRATION_BY_PHONE_NUMBER,
  //       data: { firstName, lastName, phone },
  //     });

  //     return response.data;
  //   } catch (error) {
  //     this._handleError(error);
  //   }
  // };

  // TODO Reenable when used
  // generateLoginCode = async ({ phone }) => {
  //   try {
  //     const response = await this.axios({
  //       ...resources.GENERATE_LOGIN_CODE,
  //       data: { phone },
  //     });

  //     return response.data;
  //   } catch (error) {
  //     this._handleError(error);
  //   }
  // };

  loginWithToken = async ({ refreshToken }: { refreshToken: string }) => {
    try {
      const response: AxiosResponse<UserLoginData> = await this._axios({
        data: { refreshToken },
        ...resources.LOGIN_WITH_TOKEN,
      });

      return response.data;
    } catch (error) {
      this._handleError(error as AxiosError);

      throw Error;
    }
  };

  mfaLogin = async ({
    accessToken,
    code,
  }: {
    accessToken: string | undefined;
    code: string;
  }): Promise<UserLoginData> => {
    try {
      // @ts-ignore Token is not recognized as parameter for AxiosConfig
      const response: AxiosResponse<UserLoginData, any> = await this._axios({
        token: accessToken,
        data: { code },
        ...resources.MFA_LOGIN,
      });

      return response.data;
    } catch (error) {
      this._handleError(error as AxiosError);
      throw Error;
    }
  };

  mfaResendCode = async ({
    accessToken,
  }: {
    accessToken: string | undefined;
  }): Promise<string> => {
    try {
      // @ts-ignore Token is not recognized as parameter for AxiosConfig
      const response: AxiosResponse<string, any> = await this._axios({
        token: accessToken,
        ...resources.MFA_RESEND_CODE,
      });
      return response.data;
    } catch (error) {
      this._handleError(error as AxiosError);
      throw Error;
    }
  };

  refreshToken = async ({ refreshToken }: { refreshToken: string }) => {
    try {
      const response: AxiosResponse<UserLoginData> = await this._axios({
        ...resources.REFRESH_TOKEN,
        data: { refreshToken },
      });

      return response.data;
    } catch (error) {
      this._handleError(error as AxiosError);
      throw Error;
    }
  };

  logout = async ({ refreshToken }: { refreshToken: string }) => {
    try {
      const response: AxiosResponse<void> = await this._axios({
        ...resources.LOGOUT,
        data: { refreshToken },
      });

      return response.data;
    } catch (error) {
      this._handleError(error as AxiosError);
    }
  };

  // TODO Reenable when used
  // verifyEmail = async ({ userId, verifyEmailToken }) => {
  //   try {
  //     const response = await this._axios({
  //       ...resource_creators.VERIFY_EMAIL(userId),
  //       params: { token: verifyEmailToken },
  //     });

  //     return response.data;
  //   } catch (error) {
  //     this._handleError(error);
  //   }
  // };

  getUiPermissions = async ({
    accessToken,
  }: {
    accessToken: string | undefined;
  }) => {
    try {
      // @ts-ignore Token is not recognized as parameter for AxiosConfig
      const response: AxiosResponse<Permissions> = await this._axios({
        token: accessToken,
        ...resources.GET_UI_PERMISSIONS,
      });

      return response.data;
    } catch (error) {
      this._handleError(error as AxiosError);

      return;
    }
  };

  refreshGMSubtenantTokens = async ({
    accessToken,
  }: {
    accessToken: string | undefined;
  }) => {
    try {
      // @ts-ignore Token is not recognized as parameter for AxiosConfig
      const response: AxiosResponse<SubtenantTknResponse> = await this._axios({
        token: accessToken,
        ...resources.REFRESH_SUBTENANT_TOKENS,
      });
      return response.data;
    } catch (error) {
      this._handleError(error as AxiosError);

      throw error;
    }
  };
}

export default new UserManagementSdk();
