export const HR_DEFAULT_THRESHOLDS = {
  MIN: 41,
  MAX: 159,
};

export const RR_DEFAULT_THRESHOLDS = {
  MIN: 6,
  MAX: 39,
};

export const THRESHOLDS_REFFERENCES = {
  ABOVE: 'above',
  BELOW: 'below',
};

export const VALIDATION_ERROR_IDS = {
  VALUE_EXCEEDED: 'valueExceeded',
  MIN_GAP: 'minGap',
  BELOW_AND_ABOVE_REVERSED: 'belowAndAboveReversed',
};
