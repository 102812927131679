import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { Form, Input, Checkbox } from 'antd';

import Modal from 'src/components/Modal';
import { SubTitle } from 'src/components/styled';
import { tenantFormFields, emailPattern } from 'src/utils/constants';
import SelectBox from 'src/components/general-ui/SelectBox';
import RadioGroup from 'src/components/general-ui/RadioGroup';

import {
  cityLookup,
  getTimezoneUtcOffsetStr,
  countriesListMap,
  getCountryName,
} from 'src/utils/country-city-timezones/utils';

export const tenantModalModes = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

const TENANT_TYPES = {
  GROUP: 'group',
  TENANT: 'parentTenantId',
};

const countryOptions = countriesListMap.map(c => ({
  ...c,
  value: c.id,
  label: c.name,
}));

const getTenantTypeOptions = intl => [
  {
    id: TENANT_TYPES.TENANT,
    label: intl.formatMessage(messages.tenantLabel),
    value: TENANT_TYPES.TENANT,
  },
  {
    id: TENANT_TYPES.GROUP,
    label: intl.formatMessage(messages.groupManagerLabel),
    value: TENANT_TYPES.GROUP,
  },
];

const TenantModal = ({
  isModalVisible,
  setIsModalVisible,
  onOk,
  mode,
  modalStatus,
  setModalStatus,
  intl,
  tenantListRow,
  getCustomers,
  customersList,
  mtmTenants,
  getTenantsList,
}) => {
  const tenantGroup = tenantListRow?.group;
  const tenantCountryCode = tenantListRow?.address?.countryCode;
  const tenantCity = tenantListRow?.address?.city;

  const customersOptionList = customersList?.map(customer => ({
    value: customer.id,
    label: customer.name,
  }));

  const mtmOptions = mtmTenants?.map(mtm => ({
    value: mtm.id,
    label: mtm.name,
  }));

  const [form] = Form.useForm();

  const [selectedCountry, setCountry] = useState(tenantCountryCode);
  const [citiesList, setCitiesList] = useState(null);
  const [selectedCity, setCity] = useState(tenantCity);
  const [selectedTenantType, setTenantType] = useState(null);

  const modeToPropsMap = useMemo(
    () => ({
      [tenantModalModes.ADD]: {
        title: intl.formatMessage(messages.addTenant),
        okButtonText: intl.formatMessage(messages.add),
        initialValues: {
          [tenantFormFields.PARENT_TENANT_ID]: null,
          [tenantFormFields.ENABLE_CPX]: false,
          [tenantFormFields.GROUP]: false,
        },
      },
      [tenantModalModes.EDIT]: {
        title: intl.formatMessage(messages.editTenant),
        okButtonText: intl.formatMessage(messages.apply),
        initialValues: {
          [tenantFormFields.NAME]: tenantListRow?.name,
          [tenantFormFields.ADDRESS1]: tenantListRow?.address?.address1,
          [tenantFormFields.ADDRESS2]: tenantListRow?.address?.address2,
          [tenantFormFields.COUNTRY_CODE]: tenantListRow?.address?.countryCode,
          [tenantFormFields.CITY]: tenantListRow?.address?.city,
          [tenantFormFields.STATE]: tenantListRow?.address?.state,
          [tenantFormFields.ZIP_CODE]: tenantListRow?.address?.zipCode,
          [tenantFormFields.REGION]: tenantListRow?.address?.region,
          [tenantFormFields.ENABLE_CPX]: tenantListRow?.isEnableCPX || false,
          [tenantFormFields.TIMEZONE_UTCOFFSET_STR]:
            tenantListRow?.timeZoneOffset,
          [tenantFormFields.TIMEZONE_ID]: tenantListRow?.timeZoneId,
          [tenantFormFields.GROUP]: tenantListRow?.group,
          [tenantFormFields.CUSTOMER_ID]: tenantListRow?.customerId,
        },
      },
    }),
    [tenantListRow, intl],
  );

  useEffect(() => {
    if (getCustomers && isModalVisible) {
      getCustomers();
    }
  }, [getCustomers, isModalVisible]);

  useEffect(() => {
    form.setFieldsValue({ ...modeToPropsMap[mode].initialValues });
  }, [tenantListRow, modeToPropsMap, form, mode]);

  useMemo(() => {
    if (selectedTenantType !== TENANT_TYPES.GROUP && isModalVisible) {
      getTenantsList();
    }
  }, [getTenantsList, isModalVisible, selectedTenantType]);

  useEffect(() => {
    setCountry(tenantListRow?.address?.countryCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantCountryCode]);

  useEffect(() => {
    const citiesByCountry = cityLookup(getCountryName(selectedCountry));

    citiesByCountry &&
      setCitiesList(
        citiesByCountry.map(city => ({
          ...city,
          value: city.id,
          label: city.name,
        })),
      );
  }, [selectedCountry]);

  useEffect(() => {
    setCity(tenantListRow?.address?.city);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantCity]);

  useEffect(() => {
    setTenantType(tenantGroup ? TENANT_TYPES.GROUP : TENANT_TYPES.TENANT);
  }, [tenantGroup]);

  useEffect(() => {
    if (isModalVisible) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  useEffect(() => {
    if (mode === tenantModalModes.ADD) {
      form.resetFields();
      setTenantType(TENANT_TYPES.TENANT);
      setCountry(null);
      setCitiesList(null);
      setCity(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const onCitySelect = cityOption => {
    const { timezone, city, iso2 } = cityOption;
    const utcOffsetStr = getTimezoneUtcOffsetStr(timezone);

    form.setFieldsValue({
      city: city,
      countryCode: iso2,
      timeZoneOffset: utcOffsetStr,
      timeZoneId: timezone,
    });
  };

  const onTenantTypeSelect = tenantType => {
    if (tenantType === TENANT_TYPES.GROUP) {
      form.setFieldsValue({
        group: true,
      });
    } else {
      form.setFieldsValue({
        group: false,
        parentTenantId: null,
      });
    }
    setTenantType(tenantType);
  };

  return (
    <Modal
      title={modeToPropsMap[mode].title}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      okButtonText={modeToPropsMap[mode].okButtonText}
      onOk={() => {
        form.submit();
      }}
      destroyOnClose
      modalStatus={modalStatus}
      setModalStatus={setModalStatus}
      bodyStyle={{ height: '400px', overflowY: 'scroll', overflowX: 'hidden' }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={values => {
          onOk(values);
        }}
        initialValues={modeToPropsMap[mode].initialValues}
        validateMessages={tenantFormValidateMessages}
      >
        <Form.Item
          name={tenantFormFields.NAME}
          label={intl.formatMessage(messages.nameFieldLabel)}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={tenantFormFields.CUSTOMER_ID}
          label={intl.formatMessage(messages.customer)}
        >
          <SelectBox
            onSelect={customerId => {
              form.setFieldsValue({ customerId: customerId });
            }}
            onClear={() => form.setFieldsValue({ customerId: null })}
            width="100%"
            defaultValue={tenantListRow?.customerId}
            options={customersOptionList}
            placeholder={intl.formatMessage(messages.customer)}
          />
        </Form.Item>
        <Form.Item
          name={tenantFormFields.GROUP}
          label={intl.formatMessage(messages.tenantType)}
        >
          <RadioGroup
            onSelect={onTenantTypeSelect}
            selectedOption={selectedTenantType}
            optionsList={getTenantTypeOptions(intl)}
            disabledOption={mode === tenantModalModes.EDIT}
          />
        </Form.Item>
        <Form.Item
          name={tenantFormFields.PARENT_TENANT_ID}
          label={intl.formatMessage(messages.parentTenantIdFieldLabel)}
          hidden={selectedTenantType === TENANT_TYPES.GROUP}
        >
          <SelectBox
            onSelect={tenantId => {
              form.setFieldsValue({ parentTenantId: tenantId });
            }}
            onClear={() => form.setFieldsValue({ parentTenantId: null })}
            width="100%"
            defaultValue={tenantListRow?.parentTenant?.id}
            options={mtmOptions}
            placeholder={intl.formatMessage(messages.parentTenantIdFieldLabel)}
          />
        </Form.Item>
        {mode === tenantModalModes.ADD && (
          <>
            <SubTitle>{intl.formatMessage(messages.admin)}</SubTitle>
            <Form.Item
              name={tenantFormFields.FIRST_NAME}
              label={intl.formatMessage(messages.firstNameFieldLabel)}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={tenantFormFields.LAST_NAME}
              label={intl.formatMessage(messages.lastNameFieldLabel)}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={tenantFormFields.EMAIL}
              label={intl.formatMessage(messages.emailFieldLabel)}
              rules={[
                {
                  required: true,
                  pattern: emailPattern,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}

        <Form.Item
          name={tenantFormFields.ENABLE_CPX}
          label={intl.formatMessage(messages.enableCPX)}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <SubTitle>{intl.formatMessage(messages.address)}</SubTitle>

        <Form.Item
          name={tenantFormFields.ADDRESS1}
          label={intl.formatMessage(messages.address1FieldLabel)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={tenantFormFields.ADDRESS2}
          label={intl.formatMessage(messages.address2FieldLabel)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={tenantFormFields.COUNTRY_CODE}
          label={intl.formatMessage(messages.countryFieldLabel)}
          rules={[{ required: true }]}
        >
          <SelectBox
            handleChange={value => {
              form.setFieldsValue({ city: null, countryCode: value });
              setCountry(value);
              setCity(null);
            }}
            onClear={() => {
              form.setFieldsValue({ countryCode: null, city: null });
              setCountry(null);
              setCity(null);
            }}
            selectedItem={selectedCountry}
            options={countryOptions}
            placeholder={intl.formatMessage(messages.searchCountry)}
          />
        </Form.Item>
        <Form.Item
          name={tenantFormFields.CITY}
          label={intl.formatMessage(messages.cityFieldLabel)}
          rules={[{ required: true }]}
        >
          <SelectBox
            handleChange={(value, cityObj) => {
              onCitySelect(cityObj);
            }}
            selectedItem={selectedCity}
            disabled={!selectedCountry}
            options={selectedCountry ? citiesList : []}
            placeholder={intl.formatMessage(messages.searchCity)}
            notFoundContent={intl.formatMessage(messages.notFoundCity)}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage(messages.timezoneLabel)}
          name={tenantFormFields.TIMEZONE_ID}
        >
          <Input bordered={false} readOnly />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage(messages.timezoneOffsetLabel)}
          name={tenantFormFields.TIMEZONE_UTCOFFSET_STR}
        >
          <Input bordered={false} readOnly />
        </Form.Item>
        <Form.Item
          name={tenantFormFields.ZIP_CODE}
          label={intl.formatMessage(messages.zipCodeFieldLabel)}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const messages = defineMessages({
  addTenant: {
    defaultMessage: 'Add Tenant',
  },
  editTenant: {
    defaultMessage: 'Edit Tenant',
  },
  apply: {
    defaultMessage: 'Apply',
  },
  add: {
    defaultMessage: 'Add',
  },
  searchCountry: {
    defaultMessage: 'Search country...',
  },
  notFoundCity: {
    defaultMessage: 'Please choose a city close to your area.',
  },
  searchCity: {
    defaultMessage: 'Search city...',
  },
  nameFieldLabel: {
    defaultMessage: 'Name',
  },
  firstNameFieldLabel: {
    defaultMessage: 'First Name',
  },
  lastNameFieldLabel: {
    defaultMessage: 'Last Name',
  },
  emailFieldLabel: {
    defaultMessage: 'Email',
  },
  countryFieldLabel: {
    defaultMessage: 'Country',
  },
  cityFieldLabel: {
    defaultMessage: 'City',
  },
  zipCodeFieldLabel: {
    defaultMessage: 'Zip Code',
  },
  address1FieldLabel: {
    defaultMessage: 'Address1',
  },
  address2FieldLabel: {
    defaultMessage: 'Address2',
  },
  address: {
    defaultMessage: 'Address',
  },
  admin: {
    defaultMessage: 'Admin',
  },
  enableCPX: {
    defaultMessage: 'CPX Enabled',
  },
  timezoneLabel: {
    defaultMessage: 'Timezone',
  },
  timezoneOffsetLabel: {
    defaultMessage: 'Timezone offset',
  },
  tenantType: {
    defaultMessage: 'Tenant type',
  },
  parentTenantIdFieldLabel: {
    defaultMessage: 'Parent tenant',
  },
  tenantLabel: {
    defaultMessage: 'Tenant',
  },
  groupManagerLabel: {
    defaultMessage: 'Multi-Tenant Manager',
  },
  customer: {
    defaultMessage: 'Customer',
  },
});

export const tenantFormValidateMessages = {
  required: '${label} is required!',
  pattern: { mismatch: '${label} is not a valid ${label}' },
};

TenantModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  tenantListRow: PropTypes.object,
  getCustomers: PropTypes.func,
  customersList: PropTypes.array,
  mtmTenants: PropTypes.array,
  getTenantsList: PropTypes.func.isRequired,
};

TenantModal.defaultProps = {
  tenantListRow: {},
};

export default injectIntl(TenantModal);
