import { defineMessages } from 'react-intl';

export const userMessages = defineMessages({
  // error
  deleteUserError: {
    defaultMessage: 'Could not delete user',
  },
  deleteContactTenantError: {
    defaultMessage:
      "You can't delete contact user of a tenant! Please change the tenant contact before deleting this user",
  },
  updateUserError: {
    defaultMessage: 'Could not update user',
  },
  updateMfaUserError: {
    defaultMessage: 'Could not update MFA user settings',
  },
  updateMfaUserErrorDescription: {
    defaultMessage: 'Please try again',
  },
  inviteUserError: {
    defaultMessage: 'Could not invite user',
  },
  inviteUserNameAlreadyExist: {
    defaultMessage:
      'The email address of the user you are trying to add already exists in the system',
  },

  sendingResetPasswordEmailFailedDescription: {
    defaultMessage: 'Could not send reset password to email',
  },
  resendInvitationError: {
    defaultMessage: 'Could not re-invite user',
  },
  // success
  inviteUserSuccess: {
    defaultMessage: 'User has been invited',
  },
  sendingResetPasswordEmailSuccessDescription: {
    defaultMessage: 'Go check your mailbox!',
  },
  resendInvitationSuccess: {
    defaultMessage: 'User has been re-invited',
  },
  deleteInvitationError: {
    defaultMessage: 'Could not delete invitation',
  },
});
