import React, { useState } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { useSearch } from 'src/components/general-ui/Search';
import { UUID } from 'src/types/utility';
import { MinimalPatient } from '../PatientAssigner/types';
import {
  ItemsWrapper,
  StyledItem,
  SearchWrapper,
  StyledIcon,
  StyledInput,
  StyledOccupiedText,
} from './styled';

type Props = {
  items: MinimalPatient[];
  onSelect: (item: MinimalPatient) => void;
  intl: IntlShape;
};

const searchKeys: (keyof MinimalPatient)[] = ['fullName'];

const PatientSearchSelector = ({
  items,
  onSelect,
  intl,
}: Props): JSX.Element => {
  const [selectedItemId, setSelectedItem] = useState<UUID>('');
  const [filteredItems, query, setQuery] = useSearch(items, searchKeys);

  const handleSelect = (item: MinimalPatient) => {
    setSelectedItem(item.id);
    onSelect(item);
  };

  return (
    <>
      <SearchWrapper>
        <StyledInput
          type="search"
          value={query}
          onChange={e => setQuery(e.target.value)}
          placeholder={intl.formatMessage(messages.placeholder)}
        />
        <StyledIcon type="search-icon" color="#767881" />
      </SearchWrapper>
      <ItemsWrapper>
        {filteredItems.map(item => (
          <StyledItem
            key={item.id}
            isSelected={item.id === selectedItemId}
            isAssigned={!!item.deviceId}
            onClick={() => handleSelect(item)}
          >
            <span>{item.fullName}</span>
            {!!item.deviceId && (
              <StyledOccupiedText>
                <FormattedMessage {...messages.occupied} />
              </StyledOccupiedText>
            )}
          </StyledItem>
        ))}
      </ItemsWrapper>
    </>
  );
};

const messages = defineMessages({
  placeholder: {
    defaultMessage: '|Search ',
  },
  occupied: {
    defaultMessage: 'Occupied',
  },
});

export default injectIntl(PatientSearchSelector);
