import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { isNone } from 'fp-ts/lib/Option';

import { BoldItem } from 'src/components/styled';
import { API_STATUS } from 'src/utils/api-constants';
import Loader from 'src/components/general-ui/Loader';
import { updateText } from 'src/utils/stringUtils';
import Connector, { IProps } from './Connector';
import {
  Wrapper,
  Title,
  ContentWrapper,
  AlertDetails,
  SpacedLines,
} from './styled';
import { messages } from './constants';
import { getAlertText } from './utils';
import { getTimezoneId, parseUtcOffsetToHours } from 'src/utils/timeUtils';

type Props = IProps & {
  intl: IntlShape;
};

const PersonalAlert = ({
  alertId,
  alertWithPersonalDetails,
  fetchAlertWithPersonalDetails,
  userTenant,
  intl,
}: Props): JSX.Element => {
  useEffect(() => {
    fetchAlertWithPersonalDetails(alertId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { apiStatus, data } = alertWithPersonalDetails;
  if (apiStatus === API_STATUS.LOADING || !userTenant) {
    return <Loader />;
  }

  if (apiStatus === API_STATUS.ERROR) {
    return <FormattedMessage {...messages.errorText} />;
  }

  if (isNone(data)) {
    return <FormattedMessage {...messages.dataNotFound} />;
  }

  const {
    patientData,
    roomName,
    bedName,
    tenantName,
    patientId,
    timeZoneId,
    countryName,
  } = data.value;
  const { firstName, lastName, dateOfBirth } = patientData;

  const { boldText, normalText } = getAlertText(
    data.value,
    getTimezoneId(timeZoneId),
    intl,
  );

  return (
    <Wrapper>
      <Title>
        <FormattedMessage {...messages.title} />
      </Title>
      <ContentWrapper>
        <AlertDetails>
          <BoldItem>{boldText}</BoldItem>
          {normalText}
        </AlertDetails>
        <SpacedLines>
          <BoldItem>
            {firstName} {lastName}
          </BoldItem>
          <span>
            <FormattedMessage
              {...messages.idNumber}
              values={{ patientId: patientId }}
            />
          </span>
          <span>
            <FormattedMessage {...messages.dob} />{' '}
            {dateOfBirth || intl.formatMessage(messages.notAvailable)}
          </span>
        </SpacedLines>
        <SpacedLines>
          <BoldItem>{tenantName}</BoldItem>
          <span>
            <FormattedMessage {...messages.room} /> {roomName}
          </span>
          <span>
            <FormattedMessage {...messages.bed} /> {bedName}
          </span>
          <span>
            <FormattedMessage {...messages.timezone} /> {timeZoneId || ''}
            {updateText(', ', countryName)}
            {updateText(' (', parseUtcOffsetToHours(timeZoneId), ')')}
          </span>
        </SpacedLines>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Connector(injectIntl(PersonalAlert));
