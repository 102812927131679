import { defineMessages } from 'react-intl';

export const versionMessages = defineMessages({
  // error
  uploadVersionError: {
    defaultMessage: 'Could not upload version ',
  },
  updateVersionStatusError: {
    defaultMessage: 'Could not update version ',
  },
  applicationVersionAlreadyExistError: {
    defaultMessage: 'Application version already exist ',
  },
  updateVersionStatusSuccessMessage: {
    defaultMessage: 'Version updated successfully',
  },
  uploadVersionSuccessMessage: {
    defaultMessage: 'Create Software Version successfully',
  },
  deleteVerionError: {
    defaultMessage: "Can't delete version",
  },
  versionNotFound: {
    defaultMessage: 'Version not found',
  },
  versionAlreadyAssign: {
    defaultMessage: 'Version already assign',
  },
});
