import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/redux/store';

import { selectors as settingsSelectors } from 'src/redux/data/settings';
import { NurseStationDevice } from 'src/routes/NursesStation/modules/types';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';

type OwnProps = NurseStationDevice;

const mapStateToProps = (state: RootState) => ({
  shouldDisplayHighQualityOnly:
    settingsSelectors.getShouldDisplayHighQualityOnly(state),
  timezone: loggedInUserSelectors.getUserTenantTimezone(state),
});

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type Props = OwnProps & PropsFromRedux;

export default Connector;
