import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { UUID } from 'src/types/utility';
import {
  actions as alertActions,
  selectors as alertSelectors,
} from 'src/redux/data/alerts';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';

type OwnProps = {
  alertId: UUID;
};

const mapStateToProps = (state: RootState) => {
  return {
    alertWithPersonalDetails:
      alertSelectors.selectAlertWithPersonalDetails(state),
    userTenant: loggedInUserSelectors.getUserTenant(state),
  };
};

const mapActionCreators = {
  fetchAlertWithPersonalDetails: alertActions.fetchAlertWithPersonalDetails,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type IProps = OwnProps & PropsFromRedux;

export default Connector;
