import { MessageDescriptor } from 'react-intl';

export type HPAlertSettingsOnSaveHandlers = {
  saveAlertThresholds: () => void;
  saveBaselineThresholds: () => void;
  saveNotificationSettings: () => void;
};

export type ErrorState = {
  thresholds: boolean;
  baselineThresholds: boolean;
};

export const ALERT_SETTINGS_TAB_KEYS = Object.freeze({
  MEDICAL_ALERTS: 'MedicalAlerts',
  ACTIVITY_ALERTS: 'ActivityAlerts',
  CONNECTIVITY_ALERTS: 'ConnectivityAlerts',
});

type KEYS = keyof typeof ALERT_SETTINGS_TAB_KEYS;
export type AlertSettingsTabType = {
  key: (typeof ALERT_SETTINGS_TAB_KEYS)[KEYS];
  labelMessage: MessageDescriptor;
  component: React.ElementType;
  editablePermissions?: string[];
};
