import React from 'react';

import { SVGProps } from '../types';

const DownRightArrowIcon = ({
  width = '9',
  height = '8',
  color = '#252525',
  verticalAlign = '0',
  horizontalAlign = '0',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`${horizontalAlign} ${verticalAlign} ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      d="M1.32129 0.666748L7.32107 6.33325"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M2.6543 6.33325L7.32096 6.33325L7.32096 1.66658"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);

export default DownRightArrowIcon;
