import React from 'react';

import SelectBox from 'src/components/general-ui/SelectBox';
import { UUID } from 'src/types/utility';
import { PropsFromRedux } from '../Connector';

type Props = Partial<PropsFromRedux> & {
  loading: boolean;
  contactId: string | undefined;
  placeholder: string | undefined;
};

const SelectMtmUserBox = ({
  mtmUsers,
  handleChangeForm,
  loading,
  contactId,
  placeholder,
}: Props): JSX.Element => {
  const userOptionList = mtmUsers?.map(user => ({
    value: user.id,
    label: user.fullName,
  }));

  return (
    <SelectBox
      loading={loading}
      options={userOptionList || []}
      defaultValue={
        mtmUsers && mtmUsers.find(user => user.id === contactId)?.fullName
      }
      placeholder={placeholder}
      //@ts-ignore ts error because of mocked data
      handleChange={(id: UUID) => handleChangeForm({ contactId: id })}
    />
  );
};

export default SelectMtmUserBox;
