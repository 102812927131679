import styled from 'styled-components';
import { Collapse } from 'antd';

export const CollapseStyled: typeof Collapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 0.2rem 0.8rem !important;
  }
  .ant-collapse-arrow {
    font-size: 0.7rem !important;
    vertical-align: middle !important;
    line-height: 2.2rem;
  }
  .ant-collapse-content-box {
    padding: 0rem 0.8rem;
  }
`;
export const SettingsSection = styled.div`
  display: flex;
  place-content: space-between;
  flex-direction: column;
  border: ${props => `1px solid ${props.theme.colors.settingBoxBorderColor}`};
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
`;

export const SectionHeader = styled.div`
  display: flex;
  font-size: large;
  justify-content: space-between;
  flex: 1;
  font-size: 0.9rem;
  line-height: 2.1rem;
`;

export const SectionBody = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.7rem;
  line-height: 2rem;
`;
export const AlertBody = styled.div`
  display: flex;
  flex-direction: column;
`;
