import styled from 'styled-components';

export const InputContainer = styled.div<{ width?: string | number }>`
  position: relative;
  width: auto;
  ${props => (props.width ? `width: ${props.width};` : '')}
  height: 2rem;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const StyledInput = styled.input<{
  isError?: boolean;
  width?: string | number;
}>`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  font-weight: 400;
  font-size: 1.1rem;
  text-align: center;
  width: 7.6rem;
  height: 2rem;
  ${props => (props.isError ? 'border: 1px solid #ca0000;' : '')}
  ${props => (props.width ? `width: ${props.width};` : '')}
  outline: none;
  padding-right: calc(12px + 0.5rem);
`;

export const StyledIcon = styled.img<{
  w: string;
  h: string;
  background: string;
}>`
  background: ${props => props.background};
  width: ${props => props.w};
  heigth: ${props => props.h};
`;

export const StyledOverlayIcon = styled(StyledIcon)`
  position: absolute;
  top: 0.7rem;
  right: 0.5rem;
`;
