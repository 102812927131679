import React from 'react';
import styled from 'styled-components';

import Loader from '../Loader';

export const StyledOverlay = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.overlayBackground};
`;

type Props = {
  className?: string;
};

const LoadingOverlay = ({ className }: Props): JSX.Element => (
  <StyledOverlay className={className}>
    <Loader />
  </StyledOverlay>
);

export default LoadingOverlay;
