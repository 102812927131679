import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const VersionContainer = styled.section`
  white-space: nowrap;
`;

const AppVersion = ({ className }) => (
  <VersionContainer className={className}>
    {process.env.REACT_APP_VERSION}
  </VersionContainer>
);

AppVersion.propTypes = {
  className: PropTypes.string,
};

AppVersion.defaultProps = {
  className: null,
};

export default AppVersion;
