import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { DATA_FETCHING_STATUS, DATA_STATE_KEY } from '../../constants';
import { ResponseInterface } from '../../types';
import { CustomersInitialState } from './types';
import {
  CreateCustomerPayload,
  CustomerType,
} from 'src/routes/Customers/modules/types';
import { UUID } from 'src/types/utility';

export const STATE_KEY = 'customers';

const customerInitialData = {
  name: '',
  contactId: '',
  emrMappings: [
    {
      organizationId: '',
      emrType: '',
    },
  ],
};

export const INITIAL_STATE: CustomersInitialState = {
  customersList: { data: null, status: null },
  customerForm: customerInitialData,
  isLoadingForm: null,
  currentCustomer: null,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setCustomersList(
      state,
      action: PayloadAction<ResponseInterface<CustomerType[]>>,
    ) {
      state.customersList = { ...action.payload };
    },
    setCustomersStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.customersList = { ...state.customersList, status: action.payload };
    },
    handleChangeForm(state, action: PayloadAction<CreateCustomerPayload>) {
      state.customerForm = { ...state.customerForm, ...action.payload };
    },
    setFormStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.isLoadingForm = action.payload;
    },
    setCustomerForm(state, action: PayloadAction<CreateCustomerPayload>) {
      state.customerForm = action.payload;
    },
    setCurrentCustomer(state, action: PayloadAction<CustomerType>) {
      state.currentCustomer = action.payload;
    },
    resetFormAndCurrentCustomer(state) {
      state.customerForm = customerInitialData;
      state.currentCustomer = null;
    },
  },
});

export const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  isLoadingCustomers: createSelector(
    getState,
    state => state.customersList.status === DATA_FETCHING_STATUS.LOADING,
  ),
  isLoadingForm: createSelector(
    getState,
    state => state.isLoadingForm === DATA_FETCHING_STATUS.LOADING,
  ),
  selectCustomersList: createSelector(
    getState,
    state => state.customersList.data,
  ),
  selectEditableData: createSelector(getState, state => state.customerForm),
  selectCurrentCustomer: createSelector(
    getState,
    state => state.currentCustomer,
  ),
};

const extraActions = {
  getCustomersList: createAction(`${STATE_KEY}/getCustomersList`),
  createCustomer: createAction(`${STATE_KEY}/createCustomer`),
  editCustomer: createAction(`${STATE_KEY}/editCustomer`),
  deleteCustomerById: createAction<UUID>(`${STATE_KEY}/deleteCustomerById`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
