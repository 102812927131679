import React from 'react';

import { SVGProps } from '../types';

const WifiDisconnectedIcon = ({
  width = '21',
  height = '20',
  color = '#000',
  viewBox = '100 100',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${viewBox}`}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    className={className}
    {...rest}
  >
    <g transform="scale(0.521)">
      <g transform="rotate(-45 96 166)">
        <path
          d="m 96,42  a 124,124 0 0 1 124,124 l 12,0 a 136,136 0 0 0 -136,-136 z"
          fill="#545454;"
        />
        <path
          d="m 96,66  a 100,100 0 0 1 100,100 l 12,0 a 112,112 0 0 0 -112,-112 z"
          fill="#545454;"
        />
        <path
          d="m 96,90   a 76,76 0 0 1 76,76  l 12,0 a 88,88 0 0 0 -88,-88 z"
          fill="#545454;"
        />
        <path
          d="m 96,114  a 52,52 0 0 1 52,52  l 12,0 a 64,64 0 0 0 -64,-64 z"
          fill="#545454;"
        />
        <path d="m 96,166 0,-40 a 40 40  0 0 1 40,40 z" fill="#545454;" />
      </g>
      <g transform="rotate(45,96,96)">
        <path
          fill="#D03D32"
          stroke="none"
          opacity="0.75;"
          d="m 80,10 32,0 0,70 70,0 0,32 -70,0 0,70 -32,0 0,-70 -70,0 0,-32 70,0 z"
        />
      </g>
    </g>
  </svg>
);

export default WifiDisconnectedIcon;
