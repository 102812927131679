import React from 'react';
import { Tooltip as AntdTooltip, TooltipProps } from 'antd';

type Props = TooltipProps & React.RefAttributes<unknown>;

const Tooltip = (props: Props) => (
  <AntdTooltip overlayClassName={props.className} {...props}>
    {props.children}
  </AntdTooltip>
);

export default Tooltip;
