import { all, takeLatest, call, put, select } from 'typed-redux-saga/macro';

import BackendService from 'src/services/BackendService';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { ALERTS_TARGET_MEDIAS } from 'src/utils/constants';
import { actionSuccessNotification } from 'src/utils/errorHandling/notifications';
import { actions } from './slice';
import { DATA_FETCHING_STATUS } from '../../constants';

function* getSubscriberStatus(
  action: ReturnType<typeof actions.getSubscriberStatus>,
) {
  const { userId, alertType } = action.payload;
  try {
    const [subscriberSMSStatusResponse, subscriberEmailStatusResponse] =
      yield* all([
        call(
          BackendService.getSubscriberStatus,
          userId,
          ALERTS_TARGET_MEDIAS.SMS,
          alertType,
        ),
        call(
          BackendService.getSubscriberStatus,
          userId,
          ALERTS_TARGET_MEDIAS.EMAIL,
          alertType,
        ),
      ]);
    yield* put(
      actions.getSubscriberStatusSuccess({
        sms: subscriberSMSStatusResponse?.data,
        email: subscriberEmailStatusResponse?.data,
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in getSubscriberStatus: ', e);
  }
}

function* updateSubscribers(
  action: ReturnType<typeof actions.updateSubscribers>,
) {
  const { userId, data, includeNotification } = action.payload;

  try {
    const { data: responseData } = yield* call(
      BackendService.updateSubscribers,
      userId,
      data,
    );
    yield* put(actions.updateSubscribersSuccess(responseData));
    includeNotification && actionSuccessNotification(action.type, null);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in updateSubscribers: ', e);
  }
}

function* fetchSubscribersList() {
  try {
    const {
      data: { tenantIdToSubscribers },
    } = yield* call(BackendService.getSubscribersList);
    yield* put(actions.setSubscribersStatus(DATA_FETCHING_STATUS.LOADING));
    const loggedInTenantId = yield* select(
      loggedInUserSelectors.getUserTenantId,
    );
    if (!loggedInTenantId || !tenantIdToSubscribers) {
      return;
    }

    yield* put(
      actions.fetchSubscribersListSuccess(
        tenantIdToSubscribers[loggedInTenantId]?.subscribers || [],
      ),
    );
    yield* put(actions.setSubscribersStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in fetchSubscribersList: ', e);
    yield* put(actions.setSubscribersStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

function* updateSubscribersList(
  action: ReturnType<typeof actions.updateSubscribersList>,
) {
  try {
    const { data } = yield* call(
      BackendService.updateSubscribersList,
      action.payload,
    );
    const tenantId = yield* select(loggedInUserSelectors.getCurrentTenantId);

    // TODO: Tech Debt: Find Another way to invalidate old subscriptions
    yield* put(
      actions.updateSubscribersListSuccess({
        subscribers: data.subscribers,
        tenantId: tenantId || '',
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in updateSubscribersList: ', e);
  }
}

export default function* watchAlertsActions() {
  yield* all([
    takeLatest(actions.getSubscriberStatus, getSubscriberStatus),
    takeLatest(actions.updateSubscribers, updateSubscribers),
    takeLatest(actions.fetchSubscribersList, fetchSubscribersList),
    takeLatest(actions.updateSubscribersList, updateSubscribersList),
  ]);
}
