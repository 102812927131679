import styled from 'styled-components';

import Icon from 'src/components/general-ui/Icon';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
  width: 0.7rem;
  height: 0.7rem;
`;

export const StyledCounter = styled.div`
  font-weight: 500;
  font-size: 0.6rem;
  color: #252525;
  margin-right: 0.1rem;
  line-height: 0.7rem;
  margin-top: 0.05rem;
`;
