import { defineMessages } from 'react-intl';

export const appMessages = defineMessages({
  // error
  appStartFailedMessage: {
    defaultMessage: 'Something went wrong',
  },
  appStartFailedDescription: {
    defaultMessage: 'Try to login again',
  },
  somethingWentWrong: {
    defaultMessage: 'Something went wrong...',
  },

  // success
});
