import React, { useEffect, useState } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

// TODO: add back for SMS/EMAIL subscribers
//import NotificationSettings from './NotificationSettings';
import { StyledModal, StyledTabs } from './styled';
import { ErrorState, HPAlertSettingsOnSaveHandlers } from './types';
import { ALERT_SETTINGS_TABS } from './utils';
import { TenantThresholdsState } from 'src/components/AlertSettingsComponents/AlertThresholdsForm';
import { PropsFromRedux } from 'src/routes/NursesStation/components/RoomsAndBeds/DeviceSettings/AlertSettings/AlertSettingsConnector';
import Connector from './AlertSettingsConnector';
import { deepEqual } from 'src/utils/comparators';
import {
  ActivityAlertSettings,
  SelectedLevelAlertSettings,
} from 'src/components/AlertSettingsComponents/ActivityAlerts/types';
import dayjs from 'dayjs';
import { defaultActivityFormData } from 'src/components/AlertSettingsComponents/ActivityAlerts/utils';
import { TenantBaselineThresholdsState } from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';

const DEFAULT_SAVE_HANDLERS: HPAlertSettingsOnSaveHandlers = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveAlertThresholds: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveBaselineThresholds: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveNotificationSettings: () => {},
};

type Props = {
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  areAlertSettingsDataLoading: boolean;
  intl: IntlShape;
};

const AlertSettingsModal = ({
  thresholds,
  baselineThresholds,
  activityAlertSettingsFromDb,
  isModalVisible,
  setIsModalVisible,
  createAlertThreshold,
  editAlertThreshold,
  getThresholdsList,
  fetchActivityAlertSettings,
  updateActivityAlertSettings,
  createBaselineThreshold,
  updateBaselineAlertThreshold,
  getBaselineThresholdsList,
  areAlertSettingsDataLoading,
  intl,
}: Props & PropsFromRedux): JSX.Element => {
  const [onSaveHandlers, setOnSaveHandlers] =
    useState<HPAlertSettingsOnSaveHandlers>(DEFAULT_SAVE_HANDLERS);
  const [errors, setErrors] = useState<ErrorState>({
    thresholds: false,
    baselineThresholds: false,
  });
  const [thresholdsData, setThresholdsData] =
    useState<TenantThresholdsState>(thresholds);
  const [baselineThresholdsData, setBaselineThresholdData] =
    useState<TenantBaselineThresholdsState>(baselineThresholds);
  const [activityAlertSettings, setActivityAlertSettings] = useState<
    ActivityAlertSettings | SelectedLevelAlertSettings
  >(activityAlertSettingsFromDb);

  useEffect(() => {
    getThresholdsList();
    fetchActivityAlertSettings();
    getBaselineThresholdsList();
  }, []);

  useEffect(() => {
    setThresholdsData(thresholds);
  }, [thresholds]);

  useEffect(() => {
    setBaselineThresholdData(baselineThresholds);
  }, [baselineThresholds]);

  useEffect(() => {
    if (!activityAlertSettingsFromDb) {
      return;
    }
    const { selectedLevelAlertSettings } = activityAlertSettingsFromDb;
    setActivityAlertSettings(
      selectedLevelAlertSettings?.outOfBedAlertSettings
        ? {
            ...selectedLevelAlertSettings,
            // tenantIds: tenantIds?.[0],
            outOfBedAlertSettings: {
              ...selectedLevelAlertSettings.outOfBedAlertSettings,
              bedtimeExitAlertSettings: {
                ...selectedLevelAlertSettings.outOfBedAlertSettings
                  .bedtimeExitAlertSettings,
                startTime: dayjs(
                  selectedLevelAlertSettings.outOfBedAlertSettings
                    .bedtimeExitAlertSettings.startTime,
                  'HH',
                ),
                endTime: dayjs(
                  selectedLevelAlertSettings.outOfBedAlertSettings
                    .bedtimeExitAlertSettings.endTime,
                  'HH',
                ),
              },
            },
          }
        : {
            ...defaultActivityFormData,
            tenantIds: selectedLevelAlertSettings?.tenantIds,
          },
    );
  }, [activityAlertSettingsFromDb]);

  useEffect(() => {
    setOnSaveHandlers(prevState => ({
      ...prevState,
      saveAlertThresholds: () =>
        Object.values(thresholdsData).forEach(m => {
          Object.values(m).forEach(threshold => {
            const { id, dirty, enable, value, metric, preposition } = threshold;

            if (dirty) {
              threshold.id
                ? editAlertThreshold({ id, enable, value })
                : createAlertThreshold({
                    enable,
                    metric,
                    preposition,
                    value,
                  });
            }
          });
        }),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thresholdsData]);

  useEffect(() => {
    setOnSaveHandlers(prevState => ({
      ...prevState,
      saveBaselineThresholds: () =>
        Object.values(baselineThresholdsData).forEach(threshold => {
          const {
            id,
            dirty,
            enable,
            metric,
            baselineDaysInterval,
            deviationHoursInterval,
            deviationPercentage,
          } = threshold;

          if (!dirty) {
            return;
          }

          if (threshold.id) {
            updateBaselineAlertThreshold({
              id,
              enable,
              baselineDaysInterval,
              deviationHoursInterval,
              deviationPercentage,
            });
          } else {
            if (!enable) {
              return;
            }
            createBaselineThreshold({
              enable,
              metric,
              baselineDaysInterval,
              deviationHoursInterval,
              deviationPercentage,
            });
          }
        }),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baselineThresholdsData]);

  useEffect(() => {
    if (!activityAlertSettings) {
      return;
    }

    setOnSaveHandlers(prevState => ({
      ...prevState,
      saveActivityAlertsSettings: () =>
        updateActivityAlertSettings({
          ...(activityAlertSettings as SelectedLevelAlertSettings),
          outOfBedAlertSettings: {
            ...(activityAlertSettings as SelectedLevelAlertSettings)
              .outOfBedAlertSettings,
            bedtimeExitAlertSettings: {
              ...(activityAlertSettings as SelectedLevelAlertSettings)
                .outOfBedAlertSettings.bedtimeExitAlertSettings,
              startTime: dayjs(
                (activityAlertSettings as SelectedLevelAlertSettings)
                  .outOfBedAlertSettings.bedtimeExitAlertSettings.startTime,
              ).format('HH:mm'),
              endTime: dayjs(
                (activityAlertSettings as SelectedLevelAlertSettings)
                  .outOfBedAlertSettings.bedtimeExitAlertSettings.endTime,
              ).format('HH:mm'),
            },
          },
          // @ts-ignore need to define better types
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          // tenantIds: subtenantsToUpdate.map(elem => elem.value),
        }),
    }));
  }, [activityAlertSettings]);

  return (
    <StyledModal
      title={intl.formatMessage(messages.alerts)}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      withCancelBtn={true}
      withSaveBtn={true}
      destroyOnClose={true}
      saveBtnProps={{
        isDisabled: errors.thresholds || errors.baselineThresholds,
        onClick: () => {
          Object.values(onSaveHandlers).forEach(handler => {
            handler();
          });
        },
      }}
    >
      <StyledTabs
        destroyInactiveTabPane
        items={ALERT_SETTINGS_TABS.map(
          ({
            key,
            labelMessage,
            component: Component,
            editablePermissions,
          }) => ({
            key: key,
            label: <span>{intl.formatMessage(labelMessage)}</span>,
            children: (
              <>
                {areAlertSettingsDataLoading && <LoadingOverlay />}
                <Component
                  activityAlertSettings={activityAlertSettings}
                  setActivityAlertSettings={setActivityAlertSettings}
                  thresholdsData={thresholdsData}
                  setThresholdsData={setThresholdsData}
                  setBaselineThresholdData={setBaselineThresholdData}
                  baselineThresholdsData={baselineThresholdsData}
                  displaySubtenantSelector={false}
                  setErrors={setErrors}
                  setOnSave={setOnSaveHandlers}
                  setOnSaveHandlers={setOnSaveHandlers}
                  hasSubscriptions={false}
                  editablePermissions={editablePermissions}
                />
              </>
            ),
          }),
        )}
      />
    </StyledModal>
  );
};

const messages = defineMessages({
  alerts: {
    defaultMessage: 'Alert Settings',
  },
  smsEmail: {
    defaultMessage: 'SMS/Email',
  },
  thresholds: {
    defaultMessage: 'HR/RR Thresholds',
  },
});

export default Connector(
  injectIntl(
    React.memo(AlertSettingsModal, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
