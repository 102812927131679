import ums, { PERMISSIONS_CHECK_STRATEGY } from 'src/libs/ums-js-logic';
import permissions from 'src/permissions';

export const hasAnyPermissions = (...permissions: string[]): boolean =>
  ums.hasPermissions(PERMISSIONS_CHECK_STRATEGY.ANY, ...permissions);
export const hasAllPermissions = (...permissions: string[]): boolean =>
  ums.hasPermissions(PERMISSIONS_CHECK_STRATEGY.ALL, ...permissions);

export const hasGMPermissions = () =>
  hasAnyPermissions(permissions.VIEW_TENANT_GROUP) &&
  !ums.getImpersonatedSubtenantId();

export const hasHPPermissions = () =>
  hasAnyPermissions(permissions.VIEW_PATIENTS_MEDICAL_INFO) &&
  !hasGMPermissions();

export const hasImpersonatedHPPermissions = () =>
  hasAnyPermissions(permissions.VIEW_PATIENTS_MEDICAL_INFO) &&
  !hasAnyPermissions(permissions.VIEW_TENANT_GROUP) &&
  ums.getImpersonatedSubtenantId();

export const hasTAPermissions = () =>
  hasAnyPermissions(permissions.VIEW_SETTINGS_SIDE_MENU_BUTTON) &&
  !hasAnyPermissions(permissions.VIEW_FOTA_SIDE_MENU_BUTTON) &&
  !hasGMPermissions() &&
  !ums.getImpersonatedSubtenantId();

export const hasSAPermissions = () =>
  hasAnyPermissions(permissions.VIEW_FOTA_SIDE_MENU_BUTTON) &&
  !hasGMPermissions() &&
  !ums.getImpersonatedSubtenantId();

export const hasEditOrCreateAlertThresholdsPermissions = () =>
  hasAnyPermissions(
    permissions.ALERT_THRESHOLD_CREATE,
    permissions.ALERT_THRESHOLD_UPDATE,
  );
