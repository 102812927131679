import { DeviceConnectionState } from 'src/types/devices';
import { PatientState } from 'src/types/measurements';
import { SessionStatus } from 'src/types/sessions';
import { SerialNumber, UUID } from 'src/types/utility';

type HR = number | null;
type RR = number | null;
type RA = number | null;
type RAbin = number | null;
type HRI = number | null;
type I = number | null;
type E = number | null;
type IE = number | null;
type CVG = number | null;
export type Quality = number | null;

export type Vs1Message = {
  json: string;
  i: string;
  t: string;
  pid: UUID;
  sid: UUID;
  refi: string;
  data: {
    state: PatientState | null;
    vs1: {
      hrt: {
        hr: [HR, CVG, Quality];
      };
      rsp: {
        rr: [RR, CVG, Quality];
        ra: [RA, CVG, Quality];
        rabin: [RAbin, CVG, Quality];
      };
    };
  };
};

export type Vs2Message = {
  json: string;
  i: string;
  t: string;
  pid: UUID;
  sid: UUID;
  refi: string;
  data: {
    state: PatientState | null;
    vs2: {
      hrt: {
        hri: [HRI, CVG];
      };
      rsp: {
        rr: [RR, CVG, Quality];
        avdur: {
          i: [I, CVG];
          e: [E, CVG];
        };
      };
    };
  };
};

export type Vs3Message = {
  json: '3.0';
  i: number;
  t: number;
  refi: number;
  pid: UUID;
  sid: UUID;
  data: {
    state: PatientState | null;
    vs: {
      hrt: {
        hr: [HR, CVG, Quality];
      };
      rsp: {
        rr: [RR, CVG, Quality];
        ra: [RA, CVG, Quality];
        rabin: [RAbin, CVG, Quality];
        ie: [IE, CVG, Quality];
      };
    };
  };
};

export type Vs4Message = {
  json: '4.0';
  i: number;
  t: number;
  refi: number;
  pid: UUID;
  sid: UUID;
  data: {
    length: number;
    array: Array<Vs3Message['data']>;
  };
};

export type ProccessedVs3Message = {
  patientId: UUID;
  sessionId: UUID;
  state: PatientState | null;
  hr: [HR, CVG, Quality];
  rr: [RR, CVG, Quality];
  rabin: [RAbin, CVG, Quality];
  ie: [IE, CVG, Quality];
};

export type SpotMessage = {
  pid: UUID;
  sid: UUID;
  data: {
    state: PatientState | null;
    vs: {
      hrt: {
        med: number | null;
        min: number | null;
        max: number | null;
        cvg: CVG;
      };
      rsp: {
        med: number | null;
        min: number | null;
        max: number | null;
        cvg: CVG;
      };
    };
  };
};

export type HriMessage = {
  pid: UUID;
  sid: UUID;
  data: {
    statusCode: string;
    error: string;
    sdnn: number | null;
    rmssd: number | null;
  };
};

export type DeviceStateMessage = {
  data: DeviceConnectionState;
};

export type SessionUpdateMessage = {
  pid: UUID;
  sid: UUID;
  data: {
    deviceId: SerialNumber;
    status: SessionStatus;
  };
};

export type LivePatientDevice = { patientId: UUID; deviceId: SerialNumber };

export type SessionInfo = {
  deviceId: SerialNumber;
  status: SessionStatus;
  patientId: UUID;
  sessionId: UUID;
};

export type DeviceErrorPayload = {
  actionType: string;
  deviceIds: SerialNumber | SerialNumber[];
  error: unknown;
};

export enum MeasurementType {
  HR = 'HR',
  RR = 'RR',
  IE = 'IE',
  RAbin = 'RAbin',
}
