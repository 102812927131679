import React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

import PageSummaryPanel, {
  totalDevicesIcon,
  connectedAssignedDevicesIcon,
  connectedUnassignedDevicesIcon,
  disconnectedAssignedDevicesIcon,
  disconnectedUnassignedDevicesIcon,
  lastDayAlertsIcon,
  disconnectedAssignedDevicesRedIcon,
  disconnectedUnassignedDevicesRedIcon,
  lastDayAlertsRedIcon,
} from 'src/components/PageSummaryPanel';
import Connector, { PropsFromRedux } from './Connector';

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const messages = defineMessages({
  totalDevices: {
    defaultMessage: 'Total Devices',
  },
  connectedAssignedDevices: {
    defaultMessage: 'Connected & Assigned',
  },
  connectedUnassignedDevices: {
    defaultMessage: 'Connected & Unassigned',
  },
  disconnectedAssignedDevices: {
    defaultMessage: 'Disconnected & Assigned',
  },
  disconnectedUnassignedDevices: {
    defaultMessage: 'Disconnected & Unassigned',
  },
  lastDayAlerts: {
    defaultMessage: 'Alerts',
  },
  totalDevicesTooltip: {
    defaultMessage: 'Total devices assigned to current Multi-Tenant Manager',
  },
  connectedAssignedDevicesTooltip: {
    defaultMessage:
      'Total devices that are assigned to patients and monitored in the previous 24 hours',
  },
  connectedUnassignedDevicesTooltip: {
    defaultMessage:
      'Total devices that are currently not assigned to patients and are available and ready for use',
  },
  disconnectedAssignedDevicesTooltip: {
    defaultMessage:
      'Total devices that are currently assigned to patients and are disconnected in the previous 24 hours',
  },
  disconnectedUnassignedDevicesTooltip: {
    defaultMessage:
      'Total devices that are currently unassigned to patients and are disconnected in the previous 24 hours',
  },
  lastDayAlertsTooltip: {
    defaultMessage:
      'Total active and not active alerts that occurred in the last 24 hours',
  },
});

const getStatisticsItems = ({
  totalDevices,
  connectedAssignedDevices,
  connectedUnassignedDevices,
  disconnectedAssignedDevices,
  disconnectedUnassignedDevices,
  lastDayAlertEvents,
  intl,
}: Props) => [
  {
    key: 'total-devices',
    order: 1,
    icon: totalDevicesIcon,
    title: intl.formatMessage(messages.totalDevices),
    tooltip: intl.formatMessage(messages.totalDevicesTooltip),
    value: totalDevices.toString(),
  },
  {
    key: 'connected-assigned-devices',
    order: 2,
    icon: connectedAssignedDevicesIcon,
    title: intl.formatMessage(messages.connectedAssignedDevices),
    tooltip: intl.formatMessage(messages.connectedAssignedDevicesTooltip),
    value: connectedAssignedDevices.toString(),
  },
  {
    key: 'connected-unassigned-devices',
    order: 3,
    icon: connectedUnassignedDevicesIcon,
    title: intl.formatMessage(messages.connectedUnassignedDevices),
    tooltip: intl.formatMessage(messages.connectedUnassignedDevicesTooltip),
    value: connectedUnassignedDevices.toString(),
  },
  {
    key: 'disconnected-assigned-devices',
    order: 4,
    icon:
      disconnectedAssignedDevices > 0
        ? disconnectedAssignedDevicesRedIcon
        : disconnectedAssignedDevicesIcon,
    title: intl.formatMessage(messages.disconnectedAssignedDevices),
    tooltip: intl.formatMessage(messages.disconnectedAssignedDevicesTooltip),
    value: disconnectedAssignedDevices.toString(),
  },
  {
    key: 'disconnected-unassigned-devices',
    order: 5,
    icon:
      disconnectedUnassignedDevices > 0
        ? disconnectedUnassignedDevicesRedIcon
        : disconnectedUnassignedDevicesIcon,
    title: intl.formatMessage(messages.disconnectedUnassignedDevices),
    tooltip: intl.formatMessage(messages.disconnectedUnassignedDevicesTooltip),
    value: disconnectedUnassignedDevices.toString(),
  },
  {
    key: 'last-day-alerts',
    order: 6,
    icon: lastDayAlertEvents > 0 ? lastDayAlertsRedIcon : lastDayAlertsIcon,
    title: intl.formatMessage(messages.lastDayAlerts),
    tooltip: intl.formatMessage(messages.lastDayAlertsTooltip),
    value: lastDayAlertEvents.toString(),
  },
];

const SummaryPanel = (props: Props): JSX.Element => {
  return (
    <PageSummaryPanel
      statisticsItems={getStatisticsItems(props)}
      isAltDisplay={true}
    />
  );
};

export default Connector(injectIntl(SummaryPanel));
