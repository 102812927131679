import * as R from 'fp-ts/lib/Record';

import { AlertThreshold, AverageThresholdMetric } from 'src/types/alerts';
import {
  MAX_HR_VALUE,
  MAX_RR_VALUE,
  MIN_HR_VALUE,
  MIN_RR_VALUE,
} from './constants';
import {
  EditableThreshold,
  ThresholdState,
  ThresholdsErrorState,
  TenantThresholdsState,
} from './types';
import {
  ALERT_METRIC_ENUM,
  ALERT_METRIC_PREPOSITION_ENUM,
} from 'src/redux/data/constants';

const validateThresholdPriority = (item: ThresholdState): boolean =>
  item.BELOW.value >= item.ABOVE.value;

const validateThresholdInLimits = (
  metric: AverageThresholdMetric,
  threshold: EditableThreshold,
): boolean => {
  if (metric === ALERT_METRIC_ENUM.HR) {
    if (threshold.value < MIN_HR_VALUE || threshold.value > MAX_HR_VALUE) {
      return true;
    }
  }

  if (metric === ALERT_METRIC_ENUM.RR) {
    if (threshold.value < MIN_RR_VALUE || threshold.value > MAX_RR_VALUE) {
      return true;
    }
  }

  return false;
};

export const mapValidationErrors = (
  thresholdState: TenantThresholdsState,
): ThresholdsErrorState =>
  R.mapWithIndex((metric: AverageThresholdMetric, item: ThresholdState) => {
    if (validateThresholdPriority(item)) {
      return {
        ABOVE: true,
        BELOW: true,
      };
    }

    return R.map((threshold: EditableThreshold) =>
      validateThresholdInLimits(metric, threshold),
    )(item);
  })(thresholdState);

const defaultThreshold = ({
  metric,
  preposition,
}: Pick<AlertThreshold, 'metric' | 'preposition'>): AlertThreshold => ({
  id: '',
  creationTime: '',
  lastModifiedTime: '',
  enable: true,
  metric,
  preposition,
  value: 0,
});

const findThresholdWithDefault = (
  thresholds: AlertThreshold[],
  metric: AlertThreshold['metric'],
  preposition: AlertThreshold['preposition'],
): EditableThreshold => ({
  ...(thresholds.find(
    t => t.metric === metric && t.preposition === preposition,
  ) || defaultThreshold({ metric, preposition })),
  dirty: false,
});

export const mapThresholdsArrayToState = (
  thresholds: AlertThreshold[],
): TenantThresholdsState => {
  const hrAboveTreshold = findThresholdWithDefault(
    thresholds,
    ALERT_METRIC_ENUM.HR,
    ALERT_METRIC_PREPOSITION_ENUM.ABOVE,
  );
  const hrBelowTreshold = findThresholdWithDefault(
    thresholds,
    ALERT_METRIC_ENUM.HR,
    ALERT_METRIC_PREPOSITION_ENUM.BELOW,
  );
  const rrAboveTreshold = findThresholdWithDefault(
    thresholds,
    ALERT_METRIC_ENUM.RR,
    ALERT_METRIC_PREPOSITION_ENUM.ABOVE,
  );
  const rrBelowTreshold = findThresholdWithDefault(
    thresholds,
    ALERT_METRIC_ENUM.RR,
    ALERT_METRIC_PREPOSITION_ENUM.BELOW,
  );

  return {
    HR: {
      BELOW: hrBelowTreshold,
      ABOVE: hrAboveTreshold,
    },
    RR: {
      BELOW: rrBelowTreshold,
      ABOVE: rrAboveTreshold,
    },
  };
};
