import { connect } from 'react-redux';

import { selectors as userDataSelectors } from 'src/redux/data/user';
import { USER_TYPES } from 'src/utils/constants';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';

const mapStateToProps = state => {
  const loggedInUserType = loggedInUserSelectors.getLoggedInUserType(state);
  const filteringUserTypes = [
    USER_TYPES.MULTI_TENANT_MANAGER,
    USER_TYPES.MULTI_TENANT_VIEWER,
  ];
  if (loggedInUserType.name !== USER_TYPES.TENANT_ADMIN) {
    filteringUserTypes.push(USER_TYPES.TENANT_VIEWER);
  }
  return {
    userTypes: userDataSelectors
      .getUserTypes(state)
      .filter(userType => ![...filteringUserTypes].includes(userType.name)),
  };
};

const mapActionCreators = {};
const Connector = connect(mapStateToProps, mapActionCreators);
export default Connector;
