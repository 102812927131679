import React, { useEffect, useState } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

import { ALERT_SETTINGS_TAB_KEYS, ErrorState } from './types';
import { StyledModal, StyledTabs } from './styled';
import { hasEditOrCreateAlertThresholdsPermissions } from 'src/utils/permissions';
import { ALERT_SETTINGS_TABS } from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/utils';
import { SubtenantItem } from 'src/routes/GMDashboard/modules/types';
import Connector, { PropsFromRedux } from './Connector';
import {
  ALL_TENANTS_OPTION,
  SETTINGS_TYPE,
} from 'src/routes/GMDashboard/modules/constants';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import { deepEqual } from 'src/utils/comparators';
import { useMedicalAlertsTresholds } from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/hooks/useMedicalAlertsTresholds';
import { useSubscriptions } from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/hooks/useSubscription';
import { useGetActivityAlertsSettings } from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/hooks/useGetActivityAlertsSettings';
import { useSaveHandlers } from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/hooks/useSaveHandlers';

type Props = PropsFromRedux & {
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  intl: IntlShape;
};

const GMAlertSettingsModal = ({
  isModalVisible,
  setIsModalVisible,
  intl,
  subtenants,
  allSubscribers,
  gmSubscribers,
  updateSubscribersList,
  updateSubtenantSubscribersList,
  gmUserId,
  gmTenantId,
  gmSubscriptionDetails,
  subscribersDict,
  isDataLoading,
  fetchMTMThresholdList,
  fetchMTMBaselineThresholdList,
  fetchMTMSubscribersList,
  fetchSubtenantData,
  fetchMTMActivityAlertSettings,
  activityAlertSettingsFromDb,
  updateMTMActivityAlertSettings,
  gmThresholds,
  allThresholds,
  thresholdsDict,
  gmBaselineThresholds,
  allBaselineThresholds,
  baselineThresholdsDict,
}: Props): JSX.Element => {
  const [errors, setErrors] = useState<ErrorState>({
    subtenants: false,
    thresholds: false,
    baselineThresholds: false,
    technicalAlerts: false,
  });
  const [selectedSubtenants, setSelectedSubtenants] = useState<SubtenantItem[]>(
    [],
  );
  const [isActivityFormValid, setIsActivityFormValid] = useState(true);

  const canEditAlertThresholds = !hasEditOrCreateAlertThresholdsPermissions()
    ? {
        confirmationTitle: intl.formatMessage(messages.confirmationTitle),
        confirmationText: intl.formatMessage(messages.confirmationText),
      }
    : null;

  const subtenantOptions = [
    {
      label: intl.formatMessage(messages.allTenants),
      value: ALL_TENANTS_OPTION,
      type: SETTINGS_TYPE.COLLECTIVE,
    },
    ...subtenants,
  ];

  useEffect(() => {
    fetchMTMThresholdList();
    fetchMTMBaselineThresholdList();
    fetchMTMSubscribersList();
    fetchSubtenantData({ dataKey: GM_DATA_KEYS.ALERT_THRESHOLDS });
    fetchSubtenantData({
      dataKey: GM_DATA_KEYS.BASELINE_ALERT_THRESHOLDS,
    });
    fetchSubtenantData({ dataKey: GM_DATA_KEYS.SUBSCRIBERS });
    fetchMTMActivityAlertSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    thresholdsData,
    setThresholdsData,
    baselineThresholdsData,
    setBaselineThresholdData,
  } = useMedicalAlertsTresholds({
    gmThresholds,
    selectedSubtenants,
    thresholdsDict,
    allThresholds,
    gmBaselineThresholds,
    allBaselineThresholds,
    baselineThresholdsDict,
  });

  const { subscriptionDetails, setSubscriptionDetails } = useSubscriptions({
    gmSubscriptionDetails,
    selectedSubtenants,
    gmUserId,
    allSubscribers,
    subscribersDict,
  });

  const {
    activityAlertSettings,
    setActivityAlertSettings,
    displayUniqueOverrideMessage,
    areSelectedTenantsSettingsEquals,
  } = useGetActivityAlertsSettings({
    activityAlertSettingsFromDb,
    selectedSubtenants,
    subtenantOptions,
  });

  const { onSaveHandlers, setOnSaveHandlers } = useSaveHandlers({
    selectedSubtenants,
    subtenants,
    gmUserId,
    allSubscribers,
    gmSubscribers,
    subscribersDict,
    gmTenantId,
    subscriptionDetails,
    activityAlertSettings,
    updateSubtenantSubscribersList,
    updateSubscribersList,
    updateMTMActivityAlertSettings,
  });

  const uniqueOverrideMessage = displayUniqueOverrideMessage
    ? {
        confirmationTitle: intl.formatMessage(messages.overrideSettingsTitle),
        confirmationText: intl.formatMessage(messages.overrideSettingsText),
      }
    : null;

  return (
    <StyledModal
      title={intl.formatMessage(messages.alerts)}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      withCancelBtn={true}
      withSaveBtn={true}
      withSaveConfirmation={canEditAlertThresholds || uniqueOverrideMessage}
      destroyOnClose={true}
      saveBtnProps={{
        isDisabled:
          (errors.subtenants && !isActivityFormValid) ||
          (errors.thresholds &&
            errors.baselineThresholds &&
            !isActivityFormValid &&
            errors.technicalAlerts) ||
          !selectedSubtenants.length,
        onClick: () => {
          isActivityFormValid &&
            selectedSubtenants.filter(
              subtenant => subtenant.value !== ALL_TENANTS_OPTION,
            )?.length &&
            onSaveHandlers?.saveActivityAlertsSettings?.();
          !errors.technicalAlerts &&
            !errors.subtenants &&
            onSaveHandlers?.saveSubscribers();
          if (
            !errors.subtenants &&
            !errors.thresholds &&
            !errors.baselineThresholds
          ) {
            onSaveHandlers?.saveAlertThresholds();
            onSaveHandlers?.saveThresholds?.();
            onSaveHandlers?.saveBaselineThresholds();
          }
        },
      }}
    >
      <StyledTabs
        destroyInactiveTabPane
        items={ALERT_SETTINGS_TABS.map(
          ({
            key,
            labelMessage,
            component: Component,
            editablePermissions,
          }) => ({
            key: key,
            label: <span>{intl.formatMessage(labelMessage)}</span>,
            children: (
              <>
                {isDataLoading && <LoadingOverlay />}
                <Component
                  subtenants={subtenants}
                  thresholdsData={thresholdsData}
                  allBaselineThresholds={allBaselineThresholds}
                  allThresholds={allThresholds}
                  baselineThresholdsDict={baselineThresholdsDict}
                  thresholdsDict={thresholdsDict}
                  setThresholdsData={setThresholdsData}
                  baselineThresholdsData={baselineThresholdsData}
                  setBaselineThresholdData={setBaselineThresholdData}
                  setOnSaveHandlers={setOnSaveHandlers}
                  subtenantOptions={
                    key === ALERT_SETTINGS_TAB_KEYS.ACTIVITY_ALERTS
                      ? [...subtenants]
                      : subtenantOptions
                  }
                  selectedSubtenants={selectedSubtenants}
                  setSelectedSubtenants={setSelectedSubtenants}
                  setErrors={setErrors}
                  subscriptionDetails={subscriptionDetails}
                  activityAlertSettings={activityAlertSettings}
                  setActivityAlertSettings={setActivityAlertSettings}
                  setSubscriptionDetails={setSubscriptionDetails}
                  displaySubtenantSelector={true}
                  areSelectedTenantsSettingsEquals={
                    areSelectedTenantsSettingsEquals
                  }
                  setIsFormValid={setIsActivityFormValid}
                  editablePermissions={editablePermissions}
                  isFormEnabled={
                    key === ALERT_SETTINGS_TAB_KEYS.ACTIVITY_ALERTS
                      ? !!selectedSubtenants.filter(
                          subtenant => subtenant.value !== ALL_TENANTS_OPTION,
                        ).length
                      : !!selectedSubtenants.length
                  }
                />
              </>
            ),
          }),
        )}
      />
    </StyledModal>
  );
};

const messages = defineMessages({
  alerts: {
    defaultMessage: 'Alert Settings',
  },
  confirmationTitle: {
    defaultMessage: 'Are you sure you want to subscribe to notifications?',
  },
  overrideSettingsTitle: {
    defaultMessage: 'Are you sure?',
  },
  overrideSettingsText: {
    defaultMessage:
      'You are about to override existing settings. To proceed, click “Save” again”',
  },
  confirmationText: {
    defaultMessage: "This tenant hasn't set any Alert Thresholds yet.",
  },
  allTenants: {
    defaultMessage: 'All Tenants',
  },
});

export default Connector(
  injectIntl(
    React.memo(GMAlertSettingsModal, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
