import { defineMessages } from 'react-intl';

import Dashboard from '../components/DashboardTab/_DashboardTab';
import StatisticsTab from '../components/StatisticsTab';
// TODO: Add Personal event log when required
// import PersonalEventLogTab from '../components/PersonalEventLogTab';

import { IndividualDashboardTab } from './types';

const messages = defineMessages({
  dashboard: {
    defaultMessage: 'Dashboard',
  },
  statistics: {
    defaultMessage: 'Statistics',
  },
  personalEventLog: {
    defaultMessage: 'Event Log',
  },
});

export const INDIVIDUAL_DASHBOARD_TABS: IndividualDashboardTab[] = [
  {
    key: 'dashboard',
    labelMessage: messages.dashboard,
    component: Dashboard,
  },
  {
    key: 'statistics',
    labelMessage: messages.statistics,
    component: StatisticsTab,
  },
  // TODO: Add event log when required
  // {
  //   key: 'personal-event-log',
  //   labelMessage: messages.personalEventLog,
  //   component: PersonalEventLogTab,
  // },
];

export const DEFAULT_TAB: IndividualDashboardTab['key'] = 'dashboard';
