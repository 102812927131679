export default Object.freeze({
  VIEW_TENANTS_SIDE_MENU_BUTTON: 'VIEW_TENANTS_SIDE_MENU_BUTTON',
  VIEW_DEVICES_SIDE_MENU_BUTTON: 'VIEW_DEVICES_SIDE_MENU_BUTTON',
  VIEW_DEVICES_ADD_BUTTON: 'VIEW_DEVICES_ADD_BUTTON',
  VIEW_DEVICES_MONITOR_ACTION: 'VIEW_DEVICES_MONITOR_ACTION',
  VIEW_DEVICES_TENANT_ACTIONS: 'VIEW_DEVICES_TENANT_ACTIONS',
  VIEW_DEVICES_TENANT_COLUMN: 'VIEW_DEVICES_TENANT_COLUMN',
  VIEW_DEVICES_GROUP_COLUMN: 'VIEW_DEVICES_GROUP_COLUMN',
  VIEW_DEVICES_EDIT_DEVICE_ACTION: 'VIEW_DEVICES_EDIT_DEVICE_ACTION',
  VIEW_DEVICES_DELETE_DEVICE_ACTION: 'VIEW_DEVICES_DELETE_DEVICE_ACTION',
  VIEW_PATIENTS_SIDE_MENU_BUTTON: 'VIEW_PATIENTS_SIDE_MENU_BUTTON',
  VIEW_PATIENTS_MEDICAL_INFO: 'VIEW_PATIENTS_MEDICAL_INFO',
  VIEW_PATIENTS_ADD_BUTTON: 'VIEW_PATIENTS_ADD_BUTTON',
  VIEW_PATIENTS_ACTION_BUTTONS: 'VIEW_PATIENTS_ACTION_BUTTONS',
  VIEW_USERS_SIDE_MENU_BUTTON: 'VIEW_USERS_SIDE_MENU_BUTTON',
  VIEW_FOTA_SIDE_MENU_BUTTON: 'VIEW_FOTA_SIDE_MENU_BUTTON',
  VIEW_DEVICES_FW_VERSIONS: 'VIEW_DEVICES_FW_VERSIONS',
  VIEW_MANAGEMENT_SIDE_MENU_BUTTON: 'VIEW_MANAGEMENT_SIDE_MENU_BUTTON',
  VIEW_SETTINGS_SIDE_MENU_BUTTON: 'VIEW_SETTINGS_SIDE_MENU_BUTTON',
  VIEW_DEVICE_INFO_ACTION: 'VIEW_DEVICE_INFO_ACTION',
  VIEW_ROOMS_DEVICE_MODAL: 'VIEW_ROOMS_DEVICE_MODAL',
  VIEW_PATIENTS_DELETE_PATIENT: 'VIEW_PATIENTS_DELETE_PATIENT',
  VIEW_INDIVIDUAL_DASHBOARD: 'VIEW_INDIVIDUAL_DASHBOARD',
  VIEW_TENANT_GROUP: 'VIEW_TENANT_GROUP',
  ORGANIZATION_INVITATIONS_CREATE: 'ORGANIZATION_INVITATIONS_CREATE',
  DEVICE_GET_TEMP_CREDENTIALS_FOR_DEVICES:
    'DEVICE_GET_TEMP_CREDENTIALS_FOR_DEVICES',
  ALERT_SETTINGS_PATIENTS_ALERT_TYPES_UPDATE:
    'ALERT_SETTINGS_PATIENTS_ALERT_TYPES_UPDATE',
  ALERT_GET_BED_OCCUPANCIES_BETWEEN: 'ALERT_GET_BED_OCCUPANCIES_BETWEEN',
  ALERT_SUPPRESS: 'ALERT_SUPPRESS',
  ALERT_UN_SUPPRESS: 'ALERT_UN_SUPPRESS',
  TELEMETRY_START_CONTINUOUS_SESSION: 'TELEMETRY_START_CONTINUOUS_SESSION',
  TELEMETRY_STOP_CONTINUOUS_SESSION: 'TELEMETRY_STOP_CONTINUOUS_SESSION',
  ALERT_CREATE_PATIENT_ALERT_NOTE: 'ALERT_CREATE_PATIENT_ALERT_NOTE',
  ALERT_SUBSCRIBER_CREATE: 'ALERT_SUBSCRIBER_CREATE',
  TELEMETRY_SETTINGS_UPDATE: 'TELEMETRY_SETTINGS_UPDATE',
  ORGANIZATION_PATIENTS_CREATE: 'ORGANIZATION_PATIENTS_CREATE',
  ORGANIZATION_PATIENTS_UPDATE: 'ORGANIZATION_PATIENTS_UPDATE',
  ALERT_THRESHOLD_CREATE: 'ALERT_THRESHOLD_CREATE',
  ALERT_THRESHOLD_UPDATE: 'ALERT_THRESHOLD_UPDATE',
  ORGANIZATION_USER_UPDATE: 'ORGANIZATION_USER_UPDATE',
  ORGANIZATION_INVITATIONS_DELETE: 'ORGANIZATION_INVITATIONS_DELETE',
  ORGANIZATION_INVITATIONS_RESEND: 'ORGANIZATION_INVITATIONS_RESEND',
  ALERT_CLEAR_ALL: 'ALERT_CLEAR_ALL',
  UMS_USERS_SELF_POST_FORGOT_PASSWORD: 'UMS_USERS_SELF_POST_FORGOT_PASSWORD',
  ACTIVITY_ALERT_SETTINGS_CREATE_UPDATE:
    'ACTIVITY_ALERT_SETTINGS_CREATE_UPDATE',
  ACTIVITY_ALERT_SETTINGS_CREATE_UPDATE_MTM:
    'ACTIVITY_ALERT_SETTINGS_CREATE_UPDATE_MTM',
});
