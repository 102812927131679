import { defineMessages } from 'react-intl';

export const tenantUsersTableMessages = defineMessages({
  userType: {
    defaultMessage: 'User Type',
  },
  name: {
    defaultMessage: 'Name',
  },
  email: {
    defaultMessage: 'Email',
  },
  phoneNumber: {
    defaultMessage: 'Phone Number',
  },
  status: {
    defaultMessage: 'Status',
  },
  smsAlert: {
    defaultMessage: 'SMS Alert',
  },
  emailAlert: {
    defaultMessage: 'E-mail Alert',
  },
  invite: {
    defaultMessage: 'Invite',
  },
  edit: {
    defaultMessage: 'Edit',
  },
  delete: {
    defaultMessage: 'Delete',
  },
  deleteConfirmation: {
    defaultMessage: 'Are you sure you want to delete?',
  },
  resetPassword: {
    defaultMessage: 'Reset Password',
  },
  resendInvite: {
    defaultMessage: 'Resend invite',
  },
  medicalAlerts: {
    defaultMessage: 'Medical Alerts',
  },
  technicalAlerts: {
    defaultMessage: 'Connectivity Alerts',
  },
  activityAlerts: {
    defaultMessage: 'Bed Activity Alerts',
  },
  withPersonalDetails: {
    defaultMessage: "With patient's personal details",
  },
});
