import React from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';

import { SubTenantOverview } from 'src/routes/GMDashboard/modules/types';
import Icon from 'src/components/general-ui/Icon';
import { Column, Label, Value, VerticalRule } from './styled';
import SubtenantAlerts from './SubtenantAlerts';

const PatientsStatisticsWrapper = styled.div<{ isAlert: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 0.25rem 0rem;
  background: ${props =>
    props.isAlert ? props.theme.colors.alertBackground : '#f4f6f8'};
  border: 1px solid
    ${props => (props.isAlert ? props.theme.colors.alertBorder : '#e5e5e5')};
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.4rem;
`;

const StyledBellIcon = styled(Icon)`
  width: 0.85rem;
  height: 0.95rem;
  margin-right: 0.2rem;
`;

const StyledDeviceIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.15rem;
`;

const StyledPatientIcon = styled(Icon)`
  width: 1.25rem;
  height: 1rem;
  margin-right: 0.15rem;
`;

type Props = Pick<
  SubTenantOverview,
  | 'id'
  | 'name'
  | 'lastDayAlertsCounter'
  | 'hasActiveAlert'
  | 'connectedAssignedDevicesCounter'
  | 'connectedUnassignedDevicesCounter'
>;

const PatientsStatistics = ({
  id,
  name,
  lastDayAlertsCounter,
  hasActiveAlert,
  connectedAssignedDevicesCounter,
  connectedUnassignedDevicesCounter,
}: Props): JSX.Element => (
  <PatientsStatisticsWrapper isAlert={hasActiveAlert}>
    <Column width="26%">
      <SubtenantAlerts subtenantId={id} subtenantName={name} />
      <Value isAlert={lastDayAlertsCounter > 0}>
        <StyledBellIcon
          type="bell-icon-outlined"
          color={lastDayAlertsCounter > 0 ? '#CA0000' : '#252525'}
        />
        {lastDayAlertsCounter}
      </Value>
    </Column>
    <VerticalRule isAlert={hasActiveAlert} />
    <Column width="37%">
      <Label>
        <FormattedMessage {...messages.connectedAssignedDevices} />
      </Label>
      <Value>
        <StyledPatientIcon type="patient-icon" backgroundcolor="#E5E5E5" />
        {connectedAssignedDevicesCounter}
      </Value>
    </Column>
    <VerticalRule />
    <Column width="37%">
      <Label>
        <FormattedMessage {...messages.connectedUnassignedDevices} />
      </Label>
      <Value>
        <StyledDeviceIcon type="wifi-connected-icon" />
        {connectedUnassignedDevicesCounter}
      </Value>
    </Column>
  </PatientsStatisticsWrapper>
);

const messages = defineMessages({
  connectedAssignedDevices: {
    defaultMessage: 'Connected & Assigned',
  },
  connectedUnassignedDevices: {
    defaultMessage: 'Connected & Unassigned',
  },
});

export default PatientsStatistics;
