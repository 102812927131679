import { actions as deviceDataActions } from 'src/redux/data/device/modules/slice';
import { intl, messages } from '../messages';

export const deviceActionToErrorNotificationMessagesMap = {
  [deviceDataActions.createDevice.type]: (manufacturerId, _) => ({
    message: intl.formatMessage(messages.createDeviceError),
    description: `${intl.formatMessage(
      messages.createDeviceError,
    )} ${manufacturerId}`,
  }),
  [deviceDataActions.deleteDevice.type]: (manufacturerId, _) => ({
    message: intl.formatMessage(messages.deleteDeviceError),
    description: `${intl.formatMessage(
      messages.deleteDeviceError,
    )} ${manufacturerId}`,
  }),
  [deviceDataActions.editDevice.type]: (manufacturerId, _) => ({
    message: intl.formatMessage(messages.editDeviceError),
    description: `${intl.formatMessage(
      messages.editDeviceError,
    )} ${manufacturerId}`,
  }),
  [deviceDataActions.assignDevice.type]: (manufacturerId, _) => ({
    message: intl.formatMessage(messages.assignDeviceError),
    description: `${intl.formatMessage(
      messages.assignDeviceError,
    )} ${manufacturerId}`,
  }),
  [deviceDataActions.detachDevice.type]: (manufacturerId, _) => ({
    message: intl.formatMessage(messages.detachDeviceError),
    description: `${intl.formatMessage(
      messages.detachDeviceError,
    )} ${manufacturerId}`,
  }),
};
