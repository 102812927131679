import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/redux/store';

import dataSelectors from 'src/redux/data/dataSelectors';
import { actions as monitorActions } from 'src/redux/data/monitor';
import { SerialNumber } from 'src/types/utility';

type OwnProps = {
  deviceId: SerialNumber;
};

const mapStateToProps = (state: RootState) => ({
  patients: dataSelectors.selectAssignedDevicePatients(state),
});

const mapActionCreators = {
  startContinuousSession: monitorActions.onClickStartContinuous,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type Props = PropsFromRedux & OwnProps;

export default Connector;
