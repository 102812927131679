import React, { cloneElement } from 'react';
import Connector from 'src/components/AppLayout/Connector';
import ums, { PERMISSIONS_CHECK_STRATEGY } from 'src/libs/ums-js-logic';
import styled from 'styled-components';
import { Tooltip } from 'antd';

type Props = {
  children: JSX.Element | JSX.Element[];
  permissions: string[];
  strategy?: keyof typeof PERMISSIONS_CHECK_STRATEGY;
  noPermissionBehavior?: Record<string, boolean | string>;
};
const NoPermissionWrapper = styled.div`
  pointer-events: none;
  opacity: 0.4;
`;
const AccessControl = ({
  children,
  permissions,
  strategy = PERMISSIONS_CHECK_STRATEGY.ANY,
  noPermissionBehavior = { disabled: true },
}: Props): JSX.Element => {
  if (!permissions.length) {
    return <>{children}</>;
  }
  const hasPermission = ums.hasPermissions(strategy, ...permissions);

  if (!hasPermission) {
    return (
      <Tooltip placement="top" title="No permission">
        <>
          <NoPermissionWrapper>
            {cloneElement(<>{children}</>, { ...noPermissionBehavior })}
          </NoPermissionWrapper>
        </>
      </Tooltip>
    );
  }
  return <>{children}</>;
};
export default Connector(AccessControl);
