import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Select } from 'antd';
import { RefSelectProps } from 'antd/lib/select';

import MultiSelect from 'src/components/general-ui/MultiSelect';
import { SETTINGS_TYPE } from 'src/routes/GMDashboard/modules/constants';
import {
  SubtenantItem,
  TenantSubscriptionDetails,
} from 'src/routes/GMDashboard/modules/types';
import { ErrorState, MTMAlertSettingsOnSaveHandlers } from '../types';
import MTMAlertThresholdsForm from './MTMAlertThresholdForm';
import MTMMedicalAlerts from './MTMMedicalAlerts';
import Connector, { PropsFromRedux } from './Connector';
import {
  SettingsInfo,
  StyledOptionLabel,
  StyledOptionLabelTag,
  StyledWrapper,
} from './styled';
import { messages, typeToColor, typeToMessage } from './constants';
import MTMBaselineThresholdForm from './MTMBaselineThresholdForm';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';
import { deepEqual } from 'src/utils/comparators';
import { TenantThresholdsState } from 'src/components/AlertSettingsComponents/AlertThresholdsForm';
import { DataTenantDict } from 'src/redux/data/groupManager/modules/types';
import { AlertThreshold, BaselineAlertThreshold } from 'src/types/alerts';
import { TenantBaselineThresholdsState } from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';

const { Option } = Select;

const customOptionRender = ({ value, label, type }: SubtenantItem) => (
  <Option value={value} label={label} key={value}>
    <StyledOptionLabel>{label}</StyledOptionLabel>
    <StyledOptionLabelTag color={typeToColor[type]}>
      <FormattedMessage {...typeToMessage[type]} />
    </StyledOptionLabelTag>
  </Option>
);

type Props = PropsFromRedux & {
  setOnSaveHandlers: Dispatch<SetStateAction<MTMAlertSettingsOnSaveHandlers>>;
  setErrors: Dispatch<SetStateAction<ErrorState>>;
  intl: IntlShape;
  setSelectedSubtenants: Dispatch<SetStateAction<SubtenantItem[]>>;
  selectedSubtenants: SubtenantItem[];
  subtenantOptions: SubtenantItem[];
  subtenants: SubtenantItem[];
  thresholdsDict: DataTenantDict;
  allThresholds: AlertThreshold[];
  baselineThresholdsDict: DataTenantDict;
  allBaselineThresholds: BaselineAlertThreshold[];
  subscriptionDetails: TenantSubscriptionDetails;
  setSubscriptionDetails: Dispatch<SetStateAction<TenantSubscriptionDetails>>;
  thresholdsData: TenantThresholdsState;
  setThresholdsData: Dispatch<SetStateAction<TenantThresholdsState>>;
  baselineThresholdsData: TenantBaselineThresholdsState;
  setBaselineThresholdData: Dispatch<
    SetStateAction<TenantBaselineThresholdsState>
  >;
};

const CombinedSettings = ({
  subtenants,
  thresholdsDict,
  allThresholds,
  baselineThresholdsDict,
  allBaselineThresholds,
  isDataLoading,
  selectedSubtenants,
  setSelectedSubtenants,
  setOnSaveHandlers,
  subtenantOptions,
  setErrors,
  intl,
  subscriptionDetails,
  setSubscriptionDetails,
  thresholdsData,
  setThresholdsData,
  baselineThresholdsData,
  setBaselineThresholdData,
}: Props): JSX.Element => {
  const [displayEditableSettings, setDisplayEditableSettings] = useState(true);
  const ref = useRef<RefSelectProps>(null);

  useEffect(() => {
    const settingsDoNoMatch =
      selectedSubtenants.length > 1 &&
      selectedSubtenants.some(s => s.type !== SETTINGS_TYPE.COLLECTIVE);

    setDisplayEditableSettings(!settingsDoNoMatch);
    setErrors(prevState => ({
      ...prevState,
      subtenants: selectedSubtenants.length === 0 || settingsDoNoMatch,
    }));

    if (settingsDoNoMatch === displayEditableSettings) {
      ref.current?.blur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayEditableSettings, selectedSubtenants]);

  return (
    <StyledWrapper
      data-cy={`mtm-alert-settings-loading=${isDataLoading.toString()}`}
    >
      {isDataLoading && <LoadingOverlay />}
      <MultiSelect
        selectRef={ref}
        selectedItems={selectedSubtenants.map(s => s.value)}
        options={subtenantOptions}
        handleChange={ids =>
          setSelectedSubtenants(
            subtenantOptions.filter(s => ids.includes(s.value)),
          )
        }
        placeholder={intl.formatMessage(messages.multiselectPlaceholder)}
        customOptionRender={customOptionRender}
      />
      {displayEditableSettings ? (
        <>
          <MTMMedicalAlerts
            selectedSubtenants={selectedSubtenants}
            subscriptionDetails={subscriptionDetails}
            setSubscriptionDetails={setSubscriptionDetails}
          />
          <MTMAlertThresholdsForm
            subtenants={subtenants}
            thresholdsData={thresholdsData}
            setThresholdsData={setThresholdsData}
            selectedSubtenants={selectedSubtenants}
            allThresholds={allThresholds}
            thresholdsDict={thresholdsDict}
            setOnSave={onSave =>
              setOnSaveHandlers(prevState => ({
                ...prevState,
                saveThresholds: onSave,
              }))
            }
            setIsSaveBtnDisabled={isError =>
              setErrors(prevState => ({
                ...prevState,
                thresholds: isError,
              }))
            }
          />
          <MTMBaselineThresholdForm
            baselineThresholdsData={baselineThresholdsData}
            setBaselineThresholdData={setBaselineThresholdData}
            subtenants={subtenants}
            selectedSubtenants={selectedSubtenants}
            allBaselineThresholds={allBaselineThresholds}
            baselineThresholdsDict={baselineThresholdsDict}
            setOnSave={onSave =>
              setOnSaveHandlers(prevState => ({
                ...prevState,
                saveBaselineThresholds: onSave,
              }))
            }
            setIsSaveBtnDisabled={isError =>
              setErrors(prevState => ({
                ...prevState,
                baselineThresholds: isError,
              }))
            }
          />
        </>
      ) : (
        <SettingsInfo>
          <FormattedMessage {...messages.settingsDoNoMatch} />
        </SettingsInfo>
      )}
    </StyledWrapper>
  );
};

export default Connector(
  injectIntl(
    React.memo(CombinedSettings, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
