import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/redux/store';

import {
  actions as roomActions,
  selectors as roomSelectors,
} from 'src/redux/data/rooms';
import { FormRoom } from './types';

const getDeviceRooms = (state: RootState): FormRoom[] => {
  const roomsList = roomSelectors.getRoomList(state);

  return [...roomsList]
    .sort((a, b) => a.creationTime.localeCompare(b.creationTime))
    .map(room => ({
      ...room,
      label: room.name,
      value: room.id,
      beds: room?.beds.map(bed => ({
        ...bed,
        label: bed.name,
        value: bed.name,
      })),
    }));
};

const mapStateToProps = (state: RootState) => ({
  rooms: getDeviceRooms(state),
});

const mapActionCreators = {
  addNewRoom: roomActions.onCreateRoom,
  addNewBed: roomActions.onCreateBed,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;

export default Connector;
