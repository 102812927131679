import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { selectors as deviceSelectors } from 'src/redux/data/device';
import { selectors as patientSelectors } from 'src/redux/data/patient';
import { selectors as alertSelectors } from 'src/redux/data/alerts';
import { selectors as sessionSelectors } from 'src/redux/data/sessions';
import { extractLatestPatientSession } from 'src/redux/data/dataUtils';
import { isInLast24Hours } from 'src/utils/timeUtils';
import { hasAlertFinishedInLast24h } from 'src/redux/data/alerts/modules/utils';
import { isMedicalAlert } from 'src/redux/data/alerts/modules/typeGuards';

const mapStateToProps = (state: RootState) => {
  const devices = deviceSelectors.getDevicesList(state);
  const sessions = sessionSelectors.selectSessions(state);
  const connectedDevices = devices.filter(
    device => device.connectionStatus.connected,
  );
  const patients = patientSelectors.getPatientsList(state);

  const latestPatientSessions = patients.map(p =>
    extractLatestPatientSession(p.id, sessions),
  );
  const assignedPatients = latestPatientSessions.filter(s => s && !s.endTime);
  const lastDayDischargedPatients = latestPatientSessions.filter(
    s => s && s.endTime && isInLast24Hours(s.endTime),
  );
  const lastDayAlertEvents = alertSelectors
    .selectAlerts(state)
    .filter(
      a =>
        isMedicalAlert(a) &&
        (a.status !== 'OFF' || hasAlertFinishedInLast24h(a)),
    );

  return {
    totalDevices: devices.length,
    connectedDevices: connectedDevices.length,
    totalPatients: patients.length,
    assignedPatients: assignedPatients.length,
    lastDayDischarges: lastDayDischargedPatients.length,
    lastDayAlertEvents: lastDayAlertEvents.length,
  };
};

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
