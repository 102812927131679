import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 0.65rem;
  cursor: pointer;
  width: fit-content;
`;

const StyledCheckbox = styled.input<{ withLabel: boolean }>`
  width: 1rem;
  height: 1rem;
  accent-color: #304e64;
  ${props => (props.withLabel ? 'margin-right: 0.75rem' : '')};
  cursor: pointer;
`;
interface MyInputProps {
  value: number;
  onChange: () => void;
  label: string;
  checked: boolean;
  disabled: boolean;
}

const CheckboxForm = ({
  label,
  value,
  onChange,
  checked = false,
  disabled = false,
}: MyInputProps) => {
  return (
    <StyledLabel>
      <StyledCheckbox
        withLabel={!!label}
        type="checkbox"
        value={value}
        checked={!!checked}
        disabled={!!disabled}
        onChange={onChange}
      />
      {label}
    </StyledLabel>
  );
};
export default CheckboxForm;
