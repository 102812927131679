import React from 'react';
import { Route } from 'react-router-dom';
import { defineMessages } from 'react-intl';
import { Location } from 'history';

import AuthLayout from '../../Auth/components/Layout';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: (props: any) => JSX.Element;
  location: Location<{ loginAfterSetPassword: boolean } | undefined>;
  displayLayoutTitle?: boolean;
};

const AuthRoute = ({
  component: Component,
  location,
  displayLayoutTitle,
  ...rest
}: Props) => {
  return (
    <Route
      {...rest}
      render={props => {
        const title = location.state?.loginAfterSetPassword
          ? messages.loginAfterSetPassword
          : messages.neteeraHelthGate;

        return (
          <AuthLayout title={title} displayTitle={displayLayoutTitle}>
            <Component {...props} />
          </AuthLayout>
        );
      }}
    />
  );
};

const messages = defineMessages({
  loginAfterSetPassword: {
    defaultMessage:
      'Your password has been successfully created. {br} You may now log in to Neteera HealthGate.',
  },
  neteeraHelthGate: {
    defaultMessage: 'Welcome to Neteera HealthGate',
  },
});

export default AuthRoute;
