import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { MainTitle, PageWrapper } from 'src/components/styled';
import Table from 'src/components/CrudCommonComponents/Table';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import permissions from 'src/permissions';
import { hasAnyPermissions } from 'src/utils/permissions';
import DeviceModal, { deviceModalModes } from './DeviceModal';
import AssignDeviceModal from './AssignDeviceModal';
import DeviceInfoNodal from './DeviceInfoModal';
import {
  USER_TYPES,
  DEFAULT_PAGINATION,
  healthProfessional,
} from 'src/utils/constants';
import { compareAlphanumerics, deepEqual } from 'src/utils/comparators';
import { getActionPermissionsMap } from './utils';
import { messages } from './constants';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';

const DevicesPage = ({
  devicesList,
  getUserDevicesList,
  onLoadDevicesListPage,
  detachDevice,
  assignDevice,
  deleteDevice,
  createDevice,
  editDevice,
  tenantOptions,
  isLoading,
  intl,
  modalStatus,
  setModalStatus,
  loggedInUserType,
  devicesTotalCount,
  devicesStatus,
  roomsList,
  searchAllDevicesAdmin,
  setSearchedDevices,
  searchTenants,
}) => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState(false);
  const [isDetachConfirmationVisible, setIsDetachConfirmationVisible] =
    useState(false);
  const [isAssignDeviceModalVisible, setIsAssignDeviceModalVisible] =
    useState(false);
  const [isDeviceInfoModalVisible, setisDeviceInfoModalVisible] =
    useState(false);
  const [activeActionRow, setActiveActionRow] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableParams, setTableParams] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState([]);
  const history = useHistory();
  const activeRowManufacturerId = activeActionRow.manufacturerId;
  const userType = loggedInUserType?.name;

  const devicesListByUserMap = {
    [healthProfessional]: devicesList.filter(
      device => device.certificateExists,
    ),
  };
  const devicesListHandler = {
    get(devicesListMap, userTypeProperty) {
      return devicesListMap[userTypeProperty] || devicesList;
    },
  };
  const proxyDevicesList = new Proxy(devicesListByUserMap, devicesListHandler);

  useEffect(() => {
    if (devicesTotalCount) {
      setTableParams({
        current: DEFAULT_PAGINATION.PAGE,
        pageSize: DEFAULT_PAGINATION.LIMIT,
        total: devicesTotalCount,
        showSizeChanger: false,
      });
    }
  }, [devicesTotalCount]);

  useEffect(() => {
    // TODO: remove page limit condition when all device requests supports pagination
    onLoadDevicesListPage({
      page: 0,
      limit:
        userType === USER_TYPES.SYSTEM_ADMIN
          ? DEFAULT_PAGINATION.LIMIT
          : 1000000,
    });
  }, [userType, onLoadDevicesListPage]);

  const handleTableChange = pagination => {
    // TODO: remove tabelParams condition when all device requests supports pagination
    if (tableParams) {
      setTableParams(prevState => ({
        ...prevState,
        current: pagination.current,
      }));
      onLoadDevicesListPage({
        page: pagination.current - 1,
        limit: pagination.pageSize,
      });
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setTableData(
        proxyDevicesList[userType].map(deviceData => ({
          key: deviceData.manufacturerId,
          ...deviceData,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesList, isLoading, getUserDevicesList, userType]);

  const ACTION_PERMISSION_MAP = getActionPermissionsMap(
    intl,
    history,
    setActiveActionRow,
    setIsDeleteConfirmationVisible,
    setIsEditModalVisible,
    setIsDetachConfirmationVisible,
    setIsAssignDeviceModalVisible,
    setisDeviceInfoModalVisible,
  );

  const actions = [];
  Object.keys(ACTION_PERMISSION_MAP).forEach(permission => {
    const hasPermission = hasAnyPermissions(permission);
    if (hasPermission) {
      actions.push(...ACTION_PERMISSION_MAP[permission]);
    }
  });

  const converNullToEmptyString = string => string || '';
  const sorterCompare = (a, b, field) =>
    converNullToEmptyString(a[field]).localeCompare(
      converNullToEmptyString(b[field]),
    );

  const COLUMNS_PERMISSION_MAP = {
    [permissions.VIEW_DEVICES_FW_VERSIONS]: [
      {
        title: intl.formatMessage(messages.version),
        dataIndex: 'fwVersion',
        key: 'fwVersion',
        sorter: (a, b) => sorterCompare(a, b, 'fwVersion'),
      },
    ],
    [permissions.VIEW_DEVICES_TENANT_COLUMN]: [
      {
        title: intl.formatMessage(messages.tenant),
        dataIndex: 'tenantName',
        key: 'tenantName',
        sorter: (a, b) => sorterCompare(a, b, 'tenantName'),
      },
    ],
    [permissions.VIEW_DEVICES_GROUP_COLUMN]: [
      {
        title: intl.formatMessage(messages.group),
        dataIndex: 'groupName',
        key: 'groupName',
        sorter: (a, b) => sorterCompare(a, b, 'groupName'),
      },
    ],
    [permissions.VIEW_ROOMS_DEVICE_MODAL]: [
      {
        title: intl.formatMessage(messages.room),
        render: record => record?.roomBed?.room?.name || 'N/A',
        key: 'roomName',
        sorter: (a, b) =>
          compareAlphanumerics(a?.roomBed?.room?.name, b?.roomBed?.room?.name),
      },
      {
        title: intl.formatMessage(messages.bed),
        render: record => record?.roomBed?.bed?.name || 'N/A',
        key: 'bedName',
        sorter: (a, b) =>
          compareAlphanumerics(a?.roomBed?.bed?.name, b?.roomBed?.bed?.name),
      },
    ],
  };
  const serialNumberColumn = {
    title: intl.formatMessage(messages.manufacturerId),
    dataIndex: 'manufacturerId',
    key: 'manufacturerId',
    filtered: true,
    onFilter: (value, record) =>
      record.manufacturerId.toLowerCase().includes(value.toLowerCase()) ||
      (record.name && record.name.toLowerCase().includes(value.toLowerCase())),
    sorter: (a, b) => sorterCompare(a, b, 'manufacturerId'),
  };
  const nameColumn = {
    title: intl.formatMessage(messages.name),
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => sorterCompare(a, b, 'name'),
  };

  const columnsMap = {
    [healthProfessional]: [nameColumn, serialNumberColumn],
  };
  const columnsMapHandler = {
    get(columns, userTypeProperty) {
      return columns[userTypeProperty] || [serialNumberColumn, nameColumn];
    },
  };

  const proxyColumnsMap = new Proxy(columnsMap, columnsMapHandler);

  const cols = proxyColumnsMap[userType];

  Object.keys(COLUMNS_PERMISSION_MAP).forEach(permission => {
    const hasPermission = hasAnyPermissions(permission);
    if (hasPermission) {
      cols.splice(2, 0, ...COLUMNS_PERMISSION_MAP[permission]);
    }
  });

  useEffect(() => {
    searchKeyword.length !== 0 && searchAllDevicesAdmin(searchKeyword);
    searchKeyword.length === 0 &&
      setSearchedDevices({ data: [], status: DATA_FETCHING_STATUS.SUCCESS });
  }, [searchKeyword, searchTenants, searchAllDevicesAdmin, setSearchedDevices]);

  return (
    <PageWrapper>
      <MainTitle>
        <FormattedMessage {...messages.devices} />
      </MainTitle>
      <Table
        loading={isLoading}
        data={tableData}
        columns={cols}
        actions={actions}
        withSearch
        searchFunction={
          userType === USER_TYPES.SYSTEM_ADMIN && setSearchKeyword
        }
        pagination={searchKeyword.length === 0 && tableParams}
        onChange={handleTableChange}
        searchPlaceholder={intl.formatMessage(messages.searchPlaceholder)}
        withAddButton={hasAnyPermissions(permissions.VIEW_DEVICES_ADD_BUTTON)}
        addButtonOnClick={() => {
          setIsAddModalVisible(true);
        }}
      />
      <DeviceModal
        isModalVisible={isAddModalVisible}
        setIsModalVisible={value => {
          setIsAddModalVisible(value);
        }}
        onOk={createDeviceData => {
          createDevice(createDeviceData);
        }}
        mode={deviceModalModes.ADD}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
      />
      <DeviceModal
        isModalVisible={isEditModalVisible}
        setIsModalVisible={value => {
          setIsEditModalVisible(value);
        }}
        onOk={editDeviceData => {
          editDevice({
            editDeviceData,
            manufacturerId: activeRowManufacturerId,
          });
        }}
        mode={deviceModalModes.EDIT}
        deviceListRow={activeActionRow}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        roomsList={roomsList}
      />
      <ConfirmationModal
        isModalVisible={isDeleteConfirmationVisible}
        setIsModalVisible={value => {
          setIsDeleteConfirmationVisible(value);
        }}
        onOk={() => {
          deleteDevice({
            manufacturerId: activeRowManufacturerId,
            certificateExists: activeActionRow.certificateExists,
          });
        }}
        message={intl.formatMessage(messages.deleteConfirmation)}
      />
      <ConfirmationModal
        isModalVisible={isDetachConfirmationVisible}
        setIsModalVisible={value => {
          setIsDetachConfirmationVisible(value);
        }}
        onOk={() => {
          detachDevice(activeRowManufacturerId);
        }}
        message={intl.formatMessage(messages.detachConfirmation)}
      />
      <AssignDeviceModal
        isModalVisible={isAssignDeviceModalVisible}
        setIsModalVisible={value => {
          setIsAssignDeviceModalVisible(value);
        }}
        onAssign={assignDeviceData => {
          setIsAssignDeviceModalVisible(false);
          assignDevice({
            ...assignDeviceData,
            manufacturerId: activeRowManufacturerId,
          });
        }}
        tenantOptions={tenantOptions}
        searchTenants={searchTenants}
      />
      <DeviceInfoNodal
        isModalVisible={isDeviceInfoModalVisible}
        setIsModalVisible={value => {
          setisDeviceInfoModalVisible(value);
        }}
        onClick={() => {
          setisDeviceInfoModalVisible(false);
        }}
        device={devicesList.find(
          device => device.manufacturerId === activeRowManufacturerId,
        )}
      />
    </PageWrapper>
  );
};

DevicesPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  devicesList: PropTypes.array.isRequired,
  devicesTotalCount: PropTypes.number.isRequired,
  tenantOptions: PropTypes.array.isRequired,
  getUserDevicesList: PropTypes.func.isRequired,
  detachDevice: PropTypes.func.isRequired,
  assignDevice: PropTypes.func.isRequired,
  createDevice: PropTypes.func.isRequired,
  deleteDevice: PropTypes.func.isRequired,
  editDevice: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  loggedInUserType: PropTypes.object.isRequired,
  devicesStatus: PropTypes.string.isRequired,
  onLoadDevicesListPage: PropTypes.func.isRequired,
  roomsList: PropTypes.array.isRequired,
  searchAllDevicesAdmin: PropTypes.func,
  searchTenants: PropTypes.func,
  setSearchedDevices: PropTypes.func,
};

export default injectIntl(
  React.memo(DevicesPage, (oldProps, newProps) =>
    deepEqual(oldProps, newProps),
  ),
);
