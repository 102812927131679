import qs from 'qs';

import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import { RawDevice } from 'src/types/devices';
import { Session } from 'src/types/sessions';
import { UUID } from 'src/types/utility';
import { DEFAULT_LOCALE } from 'src/utils/languageUtils';
import { extractLatestDeviceSession } from 'src/redux/data/dataUtils';
import { SubTenantOverview } from './types';

export const handleClickSubtenantPatientName = (
  subtenantId: UUID,
  patientId: UUID,
) => {
  window.open(
    `${window.location.origin}/${DEFAULT_LOCALE}/${
      appRoutes.INDIVIDUAL_DASHBOARD
    }${qs.stringify(
      {
        impersonatedSubtenantId: subtenantId,
        patientId,
      },
      { addQueryPrefix: true },
    )}`,
  );
};

export const handleClickTenantName = (subtenantId: UUID) => {
  window.open(
    `${window.location.origin}/${DEFAULT_LOCALE}/${
      appRoutes.NURSES_STATION
    }${qs.stringify(
      {
        impersonatedSubtenantId: subtenantId,
      },
      { addQueryPrefix: true },
    )}`,
  );
};

export const extractDeviceInfoData = (
  devices: RawDevice[],
  sessions: Session[],
): Pick<
  SubTenantOverview,
  | 'disconnectedAssignedDevicesCounter'
  | 'disconnectedUnassignedDevicesCounter'
  | 'connectedAssignedDevicesCounter'
  | 'connectedUnassignedDevicesCounter'
> =>
  devices.reduce(
    (acc, device) => {
      const isConnected = device.connectionStatus.connected;
      const latestDeviceSession = extractLatestDeviceSession(
        device.manufacturerId,
        sessions,
      );
      const hasLastSessionClosed = latestDeviceSession
        ? !!latestDeviceSession.endTime
        : true;

      const {
        disconnectedAssignedDevicesCounter,
        disconnectedUnassignedDevicesCounter,
        connectedAssignedDevicesCounter,
        connectedUnassignedDevicesCounter,
      } = acc;

      return {
        disconnectedAssignedDevicesCounter:
          disconnectedAssignedDevicesCounter +
          (!isConnected && !hasLastSessionClosed ? 1 : 0),
        disconnectedUnassignedDevicesCounter:
          disconnectedUnassignedDevicesCounter +
          (!isConnected && hasLastSessionClosed ? 1 : 0),
        connectedAssignedDevicesCounter:
          connectedAssignedDevicesCounter +
          (isConnected && !hasLastSessionClosed ? 1 : 0),
        connectedUnassignedDevicesCounter:
          connectedUnassignedDevicesCounter +
          (isConnected && hasLastSessionClosed ? 1 : 0),
      };
    },
    {
      disconnectedAssignedDevicesCounter: 0,
      disconnectedUnassignedDevicesCounter: 0,
      connectedAssignedDevicesCounter: 0,
      connectedUnassignedDevicesCounter: 0,
    },
  );
