import { createSlice, createSelector, createAction } from '@reduxjs/toolkit';

import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import { actions as monitorActions } from '../../../redux/data/monitor/modules/slice';
import AppConfig from '../../../config/AppConfig';
import { DEFAULT_HISTOGRAM_DATA_STATE } from './utils';

export const STATE_KEY = 'singleDeviceMonitor';

export const INITIAL_STATE = {
  selectedDevice: null,
  searchFocus: false,
  stopConfirmModal: false,
  saveRawData: AppConfig.SAVE_RAW_DATA_DEFAULT,
  histogram: {
    RR: {
      lastDay: DEFAULT_HISTOGRAM_DATA_STATE,
      lastThreeDays: DEFAULT_HISTOGRAM_DATA_STATE,
      lastWeek: DEFAULT_HISTOGRAM_DATA_STATE,
    },
    HR: {
      lastDay: DEFAULT_HISTOGRAM_DATA_STATE,
      lastThreeDays: DEFAULT_HISTOGRAM_DATA_STATE,
      lastWeek: DEFAULT_HISTOGRAM_DATA_STATE,
    },
  },
};

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedDevice: (state, action) => {
      state.selectedDevice = action.payload;
    },
    setSearchFocusStatus: (state, action) => {
      state.searchFocus = action.payload;
    },
    onChangeStopConfirmVisibleModal: (state, action) => {
      state.stopConfirmModal = action.payload;
    },
    toggleRawData: (state, action) => {
      state.saveRawData = action.payload;
    },
    notifyUser: (state, action) => {
      notifyUserByActionTypeAndCode(
        action.payload.actionType,
        action.payload.deviceId,
        action.payload.error,
      );
    },
    getHistogramDataSuccess: (state, action) => {
      state.histogram = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(monitorActions.monitorPageUnmounted, state => {
      state = INITIAL_STATE;
    });
  },
});
/* eslint-enable no-param-reassign */
const extraActions = {
  deviceNameById: createAction(`${STATE_KEY}/deviceByName`),
  getHistogramData: createAction(`${STATE_KEY}/histogramData`),
};

const getState = state => state[STATE_KEY] || INITIAL_STATE;

export const selectors = {
  getDevice: createSelector(getState, state => state.selectedDevice),
  getSearchFocusStatus: createSelector(getState, state => state.searchFocus),
  getIsStopModalVisible: createSelector(
    getState,
    state => state.stopConfirmModal,
  ),
  getIsSaveRawData: createSelector(getState, state => state.saveRawData),
  selectHistogramData: createSelector(getState, state => state.histogram),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
