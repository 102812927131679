import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { DATA_FETCHING_STATUS, DATA_STATE_KEY } from '../../constants';
import { ResponseInterface } from '../../types';
import { EmrMappingsInitialState } from './types';
import {
  CustomerTenantType,
  FacilityType,
} from 'src/routes/Customers/modules/types';
import { MapFacilitiesPayload } from 'src/services/types';

export const STATE_KEY = 'emrMappings';

export const INITIAL_STATE: EmrMappingsInitialState = {
  customerTenants: { data: null, status: null },
  emrFacilitiesList: { data: null, status: null },
  facilityTenantDict: { data: null, status: null },
  facilityFormStatus: null,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setCustomerTenantsList(
      state,
      action: PayloadAction<ResponseInterface<CustomerTenantType[]>>,
    ) {
      state.customerTenants = {
        ...action.payload,
      };
    },
    setCustomerTenantsStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.customerTenants = {
        ...state.customerTenants,
        status: action.payload,
      };
    },
    setFacilitiesList(
      state,
      action: PayloadAction<ResponseInterface<FacilityType[]>>,
    ) {
      state.emrFacilitiesList = { ...action.payload };
    },
    setFacilitiesStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.emrFacilitiesList = {
        ...state.emrFacilitiesList,
        status: action.payload,
      };
    },
    setFacilityFormStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.facilityFormStatus = action.payload;
    },
  },
});

export const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  isLoadingCustomerTenants: createSelector(
    getState,
    state => state.customerTenants.status === DATA_FETCHING_STATUS.LOADING,
  ),
  isLoadingFacilities: createSelector(
    getState,
    state => state.emrFacilitiesList.status === DATA_FETCHING_STATUS.LOADING,
  ),
  selectCustomerTenants: createSelector(
    getState,
    state => state.customerTenants.data,
  ),
  selectFacilties: createSelector(
    getState,
    state => state.emrFacilitiesList.data,
  ),
};

const extraActions = {
  getFacilities: createAction(`${STATE_KEY}/getFacilities`),
  getCustomerTenants: createAction(`${STATE_KEY}/getCustomerTenants`),
  mapFacilities: createAction<MapFacilitiesPayload>(
    `${STATE_KEY}/mapFacilities`,
  ),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
