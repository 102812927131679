import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/redux/store';

import {
  actions as alertsActions,
  selectors as alertsSelectors,
} from 'src/redux/data/alerts';
import { UUID } from 'src/types/utility';

type OwnProps = {
  children: React.ReactNode;
  patientId: UUID;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  isVacancyAlertActive: alertsSelectors.selectIsBedExitAlertEnabled(
    state,
    ownProps.patientId,
  ),
});

const mapActionCreators = {
  setPatientVacancyAlertEnabled: alertsActions.setPatientBedExitAlertStatus,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type Props = PropsFromRedux & OwnProps;

export default Connector;
