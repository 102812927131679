import { IntlShape, defineMessages } from 'react-intl';

import { appRoutes, RouteValues } from 'src/routes/Root/modules/routesUtils';
import {
  hasGMPermissions,
  hasHPPermissions,
  hasImpersonatedHPPermissions,
  hasSAPermissions,
  hasTAPermissions,
} from 'src/utils/permissions';
import { UserType } from 'src/types/users';
import { USER_TYPES } from 'src/utils/constants';

interface NavigationLink {
  key: string;
  order: number;
  route: RouteValues;
  label: string;
  isHidden: boolean;
  isCurrent: boolean;
}

const messages = defineMessages({
  users: {
    defaultMessage: 'Users',
  },
  customers: {
    defaultMessage: 'Customers',
  },
  tenants: {
    defaultMessage: 'Tenants',
  },
  devices: {
    defaultMessage: 'Devices',
  },
  patients: {
    defaultMessage: 'Monitored Persons',
  },
  groups: {
    defaultMessage: 'Groups',
  },
  versions: {
    defaultMessage: 'SW Versions',
  },
  management: {
    defaultMessage: 'Management',
  },
  nursesStation: {
    defaultMessage: 'Nurses Station',
  },
  settings: {
    defaultMessage: 'Settings',
  },
  dashboard: {
    defaultMessage: 'Dashboard',
  },
});

export const getNavigationLinks = (
  currentRoute: string,
  intl: IntlShape,
  loggedInUserType: UserType | undefined = undefined,
): NavigationLink[] => [
  {
    key: 'users',
    order: 1,
    route: appRoutes.USERS,
    label: intl.formatMessage(messages.users),
    isHidden: !(hasTAPermissions() || hasSAPermissions()),
    isCurrent: currentRoute === appRoutes.USERS,
  },
  {
    key: 'tenants',
    order: 2,
    route: appRoutes.TENANTS,
    label: intl.formatMessage(messages.tenants),
    isHidden: !hasSAPermissions(),
    isCurrent: currentRoute === appRoutes.TENANTS,
  },
  {
    key: 'devices',
    order: 3,
    route: appRoutes.DEVICES,
    label: intl.formatMessage(messages.devices),
    isHidden: !(hasTAPermissions() || hasSAPermissions()),
    isCurrent: currentRoute === appRoutes.DEVICES,
  },
  {
    key: 'patients',
    order: 4,
    route: appRoutes.PATIENTS,
    label: intl.formatMessage(messages.patients),
    // uncomment to add to Tenant Admin navbar
    // isHidden: !hasTAPermissions(),
    isHidden: true,
    isCurrent: currentRoute === appRoutes.PATIENTS,
  },
  {
    key: 'groups',
    order: 6,
    route: appRoutes.GROUPS,
    label: intl.formatMessage(messages.groups),
    isHidden: !hasSAPermissions(),
    isCurrent: currentRoute === appRoutes.GROUPS,
  },
  {
    key: 'versions',
    order: 7,
    route: appRoutes.VERSIONS,
    label: intl.formatMessage(messages.versions),
    isHidden: !hasSAPermissions(),
    isCurrent: currentRoute === appRoutes.VERSIONS,
  },
  {
    key: 'management',
    order: 8,
    route: appRoutes.MANAGEMENT,
    label: intl.formatMessage(messages.management),
    // uncomment to add to Tenant Admin navbar
    // isHidden: !(hasTAPermissions() || hasSAPermissions()),
    isHidden: !hasSAPermissions(),
    isCurrent: currentRoute === appRoutes.MANAGEMENT,
  },
  {
    key: 'nursesStation',
    order: 9,
    route: appRoutes.NURSES_STATION,
    label: intl.formatMessage(messages.nursesStation),
    isHidden: !(hasHPPermissions() || hasImpersonatedHPPermissions()),
    isCurrent: currentRoute === appRoutes.NURSES_STATION,
  },
  {
    key: 'settings',
    order: 10,
    route: appRoutes.SETTINGS,
    label: intl.formatMessage(messages.settings),
    // uncomment to add to Tenant Admin navbar
    // isHidden: !(hasTAPermissions() || hasSAPermissions()),
    isHidden: !hasSAPermissions(),
    isCurrent: currentRoute === appRoutes.SETTINGS,
  },
  {
    key: 'gmDashboard',
    order: 11,
    route: appRoutes.DASHBOARD,
    label: intl.formatMessage(messages.dashboard),
    isHidden: !hasGMPermissions(),
    isCurrent: currentRoute === appRoutes.DASHBOARD,
  },
  {
    key: 'gmSettings',
    order: 12,
    route: appRoutes.GROUP_MANAGER_USERS,
    label: intl.formatMessage(messages.users),
    isHidden:
      !hasGMPermissions() ||
      loggedInUserType?.name === USER_TYPES.MULTI_TENANT_VIEWER ||
      loggedInUserType?.name === USER_TYPES.TENANT_VIEWER,
    isCurrent: currentRoute === appRoutes.GROUP_MANAGER_USERS,
  },
  {
    key: 'customers',
    order: 13,
    route: appRoutes.CUSTOMERS,
    label: intl.formatMessage(messages.customers),
    isHidden: !hasSAPermissions(),
    isCurrent: currentRoute === appRoutes.CUSTOMERS,
  },
];
