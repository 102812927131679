import { actions as versionActions } from 'src/redux/data/version/modules/slice';
import { intl, messages } from '../messages';

export const versionsErrorCodes = {
  VERSION_NOT_FOUND: 'VERSION_NOT_FOUND',
  VERSION_ALREADY_ASSIGNED: 'VERSION_ALREADY_ASSIGNED',
  APPLICATION_VERSION_ALREADY_EXISTS: 'APPLICATION_VERSION_ALREADY_EXISTS',
};

export const versionActionsToErrorNotificationMessagesMap = {
  [versionActions.onUploadVersion.type]: (_, errorCode) => {
    const notificationMessageForErrorCode = {
      [versionsErrorCodes.APPLICATION_VERSION_ALREADY_EXISTS]: {
        message: intl.formatMessage(messages.uploadVersionError),
        description: intl.formatMessage(
          messages.applicationVersionAlreadyExistError,
        ),
      },
    };
    const notificationMessageForErrorCodeHandler = {
      get(mapTarget, error) {
        return (
          mapTarget[error] || {
            message: intl.formatMessage(messages.uploadVersionError),
            description: `${intl.formatMessage(messages.uploadVersionError)}`,
          }
        );
      },
    };
    const proxyNotificationMessageForErrorCode = new Proxy(
      notificationMessageForErrorCode,
      notificationMessageForErrorCodeHandler,
    );
    return proxyNotificationMessageForErrorCode[errorCode];
  },
  [versionActions.onUpdateVersion.type]: (versionName, _) => ({
    message: intl.formatMessage(messages.updateVersionStatusError),
    description: `${intl.formatMessage(
      messages.updateVersionStatusError,
    )} ${versionName}`,
  }),
  [versionActions.deleteVersion.type]: (versionName, errorCode) => {
    const notificationMessageForErrorCode = {
      [versionsErrorCodes.VERSION_NOT_FOUND]: {
        message: intl.formatMessage(messages.deleteVerionError),
        description: `${intl.formatMessage(
          messages.versionNotFound,
        )} ${versionName}`,
      },
      [versionsErrorCodes.VERSION_ALREADY_ASSIGNED]: {
        message: intl.formatMessage(messages.deleteVerionError),
        description: `${intl.formatMessage(
          messages.versionAlreadyAssign,
        )} ${versionName}`,
      },
    };
    const notificationMessageForErrorCodeHandler = {
      get(mapTarget, error) {
        return (
          mapTarget[error] || {
            message: intl.formatMessage(messages.deleteVerionError),
            description: `${intl.formatMessage(
              messages.deleteVerionError,
            )} ${versionName}`,
          }
        );
      },
    };
    const proxyNotificationMessageForErrorCode = new Proxy(
      notificationMessageForErrorCode,
      notificationMessageForErrorCodeHandler,
    );
    return proxyNotificationMessageForErrorCode[errorCode];
  },
};
export const versionActionsToSuccessNotificationMessagesMap = {
  [versionActions.onUpdateVersion.type]: () => ({
    message: intl.formatMessage(messages.updateVersionStatusSuccessMessage),
  }),
  [versionActions.onUploadVersion.type]: () => ({
    message: intl.formatMessage(messages.uploadVersionSuccessMessage),
  }),
};
