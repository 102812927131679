import React from 'react';
import { IntlShape, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import Checkbox from 'src/components/general-ui/Checkbox';
import { SummaryReportWrapper, CustomTimePicker } from './styled';
import { SummaryReportSettings } from '../../types';

type Props = {
  settings: SummaryReportSettings;
  disabled: boolean;
  handleChangeEnabled: (isEnabled: boolean) => void;
  handleChangeTime: (time: string | null) => void;
  intl: IntlShape;
};

const SummaryReport = ({
  settings,
  disabled,
  handleChangeEnabled,
  handleChangeTime,
  intl,
}: Props): JSX.Element => {
  const { isEnabled, timeString } = settings;

  return (
    <SummaryReportWrapper>
      <Checkbox
        label={intl.formatMessage(messages.sendSummaryReport)}
        checked={isEnabled}
        handleChange={handleChangeEnabled}
        disabled={disabled}
      />
      <CustomTimePicker
        onChange={newTimeString => {
          handleChangeTime(
            newTimeString && moment(newTimeString, 'HH:mm').format('hh:mm A'),
          );
        }}
        value={
          timeString ? moment(timeString, 'hh:mm A').format('HH:mm') : null
        }
        disableClock
        hourPlaceholder="hh"
        minutePlaceholder="mm"
        format="hh:mm a"
        disabled={disabled || !isEnabled}
      />
    </SummaryReportWrapper>
  );
};

const messages = defineMessages({
  sendSummaryReport: {
    defaultMessage: 'Send a summary report once a day at',
  },
});

export default injectIntl(React.memo(SummaryReport));
