import { createSlice, createSelector, createAction } from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { DATA_STATE_KEY } from '../../constants';

export const STATE_KEY = 'app';

export const INITIAL_STATE = {
  isAppLoading: true,
  isFocused: true,
  isConnectedToInternet: true,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    appStartFinish: state => {
      state.isAppLoading = false;
      return state;
    },
    onAppFocused: state => {
      state.isFocused = true;
      return state;
    },
    onAppUnfocused: state => {
      state.isFocused = false;
      return state;
    },
    onInternetConnection: state => {
      state.isConnectedToInternet = true;
      return state;
    },
    onInternetDisconnection: state => {
      state.isConnectedToInternet = false;
      return state;
    },
  },
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  getIsAppLoading: createSelector(getState, state => state.isAppLoading),
  getIsFocused: createSelector(getState, state => state.isFocused),
  getIsConnectedToInternet: createSelector(
    getState,
    state => state.isConnectedToInternet,
  ),
};

// For saga/reducer-less actions
const extraActions = {
  appStart: createAction(`${STATE_KEY}/appStart`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
