import { actions as monitorActions } from 'src/redux/data/monitor/modules/slice';

import { intl, messages } from '../messages';

export const monitorActionToErrorNotificationMessagesMap = {
  [monitorActions.onClickStartContinuous.type]: (deviceId, _) => ({
    message: intl.formatMessage(messages.startFailedMessage),
    description: `${intl.formatMessage(
      messages.startFailedDescription,
    )} ${deviceId}`,
  }),
  [monitorActions.onClickStopContinuous.type]: (deviceId, _) => ({
    message: intl.formatMessage(messages.stopFailedMessage),
    description: `${intl.formatMessage(
      messages.stopFailedDescription,
    )} ${deviceId}`,
  }),
  [monitorActions.onHriResult.type]: (deviceId, _) => ({
    message: intl.formatMessage(messages.hriResultFailedMessage),
    description: `${intl.formatMessage(
      messages.hriResultFailedDescription,
    )} ${deviceId}`,
  }),
};

export const monitorActionToWarningNotificationMessagesMap = {
  [monitorActions.onReceivedDataFromInactiveSession.type]: () => ({
    message: intl.formatMessage(
      messages.sessionInactiveAndReceivingDataMessage,
    ),
    description: `${intl.formatMessage(
      messages.sessionInactiveAndReceivingDataDescription,
    )}`,
  }),
};

export const monitorActionToSuccessNotificationMessagesMap = {};
