import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { FetchMeasurementSettingsResponse } from 'src/services/types';
import { UUID } from 'src/types/utility';
import { DATA_STATE_KEY, DATA_FETCHING_STATUS } from '../../constants';

export const STATE_KEY = 'measurementSettings';

export const INITIAL_STATE: {
  settingsId: UUID | null;
  shouldDisplayHighQualityOnly: boolean;
  settingsStatus: keyof typeof DATA_FETCHING_STATUS;
} = {
  settingsId: null,
  shouldDisplayHighQualityOnly: false,
  settingsStatus: DATA_FETCHING_STATUS.LOADING,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    onGetMeasurementSettingsSuccess: (
      state,
      action: PayloadAction<FetchMeasurementSettingsResponse>,
    ) => {
      const { id, highQuality } = action.payload;

      state.settingsId = id;
      state.shouldDisplayHighQualityOnly = highQuality;
      state.settingsStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    onGetMeasurementSettingsFailed: state => {
      state.shouldDisplayHighQualityOnly = false;
      state.settingsStatus = DATA_FETCHING_STATUS.ERROR;
    },
    onUpdateMeasurementSettings: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{ highQuality: boolean }>,
    ) => {
      state.settingsStatus = DATA_FETCHING_STATUS.LOADING;
    },
    onGetMeasurementSettings: state => {
      state.settingsStatus = DATA_FETCHING_STATUS.LOADING;
    },
  },
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  getSettingsId: createSelector(getState, state => state.settingsId),
  getShouldDisplayHighQualityOnly: createSelector(
    getState,
    state => state.shouldDisplayHighQualityOnly,
  ),
  getSettingsStatus: createSelector(getState, state => state.settingsStatus),
};

const extraActions = {};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
