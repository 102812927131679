import { connect, ConnectedProps } from 'react-redux';

import { actions } from 'src/routes/IndividualDashboard/modules/dashboard';

const mapStateToProps = () => ({});

const mapActionCreators = {
  onLoadIndividualDashboard: actions.onLoadIndividualDashboard,
  individualDashboardUnmounted: actions.individualDashboardUnmounted,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
