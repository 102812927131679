/**
 * App.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
// import FontFaceObserver from 'fontfaceobserver';
import history from 'src/utils/history';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import { tokenStatusEnum } from 'src/utils/constants';

import UmsLogic from 'src/libs/ums-js-logic';

import 'sanitize.css/sanitize.css';

import AppConfig from 'src/config/AppConfig';
import Schema from 'async-validator';
import AppComponent from './routes/Root';
import store from './redux';
import './app.css';

// Import Language Provider
import { translationMessages } from './i18n';

// eslint-disable-next-line @typescript-eslint/no-empty-function
Schema.warning = () => {}; // to disable antd validation warnings in console

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
// const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
// openSansObserver.load().then(() => {
//   document.body.classList.add('fontLoaded');
// });

UmsLogic.init({
  umsBaseURL: `${AppConfig.API_URL || ''}/ums`,
  onRefreshTokenFail: (e: unknown) => {
    console.log('refreshTokenFailed', e);
    notifyUserByActionTypeAndCode(tokenStatusEnum.TOKEN_NOT_VALID, null, null);
  },
  localStorage,
  sessionStorage,
  timeout: undefined,
});

const MOUNT_NODE = document.getElementById('root');

const render = () => {
  ReactDOM.render(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppComponent />
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE,
  );
};

/*
HMR DISABLE
if (module.hot) {
	// Hot reloadable React components and translation json files
	// modules.hot.accept does not accept dynamic dependencies,
	// have to be constants at compile-time
	module.hot.accept(['./i18n', 'containers/App'], () => {
		ReactDOM.unmountComponentAtNode(MOUNT_NODE);
		render(translationMessages);
	});
}
*/

// Chunked polyfill for browsers without Intl support
// TODO: Check if this is actually needed.
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        // TODO: Remove and use intl-ts https://www.npmjs.com/package/intl-ts
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        import('intl/locale-data/jsonp/en'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        import('intl/locale-data/jsonp/de'),
      ]),
    )
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}

export default render;
