import { defineMessages } from 'react-intl';

export const patientHistoryMessages = defineMessages({
  // error
  spotHistoryError: {
    defaultMessage: 'Could not get spot history for patient',
  },
  downloadHriError: {
    defaultMessage: 'Could not download HRI file',
  },
  downloadHriDescription: {
    defaultMessage: 'Could not get HRI data',
  },

  // success
});
