import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'styled-components';

import { SETTINGS_TYPE } from 'src/routes/GMDashboard/modules/constants';
import SubtenantAlertSettingsModal from './SubtenantAlertSettingsModal';
import Connector, { Props } from './Connector';
import {
  StyledCollectiveSettingsIcon,
  StyledIndividualSettingsIcon,
} from './styled';
import { ToolTipMessages } from 'src/translations/messages/toolTipMessages';
import { IconType } from 'src/components/general-ui/Icon';

type IconProps = {
  type: IconType;
  color: string;
  onClick: (modalState: boolean) => void;
};

const IndividualSubtenantSettings = ({
  subtenantId,
  subtenantSettingsType,
}: Props): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const theme = useTheme();

  const props: IconProps = {
    type:
      subtenantSettingsType === SETTINGS_TYPE.UNIQUE
        ? 'notification-settings-icon'
        : 'settings-icon',
    //@ts-ignore TODO: fix typed themeing
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    color: theme.colors.grey7,
    onClick: () => setIsModalVisible(true),
  };

  const RenderSettingsIcon =
    subtenantSettingsType === SETTINGS_TYPE.UNIQUE
      ? StyledIndividualSettingsIcon
      : StyledCollectiveSettingsIcon;

  return (
    <>
      <Tooltip
        title={
          <FormattedMessage {...ToolTipMessages.MTMalertSettingsTenantCard} />
        }
      >
        {/* @ts-ignore TO FIX: Icon doesn't recognize props */}
        <RenderSettingsIcon {...props} />
      </Tooltip>
      {isModalVisible && (
        // @ts-ignore need to investigate this
        <SubtenantAlertSettingsModal
          subtenantId={subtenantId}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </>
  );
};

export default Connector(React.memo(IndividualSubtenantSettings));
