export const EmrDataParamaters = [
  {
    label: 'Facilites List',
    value: 'facilityUpdateFreq',
  },
  {
    label: 'Floors List',
    value: 'floorsUpdateFreq',
  },
  {
    label: 'Units List',
    value: 'unitsUpdateFreq',
  },
  {
    label: 'Rooms List',
    value: 'roomsUpdateFreq',
  },
  {
    label: 'Beds List',
    value: 'bedsUpdateFreq',
  },
  {
    label: 'Patients List',
    value: 'patientsUpdateFreq',
  },
  {
    label: 'ADT Status',
    value: 'adtUpdateFreq',
  },
  {
    label: 'Medical Observations',
    value: 'observationsPushFreq',
  },
  {
    label: 'Additional Data',
    value: 'additionalDataReadFreq',
  },
];

export const WeekDays = [
  { label: 'Monday', value: 'Mon' },
  { label: 'Tuesday', value: 'Tue' },
  { label: 'Wednesday', value: 'Wed' },
  { label: 'Thursday', value: 'Thu' },
  { label: 'Friday', value: 'Fri' },
  { label: 'Saturday', value: 'Sat' },
  { label: 'Sunday', value: 'Sun' },
];

export const YearMonths = [
  { label: 'January', value: 'Jan' },
  { label: 'February', value: 'Feb' },
  { label: 'March', value: 'Mar' },
  { label: 'April', value: 'Apr' },
  { label: 'May', value: 'May' },
  { label: 'June', value: 'Jun' },
  { label: 'July', value: 'Jul' },
  { label: 'August', value: 'Aug' },
  { label: 'September', value: 'Sep' },
  { label: 'October', value: 'Oct' },
  { label: 'November', value: 'Nov' },
  { label: 'December', value: 'Dec' },
];

export const TimePeriod = ['Day', 'Week', 'Month'];

export const TimePeriods = {
  HOUR: 'Hour',
  HOURS: 'Hours',
  DAY: 'Day',
  DAYS: 'Days',
  WEEK: 'Week',
  WEEKS: 'Weeks',
  MONTH: 'Month',
  MONTHS: 'Months',
  YEAR: 'Year',
  YEARS: 'Years,',
};
