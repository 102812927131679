import React from 'react';
import { Form, Input } from 'antd';
import { defineMessages, FormattedMessage, IntlShape } from 'react-intl';
import { apiStatusEnum, emailPattern } from 'src/utils/constants';
import {
  AuthButton,
  ErrorText,
  FormItem,
  MessageArea,
} from '../components/styled';

import { PropsFromRedux } from './Connector';

type Props = Pick<
  PropsFromRedux,
  'login' | 'loginStatus' | 'isConsentApiFailed'
> & {
  intl: IntlShape;
};

const LoginForm = ({
  login,
  intl,
  loginStatus,
  isConsentApiFailed,
}: Props): JSX.Element => {
  const onLoginFinish = (values: { email: string; password: string }) => {
    login(values);
  };

  const isLoading = !!loginStatus && loginStatus === apiStatusEnum.LOADING;
  const isOk = !!loginStatus && loginStatus === apiStatusEnum.OK;
  return (
    <Form layout="vertical" onFinish={onLoginFinish}>
      <FormItem
        data-cy="login-email-field"
        label={intl.formatMessage(messages.email)}
        name="email"
        hasFeedback
        rules={[
          {
            pattern: emailPattern,
            required: true,
            message: `${intl.formatMessage(messages.invalidEmail)}`,
          },
        ]}
      >
        <Input />
      </FormItem>

      <FormItem
        data-cy="login-password-field"
        label={intl.formatMessage(messages.password)}
        name="password"
        hasFeedback
        rules={[
          {
            required: true,
            message: `${intl.formatMessage(messages.requiredPassword)}`,
          },
        ]}
      >
        <Input.Password />
      </FormItem>
      {/* <Form.Item name="rememberMe" valuePropName="checked">
          <Checkbox>
            <FormattedMessage {...messages.rememberMe} />
          </Checkbox>
        </Form.Item> */}

      <Form.Item data-cy="login-submit-btn">
        <AuthButton isLoading={isLoading}>
          <FormattedMessage {...messages.submit} />
        </AuthButton>
      </Form.Item>
      <MessageArea>
        {loginStatus && !isOk && !isLoading && !isConsentApiFailed && (
          <ErrorText data-cy="login-error-text">
            <FormattedMessage {...messages.errorText} />
          </ErrorText>
        )}

        {isConsentApiFailed && (
          <ErrorText>
            <FormattedMessage {...messages.consentError} />
          </ErrorText>
        )}
      </MessageArea>
    </Form>
  );
};

const messages = defineMessages({
  email: {
    defaultMessage: 'Email',
  },
  password: {
    defaultMessage: 'Password',
  },
  errorText: {
    defaultMessage: 'Could not login - wrong email or password',
  },
  invalidEmail: {
    defaultMessage: 'Invalid email address',
  },
  requiredPassword: {
    defaultMessage: 'Password is required',
  },
  rememberMe: {
    defaultMessage: 'Remember me',
  },
  forgotPassword: {
    defaultMessage: 'Forgot your password?',
  },
  submit: {
    defaultMessage: 'Login',
  },
  consentError: {
    defaultMessage: 'unable to load consents, please login again ',
  },
});

export default LoginForm;
