import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import lineLegendIcon from 'src/routes/IndividualDashboard/resources/metric-histogram-legend-line.svg';
import barLegendIcon from 'src/routes/IndividualDashboard/resources/metric-histogram-legend-bar.svg';
import {
  DisplayHistogramGraphData,
  HR_HISTOGRAM_TRESHOLDS,
  RR_HISTOGRAM_TRESHOLDS,
} from '../../../modules/dashboard/histogram-processor';
import {
  HrRrHistogramWrapper,
  GraphTitle,
  HistogramGraphTitle,
  StyledRow,
  StyledColumn,
  LegendWrapper,
  ContentWrapper,
  LegendItem,
} from './styled';
import MetricHistogramGraph from './MetricHistogramGraph';
import Connector, { PropsFromRedux } from './Connector';

type Props = PropsFromRedux;

const HrRrHistogram = ({
  histogramData,
  patient,
  getHistogramData,
  clearHistogramData,
  isHistogramDataLoading,
}: Props): JSX.Element => {
  const patientId = patient?.id;

  useEffect(() => {
    if (patientId) {
      getHistogramData(patientId);
    }
    return () => {
      clearHistogramData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  const extractHRPercentrageLastDayHistogramData =
    (): DisplayHistogramGraphData[] =>
      HR_HISTOGRAM_TRESHOLDS.map(t => ({
        id: t.label,
        value: histogramData?.HR?.lastDay.histogram.percentage[t.label],
      }));
  const extractHRPercentrageLastWeekHistogramData =
    (): DisplayHistogramGraphData[] =>
      HR_HISTOGRAM_TRESHOLDS.map(t => ({
        id: t.label,
        value: histogramData?.HR?.lastWeek.histogram.percentage[t.label],
      }));
  const extractRRPercentrageLastDayHistogramData =
    (): DisplayHistogramGraphData[] =>
      RR_HISTOGRAM_TRESHOLDS.map(t => ({
        id: t.label,
        value: histogramData?.RR?.lastDay.histogram.percentage[t.label],
      }));
  const extractRRPercentrageLastWeekHistogramData =
    (): DisplayHistogramGraphData[] =>
      RR_HISTOGRAM_TRESHOLDS.map(t => ({
        id: t.label,
        value: histogramData?.RR?.lastWeek.histogram.percentage[t.label],
      }));

  return (
    <HrRrHistogramWrapper
      data-cy={`HR-RR-histogram-loading=${isHistogramDataLoading.toString()}`}
    >
      <GraphTitle>
        <FormattedMessage {...messages.histogram} />
      </GraphTitle>
      <ContentWrapper>
        <StyledRow>
          <StyledColumn>
            <HistogramGraphTitle>
              <FormattedMessage {...messages.heartRate} />
            </HistogramGraphTitle>
            <MetricHistogramGraph
              lastDayData={extractHRPercentrageLastDayHistogramData()}
              lastWeekData={extractHRPercentrageLastWeekHistogramData()}
            />
          </StyledColumn>
          <StyledColumn>
            <HistogramGraphTitle>
              <FormattedMessage {...messages.respirationRate} />
            </HistogramGraphTitle>
            <MetricHistogramGraph
              lastDayData={extractRRPercentrageLastDayHistogramData()}
              lastWeekData={extractRRPercentrageLastWeekHistogramData()}
            />
          </StyledColumn>
        </StyledRow>
        <LegendWrapper>
          <img src={lineLegendIcon} />
          <LegendItem>
            <FormattedMessage {...messages.last7days} />
          </LegendItem>
          <img src={barLegendIcon} />
          <LegendItem>
            <FormattedMessage {...messages.last24hours} />
          </LegendItem>
        </LegendWrapper>
      </ContentWrapper>
    </HrRrHistogramWrapper>
  );
};

const messages = defineMessages({
  histogram: {
    defaultMessage: 'Histogram',
  },
  heartRate: {
    defaultMessage: 'Heart Rate',
  },
  respirationRate: {
    defaultMessage: 'Respiration Rate',
  },
  last24hours: {
    defaultMessage: 'Last 24 hours',
  },
  last7days: {
    defaultMessage: 'Last 7 days',
  },
});

export default Connector(HrRrHistogram);
