import React from 'react';

import { SVGProps } from '../types';

const SuppressAlertIcon = ({
  width = '32',
  height = '32',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <g>
      <path
        d="M27.568,20.168V13.63c-0.021-5.043-3.48-9.862-8.654-11.088c0,0-0.17-2.542-2.711-2.542c-2.58,0-2.709,2.47-2.709,2.47
      C8.322,3.69,4.906,8.587,4.876,13.63v6.33c0,3.094-2.033,3.626-4.443,6.146h31.543C29.566,23.586,27.568,23.263,27.568,20.168z
       M16.203,32.409c2.771,0,5.088-2.212,5.713-5.043H10.492C11.117,30.197,13.435,32.409,16.203,32.409z"
      />
    </g>
  </svg>
);

export default SuppressAlertIcon;
