import { Dayjs } from 'dayjs';

const SECONDS_IN_A_DAY = 86400;
const ONE_SECOND = 1;
const ONE_MINUTE_IN_SECONDS = 60;
const ONE_HOUR_IN_SECONDS = 3600;
const ONE_DAY_IN_SECONDS = SECONDS_IN_A_DAY;
const THREE_DAYS_IN_SECONDS = SECONDS_IN_A_DAY * 3;
const SEVEN_DAYS_IN_SECONDS = SECONDS_IN_A_DAY * 7;
const ONE_MONTH_IN_SECONDS = SECONDS_IN_A_DAY * 31;
const THREE_MONTHS_IN_SECONDS = SECONDS_IN_A_DAY * 31 * 3;

export const SECOND_RESOLUTION = 'second';
export const MINUTE_RESOLUTION = 'minute';
export const HOUR_RESOLUTION = 'hour';
export const THREE_HOUR_RESOLUTION = '3hours';
export const SIX_HOUR_RESOLUTION = '6hours';
export const DAY_RESOLUTION = 'day';
export const WEEK_RESOLUTION = 'week';
export const MONTH_RESOLUTION = 'month';

const getResolution = (intervalInSeconds: number) => {
  if (!intervalInSeconds) {
    return MONTH_RESOLUTION;
  }

  if (intervalInSeconds <= ONE_SECOND) {
    return SECOND_RESOLUTION;
  }

  if (intervalInSeconds <= ONE_MINUTE_IN_SECONDS) {
    return SECOND_RESOLUTION;
  }

  if (intervalInSeconds <= ONE_HOUR_IN_SECONDS) {
    return MINUTE_RESOLUTION;
  }

  if (intervalInSeconds <= ONE_DAY_IN_SECONDS) {
    return HOUR_RESOLUTION;
  }

  if (intervalInSeconds <= THREE_DAYS_IN_SECONDS) {
    return THREE_HOUR_RESOLUTION;
  }

  if (intervalInSeconds <= SEVEN_DAYS_IN_SECONDS) {
    return SIX_HOUR_RESOLUTION;
  }

  if (intervalInSeconds <= ONE_MONTH_IN_SECONDS) {
    return DAY_RESOLUTION;
  }

  if (intervalInSeconds <= THREE_MONTHS_IN_SECONDS) {
    return WEEK_RESOLUTION;
  }

  return MONTH_RESOLUTION;
};

export const getResolutionFromDateInterval = (
  startTime: Dayjs,
  endTime: Dayjs,
) => {
  const intervalInSeconds = endTime.diff(startTime, 'second');

  return getResolution(intervalInSeconds);
};

export const getTimeAxisResolution = (startTime: Dayjs, endTime: Dayjs) => {
  const resolution = getResolutionFromDateInterval(startTime, endTime);

  if (resolution === THREE_HOUR_RESOLUTION) {
    return {
      stepSize: 3,
      unit: HOUR_RESOLUTION,
    };
  }

  if (resolution === SIX_HOUR_RESOLUTION) {
    return {
      stepSize: 6,
      unit: HOUR_RESOLUTION,
    };
  }

  return {
    stepSize: 1,
    unit: resolution,
  };
};

const formatToHourResolution = (date: Dayjs, hourResolution: number) => {
  const hour = date.hour();
  const quotient = Math.floor(hour / hourResolution);
  const newHour = quotient * hourResolution;

  return date.hour(newHour);
};

// TODO: Change when timezone is involved?
export const formatIntervalWithResolution = (
  startTime: Dayjs,
  endTime: Dayjs,
) => {
  const resolution = getResolutionFromDateInterval(startTime, endTime);

  if (resolution === THREE_HOUR_RESOLUTION) {
    return {
      startTime: formatToHourResolution(startTime.startOf(HOUR_RESOLUTION), 3),
      endTime: formatToHourResolution(endTime.startOf(HOUR_RESOLUTION), 3),
    };
  }

  if (resolution === SIX_HOUR_RESOLUTION) {
    return {
      startTime: formatToHourResolution(startTime.startOf(HOUR_RESOLUTION), 6),
      endTime: formatToHourResolution(endTime.startOf(HOUR_RESOLUTION), 6),
    };
  }

  return {
    startTime: startTime.startOf(resolution),
    endTime: endTime.startOf(resolution),
  };
};

// TODO: Change when timezone is involved?
export const isHourAtCorrectResolution = (
  date: Dayjs,
  hourResolution: number,
) => {
  const hour = date.hour();

  return hour % hourResolution === 0;
};
