import React from 'react';
import { TimePicker as AntTimePicker } from 'antd';
import { Dayjs } from 'dayjs';

import { StatisticsDateUnitTypes } from 'src/routes/IndividualDashboard/modules/statistics/types';
import { TIME_CALC_OPTIONS } from './constants';
import { DatePickerWithArrows, LeftArrow, RightArrow } from './styled';
import { _dateTypeOfBottomDatePicker, proxyFormatMap, range } from './utils';
import moment from 'moment';

type Props = {
  momentDate: moment.Moment;
  dateType: StatisticsDateUnitTypes;
  selectedDate: Dayjs;
  onArrowClicked: (
    arrowType: keyof typeof TIME_CALC_OPTIONS,
    dateType: StatisticsDateUnitTypes | null,
  ) => void;
  onChangeDate: (newDate: Dayjs) => void;
  isDateInfuture: (
    dateType: StatisticsDateUnitTypes | null,
  ) => boolean | undefined;
};

const TimePicker = ({
  momentDate,
  dateType,
  onArrowClicked,
  onChangeDate,
  isDateInfuture,
  selectedDate,
}: Props): JSX.Element => {
  const disabledFieldsProps = {
    disabledMinutes: () => range(1, 60),
    disabledHours: () =>
      selectedDate.day() === moment().day()
        ? range(moment().hour() + 1, 24)
        : [],
  };

  return (
    <DatePickerWithArrows>
      <LeftArrow
        onClick={() =>
          onArrowClicked(
            TIME_CALC_OPTIONS.SUB,
            _dateTypeOfBottomDatePicker(dateType),
          )
        }
      />
      <AntTimePicker
        onChange={onChangeDate}
        // @ts-ignore Fix this on refactor
        value={momentDate}
        // @ts-ignore Fix this on refactor
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        format={proxyFormatMap[dateType]}
        onOk={onChangeDate}
        allowClear={false}
        showNow={false} // Hidden because it is no longer relevant to show data related to local timezone
        {...disabledFieldsProps}
      />
      <RightArrow
        disabled={isDateInfuture(_dateTypeOfBottomDatePicker(dateType))}
        onClick={() =>
          onArrowClicked(
            TIME_CALC_OPTIONS.ADD,
            _dateTypeOfBottomDatePicker(dateType),
          )
        }
      />
    </DatePickerWithArrows>
  );
};

export default TimePicker;
