import Component from './Component';

import { connect } from 'react-redux';

import {
  actions as individualDashboardActions,
  selectors as individualDashboardSelectors,
} from 'src/routes/IndividualDashboard/modules/dashboard/slice';
import { apiStatusEnum } from 'src/utils/constants';

const mapStateToProps = state => ({
  hoursInBedData: individualDashboardSelectors.getHoursInBedData(state),
  numberOfBedExitAlertsData:
    individualDashboardSelectors.getNumberOfBedExitAlertsData(state),
  patient: individualDashboardSelectors.getPatientDetails(state),
  isLoading:
    individualDashboardSelectors.selectIsBedOccupancyDataLoading(state) ===
    apiStatusEnum.LOADING,
});

const mapActionCreators = {
  getBedOccupancyData: individualDashboardActions.getBedOccupancyData,
  clearBedOccupancyGraph: individualDashboardActions.clearBedOccupancyGraph,
};

export default connect(mapStateToProps, mapActionCreators)(Component);
