import React, { useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { SerialNumber } from 'src/types/utility';
import { deepEqual } from 'src/utils/comparators';
import { StyledFooter, StyledTextBtn } from '../styled';
import { useDetectClickOutside } from '../hooks';
import Connector, { PropsFromRedux } from './Connector';
import LocationForm from './LocationForm';
import { LocationTooltip, StyledAssignBtn } from './styled';
import { FormBed, FormRoom } from './types';
import {
  BedLocationItem,
  RoomLocationItem,
  StyledDeviceLocation,
} from 'src/routes/NursesStation/components/RoomsAndBeds/DeviceCard/styled';

type AssignBtnProps = {
  onClick: () => void;
};

type Props = PropsFromRedux & {
  deviceId: SerialNumber;
  initialRoomName?: string;
  initialBedName?: string;
  CustomAssignBtn?: React.FC<AssignBtnProps>;
};

const LocationAssigner = ({
  deviceId,
  rooms,
  initialRoomName,
  initialBedName,
  CustomAssignBtn,
}: Props): JSX.Element => {
  const initialRoom = rooms.find(room => room.label === initialRoomName);
  const initialBed = (initialRoom?.beds || []).find(
    bed => bed.value === initialBedName,
  );
  const [isVisible, setIsVisible] = useState(false);
  const [selectedRoom, setRoom] = useState<FormRoom | undefined>(initialRoom);
  const [selectedBed, setBed] = useState<FormBed | undefined>(initialBed);
  const ref = useRef<HTMLDivElement>(null);
  useDetectClickOutside(ref, isVisible, () => setIsVisible(false));

  useEffect(() => {
    if (!selectedRoom && initialRoom) {
      setRoom(initialRoom);
    }

    if (!selectedBed && initialBed) {
      setBed(initialBed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialRoomName, initialBedName]);

  const renderTooltipContent = () => (
    <div ref={ref}>
      <LocationForm
        deviceId={deviceId}
        selectedRoom={selectedRoom}
        selectedBed={selectedBed}
      />
      <StyledFooter>
        <StyledTextBtn onClick={() => setIsVisible(false)}>
          <FormattedMessage {...messages.cancel} />
        </StyledTextBtn>
      </StyledFooter>
    </div>
  );

  return (
    <LocationTooltip
      title={renderTooltipContent()}
      placement="bottom"
      open={isVisible}
      destroyTooltipOnHide={true}
    >
      {CustomAssignBtn ? (
        <CustomAssignBtn onClick={() => setIsVisible(true)} />
      ) : (
        <StyledAssignBtn onClick={() => setIsVisible(true)}>
          <StyledDeviceLocation>
            <RoomLocationItem>
              <FormattedMessage {...messages.assign} />
            </RoomLocationItem>
            <BedLocationItem> &nbsp;</BedLocationItem>
          </StyledDeviceLocation>
        </StyledAssignBtn>
      )}
    </LocationTooltip>
  );
};

const messages = defineMessages({
  assign: {
    defaultMessage: 'No Device Location',
  },
  cancel: {
    defaultMessage: 'Close',
  },
});

const Memoized = React.memo(LocationAssigner, (oldProps, newProps) => {
  const isEqual = deepEqual(
    {
      deviceId: oldProps.deviceId,
      initialBedName: oldProps.initialBedName,
      initialRoomName: oldProps.initialRoomName,
      rooms: oldProps.rooms,
    },
    {
      deviceId: newProps.deviceId,
      initialBedName: newProps.initialBedName,
      initialRoomName: newProps.initialRoomName,
      rooms: newProps.rooms,
    },
  );

  return isEqual;
});

export default Connector(Memoized);
