import moment from 'moment';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { dateTimeFormat } from 'src/utils/graphsUtils/graphConstants';

dayjs.extend(utc);

export const convertToDateTimeFormat = date =>
  date ? moment(date).format(dateTimeFormat) : '';

export const convertLocaleDateTimeToUtc = date => dayjs(date)?.utc().format();

export const convertUtcToLocaleDateTime = date =>
  dayjs.utc(date).local().format(dateTimeFormat);
