import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import theme from 'src/themes/theme';

const getIcon = customStyling => (
  <LoadingOutlined
    style={{
      fontSize: 14,
      color: theme.colors.spinnerColor,
      ...customStyling,
    }}
    spin
  />
);

const Spinner = ({ customStyling }) => (
  <Spin indicator={getIcon(customStyling)} />
);

Spinner.propTypes = {
  customStyling: PropTypes.object,
};

Spinner.defaultProps = {
  customStyling: {},
};

export default Spinner;
