import { defineMessages, MessageDescriptor } from 'react-intl';
import ConnectivityAlertsSettings from './ConnectivityAlertsSettings/index';
import SubtenantCombinedSettings from './SubtenantCombinedSettings/index';
import ActivityAlerts from 'src/components/AlertSettingsComponents/ActivityAlerts/Container';
import permissions from 'src/permissions';

const ALERT_SETTINGS_TAB_KEYS = Object.freeze({
  MEDICAL_ALERTS: 'MedicalAlerts',
  ACTIVITY_ALERTS: 'ActivityAlerts',
  CONNECTIVITY_ALERTS: 'ConnectivityAlerts',
});

type KEYS = keyof typeof ALERT_SETTINGS_TAB_KEYS;

type AlertSettingsTabType = {
  key: (typeof ALERT_SETTINGS_TAB_KEYS)[KEYS];
  labelMessage: MessageDescriptor;
  component: React.ElementType;
  editablePermissions?: string[];
};

const messages = defineMessages({
  medicalAlerts: {
    defaultMessage: 'Medical Alerts',
  },
  activityAlerts: {
    defaultMessage: 'Bed Activity Alerts',
  },
  connectivityAlerts: {
    defaultMessage: 'Connectivity Alerts',
  },
  allDisconnectedDevices: {
    defaultMessage: 'All Devices',
  },
  assignedDisconnectedDevices: {
    defaultMessage: 'Patient Assigned Only',
  },
});
export const SUBTENANT_ALERT_SETTINGS_TABS: AlertSettingsTabType[] = [
  {
    key: ALERT_SETTINGS_TAB_KEYS.MEDICAL_ALERTS,
    labelMessage: messages.medicalAlerts,
    component: SubtenantCombinedSettings,
  },
  {
    key: ALERT_SETTINGS_TAB_KEYS.ACTIVITY_ALERTS,
    labelMessage: messages.activityAlerts,
    component: ActivityAlerts,
    editablePermissions: [
      permissions.ACTIVITY_ALERT_SETTINGS_CREATE_UPDATE_MTM,
    ],
  },
  {
    key: ALERT_SETTINGS_TAB_KEYS.CONNECTIVITY_ALERTS,
    labelMessage: messages.connectivityAlerts,
    component: ConnectivityAlertsSettings,
  },
];
