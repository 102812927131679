import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Dayjs } from 'dayjs';

import { nowLocal } from 'src/utils/timeUtils';
import { UUID } from 'src/types/utility';
import AlertLogBox from '../AlertLog';
import {
  MainGraphWrapper,
  MainGraphTitle,
  MainGraphHeader,
  BoxesWrapper,
} from './styled';
import DataPointsBox from './DataPointsBox';
import TimeIntervalSelector, {
  INITIAL_TIME_INTERVAL,
} from '../TimeIntervalSelector';
import Graph from '../Graph';
import Connector, { PropsFromRedux } from './Connector';

export const computeInitialInterval = (): [Dayjs, Dayjs] => {
  const { value, unit } = INITIAL_TIME_INTERVAL;
  const endDate = nowLocal();
  const startDate = endDate.subtract(value, unit);

  return [startDate, endDate];
};

const MainGraph = ({
  patient,
  dataPointsCount,
  getPatientMetricData,
  getAlertsLogData,
  getThresholdsList,
}: PropsFromRedux): JSX.Element => {
  const patientId = patient?.id;
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  useEffect(() => {
    getThresholdsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const [startTime, endTime] = computeInitialInterval();
    if (patientId) {
      getPatientMetricData({
        patientId,
        startTime,
        endTime,
      });
      setStartDate(startTime);
      setEndDate(endTime);
      getAlertsLogData({
        patientId,
        startDate: startTime,
        endDate: endTime,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  const handleDateIntervalChange = (
    patientId: UUID,
    startDate: Dayjs,
    endDate: Dayjs,
  ) => {
    setStartDate(startDate);
    setEndDate(endDate);
    getPatientMetricData({
      patientId,
      startTime: startDate,
      endTime: endDate,
    });
    getAlertsLogData({
      patientId,
      startDate,
      endDate,
    });
  };

  return (
    <MainGraphWrapper>
      <MainGraphTitle>
        <FormattedMessage {...messages.title} />
      </MainGraphTitle>
      <MainGraphHeader>
        <BoxesWrapper>
          <AlertLogBox startDate={startDate} endDate={endDate} />
          <DataPointsBox dataPointsCount={dataPointsCount} />
        </BoxesWrapper>
        <TimeIntervalSelector
          handleChange={(startDate, endDate) =>
            handleDateIntervalChange(patientId || '', startDate, endDate)
          }
        />
      </MainGraphHeader>
      <Graph patientId={patientId || ''} />
    </MainGraphWrapper>
  );
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Periodic Overview',
  },
});

export default Connector(MainGraph);
