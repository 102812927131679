import React from 'react';
import { IntlShape, defineMessages, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { VS_METRICS } from 'src/utils/constants';
import { selectors } from 'src/redux/data/monitor/modules/slice';
import { measurementUnits } from 'src/routes/Monitor/modules/constants';
import HRlogo from '../../../resources/HRlogo.svg';
import respirationLogo from '../../../resources/respirationLogo.svg';
import MeasurementCard from './MeasurementCard';
import Connector, { PropsFromRedux, SpotDataValue } from './Connector';
import { Wrapper } from './styled';
import { RootState } from 'src/redux/store';
import { MeasurementType } from 'src/redux/data/monitor/modules/types';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';

const getSpotMeasurement = (
  vsKey: keyof SpotDataValue[string],
  spotData: SpotDataValue,
) => {
  const data = Object.values(spotData);

  if (!data || !data[0]) {
    return null;
  }

  return data[0][vsKey];
};

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const ContinuousMeasurements = ({
  deviceId,
  shouldDisplayHighQualityOnly,
  spotData,
  intl,
}: Props): JSX.Element => {
  const measurements = [
    {
      measurementType: ALERT_METRIC_ENUM.HR,
      title: intl.formatMessage(messages.hr),
      unit: measurementUnits.BPM,
      logoSrc: HRlogo,
      quality: useSelector((state: RootState) =>
        selectors.getCurrentHrQuality(state, deviceId),
      ),
      measurement: useSelector((state: RootState) =>
        selectors.getCurrentHrValue(state, deviceId),
      ),
      spotMeasurement: getSpotMeasurement(VS_METRICS.HR, spotData),
      patientStateValue: useSelector((state: RootState) =>
        selectors.getCurrentPatientStateValue(state, deviceId),
      ),
    },
    {
      measurementType: ALERT_METRIC_ENUM.RR,
      title: intl.formatMessage(messages.rr),
      unit: measurementUnits.BRPM,
      logoSrc: respirationLogo,
      quality: useSelector((state: RootState) =>
        selectors.getCurrentRrQuality(state, deviceId),
      ),
      measurement: useSelector((state: RootState) =>
        selectors.getCurrentRrValue(state, deviceId),
      ),
      spotMeasurement: getSpotMeasurement(VS_METRICS.RR, spotData),
      patientStateValue: useSelector((state: RootState) =>
        selectors.getCurrentPatientStateValue(state, deviceId),
      ),
    },
    {
      measurementType: 'IE',
      title: intl.formatMessage(messages.ieRatio),
      unit: '',
      logoSrc: respirationLogo,
      quality: useSelector((state: RootState) =>
        selectors.getCurrentRrQuality(state, deviceId),
      ),
      measurement: useSelector((state: RootState) =>
        selectors.getCurrentIERatioValue(state, deviceId),
      ),
      spotMeasurement: null,
      patientStateValue: useSelector((state: RootState) =>
        selectors.getCurrentPatientStateValue(state, deviceId),
      ),
    },
    {
      measurementType: 'RAbin',
      title: intl.formatMessage(messages.RAbin),
      unit: '',
      logoSrc: respirationLogo,
      quality: useSelector((state: RootState) =>
        selectors.getCurrentRrQuality(state, deviceId),
      ),
      measurement: useSelector((state: RootState) =>
        selectors.getCurrentRabinValue(state, deviceId),
      ),
      spotMeasurement: null,
      patientStateValue: useSelector((state: RootState) =>
        selectors.getCurrentPatientStateValue(state, deviceId),
      ),
    },
  ];
  return (
    <Wrapper>
      {measurements.map(measurementData => (
        <MeasurementCard
          key={`continuous-measurement-card-${measurementData.title}`}
          title={measurementData.title}
          unit={measurementData.unit}
          measurement={measurementData.measurement}
          spotMeasurement={measurementData.spotMeasurement}
          quality={measurementData.quality}
          patientStateValue={measurementData.patientStateValue}
          logoSrc={measurementData.logoSrc}
          shouldDisplayHighQualityOnly={shouldDisplayHighQualityOnly}
          measurementType={measurementData.measurementType as MeasurementType}
        />
      ))}
    </Wrapper>
  );
};

const messages = defineMessages({
  monitor: {
    defaultMessage: 'Monitor',
  },
  age: {
    defaultMessage: 'Age',
  },
  gender: {
    defaultMessage: 'Gender',
  },
  hr: {
    defaultMessage: 'Heart Rate',
  },
  rr: {
    defaultMessage: 'Respiration Rate',
  },
  ieRatio: {
    defaultMessage: 'I/E Ratio',
  },
  RAbin: {
    defaultMessage: 'Respiratory Amplitude',
  },
});

export default Connector(injectIntl(ContinuousMeasurements));
