import styled from 'styled-components';

export const RadioboxesWrapper = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .ant-radio-wrapper {
    font-size: 0.75rem;
    font-weight: 400;

    .ant-radio-inner {
      width: 1.25rem;
      height: 1.25rem;

      .ant-radio-inner::after {
        width: 1rem;
        height: 1rem;
      }
    }
  }
`;
