import {
  all,
  takeLatest,
  call,
  put,
  race,
  take,
  cancel,
  fork,
  select,
} from 'redux-saga/effects';
import * as dayjs from 'dayjs';

import {
  actionWarningNotification,
  notifyUserByActionTypeAndCode,
} from 'src/utils/errorHandling/notifications';
import BackendService from 'src/services/BackendService';
import { extractSessions } from 'src/redux/data/monitor/modules/monitorUtils';
import dataActions from 'src/redux/data/dataActions';
import { actions as sessionsActions } from 'src/redux/data/sessions';
import { actions, selectors } from './slice';
import { actions as monitorActions } from '../../../redux/data/monitor/modules/slice';
import { actions as appActions } from 'src/redux/data/app';
import { TRESHHOLDS_METRICS_ENUM } from 'src/redux/data/constants';
import { processHistogramData, partitionHistogramData } from './utils';

function* takeMonitorErrorsActions() {
  yield all([
    takeLatest(monitorActions.onStartContinuousFail, displayErrorNotification),
    takeLatest(monitorActions.onStopContinuousFail, displayErrorNotification),
    takeLatest(monitorActions.onHRIFailed, displayErrorNotification),
    takeLatest(monitorActions.onInitMonitorFailed, displayErrorNotification),
  ]);
}

// eslint-disable-next-line require-yield
function* displayErrorNotification(action) {
  const data = action.payload;
  notifyUserByActionTypeAndCode(data.actionType, data.deviceIds, data.error);
}

// eslint-disable-next-line require-yield
function* displayWarningNotification(action) {
  actionWarningNotification(action.payload.actionType);
}

function* monitorPageMounted() {
  const monitorInternetConnectedId = yield fork(monitorInternetConnected);
  yield race({
    monitorErrorsActions: call(takeMonitorErrorsActions),
    monitorPageUnmounted: take(monitorActions.monitorPageUnmounted),
  });
  yield cancel(monitorInternetConnectedId);
}

// TODO: Migrate this logic to IDB
function* monitorInternetConnected() {
  while (true) {
    try {
      yield take(appActions.onInternetConnection);
      const selectedDevice = yield select(selectors.getDevice);
      const devicesStatusesResponse = yield call(
        BackendService.getDevicesStatuses,
        selectedDevice.manufacturerId,
      );
      const sessions = extractSessions(devicesStatusesResponse.data, [
        selectedDevice.manufacturerId,
      ]);
      yield put(sessionsActions.fetchSessionsSuccess({ sessions }));
    } catch (e) {
      console.log(
        `An error occurred when getting device status after reconnecting to the internet]`,
      );
    }
  }
}

function* deviceNameById(action) {
  const deviceResponse = yield call(
    BackendService.getDeviceById,
    action.payload,
  );
  yield put(actions.setSelectedDevice(deviceResponse?.data.data[0] || null));
}

function* getHistogramData(action) {
  const patientId = action.payload;
  const metrics = [TRESHHOLDS_METRICS_ENUM.HR, TRESHHOLDS_METRICS_ENUM.RR];

  const timeFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
  const endTime = dayjs();
  const oneDayTime = endTime.subtract(1, 'd').format(timeFormat);
  const threeDayTime = endTime.subtract(3, 'd').format(timeFormat);
  const oneWeekTime = endTime.subtract(1, 'w').format(timeFormat);
  const formattedEndTime = endTime.format(timeFormat);

  try {
    const [
      lastDayDataResponse,
      lastThreeDaysDataResponse,
      lastWeekDataResponse,
      tresholdsResponse,
    ] = yield all([
      BackendService.getHistogramData({
        patientId,
        startTime: oneDayTime,
        endTime: formattedEndTime,
        metrics,
      }),
      BackendService.getHistogramData({
        patientId,
        startTime: threeDayTime,
        endTime: formattedEndTime,
        metrics,
      }),
      BackendService.getHistogramData({
        patientId,
        startTime: oneWeekTime,
        endTime: formattedEndTime,
        metrics,
      }),
      BackendService.getVSAlertThresholds(),
    ]);

    yield put(
      actions.getHistogramDataSuccess(
        partitionHistogramData({
          lastDay: processHistogramData(
            lastDayDataResponse.data,
            tresholdsResponse.data.thresholds,
          ),
          lastThreeDays: processHistogramData(
            lastThreeDaysDataResponse.data,
            tresholdsResponse.data.thresholds,
          ),
          lastWeek: processHistogramData(
            lastWeekDataResponse.data,
            tresholdsResponse.data.thresholds,
          ),
        }),
      ),
    );
  } catch (e) {
    console.error(`An error occurred when getting histogram data: ${e}`);
  }
}

export default function* watchMonitorActions() {
  yield all([
    takeLatest(dataActions.monitorPageMounted, monitorPageMounted),
    takeLatest(actions.deviceNameById, deviceNameById),
    takeLatest(actions.getHistogramData, getHistogramData),
  ]);
}
