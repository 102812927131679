import React from 'react';

import { SVGProps } from '../types';

const SoundOffIcon = ({
  width = '25',
  height = '24',
  color = '#767881',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.98492 14.9509C6.11278 14.8941 5.45044 14.1433 5.50292 13.2709V10.6709C5.45044 9.79849 6.11278 9.04766 6.98492 8.9909H8.09092C8.42246 8.98881 8.74208 8.867 8.99092 8.6479L10.5339 7.3169C11.003 6.94728 11.6485 6.8965 12.1696 7.18823C12.6907 7.47997 12.9848 8.0568 12.9149 8.6499V15.2919C12.9848 15.885 12.6907 16.4618 12.1696 16.7536C11.6485 17.0453 11.003 16.9945 10.5339 16.6249L8.98892 15.2939C8.74008 15.0748 8.42046 14.953 8.08892 14.9509H6.98492Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1494 13.6171C14.9541 13.8124 14.9541 14.129 15.1494 14.3243C15.3446 14.5195 15.6612 14.5195 15.8565 14.3243L15.1494 13.6171ZM17.8565 12.3243C18.0517 12.129 18.0517 11.8124 17.8565 11.6171C17.6612 11.4219 17.3446 11.4219 17.1494 11.6171L17.8565 12.3243ZM17.1494 11.6171C16.9541 11.8124 16.9541 12.129 17.1494 12.3243C17.3446 12.5195 17.6612 12.5195 17.8565 12.3243L17.1494 11.6171ZM19.8565 10.3243C20.0517 10.129 20.0517 9.81241 19.8565 9.61715C19.6612 9.42189 19.3446 9.42189 19.1494 9.61715L19.8565 10.3243ZM17.8565 11.6171C17.6612 11.4219 17.3446 11.4219 17.1494 11.6171C16.9541 11.8124 16.9541 12.129 17.1494 12.3243L17.8565 11.6171ZM19.1494 14.3243C19.3446 14.5195 19.6612 14.5195 19.8565 14.3243C20.0517 14.129 20.0517 13.8124 19.8565 13.6171L19.1494 14.3243ZM17.1494 12.3243C17.3446 12.5195 17.6612 12.5195 17.8565 12.3243C18.0517 12.129 18.0517 11.8124 17.8565 11.6171L17.1494 12.3243ZM15.8565 9.61715C15.6612 9.42189 15.3446 9.42189 15.1494 9.61715C14.9541 9.81241 14.9541 10.129 15.1494 10.3243L15.8565 9.61715ZM15.8565 14.3243L17.8565 12.3243L17.1494 11.6171L15.1494 13.6171L15.8565 14.3243ZM17.8565 12.3243L19.8565 10.3243L19.1494 9.61715L17.1494 11.6171L17.8565 12.3243ZM17.1494 12.3243L19.1494 14.3243L19.8565 13.6171L17.8565 11.6171L17.1494 12.3243ZM17.8565 11.6171L15.8565 9.61715L15.1494 10.3243L17.1494 12.3243L17.8565 11.6171Z"
      fill={color}
    />
  </svg>
);

export default SoundOffIcon;
