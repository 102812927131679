import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { UUID } from 'src/types/utility';
import { RootState } from 'src/redux/store';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import { ListUserType } from 'src/types/users';
import {
  FetchInvitedUsersResponse,
  FetchUsersResponse,
  FetchUserTypesResponse,
} from 'src/services/types';
import { DATA_STATE_KEY, DATA_FETCHING_STATUS } from '../../constants';
import { mergeArraysWithUniqueIds } from '../../dataUtils';
import { ActiveUserWithStatus, PendingUserWithStatus } from './types';
import {
  parseUserTypesResponse,
  parseAllUsersResponse,
  parseAllPendingUsersResponse,
} from './parser';

export const STATE_KEY = 'user';

export const INITIAL_STATE: {
  userTypes: {
    data: ListUserType[];
    status: keyof typeof DATA_FETCHING_STATUS;
  };
  userList: ActiveUserWithStatus[];
  invitedUsersList: PendingUserWithStatus[];
  mtmUsersList: {
    data: ActiveUserWithStatus[];
    status: keyof typeof DATA_FETCHING_STATUS;
  };
  status: keyof typeof DATA_FETCHING_STATUS;
  modalStatus: MODAL_STATUS;
} = {
  userTypes: { data: [], status: DATA_FETCHING_STATUS.LOADING },
  userList: [],
  invitedUsersList: [],
  mtmUsersList: { data: [], status: DATA_FETCHING_STATUS.LOADING },
  status: DATA_FETCHING_STATUS.LOADING,
  modalStatus: MODAL_STATUS.INITIAL,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    fetchAllUsers() {
      return INITIAL_STATE;
    },
    fetchExistentUsersSuccess(
      state,
      action: PayloadAction<{
        users: FetchUsersResponse;
      }>,
    ) {
      const { users } = action.payload;

      state.userList = mergeArraysWithUniqueIds(
        state.userList,
        parseAllUsersResponse(users.data),
      );
      state.status = DATA_FETCHING_STATUS.SUCCESS;
    },
    fetchAllUsersSuccess(
      state,
      action: PayloadAction<{
        users: FetchUsersResponse;
        invitedUsers: FetchInvitedUsersResponse;
      }>,
    ) {
      const { users, invitedUsers } = action.payload;

      state.userList = mergeArraysWithUniqueIds(
        state.userList,
        parseAllUsersResponse(users.data),
      );
      state.invitedUsersList = mergeArraysWithUniqueIds(
        state.invitedUsersList,
        parseAllPendingUsersResponse(invitedUsers.invitationsResponse),
      );
      state.status = DATA_FETCHING_STATUS.SUCCESS;
    },
    fetchAllUsersFail(state) {
      state.status = DATA_FETCHING_STATUS.ERROR;
    },
    fetchExistentUsersFail(state) {
      state.status = DATA_FETCHING_STATUS.ERROR;
    },
    fetchUserTypes(state) {
      state.userTypes = INITIAL_STATE.userTypes;
    },
    fetchUserTypesSuccess(
      state,
      action: PayloadAction<FetchUserTypesResponse>,
    ) {
      state.userTypes.data = parseUserTypesResponse(action.payload.data);
      state.userTypes.status = DATA_FETCHING_STATUS.SUCCESS;
    },
    fetchUserTypesFail(state) {
      state.userTypes.status = DATA_FETCHING_STATUS.ERROR;
    },

    fetchMtmUsersList(state) {
      state.mtmUsersList = INITIAL_STATE.mtmUsersList;
    },
    fetchMtmUsersListSuccess(state, action: PayloadAction<FetchUsersResponse>) {
      state.mtmUsersList.data = parseAllUsersResponse(action.payload.data);
      state.mtmUsersList.status = DATA_FETCHING_STATUS.SUCCESS;
    },
    fetchMtmUsersListFail(state) {
      state.mtmUsersList.status = DATA_FETCHING_STATUS.ERROR;
    },

    setStatus(state, action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>) {
      state.status = action.payload;
    },
    setModalStatus(state, action: PayloadAction<MODAL_STATUS>) {
      state.modalStatus = action.payload;
    },
    deleteInvitationSuccess(state, action: PayloadAction<UUID>) {
      const deletedId = action.payload;
      state.invitedUsersList = state.invitedUsersList.filter(
        user => user.id !== deletedId,
      );
    },
    deleteUserSuccess(state, action: PayloadAction<UUID>) {
      const deletedId = action.payload;
      state.userList = state.userList.filter(user => user.id !== deletedId);
    },
  },
  extraReducers: {},
});

export const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

const getUserId = (_state: RootState, userId: UUID) => userId;

export const selectors = {
  getUsersList: createSelector(getState, state => state.userList),
  getMtmUsers: createSelector(getState, state => state.mtmUsersList),
  selectUser: createSelector(getState, getUserId, (state, userId) =>
    state.userList.find(user => user.id === userId),
  ),
  getInvitedUsersList: createSelector(
    getState,
    state => state.invitedUsersList,
  ),
  getStatus: createSelector(getState, state => state.status),
  getUserTypes: createSelector(getState, state => state.userTypes.data),
  getModalStatus: createSelector(getState, state => state.modalStatus),
};

const extraActions = {
  fetchUsers: createAction(`${STATE_KEY}/fetchUsers`),
  fetchExistentUsers: createAction(`${STATE_KEY}/fetchExistentUsers`),
  fetchAllUsers: createAction(`${STATE_KEY}/fetchAllUsers`),
  fetchMtmUsers: createAction(`${STATE_KEY}/fetchMtmUsers`),
  inviteUser: createAction<Record<string, unknown>>(`${STATE_KEY}/inviteUser`),
  deleteUser: createAction<UUID>(`${STATE_KEY}/deleteUser`),
  updateUser: createAction<{ userId: UUID; data: Record<string, unknown> }>(
    `${STATE_KEY}/updateUser`,
  ),
  updateMfaUserInfo: createAction<{
    userId: UUID;
    data: Record<string, unknown>;
  }>(`${STATE_KEY}/updateMfaUserInfo`),
  updateUserPhone: createAction<{ userId: UUID; data: { phone: string } }>(
    `${STATE_KEY}/updatePhone`,
  ),
  sendMessageForResetPassword: createAction<{ email: string }>(
    `${STATE_KEY}/sendMessageForResetPassword`,
  ),
  resendInvitation: createAction<{ id: string }>(
    `${STATE_KEY}/resendInvitation`,
  ),
  deleteInvitation: createAction<UUID>(`${STATE_KEY}/deleteInvitation`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
