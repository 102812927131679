import {
  createSlice,
  createSelector,
  PayloadAction,
  createAction,
} from '@reduxjs/toolkit';
import * as A from 'fp-ts/lib/Array';

import { DATA_FETCHING_STATUS, DATA_STATE_KEY } from 'src/redux/data/constants';
import { RootState } from 'src/redux/store';
import { StartSessionResponse } from 'src/services/types';
import { Session, SessionStatus } from 'src/types/sessions';
import { SerialNumber, UUID } from 'src/types/utility';
import { API_DATE_FORMAT, nowUTC } from 'src/utils/timeUtils';
import { mergeArraysWithUniqueIds } from '../../dataUtils';

export const STATE_KEY = 'sessions';

export const INITIAL_STATE: {
  fetchStatus: keyof typeof DATA_FETCHING_STATUS;
  sessions: Session[];
} = {
  fetchStatus: DATA_FETCHING_STATUS.SUCCESS,
  sessions: [],
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    onFetchSessions: state => {
      state.fetchStatus = DATA_FETCHING_STATUS.LOADING;
    },
    fetchSessionsSuccess: (
      state,
      action: PayloadAction<{ sessions: Session[] }>,
    ) => {
      const newSessions = action.payload.sessions;

      state.sessions = mergeArraysWithUniqueIds(state.sessions, newSessions);
      state.fetchStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    fetchSessionsFailed: state => {
      state.fetchStatus = DATA_FETCHING_STATUS.ERROR;
    },
    onSessionStarted: (state, action: PayloadAction<StartSessionResponse>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { sessionId, currentTime, tenantId, ...rest } = action.payload;
      state.sessions = [...state.sessions, { id: sessionId, ...rest }];
    },
    onSessionStopped: (
      state,
      action: PayloadAction<{
        deviceId: SerialNumber;
        status: SessionStatus;
        patientId: UUID;
        sessionId: UUID;
      }>,
    ) => {
      const { status, sessionId } = action.payload;

      state.sessions = A.map<Session, Session>(s =>
        s.id === sessionId
          ? {
              ...s,
              status,
              endTime: nowUTC().format(API_DATE_FORMAT),
            }
          : s,
      )(state.sessions);
    },
    fetchPatientsLatestSessionsSuccess: (
      state,
      action: PayloadAction<Session[]>,
    ) => {
      state.sessions = mergeArraysWithUniqueIds(state.sessions, action.payload);
    },
    fetchPatientsLatestSessionsFailed: state => {
      state.fetchStatus = DATA_FETCHING_STATUS.ERROR;
    },
    fetchDevicesLatestSessionsSuccess: (
      state,
      action: PayloadAction<{ sessions: Session[] }>,
    ) => {
      const newSessions = action.payload.sessions;

      state.sessions = mergeArraysWithUniqueIds(state.sessions, newSessions);
    },
    fetchDevicesLatestSessionsFailed: state => {
      state.fetchStatus = DATA_FETCHING_STATUS.ERROR;
    },
  },
});

const extraActions = {
  fetchDevicesLatestSessions: createAction(
    `${STATE_KEY}/fetchDevicesLatestSessions`,
  ),
  fetchPatientsLatestSessions: createAction<UUID[]>(
    `${STATE_KEY}/fetchPatientsLatestSessions`,
  ),
};

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  selectFetchStatus: createSelector(getState, state => state.fetchStatus),
  areSessionsLoading: createSelector(
    getState,
    state => state.fetchStatus === DATA_FETCHING_STATUS.LOADING,
  ),
  selectSessions: createSelector(getState, state => state.sessions),
};

export const actions = { ...slice.actions, ...extraActions };
const { reducer } = slice;
export default reducer;
