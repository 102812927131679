import {
  all,
  takeLatest,
  call,
  put,
  select,
  delay,
} from 'typed-redux-saga/macro';

import BackendService from 'src/services/BackendService';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import permissions from 'src/permissions';
import { hasAnyPermissions, hasAllPermissions } from 'src/utils/permissions';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import { actions as deviceActions } from 'src/routes/Devices/modules/slice';
import { actions } from './slice';
import {
  DATA_FETCHING_STATUS,
  DEVICE_DEFAULT_PROTOCOL_TYPE,
} from '../../constants';
import dataActions from '../../dataActions';
import { SerialNumber } from 'src/types/utility';
import { RootState } from 'src/redux/store';

export function* getUserDevicesList(
  action: ReturnType<typeof actions.getUserDevicesList> | undefined,
) {
  if (
    hasAllPermissions(
      permissions.VIEW_DEVICES_ADD_BUTTON,
      permissions.VIEW_DEVICES_TENANT_ACTIONS,
      permissions.VIEW_DEVICES_TENANT_COLUMN,
      permissions.VIEW_DEVICES_EDIT_DEVICE_ACTION,
      permissions.VIEW_DEVICES_DELETE_DEVICE_ACTION,
    )
  ) {
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
    yield* call(getAdminDevicesList, action?.payload || {});
  } else if (
    hasAnyPermissions(
      permissions.VIEW_DEVICES_MONITOR_ACTION,
      permissions.VIEW_DEVICES_SIDE_MENU_BUTTON,
    )
  ) {
    yield* call(getDevicesList);
  } else {
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

export function* getDevicesList() {
  try {
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
    const { data } = yield* call(BackendService.getDevices, {});
    yield* put(actions.setDevicesList(data.data));
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (e) {
    console.error('error in getDevicesList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

function* getAdminDevicesList(action: any) {
  try {
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
    const devicesResponse = yield* call(BackendService.adminGetDevices, action);
    const { data: devicesSearchResults } = yield* select(
      (state: RootState) => state.data.device.devicesSearchResults,
    );
    if (devicesSearchResults.length) {
      const updatedSearchResults = devicesResponse.data.data.filter(device =>
        devicesSearchResults.find(
          searchedDevice =>
            device.manufacturerId === searchedDevice.manufacturerId,
        ),
      );
      yield* put(
        actions.setSearchedDevices({
          data: [...updatedSearchResults],
          status: DATA_FETCHING_STATUS.SUCCESS,
        }),
      );
    }
    yield* put(
      actions.setAdminDevicesList({
        adminDevicesList: devicesResponse.data.data,
        pageMetadata: devicesResponse.data.metadata.page,
      }),
    );
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (e) {
    console.error('error in getAdminDevicesList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

function* searchAdminDevicesListByTenantId(action: any) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { payload } = action;

    yield* put(
      actions.setSearchedDevices({
        data: [],
        status: DATA_FETCHING_STATUS.LOADING,
      }),
    );
    const devicesResponse = yield* call(
      BackendService.getAdminDevicesByTenantId,
      payload,
    );

    yield* put(
      actions.setSearchedDevices({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: devicesResponse?.data?.data,
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
  } catch (e) {
    console.error('error in search searchAdminDevicesListByTenantId: ', e);
    yield* put(
      actions.setSearchedDevices({
        data: [],
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
  }
}

function* searchAllDevicesAdmin(
  action: ReturnType<typeof actions.searchAllDevicesAdmin>,
) {
  const { payload } = action;

  if (!payload?.length) {
    yield* put(
      actions.setSearchedDevices({
        data: [],
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
    return;
  }
  yield* delay(500);
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

    yield* put(
      actions.setSearchedDevices({
        data: [],
        status: DATA_FETCHING_STATUS.LOADING,
      }),
    );
    const devicesResponse = yield* call(
      BackendService.adminSearchAllDevices,
      payload,
    );
    yield* put(
      actions.setSearchedDevices({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: devicesResponse?.data?.data,
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
  } catch (e) {
    console.error('error in search searchAdminDevicesListByTenantId: ', e);
    yield* put(
      actions.setSearchedDevices({
        data: [],
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
  }
}

function* createDevice(action: ReturnType<typeof actions.createDevice>) {
  const { manufacturerId, name } = action.payload;

  const devicePageMetadata = yield* select((state: RootState) => ({
    page: state.data.device.adminDevices.pageMetadata.page,
    limit: state.data.device.adminDevices.pageMetadata.limit,
  }));

  const pageParams = { payload: devicePageMetadata, type: '' };

  try {
    const createDeviceRequest = {
      manufacturerId,
      name,
      deviceProtocolType: DEVICE_DEFAULT_PROTOCOL_TYPE,
    };
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));
    yield* call(BackendService.adminCreateDevice, createDeviceRequest);
    yield* call(getUserDevicesList, pageParams);
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
  } catch (error) {
    console.error('error in createDevice: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, manufacturerId, error);
  }
}

function* deleteDeviceCertificate(manufacturerId: SerialNumber) {
  yield* call(BackendService.deleteDeviceCertificate, manufacturerId);
}

function* deleteDevice(action: ReturnType<typeof actions.deleteDevice>) {
  const { manufacturerId, certificateExists } = action.payload;

  const devicePageMetadata = yield* select((state: RootState) => ({
    page: state.data.device.adminDevices.pageMetadata.page,
    limit: state.data.device.adminDevices.pageMetadata.limit,
  }));

  const paginationAction = { payload: devicePageMetadata, type: '' };

  try {
    if (certificateExists) {
      yield* call(deleteDeviceCertificate, manufacturerId);
    }
    yield* call(BackendService.adminDeleteDevice, manufacturerId);
    yield* call(getUserDevicesList, paginationAction);
  } catch (error) {
    console.error('error in deleteDevice: ', error);
    notifyUserByActionTypeAndCode(action.type, manufacturerId, error);
  }
}

function* editDevice(action: ReturnType<typeof actions.editDevice>) {
  const {
    manufacturerId,
    editDeviceData: { name },
  } = action.payload;

  const devicePageMetadata = yield* select((state: RootState) => ({
    page: state.data.device.adminDevices.pageMetadata.page,
    limit: state.data.device.adminDevices.pageMetadata.limit,
  }));

  const paginationAction = { payload: devicePageMetadata, type: '' };

  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));
    if (
      hasAllPermissions(
        permissions.VIEW_DEVICES_ADD_BUTTON,
        permissions.VIEW_DEVICES_TENANT_ACTIONS,
        permissions.VIEW_DEVICES_TENANT_COLUMN,
        permissions.VIEW_DEVICES_EDIT_DEVICE_ACTION,
        permissions.VIEW_DEVICES_DELETE_DEVICE_ACTION,
      )
    ) {
      yield* call(BackendService.adminUpdateDevice, manufacturerId, { name });
    } else {
      yield* call(BackendService.updateDevice, manufacturerId, { name });
    }
    yield* call(getUserDevicesList, paginationAction);
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
  } catch (error) {
    console.error('error in editDevice: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, manufacturerId, error);
  }
}

function* assignDevice(action: ReturnType<typeof actions.assignDevice>) {
  const { manufacturerId, tenantSelect } = action.payload;
  const devicePageMetadata = yield* select((state: RootState) => ({
    page: state.data.device.adminDevices.pageMetadata.page,
    limit: state.data.device.adminDevices.pageMetadata.limit,
  }));

  const paginationAction = { payload: devicePageMetadata, type: '' };
  try {
    yield* call(
      BackendService.adminUpdateDeviceTenant,
      manufacturerId,
      tenantSelect,
    );
    yield* call(getUserDevicesList, paginationAction);
  } catch (error) {
    console.error('error in assignDevice: ', error);
    notifyUserByActionTypeAndCode(action.type, manufacturerId, error);
  }
}

function* detachDevice(action: ReturnType<typeof actions.detachDevice>) {
  const manufacturerId = action.payload;
  const devicePageMetadata = yield* select((state: RootState) => ({
    page: state.data.device.adminDevices.pageMetadata.page,
    limit: state.data.device.adminDevices.pageMetadata.limit,
  }));

  const paginationAction = { payload: devicePageMetadata, type: '' };

  try {
    yield* call(BackendService.adminUpdateDeviceTenant, manufacturerId, null);
    yield* call(getUserDevicesList, paginationAction);
  } catch (error) {
    console.error('error in detachDevice: ', error);
    notifyUserByActionTypeAndCode(action.type, manufacturerId, error);
  }
}

function* fetchMTMDeviceConnectionInfo(
  action: ReturnType<typeof actions.fetchMTMDeviceConnectionInfo>,
) {
  const continuousDisconnectionSeconds = action.payload;

  try {
    const { data } = yield* call(
      BackendService.fetchMTMDeviceConnectionInfo,
      continuousDisconnectionSeconds,
    );
    const mappedResponse = {};
    data.data.forEach(elem => {
      // @ts-ignore need to fix this
      mappedResponse[elem.tenantId] = { ...elem };
    });
    yield* put(actions.fetchMTMDeviceConnectionInfoSuccess(mappedResponse));
  } catch (error) {
    console.error('error in fetchMTMDeviceConnectionInfo: ', error);
  }
}

export default function* watchTenantActions() {
  yield* all([
    takeLatest(actions.getUserDevicesList, getUserDevicesList),
    takeLatest(actions.getDevicesList, getDevicesList),
    takeLatest(actions.getAdminDevicesList, getAdminDevicesList),
    takeLatest(actions.deleteDevice, deleteDevice),
    takeLatest(actions.editDevice, editDevice),
    takeLatest(actions.createDevice, createDevice),
    takeLatest(actions.assignDevice, assignDevice),
    takeLatest(actions.detachDevice, detachDevice),
    takeLatest(deviceActions.onLoadDevicesListPage, getUserDevicesList),
    takeLatest(
      dataActions.onLoadGroupDevices,
      searchAdminDevicesListByTenantId,
    ),
    takeLatest(actions.searchAllDevicesAdmin, searchAllDevicesAdmin),
    takeLatest(dataActions.onLoadManagment, getDevicesList),
    takeLatest(dataActions.monitorPageMounted, getDevicesList),
    takeLatest(
      actions.fetchMTMDeviceConnectionInfo,
      fetchMTMDeviceConnectionInfo,
    ),
  ]);
}
