import React, { useEffect } from 'react';

import SelectBox from 'src/components/general-ui/SelectBox';
import { UUID } from 'src/types/utility';
import Connector, { PropsFromRedux } from './Connector';

type Props = PropsFromRedux;

const SelectTenantBox = ({
  subtenants,
  loggedInGroupManager,
  setSelectedTenantId,
}: Props): JSX.Element => {
  useEffect(() => {
    setSelectedTenantId(loggedInGroupManager?.id || '');
  }, [loggedInGroupManager?.id, setSelectedTenantId]);

  const onSelectTenant = (tenantId: UUID) => {
    setSelectedTenantId(tenantId);
  };

  const subtenantsOptionList = subtenants.map(tenant => ({
    value: tenant.id,
    label: tenant.name,
  }));

  return (
    <SelectBox
      options={subtenantsOptionList}
      defaultValue={loggedInGroupManager?.name}
      handleChange={(id: UUID) => {
        onSelectTenant(id);
      }}
    />
  );
};

export default Connector(SelectTenantBox);
