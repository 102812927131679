import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const EditableRow = ({ EditableContext, index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

EditableRow.propTypes = {
  EditableContext: PropTypes.object.isRequired,
  index: PropTypes.string,
};

EditableRow.defaultProps = {
  index: '',
};

export default injectIntl(EditableRow);
