import React from 'react';

import { SVGProps } from '../types';

const BedExitFrequencyIcon = ({
  width = '30',
  height = '19',
  color = '#CA0000',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      d="M8.66632 8.36011L15.666 1.64011"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M15.666 4.62622L15.666 1.63955L12.555 1.63955"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M24.3347 3.23999L17.335 9.95999"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M17.335 6.97388V9.96054H20.4459"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path
      d="M0.695876 19C1.08006 19 1.39175 18.797 1.39175 18.5468V16.482H28.6082V18.5468C28.6082 18.797 28.9199 19 29.3041 19C29.6883 19 30 18.797 30 18.5468V8.97842C30 8.72819 29.6883 8.52518 29.3041 8.52518C28.9199 8.52518 28.6082 8.72819 28.6082 8.97842V13.1432H1.39175V5.45324C1.39175 5.20301 1.08006 5 0.695876 5C0.311695 5 0 5.20301 0 5.45324V18.5468C0 18.797 0.311695 19 0.695876 19ZM1.39175 15.5755V14.9712H28.6082V15.5755H1.39175Z"
      fill={color}
    />
  </svg>
);

export default BedExitFrequencyIcon;
