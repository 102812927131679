import React, { WheelEvent, InputHTMLAttributes } from 'react';
import { InputContainer, StyledInput, StyledOverlayIcon } from './styled';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  iconSrc: string;
  isError?: boolean;
};

const InputWithIcon = ({
  iconSrc,
  isError,
  ...inputProps
}: Props): JSX.Element => {
  const numberInputOnWheelPreventChange = (e: WheelEvent) => {
    const target = e.target as HTMLInputElement;

    target?.blur();

    e.stopPropagation();
  };

  return (
    <InputContainer>
      <StyledInput
        isError={isError}
        onWheel={numberInputOnWheelPreventChange}
        {...inputProps}
      />
      <StyledOverlayIcon
        src={iconSrc}
        alt="icon"
        w="0.6rem"
        h="0.6rem"
        background="#ffffff"
      />
    </InputContainer>
  );
};

export default InputWithIcon;
