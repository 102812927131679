import { all, takeLatest, put, call, select } from 'typed-redux-saga/macro';

import dataActions from 'src/redux/data/dataActions';
import BackendService from 'src/services/BackendService';
import {
  notifyUserByActionTypeAndCode,
  actionSuccessNotification,
} from 'src/utils/errorHandling/notifications';
import { DATA_FETCHING_STATUS } from '../../constants';
import { actions } from './slice';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';

export function* fetchSubscribersList() {
  try {
    const {
      data: { tenantIdToSubscribers },
    } = yield* call(BackendService.getSubscribersList);
    const loggedInTenantId = yield* select(
      loggedInUserSelectors.getUserTenantId,
    );
    if (!loggedInTenantId || !tenantIdToSubscribers) {
      return;
    }
    yield* put(
      actions.fetchSubscribersListSuccess(
        tenantIdToSubscribers[loggedInTenantId]?.subscribers || [],
      ),
    );
  } catch (e) {
    console.error('error in fetchSubscribersList: ', e);
    yield* put(actions.fetchSubscribersListFail());
  }
}

function* updateSubscribersList(
  action: ReturnType<typeof actions.updateSubscribersList>,
) {
  try {
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
    const { data, callback } = action.payload;

    yield* call(BackendService.updateSubscribersList, data);

    callback();

    yield* put(actions.updateSubscribersListSuccess());
    actionSuccessNotification(action.type, null);
  } catch (error) {
    console.error('error in updateSubscribersList: ', error);
    yield* put(actions.updateSubscribersListFail());
    notifyUserByActionTypeAndCode(action.type, action.payload, error);
  }
}

export default function* watchAlertsActions() {
  yield* all([
    takeLatest(dataActions.onLoadSMSEmailAlert, fetchSubscribersList),
    takeLatest(actions.updateSubscribersList, updateSubscribersList),
  ]);
}
