import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { API_STATUS } from 'src/utils/api-constants';
import { selectors as individualDashboardSelectors } from 'src/routes/IndividualDashboard/modules/dashboard/slice';

const mapStateToProps = (state: RootState) => ({
  alertLogMetadata: individualDashboardSelectors.selectAlertsLogMetadata(state),
  isLoading:
    individualDashboardSelectors.selectAlertsLogStatus(state) ===
    API_STATUS.LOADING,
});

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type IProps = PropsFromRedux;

export default Connector;
