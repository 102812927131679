import { roundToNDecimals } from 'src/utils/dataUtils';
import {
  VALID_MIN_HR,
  VALID_MAX_HR,
  VALID_MIN_RR,
  VALID_MAX_RR,
} from 'src/utils/constants';
import {
  ALERT_METRIC_ENUM,
  ALERT_METRIC_PREPOSITION_ENUM,
} from 'src/redux/data/constants';

export const DEFAULT_HISTOGRAM_DATA_STATE = {
  avg: 0,
  maxValue: 0,
  minValue: 0,
  histogram: {
    number: {
      total: 0,
      above: 0,
      below: 0,
      between: 0,
    },
    percentage: {
      above: 0,
      below: 0,
      between: 0,
    },
  },
};

export const extractThresholdValues = thresholds => ({
  rrBelowTreshold:
    thresholds.find(
      th =>
        th.enable &&
        th.metric === ALERT_METRIC_ENUM.RR &&
        th.preposition === ALERT_METRIC_PREPOSITION_ENUM.BELOW,
    )?.value || null,
  rrAboveTreshold:
    thresholds.find(
      th =>
        th.enable &&
        th.metric === ALERT_METRIC_ENUM.RR &&
        th.preposition === ALERT_METRIC_PREPOSITION_ENUM.ABOVE,
    )?.value || null,
  hrBelowTreshold:
    thresholds.find(
      th =>
        th.enable &&
        th.metric === ALERT_METRIC_ENUM.HR &&
        th.preposition === ALERT_METRIC_PREPOSITION_ENUM.BELOW,
    )?.value || null,
  hrAboveTreshold:
    thresholds.find(
      th =>
        th.enable &&
        th.metric === ALERT_METRIC_ENUM.HR &&
        th.preposition === ALERT_METRIC_PREPOSITION_ENUM.ABOVE,
    )?.value || null,
});

export const setDefaultThresholdValues = ({
  rrBelowTreshold,
  rrAboveTreshold,
  hrBelowTreshold,
  hrAboveTreshold,
}) => ({
  rrBelowTreshold: rrBelowTreshold || VALID_MIN_RR,
  rrAboveTreshold: rrAboveTreshold || VALID_MAX_RR,
  hrBelowTreshold: hrBelowTreshold || VALID_MIN_HR,
  hrAboveTreshold: hrAboveTreshold || VALID_MAX_HR,
});

const computePercentages = ({ total, above, below }) => {
  if (total === 0) {
    return {
      above: 0,
      below: 0,
      between: 0,
    };
  }
  const abovePercentage = roundToNDecimals((above * 100) / total);
  const belowPercentage = roundToNDecimals((below * 100) / total);

  return {
    above: abovePercentage,
    below: belowPercentage,
    between: roundToNDecimals(100 - abovePercentage - belowPercentage),
  };
};

export const processHistogramData = (data, thresholds) => {
  const rrData = data.metrics.RR.histogram;
  const hrData = data.metrics.HR.histogram;
  const { rrBelowTreshold, rrAboveTreshold, hrBelowTreshold, hrAboveTreshold } =
    setDefaultThresholdValues(extractThresholdValues(thresholds));

  const rrNormalized = rrData.reduce(
    (acc, item) => {
      if (item.value > rrAboveTreshold) {
        return {
          ...acc,
          total: acc.total + item.count,
          above: acc.above + item.count,
        };
      }

      if (item.value < rrBelowTreshold) {
        return {
          ...acc,
          total: acc.total + item.count,
          below: acc.below + item.count,
        };
      }

      return {
        ...acc,
        total: acc.total + item.count,
        between: acc.between + item.count,
      };
    },
    {
      total: 0,
      above: 0,
      below: 0,
      between: 0,
    },
  );

  const hrNormalized = hrData.reduce(
    (acc, item) => {
      if (item.value > hrAboveTreshold) {
        return {
          ...acc,
          total: acc.total + item.count,
          above: acc.above + item.count,
        };
      }

      if (item.value < hrBelowTreshold) {
        return {
          ...acc,
          total: acc.total + item.count,
          below: acc.below + item.count,
        };
      }

      return {
        ...acc,
        total: acc.total + item.count,
        between: acc.between + item.count,
      };
    },
    {
      total: 0,
      above: 0,
      below: 0,
      between: 0,
    },
  );

  return {
    ...data,
    RR: {
      ...data.RR,
      histogram: {
        numeric: rrNormalized,
        percentage: computePercentages(rrNormalized),
      },
    },
    HR: {
      ...data.HR,
      histogram: {
        numeric: hrNormalized,
        percentage: computePercentages(hrNormalized),
      },
    },
  };
};

export const partitionHistogramData = ({
  lastDay,
  lastThreeDays,
  lastWeek,
}) => ({
  RR: {
    lastDay: {
      histogram: lastDay.RR.histogram,
      avg: lastDay.metrics.RR.avg,
      minValue: lastDay.metrics.RR.minValue,
      maxValue: lastDay.metrics.RR.maxValue,
    },
    lastThreeDays: {
      histogram: lastThreeDays.RR.histogram,
      avg: lastThreeDays.metrics.RR.avg,
      minValue: lastThreeDays.metrics.RR.minValue,
      maxValue: lastThreeDays.metrics.RR.maxValue,
    },
    lastWeek: {
      histogram: lastWeek.RR.histogram,
      avg: lastWeek.metrics.RR.avg,
      minValue: lastWeek.metrics.RR.minValue,
      maxValue: lastWeek.metrics.RR.maxValue,
    },
  },
  HR: {
    lastDay: {
      histogram: lastDay.HR.histogram,
      avg: lastDay.metrics.HR.avg,
      minValue: lastDay.metrics.HR.minValue,
      maxValue: lastDay.metrics.HR.maxValue,
    },
    lastThreeDays: {
      histogram: lastThreeDays.HR.histogram,
      avg: lastThreeDays.metrics.HR.avg,
      minValue: lastThreeDays.metrics.HR.minValue,
      maxValue: lastThreeDays.metrics.HR.maxValue,
    },
    lastWeek: {
      histogram: lastWeek.HR.histogram,
      avg: lastWeek.metrics.HR.avg,
      minValue: lastWeek.metrics.HR.minValue,
      maxValue: lastWeek.metrics.HR.maxValue,
    },
  },
});
