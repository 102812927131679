import { all, takeLatest, select, put } from 'redux-saga/effects';
import { push, getLocation } from 'connected-react-router';
import { actions } from './slice';
import {
  getLocaleByLanguage,
  getLocaleFromUrlPath,
  getUrlPathWithoutLocale,
} from '../../../utils/languageUtils';

function* changeLanguage(action) {
  const { language } = action.payload;

  const routeLocationObj = yield select(getLocation);
  const currentLocale = getLocaleFromUrlPath(routeLocationObj.pathname);
  const pathSuffix = getUrlPathWithoutLocale(
    currentLocale,
    routeLocationObj.pathname,
  );

  const updatedLocale = getLocaleByLanguage(language);
  yield put(push(`/${updatedLocale}${pathSuffix}${routeLocationObj.search}`));
}

export default function* watchLanguageActions() {
  yield all([takeLatest(actions.changeLanguage, changeLanguage)]);
}
