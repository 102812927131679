import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  DeviceSettingItem,
  StyledDropdownIcon,
  TooltipWithoutArrow,
} from '../styled';
import StatusFilterSelector from './StatusFilterSelector';

const DeviceStatus = () => {
  return (
    <TooltipWithoutArrow
      title={<StatusFilterSelector />}
      placement="bottom"
      overlayStyle={{ padding: '0' }}
      trigger="click"
    >
      <DeviceSettingItem>
        <FormattedMessage {...messages.deviceStatus} />
        <StyledDropdownIcon type="dropdown-arrow-icon" />
      </DeviceSettingItem>
    </TooltipWithoutArrow>
  );
};

const messages = defineMessages({
  deviceStatus: {
    defaultMessage: 'Filter',
  },
});

export default DeviceStatus;
