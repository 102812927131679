import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form, Input, Select } from 'antd';
import {
  MFA_OPTIONS,
  MFA_OPTIONS_VALUES,
  userFormFields,
  userFormFieldsLabelsMessages as labelMessages,
  userFormFieldsPlaceholdersMessages as placeholderMessages,
  userFormModes,
  userFormValidateMessages,
} from './constants';
import {
  defaultFormFieldsRulesArray as defaultRulesArray,
  emailPattern,
  phonePattern,
} from '../../utils/constants';
import RadioGroup from 'src/components/general-ui/RadioGroup';

const { Option } = Select;
const UserForm = ({ form, onSubmit, userTypes, user, mode, intl, fields }) => {
  useEffect(() => {
    form.resetFields(); // In order for the initial values of the form to be updated with the details of the current user
  }, [user, form]);

  const getExternalUserTypeOptions = () =>
    fields?.externalUserTypes
      ? fields?.externalUserTypes
          .filter(userType => !!userType)
          .map(userType => ({
            id: userType.id,
            label: userType.name,
            value: userType.id,
          }))
      : null;

  const onTenantTypeSelect = tenantType => {
    form.setFieldsValue({
      [userFormFields.USER_TYPE]: tenantType,
    });
  };
  const onMfaEnableChange = isMfaEnabled => {
    form.setFieldsValue({
      [userFormFields.MFA]: isMfaEnabled,
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={values => {
        onSubmit(values);
      }}
      scrollToFirstError
      validateMessages={userFormValidateMessages}
    >
      <Form.Item
        name={userFormFields.FIRST_NAME}
        label={intl.formatMessage(labelMessages.firstName)}
        initialValue={user?.firstName}
        rules={defaultRulesArray}
      >
        <Input
          placeholder={intl.formatMessage(placeholderMessages.firstName)}
          id={`${mode}-${userFormFields.FIRST_NAME}`}
        />
      </Form.Item>
      <Form.Item
        name={userFormFields.LAST_NAME}
        label={intl.formatMessage(labelMessages.lastName)}
        initialValue={user?.lastName}
        rules={defaultRulesArray}
      >
        <Input
          placeholder={intl.formatMessage(placeholderMessages.lastName)}
          id={`${mode}-${userFormFields.LAST_NAME}`}
        />
      </Form.Item>
      <Form.Item
        name={userFormFields.EMAIL}
        label={intl.formatMessage(labelMessages.email)}
        initialValue={user?.email}
        rules={[{ required: true, pattern: emailPattern }]}
      >
        <Input
          placeholder={intl.formatMessage(placeholderMessages.email)}
          disabled={mode === userFormModes.EDIT}
          id={`${mode}-${userFormFields.EMAIL}`}
        />
      </Form.Item>
      <Form.Item
        name={userFormFields.PHONE}
        label={intl.formatMessage(labelMessages.phoneNumber)}
        initialValue={user?.phone}
        rules={[{ pattern: phonePattern }]}
      >
        <Input
          placeholder={intl.formatMessage(placeholderMessages.phoneNumber)}
          disabled={!!fields?.phone?.disabled}
          id={`${mode}-${userFormFields.PHONE}`}
        />
      </Form.Item>
      {fields?.mfaPhone && (
        <>
          <Form.Item
            name={userFormFields.MFA_PHONE}
            label={intl.formatMessage(labelMessages.mfaPhoneNumber)}
            initialValue={user?.mfaPhone}
            rules={[{ pattern: phonePattern }]}
          >
            <Input
              placeholder={intl.formatMessage(
                placeholderMessages.mfaPhoneNumber,
              )}
              id={`${mode}-${userFormFields.MFA_PHONE}`}
            />
          </Form.Item>
          <Form.Item
            initialValue={user?.mfa}
            name={userFormFields.MFA}
            label={intl.formatMessage(labelMessages.enableMfa)}
          >
            <RadioGroup
              onSelect={onMfaEnableChange}
              selectedOption={
                user?.mfa ? MFA_OPTIONS_VALUES.TRUE : MFA_OPTIONS_VALUES.FALSE
              }
              optionsList={MFA_OPTIONS}
            />
          </Form.Item>
        </>
      )}
      {fields?.userType && (
        <Form.Item
          name={userFormFields.USER_TYPE}
          label={intl.formatMessage(labelMessages.userType)}
          initialValue={user?.userType?.name}
          rules={defaultRulesArray}
        >
          <Select
            placeholder={intl.formatMessage(placeholderMessages.userType)}
            disabled={mode === userFormModes.EDIT}
            allowClear
            id={`${mode}-${userFormFields.USER_TYPE}`}
          >
            {userTypes?.map(userType => (
              <Option key={userType.id} value={userType.id}>
                {userType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {fields?.externalUserTypes && (
        <Form.Item
          name={userFormFields.USER_TYPE}
          label={intl.formatMessage(labelMessages.userType)}
          initialValue={user?.userType?.name}
          rules={defaultRulesArray}
        >
          <RadioGroup
            onSelect={onTenantTypeSelect}
            optionsList={getExternalUserTypeOptions()}
            // disabledOption={mode === tenantModalModes.EDIT}
          />
        </Form.Item>
      )}
    </Form>
  );
};
UserForm.propTypes = {
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
  mode: PropTypes.oneOf(Object.values(userFormModes)).isRequired,
  userTypes: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  fields: PropTypes.object,
};

UserForm.defaultProps = {
  user: {},
};

export default injectIntl(UserForm);
