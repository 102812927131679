import { all, takeLatest, call, put, fork } from 'redux-saga/effects';
// import AppConfig from 'src/config/AppConfig';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import BackendService from '../../../../services/BackendService';
import { actions } from './slice';
import { convertLocaleDateTimeToUtc } from '../../../../redux/data/parser';
import { createAndDownloadHriCSVFile } from '../../../../utils/fileUtils';
// import { minute, hour, day, week, month, year } from './mockData';

function* getAllSessions(action) {
  const { patientId } = action.payload;
  try {
    const response = yield call(BackendService.getAllSessions, { patientId });
    yield put(
      actions.getAllSessionsSuccess({
        patientId,
        data: response?.data?.continuousSessions,
      }),
    );
  } catch (error) {
    console.error(
      `An error occurred while trying to fetch sessions for patient id: ${patientId} .\n Error:`,
      error,
    );
    yield put(actions.getAllSessionsFail({ patientId }));
  }
}

function* getSingleGraphPointsBySessionId(sessionId, metric) {
  try {
    const response = yield call(BackendService.getSessionGraphPoints, {
      sessionId,
      metric,
    });
    yield put(
      actions.getSessionGraphPointsSuccess({
        sessionId,
        metric,
        data: response?.data,
      }),
    );
  } catch (error) {
    console.error(
      `An error occurred while trying to fetch ${metric} graph points for session id:${sessionId}.\n Error:`,
      error,
    );
    yield put(actions.getSessionGraphPointsFail({ sessionId, metric }));
  }
}

function* getSessionGraphsPoints(action) {
  const { sessionId, metrics } = action.payload;
  for (const metric of metrics) {
    yield fork(getSingleGraphPointsBySessionId, sessionId, metric);
  }
}

function* getSpotMeasurements(action) {
  const { patientId, startTime, endTime } = action.payload;
  try {
    const response = yield call(BackendService.getSpotMeasurements, {
      patientId,
      startTime: startTime ? convertLocaleDateTimeToUtc(startTime) : null,
      endTime: endTime ? convertLocaleDateTimeToUtc(endTime) : null,
    });

    yield put(
      actions.getSpotMeasurementsSuccess({
        patientId,
        data: response?.data?.spotMeasurements,
      }),
    );
  } catch (error) {
    console.error(
      `An error occurred while trying to fetch spot sessions for patient id: ${patientId}.`,
      error,
    );
    yield put(actions.getSpotMeasurementsFail({ patientId }));
    notifyUserByActionTypeAndCode(action.type, patientId, error);
  }
}

function* getHriData(action) {
  const { patientId, startTime, endTime } = action.payload;
  try {
    const response = yield call(BackendService.getHriData, {
      patientId,
      startTime: startTime ? convertLocaleDateTimeToUtc(startTime) : null,
      stopTime: endTime ? convertLocaleDateTimeToUtc(endTime) : null,
    });
    yield put(
      actions.getHriDataSuccess({ patientId, data: response.data?.hriList }),
    );
  } catch (error) {
    yield put(actions.getHriDataFail({ patientId }));
    notifyUserByActionTypeAndCode(action.type, patientId, error);
  }
}

// eslint-disable-next-line require-yield
function* downloadHri(action) {
  const record = action.payload;
  try {
    createAndDownloadHriCSVFile(record, 'hriData.csv');
  } catch (error) {
    console.error('error in download hri data ', error);
    notifyUserByActionTypeAndCode(action.type, record.patientId, error);
  }
}
export default function* watchPatientHistoryActions() {
  yield all([
    takeLatest(actions.getAllSessions, getAllSessions),
    takeLatest(actions.viewSessionGraphs, getSessionGraphsPoints),
    takeLatest(actions.onSpotHistoryMount, getSpotMeasurements),
    takeLatest(actions.onHriParamsChanged, getHriData),
    takeLatest(actions.downloadHri, downloadHri),
  ]);
}
