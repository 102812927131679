import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Redirect } from 'react-router-dom';

import {
  QueryParamsType,
  appRoutes,
} from 'src/routes/Root/modules/routesUtils';
import { ErrorText } from 'src/routes/Auth/components/styled';
import Loader from 'src/components/general-ui/Loader';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const MODAL_MODES = Object.freeze({
  ADD: 'ADD',
  EDIT: 'EDIT',
});

export const apiStatusEnum = {
  LOADING: 'LOADING',
  OK: 'OK',
  ERROR: 'ERROR',
};

export const graphDataTypesEnum = {
  HR_DATA: 'hrData',
  RR_DATA: 'rrData',
};

export const tokenStatusEnum = Object.freeze({
  LOADING: 'LOADING',
  VALIDATED: 'VALIDATED',
  VALIDATE_FAILED: 'VALIDATE_FAILED',
  SUBMITTING_BODY: 'SUBMITTING_BODY',
  TOKEN_NOT_VALID: 'TOKEN_NOT_VALID',
  GENERAL_ERROR: 'GENERAL_ERROR',
  SUCCESS: 'SUCCESS',
});

export const TOKEN_STATUS_COMPONENT_MAPPER = (
  messages: Record<string, MessageDescriptor>,
) => ({
  [tokenStatusEnum.SUBMITTING_BODY]: <Loader />,
  [tokenStatusEnum.SUCCESS]: (
    <Redirect
      to={{
        pathname: `/${appRoutes.LOGIN}`,
        state: { loginAfterSetPassword: true },
      }}
    />
  ),
  [tokenStatusEnum.TOKEN_NOT_VALID]: (
    <ErrorText>
      <FormattedMessage {...messages.tokenError} />
    </ErrorText>
  ),
  [tokenStatusEnum.VALIDATE_FAILED]: (
    <ErrorText>
      <FormattedMessage {...messages.validationFailed} />
    </ErrorText>
  ),
  [tokenStatusEnum.GENERAL_ERROR]: (
    <ErrorText>
      <FormattedMessage {...messages.generalError} />
    </ErrorText>
  ),
});

export const tokenOperationErrorToStatus = (
  error: unknown,
  defaultValue: keyof typeof tokenStatusEnum,
) => {
  let tokenStatus;
  // TODO: // @ts-ignore FIX ME
  // @ts-ignore FIX ME
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  switch (error?.response?.data?.code || error?.code) {
    case 'TOKEN_NOT_VALID':
      tokenStatus = tokenStatusEnum.TOKEN_NOT_VALID;
      break;
    default:
      tokenStatus = defaultValue;
      break;
  }
  return tokenStatus;
};

export const fileActions = {
  DOWNLOAD: 'DOWNLOAD',
};

export const tenantFormFields = {
  NAME: 'name',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  COUNTRY_CODE: 'countryCode',
  STATE: 'state',
  CITY: 'city',
  TIMEZONE_UTCOFFSET_STR: 'timeZoneOffset',
  TIMEZONE_ID: 'timeZoneId',
  ZIP_CODE: 'zipCode',
  ADDRESS1: 'address1',
  ADDRESS2: 'address2',
  REGION: 'region',
  ENABLE_CPX: 'enableCpx',
  GROUP: 'group',
  PARENT_TENANT_ID: 'parentTenantId',
  CUSTOMER_ID: 'customerId',
};

export const NETEERA_TENANT = '555b93ff-c1b2-4483-b285-f50c0b5e7389';

export const defaultFormFieldsRulesArray = [
  { required: true },
  { whitespace: true },
];

export const emailPattern = new RegExp(
  '^([A-Za-z0-9!@#$%^&*)(+=._-]+)@([A-Za-z0-9!@#$%^&*)(+=._-]+)\\.([A-Za-z0-9!@#$%^&*)(+=._-]+)$',
);

export const phonePattern = new RegExp('^\\+[1-9]\\d{1,11}$');

export const patternUUID = new RegExp(
  '/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i',
);

export const patientStateToMessageMap = {
  IDLE: 'idle',
  MANUAL_START: 'idle',
  OCCUPIED: 'occupied',
  TIMEOUT: 'connectionError',
  EMPTY: 'empty',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
};

export const mapPatientStateToState = {
  0: patientStateToMessageMap.OCCUPIED,
  1: patientStateToMessageMap.EMPTY,
  2: patientStateToMessageMap.EMPTY,
  3: patientStateToMessageMap.OCCUPIED,
  4: patientStateToMessageMap.EMPTY,
  5: patientStateToMessageMap.EMPTY,
  6: patientStateToMessageMap.OCCUPIED,
  7: patientStateToMessageMap.OCCUPIED,
};

// To be discussed if needed for new alerts
export const alertLevelEnum = {
  NONE: 0,
  VISUAL_ALERT: 1,
  SOUND_ALERT: 2,
};

export const ALERT_STATUS_ENUM = Object.freeze({
  OFF: 'OFF',
  ON: 'ON',
  NONE: 'NONE',
  SUPPRESSED: 'SUPPRESSED',
});

export const VALID_MIN_HR = 40;
export const VALID_MAX_HR = 160;
export const VALID_MIN_RR = 5;
export const VALID_MAX_RR = 40;
export const VALID_MIN_IE = 0;
export const VALID_MAX_IE = 10;

export const healthProfessional = 'Health Professional';

export const SMS_EMAIL_ALERTS = {
  IS_SUBSCRIBED_TO_SMS: 'isSubscribedToSMS',
  IS_SUBSCRIBED_TO_EMAIL: 'isSubscribedToEmail',
  WITH_PERSONAL_DETAILS: 'withPersonalDetails',
};

export const ALERTS_TARGET_MEDIAS = {
  SMS: 'SMS',
  EMAIL: 'EMAIL',
};

export const VS_METRICS = Object.freeze({
  HR: 'hr',
  RR: 'rr',
});

export const USER_TYPES = {
  SYSTEM_ADMIN: 'System admin',
  MULTI_TENANT_MANAGER: 'Multi-Tenant Manager',
  HEALTH_PROFESSIONAL: 'Health Professional',
  TENANT_ADMIN: 'Tenant admin',
  MULTI_TENANT_VIEWER: 'Multi-Tenant Viewer',
  TENANT_VIEWER: 'Tenant Viewer',
};

export const DEFAULT_PAGINATION = {
  LIMIT: 50,
  PAGE: 1,
};

export const EXTERNAL_LINKS = {
  PRIVACY_POLICY: 'https://www.neteera.com/privacy-policy',
};

export const DATA_STRATEGY = {
  MAP: 'map',
  CREATE: 'create',
  SKIP: 'skip',
  MAPPED: 'mapped',
};
export const LOCAL_STORAGE_ITEMS: QueryParamsType = Object.freeze({
  sound: 'SOUND_FILE',
  repetitions: 'REPETITIONS',
  delay: 'DELAY',
  statisticsFilter: 'STATISTICS_FILTER',
});
export const INFINITE_SCROLL_PAGINATION = 20;
