import theme from 'src/themes/theme';

export const getGraphOptions = isExtraLarge => ({
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 2,
  plugins: {
    tooltip: {
      enabled: false,
    },
    datalabels: {
      anchor: 'end',
      align: 'top',
      font: {
        weight: 'bold',
        size: isExtraLarge ? 11 : 7,
      },
      formatter: function (value, context) {
        if (
          context.dataset.label === 'trend_hours' ||
          context.dataset.label === 'trend_no_alerts'
        ) {
          return '';
        }
      },
    },
    legend: {
      labels: {
        usePointStyle: true,
        boxHeight: 100,
        font: {
          size: isExtraLarge ? 10 : 7,
          family: 'Inter',
        },
        filter: label =>
          label.text !== 'trend_hours' && label.text !== 'trend_no_alerts',
      },
      textAlign: 'right',
      align: 'end',
      fullSize: false,
      onClick: function (item, legendItem) {
        const index = legendItem.datasetIndex;
        const ci = this.chart;
        const metaCurrent = ci.getDatasetMeta(index);
        const alreadyHidden = metaCurrent.hidden || false;
        const metaNext = ci.getDatasetMeta(index + 1);

        if (metaCurrent.type !== 'bar') return;

        metaCurrent.hidden = !alreadyHidden ? !metaCurrent.hidden : null;
        metaNext.hidden = !alreadyHidden ? !metaNext.hidden : null;

        ci.update();
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: theme.colors.individualDashboardGraphAxisLabels,
        font: {
          size: isExtraLarge ? 11 : 7,
        },
      },
    },
    y: {
      ticks: {
        stepSize: 5,
        color: theme.colors.individualDashboardGraphAxisLabels,
        font: {
          size: isExtraLarge ? 11 : 7,
        },
      },
    },
  },
});

const getYSum = dataset => dataset.reduce((acc, item) => acc + item.value, 0);
const getXSum = dataset => dataset.reduce((acc, item, index) => acc + index, 0);
const getXYSum = dataset =>
  dataset.reduce((acc, item, index) => acc + index * item.value, 0);
const getX2Sum = dataset =>
  dataset.reduce((acc, item, index) => acc + index * index, 0);
const computeM = data =>
  (data.length * getXYSum(data) - getXSum(data) * getYSum(data)) /
  (data.length * getX2Sum(data) - getXSum(data) * getXSum(data));
const computeB = (data, m) =>
  getYSum(data) / data.length - (m * getXSum(data)) / data.length;

export const calculateTrendLineData = data => {
  const m = computeM(data);
  const b = computeB(data, m);

  return data.map((item, index) => {
    const y = index * m + b;
    // prevent trendline to go under the 0 y axis
    if (y < 0) {
      return 0;
    }
    return y;
  });
};
