import { createGlobalStyle } from 'styled-components';
import { media } from 'src/utils/mediaQueryUtils';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    overflow: auto;
    ${media.small`
      font-size: 10px;
    `}
    ${media.medium`
      font-size: 12px;
    `}
    ${media.large`
      font-size: 14px;
    `}
    ${media.extraLarge`
      font-size: 16px;
    `}
    ${media.extraExtraLarge`
      font-size: 20px;
    `}
  }

  body {
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 500;
    
    /* antd override styles*/
    .ant-input,
    .ant-select-selector,
    .ant-picker {
    :hover,
    :active {
      border-color: ${props => props.theme.colors.inputHoverBorder} !important;
    }
 
  * {
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;

      .ant-table-scroll-horizontal {
        width: auto;

      }
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${props => props.theme.colors.scrollBarThumbColor};
    }
    ::-webkit-scrollbar-track {
      background-color: ${props => props.theme.colors.scrollBarTrackColor};
    }

    scrollbar-color: ${props =>
      `${props.theme.colors.scrollBarThumbColor} ${props.theme.colors.scrollBarTrackColor}`};
    :not(.ant-table-scroll-horizontal) {
      scrollbar-width: thin;
    }
   }
  }

  #root {
    background-color: ${props => props.theme.colors.neutralLightest};
    height: 100%;
    min-width: 100%;
  }

  svg { vertical-align: baseline; }
`;

export default GlobalStyle;
