import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import {
  selectors as SMSEmailAlertsSelectors,
  actions as SMSEmailAlertsDataActions,
} from 'src/redux/data/SMSEmailAlerts';
import {
  actions as userActions,
  selectors as usersSelectors,
} from 'src/redux/data/user';
import dataActions from 'src/redux/data/dataActions';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { USER_TYPES } from 'src/utils/constants';

const mapStateToProps = (state: RootState) => {
  const usersList = usersSelectors.getUsersList(state);
  const hpUsers = usersList.filter(
    user => user.userType.name === USER_TYPES.HEALTH_PROFESSIONAL,
  );
  const hpUserIds = hpUsers.map(user => user.id);
  const allSubscribersPayload =
    SMSEmailAlertsSelectors.getUserSubscriptions(state);

  const hpSubscriptionsByTargetMedia = {
    SMS: {
      MEDICAL: allSubscribersPayload.SMS.MEDICAL.filter(userSubscription =>
        hpUserIds.includes(userSubscription.id),
      ),
      TECHNICAL: allSubscribersPayload.SMS.TECHNICAL.filter(userSubscription =>
        hpUserIds.includes(userSubscription.id),
      ),
      ACTIVITY: allSubscribersPayload.SMS.ACTIVITY.filter(userSubscription =>
        hpUserIds.includes(userSubscription.id),
      ),
    },
    EMAIL: {
      MEDICAL: allSubscribersPayload.EMAIL.MEDICAL.filter(userSubscription =>
        hpUserIds.includes(userSubscription.id),
      ),
      TECHNICAL: allSubscribersPayload.EMAIL.TECHNICAL.filter(
        userSubscription => hpUserIds.includes(userSubscription.id),
      ),
      ACTIVITY: allSubscribersPayload.EMAIL.ACTIVITY.filter(userSubscription =>
        hpUserIds.includes(userSubscription.id),
      ),
    },
  };
  const nonHpSubscriptionsByTargetMedia = {
    SMS: {
      MEDICAL: allSubscribersPayload.SMS.MEDICAL.filter(
        userSubscription => !hpUserIds.includes(userSubscription.id),
      ),
      TECHNICAL: allSubscribersPayload.SMS.TECHNICAL.filter(
        userSubscription => !hpUserIds.includes(userSubscription.id),
      ),
      ACTIVITY: allSubscribersPayload.SMS.ACTIVITY.filter(
        userSubscription => !hpUserIds.includes(userSubscription.id),
      ),
    },
    EMAIL: {
      MEDICAL: allSubscribersPayload.EMAIL.MEDICAL.filter(
        userSubscription => !hpUserIds.includes(userSubscription.id),
      ),
      TECHNICAL: allSubscribersPayload.EMAIL.TECHNICAL.filter(
        userSubscription => !hpUserIds.includes(userSubscription.id),
      ),
      ACTIVITY: allSubscribersPayload.EMAIL.ACTIVITY.filter(
        userSubscription => !hpUserIds.includes(userSubscription.id),
      ),
    },
  };

  return {
    hpUsers,
    hpSubscriptionsByTargetMedia,
    nonHpSubscriptionsByTargetMedia,
    isLoadingSMSEmailAlerts:
      SMSEmailAlertsSelectors.getStatus(state) === DATA_FETCHING_STATUS.LOADING,
  };
};

const mapActionCreators = {
  updateUserPhone: userActions.updateUserPhone,
  updateSubscribersList: SMSEmailAlertsDataActions.updateSubscribersList,
  onLoadSMSEmailAlert: dataActions.onLoadSMSEmailAlert,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
