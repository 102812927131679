import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Tooltip } from 'antd';

import Icon from 'src/components/general-ui/Icon';
import { SubtenantSettingItem } from '../styled';
import GMAlertSettingsModal from './GMAlertSettingsModal';
import { ToolTipMessages } from 'src/translations/messages/toolTipMessages';

export const StyledSettingsIcon = styled(Icon)`
  width: 0.75rem;
  height: 0.8rem;
  margin-right: 0.4rem;
`;

const AlertSettings = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  return (
    <>
      <Tooltip title={<FormattedMessage {...ToolTipMessages.alertSettings} />}>
        <SubtenantSettingItem onClick={() => setIsModalVisible(true)}>
          <StyledSettingsIcon type="settings-icon" />
          <FormattedMessage {...messages.alertSettings} />
        </SubtenantSettingItem>
      </Tooltip>
      {isModalVisible && (
        <GMAlertSettingsModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </>
  );
};

const messages = defineMessages({
  alertSettings: {
    defaultMessage: 'Alert Settings',
  },
});

export default AlertSettings;
