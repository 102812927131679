import React, { useEffect, useState } from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { Checkbox } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { Searchbar } from 'src/components/styled';
import {
  StyledSpacedRow,
  StyledTableContainer,
} from 'src/components/CrudCommonComponents/styled';
import EditableCell from 'src/components/CrudCommonComponents/EditableCell';
import { CustomTooltip } from 'src/components/CustomTooltip';
import EditableRow from 'src/components/CrudCommonComponents/EditableRow';
import { SMS_EMAIL_ALERTS, phonePattern } from 'src/utils/constants';
import { ActiveUserWithStatus } from 'src/redux/data/user/modules/types';
import { TableColumnConfig } from 'src/types/table';
import { UUID } from 'src/types/utility';
import { UserTableItem } from './tableConfig';
import {
  StyledTable,
  StyledCheckbox,
  StyledActionButton,
  StyledColumnHeader,
} from './styled';
import {
  AlertCategory,
  UserMedicalSubscription,
  UserTechnicalSubscription,
} from 'src/types/subscribers';
import permissions from 'src/permissions';
import AccessControl from 'src/components/AccessControl';

type Props = {
  data: ActiveUserWithStatus[];
  columns: TableColumnConfig<UserTableItem>[];
  loading: boolean;
  applyChanges: () => void;
  updateCell: (userId: UUID, phone: string) => void;
  handleAssignmentToSMS: (
    category: AlertCategory,
  ) => (userId: UUID | null, checked: boolean) => void;
  handleAssignmentToEmail: (
    category: AlertCategory,
  ) => (userId: UUID | null, checked: boolean) => void;
  handleEmailPersonalDetailsUpdate: (userId: UUID, checked: boolean) => void;
  usersAssignedToMedicalSMS: UserMedicalSubscription[];
  usersAssignedToMedicalEmail: UserMedicalSubscription[];
  usersAssignedToTechnicalSMS: UserTechnicalSubscription[];
  usersAssignedToTechnicalEmail: UserTechnicalSubscription[];
  changesStatus: boolean;
  intl: IntlShape;
};

const SubscribersTable = ({
  data,
  columns,
  loading,
  applyChanges,
  updateCell,
  handleAssignmentToSMS,
  handleAssignmentToEmail,
  handleEmailPersonalDetailsUpdate,
  usersAssignedToMedicalSMS,
  usersAssignedToMedicalEmail,
  usersAssignedToTechnicalSMS,
  usersAssignedToTechnicalEmail,
  changesStatus,
  intl,
}: Props) => {
  const [filteredValue, setFilteredValue] = useState<string>();
  const [displayedColumns, setDisplayedColumns] =
    useState<ColumnsType<UserTableItem>>(columns);
  const EditableContext = React.createContext(null);

  useEffect(() => {
    const columnsWithCustom: ColumnsType<UserTableItem> = [
      ...columns,
      {
        title: intl.formatMessage(messages.medicalAlerts),
        children: [
          {
            key: SMS_EMAIL_ALERTS.IS_SUBSCRIBED_TO_SMS,
            width: '100px',
            title: (
              <StyledColumnHeader>
                <StyledCheckbox
                  onChange={e =>
                    handleAssignmentToSMS('MEDICAL')(null, e.target.checked)
                  }
                  checked={
                    usersAssignedToMedicalSMS.length ===
                    data.filter(user => !!user.phone).length
                  }
                />
                {intl.formatMessage(messages.assignedToSMS)}
              </StyledColumnHeader>
            ),
            render: (_, record) => (
              // @ts-ignore Refactor later
              <CustomTooltip
                title={
                  !record.phone
                    ? intl.formatMessage(messages.invalidPhoneNumber)
                    : null
                }
              >
                <Checkbox
                  key={record.id}
                  onChange={e =>
                    handleAssignmentToSMS('MEDICAL')(
                      record.id,
                      e.target.checked,
                    )
                  }
                  checked={usersAssignedToMedicalSMS.some(
                    userSubscription => userSubscription.id === record.id,
                  )}
                  disabled={!record.phone}
                />
              </CustomTooltip>
            ),
          },
          {
            key: SMS_EMAIL_ALERTS.IS_SUBSCRIBED_TO_EMAIL,
            width: '100px',
            title: (
              <StyledColumnHeader>
                <StyledCheckbox
                  onChange={e =>
                    handleAssignmentToEmail('MEDICAL')(null, e.target.checked)
                  }
                  checked={usersAssignedToMedicalEmail.length === data.length}
                />
                {intl.formatMessage(messages.assignedToEmail)}
              </StyledColumnHeader>
            ),
            render: (_, record) => (
              // @ts-ignore Refactor later
              <CustomTooltip title={null}>
                <Checkbox
                  key={record.id}
                  onChange={e =>
                    handleAssignmentToEmail('MEDICAL')(
                      record.id,
                      e.target.checked,
                    )
                  }
                  checked={usersAssignedToMedicalEmail.some(
                    userSubscription => userSubscription.id === record.id,
                  )}
                />
              </CustomTooltip>
            ),
          },
          {
            key: SMS_EMAIL_ALERTS.WITH_PERSONAL_DETAILS,
            width: '200px',
            title: (
              <StyledColumnHeader>
                {intl.formatMessage(messages.withPersonalDetails)}
              </StyledColumnHeader>
            ),
            render: (_, record) => (
              // @ts-ignore Refactor later
              <CustomTooltip title={null}>
                <Checkbox
                  key={record.id}
                  onChange={e =>
                    handleEmailPersonalDetailsUpdate(
                      record.id,
                      e.target.checked,
                    )
                  }
                  disabled={
                    !usersAssignedToMedicalEmail.some(
                      userSubscription => userSubscription.id === record.id,
                    )
                  }
                  checked={usersAssignedToMedicalEmail.some(
                    userSubscription =>
                      userSubscription.id === record.id &&
                      userSubscription.withPersonalDetails,
                  )}
                />
              </CustomTooltip>
            ),
          },
        ],
      },
      {
        title: intl.formatMessage(messages.technicalAlerts),
        children: [
          {
            key: SMS_EMAIL_ALERTS.IS_SUBSCRIBED_TO_SMS,
            width: '100px',
            title: (
              <StyledColumnHeader>
                <StyledCheckbox
                  onChange={e =>
                    handleAssignmentToSMS('TECHNICAL')(null, e.target.checked)
                  }
                  checked={
                    usersAssignedToTechnicalSMS.length ===
                    data.filter(user => !!user.phone).length
                  }
                />
                {intl.formatMessage(messages.assignedToSMS)}
              </StyledColumnHeader>
            ),
            render: (_, record) => (
              // @ts-ignore Refactor later
              <CustomTooltip
                title={
                  !record.phone
                    ? intl.formatMessage(messages.invalidPhoneNumber)
                    : null
                }
              >
                <Checkbox
                  key={record.id}
                  onChange={e =>
                    handleAssignmentToSMS('TECHNICAL')(
                      record.id,
                      e.target.checked,
                    )
                  }
                  checked={usersAssignedToTechnicalSMS.some(
                    userSubscription => userSubscription.id === record.id,
                  )}
                  disabled={!record.phone}
                />
              </CustomTooltip>
            ),
          },
          {
            key: SMS_EMAIL_ALERTS.IS_SUBSCRIBED_TO_EMAIL,
            width: '100px',
            title: (
              <StyledColumnHeader>
                <StyledCheckbox
                  onChange={e =>
                    handleAssignmentToEmail('TECHNICAL')(null, e.target.checked)
                  }
                  checked={usersAssignedToTechnicalEmail.length === data.length}
                />
                {intl.formatMessage(messages.assignedToEmail)}
              </StyledColumnHeader>
            ),
            render: (_, record) => (
              // @ts-ignore Refactor later
              <CustomTooltip title={null}>
                <Checkbox
                  key={record.id}
                  onChange={e =>
                    handleAssignmentToEmail('TECHNICAL')(
                      record.id,
                      e.target.checked,
                    )
                  }
                  checked={usersAssignedToTechnicalEmail.some(
                    userSubscription => userSubscription.id === record.id,
                  )}
                />
              </CustomTooltip>
            ),
          },
        ],
      },
    ];

    setDisplayedColumns(columnsWithCustom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  useEffect(() => {
    setDisplayedColumns(mapFilteredValueToColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredValue]);

  const mapFilteredValueToColumns = (columns: ColumnsType<UserTableItem>) =>
    columns.map(column =>
      column.filtered
        ? { ...column, filteredValue: [filteredValue || ''] }
        : column,
    );

  const components = {
    body: {
      row: (props: { index: string }) => (
        // @ts-ignore Refactor later
        <EditableRow EditableContext={EditableContext} {...props} />
      ),
      cell: (props: { record: UserTableItem }) => (
        <EditableCell
          EditableContext={EditableContext}
          updateElement={updateCell}
          {...props}
          cellName="phone"
          validatePattern={phonePattern}
          isRequired={[
            ...usersAssignedToMedicalSMS,
            ...usersAssignedToTechnicalSMS,
          ].some(userSubscription => userSubscription.id === props.record?.id)}
        />
      ),
    },
  };

  return (
    <StyledTableContainer>
      <StyledSpacedRow>
        <Searchbar
          width="18rem"
          placeholder={intl.formatMessage(messages.searchPlaceholder)}
          onChange={event => setFilteredValue(event.target.value)}
          value={filteredValue}
        />
      </StyledSpacedRow>
      <br />
      <StyledTable
        components={components}
        rowClassName="editable-row"
        columns={displayedColumns}
        dataSource={data}
        pagination={false}
        scroll={{ x: 'max-content', y: '35vh' }}
        tableLayout="auto"
        loading={loading}
      />
      <StyledSpacedRow>
        <AccessControl permissions={[permissions.ALERT_SUBSCRIBER_CREATE]}>
          <StyledActionButton onClick={applyChanges} disabled={!changesStatus}>
            {intl.formatMessage(messages.apply)}
          </StyledActionButton>
        </AccessControl>
      </StyledSpacedRow>
    </StyledTableContainer>
  );
};

const messages = defineMessages({
  apply: {
    defaultMessage: 'Apply',
  },
  assignedToSMS: {
    defaultMessage: 'SMS Alert',
  },
  assignedToEmail: {
    defaultMessage: 'E-mail Alert',
  },
  invalidPhoneNumber: {
    defaultMessage: 'Invalid phone number',
  },
  searchPlaceholder: {
    defaultMessage: 'Search by name or phone number',
  },
  medicalAlerts: {
    defaultMessage: 'Medical Alerts',
  },
  technicalAlerts: {
    defaultMessage: 'Technical Alerts',
  },
  withPersonalDetails: {
    defaultMessage: "With patient's personal details",
  },
});

export default injectIntl(SubscribersTable);
