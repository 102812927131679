import { Input, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  injectIntl,
  defineMessages,
  IntlShape,
  FormattedMessage,
} from 'react-intl';
import { PlusCircleTwoTone } from '@ant-design/icons';

import Connector, { PropsFromRedux } from './Connector';
import { CustomerFormWrapper, StyledDropdown, StyledTable } from './style';
import { getIntegratedEMRsColumns } from './utils';
import EditableCell from 'src/components/CrudCommonComponents/EditableCell';
import EditableRow from 'src/components/CrudCommonComponents/EditableRow';
import { UUID } from 'src/types/utility';
import SelectMtmUserBox from './components/SelectMtmUserBox';
import { IntegratedEmrsType } from 'src/routes/Customers/modules/types';

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const CustomerForm = ({
  intl,
  handleChangeForm,
  editableData,
  fetchMtmUsers,
  mtmUsers,
  isLoadingMtmUsers,
}: Props) => {
  const columns = getIntegratedEMRsColumns(intl);
  const EditableContext = React.createContext(null);

  const [tableData, setTableData] = useState<IntegratedEmrsType[]>([]);

  useEffect(() => {
    if (editableData) {
      //@ts-ignore: to fix type with hardcoded integrationType field
      setTableData(editableData?.emrMappings);
    }
    fetchMtmUsers();
  }, [editableData, fetchMtmUsers]);

  const updateCell = (_: unknown, organizationId: UUID) => {
    handleChangeForm({
      emrMappings: [
        {
          emrType: 'PCC',
          organizationId,
        },
      ],
    });
  };

  const items: MenuProps['items'] = [
    // integrated EMRS will be hardcoded for now
    {
      key: '1',
      label: 'PCC',
      onClick: () => {
        setTableData([
          {
            emrType: 'PCC',
            organizationId: '',
            integrationType: 'Full',
          },
        ]);
      },
    },
  ];

  const tableEditableComponents = {
    body: {
      row: (props: unknown) => (
        // @ts-ignore createContext type
        <EditableRow EditableContext={EditableContext} {...props} />
      ),
      cell: (props: unknown) => (
        <EditableCell
          EditableContext={EditableContext}
          updateElement={updateCell}
          cellName="organizationId"
          // validatePattern={patternUUID}
          // @ts-ignore determine props type shape
          {...props}
        />
      ),
    },
  };

  return (
    <CustomerFormWrapper>
      <FormattedMessage {...messages.customerName} />
      <Input
        name="name"
        value={editableData.name}
        placeholder={intl.formatMessage(messages.customerName)}
        onChange={e => handleChangeForm({ name: e.target.value })}
      />
      <FormattedMessage {...messages.defaultUser} />
      <SelectMtmUserBox
        mtmUsers={mtmUsers}
        loading={isLoadingMtmUsers}
        contactId={editableData.contactId}
        handleChangeForm={handleChangeForm}
        placeholder={intl.formatMessage(messages.chooseCustomerDefaultUser)}
        editableData={{
          name: undefined,
          contactId: undefined,
          emrMappings: undefined,
        }}
      />
      <FormattedMessage {...messages.integratedEMRs} />
      <StyledDropdown menu={{ items }} trigger={['click']}>
        <PlusCircleTwoTone twoToneColor={'#87b7c5'} />
      </StyledDropdown>
      <StyledTable
        data={tableData}
        columns={columns}
        components={tableEditableComponents}
      />
    </CustomerFormWrapper>
  );
};

const messages = defineMessages({
  customerName: {
    defaultMessage: 'Customer Name',
  },
  defaultUser: {
    defaultMessage: 'Default user',
  },
  chooseCustomerDefaultUser: {
    defaultMessage: "Choose Customer's default user",
  },
  integratedEMRs: {
    defaultMessage: 'Integrated EMRs',
  },
});

export default Connector(injectIntl(CustomerForm));
