import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { notification, Spin } from 'antd';

import Box from 'src/components/Box';
import {
  ContentWrapper,
  FooterButtonsArea,
  StyledRightSideContinuousContent,
} from './styled';
import ContinuousContent from '../containers/continuousContainer';
import Header from '../containers/headerContainer';
import IndicationNotification from './IndicationNotification';
import StopConfirmationModal from '../containers/stopConfirmationModalContainer';
import AppConfig from '../../../config/AppConfig';
import ActionButton from 'src/components/general-ui/Buttons/ActionButton';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import history from 'src/utils/history';
import AverageValues from './AverageValues';
import { DEFAULT_HISTOGRAM_DATA_STATE } from '../modules/utils';
import { Row } from 'src/components/styled';
import { DEFAULT_LOCALE } from 'src/utils/languageUtils';

const MonitorPage = props => {
  const {
    match,
    intl,
    patientsList,
    monitorPageMounted,
    monitorPageUnmounted,
    startModePatientId,
    selectedDevice,
    deviceNameById,
    getHistogramData,
    patientId,
    setPatientId,
    isActive,
    toggleRawData,
    saveRawData,
    histogramData,
    getThresholdsList,
    getRoomsList,
    roomsList,
    thresholds,
    fetchPatientAlerts,
  } = props;
  const {
    params: { id },
  } = match;
  const [lastPatientId, setLastPatientId] = useState(patientId);

  useEffect(() => {
    getThresholdsList();
    getRoomsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    patientId && setLastPatientId(patientId);
  }, [patientId]);
  useEffect(() => {
    if (lastPatientId) {
      getHistogramData(lastPatientId);
      fetchPatientAlerts({ patientId: lastPatientId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPatientId]);

  useEffect(() => {
    deviceNameById(id);
  }, [id, deviceNameById]);
  useEffect(() => {
    if (selectedDevice && !selectedDevice?.manufacturerId) {
      notification.warning({
        message: intl.formatMessage(messages.deviceNotExistsNotification),
        description: '',
        placement: 'bottomRight',
      });
    }
  }, [selectedDevice, intl]);
  useEffect(() => {
    if (selectedDevice?.manufacturerId) {
      monitorPageMounted({ deviceIds: [selectedDevice?.manufacturerId] });
    }
    return () => {
      if (selectedDevice?.manufacturerId) {
        monitorPageUnmounted();
      }
    };
  }, [monitorPageMounted, monitorPageUnmounted, selectedDevice]);
  useEffect(() => {
    if (startModePatientId) {
      setPatientId({
        selectedPatientId: startModePatientId,
        selectedDevice: selectedDevice?.manufacturerId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startModePatientId, setPatientId]);

  const patientName = patientsList.find(
    patient => patient.id === patientId || patient.id === lastPatientId,
  )?.fullName;
  const room = roomsList.find(room =>
    room.beds?.find(bed => bed.deviceId === selectedDevice?.manufacturerId),
  );
  const bed = room?.beds?.find(
    bed => bed.deviceId === selectedDevice?.manufacturerId,
  );
  return (
    <>
      {selectedDevice?.manufacturerId ? (
        <>
          <Header
            deviceId={id}
            deviceName={selectedDevice?.name}
            patientName={patientName}
            room={room?.name}
            bed={bed?.name}
            patientsList={patientsList}
            selectedPatient={patientId}
            previouslySelectedPatient={lastPatientId}
            setSelectedPatient={selectedPatientId =>
              setPatientId({
                selectedPatientId,
                selectedDevice: selectedDevice?.manufacturerId,
              })
            }
          />
          <IndicationNotification
            message={intl.formatMessage(messages.patientIndicationTitle)}
            description={intl.formatMessage(
              messages.patientIndicationDescription,
            )}
            hriMessage={
              AppConfig.HRI_ENABLED
                ? intl.formatMessage(messages.hriMessage)
                : null
            }
            isActive={
              isActive &&
              !!patientsList.filter(patient => patient.id === patientId)[0]
            }
            selectedPatient={
              patientsList.filter(patient => patient.id === patientId)[0]
            }
          />
          <StopConfirmationModal />
          <ContentWrapper>
            <Row>
              <StyledRightSideContinuousContent>
                <ContinuousContent
                  patientId={lastPatientId}
                  device={selectedDevice}
                  saveRawData={saveRawData}
                  toggleRawData={() => toggleRawData(!saveRawData)}
                />
              </StyledRightSideContinuousContent>
              <AverageValues
                hrData={histogramData.HR}
                rrData={histogramData.RR}
                thresholds={thresholds}
              />
            </Row>
            <FooterButtonsArea>
              <ActionButton
                disabled={!lastPatientId}
                onClick={() =>
                  history.push({
                    pathname: `/${DEFAULT_LOCALE}/${appRoutes.STATISTICS}`,
                    state: { previousPatientId: lastPatientId },
                  })
                }
              >
                {intl.formatMessage(messages.personalStatistics)}
              </ActionButton>
            </FooterButtonsArea>
          </ContentWrapper>
        </>
      ) : (
        <Box centered>
          <Spin spinning size="medium" />
        </Box>
      )}
    </>
  );
};

MonitorPage.propTypes = {
  match: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  patientsList: PropTypes.array,
  monitorPageMounted: PropTypes.func.isRequired,
  monitorPageUnmounted: PropTypes.func.isRequired,
  startModePatientId: PropTypes.string,
  selectedDevice: PropTypes.object,
  deviceNameById: PropTypes.func.isRequired,
  getHistogramData: PropTypes.func.isRequired,
  patientId: PropTypes.string,
  setPatientId: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  toggleRawData: PropTypes.func.isRequired,
  saveRawData: PropTypes.bool.isRequired,
  histogramData: PropTypes.object.isRequired,
  getThresholdsList: PropTypes.func.isRequired,
  getRoomsList: PropTypes.func.isRequired,
  thresholds: PropTypes.array,
  roomsList: PropTypes.array,
  fetchPatientAlerts: PropTypes.func.isRequired,
};

MonitorPage.defaultProps = {
  patientsList: [],
  startModePatientId: null,
  selectedDevice: null,
  patientId: '',
  histogramData: {
    RR: {
      lastDay: DEFAULT_HISTOGRAM_DATA_STATE,
      lastThreeDays: DEFAULT_HISTOGRAM_DATA_STATE,
      lastWeek: DEFAULT_HISTOGRAM_DATA_STATE,
    },
    HR: {
      lastDay: DEFAULT_HISTOGRAM_DATA_STATE,
      lastThreeDays: DEFAULT_HISTOGRAM_DATA_STATE,
      lastWeek: DEFAULT_HISTOGRAM_DATA_STATE,
    },
  },
  thresholds: [],
  roomsList: [],
};

const messages = defineMessages({
  initializing: {
    defaultMessage: 'Checking device status...',
  },
  deviceNotExistsNotification: {
    defaultMessage: 'There is no such device',
  },
  patientIndicationTitle: {
    defaultMessage: 'Active Monitor',
  },
  patientIndicationDescription: {
    defaultMessage: 'Monitor started for:',
  },
  hriMessage: {
    defaultMessage:
      'In order to get HRI data the session must continue for at least 5 minutes',
  },
  personalStatistics: {
    defaultMessage: 'Personal Statistics',
  },
});

export default injectIntl(MonitorPage);
