import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';

import theme from 'src/themes/theme';
import { BarWrapper } from './styled';
import { roundToNDecimals } from 'src/utils/dataUtils';
import { useWindowSize } from 'src/utils/hooks/useWindowSize';
import { BREAKPOINT_SIZES } from 'src/utils/mediaQueryUtils';
import { DisplayHistogramGraphData } from 'src/routes/IndividualDashboard/modules/dashboard/histogram-processor';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const formatBarLabelWithResolution = (
  value: number | undefined,
  obj: { dataset: { type: string } },
) => {
  if (obj.dataset.type === 'line' || !value) {
    return null;
  }
  if (value <= 0) {
    return `${0}%`;
  }

  return `${roundToNDecimals(value, 1)}%`;
};

const getGraphOptions = (isExtraLarge: boolean) => ({
  layout: {
    padding: {
      top: 5,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 2,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      anchor: 'end',
      align: 'top',
      offset: -3,
      formatter: formatBarLabelWithResolution,
      font: {
        weight: 'bold',
        size: isExtraLarge ? 10 : 7,
      },
    },
  },
  scales: {
    xAxes: {
      grid: {
        display: false,
        drawBorder: false,
        drawOnChartArea: false,
      },
      ticks: {
        display: true,
        font: {
          size: isExtraLarge ? 10 : 5,
        },
        padding: 0,
      },
    },
    yAxes: {
      display: true,
      ticks: {
        display: false,
        stepSize: 20,
      },
      grid: {
        drawBorder: false,
      },
    },
  },
});

type Props = {
  lastDayData: DisplayHistogramGraphData[];
  lastWeekData: DisplayHistogramGraphData[];
  intl: IntlShape;
};

const MetricHistogramGraph = ({
  intl,
  lastDayData,
  lastWeekData,
}: Props): JSX.Element => {
  const [width] = useWindowSize();

  const barData = {
    labels: lastWeekData.map(d => d.id),
    datasets: [
      {
        type: 'line',
        data: lastWeekData.map(d => d.value),
        backgroundColor: theme.colors.hrRrHistogramLineColor,
        borderColor: theme.colors.hrRrHistogramLineColor,
        borderDash: [4, 5],
        borderWidth: 1,
        pointRadius: 0,
        label: intl.formatMessage(messages.last7days),
        tension: 0.5,
        pointStyle: 'line',
      },
      {
        type: 'bar',
        data: lastDayData.map(d => d.value),
        backgroundColor: [
          theme.colors.hrRrHistogramBarColor1,
          theme.colors.hrRrHistogramBarColor2,
          theme.colors.hrRrHistogramBarColor3,
          theme.colors.hrRrHistogramBarColor2,
          theme.colors.hrRrHistogramBarColor1,
        ],
        label: intl.formatMessage(messages.last24hours),
        pointStyle: 'rect',
      },
    ],
  };

  return (
    <BarWrapper>
      <Bar
        options={getGraphOptions(width > BREAKPOINT_SIZES.XL)}
        data={barData}
        plugins={[ChartDataLabels]}
        type={undefined}
      />
    </BarWrapper>
  );
};

const messages = defineMessages({
  last24hours: {
    defaultMessage: 'Last 24 hours',
  },
  last7days: {
    defaultMessage: 'Last 7 days',
  },
});

export default injectIntl(MetricHistogramGraph);
