import { connect } from 'react-redux';
import {
  selectors as appSelectors,
  actions as appActions,
} from 'src/redux/data/app/modules/slice';
import { selectors as loginSelectors } from 'src/redux/data/auth/modules/slice';
import permissions from 'src/permissions';
import {
  hasAnyPermissions,
  hasGMPermissions,
  hasHPPermissions,
  hasImpersonatedHPPermissions,
  hasSAPermissions,
  hasTAPermissions,
} from 'src/utils/permissions';
import { apiStatusEnum } from 'src/utils/constants';
import Component from '../components/Component';

const mapStateToProps = state => ({
  isAppLoading: appSelectors.getIsAppLoading(state),
  isLoggedIn: loginSelectors.getLoginStatus(state) === apiStatusEnum.OK,
  devicesPremission:
    hasAnyPermissions(permissions.VIEW_SETTINGS_SIDE_MENU_BUTTON) &&
    !hasGMPermissions(),
  monitorPremission:
    hasAnyPermissions(permissions.VIEW_DEVICES_MONITOR_ACTION) &&
    !hasGMPermissions(),
  tenantsPremission: hasSAPermissions(),
  customersPremission: hasSAPermissions(),
  patientsPremission: hasTAPermissions(),
  usersPremission: hasTAPermissions() || hasSAPermissions(),
  // FIXME: Change permission to firmwareManager permission
  firmwareManagerPermission: hasSAPermissions(),
  managmentPermission: hasTAPermissions() || hasSAPermissions(),
  medicalInfoPermissions: hasHPPermissions() || hasImpersonatedHPPermissions(),
  individualDashboardPermissions:
    hasHPPermissions() || hasImpersonatedHPPermissions(),
  settingsPermission: hasTAPermissions() || hasSAPermissions(),
  gmPermissions: hasGMPermissions(),
  gmUserPermissions:
    hasGMPermissions() &&
    hasAnyPermissions(permissions.ORGANIZATION_INVITATIONS_CREATE),
});

const mapActionCreators = {
  onAppStart: appActions.appStart,
};

export default connect(mapStateToProps, mapActionCreators)(Component);
