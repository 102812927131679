import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import {
  actions as individualDashboardActions,
  selectors as individualDashboardSelectors,
} from 'src/routes/IndividualDashboard/modules/dashboard/slice';
import { selectors as tenantSelectors } from 'src/redux/data/tenant';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { API_STATUS } from 'src/utils/api-constants';
import { getTimezoneId } from 'src/utils/timeUtils';
import { correctAlertLogTabelItemTimestamps } from 'src/redux/data/alerts/modules/utils';

const mapStateToProps = (state: RootState) => {
  const tenantId = individualDashboardSelectors
    .selectAlertsLogForModal(state)
    .map(({ tenantId }) => tenantId)[0];

  const tenant = tenantSelectors.getTenant(state, tenantId ?? '');
  const timezone = tenant
    ? getTimezoneId(tenant.timeZoneId)
    : loggedInUserSelectors.getUserTenantTimezone(state);

  const alertLogData = individualDashboardSelectors
    .selectAlertsLogForModal(state)
    .map(alertLog => correctAlertLogTabelItemTimestamps(alertLog, timezone));
  return {
    patient: individualDashboardSelectors.getPatientDetails(state),
    alertsLogData: alertLogData,
    alertLogMetadata:
      individualDashboardSelectors.selectAlertsLogMetadataForModal(state),
    isLoading:
      individualDashboardSelectors.selectAlertsLogStatusForModal(state) ===
      API_STATUS.LOADING,
  };
};

const mapActionCreators = {
  getAlertsLogData: individualDashboardActions.getAlertsLogDataForModal,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;

export default Connector;
