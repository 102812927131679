import { fromEquals } from 'fp-ts/lib/Eq';
import { Magma } from 'fp-ts/lib/Magma';

import { UUID } from 'src/types/utility';

export type EntityWithUUID = { id: UUID };

export const equalsEntity = <T extends EntityWithUUID>() =>
  fromEquals<T>((a, b) => a.id === b.id);

export const objectMagma = <T>(): Magma<T> => ({
  concat: (existingData, newData) => ({
    ...existingData,
    ...newData,
  }),
});

export type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;

export function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noOp = () => {};
