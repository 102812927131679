import { styled } from 'styled-components';
import { ListWrapper } from 'src/components/Sidebars/styled';
import { SortItem } from 'src/components/general-ui/Sort';

export const FieldsContainer = styled.div`
  padding-right: 0.7rem;
`;
export const ListWrapperContainer = styled(ListWrapper)`
  height: 100%;
`;
export const SortItemWrapper = styled(SortItem)`
  cursor: auto;
`;
