import React from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';
import { Typography } from 'antd';

import {
  CardWrapper,
  RoomDetails,
  PatientName,
  StyledTenantName,
  BoldItem,
  DisconnectionAlertInfo,
  AlertNotesWrapper,
  ItemLabel,
} from './styled';
import { AttentionListItem } from '../../AlertSidebarGroupManager/modules/types';
import AlertCounter from './AlertCounter';
import AlertNotes from '../AlertNotes';
import AlertTooltip from '../AlertLogCard/AlertTooltip';
import { getTooltipMessage } from '../AlertLogCard/utils';
import AlertIndicator from 'src/components/AlertIndicator';

const { Paragraph } = Typography;

export type Props = AttentionListItem & {
  onPatientNameClick: () => void;
  intl: IntlShape;
};

const AttentionListCard = (props: Props): JSX.Element | null => {
  const {
    type,
    deviceId,
    patientId,
    tenantId,
    notesCount,
    tenantName,
    deviceRoomName,
    deviceBedName,
    patientFirstName,
    patientLastName,
    alertCounter,
    onPatientNameClick,
    intl,
  } = props;

  const displayRoomName = deviceRoomName || ' N/A';
  const displayBedName = deviceBedName || ' N/A';

  if (type === 'MASS_DISCONNECTION') {
    const { alert, tenant } = props;

    return (
      <AlertTooltip
        // @ts-ignore FIX ME LATER
        title={getTooltipMessage(alert, tenant, intl)}
        placement="top"
      >
        <CardWrapper>
          <StyledTenantName ellipsis={{ rows: 2, tooltip: tenantName }}>
            {tenantName}
          </StyledTenantName>
          <AlertIndicator {...props.alert} />
          <DisconnectionAlertInfo>
            <FormattedMessage {...messages.allDevicesDisconnectedInfo} />
          </DisconnectionAlertInfo>
        </CardWrapper>
      </AlertTooltip>
    );
  }

  return (
    <CardWrapper>
      <StyledTenantName ellipsis={{ rows: 2, tooltip: tenantName }}>
        {tenantName}
      </StyledTenantName>
      <RoomDetails>
        <Paragraph
          ellipsis={{
            rows: 1,
            tooltip: displayRoomName,
          }}
        >
          <BoldItem>{displayRoomName}</BoldItem>
        </Paragraph>

        <Paragraph
          ellipsis={{
            rows: 1,
            tooltip: displayBedName,
          }}
        >
          <ItemLabel>
            <FormattedMessage {...messages.bed} />
          </ItemLabel>

          <BoldItem>{displayBedName}</BoldItem>
        </Paragraph>
      </RoomDetails>

      <AlertCounter counter={alertCounter} />

      <PatientName onClick={onPatientNameClick}>
        <Paragraph
          ellipsis={{
            rows: 2,
            tooltip: `${patientFirstName || ''} ${patientLastName || ''}`,
          }}
        >
          {`${patientFirstName || ''} ${patientLastName || ''}`}
        </Paragraph>
      </PatientName>

      <AlertNotesWrapper>
        <AlertNotes
          counterMetadata={notesCount}
          patientId={patientId}
          deviceId={deviceId}
          tenantId={tenantId}
        />
      </AlertNotesWrapper>
    </CardWrapper>
  );
};

export const messages = defineMessages({
  room: {
    defaultMessage: 'R',
  },
  bed: {
    defaultMessage: 'Bed',
  },
  allDevicesDisconnectedInfo: {
    defaultMessage: 'All devices are disconnected',
  },
});

export default injectIntl(AttentionListCard);
