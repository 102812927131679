import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { Form, Select } from 'antd';
import Modal from 'src/components/Modal';

const AssignDeviceModal = ({
  isModalVisible,
  setIsModalVisible,
  onAssign,
  tenantOptions,
  intl,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (isModalVisible) {
      form.resetFields();
    }
  }, [isModalVisible, form]);

  return (
    <Modal
      title={intl.formatMessage(messages.assignDevice)}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      okButtonText={intl.formatMessage(messages.assign)}
      onOk={() => {
        form.submit();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={values => {
          onAssign(values);
        }}
      >
        <Form.Item
          name="tenantSelect"
          label={intl.formatMessage(messages.chooseTenantLabel)}
          rules={[{ required: true }]}
        >
          <Select
            options={tenantOptions}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            placeholder={intl.formatMessage(messages.search)}
            allowClear={true}
            showSearch={true}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const messages = defineMessages({
  assignDevice: {
    defaultMessage: 'Assign Device',
  },
  assign: {
    defaultMessage: 'Assign',
  },
  chooseTenantLabel: {
    defaultMessage: 'Choose Tenant',
  },
  search: {
    defaultMessage: 'Search tenant',
  },
});

AssignDeviceModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
  tenantOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(AssignDeviceModal);
