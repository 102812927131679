import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Form, Radio, Space } from 'antd';

import Connector from '../Connector';
import Modal from 'src/components/Modal';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import SelectVersion from './SelectVersion';
import { RadioGroupWrap } from './updateVersionModal.styled';
import { DEVICE_TYPE } from '../../Versions/constants';
import { GROUP_UPDATE_VERSION_FIELDS, RADIO_BUTTONS_VALUES } from './constants';
import { InfoFieldsWrap } from 'src/components/styled';

const UpdateVersionModal = ({
  isModalVisible,
  setIsModalVisible,
  onOk,
  modalStatus,
  setModalStatus,
  intl,
  selectedGroup,
  onLoadLatestVersions,
  latestVersions,
  onLoadVersionsByDeviceType,
  versionsByDeviceType_130H,
  versionsByDeviceType_130H_Plus,
  isLoadingVersionsByDeviceType,
}) => {
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState(
    RADIO_BUTTONS_VALUES.LATEST_VERSION,
  );

  useEffect(() => {
    if (isModalVisible) {
      onLoadLatestVersions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible, selectedGroup]);

  useEffect(() => {
    if (isModalVisible && modalStatus === MODAL_STATUS.INITIAL) {
      form.resetFields();
    }
  }, [isModalVisible, modalStatus, form]);

  useEffect(() => {
    if (radioValue === RADIO_BUTTONS_VALUES.LATEST_VERSION) {
      const latestVersion_device_130H = latestVersions?.find(
        version => version.deviceType === DEVICE_TYPE.DEVICE_130H,
      )?.id;
      const latestVersion_device_130H_PLUS = latestVersions?.find(
        version => version.deviceType === DEVICE_TYPE.DEVICE_130H_PLUS,
      )?.id;

      form.setFieldsValue({
        versionId: latestVersion_device_130H,
        versionPlusId: latestVersion_device_130H_PLUS,
      });
      return;
    }

    if (radioValue === RADIO_BUTTONS_VALUES.OTHER) {
      form.setFieldsValue({
        versionId: selectedGroup.versionId || null,
        versionPlusId: selectedGroup.versionPlusId || null,
      });
      return;
    }
  }, [latestVersions, radioValue, form, selectedGroup]);

  return (
    <Modal
      title={intl.formatMessage(messages.title)}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      okButtonText={intl.formatMessage(messages.okButtonText)}
      onOk={() => {
        setModalStatus(MODAL_STATUS.SUBMITTING);
        form.submit();
      }}
      modalStatus={modalStatus}
      setModalStatus={setModalStatus}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={values => {
          onOk(values);
        }}
        initialValues={{
          groupName: selectedGroup.name,
          version: selectedGroup.versionName || null,
          versionPlus: selectedGroup.versionPlusName || null,
        }}
        validateMessages={deviceFormValidateMessages}
      >
        <InfoFieldsWrap>
          <FormattedMessage {...messages.groupNameFieldLabel} />
          <p>{selectedGroup.name && selectedGroup.name}</p>
        </InfoFieldsWrap>
        <RadioGroupWrap
          onChange={e => setRadioValue(e.target.value)}
          value={radioValue}
        >
          <Space direction="vertical">
            <Radio value={RADIO_BUTTONS_VALUES.LATEST_VERSION}>
              <FormattedMessage {...messages.updateToLatest} />
            </Radio>
            <Radio value={RADIO_BUTTONS_VALUES.OTHER}>
              <FormattedMessage {...messages.other} />
            </Radio>
          </Space>
        </RadioGroupWrap>
        <Form.Item
          name={GROUP_UPDATE_VERSION_FIELDS.VERSION_ID}
          label={intl.formatMessage(messages.versionLabel)}
          rules={[
            {
              required: radioValue === RADIO_BUTTONS_VALUES.OTHER,
              message: intl.formatMessage(messages.versionError),
            },
          ]}
        >
          <SelectVersion
            disabled={radioValue !== RADIO_BUTTONS_VALUES.OTHER}
            versions={versionsByDeviceType_130H}
            defaultValue={selectedGroup.versionName}
            deviceType={DEVICE_TYPE.DEVICE_130H}
            onLoadVersionsByDeviceType={onLoadVersionsByDeviceType}
            loading={isLoadingVersionsByDeviceType}
          />
        </Form.Item>
        <Form.Item
          name={GROUP_UPDATE_VERSION_FIELDS.VERSION_PLUS_ID}
          label={intl.formatMessage(messages.versionPlusLabel)}
          rules={[
            {
              required: radioValue === RADIO_BUTTONS_VALUES.OTHER,
              message: intl.formatMessage(messages.versionError),
            },
          ]}
        >
          <SelectVersion
            disabled={radioValue !== RADIO_BUTTONS_VALUES.OTHER}
            versions={versionsByDeviceType_130H_Plus}
            defaultValue={selectedGroup.versionPlusName}
            deviceType={DEVICE_TYPE.DEVICE_130H_PLUS}
            onLoadVersionsByDeviceType={onLoadVersionsByDeviceType}
            loading={isLoadingVersionsByDeviceType}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const messages = defineMessages({
  addDevice: {
    defaultMessage: 'Add Device',
  },
  title: {
    defaultMessage: 'Update Group Version',
  },
  okButtonText: {
    defaultMessage: 'Update',
  },
  add: {
    defaultMessage: 'Add',
  },
  editDevice: {
    defaultMessage: 'Edit Device',
  },
  apply: {
    defaultMessage: 'Apply',
  },
  groupNameFieldLabel: {
    defaultMessage: 'Group',
  },
  versionLabel: {
    defaultMessage: '130H Version',
  },
  versionPlusLabel: {
    defaultMessage: '130H-Plus Version',
  },
  versionError: {
    defaultMessage: 'No spaces allowed',
  },
  updateToLatest: {
    defaultMessage: 'Update to latest',
  },
  other: {
    defaultMessage: 'Other',
  },
});

/* eslint-disable no-template-curly-in-string */
export const deviceFormValidateMessages = {
  required: '${label} is required!',
};
/* eslint-enable no-template-curly-in-string */

UpdateVersionModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object.isRequired,
  onLoadLatestVersions: PropTypes.func.isRequired,
  onLoadVersionsByDeviceType: PropTypes.func.isRequired,
  versionsByDeviceType_130H: PropTypes.arrayOf(PropTypes.object),
  versionsByDeviceType_130H_Plus: PropTypes.arrayOf(PropTypes.object),
  latestVersions: PropTypes.arrayOf(PropTypes.object),
  isLoadingVersionsByDeviceType: PropTypes.boolean,
};
export default Connector(injectIntl(UpdateVersionModal));
