import { connect, ConnectedProps } from 'react-redux';

import { actions, selectors } from 'src/redux/data/auth/modules/slice';
import { RootState } from 'src/redux/store';

const mapStateToProps = (state: RootState) => ({
  loginStatus: selectors.getLoginStatus(state),
  userIsIdle: selectors.getIsIdle(state),
  isConsentApiFailed: selectors.getIsConsentFailed(state),
});

const mapActionCreators = {
  login: actions.login,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
