import { styled } from 'styled-components';
import ButtonWithIcon from 'src/components/general-ui/Buttons/ButtonWithIcon';
import { SpinBox } from 'src/routes/Root/components/styled';

export const FieldsContainer = styled.div`
  padding-right: 0.7rem;
  & input {
    outline-color: #252525;
    outline-style: solid;
    outline-width: thin;
    max-width: 100%;
  }
`;
export const StyledButtonWithIcon = styled(ButtonWithIcon)`
  margin-bottom: 5px;
`;
export const SpinnerContainer = styled(SpinBox)`
  height: calc(100% - 51px);
`;
