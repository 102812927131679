import React from 'react';

import { SVGProps } from '../types';

const arrowColors = {
  DEFAULT: {
    up: '#767881',
    down: '#767881',
  },
  ASC: {
    up: '#1890ff',
    down: '#767881',
  },
  DESC: {
    up: '#767881',
    down: '#1890ff',
  },
};

const SortIcon = ({
  width = '9',
  height = '11',
  sortOrder = 'DEFAULT',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      opacity="0.5"
      d="M0 7.17053L4.47527 10.6206L8.95053 7.17053H0Z"
      fill={arrowColors[sortOrder].up}
    />
    <path
      d="M0 3.82938L4.47527 0.379272L8.95053 3.82938H0Z"
      fill={arrowColors[sortOrder].down}
    />
  </svg>
);

export default SortIcon;
