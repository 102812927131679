import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Modal } from 'antd';

import Connector, { PropsFromRedux } from './Connector';
import CustomersTable from './components/CustomersTable';
import CustomerStepWizard from './components/CustomerWizard';

import { MainTitle } from 'src/components/styled';
import ActionButton from 'src/components/general-ui/Buttons/ActionButton';
import { CustomerModalType } from './modules/types';
import { MODAL_MODES } from 'src/utils/constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: 'Inter';
  background: #e3f3f5;
  color: #252525;
  padding: 1.4rem 1.25rem;
`;

const CustomerModal = styled(Modal)`
  .ant-modal-footer {
    position: absolute;
    bottom: 3%;
    right: 5%;
    .modal-cancel-btn {
      margin-right: 5px;
    }
  }
`;

type Props = PropsFromRedux;

const CustomersPage = ({
  getCustomers,
  customersList,
  isLoading,
  createCustomer,
  editCustomer,
  customerForm,
  resetFormAndCurrentCustomer,
}: Props): JSX.Element => {
  const [modalState, setModalState] = useState<CustomerModalType>({
    mode: '',
    visible: false,
  });

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  const handleSave = () => {
    setModalState(prevState => ({ ...prevState, mode: '', visible: false }));
    if (modalState.mode === MODAL_MODES.ADD) {
      createCustomer();
    } else {
      editCustomer();
    }
  };

  return (
    <Wrapper>
      <MainTitle>
        <FormattedMessage {...messages.customers} />
      </MainTitle>
      <CustomersTable
        customersTableData={customersList}
        isLoading={isLoading}
        setModalState={setModalState}
      />
      <CustomerModal
        destroyOnClose
        className="customer-modal"
        title={<FormattedMessage {...messages.manageCustomer} />}
        centered
        open={modalState.visible}
        onOk={handleSave}
        onCancel={() => {
          resetFormAndCurrentCustomer();
          setModalState(prevState => ({
            ...prevState,
            mode: '',
            visible: false,
          }));
        }}
        width={800}
        footer={[
          <ActionButton
            className="modal-cancel-btn"
            key="cancel"
            onClick={() => {
              resetFormAndCurrentCustomer();
              setModalState(prevState => ({
                ...prevState,
                mode: '',
                visible: false,
              }));
            }}
          >
            <FormattedMessage {...messages.cancel} />
          </ActionButton>,
          <ActionButton
            key="submit"
            onClick={handleSave}
            disabled={!customerForm.name}
          >
            {modalState.mode && (
              <FormattedMessage {...modesMessages[modalState.mode]} />
            )}
          </ActionButton>,
        ]}
      >
        <CustomerStepWizard modalMode={modalState.mode} />
      </CustomerModal>
    </Wrapper>
  );
};

const modesMessages = defineMessages({
  ADD: {
    defaultMessage: 'Add Customer',
  },
  EDIT: {
    defaultMessage: 'Edit Customer',
  },
});

const messages = defineMessages({
  customers: {
    defaultMessage: 'Customers',
  },
  manageCustomer: {
    defaultMessage: 'Manage Customer',
  },

  save: {
    defaultMessage: 'Save',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
});

export default Connector(CustomersPage);
