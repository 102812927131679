import { Tabs } from 'antd';
import { styled } from 'styled-components';

import FormModal from 'src/components/general-ui/FormModal';

export const StyledModal = styled(FormModal)`
  .ant-modal-content {
    width: fit-content;
    min-width: 26.9rem;
    min-height: 34rem;
  }
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 0.5rem 0rem;
  }

  .ant-tabs-nav {
    margin-bottom: 0.5rem;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4a4a4a;
    font-weight: 700;
  }
  .ant-tabs-tab-btn {
    font-family: ${props => props.theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 0.6rem;
    color: #4a4a4a;
    padding: 0rem 0.75rem;
  }

  .ant-tabs-ink-bar {
    background: #304e64;
  }
`;
