import qs from 'qs';
import { API_DATE_FORMAT, nowUTC } from 'src/utils/timeUtils';
import { DateUTC, UUID } from 'src/types/utility';

export const POINTS_DATE_FORMAT = 'YYYY-MM-DDTHH:mm';
const BED_OCCUPANCY_DAYS_TIMEFRAME = 6;

export const extractQpPatientId = (): UUID | null => {
  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  return (queryParams.patientId || null) as UUID | null;
};

export const getBedOccupancyInterval = (): {
  startTime: DateUTC;
  endTime: DateUTC;
} => {
  const now = nowUTC();

  return {
    startTime: now
      .subtract(BED_OCCUPANCY_DAYS_TIMEFRAME, 'day')
      .startOf('day')
      .format(API_DATE_FORMAT),
    endTime: now.subtract(1, 'day').endOf('day').format(API_DATE_FORMAT),
  };
};

export const getMetricHistogramIntervals = (): {
  startTimeOneDay: DateUTC;
  startTimeOneWeek: DateUTC;
  endTime: DateUTC;
} => {
  const now = nowUTC();

  return {
    startTimeOneDay: now.subtract(1, 'day').format(API_DATE_FORMAT),
    startTimeOneWeek: now.subtract(1, 'week').format(API_DATE_FORMAT),
    endTime: now.format(API_DATE_FORMAT),
  };
};
