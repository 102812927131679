import styled from 'styled-components';
import { Select } from 'antd';

export const StyledSelect: typeof Select = styled(Select)`
  .ant-select-selection-placeholder {
    font-size: 0.75rem;
    color: #000;
  }

  .ant-select-selection-item {
    font-size: 0.75rem;
  }

  .ant-select-selector > .ant-select-selection-search {
    left: 0.4rem;

    .ant-select-selection-search-input {
      height: 100%;
    }
  }
`;
