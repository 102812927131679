import React from 'react';

import { SVGProps } from '../types';

const DropdownArrowIcon = ({
  width = '12',
  height = '8',
  color = '#252525',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      d="M5.97407 7.49931C5.76881 7.49418 5.57325 7.40956 5.42457 7.25996L0.252757 2.00824C-0.0761296 1.6723 -0.0850252 1.11757 0.231747 0.768807C0.549338 0.420924 1.07297 0.408954 1.40349 0.743163L6 5.4137L10.5965 0.743163C10.927 0.408957 11.4507 0.420904 11.7683 0.768807C12.085 1.11757 12.0761 1.6723 11.7472 2.00824L6.57542 7.25996C6.41381 7.42237 6.19724 7.50869 5.97421 7.49931H5.97407Z"
      fill={color}
    />
  </svg>
);

export default DropdownArrowIcon;
