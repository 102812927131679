import {
  Subscriber,
  UserMedicalSubscription,
  UserTechnicalSubscription,
  UsersSubscriptionsByTargetMediaAndAlertCategory,
  UserActivitySubscription,
} from 'src/types/subscribers';
import { activityAlertsArray } from 'src/routes/GMDashboard/modules/constants';

export const extractUserSubscriptionsFromSubscribers = (
  subscribers: Subscriber[],
): UsersSubscriptionsByTargetMediaAndAlertCategory => {
  const smsSubscribers = subscribers.filter(
    subscriber => subscriber.targetMedia === 'SMS',
  );
  const smsMedicalSubscribers: UserMedicalSubscription[] = smsSubscribers
    .filter(subscriber => subscriber.alertType === 'RR')
    .map(subscriber => ({
      alertCategory: 'MEDICAL',
      id: subscriber.userId,
      tenantId: subscriber.tenantId,
      targetMedia: subscriber.targetMedia,
      suppressStartTime: subscriber.suppressStartTime,
      suppressDuration: subscriber.suppressDuration,
      mediaAlertsEnabled: subscriber.mediaAlertsEnabled,
      withPersonalDetails: !!subscriber.withPersonalDetails,
      summaryReportEnabled: null,
      summaryReportTime: null,
      singleDisconnectionAllowedType: null,
      emailCcEnabled: null,
      emailCcRecipients: null,
    }));
  const smsTechnicalSubscribers: UserTechnicalSubscription[] = smsSubscribers
    .filter(subscriber => subscriber.alertType === 'DEVICE_DISCONNECTED')
    .map(subscriber => ({
      alertCategory: 'TECHNICAL',
      id: subscriber.userId,
      tenantId: subscriber.tenantId,
      targetMedia: subscriber.targetMedia,
      suppressStartTime: subscriber.suppressStartTime,
      suppressDuration: subscriber.suppressDuration,
      mediaAlertsEnabled: subscriber.mediaAlertsEnabled,
      withPersonalDetails: !!subscriber.withPersonalDetails,
      summaryReportEnabled: subscriber.summaryReportEnabled,
      summaryReportTime: subscriber.summaryReportTime,
      singleDisconnectionAllowedType: subscriber.singleDisconnectionAllowedType,
      emailCcEnabled: subscriber.emailCcEnabled,
      emailCcRecipients: subscriber.emailCcRecipients,
    }));
  const smsActivitySubscribers: UserActivitySubscription[] = smsSubscribers
    .filter(subscriber => activityAlertsArray.includes(subscriber.alertType))
    .map(subscriber => ({
      alertCategory: 'ACTIVITY',
      id: subscriber.userId,
      tenantId: subscriber.tenantId,
      targetMedia: subscriber.targetMedia,
      suppressStartTime: subscriber.suppressStartTime,
      suppressDuration: subscriber.suppressDuration,
      mediaAlertsEnabled: subscriber.mediaAlertsEnabled,
    }));

  const emailSubscribers = subscribers.filter(
    subscriber => subscriber.targetMedia === 'EMAIL',
  );
  const emailMedicalSubscribers: UserMedicalSubscription[] = emailSubscribers
    .filter(subscriber => subscriber.alertType === 'RR')
    .map(subscriber => ({
      alertCategory: 'MEDICAL',
      id: subscriber.userId,
      tenantId: subscriber.tenantId,
      targetMedia: subscriber.targetMedia,
      suppressStartTime: subscriber.suppressStartTime,
      suppressDuration: subscriber.suppressDuration,
      mediaAlertsEnabled: subscriber.mediaAlertsEnabled,
      withPersonalDetails: !!subscriber.withPersonalDetails,
      summaryReportEnabled: null,
      summaryReportTime: null,
      singleDisconnectionAllowedType: null,
      emailCcEnabled: null,
      emailCcRecipients: null,
    }));
  const emailTechnicalSubscribers: UserTechnicalSubscription[] =
    emailSubscribers
      .filter(subscriber => subscriber.alertType === 'DEVICE_DISCONNECTED')
      .map(subscriber => ({
        alertCategory: 'TECHNICAL',
        id: subscriber.userId,
        tenantId: subscriber.tenantId,
        targetMedia: subscriber.targetMedia,
        suppressStartTime: subscriber.suppressStartTime,
        suppressDuration: subscriber.suppressDuration,
        mediaAlertsEnabled: subscriber.mediaAlertsEnabled,
        withPersonalDetails: !!subscriber.withPersonalDetails,
        summaryReportEnabled: subscriber.summaryReportEnabled,
        summaryReportTime: subscriber.summaryReportTime,
        singleDisconnectionAllowedType:
          subscriber.singleDisconnectionAllowedType,
        emailCcEnabled: subscriber.emailCcEnabled,
        emailCcRecipients: subscriber.emailCcRecipients,
      }));

  const emailActivitySubscribers: UserActivitySubscription[] = emailSubscribers
    .filter(subscriber => activityAlertsArray.includes(subscriber.alertType))
    .map(subscriber => ({
      alertCategory: 'ACTIVITY',
      id: subscriber.userId,
      tenantId: subscriber.tenantId,
      targetMedia: subscriber.targetMedia,
      suppressStartTime: subscriber.suppressStartTime,
      suppressDuration: subscriber.suppressDuration,
      mediaAlertsEnabled: subscriber.mediaAlertsEnabled,
    }));

  return {
    SMS: {
      MEDICAL: smsMedicalSubscribers,
      TECHNICAL: smsTechnicalSubscribers,
      ACTIVITY: smsActivitySubscribers,
    },
    EMAIL: {
      MEDICAL: emailMedicalSubscribers,
      TECHNICAL: emailTechnicalSubscribers,
      ACTIVITY: emailActivitySubscribers,
    },
  };
};
