import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import * as R from 'fp-ts/lib/Record';
import * as Ord from 'fp-ts/lib/Ord';

import { MODAL_STATUS } from 'src/components/Modal/constants';
import { RootState } from 'src/redux/store';
import {
  DeviceConnectionState,
  MTMAssignedDevices,
  MTMDeviceConnectioninfo,
  RawDevice,
  TenantConnectionInfo,
} from 'src/types/devices';
import { Room } from 'src/types/rooms';
import { PageMetaDataType, SerialNumber, UUID } from 'src/types/utility';
import { DATA_STATE_KEY, DATA_FETCHING_STATUS } from '../../constants';
import { mergeArraysWithCustomUniqueIds } from '../../dataUtils';
export const STATE_KEY = 'device';

export const INITIAL_STATE: {
  devicesList: RawDevice[];
  staticDevicesList: RawDevice[];
  metadata: {
    connectionInfo: MTMDeviceConnectioninfo;
    sessionInfo: MTMAssignedDevices;
  };
  status: keyof typeof DATA_FETCHING_STATUS;
  modalStatus: keyof typeof MODAL_STATUS;
  adminDevices: {
    adminDevicesList: RawDevice[];
    pageMetadata: PageMetaDataType;
  };
  devicesSearchResults: {
    data: RawDevice[];
    status: keyof typeof DATA_FETCHING_STATUS | null;
  };
} = {
  devicesList: [],
  staticDevicesList: [],
  adminDevices: {
    adminDevicesList: [],
    pageMetadata: {
      totalResults: 0,
      page: 0,
      limit: 0,
    },
  },
  devicesSearchResults: {
    data: [],
    status: null,
  },
  metadata: {
    connectionInfo: {},
    sessionInfo: {},
  },
  status: DATA_FETCHING_STATUS.LOADING,
  modalStatus: MODAL_STATUS.INITIAL,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setDevicesList(state, action: PayloadAction<RawDevice[]>) {
      state.devicesList = mergeArraysWithCustomUniqueIds(
        state.devicesList,
        action.payload,
        (a, b) => a.manufacturerId === b.manufacturerId,
      );
      state.staticDevicesList = mergeArraysWithCustomUniqueIds(
        state.staticDevicesList,
        action.payload,
        (a, b) => a.manufacturerId === b.manufacturerId,
      );
    },
    setAdminDevicesList(
      state,
      action: PayloadAction<{
        adminDevicesList: RawDevice[];
        pageMetadata: PageMetaDataType;
      }>,
    ) {
      state.adminDevices = { ...action.payload };
    },
    setSearchedDevices(
      state,
      action: {
        payload: {
          data: RawDevice[];
          status: keyof typeof DATA_FETCHING_STATUS | null;
        };
      },
    ) {
      state.devicesSearchResults = { ...action.payload };
    },
    setStatus(state, action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>) {
      state.status = action.payload;
    },
    setModalStatus(state, action: PayloadAction<keyof typeof MODAL_STATUS>) {
      state.modalStatus = action.payload;
    },
    setDeviceConnectionState(
      state,
      action: PayloadAction<DeviceConnectionState>,
    ) {
      const { deviceId, connectionStatus, timestamp } = action.payload;
      const device = state.devicesList.find(d => d.manufacturerId === deviceId);

      if (device) {
        device.connectionStatus.connected = connectionStatus === 'connected';
        device.connectionStatus.lastModifiedTimestamp = timestamp;
      }
    },
    fetchMTMDeviceConnectionInfoSuccess(
      state,
      action: PayloadAction<Record<UUID, TenantConnectionInfo>>,
    ) {
      state.metadata.connectionInfo = { ...action.payload };
    },
  },
  extraReducers: {},
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

const getDeviceId = (_state: RootState, deviceId: SerialNumber) => deviceId;

const getTenantId = (_state: RootState, tenantId: UUID) => tenantId;

export const selectors = {
  getDevicesList: createSelector(getState, state => state.devicesList),
  getStaticDevicesList: createSelector(
    getState,
    state => state.staticDevicesList,
  ),
  getAdminDevices: createSelector(getState, state => state.adminDevices),
  getDevicesSearchResults: createSelector(
    getState,
    state => state.devicesSearchResults.data,
  ),
  getDevicesSearchStatus: createSelector(
    getState,
    state => state.devicesSearchResults.status,
  ),
  isDeviceListLoading: createSelector(
    getState,
    state => state.status === DATA_FETCHING_STATUS.LOADING,
  ),
  getStatus: createSelector(getState, state => state.status),
  getModalStatus: createSelector(getState, state => state.modalStatus),
  selectDevice: createSelector(getState, getDeviceId, (state, deviceId) =>
    state.devicesList.find(d => d.manufacturerId === deviceId),
  ),
  getIsDeviceConnected: createSelector(
    getState,
    getDeviceId,
    (state, deviceId) => {
      const device = state.devicesList.find(d => d.manufacturerId === deviceId);
      return device?.connectionStatus?.connected || false;
    },
  ),

  selectMTMDeviceStatistics: createSelector(getState, state => {
    const { connectionInfo } = state.metadata;

    const mtmDeviceStats = R.reduceWithIndex(Ord.trivial)(
      {
        connected: {
          assigned: 0,
          unassigned: 0,
        },
        disconnected: {
          assigned: 0,
          unassigned: 0,
        },
      },
      (_tenantId: UUID, acc, info: TenantConnectionInfo) => ({
        connected: {
          assigned: acc.connected.assigned + info.connectedAssigned,
          unassigned: acc.connected.unassigned + info.connectedUnassigned,
        },
        disconnected: {
          assigned: acc.disconnected.assigned + info.disconnectedAssigned,
          unassigned: acc.disconnected.unassigned + info.disconnectedUnassigned,
        },
      }),
    )(connectionInfo);
    return {
      totalDevices:
        mtmDeviceStats.connected.assigned +
        mtmDeviceStats.connected.unassigned +
        mtmDeviceStats.disconnected.assigned +
        mtmDeviceStats.disconnected.unassigned,
      connectedAssignedDevices: mtmDeviceStats.connected.assigned,
      connectedUnassignedDevices: mtmDeviceStats.connected.unassigned,
      disconnectedAssignedDevices: mtmDeviceStats.disconnected.assigned,
      disconnectedUnassignedDevices: mtmDeviceStats.disconnected.unassigned,
    };
  }),
  selectTenantDeviceStatistics: createSelector(
    getState,
    getTenantId,
    (state, tenantId) => {
      const tenantConnectionInfo = state.metadata.connectionInfo[tenantId];
      if (!tenantConnectionInfo) {
        return {
          totalDevices: 0,
          connectedAssignedDevices: 0,
          connectedUnassignedDevices: 0,
          disconnectedAssignedDevices: 0,
          disconnectedUnassignedDevices: 0,
        };
      }
      return {
        totalDevices:
          tenantConnectionInfo.connectedAssigned +
          tenantConnectionInfo.connectedUnassigned +
          tenantConnectionInfo.disconnectedAssigned +
          tenantConnectionInfo.disconnectedUnassigned,
        connectedAssignedDevices: tenantConnectionInfo.connectedAssigned,
        connectedUnassignedDevices: tenantConnectionInfo.connectedUnassigned,
        disconnectedAssignedDevices: tenantConnectionInfo.disconnectedAssigned,
        disconnectedUnassignedDevices:
          tenantConnectionInfo.disconnectedUnassigned,
      };
    },
  ),
};

const extraActions = {
  getUserDevicesList: createAction<{ page: number; limit: number }>(
    `${STATE_KEY}/getUserDevicesList`,
  ),
  getDevicesList: createAction(`${STATE_KEY}/getDevicesList`),
  getAdminDevicesList: createAction<{ page: number; limit: number }>(
    `${STATE_KEY}/getAdminDevicesList`,
  ),
  searchAllDevicesAdmin: createAction<string>(
    `${STATE_KEY}/searchAllDevicesAdmin`,
  ),
  deleteDevice: createAction<{
    manufacturerId: SerialNumber;
    certificateExists: boolean;
  }>(`${STATE_KEY}/deleteDevice`),
  editDevice: createAction<{
    manufacturerId: SerialNumber;
    editDeviceData: {
      manufacturerId: SerialNumber;
      name: string;
      room: Room;
      bed: string;
    };
  }>(`${STATE_KEY}/editDevice`),
  createDevice: createAction<{
    manufacturerId: SerialNumber;
    name: string;
    room: Room;
    bed: string;
  }>(`${STATE_KEY}/createDevice`),
  assignDevice: createAction<{
    manufacturerId: SerialNumber;
    tenantSelect: UUID;
  }>(`${STATE_KEY}/assignDevice`),
  detachDevice: createAction<SerialNumber>(`${STATE_KEY}/detachDevice`),
  fetchMTMDeviceConnectionInfo: createAction<number>(
    `${STATE_KEY}/fetchMTMDeviceConnectionInfo`,
  ),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
