import {
  createSlice,
  createAction,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { UUID } from 'src/types/utility';
import { API_STATUS } from 'src/utils/api-constants';
import { DATA_STATE_KEY } from '../../constants';

export const STATE_KEY = 'auth';

export const INITIAL_STATE: {
  loginStatus: API_STATUS | null;
  forgotPasswordStatus: API_STATUS | null;
  resetPasswordStatus: API_STATUS | null;
  isIdle: boolean;
  mfaRequired: boolean;
  mfaPhone: string | null;
  consentsApiFailed: boolean;
  activeConsent: { revisionId: UUID; url: string } | null;
} = {
  mfaRequired: false,
  mfaPhone: null,
  loginStatus: null,
  forgotPasswordStatus: null,
  resetPasswordStatus: null,
  isIdle: false,
  consentsApiFailed: false,
  activeConsent: null,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    login: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{
        email: string;
        password: string;
        rememberMe?: boolean;
        withRedirect?: boolean;
      }>,
    ) => {
      state.loginStatus = API_STATUS.LOADING;
    },
    mfaLogin: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{
        code: string;
        withRedirect?: boolean;
      }>,
    ) => {
      state.loginStatus = API_STATUS.LOADING;
    },
    loginSuccess: state => {
      state.loginStatus = API_STATUS.OK;
    },
    loginFail: state => {
      state.loginStatus = API_STATUS.ERROR;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    forgotPassword: (state, _action: PayloadAction<{ email: string }>) => {
      state.forgotPasswordStatus = API_STATUS.LOADING;
    },
    onForgotPasswordUnmount: state => {
      state.forgotPasswordStatus = INITIAL_STATE.forgotPasswordStatus;
    },
    forgotPasswordSuccess: state => {
      state.forgotPasswordStatus = API_STATUS.OK;
    },
    forgotPasswordFail: state => {
      state.forgotPasswordStatus = API_STATUS.ERROR;
    },
    resetPasswordSuccess: state => {
      state.resetPasswordStatus = API_STATUS.OK;
    },
    // TODO: Add later if needed
    // resetPasswordFail: (state, action) => {
    //   state.resetPasswordStatus = action.payload;
    // },
    resetPassword: state => {
      state.resetPasswordStatus = API_STATUS.LOADING;
    },
    onIdle: (state, action: PayloadAction<boolean>) => {
      state.isIdle = action.payload;
    },
    setUserMfa: (state, action: PayloadAction<boolean>) => {
      state.mfaRequired = action.payload;
    },
    setUserMfaPhone: (state, action: PayloadAction<string | null>) => {
      state.mfaPhone = action.payload;
    },
    // TODO: Add later if needed
    // getConsentsSuccess: (state, action) => {
    //   state.consents.data = action.payload;
    //   state.consents.status = API_STATUS.OK;
    // },
    // getRevisionIdSuccess: (state, action) => {
    //   const { consentId, revisionId } = action.payload;
    //   const consentIndex = state.consents.data.findIndex(
    //     consent => consent.id === consentId,
    //   );
    //   state.consents.data[consentIndex] = {
    //     ...state.consents.data[consentIndex],
    //     revisionId,
    //   };
    // },
    // getcurrentConsentHtml: (state, action) => {
    //   state.currentConsentHtml = action.payload;
    // },
    consentFailed: state => {
      state.consentsApiFailed = true;
      state.loginStatus = API_STATUS.ERROR;
    },
    getActiveConsent: (
      state,
      action: PayloadAction<{ revisionId: UUID; url: string }>,
    ) => {
      state.activeConsent = action.payload;
    },
  },
});

// For saga/reducer-less actions
const extraActions = {
  logout: createAction(`${STATE_KEY}/logout`), // handled by user reducer
  mfaResendCodeLogin: createAction(`${STATE_KEY}/mfaResendCodeLogin`), // handled by user reducer
  logoutFinish: createAction(`${STATE_KEY}/logoutFinish`), // handled by user reducer
  onConfirmConsent: createAction<UUID>(`${STATE_KEY}/onConfirmConsent`),
  onCancelConsent: createAction(`${STATE_KEY}/onCancelConsent`),
};

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  getLoginStatus: createSelector(getState, state => state.loginStatus),
  getForgotPasswordStatus: createSelector(
    getState,
    state => state.forgotPasswordStatus,
  ),
  getResetPasswordStatus: createSelector(
    getState,
    state => state.resetPasswordStatus,
  ),
  getIsIdle: createSelector(getState, state => state?.isIdle),
  getIsConsentFailed: createSelector(
    getState,
    state => state?.consentsApiFailed,
  ),
  getActiveConsent: createSelector(getState, state => state?.activeConsent),
  getUserMfa: createSelector(getState, state => state?.mfaRequired),
  getUserMfaPhone: createSelector(getState, state => state?.mfaPhone),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
