import {
  all,
  takeLatest,
  select,
  put,
  call,
  race,
  take,
  debounce,
} from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';
import * as R from 'fp-ts/lib/Record';
import * as O from 'fp-ts/lib/Option';
import * as Ord from 'fp-ts/lib/Ord';

import AppConfig from 'src/config/AppConfig';
import { actions as monitorActions } from 'src/redux/data/monitor';
import { actions as nursesStationActions } from 'src/routes/NursesStation';
import {
  actions as alertsActions,
  selectors as alertsSelectors,
} from './slice';
import {
  AlertSounds,
  AlertSoundsMap,
  AlertSoundState,
  AlertSoundStatus,
  AlertSoundSeverity,
  SoundsToBePlayed,
} from './types';
import { playAlertSound, toggleMute } from './soundUtils';
import { mergeRecordsWithUniqueIds } from '../../dataUtils';

const ALERT_SOUNDS_DEBOUNCE_PERIOD = 500;

type PlaySoundRaceRT = {
  didPlaySound: boolean | undefined;
};

function* playSoundWrapper(
  action: ReturnType<typeof alertsActions.playSoundForAlerts>,
) {
  const { payload: soundsToBePlayed } = action;

  if (soundsToBePlayed.severity === AlertSoundSeverity.NO_ALERT) {
    return;
  }

  try {
    const alertsSettings = yield* select(alertsSelectors.getAlertsSettings);
    const repeats =
      alertsSettings.soundDuration || AppConfig.ALERT_SOUND_REPEAT_TIMES;

    const { soundsData, severity } = soundsToBePlayed;
    const playSound = playAlertSound(severity);

    const result = yield* race({
      didPlaySound: call(playSound, repeats),
      playSoundForAlerts: take(alertsActions.playSoundForAlerts),
      stopContinuous: take(monitorActions.onClickStopContinuous),
    });
    const { didPlaySound } = result as PlaySoundRaceRT;

    if (didPlaySound) {
      yield* put(alertsActions.alertSoundsPlayed(soundsData));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in play sound wrapper: ', error);
  }
}

const getAlertSoundsToBePlayed = (
  alertSounds: AlertSoundsMap,
): SoundsToBePlayed => {
  const soundsToBePlayed = R.filterMap<AlertSounds, AlertSounds>(
    alertSounds => {
      const x = R.filter<AlertSoundState>(
        soundState => soundState.status === AlertSoundStatus.NEED_TO_PLAY,
      )(alertSounds);

      return !R.isEmpty(x) ? O.some(x) : O.none;
    },
  )(alertSounds);

  const highestSeverity = R.reduce(Ord.trivial)(
    AlertSoundSeverity.NO_ALERT,
    (severity, alertSounds: AlertSounds) => {
      const x = R.collect(Ord.trivial)(
        (_key, soundState: AlertSoundState) =>
          soundState?.severity || AlertSoundSeverity.NO_ALERT,
      )(alertSounds);
      const maxSeverity = Math.max(...x);

      return maxSeverity > severity ? maxSeverity : severity;
    },
  )(soundsToBePlayed);

  const updatedSounds = R.map<AlertSounds, AlertSounds>(alertSounds =>
    R.filterMap((soundState: AlertSoundState) =>
      soundState
        ? O.some({
            ...soundState,
            status: AlertSoundStatus.SOUND_PLAYED,
          })
        : O.none,
    )(alertSounds),
  )(soundsToBePlayed);

  return {
    severity: highestSeverity,
    soundsData: updatedSounds,
  };
};

// TODO: Check if devices state needs to be considered here (eg: if device is not connected)
function* checkForSoundsToBePlayed(action: PayloadAction<AlertSoundsMap>) {
  const { payload: alertSounds } = action;

  const existingAlertSounds = yield* select(alertsSelectors.selectAlertSounds);
  const combinedAlertSounds = mergeRecordsWithUniqueIds(
    existingAlertSounds,
    alertSounds,
  );
  const soundsToBePlayed = getAlertSoundsToBePlayed(combinedAlertSounds);

  yield* put(alertsActions.playSoundForAlerts(soundsToBePlayed));
}

export default function* watchAlertSoundActions() {
  yield* all([
    debounce(
      ALERT_SOUNDS_DEBOUNCE_PERIOD,
      alertsActions.alertSoundsChanged,
      checkForSoundsToBePlayed,
    ),
    takeLatest(alertsActions.playSoundForAlerts, playSoundWrapper),
    takeLatest(nursesStationActions.changeAlertSoundStatus, toggleMute),
  ]);
}
