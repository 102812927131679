import { connect, ConnectedProps } from 'react-redux';
import * as A from 'fp-ts/lib/Array';

import { selectors as alertSelectors } from 'src/redux/data/alerts';
import { RootState } from 'src/redux/store';
import { Alert } from 'src/types/alerts';
import dataSelectors from 'src/redux/data/dataSelectors';
import { selectors as patientSelectors } from 'src/redux/data/patient';
import { selectors as sessionSelectors } from 'src/redux/data/sessions';
import { selectors as deviceSelectors } from 'src/redux/data/device';
import { selectors as roomSelectors } from 'src/redux/data/rooms';
import { isInLast24Hours } from 'src/utils/timeUtils';
import { Patient } from 'src/types/patients';
import { DeviceWithRoomAndBed } from 'src/types/devices';
import { AlertLogItem } from '../../components/AlertLogCard/types';
import { Session } from 'src/types/sessions';
import { extractLatestDeviceSession } from 'src/redux/data/dataUtils';

const filterEventLogAlerts = (alerts: Alert[]): Alert[] =>
  A.filter<Alert>(alert =>
    ['OFF'].includes(alert.status) && alert.endTime
      ? isInLast24Hours(alert.endTime)
      : false,
  )(alerts);

const extendAlerts = (
  alerts: Alert[],
  patients: Patient[],
  sessions: Session[],
  devices: DeviceWithRoomAndBed[],
): AlertLogItem[] =>
  alerts.map(alert => {
    const { roomName, bedName } =
      devices.find(device => device.manufacturerId === alert.deviceId) || {};
    const { lastName, firstName } =
      patients.find(patient => patient.id === alert.patientId) || {};

    if (alert.thresholdMetric === 'DEVICE_DISCONNECTED') {
      const lastDeviceSession = extractLatestDeviceSession(
        alert.deviceId,
        sessions,
      );
      const assignedSessionId =
        lastDeviceSession && !lastDeviceSession.endTime
          ? lastDeviceSession.id
          : null;

      return {
        ...alert,
        sessionId: assignedSessionId,
        deviceRoomName: roomName,
        deviceBedName: bedName,
        patientFirstName: firstName,
        patientLastName: lastName,
      };
    }

    return {
      ...alert,
      deviceRoomName: roomName,
      deviceBedName: bedName,
      patientFirstName: firstName,
      patientLastName: lastName,
    };
  });

const mapStateToProps = (state: RootState) => ({
  alertItems: extendAlerts(
    filterEventLogAlerts(alertSelectors.selectAlerts(state)),
    patientSelectors.getPatientsList(state),
    sessionSelectors.selectSessions(state),
    dataSelectors.selectDeviceListWithRoomAndBed(state),
  ),
  areHistoricalAlertsLoading:
    alertSelectors.areHistoricalAlertsLoading(state) ||
    patientSelectors.isPatientListLoading(state) ||
    sessionSelectors.areSessionsLoading(state) ||
    deviceSelectors.isDeviceListLoading(state) ||
    roomSelectors.getIsLoading(state),
});

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;

export default Connector;
