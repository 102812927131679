import React from 'react';

import { SVGProps } from '../types';

const WifiCircleIcon = ({
  width = '21',
  height = '20',
  color = '#767881',
  backgroundcolor = '#fff',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle
      cx="10.4932"
      cy="10"
      r="9.5"
      fill={backgroundcolor}
      stroke={color}
    />
    <path
      d="M16.3986 9.08327L16.3992 9.08271L4.60829 8.41705L4.50222 8.31098C4.50221 8.311 4.5022 8.31101 4.50218 8.31102C4.28999 8.52326 4.29041 8.86976 4.50194 9.08243L4.50222 9.08271C4.71446 9.29494 5.06099 9.29454 5.27367 9.08299L5.27395 9.08271C8.12837 6.22828 12.7725 6.22794 15.6272 9.08166C15.7315 9.18985 15.8727 9.24278 16.012 9.24278H16.0121C16.1523 9.24278 16.2928 9.18796 16.3986 9.08327Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M14.0406 11.4413L14.0412 11.4407C14.2534 11.2285 14.253 10.882 14.0415 10.6693L14.0412 10.669C12.0618 8.68962 8.83918 8.68963 6.85965 10.669L6.85965 10.669C6.64741 10.8813 6.64781 11.2278 6.85937 11.4405L6.85965 11.4407C7.07188 11.653 7.41841 11.6526 7.63109 11.441L7.63137 11.4407C9.18624 9.88586 11.7139 9.88552 13.2692 11.4397C13.3736 11.5479 13.5148 11.6008 13.6541 11.6008H13.6541C13.7943 11.6008 13.9348 11.546 14.0406 11.4413Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M10.4186 14.5228C11.1181 14.5228 11.6862 13.9554 11.6862 13.2548C11.6862 12.555 11.1181 11.9876 10.4186 11.9876C9.71904 11.9876 9.15098 12.555 9.15098 13.2548C9.15098 13.9554 9.71899 14.5228 10.4186 14.5228Z"
      fill={color}
      stroke={color}
      strokeWidth="0.2"
    />
  </svg>
);

export default WifiCircleIcon;
