import { InputNumber } from 'antd';
import styled from 'styled-components';

interface TextWrapperProps {
  inactive: boolean;
}

export const StyledWrapper = styled.div`
  height: fit-content;
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  margin: 0.75rem 0;
`;

export const StyledTextWrapper = styled.div<TextWrapperProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.6rem;
  opacity: ${props => (props.inactive ? '0.6' : '1')};

  .baseline-checkbox {
    margin-right: 0.3rem;
  }
`;

export const StyledInputNumber: typeof InputNumber = styled(InputNumber)`
  width: 1.5rem;
  height: 1rem;
  padding: 0 0.25rem;
  font-size: 0.7rem;
  line-height: 1rem;

  .ant-input-number-input-wrap {
    border-bottom: 1px solid #000;

    .ant-input-number-input {
      height: 100%;
      font-weight: 700;
      text-align: center;
      padding: 0;
    }
  }
`;
