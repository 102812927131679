import { IntlShape } from 'react-intl';

import {
  isAverageAlertDetails,
  isBaselineAlertDetails,
  isHrAlertDetails,
  isVacancyAlertDetails,
} from 'src/redux/data/alerts/modules/typeGuards';
import {
  AlertWithPersonalDetails,
  AverageAlertWithPersonalDetails,
} from 'src/types/alerts';
import { getBaselineAlertDetails } from 'src/utils/messages/alertMessages';
import { API_DATE_FORMAT, displayInTimezone } from 'src/utils/timeUtils';
import { messages } from './constants';

export type AlertTextType = {
  boldText?: string;
  normalText: string;
};

export const getAverageAlertText = (
  alert: AverageAlertWithPersonalDetails,
  intl: IntlShape,
): AlertTextType => {
  const { thresholdPreposition, lastDayBaseline } = alert;
  const metricMessage = isHrAlertDetails(alert) ? messages.hr : messages.rr;
  const unitMessage = isHrAlertDetails(alert) ? messages.bpm : messages.brpm;

  const reminderCountText = `| ${intl.formatMessage(messages.alert)} #${
    alert.continuesAlertCount
  }`;

  const boldText = `${intl.formatMessage(
    thresholdPreposition === 'ABOVE' ? messages.high : messages.low,
  )} ${intl.formatMessage(metricMessage)} - ${Math.round(
    alert.averageValue,
  )} ${intl.formatMessage(unitMessage)} `;

  const normalText = `${intl.formatMessage(
    thresholdPreposition === 'ABOVE' ? messages.above : messages.below,
  )} ${intl.formatMessage(messages.threshold)} ${
    alert.thresholdValue
  } ${intl.formatMessage(
    isHrAlertDetails(alert) ? messages.bpm : messages.brpm,
  )} ${reminderCountText}\r\n${intl.formatMessage(
    metricMessage,
  )} ${intl.formatMessage(messages.baselineOfLast24h)} ${
    lastDayBaseline || 'N/A'
  } ${intl.formatMessage(unitMessage)}`;

  return { boldText, normalText };
};

export const getAlertText = (
  alert: AlertWithPersonalDetails,
  timezone: string,
  intl: IntlShape,
): AlertTextType => {
  if (isVacancyAlertDetails(alert)) {
    return {
      boldText: intl.formatMessage(messages.vacantStation),
      normalText: '',
    };
  }

  if (isAverageAlertDetails(alert)) {
    return getAverageAlertText(alert, intl);
  }

  if (isBaselineAlertDetails(alert)) {
    const {
      alertType,
      thresholdPreposition,
      baselineValue,
      baselineDaysInterval,
      deviationHours,
      deviationPercentage,
      deviationValue,
      continuesAlertCount,
      alertStartTime,
    } = alert;
    return {
      boldText: '',
      normalText: getBaselineAlertDetails(
        {
          thresholdMetric: alertType,
          thresholdPreposition,
          baselineValue,
          baselineDaysInterval,
          deviationHours,
          deviationPercentage,
          deviationValue,
          reminderCount: continuesAlertCount,
          startTime: displayInTimezone(
            alertStartTime,
            timezone,
            API_DATE_FORMAT,
          ),
        },
        intl,
      ),
    };
  }

  return {
    boldText: '',
    normalText: '',
  };
};
