import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const RouterLink = ({ to, children, routerState }) => (
  <Link
    to={{
      pathname: to,
      state: routerState,
    }}
    replace
  >
    {children}
  </Link>
);

RouterLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  routerState: PropTypes.object,
};

RouterLink.defaultProps = {
  routerState: {},
};

export default RouterLink;
