import { all, takeLatest, put, race, take } from 'redux-saga/effects';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { actions as groupActions } from 'src/redux/data/groups/modules/slice';
import { actions as roomActions } from 'src/redux/data/rooms';
import { actions as deviceActions } from 'src/redux/data/device/modules/slice';
import { actions } from 'src/routes/Devices/modules/slice';
import { hasAllPermissions } from 'src/utils/permissions';
import permissions from 'src/permissions';

function* onLoadDevicesListPage(act) {
  const { deviceGotSuccess, deviceGotFailed } = yield race({
    deviceGotSuccess: take(
      action =>
        action.type === deviceActions.setStatus.type &&
        action.payload === DATA_FETCHING_STATUS.SUCCESS,
    ),
    deviceGotFailed: take(
      action =>
        action.type === deviceActions.setStatus.type &&
        action.payload === DATA_FETCHING_STATUS.ERROR,
    ),
  });
  if (
    deviceGotSuccess &&
    hasAllPermissions(permissions.VIEW_DEVICES_GROUP_COLUMN)
  ) {
    yield put(groupActions.onLoadGroupClients());
  }
  if (hasAllPermissions(permissions.VIEW_ROOMS_DEVICE_MODAL)) {
    yield put(roomActions.onLoadRoomsList());
  }

  if (deviceGotFailed) {
    notifyUserByActionTypeAndCode(act.type, act.payload, null);
  }
}

export default function* watchDeviceActions() {
  yield all([takeLatest(actions.onLoadDevicesListPage, onLoadDevicesListPage)]);
}
