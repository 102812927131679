import React from 'react';
import { Location } from 'history';
import qs from 'qs';

import { apiStatusEnum } from 'src/utils/constants';
import { UUID } from 'src/types/utility';
import Connector, { PropsFromRedux } from './Connector';
import LoginForm from './LoginForm';
import PersonalAlert from './PersonalAlert';

type QueryProps = {
  email: string;
  alertId: UUID;
};

const validateQP = (queryParams: qs.ParsedQs): QueryProps => {
  const { email, alertId } = queryParams;

  if (!email || !alertId) {
    console.error('Missing QS props', queryParams);

    return {
      email: '',
      alertId: '',
    };
  }

  return {
    email: email as string,
    alertId: alertId as string,
  };
};

type Props = PropsFromRedux & {
  location: Location;
};

const PersonalAlertDetails = ({
  login,
  location,
  loginStatus,
  isConsentApiFailed,
}: Props): JSX.Element => {
  const onFinish = (values: {
    email: string;
    password: string;
    rememberMe: boolean;
  }) => {
    login({ ...values, withRedirect: false });
  };
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { email, alertId } = validateQP(queryParams);
  const isLoginSuccess = loginStatus === apiStatusEnum.OK;

  if (!isLoginSuccess) {
    return (
      <LoginForm
        email={email}
        onFinish={onFinish}
        loginStatus={loginStatus}
        isConsentApiFailed={isConsentApiFailed}
      />
    );
  }

  return <PersonalAlert alertId={alertId} />;
};

export default Connector(PersonalAlertDetails);
