import React from 'react';

import { SVGProps } from '../types';

const BellIconOutlined = ({
  width = '15',
  height = '16',
  color = '#252525',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.50012 0.5C10.2817 0.5 12.5576 2.78021 12.5576 5.58309L12.5578 8.96012H14.0072V13.2637H10.1831C10.0783 13.8895 9.75509 14.4576 9.2703 14.8689C8.78554 15.2795 8.1706 15.5058 7.53409 15.5076H7.46625C6.82974 15.5058 6.21482 15.2795 5.73004 14.8689C5.24525 14.4576 4.92207 13.8895 4.81722 13.2637H0.993164V8.96012H2.44259V5.58309C2.44259 2.78021 4.71849 0.5 7.50012 0.5ZM7.50012 1.48366C5.26243 1.48366 3.42943 3.32256 3.42943 5.58309V8.96012H11.5708V5.58309C11.5708 3.32256 9.7378 1.48366 7.50012 1.48366ZM7.53406 14.524H7.46622C6.6934 14.5296 6.01618 14.0101 5.82443 13.2637H9.17606C8.98425 14.0101 8.30702 14.5296 7.53427 14.524H7.53406ZM1.97996 12.28H13.0203V9.94379H1.97996V12.28Z"
      fill={color}
    />
  </svg>
);
export default BellIconOutlined;
