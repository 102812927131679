import { SerialNumber, UUID } from './utility';

export enum PatientState {
  OCCUPIED = 0,
  EMPTY = 1,
  NO_SIGNAL = 2,
  HOLDING_BREATH = 3,
  UNKNOWN = 4,
  DISABLED = 5,
  MOTION = 6,
  SPEAKING = 7,
}

export type ContinuousMeasurementValue = string;

export type MeasurementsState = Partial<{
  currentHrValue: ContinuousMeasurementValue;
  currentHrQuality: boolean;
  currentRrValue: ContinuousMeasurementValue;
  currentRrQuality: boolean;
  currentRabinValue: ContinuousMeasurementValue;
  currentRabinQuality: boolean;
  currentIERatioValue: ContinuousMeasurementValue;
  currentPatientStateValue: PatientState;
}>;

export type SpotMeasurementsState = Partial<{
  hr: string;
  rr: string;
}>;

export type ContinuousMeasurement = {
  dataType: 'CONTINUOUS';
  patientId: UUID;
  sessionId: UUID;
  deviceId: SerialNumber;
  data: MeasurementsState;
};

export type SpotMeasurement = {
  dataType: 'SPOT';
  patientId: UUID;
  sessionId: UUID;
  deviceId: SerialNumber;
  data: SpotMeasurementsState;
};
