export const redirectRoutes = ['/en_US/individual-dashboard'];

export const searchParams = ['patientId'];

export const ValidQueryParams = [
  'sound',
  'repetitions',
  'delay',
  'statisticsFilter',
];
