import { defineMessages } from 'react-intl';

export const patientMessages = defineMessages({
  // error
  deletePatientError: {
    defaultMessage: 'Could not delete patient',
  },
  updatePatientError: {
    defaultMessage: 'Could not update patient',
  },
  createPatientError: {
    defaultMessage: 'Could not create patient',
  },
  patientAlreadyExistsError: {
    defaultMessage: 'Patient already exists',
  },
  userNotFoundError: {
    defaultMessage: 'Patient not exist',
  },

  // success
});
