import React, { Dispatch, SetStateAction } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { getCustomersColumns, getTableActions } from './utils';
import { StyledTable } from './styled';
import { CustomerModalType, CustomerType } from '../../modules/types';
import Connector, { PropsFromRedux } from './Connector';
import { MODAL_MODES } from 'src/utils/constants';

type Props = PropsFromRedux & {
  intl: IntlShape;
  customersTableData: CustomerType[] | null | undefined;
  isLoading: boolean;
  setModalState: Dispatch<SetStateAction<CustomerModalType>>;
};

const CustomersTable = ({
  intl,
  customersTableData,
  isLoading,
  setModalState,
  setCustomerForm,
  setCurrentCustomer,
  deleteCustomer,
}: Props) => {
  const columns = getCustomersColumns(intl);
  const actions = getTableActions(
    intl,
    setModalState,
    setCustomerForm,
    setCurrentCustomer,
    deleteCustomer,
  );

  return (
    <StyledTable
      data={customersTableData || []}
      columns={columns}
      actions={actions}
      loading={isLoading}
      withSearch
      withAddButton
      addButtonOnClick={() =>
        setModalState({ mode: MODAL_MODES.ADD, visible: true })
      }
    />
  );
};

export default Connector(injectIntl(CustomersTable));
