import { connect, ConnectedProps } from 'react-redux';

import {
  selectors as customersSelectors,
  actions as customersActions,
} from 'src/redux/data/customers';
import { RootState } from 'src/redux/store';

const mapStateToProps = (state: RootState) => ({
  editableData: customersSelectors.selectEditableData(state),
});

const mapActionCreators = {
  setCustomerForm: customersActions.setCustomerForm,
  setCurrentCustomer: customersActions.setCurrentCustomer,
  deleteCustomer: customersActions.deleteCustomerById,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
