import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

const RadioGroup = ({
  optionsList,
  onSelect,
  selectedOption,
  disabledOption,
}) => {
  const [value, setValue] = useState(selectedOption);

  useEffect(() => {
    setValue(selectedOption);
  }, [selectedOption]);

  const onChange = e => {
    setValue(e.target.value);
    onSelect(e.target.value);
  };

  return (
    <Radio.Group onChange={onChange} value={value}>
      {optionsList.map(option => (
        <Radio
          key={option.id}
          value={option.value}
          optionobject={option}
          defaultValue={selectedOption}
          disabled={disabledOption}
        >
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};
export default RadioGroup;

RadioGroup.propTypes = {
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  onSelect: PropTypes.func,
  selectedOption: PropTypes.string,
  disabledOption: PropTypes.bool,
};
