import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Radio, Space } from 'antd';
import styled from 'styled-components';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import {
  StyledWrapper,
  StyledSwitch,
  StatusWrapper,
  StyledHeader,
  StyledSubheader,
} from './styled';
import { ALERT_STATUSES, SUPPRESSION_OPTIONS } from './constants';
import ActionButton from '../general-ui/Buttons/ActionButton';
import * as dayjs from 'dayjs';

const StyledActionButton = styled(ActionButton)`
  position: ${props => (!props.isNormalFlow ? 'relative' : 'absolute')};
  bottom: ${props => (!props.isNormalFlow ? '0px' : '11px')};
  right: ${props => (!props.isNormalFlow ? '0px' : '105px')};
  margin-top: ${props => (!props.isNormalFlow ? '15px' : '0px')};
`;

const getAlertsStatus = (areAlertsActive, suppressedUntil) => {
  if (suppressedUntil) {
    return ALERT_STATUSES.SUPPRESSED;
  }

  return areAlertsActive ? ALERT_STATUSES.ENABLED : ALERT_STATUSES.DISABLED;
};

const onSwitchAlertStatus = (value, setAreAlertsActive, setOption) => {
  setAreAlertsActive(value);

  if (!value) {
    setOption();

    return;
  }

  setOption();
};

const onChangeSuppresionOption = (option, setOption, setAreAlertsActive) => {
  setOption(option);
  setAreAlertsActive(false);
};

const renderSuppresionOptions = (
  option,
  isDisabled,
  setOption,
  setAreAlertsActive,
) => (
  <Radio.Group
    onChange={e =>
      onChangeSuppresionOption(e.target.value, setOption, setAreAlertsActive)
    }
    value={option}
    disabled={isDisabled}
  >
    <Space direction="vertical" align="start">
      {Object.entries(SUPPRESSION_OPTIONS).map(([k, v]) => (
        <Radio key={k} value={v}>
          <FormattedMessage {...messages[k]} />
        </Radio>
      ))}
    </Space>
  </Radio.Group>
);

const ControlSMSEmailAlertsComponent = ({
  location,
  mediaAlertsEnabled,
  suppressedUntil,
  handleApply,
  setIsModalVisible,
  isModalVisible,
  suppressDuration,
  permissionStatus,
}) => {
  const [areAlertsActive, setAreAlertsActive] = useState(false);
  const [option, setOption] = useState();
  const isNormalFlow = (path => !path.includes(appRoutes.SUPPRESS_ALERTS))(
    location?.pathname || '',
  );
  const alertsStatus = getAlertsStatus(areAlertsActive, option);
  const areSuppressedOptionsDisabled = !areAlertsActive && !option;

  useEffect(() => {
    setAreAlertsActive(mediaAlertsEnabled);
    if (isModalVisible && !suppressDuration) {
      setOption(null);
    }
  }, [mediaAlertsEnabled, isModalVisible]);

  useEffect(() => {
    if (suppressDuration && !mediaAlertsEnabled) {
      setOption(suppressDuration);
    }
  }, [suppressDuration, isModalVisible]);

  const suppressUntilLocal = dayjs()
    .add(parseInt(option), 'h')
    .format('DD MMM YY, HH:mm');
  return (
    <StyledWrapper isNormalFlow={isNormalFlow}>
      <StyledHeader>
        <FormattedMessage {...messages.alerts} />
        <StyledSwitch
          defaultChecked={false}
          checked={permissionStatus ? areAlertsActive : false}
          onChange={value =>
            onSwitchAlertStatus(value, setAreAlertsActive, setOption)
          }
          status={alertsStatus}
          disabled={!permissionStatus}
        />
      </StyledHeader>
      <StatusWrapper alertsStatus={alertsStatus}>
        <>
          <FormattedMessage {...messages[alertsStatus]} />
          {alertsStatus === ALERT_STATUSES.SUPPRESSED ? (
            <span>
              &nbsp;
              {!option && suppressedUntil
                ? dayjs(suppressedUntil).format('DD MMM YY, HH:mm')
                : suppressUntilLocal}
            </span>
          ) : null}
        </>
      </StatusWrapper>
      {/*
      <StyledSubheader>
        <FormattedMessage { ...messages.suppressFor } />
      </StyledSubheader>
       {renderSuppresionOptions(
        option,
        areSuppressedOptionsDisabled,
        setOption,
        setAreAlertsActive,
      )} */}
      <StyledActionButton
        isNormalFlow={isNormalFlow}
        onClick={() => {
          handleApply(areAlertsActive, option);
          setAreAlertsActive(false);
          setOption(null);
          setIsModalVisible(false);
        }}
        disabled={!permissionStatus}
      >
        <FormattedMessage {...messages.apply} />
      </StyledActionButton>
    </StyledWrapper>
  );
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Settings',
  },
  alerts: {
    defaultMessage: 'SMS/Email Alerts',
  },
  status: {
    defaultMessage: 'Status:',
  },
  enabled: {
    defaultMessage: 'Enabled',
  },
  disabled: {
    defaultMessage: 'Disabled',
  },
  suppressed: {
    defaultMessage: 'Suppressed until: ',
  },
  suppressFor: {
    defaultMessage: 'Suppress alerts for:',
  },
  oneHour: {
    defaultMessage: '1 hour',
  },
  twoHours: {
    defaultMessage: '2 hours',
  },
  sixHours: {
    defaultMessage: '6 hours',
  },
  twelveHours: {
    defaultMessage: '12 hours',
  },
  twentyFourHours: {
    defaultMessage: '24 hours',
  },
  apply: {
    defaultMessage: 'Apply',
  },
});

ControlSMSEmailAlertsComponent.defaultProps = {
  location: {
    pathname: '',
  },
  suppressedUntil: '',
  suppressDuration: 0,
  permissionStatus: true,
  setIsModalVisible: () => {},
  isModalVisible: false,
};

ControlSMSEmailAlertsComponent.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  suppressedUntil: PropTypes.string,
  suppressDuration: PropTypes.number,
  mediaAlertsEnabled: PropTypes.bool.isRequired,
  handleApply: PropTypes.func.isRequired,
  setIsModalVisible: PropTypes.func,
  isModalVisible: PropTypes.bool,
  permissionStatus: PropTypes.bool,
};

export default injectIntl(ControlSMSEmailAlertsComponent);
