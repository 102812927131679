type CustomPromise<T> = {
  resolve: (a: T) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: (reason?: any) => void;
};

export const createChannel = <T>() => {
  const persistMessages = true;
  let messageQueue: T[] = [];
  let promiseQueue: CustomPromise<T>[] = [];

  function put(paramsObject: T) {
    // anyone waiting for a message ?
    if (promiseQueue.length) {
      // deliver the message to the oldest one waiting (First In First Out)
      const nextPromise = promiseQueue.shift();

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      nextPromise?.resolve(paramsObject);
    } else if (persistMessages) {
      // no one is waiting ? queue the event
      messageQueue.push(paramsObject);
    }
  }

  // returns a Promise resolved with the next message
  function take(): Promise<T> {
    // do we have queued messages ?
    if (persistMessages && messageQueue.length) {
      const olderMessage = messageQueue.shift();

      if (!olderMessage) {
        throw Error('In event channel: cannot shift empty message queue');
      }

      // deliver the oldest queued message
      return Promise.resolve(olderMessage);
    }

    return new Promise<T>((resolve, reject) => {
      // FORLATER: think about adding id and filter instead of shift
      let timeoutId: number;
      // if (timeOutInSeconds !== null) {
      //   timeoutId = setTimeout(() => {
      //     promiseQueue.shift();
      //     // eslint-disable-next-line
      //     reject('request time out');
      //   }, timeOutInSeconds * 1000);
      // }
      const resolveFunc = (value: T) => {
        clearTimeout(timeoutId);
        resolve(value);
      };
      promiseQueue.push({ resolve: resolveFunc, reject });
    });
  }

  function clear() {
    messageQueue = [];
    promiseQueue = [];
  }

  return {
    take,
    put,
    clear,
  };
};
