import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import { Patient, PatientWithFullName } from 'src/types/patients';
import { UUID } from 'src/types/utility';
import { DATA_STATE_KEY, DATA_FETCHING_STATUS } from '../../constants';
import {
  CreatePatientPayload,
  DeletePatientPayload,
  UpdatePatientPayload,
} from './types';
import { mergeArraysWithUniqueIds } from '../../dataUtils';

export const STATE_KEY = 'patient';

export const INITIAL_STATE: {
  patientList: PatientWithFullName[];
  status: string;
  modalStatus: string;
} = {
  patientList: [],
  status: DATA_FETCHING_STATUS.LOADING,
  modalStatus: MODAL_STATUS.INITIAL,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    fetchAllPatientsSuccess(state, action: PayloadAction<Patient[]>) {
      state.patientList = mergeArraysWithUniqueIds(
        state.patientList,
        action.payload.map(p => ({
          ...p,
          fullName: `${p.firstName || ''} ${p.lastName || ''}`,
        })),
      );
      state.status = DATA_FETCHING_STATUS.SUCCESS;
    },
    fetchAllPatientsFail(state) {
      state.status = DATA_FETCHING_STATUS.ERROR;
    },
    setStatus(state, action: PayloadAction<string>) {
      state.status = action.payload;
    },
    setModalStatus(state, action: PayloadAction<string>) {
      state.modalStatus = action.payload;
    },
  },
  extraReducers: {},
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;
const getPatientId = (_state: RootState, patientId: UUID) => patientId;

export const selectors = {
  getPatientsList: createSelector(getState, state => state.patientList),
  selectPatient: createSelector(getState, getPatientId, (state, patientId) =>
    state.patientList.find(patient => patient.id === patientId),
  ),
  getStatus: createSelector(getState, state => state.status),
  isPatientListLoading: createSelector(
    getState,
    state => state.status === DATA_FETCHING_STATUS.LOADING,
  ),
  getModalStatus: createSelector(getState, state => state.modalStatus),
};

const extraActions = {
  fetchAllPatients: createAction(`${STATE_KEY}/fetchAllPatients`),
  deletePatient: createAction<DeletePatientPayload>(
    `${STATE_KEY}/deletePatient`,
  ),
  createPatient: createAction<CreatePatientPayload>(
    `${STATE_KEY}/createPatient`,
  ),
  updatePatient: createAction<UpdatePatientPayload>(
    `${STATE_KEY}/updatePatient`,
  ),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
