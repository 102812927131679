import React from 'react';

import { SVGProps } from '../types';

const CrossIcon = ({
  width = '12',
  height = '12',
  color = '#4A4A4A',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.067 11.8391C11.1688 11.9415 11.3071 11.9994 11.4515 12C11.673 12.0013 11.8733 11.8684 11.9583 11.6637C12.0433 11.4591 11.996 11.2234 11.8387 11.0674L6.77257 6.00111L11.8392 0.934252C11.9771 0.796399 12.0309 0.595475 11.9805 0.407165C11.93 0.218855 11.7829 0.0717679 11.5946 0.0213104C11.4063 -0.0291472 11.2054 0.0246904 11.0676 0.162543L6.00089 5.2294L0.931513 0.159839C0.793666 0.0219861 0.592749 -0.0318515 0.404446 0.018606C0.216143 0.0690636 0.069061 0.21615 0.0186054 0.404461C-0.0318503 0.592771 0.0219853 0.793695 0.159833 0.931548L5.22921 6.00111L0.160409 11.0701C0.0054018 11.226 -0.040811 11.4598 0.0432024 11.663C0.127216 11.8662 0.325022 11.999 0.544886 12C0.689844 12.0008 0.829171 11.9439 0.93209 11.8418L6.00089 6.77282L11.067 11.8391Z"
      fill={color}
    />
  </svg>
);

export default CrossIcon;
