import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { MODAL_STATUS } from 'src/components/Modal/constants';
import { RootState } from 'src/redux/store';
import { Group, GroupClient } from 'src/types/groups';
import { MetaDataType, SerialNumber, UUID } from 'src/types/utility';
import { DATA_STATE_KEY, DATA_FETCHING_STATUS } from '../../constants';

export const STATE_KEY = 'groups';

export const INITIAL_STATE: {
  groupsList: { data: Group[]; metadata: MetaDataType };
  status: keyof typeof DATA_FETCHING_STATUS;
  modalStatus: MODAL_STATUS;
  groupClients: {
    data: GroupClient[];
    status: keyof typeof DATA_FETCHING_STATUS;
  };
} = {
  groupsList: {
    data: [],
    metadata: {
      page: {
        totalResults: 0,
        page: 0,
        limit: 0,
      },
      sort: null,
    },
  },
  status: DATA_FETCHING_STATUS.LOADING,
  modalStatus: MODAL_STATUS.INITIAL,
  groupClients: {
    data: [],
    status: DATA_FETCHING_STATUS.LOADING,
  },
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setGroupListLoading(state) {
      state.status = DATA_FETCHING_STATUS.LOADING;
    },
    onLoadGroupsListSuccess(
      state,
      action: PayloadAction<{ data: Group[]; metadata: MetaDataType }>,
    ) {
      state.groupsList = action.payload;
      state.status = DATA_FETCHING_STATUS.SUCCESS;
    },
    onLoadGroupsListFail(state) {
      state.status = DATA_FETCHING_STATUS.ERROR;
    },
    setModalStatus(state, action: PayloadAction<MODAL_STATUS>) {
      state.modalStatus = action.payload;
    },
    onLoadGroupClients(state) {
      state.groupClients.data = [];
      state.groupClients.status = DATA_FETCHING_STATUS.LOADING;
    },
    getGroupClientSuccess(state, action: PayloadAction<GroupClient[]>) {
      state.groupClients.data = action.payload;
      state.groupClients.status = DATA_FETCHING_STATUS.SUCCESS;
    },
    getGroupClientFailed(state) {
      state.groupClients.data = [];
      state.groupClients.status = DATA_FETCHING_STATUS.ERROR;
    },
  },
  extraReducers: {},
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  getGroupList: createSelector(getState, state =>
    state.groupsList.data.map(group => ({
      id: group.id,
      name: group.name,
      versionName: group.version?.name || null,
      versionId: group.version?.id || null,
      versionPlusName: group.versionPlus?.name || null,
      versionPlusId: group.versionPlus?.id || null,
    })),
  ),
  getStatus: createSelector(getState, state => state.status),
  getGroupListMetadata: createSelector(
    getState,
    state => state.groupsList.metadata,
  ),
  getIsLoading: createSelector(
    getState,
    state => state.status === DATA_FETCHING_STATUS.LOADING,
  ),
  getModalStatus: createSelector(getState, state => state.modalStatus),
  getGroupClients: createSelector(getState, state => state.groupClients.data),
  getGroupClientsStatus: createSelector(
    getState,
    state => state.groupClients.status,
  ),
};

const extraActions = {
  onLoadGroupsList: createAction<{
    currentPage?: number | string;
    pageSize?: string | number;
  }>(`${STATE_KEY}/onLoadGroupsList`),
  searchAdminGroupsList: createAction<string>(
    `${STATE_KEY}/searchAllGroupsAdmin`,
  ),
  onEditGroup: createAction<{
    name: string;
    newDevicesToBeHandle: {
      clientIds: SerialNumber[];
      unassignedClientIds: SerialNumber[];
    };
    groupId: UUID;
  }>(`${STATE_KEY}/onEditGroup`),
  onCreateGroup: createAction<{
    name: string;
    newDevicesToBeHandle: {
      clientIds: SerialNumber[];
    };
  }>(`${STATE_KEY}/onCreateGroup`),
  onDeleteGroup: createAction<UUID>(`${STATE_KEY}/onDeleteGroup`),
  onUpdateGroupVersion: createAction<{
    updateVersionData: {
      versionPlusId: UUID;
      versionId: UUID;
      groupName: string;
    };
    groupId: UUID;
  }>(`${STATE_KEY}/onUpdateGroupVersion`),
  onEditApp: createAction<{ groupName: string; version: string }>(
    `${STATE_KEY}/onEditApp`,
  ),
  onLoadGroupClients: createAction(`${STATE_KEY}/onLoadGroupClients`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
