import React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

import { StyledModal } from './styled';
import AlertLogTable from 'src/routes/IndividualDashboard/components/DashboardTab/MainGraph/AlertLog/AlertLogTable';
import { Dayjs } from 'dayjs';

type Props = {
  setIsModalVisible: (isVisible: boolean) => void;
  isModalVisible: boolean;
  isLoading: boolean;
  intl: IntlShape;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
};

const AlertLogModal = ({
  setIsModalVisible,
  isModalVisible,
  isLoading,
  startDate,
  endDate,
  intl,
}: Props): JSX.Element => {
  return (
    <StyledModal
      title={intl.formatMessage(messages.title)}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      destroyOnClose={true}
      width="46rem"
      data-cy={`idb-alert-log-loading=${isLoading.toString()}`}
    >
      <AlertLogTable startDate={startDate} endDate={endDate} />
    </StyledModal>
  );
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Alert log',
  },
});

export default injectIntl(AlertLogModal);
