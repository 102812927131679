import { all, put, call, takeLatest, spawn } from 'typed-redux-saga/macro';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import qs from 'qs';

import UmsLogic from 'src/libs/ums-js-logic';
import BackendService from 'src/services/BackendService';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import { actions as authActions } from '../../auth/modules/slice';
import { actions as loggedInUserActions } from '../../loggedInUser';
import { createChannel } from '../../monitor/modules/channelUtils';
import { actions } from './slice';

type EventType = 'focus' | 'blur' | 'online' | 'offline';

function* monitorWindowEventListener(
  eventType: EventType,
  onEventAction: ActionCreatorWithoutPayload,
) {
  const channel = createChannel();
  window.addEventListener(eventType, channel.put);

  while (true) {
    yield* call(channel.take);
    yield* put(onEventAction());
  }
}

type MonitorChangesGenerator = typeof monitorWindowEventListener;

function* monitorConnectivityChanges() {
  yield* spawn<Parameters<MonitorChangesGenerator>, MonitorChangesGenerator>(
    monitorWindowEventListener,
    'online',
    actions.onInternetConnection,
  );
  yield* spawn<Parameters<MonitorChangesGenerator>, MonitorChangesGenerator>(
    monitorWindowEventListener,
    'offline',
    actions.onInternetDisconnection,
  );
}

function* monitorFocusState() {
  yield* spawn<Parameters<MonitorChangesGenerator>, MonitorChangesGenerator>(
    monitorWindowEventListener,
    'focus',
    actions.onAppFocused,
  );
  yield* spawn<Parameters<MonitorChangesGenerator>, MonitorChangesGenerator>(
    monitorWindowEventListener,
    'blur',
    actions.onAppUnfocused,
  );
}

export function* getUserTenant() {
  return yield* call(BackendService.getTenant);
}

function* handleLogin() {
  try {
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    const impersonatedSubtenantId = queryParams.impersonatedSubtenantId;
    const refreshToken = queryParams.refreshToken;

    if (
      impersonatedSubtenantId &&
      typeof impersonatedSubtenantId === 'string'
    ) {
      UmsLogic.setImpersonatedSubtenantId(impersonatedSubtenantId);
    } else {
      UmsLogic.setImpersonatedSubtenantId(null);
    }

    if (refreshToken && typeof refreshToken === 'string') {
      yield* handleLoginWithQPToken(refreshToken);

      return;
    }

    const isLoggedIn = yield* call([UmsLogic, UmsLogic.isLoggedIn]);
    const isUserRemembered = yield* call([UmsLogic, UmsLogic.isUserRemembered]);

    if (!isLoggedIn && !isUserRemembered) {
      yield* put(actions.appStartFinish());
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = yield* call([UmsLogic, UmsLogic.loginWithToken]);
    const tenantDetails = yield* call(getUserTenant);
    const userDetails = yield* call(BackendService.getUserDetails);
    yield* put(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      loggedInUserActions.loginUser({
        ...response,
        ...userDetails?.data,
        tenantDetails: { ...tenantDetails?.data },
      }),
    );

    yield* put(authActions.loginSuccess());
    yield* put(actions.appStartFinish());
  } catch (error) {
    console.log('Error in handleLogin ', error);
    yield* put(actions.appStartFinish());
    notifyUserByActionTypeAndCode(actions.appStart.type, null, error);
  }
}

function* handleLoginWithQPToken(refreshToken: string) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response = yield* call([UmsLogic, UmsLogic.loginWithToken], {
    refreshToken,
  });
  const tenantDetails = yield* call(getUserTenant);
  const userDetails = yield* call(BackendService.getUserDetails);

  yield* put(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    loggedInUserActions.loginUser({
      ...response,
      ...userDetails?.data,
      tenantDetails: { ...tenantDetails?.data },
    }),
  );

  yield* put(authActions.loginSuccess());
  yield* put(actions.appStartFinish());
}

function* onAppStart() {
  yield* spawn(monitorFocusState);
  yield* spawn(monitorConnectivityChanges);
  yield* call(handleLogin);
}

export default function* watchAppActions() {
  yield* all([takeLatest(actions.appStart, onAppStart)]);
}
