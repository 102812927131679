import { createSelector } from '@reduxjs/toolkit';

import { selectors as deviceSelectors } from 'src/redux/data/device';
import { selectors as monitorSelectors } from 'src/redux/data/monitor';
import { selectors as sessionsSelectors } from 'src/redux/data/sessions';
import { selectors as patientSelectors } from 'src/redux/data/patient';
import { selectors as roomSelectors } from 'src/redux/data/rooms';
import { DeviceMonitorStatus } from 'src/types/devices';
import { UUID } from 'src/types/utility';
import { PatientWithAssignedDevice } from 'src/types/patients';
import { RootState } from '../store';
import { selectors as alertSelectors } from 'src/redux/data/alerts';
import { getAlertDeviceIdsBySubtenant } from 'src/redux/data/groupManager/modules/utilitySaga';

const dataSelectors = {
  getDevicesWithStatuses: createSelector(
    deviceSelectors.getDevicesList,
    monitorSelectors.getDevices,
    sessionsSelectors.selectSessions,
    (devicesList, monitorDevices, deviceSessions) =>
      deviceSessions.map(device => ({
        ...device,
        status: monitorDevices[device.deviceId]?.status || null,
        connectionStatus: devicesList.find(
          dev => dev.manufacturerId === device.deviceId,
        )?.connectionStatus,
      })),
  ),

  selectDeviceListWithRoomAndBed: createSelector(
    deviceSelectors.getDevicesList,
    roomSelectors.getRoomList,
    (devices, rooms) =>
      devices.map(device => {
        const room = rooms.find(room =>
          room.beds.find(bed => bed.deviceId === device.manufacturerId),
        );
        const { beds } = room || {};
        const bed = (beds || []).find(
          bed => bed.deviceId === device.manufacturerId,
        );

        return {
          ...device,
          roomName: room?.name,
          bedName: bed?.name,
        };
      }),
  ),

  selectDeviceWithRoomAndBed: createSelector(
    deviceSelectors.getDevicesList,
    roomSelectors.getRoomList,
    (_state: RootState, deviceId: UUID) => deviceId,
    (devices, rooms, deviceId) => {
      const device = devices.find(d => d.manufacturerId === deviceId);

      if (!device) {
        return undefined;
      }

      const room = rooms.find(room =>
        room.beds.find(bed => bed.deviceId === deviceId),
      );
      const { beds } = room || {};
      const bed = (beds || []).find(bed => bed.deviceId === deviceId);

      return {
        ...device,
        roomName: room?.name,
        bedName: bed?.name,
      };
    },
  ),

  selectMTMAttentionListDeviceRoomAndBed: createSelector(
    alertSelectors.selectMTMAttentionList,
    roomSelectors.getRoomList,
    (mtmAttentionList, rooms) => {
      return getAlertDeviceIdsBySubtenant([], mtmAttentionList).map(
        deviceId => {
          const room = rooms.find(room =>
            room.beds.find(bed => bed.deviceId === deviceId),
          );
          const { beds } = room || {};
          const bed = (beds || []).find(bed => bed.deviceId === deviceId);

          return {
            deviceId,
            roomName: room?.name,
            bedName: bed?.name,
          };
        },
      );
    },
  ),

  selectDeviceRoomAndBed: createSelector(
    roomSelectors.getRoomList,
    (_state: RootState, deviceId: UUID) => deviceId,
    (rooms, deviceId) => {
      const room = rooms.find(room =>
        room.beds.find(bed => bed.deviceId === deviceId),
      );
      const { beds } = room || {};
      const bed = (beds || []).find(bed => bed.deviceId === deviceId);

      return {
        deviceId,
        roomName: room?.name,
        bedName: bed?.name,
      };
    },
  ),

  selectAssignedDevicePatients: createSelector(
    patientSelectors.getPatientsList,
    monitorSelectors.getActiveDevices,
    (rawPatients, activeDevices): PatientWithAssignedDevice[] =>
      rawPatients.map(p => {
        const assignedDevice = activeDevices.find(
          device =>
            device.patientId === p.id &&
            device.status === DeviceMonitorStatus.ACTIVE,
        );

        return {
          ...p,
          deviceId: assignedDevice?.id,
        };
      }),
  ),
};

export default dataSelectors;
