import React from 'react';

import { SVGProps } from '../types';

const UpRightArrowIcon = ({
  width = '8',
  height = '9',
  color = '#252525',
  verticalAlign = '0',
  horizontalAlign = '0',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`${horizontalAlign} ${verticalAlign} ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      d="M0.655762 7.49988L6.32227 1.5001"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M6.32227 6.16687L6.32227 1.5002L1.6556 1.5002"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);

export default UpRightArrowIcon;
