import {
  call,
  put,
  select,
  take,
  fork,
  cancel,
  spawn,
} from 'typed-redux-saga/macro';

import BackendService from 'src/services/BackendService';
import {
  FetchDevicesLatestSessionsResponse,
  PatientAlerts,
} from 'src/services/types';
import { Session, SessionStatus } from 'src/types/sessions';
import { actions as alertsActions } from 'src/redux/data/alerts/modules/slice';
// TODO: Uplift these utilities to avoid circular dependencies
import {
  mapPatientAlertsByPatientId,
  partitionAlertsByType,
  createSoundMap,
} from 'src/redux/data/alerts/modules/utils';
import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from 'src/redux/data/device/modules/slice';
import { actions as alertSidebarActions } from 'src/components/Sidebars/AlertSidebar';
import { actions as sessionsActions } from 'src/redux/data/sessions';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { actions as appActions } from 'src/redux/data/app';
import { noOp } from 'src/utils/fpUtils';
import { SerialNumber } from 'src/types/utility';
import { initMonitorActionType } from './constants';
import { createChannel } from './channelUtils';
import OnlineMonitor from './OnlineMonitor';
import { actions } from './slice';
import { ContinuousMeasurement, SpotMeasurement } from 'src/types/measurements';
import { DeviceConnectionState } from 'src/types/devices';
import { SessionInfo } from './types';
import { getAlertLogsUpdated } from 'src/utils/alertHelpers';
import { Alert } from 'src/types/alerts';

const measurementChannel = createChannel<ContinuousMeasurement[]>();
const alertChannel = createChannel<PatientAlerts>();
const hriChannel = createChannel();
const deviceStateChannel = createChannel<DeviceConnectionState>();
const sessionStateChannel = createChannel<SessionInfo>();
const spotChannel = createChannel<SpotMeasurement>();
const unmountChannel = createChannel();

function* monitorUnmount() {
  // TODO: Create Custom action for stop monitor
  const action = yield* take(alertSidebarActions.alertSidebarUnmounted);

  unmountChannel.put(action);
}

function* onFocus() {
  while (true) {
    yield* take(appActions.onAppFocused);
    const devices = yield* select(deviceSelectors.getDevicesList);
    const deviceIds = devices.map(d => d.manufacturerId);

    try {
      const { data } = yield* call(BackendService.getAllLatestSessions);
      const sessions = extractSessions(data, deviceIds);

      yield* put(sessionsActions.fetchSessionsSuccess({ sessions }));
    } catch {
      console.log(
        `An error occurred when getting device status for deviceIds: [${deviceIds.join(
          ', ',
        )}]`,
      );
    }
  }
}

export function* initMonitor(
  deviceIds: SerialNumber[],
  subscribeAlerts = false,
) {
  try {
    yield* spawn(monitorUnmount);

    const onFocusId = yield* fork(onFocus);

    const devicesStatusesResponse = yield* call(
      BackendService.getAllLatestSessions,
    );
    const sessions = extractSessions(devicesStatusesResponse.data, deviceIds);
    yield* put(sessionsActions.fetchSessionsSuccess({ sessions }));

    const tenantId = yield* select(loggedInUserSelectors.getCurrentTenantId);

    // TODO: Throw error or wait if tenantId is undefined
    yield* call(OnlineMonitor.init, deviceIds, tenantId || '', {
      onDataReceived: measurementChannel.put,
      onAlertsReceived: subscribeAlerts ? alertChannel.put : noOp,
      onHriReceived: hriChannel.put,
      onStateReceived: deviceStateChannel.put,
      onSessionStatusReceived: sessionStateChannel.put,
      onSpotReceived: spotChannel.put,
    });

    const continuousManagmentId = yield* fork(
      continuousManagement,
      measurementChannel,
    );
    const monitorHriId = yield* fork(hriManagement, hriChannel);
    const deviceStateId = yield* fork(
      deviceStateManagement,
      deviceStateChannel,
    );
    const sessionStateId = yield* fork(
      sessionStateManagement,
      sessionStateChannel,
    );
    const spotManagementId = yield* fork(spotManagement, spotChannel);

    let alertManagementId;
    if (subscribeAlerts) {
      alertManagementId = yield* fork(alertManagement, alertChannel);
    }

    yield* call(OnlineMonitor.startLoadingData);

    yield* call(unmountChannel.take);
    yield* call(OnlineMonitor.stopAllLoadingData);
    yield* cancel(continuousManagmentId);
    yield* cancel(monitorHriId);
    yield* cancel(deviceStateId);
    yield* cancel(sessionStateId);
    yield* cancel(spotManagementId);
    yield* cancel(onFocusId);
    if (subscribeAlerts && alertManagementId) {
      yield* cancel(alertManagementId);
    }
    measurementChannel.clear();
    hriChannel.clear();
    deviceStateChannel.clear();
    sessionStateChannel.clear();
    alertChannel.clear();
    spotChannel.clear();
  } catch (error) {
    console.error(
      `An error occurred while trying to init devices ${deviceIds.join(
        ', ',
      )} for patientId.\n Error:`,
      error,
    );

    yield* put(
      actions.onInitMonitorFailed({
        actionType: initMonitorActionType,
        deviceIds,
        error,
      }),
    );
  }
}

// TODO: Check why we need this
export function extractSessions(
  deviceSessionsResponse: FetchDevicesLatestSessionsResponse,
  deviceIds: SerialNumber[],
): Session[] {
  const latestSessions = deviceSessionsResponse.sessions.filter(session =>
    deviceIds.includes(session.deviceId),
  );
  const deviceIdsOfSessions = latestSessions.map(session => session.deviceId);
  const missingSessions = deviceIds
    .filter(deviceId => !deviceIdsOfSessions.includes(deviceId))
    .map(deviceId => ({
      id: '',
      deviceId,
      patientId: '',
      status: SessionStatus.EMPTY,
      saveRawData: false,
      startTime: '',
      endTime: null,
    }));

  return [...latestSessions, ...missingSessions];
}

function* continuousManagement(channel: typeof measurementChannel) {
  while (true) {
    const measurements = yield* call(channel.take);

    if (measurements.length !== 0) {
      yield* put(actions.gotContinuousDataFromOnlineMonitor(measurements));
    }
  }
}

function* spotManagement(channel: typeof spotChannel) {
  while (true) {
    const spot = yield* call(channel.take);

    yield* put(actions.gotDataFromOnlineMonitor(spot));
  }
}

function* hriManagement(channel: typeof hriChannel) {
  while (true) {
    const hri = yield* call(channel.take);

    // @ts-ignore Fix this
    yield* put(actions.onHriResult(hri));
    // @ts-ignore Fix this
    yield* put(actions.gotDataFromOnlineMonitor(hri));
  }
}

function* alertManagement(channel: typeof alertChannel) {
  while (true) {
    const patientAlerts = yield* call(channel.take);
    const timezone = yield* select(loggedInUserSelectors.getUserTenantTimezone);
    const updatedAlerts = getAlertLogsUpdated(
      patientAlerts.alerts,
      timezone,
    ) as Alert[];
    if (!patientAlerts.patientId) {
      // TODO: HANDLE LATER
      yield* put(
        alertsActions.gotAlertsFromAws({
          alertsList: updatedAlerts,
          alertsMap: {},
        }),
      );

      continue;
    }

    const newPatientAlerts = partitionAlertsByType(
      mapPatientAlertsByPatientId([patientAlerts]),
    );
    yield* put(
      alertsActions.gotAlertsFromAws({
        alertsList: updatedAlerts,
        alertsMap: newPatientAlerts,
      }),
    );
    yield* put(
      alertsActions.alertSoundsChanged(createSoundMap(newPatientAlerts)),
    );
  }
}

function* deviceStateManagement(channel: typeof deviceStateChannel) {
  while (true) {
    const deviceState = yield* call(channel.take);
    yield* put(deviceActions.setDeviceConnectionState(deviceState));

    if (deviceState.connectionStatus === 'disconnected') {
      yield* put(actions.onDeviceDisconnected(deviceState));
    }
  }
}

function* sessionStateManagement(channel: typeof sessionStateChannel) {
  while (true) {
    const { deviceId, status, patientId, sessionId } = yield* call(
      channel.take,
    );
    const updatedStatus = SessionStatus[status];

    switch (updatedStatus) {
      case SessionStatus.RUNNING:
        yield* put(
          actions.onSessionStarted({ deviceId, status, patientId, sessionId }),
        );
        break;
      case SessionStatus.FINISHED:
        yield* put(
          actions.onSessionStopped({ deviceId, status, patientId, sessionId }),
        );
        yield* put(
          sessionsActions.onSessionStopped({
            deviceId,
            status,
            patientId,
            sessionId,
          }),
        );
        break;
      case SessionStatus.EMPTY:
        yield* put(
          actions.onSessionStopped({ deviceId, status, patientId, sessionId }),
        );
        yield* put(
          sessionsActions.onSessionStopped({
            deviceId,
            status,
            patientId,
            sessionId,
          }),
        );
        break;
      case SessionStatus.STOPPING:
        yield* put(
          actions.onSessionStopping({ deviceId, status, patientId, sessionId }),
        );
        break;
      default:
        console.log(
          'Error: app is not defined for the received status update',
          status,
        );
        break;
    }
  }
}
