import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Form, Input, Upload, Button } from 'antd';
import Modal from 'src/components/Modal';
import { UploadOutlined } from '@ant-design/icons';
import Box from 'src/components/Box';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import {
  readFileAsText,
  getFieldFromFileText,
  checkDeviceType,
} from 'src/utils/fileUtils';
import { InfoFieldsWrap } from 'src/components/styled';
import { SW_VERSIONS_FILE_FIELDS } from '../constants';

const VersionModal = ({
  isModalVisible,
  setIsModalVisible,
  onOk,
  modalStatus,
  setModalStatus,
  intl,
}) => {
  const [versionName, setVersionName] = useState(null);
  const [versionId, setVersionId] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [protocol, setProtocol] = useState(null);
  const [uploadError, setUploadError] = useState('');

  const [form] = Form.useForm();
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (isModalVisible) {
      if (modalStatus === MODAL_STATUS.INITIAL) {
        form.resetFields();
      } else if (
        modalStatus === MODAL_STATUS.SUCCESS ||
        modalStatus === MODAL_STATUS.ERROR
      ) {
        setIsModalVisible(false);
        setModalStatus(MODAL_STATUS.INITIAL);
        setVersionName(null);
        setVersionId(null);
        setDeviceType(null);
        setProtocol(null);
        setUploadError('');
      }
    }
  }, [isModalVisible, modalStatus, form, setIsModalVisible, setModalStatus]);

  // TODO: maybe move to saga
  const readFileContent = async file => {
    const fileToRead = new File([file], null);
    const fileContent = await readFileAsText(fileToRead);

    const versionNameFromFile = getFieldFromFileText(
      SW_VERSIONS_FILE_FIELDS.PACK_VERSION,
      fileContent,
    );

    const versionIdFromFile = getFieldFromFileText(
      SW_VERSIONS_FILE_FIELDS.PACK_ID,
      fileContent,
    );

    const dateFromFile = getFieldFromFileText(
      SW_VERSIONS_FILE_FIELDS.DATE,
      fileContent,
    );

    const jsonFromFile = getFieldFromFileText(
      SW_VERSIONS_FILE_FIELDS.JSON,
      fileContent,
    );

    const computedDeviceType = checkDeviceType(versionNameFromFile);

    setVersionName(versionNameFromFile);
    setVersionId(versionIdFromFile);
    setDeviceType(computedDeviceType?.deviceType);
    setProtocol(computedDeviceType?.protocol);

    return {
      versionNameFromFile,
      versionIdFromFile,
      dateFromFile,
      jsonFromFile,
    };
  };

  // eslint-disable-next-line no-unused-vars
  const validateFileBeforeUpload = async file => {
    setUploadError('');
    const isLt10M = file.size / 1024 / 1024 < 10;

    if (!isLt10M) {
      setUploadError(intl.formatMessage(messages.fileSizeValidationError));
      return Upload.LIST_IGNORE;
    }

    const {
      versionNameFromFile,
      versionIdFromFile,
      dateFromFile,
      jsonFromFile,
    } = await readFileContent(file)
      .then(res => res)
      .catch(err => err);

    if (
      !versionNameFromFile ||
      !versionIdFromFile ||
      !dateFromFile ||
      !jsonFromFile
    ) {
      setUploadError(intl.formatMessage(messages.fileValidationError));
      return Upload.LIST_IGNORE;
    }

    return (
      !isLt10M &&
      !versionNameFromFile &&
      !versionIdFromFile &&
      !dateFromFile &&
      !jsonFromFile
    );
  };

  return (
    <Modal
      title={intl.formatMessage(messages.UploadVersion)}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      okButtonText={intl.formatMessage(messages.okButtonText)}
      onOk={() => {
        form.submit();
      }}
      modalStatus={modalStatus}
      setModalStatus={setModalStatus}
      onClose={() => {
        setVersionName(null);
        setVersionId(null);
        setDeviceType(null);
        setProtocol(null);
        setUploadError('');
      }}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={values => {
          setModalStatus(MODAL_STATUS.SUBMITTING);
          onOk({ ...values, versionName, versionIdFromFile: versionId });
        }}
      >
        <InfoFieldsWrap>
          <FormattedMessage {...messages.versionFieldLabel} />
          <p>{versionName && versionName}</p>
        </InfoFieldsWrap>
        <InfoFieldsWrap>
          <FormattedMessage {...messages.deviceTypeLabel} />
          <p>{deviceType && deviceType}</p>
        </InfoFieldsWrap>
        <InfoFieldsWrap>
          <FormattedMessage {...messages.protocolLabel} />
          <p>{protocol && protocol}</p>
        </InfoFieldsWrap>

        <Form.Item
          name="description"
          rules={[
            {
              message: intl.formatMessage(messages.description),
            },
          ]}
          label={intl.formatMessage(messages.description)}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          validateStatus={uploadError ? 'error' : ''}
          name="uploadFile"
          valuePropName="fileList"
          help={uploadError || intl.formatMessage(messages.fileValidationHint)}
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: intl.formatMessage(messages.fileError),
            },
          ]}
        >
          <Upload maxCount={1} beforeUpload={validateFileBeforeUpload}>
            <Button>
              <Box hAligned>
                <UploadOutlined />
                <div />
                <FormattedMessage {...messages.upload} />
              </Box>
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};
const messages = defineMessages({
  UploadVersion: {
    defaultMessage: 'Upload Version',
  },
  apply: {
    defaultMessage: 'Apply',
  },
  versionFieldLabel: {
    defaultMessage: 'Version',
  },
  deviceTypeLabel: {
    defaultMessage: 'Device Type',
  },
  protocolLabel: {
    defaultMessage: 'Protocol',
  },
  okButtonText: {
    defaultMessage: 'upload',
  },
  versionError: {
    defaultMessage: 'No spaces allowed',
  },
  upload: {
    defaultMessage: 'browse',
  },
  fileError: {
    defaultMessage: 'File is required',
  },
  fileValidationError: {
    defaultMessage:
      'Version file upload failed due to non-compliance with the requirements.',
  },
  fileSizeValidationError: {
    defaultMessage: "Version file size shouldn't be bigger than 10mb.",
  },
  fileExtensionValidationError: {
    defaultMessage: 'Version file extension is not supported.',
  },
  fileValidationHint: {
    defaultMessage:
      'File should be less than 10MB and should contain proper content',
  },
  description: {
    defaultMessage: 'Description',
  },
});

VersionModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
};

VersionModal.defaultProps = {};

export default injectIntl(VersionModal);
