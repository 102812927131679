import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { deepEqual } from 'src/utils/comparators';
import { UUID } from 'src/types/utility';
import { getUsersTenantColumns, getTableActions } from './utils';
import { StyledTable, StyledSpacedRow, StyledActionButton } from './styled';
import UserModal from 'src/components/UserCrudComponents/UserModal';
import EditableCell from 'src/components/CrudCommonComponents/EditableCell';
import EditableRow from 'src/components/CrudCommonComponents/EditableRow';
import {
  userFormModes,
  USER_STATUS,
} from 'src/components/UserCrudComponents/constants';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import { UserTableItem } from '../../modules/types';
import Connector, { PropsFromRedux } from './Connector';
import {
  UserActivitySubscription,
  UserMedicalSubscription,
  UserTechnicalSubscription,
} from 'src/types/subscribers';
import permissions from 'src/permissions';
import AccessControl from 'src/components/AccessControl';
import { phonePattern } from 'src/utils/constants';

type Props = PropsFromRedux & { intl: IntlShape };

type UserFormData = {
  name: string;
  email: string;
  userType?: string;
};

export const FORM_MODES = {
  INVITE: 'invite',
  EDIT: 'edit',
};

const TenantUsersTable = ({
  selectedTenantId,
  loggedInUserId,
  isGmTenantSelected,
  intl,
  usersTableData,
  tenantGmUserSubscriptions,
  isLoadingUsers,
  areSubtenantUsersLoading,
  areSubtenantSubscribersLoading,
  resendUserInvitation,
  updateTenantSubscribers,
  modalStatus,
  setModalStatus,
  updateUser,
  updateUserByTenantId,
  deleteUserByTenantId,
  deleteInvitationByTenantId,
  sendMessageForResetPassword,
  updateUserPhoneNumber,
  updateUserPhoneNumberByTenantId,
}: Props): JSX.Element => {
  const EditableContext = React.createContext(null);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [editableUserData, setEditableUserData] =
    useState<UserTableItem | null>(null);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState<boolean>(false);
  const [usersAssignedToEmailMedical, setUsersAssignedToEmailMedical] =
    useState<UserMedicalSubscription[]>([]);
  const [usersAssignedToSmsMedical, setUsersAssignedToSmsMedical] = useState<
    UserMedicalSubscription[]
  >([]);
  const [usersAssignedToSmsActivity, setUsersAssignedToSmsActivity] = useState<
    UserActivitySubscription[]
  >([]);
  const [usersAssignedToEmailActivity, setUsersAssignedToEmailActivity] =
    useState<UserActivitySubscription[]>([]);
  const [usersAssignedToEmailTechnical, setUsersAssignedToEmailTechnical] =
    useState<UserTechnicalSubscription[]>([]);
  const [usersAssignedToSmsTechnical, setUsersAssignedToSmsTechnical] =
    useState<UserTechnicalSubscription[]>([]);

  const columns = getUsersTenantColumns(
    intl,
    usersAssignedToEmailMedical,
    setUsersAssignedToEmailMedical,
    usersAssignedToSmsMedical,
    setUsersAssignedToSmsMedical,
    usersAssignedToEmailTechnical,
    setUsersAssignedToEmailTechnical,
    usersAssignedToSmsTechnical,
    setUsersAssignedToSmsTechnical,
    usersAssignedToSmsActivity,
    setUsersAssignedToSmsActivity,
    usersAssignedToEmailActivity,
    setUsersAssignedToEmailActivity,
  );

  const actions = getTableActions(
    loggedInUserId,
    selectedTenantId,
    isGmTenantSelected ? 'groupManager' : 'subtenant',
    intl,
    resendUserInvitation,
    setModalVisible,
    setEditableUserData,
    setIsDeleteConfirmationVisible,
    sendMessageForResetPassword,
  );

  useEffect(() => {
    setUsersAssignedToEmailMedical(
      usersTableData.reduce(
        (acc: UserMedicalSubscription[], user) =>
          user.emailMedicalAlert
            ? [
                ...acc,
                {
                  alertCategory: 'MEDICAL',
                  id: user.id,
                  targetMedia: 'EMAIL',
                  withPersonalDetails: user.emailMedicalWithPersonalDetails,
                  summaryReportEnabled: null,
                  summaryReportTime: null,
                  singleDisconnectionAllowedType: null,
                  emailCcEnabled: null,
                  emailCcRecipients: null,
                },
              ]
            : acc,
        [],
      ),
    );
    setUsersAssignedToSmsMedical(
      usersTableData.reduce(
        (acc: UserMedicalSubscription[], user) =>
          user.smsMedicalAlert
            ? [
                ...acc,
                {
                  alertCategory: 'MEDICAL',
                  id: user.id,
                  targetMedia: 'SMS',
                  withPersonalDetails: false,
                  summaryReportEnabled: null,
                  summaryReportTime: null,
                  singleDisconnectionAllowedType: null,
                  emailCcEnabled: null,
                  emailCcRecipients: null,
                },
              ]
            : acc,
        [],
      ),
    );
    setUsersAssignedToEmailTechnical(
      usersTableData.reduce(
        (acc: UserTechnicalSubscription[], user) =>
          user.emailTechnicalAlert
            ? [
                ...acc,
                {
                  alertCategory: 'TECHNICAL',
                  id: user.id,
                  targetMedia: 'EMAIL',
                  withPersonalDetails: false,
                  summaryReportEnabled: null,
                  summaryReportTime: null,
                  singleDisconnectionAllowedType: null,
                  emailCcEnabled: null,
                  emailCcRecipients: null,
                },
              ]
            : acc,
        [],
      ),
    );
    setUsersAssignedToSmsTechnical(
      usersTableData.reduce(
        (acc: UserTechnicalSubscription[], user) =>
          user.smsTechnicalAlert
            ? [
                ...acc,
                {
                  alertCategory: 'TECHNICAL',
                  id: user.id,
                  targetMedia: 'SMS',
                  withPersonalDetails: false,
                  summaryReportEnabled: null,
                  summaryReportTime: null,
                  singleDisconnectionAllowedType: null,
                  emailCcEnabled: null,
                  emailCcRecipients: null,
                },
              ]
            : acc,
        [],
      ),
    );
    setUsersAssignedToSmsActivity(
      usersTableData.reduce(
        (acc: UserActivitySubscription[], user) =>
          user.smsActivityAlert
            ? [
                ...acc,
                {
                  alertCategory: 'ACTIVITY',
                  id: user.id,
                  targetMedia: 'SMS',
                },
              ]
            : acc,
        [],
      ),
    );
    setUsersAssignedToEmailActivity(
      usersTableData.reduce(
        (acc: UserActivitySubscription[], user) =>
          user.emailActivityAlert
            ? [
                ...acc,
                {
                  alertCategory: 'ACTIVITY',
                  id: user.id,
                  targetMedia: 'EMAIL',
                },
              ]
            : acc,
        [],
      ),
    );
  }, [usersTableData]);

  const ref = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState<number | null>(null);

  const getTableHeight = () => {
    const node = ref.current;
    const { top } = node ? node.getBoundingClientRect() : { top: 0 };

    setTableHeight(window.innerHeight - top - 170);
  };

  useEffect(() => {
    window.addEventListener('resize', getTableHeight);
  }, []);

  useLayoutEffect(() => {
    getTableHeight();
  }, [ref]);

  const handleSubmit = () => {
    updateTenantSubscribers({
      tenantId: selectedTenantId,
      tenantType: isGmTenantSelected ? 'groupManager' : 'subtenant',
      data: {
        usersAssignedToEmail: {
          MEDICAL: [
            ...usersAssignedToEmailMedical,
            ...tenantGmUserSubscriptions.EMAIL.MEDICAL,
          ],
          TECHNICAL: [
            ...usersAssignedToEmailTechnical,
            ...tenantGmUserSubscriptions.EMAIL.TECHNICAL,
          ],
          ACTIVITY: [
            ...usersAssignedToEmailActivity,
            ...tenantGmUserSubscriptions.EMAIL.ACTIVITY,
          ],
        },
        usersAssignedToSMS: {
          MEDICAL: [
            ...usersAssignedToSmsMedical,
            ...tenantGmUserSubscriptions.SMS.MEDICAL,
          ],
          TECHNICAL: [
            ...usersAssignedToSmsTechnical,
            ...tenantGmUserSubscriptions.SMS.TECHNICAL,
          ],
          ACTIVITY: [
            ...usersAssignedToSmsActivity,
            ...tenantGmUserSubscriptions.SMS.ACTIVITY,
          ],
        },
      },
    });
  };

  const updateCell = (userId: UUID, phone: string) => {
    isGmTenantSelected
      ? updateUserPhoneNumber({
          userId: userId,
          data: { phone },
        })
      : updateUserPhoneNumberByTenantId({
          formData: { phone },
          userId: userId,
          selectedTenantId,
        });
  };

  const components = {
    body: {
      row: (props: unknown) => (
        // @ts-ignore createContext fix later
        <EditableRow EditableContext={EditableContext} {...props} />
      ),
      cell: (props: unknown) => (
        <EditableCell
          EditableContext={EditableContext}
          updateElement={updateCell}
          // @ts-ignore fix later
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          disabled={props.record?.status === USER_STATUS.PENDING}
          cellName="phone"
          validatePattern={phonePattern}
          // @ts-ignore fix later
          {...props}
          isRequired={[
            ...usersAssignedToSmsMedical,
            ...usersAssignedToSmsTechnical,
            // @ts-ignore fix later
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          ].includes(props.record?.id)}
        />
      ),
    },
  };

  const isDataLoading = isGmTenantSelected
    ? isLoadingUsers
    : areSubtenantUsersLoading || areSubtenantSubscribersLoading;

  return (
    <div ref={ref}>
      <StyledTable
        data-cy={`mtm-users-table-loading=${JSON.stringify(isDataLoading)}`}
        data={usersTableData}
        columns={columns}
        scroll={{ y: tableHeight }}
        actions={actions}
        loading={isDataLoading}
        components={components}
      />
      <StyledSpacedRow>
        <AccessControl permissions={[permissions.ALERT_SUBSCRIBER_CREATE]}>
          <StyledActionButton onClick={() => handleSubmit()}>
            <FormattedMessage {...messages.apply} />
          </StyledActionButton>
        </AccessControl>
      </StyledSpacedRow>
      {/* @ts-ignore ConfirmationModal to be refactored to ts file */}
      <ConfirmationModal
        isModalVisible={isDeleteConfirmationVisible}
        setIsModalVisible={(value: boolean) =>
          setIsDeleteConfirmationVisible(value)
        }
        onOk={() => {
          editableUserData?.status === 'Active'
            ? deleteUserByTenantId({
                tenantId: selectedTenantId,
                tenantType: isGmTenantSelected ? 'groupManager' : 'subtenant',
                userId: editableUserData?.id,
              })
            : deleteInvitationByTenantId({
                tenantId: selectedTenantId,
                tenantType: isGmTenantSelected ? 'groupManager' : 'subtenant',
                userId: editableUserData?.id,
              });
        }}
        message={intl.formatMessage(messages.deleteConfirmation)}
      />
      <UserModal
        isModalVisible={modalVisible}
        setIsModalVisible={(value: boolean) => setModalVisible(value)}
        onSubmit={(formData: UserFormData) => {
          if (!selectedTenantId) {
            return;
          }
          setModalVisible(false);
          isGmTenantSelected
            ? updateUser({
                userId: editableUserData?.id || '',
                data: {
                  ...formData,
                  userType: editableUserData?.userType.name,
                },
              })
            : updateUserByTenantId({
                formData,
                userId: editableUserData?.id || '',
                selectedTenantId,
              });
        }}
        fields={{
          firstName: true,
          lastName: true,
          email: true,
          userType: false,
          mfaPhone: true,
        }}
        mode={userFormModes.EDIT}
        modalMessage={undefined}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        currentUser={editableUserData}
      />
    </div>
  );
};

const messages = defineMessages({
  apply: {
    defaultMessage: 'Apply',
  },
  deleteConfirmation: {
    defaultMessage: 'Are you sure you want to delete this user?',
  },
});

export default Connector(
  injectIntl(
    React.memo(TenantUsersTable, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
