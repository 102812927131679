import React, { useEffect } from 'react';
import styled from 'styled-components';
import SubtenantCards from './components/SubtenantCards';

import SummaryPanel from './components/SummaryPanel';
import Connector, { PropsFromRedux } from './Connector';
import { defineMessages, FormattedMessage } from 'react-intl';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: ${props => props.theme.fonts.primary};
  background: #e3f3f5;
  color: #252525;
  padding: 0.65rem 1.25rem 1.4rem;
`;

const StyledTitle = styled.div`
  margin-bottom: 0.8rem;
  margin-left: 0.25rem;
  font-weight: 700;
  font-size: 1rem;
  color: #767881;
`;

type Props = PropsFromRedux;

const GMDashboardPage = ({ gmDashboardPageMounted }: Props): JSX.Element => {
  useEffect(() => {
    gmDashboardPageMounted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <StyledTitle>
        <FormattedMessage {...messages.title} />
      </StyledTitle>
      <SummaryPanel />
      <SubtenantCards />
    </Wrapper>
  );
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Overview of the previous 24 hours',
  },
});

export default Connector(GMDashboardPage);
