import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { appRoutes } from '../modules/routesUtils';
import { DEFAULT_LOCALE } from '../../../utils/languageUtils';

const RedirectToDefaultLocale = () =>
  Object.values(appRoutes).map(route => (
    <Route
      key={route}
      path={`/${route}`}
      component={({ location }) => (
        <Redirect
          to={{
            ...location,
            pathname: `/${DEFAULT_LOCALE}${location.pathname}`,
          }}
        />
      )}
    />
  ));

export default RedirectToDefaultLocale;
