import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { SerialNumber, UUID } from 'src/types/utility';
import {
  actions as notesActions,
  selectors as notesSelectors,
} from 'src/redux/data/notes';
import { NoteCounterMetadata } from 'src/types/notes';

type OwnProps = {
  patientId: UUID;
  deviceId: SerialNumber;
  tenantId: UUID;
  counterMetadata?: NoteCounterMetadata;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  notes: [...notesSelectors.selectPatientNotes(state, ownProps.patientId)]
    .sort((a, b) => a.creationTime.localeCompare(b.creationTime))
    .map(note => ({
      id: note.id,
      type: note.type,
      patientId: ownProps.patientId,
    })),
});

const mapActionCreators = {
  fetchPatientNotes: notesActions.fetchPatientNotes,
  createNote: notesActions.createNote,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type Props = PropsFromRedux & OwnProps;

export default Connector;
