import React from 'react';
import { styled, useTheme } from 'styled-components';

import { AlertLog, AlertType, ThresholdPreposition } from 'src/types/alerts';
import Icon from '../general-ui/Icon';
import { StyledAlertIcon } from '../AlertIndicator/styled';
import { alertIcons } from '../AlertIndicator/utils';
import { ALERT_STATUS_ENUM } from 'src/utils/constants';
import {
  isAverageAlert,
  isBaselineAlert,
  isTechnicalAlert,
} from 'src/redux/data/alerts/modules/typeGuards';
import { ALERT_METRIC_PREPOSITION_ENUM } from 'src/redux/data/constants';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AlertIconWrapper = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.alertBackground};
  border: 1px solid ${props => props.color};
  border-radius: 100%;
  white-space: nowrap;

  svg {
    margin: 0.2rem;
  }
`;

const ArrowIcon = styled(Icon)<{ h: string }>`
  width: 1rem;
  height: ${props => props.h || '0.6rem'};
`;

export const getArrowIcon = (
  thresholdMetric: AlertType,
  thresholdPreposition?: ThresholdPreposition,
) => {
  if (isAverageAlert(thresholdMetric)) {
    switch (thresholdPreposition) {
      case ALERT_METRIC_PREPOSITION_ENUM.ABOVE:
        return 'up-right-arrow-icon';
      case ALERT_METRIC_PREPOSITION_ENUM.BELOW:
        return 'down-right-arrow-icon';
    }
  }

  if (isBaselineAlert(thresholdMetric)) {
    switch (thresholdPreposition) {
      case ALERT_METRIC_PREPOSITION_ENUM.ABOVE:
        return 'up-trend-arrow-icon';
      case ALERT_METRIC_PREPOSITION_ENUM.BELOW:
        return 'down-trend-arrow-icon';
    }
  }

  return 'right-arrow-icon';
};

type Props = Pick<
  AlertLog,
  'thresholdMetric' | 'thresholdPreposition' | 'status'
>;

const AlertLogTypeCell = ({
  thresholdMetric,
  thresholdPreposition,
  status,
}: Props): JSX.Element | null => {
  const theme = useTheme();

  const color =
    status === ALERT_STATUS_ENUM.SUPPRESSED
      ? theme.colors.suppressedAlertColor
      : theme.colors.alertBorder;

  if (isTechnicalAlert(thresholdMetric)) {
    return null;
  }
  return (
    <Wrapper>
      <AlertIconWrapper color={color}>
        <StyledAlertIcon type={alertIcons[thresholdMetric]} color={color} />
      </AlertIconWrapper>
      {(isAverageAlert(thresholdMetric) ||
        isBaselineAlert(thresholdMetric)) && (
        <ArrowIcon
          h={isBaselineAlert(thresholdMetric) ? '1rem' : '0.6rem'}
          type={getArrowIcon(
            thresholdMetric,
            thresholdPreposition || undefined,
          )}
        />
      )}
    </Wrapper>
  );
};

export default AlertLogTypeCell;
