export const extractStringEnvVar = (key: keyof NodeJS.ProcessEnv): string => {
  const value = process.env[key];

  if (value === undefined) {
    const message = `The environment variable "${key}" cannot be "undefined".`;

    throw new Error(message);
  }

  return value;
};

export const extractNumberEnvVar = (key: keyof NodeJS.ProcessEnv): number => {
  const stringValue = extractStringEnvVar(key);

  const numberValue = parseFloat(stringValue);

  if (Number.isNaN(numberValue)) {
    const message = `The environment variable "${key}" has to hold a stringified number value - not ${stringValue}`;

    throw new Error(message);
  }

  return numberValue;
};

const booleanMap = {
  true: true,
  false: false,
};

const isObjKey = <T extends object>(key: PropertyKey, obj: T): key is keyof T =>
  key in obj;

export const extractBooleanEnvVar = (key: keyof NodeJS.ProcessEnv): boolean => {
  const stringValue = extractStringEnvVar(key);

  if (!isObjKey(stringValue, booleanMap)) {
    const message = `The environment variable "${key}" has to hold a stringified "true" or "false" value - not ${stringValue}.`;

    throw new Error(message);
  }

  return booleanMap[stringValue];
};
