import { defineMessages, IntlShape } from 'react-intl';
import { ActiveUserWithStatus } from 'src/redux/data/user/modules/types';
import { TableColumnConfig } from 'src/types/table';
import { stringFilter } from 'src/utils/tableUtils';

export const SMS_EMAIL_ALERTS_TABLE_COLUMNS_KEYS = {
  FULL_NAME: 'FULL_NAME',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
};

const SMSEmailAlertsColumnsMessages = defineMessages({
  fullName: {
    defaultMessage: 'HP Name',
  },
  email: {
    defaultMessage: 'E-mail Address',
  },
  phone: {
    defaultMessage: 'Phone Number',
  },
});

export type UserTableItem = ActiveUserWithStatus;

export const getSMSEmailAlertsColumns = (
  intl: IntlShape,
): TableColumnConfig<UserTableItem>[] => [
  {
    title: intl.formatMessage(SMSEmailAlertsColumnsMessages.fullName),
    dataIndex: 'fullName',
    key: SMS_EMAIL_ALERTS_TABLE_COLUMNS_KEYS.FULL_NAME,
    filtered: true,
    onFilter: stringFilter(
      (value, record) =>
        record.email.toLowerCase().includes(value.toLowerCase()) ||
        record.fullName.toLowerCase().includes(value.toLowerCase()) ||
        (record.phone || '').toString().includes(value.toString()),
    ),
    sorter: (a, b) => a.fullName.localeCompare(b.fullName),
  },
  {
    title: intl.formatMessage(SMSEmailAlertsColumnsMessages.email),
    dataIndex: 'email',
    key: SMS_EMAIL_ALERTS_TABLE_COLUMNS_KEYS.EMAIL,
    filtered: true,
    onFilter: stringFilter(
      (value, record) =>
        record.email.toLowerCase().includes(value.toLowerCase()) ||
        record.fullName.toLowerCase().includes(value.toLowerCase()),
    ),
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: intl.formatMessage(SMSEmailAlertsColumnsMessages.phone),
    dataIndex: 'phone',
    key: SMS_EMAIL_ALERTS_TABLE_COLUMNS_KEYS.PHONE,
    render: (_, record) => record.phone,
    editable: true,
    onCell: record => ({
      record,
      editable: true,
      dataIndex: 'phone',
      title: intl.formatMessage(SMSEmailAlertsColumnsMessages.phone),
    }),
  },
];
