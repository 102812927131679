import { useEffect, useState } from 'react';
import { ALL_TENANTS_OPTION } from 'src/routes/GMDashboard/modules/constants';
import {
  mapThresholdsArrayToState,
  TenantThresholdsState,
} from 'src/components/AlertSettingsComponents/AlertThresholdsForm';
import { SubtenantItem } from 'src/routes/GMDashboard/modules/types';
import { DataTenantDict } from 'src/redux/data/groupManager/modules/types';
import { AlertThreshold, BaselineAlertThreshold } from 'src/types/alerts';
import {
  mapBaselineThresholdsArrayToState,
  TenantBaselineThresholdsState,
} from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';

type Props = {
  gmThresholds: TenantThresholdsState;
  selectedSubtenants: SubtenantItem[];
  thresholdsDict: DataTenantDict;
  allThresholds: AlertThreshold[];
  gmBaselineThresholds: TenantBaselineThresholdsState;
  allBaselineThresholds: BaselineAlertThreshold[];
  baselineThresholdsDict: DataTenantDict;
};

export const useMedicalAlertsTresholds = ({
  gmThresholds,
  selectedSubtenants,
  thresholdsDict,
  allThresholds,
  gmBaselineThresholds,
  allBaselineThresholds,
  baselineThresholdsDict,
}: Props) => {
  const [thresholdsData, setThresholdsData] =
    useState<TenantThresholdsState>(gmThresholds);
  const [baselineThresholdsData, setBaselineThresholdData] =
    useState<TenantBaselineThresholdsState>(gmBaselineThresholds);

  useEffect(() => {
    if (selectedSubtenants.length === 1) {
      const selectedTenantId = selectedSubtenants[0]?.value;
      const selectedThresholdData =
        !selectedTenantId || selectedTenantId === ALL_TENANTS_OPTION
          ? gmThresholds
          : mapThresholdsArrayToState(
              allThresholds.filter(
                thr => thresholdsDict[thr.id] === selectedTenantId,
              ),
            );

      setThresholdsData(selectedThresholdData);

      return;
    }

    setThresholdsData(gmThresholds);
  }, [selectedSubtenants, gmThresholds, allThresholds, thresholdsDict]);

  useEffect(() => {
    if (selectedSubtenants.length === 1) {
      const selectedTenantId = selectedSubtenants[0]?.value;
      const selectedBaselineThresholdData =
        !selectedTenantId || selectedTenantId === ALL_TENANTS_OPTION
          ? gmBaselineThresholds
          : mapBaselineThresholdsArrayToState(
              allBaselineThresholds.filter(
                thr => baselineThresholdsDict[thr.id] === selectedTenantId,
              ),
            );

      setBaselineThresholdData(selectedBaselineThresholdData);

      return;
    }

    setBaselineThresholdData(gmBaselineThresholds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedSubtenants,
    gmBaselineThresholds,
    gmBaselineThresholds,
    allBaselineThresholds,
    baselineThresholdsDict,
  ]);

  return {
    thresholdsData,
    baselineThresholdsData,
    setBaselineThresholdData,
    setThresholdsData,
  };
};
