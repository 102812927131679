import { InternalAxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';

import { UserLoginData } from 'src/types/users';
import permissions from 'src/permissions';
import ums from '../../biot-ums-js-sdk';
import TokenHandler from '../../js-token-handler';
import { SubtenantTokensState } from './types';
import { UUID } from 'src/types/utility';
import { DEFAULT_SUBTENANTS_TOKENS_STATE } from './constants';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import { jwtDecode } from 'jwt-decode';
import { LOCAL_STORAGE_ITEMS } from 'src/utils/constants';

const USER_STORAGE_KEY = 'USER_STORAGE_KEY';
const IMPERSONATED_SUBTENANT_ID_KEY = 'IMPERSONATED_SUBTENANT_ID_KEY';

const INITIAL_UI_PERMISSIONS: string[] = [];

export const PERMISSIONS_CHECK_STRATEGY = Object.freeze({
  ALL: 'ALL',
  ANY: 'ANY',
});

const CHECK_PERMISSIONS_MAP = Object.freeze({
  [PERMISSIONS_CHECK_STRATEGY.ALL]: (
    allPermissions: string[],
    checkPermissions: string[],
  ) =>
    checkPermissions.every(permission => allPermissions.includes(permission)),
  [PERMISSIONS_CHECK_STRATEGY.ANY]: (
    allPermissions: string[],
    checkPermissions: string[],
  ) => checkPermissions.some(permission => allPermissions.includes(permission)),
});

class UmsSmartSdk {
  _tokenHandler: TokenHandler;
  _onRefreshTokenFail: (e: unknown) => void;
  session: Record<string, string>;
  _localStorage: Storage;
  _sessionStorage: Storage;
  _uiPermissions: string[];
  _subtenantTokensState: SubtenantTokensState = DEFAULT_SUBTENANTS_TOKENS_STATE;
  _impersonatedSubtenantId: UUID | null = null;

  _getDummyStorage = (): Storage => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const setItem = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const removeItem = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const clear = () => {};
    const key = () => null;
    const getItem = () => null;

    return { setItem, getItem, removeItem, clear, key, length: 0 };
  };

  _getInMemoryStorage = (): Storage => {
    this.session = {};

    const setItem = (key: string, value: string) => {
      this.session[key] = value;
    };
    const getItem = (key: string) => this.session[key] || null;
    const removeItem = (key: string) => {
      delete this.session[key];
    };
    const clear = () => {
      this.session = {};
    };
    const key = () => null;

    return { setItem, getItem, removeItem, clear, key, length: 0 };
  };

  /**
   *
   * @param umsBaseURL Ums base url.
   * @param timeout Api calls timeout.
   * @param onRefreshTokenFail Callback for when refresh token api fails. (should be logout)
   *
   * @return
   */
  init = ({
    umsBaseURL,
    timeout,
    onRefreshTokenFail,
    localStorage,
    sessionStorage,
  }: {
    umsBaseURL: string;
    timeout: number | undefined;
    onRefreshTokenFail: (e: unknown) => void;
    localStorage: Storage;
    sessionStorage: Storage;
  }) => {
    ums.init({
      umsBaseURL,
      timeout,
      requestInterceptor: this._requestInterceptor,
    });
    this._tokenHandler = new TokenHandler();
    this._tokenHandler.setConfig({ onRefreshToken: this._onRefreshToken });

    this._onRefreshTokenFail = onRefreshTokenFail;

    this._localStorage = localStorage || this._getDummyStorage();
    this._sessionStorage = sessionStorage || this._getInMemoryStorage();

    this._initTokens();

    this._uiPermissions = INITIAL_UI_PERMISSIONS;
  };

  /**
   * @param email User email.
   * @param password User password.
   * @param tenantId Tenant id for multi-tenant system (not mandatory).
   * @param rememberMe If true save user data to local storage (not mandatory).
   *
   * @return userData object - ums response.
   */
  login = async ({
    username,
    password,
    code,
    rememberMe = false,
  }: {
    username: string;
    password: string;
    code?: string;
    rememberMe?: boolean;
  }) => {
    const loginResponse = await ums.login({ username, password, code });

    if (this._localStorage === this._getDummyStorage() && rememberMe) {
      console.warn(
        "you must set localStorage in 'init' to use the remember me feature",
      );
    }
    await this._afterLogin(loginResponse, rememberMe);
    return loginResponse;
  };

  /**
   * @param request Object that may contain refreshToken, in most cases you
   * prefer not to send it and let the UmsLogic package handle it by itself (not mandatory)
   *
   * @return void
   */
  logout = async (request?: { refreshToken: string }) => {
    const refreshToken = this._getRefreshToken(request);

    await ums.logout({ refreshToken });

    this.resetStorages();
  };

  /**
   * This function reset local storage / session storage and tokens data.
   * This method should be called if logout fail and we want to force the user to logout.
   */
  resetStorages = () => {
    this._tokenHandler.resetTokens();

    this._subtenantTokensState = DEFAULT_SUBTENANTS_TOKENS_STATE;
    this._impersonatedSubtenantId = null;

    this._localStorage.removeItem(USER_STORAGE_KEY);
    this._localStorage.removeItem(LOCAL_STORAGE_ITEMS.sound);
    this._localStorage.removeItem(LOCAL_STORAGE_ITEMS.repetitions);
    this._localStorage.removeItem(LOCAL_STORAGE_ITEMS.delay);
    this._localStorage.removeItem(LOCAL_STORAGE_ITEMS.statisticsFilter);
    this._sessionStorage.removeItem(USER_STORAGE_KEY);
    this._sessionStorage.removeItem(IMPERSONATED_SUBTENANT_ID_KEY);
    window.location.reload();
  };

  /**
   * @param request Object that may contain refreshToken, in most cases you
   * prefer not to send it and let the UmsLogic package handle it by itself (not mandatory)
   *
   * @return userData object - ums response.
   */
  loginWithToken = async (request?: { refreshToken: string }) => {
    const refreshTokenToUse = this._getRefreshToken(request);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const loginResponse = await ums.loginWithToken({
      refreshToken: refreshTokenToUse,
    });
    const rememberMe = !!this._getUserFromLocalStorage();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    await this._afterLogin(loginResponse, rememberMe);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return loginResponse;
  };

  /**
   * login with mfa
   **/
  loginWithMfa = async ({ code }: { code: string }) => {
    const accessToken = this._getAccessToken();

    const loginResponse = await ums.mfaLogin({
      accessToken,
      code: code,
    });
    const rememberMe = !!this._localStorage.getItem(USER_STORAGE_KEY);

    await this._afterLogin(loginResponse, rememberMe);

    return loginResponse;
  };
  /**
   * resend mfa code
   **/
  resendMfaCode = async () => {
    const accessToken = this._getAccessToken();
    return await ums.mfaResendCode({
      accessToken,
    });
  };

  /**
   * @return userData object - ums response.
   */
  getLoginData = () => this._getUserFromSessionStorage();

  /**
   * @return true if user logged in, false otherwise.
   */
  isLoggedIn = () => {
    const loginData = this.getLoginData();

    return (
      !!loginData &&
      !loginData?.passwordResetRequired &&
      !loginData?.mfaRequired
    );
  };

  /**
   * @return true if logged in with remember me, false otherwise.
   */
  isUserRemembered = () => {
    return this._getUserFromLocalStorage() !== null;
  };

  getUiPermissions = async () => {
    const accessToken = this._getAccessToken();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const permissionsResponse = await ums.getUiPermissions({ accessToken });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this._uiPermissions =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      permissionsResponse?.permissions || INITIAL_UI_PERMISSIONS;
    const accessJwt = this._tokenHandler.getAccessToken()?.accessToken;

    if (accessJwt) {
      const decodedJwtToken = jwtDecode<{ scopes: string[] }>(accessJwt);
      this._uiPermissions = [
        ...this._uiPermissions,
        ...(decodedJwtToken?.scopes ? decodedJwtToken.scopes : []),
      ] as string[];
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return permissionsResponse;
  };

  hasPermissions = (
    strategy: keyof typeof PERMISSIONS_CHECK_STRATEGY,
    ...permissions: string[]
  ) => {
    if (permissions.length === 0) {
      return true;
    }

    const checkPermissionsFunction = CHECK_PERMISSIONS_MAP[strategy];
    return checkPermissionsFunction(this._uiPermissions, permissions);
  };

  _initTokens = () => {
    const userData =
      this._getUserFromSessionStorage() || this._getUserFromLocalStorage();

    if (!userData) {
      return;
    }

    this._handleTokens(userData);
  };

  _handleTokens = ({ accessJwt, refreshJwt }: UserLoginData) => {
    this._tokenHandler.setAccessToken({
      token: accessJwt.token,
      expirationTime: new Date(accessJwt.expiration).getTime(),
    });
    this._tokenHandler.setRefreshToken({
      token: refreshJwt.token,
      expirationTime: new Date(refreshJwt.expiration).getTime(),
    });
  };

  /**
   *
   * @param {string} refreshToken in most cases should be undefined
   */
  refreshToken = async ({ refreshToken }: { refreshToken?: string }) => {
    const refreshTokenToUse = this._getRefreshToken({ refreshToken });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const tokensResponse = await ums.refreshToken({
      refreshToken: refreshTokenToUse,
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this._updateTokenInSession(tokensResponse);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this._updateTokenInLocal(tokensResponse);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this._handleTokens(tokensResponse);

    if (this.hasPermissions('ALL', permissions.VIEW_TENANT_GROUP)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      await this.refreshGMSubtenantTokens(tokensResponse.accessJwt.token);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return tokensResponse;
  };

  _onRefreshToken = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return await this.refreshToken({});
    } catch (e) {
      console.log('Refresh token failed with error:', e);
      this._onRefreshTokenFail(e);
      throw Error;
    }
  };

  _updateTokenInSession(tokens: UserLoginData) {
    this._updateTokensInStorage(this._sessionStorage, tokens);
  }

  _updateTokenInLocal(tokens: UserLoginData) {
    this._updateTokensInStorage(this._localStorage, tokens);
  }

  _updateTokensInStorage(storageService: Storage, tokens: UserLoginData) {
    const userData = storageService.getItem(USER_STORAGE_KEY);

    if (!userData) {
      return;
    }

    const parsedData = JSON.parse(userData) as UserLoginData;

    storageService.setItem(
      USER_STORAGE_KEY,
      JSON.stringify({
        ...parsedData,
        ...tokens,
      }),
    );
  }

  _requestInterceptor = (
    request: InternalAxiosRequestConfig &
      Partial<{ requireAuth: boolean; token?: string }>,
  ) => {
    if (!request.requireAuth || request.token) {
      // If request does not require auth OR already contains a token
      // We do nothing
      return request;
    }

    const accessJwt = this._tokenHandler.getAccessToken();

    if (!accessJwt || !accessJwt.accessToken) {
      return request;
    }

    return {
      token: accessJwt.accessToken,
      ...request,
    };
  };

  _saveSessionStorage = (userData: UserLoginData) => {
    this._sessionStorage.setItem(USER_STORAGE_KEY, JSON.stringify(userData));
  };

  _saveLocalStorage = (userData: UserLoginData) => {
    const newRefreshExpirationUnix = dayjs().add(12, 'h').valueOf();

    const newUserData: UserLoginData = {
      ...userData,
      refreshJwt: {
        ...userData.refreshJwt,
        expiration: newRefreshExpirationUnix,
      },
    };

    this._localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(newUserData));
  };

  _getUserFromLocalStorage = () => {
    const SUPPORTED_REMEMBERED_PATHS = [appRoutes.PERSONAL_ALERT_DETAILS];

    if (
      !SUPPORTED_REMEMBERED_PATHS.reduce(
        (acc, path) => acc || window.location.pathname.includes(path),
        false,
      )
    ) {
      return null;
    }

    const userData = this._getUserData(this._localStorage);

    if (!userData) {
      return null;
    }

    const refreshExpirationDate = dayjs(userData.refreshJwt.expiration);

    if (refreshExpirationDate.isBefore(dayjs())) {
      this._localStorage.removeItem(USER_STORAGE_KEY);

      return null;
    }

    return userData;
  };

  _getUserFromSessionStorage = () => this._getUserData(this._sessionStorage);

  _setSubtenantTokens = (tokensState: SubtenantTokensState) => {
    const { tokens, tenantIds } = tokensState;

    this._subtenantTokensState = {
      tokens: { ...tokens },
      tenantIds: [...tenantIds],
    };
  };

  setImpersonatedSubtenantId = (subtenantId: UUID | null) => {
    this._impersonatedSubtenantId = subtenantId;

    if (subtenantId) {
      this._sessionStorage.setItem(IMPERSONATED_SUBTENANT_ID_KEY, subtenantId);
    } else {
      this._sessionStorage.removeItem(IMPERSONATED_SUBTENANT_ID_KEY);
    }
  };

  _getUserData = (storageService: Storage): UserLoginData | null => {
    const userData = storageService.getItem(USER_STORAGE_KEY);

    if (!userData) {
      return null;
    }

    const obj = JSON.parse(userData) as UserLoginData;

    return obj;
  };

  _afterLogin = async (loginResponse: UserLoginData, rememberMe: boolean) => {
    this._saveSessionStorage(loginResponse);

    if (rememberMe) {
      this._saveLocalStorage(loginResponse);
    }

    this._handleTokens(loginResponse);
    if (loginResponse.mfaRequired) {
      return;
    }

    await this.getUiPermissions();

    if (this.hasPermissions('ALL', permissions.VIEW_TENANT_GROUP)) {
      await this.refreshGMSubtenantTokens(loginResponse.accessJwt.token);
    }
  };

  refreshGMSubtenantTokens = async (accessToken: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const tokens = await ums.refreshGMSubtenantTokens({
      accessToken,
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    const tenantIds = Object.keys(tokens.subTenantsTokens);

    this._setSubtenantTokens({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      tokens: tokens.subTenantsTokens,
      tenantIds: tenantIds,
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return tokens;
  };

  /**
   * @return Jwt access token.
   */
  getToken = () => this._tokenHandler.getAccessToken()?.accessToken;

  /**
   * @return Impersonated subtenant id.
   */
  getImpersonatedSubtenantId = () =>
    this._impersonatedSubtenantId ||
    this._sessionStorage.getItem(IMPERSONATED_SUBTENANT_ID_KEY) ||
    '';

  /**
   * @return Jwt access token.
   */
  getApiCallToken = () => {
    const impersonatedSubtenantId = this.getImpersonatedSubtenantId();

    return impersonatedSubtenantId
      ? this.getSingleSubtenantToken(impersonatedSubtenantId)
      : this._tokenHandler.getAccessToken()?.accessToken || '';
  };

  /**
   * @return Jwt access token of all GM subtenants.
   */
  getSubtenantTokens = (): SubtenantTokensState => {
    if (this._subtenantTokensState.tenantIds.length) {
      return this._subtenantTokensState;
    }

    return {
      tokens: {},
      tenantIds: [],
    };
  };

  getSingleSubtenantToken = (subtenantId: UUID): string => {
    return this.getSubtenantTokens().tokens[subtenantId]?.accessJwt.token || '';
  };

  // Request should contain { refreshToken: ... }
  _getRefreshToken = (request?: { refreshToken?: string }) => {
    const refreshTokenToUse =
      request?.refreshToken ||
      this._tokenHandler.getRefreshToken()?.refreshToken;

    if (!refreshTokenToUse) {
      throw new Error(
        'did not receive refresh token and could not load any from storage',
      );
    }

    return refreshTokenToUse;
  };

  // Request should contain { refreshToken: ... }
  _getAccessToken = (request?: { accessToken: string }) => {
    const accessTokenToUse = request?.accessToken || this.getToken();

    if (!accessTokenToUse) {
      console.error(
        'did not receive access token and could not load any from storage',
      );
    }

    return accessTokenToUse;
  };
}

const instance = new UmsSmartSdk();

export default {
  ...ums,
  ...instance,
};
