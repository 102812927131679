export const GROUP_UPDATE_VERSION_FIELDS = {
  GROUP_NAME: 'groupName',
  VERSION_ID: 'versionId',
  VERSION_PLUS_ID: 'versionPlusId',
};

export const RADIO_BUTTONS_VALUES = {
  LATEST_VERSION: 'latestVersion',
  OTHER: 'other',
};
