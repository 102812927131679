import moment from 'moment';
import { IntlShape } from 'react-intl';

import {
  ContinuousMeasurement,
  ContinuousMeasurementValue,
} from 'src/types/measurements';
import { SerialNumber, DateUTC } from 'src/types/utility';
import {
  LivePatientDevice,
  MeasurementType,
  ProccessedVs3Message,
} from './types';
import {
  validContinuousValues,
  messages,
  specialMeasurementValues,
  onlineMonitorDataTypes,
} from './constants';
import { isQuality } from './messageProcessors';

export const isSessionLessThenFiveMinutes = (startTime: DateUTC): boolean => {
  const milliSecondsStartTime = moment(startTime).valueOf();
  const diff = new Date().getTime() - milliSecondsStartTime;

  return diff < 300000;
};

export const checkMeasurementIsValid = (
  measurement: string,
  measurementType: MeasurementType | undefined,
) => {
  if (
    !measurementType ||
    measurementType === MeasurementType.RAbin ||
    !validContinuousValues[measurementType]
  ) {
    return true;
  }

  return (
    validContinuousValues[measurementType].min <= parseFloat(measurement) &&
    parseFloat(measurement) <= validContinuousValues[measurementType].max
  );
};

export const displayMeasurement = (
  measurement: ContinuousMeasurementValue,
  quality: boolean,
  shouldDisplayHighQualityOnly: boolean,
  intl: IntlShape,
  measurementType: MeasurementType,
) => {
  if (!measurement) {
    return ' ';
  }

  if (!quality && shouldDisplayHighQualityOnly) {
    return intl.formatMessage(messages.noData);
  }

  switch (measurement) {
    case specialMeasurementValues.NO_DATA:
      return intl.formatMessage(messages.noData);
    case specialMeasurementValues.DATA_UNRELIABLE:
      return intl.formatMessage(messages.insufficientData);
    default: {
      if (!checkMeasurementIsValid(measurement, measurementType)) {
        return intl.formatMessage(messages.noData);
      }

      return measurement;
    }
  }
};

export const displaySpotMeasurement = (
  spotMeasurement: ContinuousMeasurementValue,
  intl: IntlShape,
) => {
  if (!spotMeasurement) {
    return ' ';
  }

  switch (spotMeasurement) {
    case specialMeasurementValues.NO_DATA:
      return intl.formatMessage(messages.noData);
    case specialMeasurementValues.DATA_UNRELIABLE:
      return intl.formatMessage(messages.insufficientData);
    default:
      return spotMeasurement;
  }
};

export function removeDuplicatedPatientsAndDevices(
  value: LivePatientDevice,
  index: number,
  self: LivePatientDevice[],
) {
  return (
    index ===
    self.findIndex(
      t => t.patientId === value.patientId && t.deviceId === value.deviceId,
    )
  );
}

export function isDeviceActive(
  livePatientDevice: LivePatientDevice,
  activeDevices: SerialNumber[],
) {
  return activeDevices.includes(livePatientDevice.deviceId);
}

export function notIn(array: string[]) {
  return function (item: string) {
    return array.indexOf(item) < 0;
  };
}

type VsData = {
  x: number;
  y: string;
  isQualityMeasurement: boolean;
};

export function parseVs3ContinuousMessage(
  message: ProccessedVs3Message,
  deviceId: SerialNumber,
  hrData: Record<string, VsData[]> = {},
  rrData: Record<string, VsData[]> = {},
): ContinuousMeasurement {
  const { patientId, sessionId, state, hr, rr, rabin, ie } = message;

  const currentIeValue = ie[0];
  const currentIERatioValue =
    currentIeValue && currentIeValue > 0
      ? Number(currentIeValue).toFixed(1)
      : '-1';

  let data: Partial<ContinuousMeasurement['data']> = {
    currentIERatioValue,
  };

  if (hr[0]) {
    const currentHrValue = Number(hr[0]).toFixed();
    const currentHrQuality = isQuality(hr[2]);

    hrData[deviceId] = [
      ...(hrData[deviceId] || []),
      {
        x: Date.now(),
        y: currentHrValue,
        isQualityMeasurement: currentHrQuality,
      },
    ];
    data = { ...data, currentHrValue, currentHrQuality };
  }

  if (rr[0]) {
    const currentRrValue = Number(rr[0]).toFixed();
    const currentRrQuality = isQuality(rr[2]);

    rrData[deviceId] = [
      ...(rrData[deviceId] || []),
      {
        x: Date.now(),
        y: currentRrValue,
        isQualityMeasurement: currentRrQuality,
      },
    ];
    data = { ...data, currentRrValue, currentRrQuality };
  }

  if (rabin[0]) {
    const currentRabinValue = Number(rabin[0]).toFixed();
    const currentRabinQuality = isQuality(rabin[2]);
    data = { ...data, currentRabinValue, currentRabinQuality };
  }

  if (state || state === 0) {
    data = { ...data, currentPatientStateValue: state };
  }

  return {
    dataType: onlineMonitorDataTypes.CONTINUOUS,
    data,
    patientId,
    sessionId,
    deviceId,
  };
}
