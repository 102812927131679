import * as R from 'fp-ts/lib/Record';

import { BaselineAlertThreshold } from 'src/types/alerts';
import {
  EditableBaselineThreshold,
  TenantBaselineThresholdsState,
  ThresholdsErrorState,
} from './types';

export const checkBaselineMetricValid = ({
  enable,
  baselineDaysInterval,
  deviationPercentage,
  deviationHoursInterval,
}: EditableBaselineThreshold): boolean =>
  enable
    ? !!(baselineDaysInterval && deviationHoursInterval && deviationPercentage)
    : true;

export const mapValidationErrors = (
  thresholdState: TenantBaselineThresholdsState,
): ThresholdsErrorState =>
  R.map(
    (threshold: EditableBaselineThreshold) =>
      !checkBaselineMetricValid(threshold),
  )(thresholdState);

const defaultBaselineThreshold = ({
  metric,
}: Pick<BaselineAlertThreshold, 'metric'>): BaselineAlertThreshold => ({
  id: '',
  creationTime: '',
  lastModifiedTime: '',
  enable: false,
  metric,
  baselineDaysInterval: 0,
  deviationPercentage: 0,
  deviationHoursInterval: 0,
});

const findThresholdWithDefault = (
  baselineThresholds: BaselineAlertThreshold[],
  metric: BaselineAlertThreshold['metric'],
): EditableBaselineThreshold => ({
  ...(baselineThresholds.find(t => t.metric === metric) ||
    defaultBaselineThreshold({ metric })),
  dirty: false,
});

export const mapBaselineThresholdsArrayToState = (
  baselineThresholds: BaselineAlertThreshold[],
): TenantBaselineThresholdsState => ({
  HR_BASELINE: findThresholdWithDefault(baselineThresholds, 'HR_BASELINE'),
  RR_BASELINE: findThresholdWithDefault(baselineThresholds, 'RR_BASELINE'),
});
