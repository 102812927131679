import { useEffect, useState } from 'react';
import { SubtenantItem } from 'src/routes/GMDashboard/modules/types';
import {
  ActivityAlertSettings,
  SelectedLevelAlertSettings,
} from 'src/components/AlertSettingsComponents/ActivityAlerts/types';
import dayjs from 'dayjs';
import { defaultActivityFormData } from 'src/components/AlertSettingsComponents/ActivityAlerts/utils';
import { omit } from 'lodash';
import equal from 'fast-deep-equal';
import { ALL_TENANTS_OPTION } from 'src/routes/GMDashboard/modules/constants';

type Props = {
  activityAlertSettingsFromDb: ActivityAlertSettings;
  selectedSubtenants: SubtenantItem[];
  subtenantOptions: SubtenantItem[];
};
const areObjectEquals = <T>(arrayOfObjects: Array<T>) =>
  arrayOfObjects.every(obj => equal(obj, arrayOfObjects[0]));
export const useGetActivityAlertsSettings = ({
  activityAlertSettingsFromDb,
  selectedSubtenants,
  subtenantOptions,
}: Props) => {
  const [activityAlertSettings, setActivityAlertSettings] =
    useState<SelectedLevelAlertSettings>();
  const [displayUniqueOverrideMessage, setDisplayUniqueOverrideMessage] =
    useState(false);
  const [
    areSelectedTenantsSettingsEquals,
    setAreSelectedTenantsSettingsEquals,
  ] = useState({ equals: true });

  useEffect(() => {
    setDisplayUniqueOverrideMessage(false);
    let areSettingsEquals = true;
    if (!activityAlertSettingsFromDb) {
      return;
    }
    let currentSetting:
      | Omit<SelectedLevelAlertSettings, 'tenantIds'>
      | null
      | undefined = null;
    if (selectedSubtenants.length === 1) {
      const selectedTenantId = selectedSubtenants[0]?.value;
      if (selectedTenantId === ALL_TENANTS_OPTION) {
        return;
      }
      const isSelectedTenantIdUnique =
        activityAlertSettingsFromDb?.uniqueChildTenantIds?.find(
          uniqueId => uniqueId === selectedTenantId,
        );
      if (isSelectedTenantIdUnique) {
        currentSetting =
          activityAlertSettingsFromDb?.uniqueChildTenantAlertSettings.find(
            // @ts-ignore need to check selectedTenantId type
            uniqueChild => uniqueChild?.tenantIds?.includes(selectedTenantId),
          );
      }
      setDisplayUniqueOverrideMessage(
        !!isSelectedTenantIdUnique && !!currentSetting?.outOfBedAlertSettings,
      );
    }
    if (selectedSubtenants.length > 1) {
      const selectedSubtenantsIds = selectedSubtenants
        .filter(subtenant => subtenant.value !== ALL_TENANTS_OPTION)
        .map(elem => elem.value);
      const selectedSubtenantsSettings: Array<
        Pick<
          SelectedLevelAlertSettings,
          'inBedAlertSettings' | 'outOfBedAlertSettings'
        >
      > = [];
      selectedSubtenantsIds.forEach(selectedSubtenantsId => {
        const isSelectedTenantIdUnique =
          activityAlertSettingsFromDb?.uniqueChildTenantIds?.find(
            uniqueId => uniqueId === selectedSubtenantsId,
          );
        if (isSelectedTenantIdUnique) {
          selectedSubtenantsSettings.push(
            omit(
              activityAlertSettingsFromDb?.uniqueChildTenantAlertSettings.find(
                uniqueChild =>
                  // @ts-ignore need to check selectedTenantId type
                  uniqueChild?.tenantIds?.includes(selectedSubtenantsId),
              ),
              'tenantIds',
            ),
          );
          return;
        }
        selectedSubtenantsSettings.push(
          omit(
            activityAlertSettingsFromDb.selectedLevelAlertSettings,
            'tenantIds',
          ),
        );
      });
      areSettingsEquals = areObjectEquals<
        Pick<
          SelectedLevelAlertSettings,
          'inBedAlertSettings' | 'outOfBedAlertSettings'
        >
      >([...selectedSubtenantsSettings]);
      if (areSettingsEquals) {
        currentSetting = selectedSubtenantsSettings[0];
      }
    }
    const { selectedLevelAlertSettings } = activityAlertSettingsFromDb;
    currentSetting = currentSetting
      ? currentSetting
      : selectedLevelAlertSettings;

    setActivityAlertSettings(
      currentSetting?.outOfBedAlertSettings
        ? {
            ...currentSetting,
            outOfBedAlertSettings: {
              ...currentSetting.outOfBedAlertSettings,
              bedtimeExitAlertSettings: {
                ...currentSetting.outOfBedAlertSettings
                  .bedtimeExitAlertSettings,
                startTime: dayjs(
                  currentSetting.outOfBedAlertSettings.bedtimeExitAlertSettings
                    .startTime,
                  'HH',
                ),
                endTime: dayjs(
                  currentSetting.outOfBedAlertSettings.bedtimeExitAlertSettings
                    .endTime,
                  'HH',
                ),
              },
            },

            tenantIds: subtenantOptions.filter(
              s =>
                // @ts-ignore check s.value type
                !!selectedSubtenants
                  .filter(subtenant => subtenant.value !== ALL_TENANTS_OPTION)
                  .find(elem => elem.value === s.value),
            ),
          }
        : {
            ...defaultActivityFormData,
            tenantIds: subtenantOptions.filter(
              s =>
                // @ts-ignore check s.value type
                !!selectedSubtenants
                  .filter(subtenant => subtenant.value !== ALL_TENANTS_OPTION)
                  .find(elem => elem.value === s.value),
            ),
          },
    );
    setAreSelectedTenantsSettingsEquals({
      equals: areSettingsEquals,
    });
  }, [activityAlertSettingsFromDb, selectedSubtenants]);

  return {
    areSelectedTenantsSettingsEquals,
    displayUniqueOverrideMessage,
    activityAlertSettings,
    setActivityAlertSettings,
  };
};
