export type UUID = string;
export type DateUTC = string;
export type SerialNumber = string;

export type PageMetaDataType = {
  totalResults: number;
  page: number;
  limit: number;
};

export enum SortableFields {
  name = 'name',
  timeZoneOffset = 'timeZoneOffset',
  isEnableCPX = 'isEnableCPX',
  logo = 'logo',
  creationTime = 'creationTime',
  lastModifiedTime = 'lastModifiedTime',
}

export enum SortOrder {
  desc = 'DESC',
  asc = 'ASC',
}

export type MetaDataType = {
  page: PageMetaDataType;
  sort?:
    | null
    | [
        {
          prop: SortableFields;
          order: SortOrder;
        },
      ];
};
