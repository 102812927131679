import React from 'react';

import { SVGProps } from '../types';

const UnSuppressAlertIcon = ({
  width = '21',
  height = '21',
  color = '#252525',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="matrix(-1 0 0 -1 18 19)"
    >
      <path d="m2.5 3.5h10.0845241c.9116644 0 1.6507144.73905002 1.6507144 1.65071443 0 .299182-.0813106.59273912-.2352385.84928557-.9815222 1.63587038-1.5 3.50773964-1.5 5.4154759v1.0845241c0 .2743851.0526244.7411502 0 1m-1.3634603 2.0081694c-.7039698.6174972-1.62653961.9918306-2.6365397.9918306h-2c-2.209139 0-4-1.790861-4-4v-1.0845241c0-1.90773626-.51847777-3.77960552-1.5-5.4154759-.23818692-.39697819-.29005196-.85383979-.18103675-1.26881839" />
      <path d="m.5 1.5 14 14" />
      <path d="m5 2c.66666667-1 1.5-1.5 2.5-1.5s1.83333333.5 2.5 1.5" />
    </g>
  </svg>
);

export default UnSuppressAlertIcon;
