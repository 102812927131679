import React, { ChangeEventHandler, useRef } from 'react';
import styled from 'styled-components';

import Icon from '../Icon';
import { useSearch } from './hooks';

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.4rem;
  cursor: pointer;
`;

const StyledInput = styled.input`
  max-width: 10rem;
  background-color: transparent;
  border: none;
  font-size: 0.7rem;
  padding: 0.2rem;
  flex-grow: 1;

  :focus-visible {
    outline-color: #252525;
    outline-style: solid;
  }
`;

interface Props {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
}

const Search = ({ value, onChange, className }: Props): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);
  const focusInput = () => {
    ref?.current?.focus();
  };

  return (
    <SearchWrapper className={className}>
      <StyledIcon onClick={focusInput} type="search-icon" />
      <StyledInput ref={ref} type="search" value={value} onChange={onChange} />
    </SearchWrapper>
  );
};

export default Search;
export { useSearch };
