import { useState, useEffect } from 'react';
import _ from 'lodash';
import queryString, { IParseOptions } from 'qs';
import { AxiosError, AxiosResponse } from 'axios';

import { validate, execute } from './apiUtils';

type Props = {
  baseURL: string;
  onValidateSuccess?: (response: AxiosResponse) => void;
  onValidateFail: (error: unknown) => void;
  onExecuteSuccess: (response: AxiosResponse) => void;
  onExecuteFail: (error: unknown) => void;
  shouldExecute: boolean;
  body: Record<string, unknown>;
  decoder?: IParseOptions['decoder'];
};

const extractQueryStringData = (
  search: string,
  options: IParseOptions,
): Record<string, unknown> & {
  token: string;
  entityId: string;
  operation: string;
} => {
  const { token, entityId, operation, ...additionalParams } = queryString.parse(
    search,
    options,
  );

  return {
    token: token as string,
    entityId: entityId as string,
    operation: operation as string,
    ...additionalParams,
  };
};

const TokenOperation = (props: Props): null => {
  const [validateSucceed, setValidateSucceed] = useState(false);

  const {
    baseURL,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onValidateSuccess = () => {},
    onValidateFail,
    onExecuteSuccess,
    onExecuteFail,
    shouldExecute,
    body,
    decoder = val => val,
  } = props;
  const { token, entityId, operation, ...additionalParams } =
    extractQueryStringData(window.location.search, {
      ignoreQueryPrefix: true,
      decoder,
    });

  const _getError = (e: AxiosError): unknown =>
    e.response
      ? e.response.data
      : {
          code: null,
          message: e,
        };

  async function _validate() {
    try {
      const response = await validate({
        baseURL,
        token,
        operation,
        entityId,
      });
      onValidateSuccess(response);
      setValidateSucceed(true);
    } catch (e) {
      onValidateFail(_getError(e as AxiosError));
      return;
    }
  }

  async function _execute() {
    if (shouldExecute && validateSucceed) {
      try {
        const operationData = _.isEmpty(body)
          ? { ...additionalParams }
          : { ...body, ...additionalParams };

        const response = await execute({
          baseURL,
          token,
          operation,
          entityId,
          operationData,
        });
        onExecuteSuccess(response);
      } catch (e) {
        onExecuteFail(_getError(e as AxiosError));
      }
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    _validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    _execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldExecute, validateSucceed]);

  return null;
};

export default TokenOperation;
