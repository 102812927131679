import { all, takeLatest, call, put, delay } from 'typed-redux-saga/macro';

import { MODAL_STATUS } from 'src/components/Modal/constants';
import {
  notifyUserByActionTypeAndCode,
  actionSuccessNotification,
  notificationsPlacementTypes,
} from 'src/utils/errorHandling/notifications';
import AppConfig from 'src/config/AppConfig';
import BackendService from 'src/services/BackendService';
import { SerialNumber, UUID } from 'src/types/utility';
import { actions } from './slice';
import { DEFAULT_PAGINATION } from 'src/utils/constants';

function* loadGroupsList(action: ReturnType<typeof actions.onLoadGroupsList>) {
  try {
    yield* put(actions.setGroupListLoading());
    const { currentPage, pageSize } = action.payload;
    const groupsResponse = yield* call(
      BackendService.getGroups,
      currentPage,
      pageSize,
    );
    yield* put(actions.onLoadGroupsListSuccess(groupsResponse.data));
  } catch (e) {
    yield* put(actions.onLoadGroupsListFail());
    notifyUserByActionTypeAndCode(action.type, e);
  }
}

function* onCreateGroup(action: ReturnType<typeof actions.onCreateGroup>) {
  const formData = action.payload;

  try {
    const response = yield* call(BackendService.createGroup, {
      name: formData.name,
      applicationIds: [AppConfig.APPLICATION_ID],
    });
    const groupId = response.data.id;
    const { clientIds } = formData.newDevicesToBeHandle;

    yield* call(assignDevice, groupId, clientIds);

    yield* put(
      actions.onLoadGroupsList({
        currentPage: DEFAULT_PAGINATION.PAGE - 1,
        pageSize: DEFAULT_PAGINATION.LIMIT,
      }),
    );

    actionSuccessNotification(action.type, null);
  } catch (error) {
    yield* put(actions.setModalStatus(MODAL_STATUS.INITIAL));
    notifyUserByActionTypeAndCode(action.type, formData?.name, error);
  }
}

function* onEditGroup(action: ReturnType<typeof actions.onEditGroup>) {
  const formData = action.payload;

  try {
    const data = { name: formData.name };
    const { groupId, newDevicesToBeHandle } = formData;

    if (!groupId) {
      yield* put(
        actions.onCreateGroup({
          newDevicesToBeHandle: {
            clientIds: newDevicesToBeHandle.clientIds,
          },
          name: formData.name,
        }),
      );
      return;
    }

    yield* call(updateGroup, groupId, data);

    yield* call(BackendService.editAssignedDevices, {
      groupId: formData.groupId,
      data: {
        clientIds: newDevicesToBeHandle.clientIds,
        unassignedClientIds: newDevicesToBeHandle.unassignedClientIds,
        removeOldGroupIfExists: true,
      },
    });

    yield* put(
      actions.onLoadGroupsList({
        currentPage: DEFAULT_PAGINATION.PAGE - 1,
        pageSize: DEFAULT_PAGINATION.LIMIT,
      }),
    );

    actionSuccessNotification(action.type, null);
  } catch (error) {
    console.error('Error in edit group: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, formData?.groupId, error);
  }
}

function* assignDevice(groupId: UUID, clientsIds: SerialNumber[]) {
  try {
    yield* call(BackendService.assignDevice, {
      groupId,
      data: { clientIds: clientsIds, removeOldGroupIfExists: true },
    });
  } catch (e) {
    console.error('Error in assign device', e);
  }
}

function* onDeleteGroup(action: ReturnType<typeof actions.onDeleteGroup>) {
  const groupId = action.payload;
  try {
    yield* call(BackendService.deleteGroup, groupId);
    yield* put(
      actions.onLoadGroupsList({
        currentPage: DEFAULT_PAGINATION.PAGE - 1,
        pageSize: DEFAULT_PAGINATION.LIMIT,
      }),
    );

    actionSuccessNotification(action.type, null);
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, groupId, error);
  }
}

function* updateGroup(
  groupId: string,
  data: Partial<{ name: string; versionId: string; versionPlusId: string }>,
) {
  yield* call(BackendService.updateGroup, { groupId, data });
}

function* onUpdateGroupVersion(
  action: ReturnType<typeof actions.onUpdateGroupVersion>,
) {
  const { updateVersionData, groupId } = action.payload;

  try {
    const data = {
      versionId: updateVersionData.versionId,
      versionPlusId: updateVersionData.versionPlusId,
    };

    yield* call(updateGroup, groupId, data);
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
    yield* put(
      actions.onLoadGroupsList({
        currentPage: DEFAULT_PAGINATION.PAGE - 1,
        pageSize: DEFAULT_PAGINATION.LIMIT,
      }),
    );

    actionSuccessNotification(
      action.type,
      null,
      notificationsPlacementTypes.TOP_RIGHT,
    );
  } catch (error) {
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, groupId, error);
  }
}

function* onEditApp(action: ReturnType<typeof actions.onEditApp>) {
  const data = action.payload;

  try {
    yield* call(BackendService.updateDefaultVersion, {
      appId: AppConfig.APPLICATION_ID,
      data: { versionId: data.version, name: AppConfig.APPLICATION_NAME },
    });
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
    yield* put(
      actions.onLoadGroupsList({
        currentPage: DEFAULT_PAGINATION.PAGE - 1,
        pageSize: DEFAULT_PAGINATION.LIMIT,
      }),
    );

    actionSuccessNotification(
      action.type,
      null,
      notificationsPlacementTypes.TOP_RIGHT,
    );
  } catch (error) {
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, error);
  }
}

function* onLoadGroupClients() {
  try {
    const groupClientsResponse = yield* call(BackendService.getGroupClients);
    yield* put(
      actions.getGroupClientSuccess(groupClientsResponse?.data?.clients),
    );
  } catch (error) {
    console.log('error in get group clients', error);
    yield* put(actions.getGroupClientFailed());
  }
}

function* searchAdminGroupsList(
  action: ReturnType<typeof actions.searchAdminGroupsList>,
) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { payload } = action;
    yield* delay(500);
    yield* put(actions.setGroupListLoading());

    const groupsResponse = yield* call(
      BackendService.adminSearchAllGroups,
      payload,
    );

    yield* put(actions.onLoadGroupsListSuccess(groupsResponse.data));
  } catch (e) {
    yield* put(actions.onLoadGroupsListFail());
    notifyUserByActionTypeAndCode(action.type, e);
  }
}

export default function* watchUserActions() {
  yield* all([
    takeLatest(actions.onLoadGroupsList, loadGroupsList),
    takeLatest(actions.searchAdminGroupsList, searchAdminGroupsList),
    takeLatest(actions.onCreateGroup, onCreateGroup),
    takeLatest(actions.onDeleteGroup, onDeleteGroup),
    takeLatest(actions.onEditGroup, onEditGroup),
    takeLatest(actions.onUpdateGroupVersion, onUpdateGroupVersion),
    takeLatest(actions.onEditApp, onEditApp),
    takeLatest(actions.onLoadGroupClients, onLoadGroupClients),
  ]);
}
