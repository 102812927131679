import React from 'react';
import styled from 'styled-components';
import { CheckCircleOutlined } from '@ant-design/icons';

import { tenantModalModes } from './TenantModal';

export const StyledCheckmark = styled(CheckCircleOutlined)`
  color: ${props => props.theme.colors.activeUserColor};
`;

export const getTenantColumns = (intl, messages) => [
  {
    title: intl.formatMessage(messages.name),
    dataIndex: 'name',
    key: 'name',
    filtered: true,
    onFilter: (value, record) =>
      record.name.toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => a.name?.localeCompare(b.name),
  },
  {
    title: intl.formatMessage(messages.address),
    dataIndex: 'address',
    key: 'address',
    render: (_, record) => {
      const address = [
        ...Object.values(record.address || {}).filter(value => !!value),
      ].join(', ');
      return address ? <div>{address}</div> : null;
    },
  },
  {
    title: intl.formatMessage(messages.contactName),
    dataIndex: 'contactName',
    key: 'contactName',
    render: (_, record) =>
      record?.tenantContact && (
        <div>{`${record.tenantContact.firstName} ${record.tenantContact.lastName}`}</div>
      ),
  },
  {
    title: intl.formatMessage(messages.contactEmail),
    dataIndex: 'contactEmail',
    key: 'contactEmail',
    render: (_, record) => <div>{record.tenantContact?.email}</div>,
  },
  {
    title: intl.formatMessage(messages.contactPhone),
    dataIndex: 'contactPhone',
    key: 'contactPhone',
    render: (_, record) => <div>{record.tenantContact?.phone}</div>,
  },
  {
    title: intl.formatMessage(messages.enableCPX),
    dataIndex: 'isEnableCPX',
    key: 'isEnableCPX',
    sorter: (a, b) => a.isEnableCPX - b.isEnableCPX,
    render: (_, record) => record.isEnableCPX && <StyledCheckmark />,
  },
];

export const getTenantTableactions = (
  intl,
  messages,
  excludedFromDeleteTenants,
  setIsConfirmationModalVisible,
  setActiveActionRow,
  setIsEditModalVisible,
  setModalMode,
) => [
  {
    key: 'delete',
    name: intl.formatMessage(messages.delete),
    isDisabled: ({ record }) => excludedFromDeleteTenants.includes(record.id),
    onClick: (_, { record }) => {
      setIsConfirmationModalVisible(true);
      setActiveActionRow(record);
    },
  },
  {
    key: 'edit',
    name: intl.formatMessage(messages.edit),
    onClick: (_, { record }) => {
      setActiveActionRow(record);
      setIsEditModalVisible(true);
      setModalMode(tenantModalModes.EDIT);
    },
  },
];
