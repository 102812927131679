import { actions as gmActions } from 'src/redux/data/groupManager';
import {
  EditableThreshold,
  mapThresholdsArrayToState,
  TenantThresholdsState,
} from 'src/components/AlertSettingsComponents/AlertThresholdsForm';
import { DataTenantDict } from 'src/redux/data/groupManager/modules/types';
import {
  AlertThreshold,
  AverageThresholdMetric,
  BaselineAlertThreshold,
  BaselineMetric,
  MedicalAlertType,
  TechnicalAlertType,
  ThresholdPreposition,
} from 'src/types/alerts';
import { mergeArraysWithUniqueIds } from 'src/redux/data/dataUtils';
import { UUID } from 'src/types/utility';
import { Truthy } from 'src/utils/fpUtils';
import {
  Subscriber,
  TargetMedia,
  UserActivitySubscription,
  UserMedicalSubscription,
  UserTechnicalSubscription,
} from 'src/types/subscribers';
import { UpdateSubscribersListPayload } from 'src/services/types';
import {
  activityAlertsArray,
  ALL_TENANTS_OPTION,
  SETTINGS_TYPE,
  SubtenantSettingsType,
  UNSET_BASELINE_THRESHOLD_STATE,
  UNSET_THRESHOLD_STATE,
} from './constants';
import {
  SubtenantItem,
  TenantSubscriptionDetails,
  MedicalSubscriptionDetails,
  TechnicalSubscriptionDetails,
  ActivitySubscriptionDetails,
} from './types';
import {
  eqTenantBaselineThresholds,
  eqTenantSubscriptionDetails,
  eqTenantThresholds,
} from './equalityTools';
import {
  EditableBaselineThreshold,
  mapBaselineThresholdsArrayToState,
  TenantBaselineThresholdsState,
} from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';

// TODO: Move these computations to GM dashboard slice
const computeThresholdsSettingsType = (
  gm: TenantThresholdsState,
  subtenant: TenantThresholdsState,
): SubtenantSettingsType => {
  if (eqTenantThresholds.equals(UNSET_THRESHOLD_STATE, subtenant)) {
    return SETTINGS_TYPE.NOT_SET;
  }

  return eqTenantThresholds.equals(gm, subtenant)
    ? SETTINGS_TYPE.COLLECTIVE
    : SETTINGS_TYPE.UNIQUE;
};

const computeBaselineThresholdsSettingsType = (
  gm: TenantBaselineThresholdsState,
  subtenant: TenantBaselineThresholdsState,
): SubtenantSettingsType => {
  if (
    eqTenantBaselineThresholds.equals(UNSET_BASELINE_THRESHOLD_STATE, subtenant)
  ) {
    return SETTINGS_TYPE.NOT_SET;
  }

  return eqTenantBaselineThresholds.equals(gm, subtenant)
    ? SETTINGS_TYPE.COLLECTIVE
    : SETTINGS_TYPE.UNIQUE;
};

const computeSubscriptionsSettingsType = (
  gmTenantSubscriptions: TenantSubscriptionDetails,
  subtenantSubscriptions: TenantSubscriptionDetails,
): SubtenantSettingsType =>
  eqTenantSubscriptionDetails.equals(
    gmTenantSubscriptions,
    subtenantSubscriptions,
  )
    ? SETTINGS_TYPE.COLLECTIVE
    : SETTINGS_TYPE.UNIQUE;

export const computeSettingsType = (
  thresholds: {
    gm: TenantThresholdsState;
    subtenant: TenantThresholdsState;
  },
  baselineThresholds: {
    gm: TenantBaselineThresholdsState;
    subtenant: TenantBaselineThresholdsState;
  },
  subscriptions: {
    gm: TenantSubscriptionDetails;
    subtenant: TenantSubscriptionDetails;
  },
) => {
  const thresholdSettingsType = computeThresholdsSettingsType(
    thresholds.gm,
    thresholds.subtenant,
  );
  const baselineThresholdSettingsType = computeBaselineThresholdsSettingsType(
    baselineThresholds.gm,
    baselineThresholds.subtenant,
  );
  const subscriptionSettingsType = computeSubscriptionsSettingsType(
    subscriptions.gm,
    subscriptions.subtenant,
  );

  if (
    subscriptionSettingsType === SETTINGS_TYPE.UNIQUE ||
    baselineThresholdSettingsType === SETTINGS_TYPE.UNIQUE ||
    thresholdSettingsType === SETTINGS_TYPE.UNIQUE
  ) {
    return SETTINGS_TYPE.UNIQUE;
  }

  if (
    subscriptionSettingsType === SETTINGS_TYPE.COLLECTIVE ||
    baselineThresholdSettingsType === SETTINGS_TYPE.COLLECTIVE ||
    thresholdSettingsType === SETTINGS_TYPE.COLLECTIVE
  ) {
    return SETTINGS_TYPE.COLLECTIVE;
  }

  return SETTINGS_TYPE.NOT_SET;
};

export const getSubtenantThresholds = (
  subtenantId: UUID,
  allThresholds: AlertThreshold[],
  thresholdsDict: DataTenantDict,
): TenantThresholdsState =>
  mapThresholdsArrayToState(
    allThresholds.filter(
      threshold => thresholdsDict[threshold.id] === subtenantId,
    ),
  );

export const getSubtenantBaselineThresholds = (
  subtenantId: UUID,
  allThresholds: BaselineAlertThreshold[],
  thresholdsDict: DataTenantDict,
): TenantBaselineThresholdsState =>
  mapBaselineThresholdsArrayToState(
    allThresholds.filter(
      threshold => thresholdsDict[threshold.id] === subtenantId,
    ),
  );

const extractTenantMedicalSubscriptionDetails = (
  subscriber: Subscriber | undefined,
): MedicalSubscriptionDetails => {
  if (!subscriber) {
    return {
      isAssigned: false,
      withPersonalDetails: false,
    };
  }

  return {
    isAssigned: true,
    withPersonalDetails: !!subscriber.withPersonalDetails,
  };
};

const extractTenantTechnicalSubscriptionDetails = (
  subscriber: Subscriber | undefined,
): TechnicalSubscriptionDetails => {
  if (!subscriber) {
    return {
      isAssigned: false,
      summaryReportEnabled: false,
      summaryReportTime: null,
      singleDisconnectionAllowedType: null,
      emailCcEnabled: false,
      emailCcRecipients: '',
    };
  }

  return {
    isAssigned: true,
    summaryReportEnabled: !!subscriber.summaryReportEnabled,
    summaryReportTime: subscriber.summaryReportTime,
    singleDisconnectionAllowedType: subscriber.singleDisconnectionAllowedType,
    emailCcEnabled: !!subscriber.emailCcEnabled,
    emailCcRecipients: subscriber.emailCcRecipients || '',
  };
};
const extractTenantActivitySubscriptionDetails = (
  subscriber: Subscriber | undefined,
): ActivitySubscriptionDetails => {
  if (!subscriber) {
    return {
      isAssigned: false,
    };
  }

  return {
    isAssigned: true,
  };
};

export const getGMUserSubscriptionDetails = (
  gmUserId: UUID,
  subscribers: Subscriber[],
): TenantSubscriptionDetails => ({
  MEDICAL: {
    EMAIL: extractTenantMedicalSubscriptionDetails(
      subscribers.find(
        s =>
          s.userId === gmUserId &&
          s.targetMedia === 'EMAIL' &&
          s.alertType === ALERT_METRIC_ENUM.RR &&
          s.mediaAlertsEnabled,
      ),
    ),
    SMS: extractTenantMedicalSubscriptionDetails(
      subscribers.find(
        s =>
          s.userId === gmUserId &&
          s.targetMedia === 'SMS' &&
          s.alertType === ALERT_METRIC_ENUM.RR &&
          s.mediaAlertsEnabled,
      ),
    ),
  },
  TECHNICAL: {
    EMAIL: extractTenantTechnicalSubscriptionDetails(
      subscribers.find(
        s =>
          s.userId === gmUserId &&
          s.targetMedia === 'EMAIL' &&
          s.alertType === ALERT_METRIC_ENUM.DEVICE_DISCONNECTED &&
          s.mediaAlertsEnabled,
      ),
    ),
    SMS: extractTenantTechnicalSubscriptionDetails(
      subscribers.find(
        s =>
          s.userId === gmUserId &&
          s.targetMedia === 'SMS' &&
          s.alertType === ALERT_METRIC_ENUM.DEVICE_DISCONNECTED &&
          s.mediaAlertsEnabled,
      ),
    ),
  },
  ACTIVITY: {
    EMAIL: extractTenantActivitySubscriptionDetails(
      subscribers.find(
        s =>
          s.userId === gmUserId &&
          s.targetMedia === 'EMAIL' &&
          activityAlertsArray.includes(s.alertType) &&
          s.mediaAlertsEnabled,
      ),
    ),
    SMS: extractTenantActivitySubscriptionDetails(
      subscribers.find(
        s =>
          s.userId === gmUserId &&
          s.targetMedia === 'SMS' &&
          activityAlertsArray.includes(s.alertType) &&
          s.mediaAlertsEnabled,
      ),
    ),
  },
});

export const getGMSubtenantSubscriptionDetails = (
  gmUserId: UUID,
  subtenantId: UUID,
  allSubscribers: Subscriber[],
  subscribersDict: DataTenantDict,
): TenantSubscriptionDetails =>
  getGMUserSubscriptionDetails(
    gmUserId,
    allSubscribers.filter(s => subscribersDict[s.id] === subtenantId),
  );

export const checkIfCollectiveChange = (
  selectedSubtenants: SubtenantItem[],
): boolean => {
  if (!selectedSubtenants.length) {
    return false;
  }

  if (selectedSubtenants.length === 1) {
    return selectedSubtenants[0]?.value === ALL_TENANTS_OPTION;
  }

  return selectedSubtenants.every(s => s.type === SETTINGS_TYPE.COLLECTIVE);
};

export const findSubtenantThreshold = (
  subtenantId: UUID,
  allThresholds: AlertThreshold[],
  thresholdsDict: DataTenantDict,
  metric: AverageThresholdMetric,
  preposition: ThresholdPreposition,
): AlertThreshold | undefined =>
  allThresholds.find(
    (t: AlertThreshold) =>
      thresholdsDict[t.id] === subtenantId &&
      t.metric === metric &&
      t.preposition === preposition,
  );

export const applyForEachThreshold = (
  thresholdsData: TenantThresholdsState,
  fn: (threshold: EditableThreshold) => void,
) => {
  Object.values(thresholdsData).forEach(m => {
    Object.values(m).forEach(threshold => {
      fn(threshold);
    });
  });
};

export const upsertSubtenantThresholds = (
  selectedSubtenantId: UUID,
  thresholdsData: TenantThresholdsState,
  createSubtenantAlertThresholds: typeof gmActions.createSubtenantAlertThresholds,
  editSubtenantAlertThresholds: typeof gmActions.editSubtenantAlertThresholds,
): void => {
  applyForEachThreshold(thresholdsData, threshold => {
    const { id, dirty, enable, value, metric, preposition } = threshold;

    if (!dirty) {
      return;
    }

    threshold.id
      ? editSubtenantAlertThresholds({
          subtenantIdThresholdIdDict: {
            [selectedSubtenantId]: id,
          },
          data: { enable, value },
        })
      : createSubtenantAlertThresholds({
          subtenantIds: [selectedSubtenantId],
          data: {
            enable,
            metric,
            preposition,
            value,
          },
        });
  });
};

export const upsertMultiSubtenantThresholds = (
  subtenantsToUpdate: SubtenantItem[],
  allThresholds: AlertThreshold[],
  thresholdsDict: DataTenantDict,
  thresholdsData: TenantThresholdsState,
  createSubtenantAlertThresholds: typeof gmActions.createSubtenantAlertThresholds,
  editSubtenantAlertThresholds: typeof gmActions.editSubtenantAlertThresholds,
): void => {
  applyForEachThreshold(thresholdsData, threshold => {
    const { enable, value, metric, preposition } = threshold;

    const subtenantThresholdPairs = subtenantsToUpdate.map(
      s =>
        [
          s.value,
          findSubtenantThreshold(
            s.value,
            allThresholds,
            thresholdsDict,
            metric,
            preposition,
          ),
        ] as const,
    );

    const subtenatsForCreate = subtenantThresholdPairs
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, threshold]) => !threshold)
      .map(([subtenantId]) => subtenantId);
    const subtenatsForUpdate: Record<UUID, UUID> = subtenantThresholdPairs
      .filter((pair): pair is [string, Truthy<AlertThreshold>] => !!pair[1])
      .reduce(
        (acc, [subtenantId, threshold]) => ({
          ...acc,
          [subtenantId]: threshold.id,
        }),
        {},
      );

    subtenatsForCreate.length &&
      createSubtenantAlertThresholds({
        subtenantIds: subtenatsForCreate,
        data: {
          enable,
          metric,
          preposition,
          value,
        },
      });
    Object.keys(subtenatsForUpdate).length &&
      editSubtenantAlertThresholds({
        subtenantIdThresholdIdDict: subtenatsForUpdate,
        data: { enable, value },
      });
  });
};

export const findSubtenantBaselineThreshold = (
  subtenantId: UUID,
  allThresholds: BaselineAlertThreshold[],
  thresholdsDict: DataTenantDict,
  metric: BaselineMetric,
): BaselineAlertThreshold | undefined =>
  allThresholds.find(
    (t: BaselineAlertThreshold) =>
      thresholdsDict[t.id] === subtenantId && t.metric === metric,
  );

export const applyForEachBaselineThreshold = (
  thresholdsData: TenantBaselineThresholdsState,
  fn: (threshold: EditableBaselineThreshold) => void,
) => {
  Object.values(thresholdsData).forEach(threshold => {
    fn(threshold);
  });
};

export const upsertSubtenantBaselineThresholds = (
  selectedSubtenantId: UUID,
  thresholdsData: TenantBaselineThresholdsState,
  createSubtenantBaselineAlertThresholds: typeof gmActions.createSubtenantBaselineAlertThresholds,
  editSubtenantBaselineAlertThresholds: typeof gmActions.editSubtenantBaselineAlertThresholds,
): void => {
  applyForEachBaselineThreshold(thresholdsData, threshold => {
    const {
      id,
      dirty,
      enable,
      metric,
      baselineDaysInterval,
      deviationHoursInterval,
      deviationPercentage,
    } = threshold;

    if (!dirty) {
      return;
    }

    if (threshold.id) {
      editSubtenantBaselineAlertThresholds({
        subtenantIdThresholdIdDict: {
          [selectedSubtenantId]: id,
        },
        data: {
          enable,
          ...(baselineDaysInterval ? { baselineDaysInterval } : {}),
          ...(deviationHoursInterval ? { deviationHoursInterval } : {}),
          ...(deviationPercentage ? { deviationPercentage } : {}),
        },
      });
    } else {
      if (!enable) {
        return;
      }

      createSubtenantBaselineAlertThresholds({
        subtenantIds: [selectedSubtenantId],
        data: {
          enable,
          metric,
          baselineDaysInterval,
          deviationHoursInterval,
          deviationPercentage,
        },
      });
    }
  });
};

export const upsertMultiSubtenantBaselineThresholds = (
  subtenantsToUpdate: SubtenantItem[],
  allThresholds: BaselineAlertThreshold[],
  thresholdsDict: DataTenantDict,
  thresholdsData: TenantBaselineThresholdsState,
  createSubtenantAlertThresholds: typeof gmActions.createSubtenantBaselineAlertThresholds,
  editSubtenantAlertThresholds: typeof gmActions.editSubtenantBaselineAlertThresholds,
): void => {
  applyForEachBaselineThreshold(thresholdsData, threshold => {
    const {
      enable,
      metric,
      baselineDaysInterval,
      deviationHoursInterval,
      deviationPercentage,
    } = threshold;

    const subtenantThresholdPairs = subtenantsToUpdate.map(
      s =>
        [
          s.value,
          findSubtenantBaselineThreshold(
            s.value,
            allThresholds,
            thresholdsDict,
            metric,
          ),
        ] as const,
    );

    const subtenatsForCreate = subtenantThresholdPairs
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, threshold]) => !threshold)
      .map(([subtenantId]) => subtenantId);
    const subtenatsForUpdate: Record<UUID, UUID> = subtenantThresholdPairs
      .filter(
        (pair): pair is [string, Truthy<BaselineAlertThreshold>] => !!pair[1],
      )
      .reduce(
        (acc, [subtenantId, threshold]) => ({
          ...acc,
          [subtenantId]: threshold.id,
        }),
        {},
      );

    if (enable) {
      subtenatsForCreate.length &&
        createSubtenantAlertThresholds({
          subtenantIds: subtenatsForCreate,
          data: {
            enable,
            metric,
            baselineDaysInterval,
            deviationHoursInterval,
            deviationPercentage,
          },
        });
    }

    Object.keys(subtenatsForUpdate).length &&
      editSubtenantAlertThresholds({
        subtenantIdThresholdIdDict: subtenatsForUpdate,
        data: {
          enable,
          ...(baselineDaysInterval ? { baselineDaysInterval } : {}),
          ...(deviationHoursInterval ? { deviationHoursInterval } : {}),
          ...(deviationPercentage ? { deviationPercentage } : {}),
        },
      });
  });
};

const filterMedicalSubscriptionsBy =
  (targetMedia: TargetMedia, alertType: MedicalAlertType) =>
  (acc: UserMedicalSubscription[], s: Subscriber): UserMedicalSubscription[] =>
    s.targetMedia === targetMedia && s.alertType === alertType
      ? [
          ...acc,
          {
            alertCategory: 'MEDICAL',
            targetMedia,
            id: s.userId,
            withPersonalDetails: !!s.withPersonalDetails,
            summaryReportEnabled: null,
            summaryReportTime: null,
            singleDisconnectionAllowedType: null,
            emailCcEnabled: null,
            emailCcRecipients: null,
          },
        ]
      : acc;

const filterActivitySubscriptionsBy =
  (targetMedia: TargetMedia, alertType: MedicalAlertType) =>
  (
    acc: UserActivitySubscription[],
    s: Subscriber,
  ): UserActivitySubscription[] =>
    s.targetMedia === targetMedia && s.alertType === alertType
      ? [
          ...acc,
          {
            alertCategory: 'ACTIVITY',
            targetMedia,
            id: s.userId,
          },
        ]
      : acc;

const filterTechnicalSubscriptionsBy =
  (targetMedia: TargetMedia, alertType: TechnicalAlertType) =>
  (
    acc: UserTechnicalSubscription[],
    s: Subscriber,
  ): UserTechnicalSubscription[] =>
    s.targetMedia === targetMedia && s.alertType === alertType
      ? [
          ...acc,
          {
            alertCategory: 'TECHNICAL',
            targetMedia,
            id: s.userId,
            withPersonalDetails: !!s.withPersonalDetails,
            summaryReportEnabled: s.summaryReportEnabled,
            summaryReportTime: s.summaryReportTime,
            singleDisconnectionAllowedType: s.singleDisconnectionAllowedType,
            emailCcEnabled: s.emailCcEnabled,
            emailCcRecipients: s.emailCcRecipients,
          },
        ]
      : acc;

const updateGmUserSubscriberMedical = (
  tenantUserSubscriptions: UserMedicalSubscription[],
  gmUserId: UUID,
  subscriptionDetails: MedicalSubscriptionDetails,
): UserMedicalSubscription[] =>
  subscriptionDetails.isAssigned
    ? mergeArraysWithUniqueIds(tenantUserSubscriptions, [
        {
          alertCategory: 'MEDICAL',
          targetMedia: 'EMAIL', //* Not relevant. Remains at EMAIL for now
          id: gmUserId,
          withPersonalDetails: subscriptionDetails.withPersonalDetails,
          summaryReportEnabled: null,
          summaryReportTime: null,
          singleDisconnectionAllowedType: null,
          emailCcEnabled: null,
          emailCcRecipients: null,
        },
      ])
    : tenantUserSubscriptions.filter(
        userSubscription => userSubscription.id !== gmUserId,
      );

const updateGmUserSubscriberTechnical = (
  tenantUserSubscriptions: UserTechnicalSubscription[],
  gmUserId: UUID,
  subscriptionDetails: TechnicalSubscriptionDetails,
): UserTechnicalSubscription[] =>
  subscriptionDetails.isAssigned
    ? mergeArraysWithUniqueIds(tenantUserSubscriptions, [
        {
          alertCategory: 'TECHNICAL',
          targetMedia: 'EMAIL', //* Not relevant. Remains at EMAIL for now
          id: gmUserId,
          withPersonalDetails: false,
          summaryReportEnabled: subscriptionDetails.summaryReportEnabled,
          summaryReportTime: subscriptionDetails.summaryReportTime,
          singleDisconnectionAllowedType:
            subscriptionDetails.singleDisconnectionAllowedType,
          emailCcEnabled: subscriptionDetails.emailCcEnabled,
          emailCcRecipients: subscriptionDetails.emailCcRecipients,
        },
      ])
    : tenantUserSubscriptions.filter(
        userSubscription => userSubscription.id !== gmUserId,
      );

const updateGmUserSubscriberActivity = (
  tenantUserSubscriptions: UserActivitySubscription[],
  gmUserId: UUID,
  subscriptionDetails: ActivitySubscriptionDetails,
): UserActivitySubscription[] =>
  subscriptionDetails.isAssigned
    ? mergeArraysWithUniqueIds(tenantUserSubscriptions, [
        {
          alertCategory: 'ACTIVITY',
          targetMedia: 'EMAIL', //* Not relevant. Remains at EMAIL for now
          id: gmUserId,
        },
      ])
    : tenantUserSubscriptions.filter(
        userSubscription => userSubscription.id !== gmUserId,
      );

export const getTenantSubscribersWithGM = (
  tenantSubscribers: Subscriber[],
  gmUserId: UUID,
  subscriptions: TenantSubscriptionDetails,
): UpdateSubscribersListPayload => ({
  targetMedias: {
    EMAIL: {
      HR: updateGmUserSubscriberMedical(
        tenantSubscribers.reduce(
          filterMedicalSubscriptionsBy('EMAIL', ALERT_METRIC_ENUM.HR),
          [],
        ),
        gmUserId,
        subscriptions.MEDICAL.EMAIL,
      ),
      RR: updateGmUserSubscriberMedical(
        tenantSubscribers.reduce(
          filterMedicalSubscriptionsBy('EMAIL', ALERT_METRIC_ENUM.RR),
          [],
        ),
        gmUserId,
        subscriptions.MEDICAL.EMAIL,
      ),
      HR_BASELINE: updateGmUserSubscriberMedical(
        tenantSubscribers.reduce(
          filterMedicalSubscriptionsBy('EMAIL', ALERT_METRIC_ENUM.HR_BASELINE),
          [],
        ),
        gmUserId,
        subscriptions.MEDICAL.EMAIL,
      ),
      RR_BASELINE: updateGmUserSubscriberMedical(
        tenantSubscribers.reduce(
          filterMedicalSubscriptionsBy('EMAIL', ALERT_METRIC_ENUM.RR_BASELINE),
          [],
        ),
        gmUserId,
        subscriptions.MEDICAL.EMAIL,
      ),
      DEVICE_DISCONNECTED: updateGmUserSubscriberTechnical(
        tenantSubscribers.reduce(
          filterTechnicalSubscriptionsBy(
            'EMAIL',
            ALERT_METRIC_ENUM.DEVICE_DISCONNECTED,
          ),
          [],
        ),
        gmUserId,
        subscriptions.TECHNICAL.EMAIL,
      ),
      ALL_DEVICES_DISCONNECTED: updateGmUserSubscriberTechnical(
        tenantSubscribers.reduce(
          filterTechnicalSubscriptionsBy(
            'EMAIL',
            ALERT_METRIC_ENUM.ALL_DEVICES_DISCONNECTED,
          ),
          [],
        ),
        gmUserId,
        subscriptions.TECHNICAL.EMAIL,
      ),
      BED_EXIT: updateGmUserSubscriberActivity(
        tenantSubscribers.reduce(
          filterActivitySubscriptionsBy('EMAIL', ALERT_METRIC_ENUM.BED_EXIT),
          [],
        ),
        gmUserId,
        subscriptions.ACTIVITY.EMAIL,
      ),
      BED_EXIT_FREQUENCY: updateGmUserSubscriberActivity(
        tenantSubscribers.reduce(
          filterActivitySubscriptionsBy(
            'EMAIL',
            ALERT_METRIC_ENUM.BED_EXIT_FREQUENCY,
          ),
          [],
        ),
        gmUserId,
        subscriptions.ACTIVITY.EMAIL,
      ),
      BED_TIME_BASELINE: updateGmUserSubscriberActivity(
        tenantSubscribers.reduce(
          filterActivitySubscriptionsBy(
            'EMAIL',
            ALERT_METRIC_ENUM.BED_TIME_BASELINE,
          ),
          [],
        ),
        gmUserId,
        subscriptions.ACTIVITY.EMAIL,
      ),
      LONG_OUT_OF_BED: updateGmUserSubscriberActivity(
        tenantSubscribers.reduce(
          filterActivitySubscriptionsBy(
            'EMAIL',
            ALERT_METRIC_ENUM.LONG_OUT_OF_BED,
          ),
          [],
        ),
        gmUserId,
        subscriptions.ACTIVITY.EMAIL,
      ),
    },
    SMS: {
      HR: updateGmUserSubscriberMedical(
        tenantSubscribers.reduce(
          filterMedicalSubscriptionsBy('SMS', ALERT_METRIC_ENUM.HR),
          [],
        ),
        gmUserId,
        subscriptions.MEDICAL.SMS,
      ),
      RR: updateGmUserSubscriberMedical(
        tenantSubscribers.reduce(
          filterMedicalSubscriptionsBy('SMS', ALERT_METRIC_ENUM.RR),
          [],
        ),
        gmUserId,
        subscriptions.MEDICAL.SMS,
      ),
      HR_BASELINE: updateGmUserSubscriberMedical(
        tenantSubscribers.reduce(
          filterMedicalSubscriptionsBy('SMS', ALERT_METRIC_ENUM.HR_BASELINE),
          [],
        ),
        gmUserId,
        subscriptions.MEDICAL.SMS,
      ),
      RR_BASELINE: updateGmUserSubscriberMedical(
        tenantSubscribers.reduce(
          filterMedicalSubscriptionsBy('SMS', ALERT_METRIC_ENUM.RR_BASELINE),
          [],
        ),
        gmUserId,
        subscriptions.MEDICAL.SMS,
      ),
      DEVICE_DISCONNECTED: updateGmUserSubscriberTechnical(
        tenantSubscribers.reduce(
          filterTechnicalSubscriptionsBy(
            'SMS',
            ALERT_METRIC_ENUM.DEVICE_DISCONNECTED,
          ),
          [],
        ),
        gmUserId,
        subscriptions.TECHNICAL.SMS,
      ),
      ALL_DEVICES_DISCONNECTED: updateGmUserSubscriberTechnical(
        tenantSubscribers.reduce(
          filterTechnicalSubscriptionsBy(
            'SMS',
            ALERT_METRIC_ENUM.ALL_DEVICES_DISCONNECTED,
          ),
          [],
        ),
        gmUserId,
        subscriptions.TECHNICAL.SMS,
      ),
      BED_EXIT: updateGmUserSubscriberActivity(
        tenantSubscribers.reduce(
          filterActivitySubscriptionsBy('SMS', ALERT_METRIC_ENUM.BED_EXIT),
          [],
        ),
        gmUserId,
        subscriptions.ACTIVITY.SMS,
      ),
      BED_EXIT_FREQUENCY: updateGmUserSubscriberActivity(
        tenantSubscribers.reduce(
          filterActivitySubscriptionsBy(
            'SMS',
            ALERT_METRIC_ENUM.BED_EXIT_FREQUENCY,
          ),
          [],
        ),
        gmUserId,
        subscriptions.ACTIVITY.SMS,
      ),
      BED_TIME_BASELINE: updateGmUserSubscriberActivity(
        tenantSubscribers.reduce(
          filterActivitySubscriptionsBy(
            'SMS',
            ALERT_METRIC_ENUM.BED_TIME_BASELINE,
          ),
          [],
        ),
        gmUserId,
        subscriptions.ACTIVITY.SMS,
      ),
      LONG_OUT_OF_BED: updateGmUserSubscriberActivity(
        tenantSubscribers.reduce(
          filterActivitySubscriptionsBy(
            'SMS',
            ALERT_METRIC_ENUM.LONG_OUT_OF_BED,
          ),
          [],
        ),
        gmUserId,
        subscriptions.ACTIVITY.SMS,
      ),
    },
  },
});
