import { MessageDescriptor } from 'react-intl';

export type MTMAlertSettingsOnSaveHandlers = {
  saveAlertThresholds: () => void;
  saveBaselineThresholds: () => void;
  saveSubscribers: () => void;
  saveThresholds?: () => void;
  saveActivityAlertsSettings?: () => void;
};

export type ErrorState = {
  subtenants: boolean;
  thresholds: boolean;
  baselineThresholds: boolean;
  technicalAlerts: boolean;
};

export const ALERT_SETTINGS_TAB_KEYS = Object.freeze({
  MEDICAL_ALERTS: 'MedicalAlerts',
  ACTIVITY_ALERTS: 'ActivityAlerts',
  CONNECTIVITY_ALERTS: 'ConnectivityAlerts',
});

type KEYS = keyof typeof ALERT_SETTINGS_TAB_KEYS;

export type AlertSettingsTabType = {
  key: (typeof ALERT_SETTINGS_TAB_KEYS)[KEYS];
  labelMessage: MessageDescriptor;
  component: React.ElementType;
  editablePermissions?: string[];
};

export type AlertSettingsTabTypeKey = AlertSettingsTabType['key'];
