import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Typography } from 'antd';

import { NurseStationDevice } from 'src/routes/NursesStation/modules/types';
import LocationAssigner from '../LocationAssigner';
import {
  RoomLocationItem,
  BedLocationItem,
  StyledDeviceLocation,
} from './styled';
import { getValueOrNA } from 'src/utils/dataUtils';
import { deepEqual } from 'src/utils/comparators';

type Props = Pick<
  NurseStationDevice,
  'id' | 'roomName' | 'bedName' | 'isDeviceConnected'
>;

const { Paragraph } = Typography;

const DeviceLocation = ({
  id,
  roomName,
  bedName,
  isDeviceConnected,
}: Props): JSX.Element => {
  if (!roomName || !bedName) {
    return <LocationAssigner deviceId={id} />;
  }
  const displayRoomName = getValueOrNA(roomName);
  const displayBedName = getValueOrNA(bedName);

  return (
    <LocationAssigner
      deviceId={id}
      initialRoomName={roomName}
      initialBedName={bedName}
      CustomAssignBtn={({ onClick }) => (
        <StyledDeviceLocation isDisabled={!isDeviceConnected} onClick={onClick}>
          <RoomLocationItem>
            <Paragraph
              ellipsis={{
                rows: 1,
                tooltip: displayRoomName,
              }}
            >
              {displayRoomName}
            </Paragraph>
          </RoomLocationItem>
          <BedLocationItem>
            <Paragraph
              ellipsis={{
                rows: 1,
                tooltip: displayBedName,
              }}
            >
              <FormattedMessage {...messages.bed} />
              <span className="bed-name-value">{displayBedName}</span>
            </Paragraph>
          </BedLocationItem>
        </StyledDeviceLocation>
      )}
    />
  );
};

const messages = defineMessages({
  bed: {
    defaultMessage: 'Bed',
  },
});

export default React.memo(DeviceLocation, (oldProps, newProps) =>
  deepEqual(oldProps, newProps),
);
