import { ConnectedProps, connect } from 'react-redux';

import { RootState } from 'src/redux/store';
import { selectors as measurementSettingsSelectors } from 'src/redux/data/settings';
import { selectors as sessionsSelectors } from 'src/redux/data/sessions';
import { selectors as idbSelectors } from 'src/routes/IndividualDashboard/modules/dashboard/slice';
import { selectors as monitorSelectors } from 'src/redux/data/monitor';

export type SpotDataValue = Record<
  string,
  Partial<{
    hr: string;
    rr: string;
  }>
>;

const getSpotData = (state: RootState) => {
  const spotData =
    monitorSelectors.getSpotData(state, idbSelectors.getDeviceId(state)) || {};
  const deviceId = idbSelectors.getDeviceId(state);
  const latestSessions = sessionsSelectors.selectSessions(state);
  const previouslyAssignedToDevicePatientId = [...latestSessions]
    .sort((a, b) => b.startTime.localeCompare(a.startTime))
    .find(d => d.deviceId === deviceId)?.patientId;
  const currentPatientId = idbSelectors.getCurrentPatientId(state);

  if (
    previouslyAssignedToDevicePatientId &&
    currentPatientId &&
    currentPatientId === previouslyAssignedToDevicePatientId
  ) {
    return spotData;
  }

  return {};
};

const mapStateToProps = (state: RootState) => ({
  deviceId: idbSelectors.getDeviceId(state),
  spotData: getSpotData(state),
  shouldDisplayHighQualityOnly:
    measurementSettingsSelectors.getShouldDisplayHighQualityOnly(state),
});

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
