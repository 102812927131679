import { all, takeLatest, call, put, select } from 'typed-redux-saga/macro';

import BackendService from 'src/services/BackendService';
import {
  notifyUserByActionTypeAndCode,
  actionSuccessNotification,
  notificationsPlacementTypes,
} from 'src/utils/errorHandling/notifications';
import { actions as sessionsActions } from 'src/redux/data/sessions';
import { actions as monitorActions } from 'src/redux/data/monitor/modules/slice';
import { actions as loginActions } from 'src/redux/data/loggedInUser/modules/slice';
import { actions as settingsPageActions } from 'src/routes/Settings/modules/slice';
import AppConfig from 'src/config/AppConfig';
import { actions, selectors } from './slice';

function* updateMeasurementSettings(
  action: ReturnType<typeof actions.onUpdateMeasurementSettings>,
) {
  const { highQuality } = action.payload;
  const updateMeasurementSettingsRequest = {
    highQuality,
  };

  try {
    const settingsId = yield* select(selectors.getSettingsId);

    const measurementSettingsResponse =
      settingsId !== AppConfig.DEFAULT_SETTINGS_ID
        ? yield* call(
            BackendService.updateMeasurementSettings,
            updateMeasurementSettingsRequest,
          )
        : yield* call(
            BackendService.createMeasurementSettings,
            updateMeasurementSettingsRequest,
          );

    yield* put(
      actions.onGetMeasurementSettingsSuccess(measurementSettingsResponse.data),
    );
    actionSuccessNotification(
      action.type,
      updateMeasurementSettingsRequest?.highQuality,
      notificationsPlacementTypes.BOTTOM_RIGHT,
    );
  } catch (error) {
    console.error('error in updateMeasurementSettings: ', error);
    yield* put(actions.onGetMeasurementSettingsFailed());
    notifyUserByActionTypeAndCode(
      action.type,
      updateMeasurementSettingsRequest?.highQuality,
      error,
    );
  }
}

function* getMeasurementSettings() {
  try {
    const measurementSettingsResponse = yield* call(
      BackendService.getMeasurementSettings,
    );
    yield* put(
      actions.onGetMeasurementSettingsSuccess(measurementSettingsResponse.data),
    );
  } catch (e) {
    console.error('error in getMeasurementSettings: ', e);
    yield* put(actions.onGetMeasurementSettingsFailed());
  }
}

export default function* watchAlertsActions() {
  yield* all([
    takeLatest(actions.onGetMeasurementSettings, getMeasurementSettings),
    takeLatest(monitorActions.onClickStartContinuous, getMeasurementSettings),
    takeLatest(loginActions.loginUser, getMeasurementSettings),
    takeLatest(sessionsActions.onFetchSessions, getMeasurementSettings),
    takeLatest(
      settingsPageActions.onSettingsPageMounted,
      getMeasurementSettings,
    ),
    takeLatest(actions.onUpdateMeasurementSettings, updateMeasurementSettings),
  ]);
}
