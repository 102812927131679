import { call, put, select, take, all } from 'typed-redux-saga/macro';

import { selectors as alertSelectors } from 'src/redux/data/alerts';
import { actions as gmActions } from 'src/redux/data/groupManager';
import { Alert, ALERT_TYPES } from 'src/types/alerts';
import { UUID } from 'src/types/utility';
import { filterDuplicates } from 'src/utils/dataUtils';
import { GM_DATA_KEYS } from './constants';
import { hasGMPermissions } from 'src/utils/permissions';
import { Action } from '@reduxjs/toolkit';
import { FetchMTMAttentionListResponse } from 'src/services/types';
import {
  DataKey,
  DataTenantDict,
} from 'src/redux/data/groupManager/modules/types';

export const getAlertDeviceIdsBySubtenant = (
  currentAlerts: Alert[],
  attentionList: FetchMTMAttentionListResponse['data'],
): UUID[] => {
  const currentAlertsDeviceIds = currentAlerts.reduce((acc: UUID[], alert) => {
    return [...acc, alert.deviceId || ''];
  }, []);

  const attentionListDeviceIds = attentionList
    .filter(elem => elem.type === ALERT_TYPES.MEDICAL)
    .reduce((acc: UUID[], alert) => {
      return [...acc, alert.deviceId || ''];
    }, []);

  const result = [...currentAlertsDeviceIds, ...attentionListDeviceIds];
  return filterDuplicates(result);
};

function* onAlertsLoaded(payload: { dataKey: DataKey; dict: DataTenantDict }) {
  if (!hasGMPermissions()) {
    return;
  }

  const alertIds = Object.keys(payload.dict);
  const currentAlertsData = yield* select(alertSelectors.selectAlerts);

  const currentAlertsPatientIds = filterDuplicates(
    currentAlertsData
      .filter(alert => !!alert.patientId && alertIds.includes(alert.id))
      .reduce((acc: UUID[], alert) => {
        return [...acc, alert.patientId || ''];
      }, []),
  );
  const currentAlertsDeviceIds = filterDuplicates(
    currentAlertsData.reduce((acc: UUID[], alert) => {
      return [...acc, alert.deviceId || ''];
    }, []),
  );

  yield* all([
    put(gmActions.fetchMultitenantPatients(currentAlertsPatientIds)),
    put(
      gmActions.fetchSubtenantData({
        dataKey: GM_DATA_KEYS.ROOMS,
        payload: currentAlertsDeviceIds,
      }),
    ),
  ]);
}

export default function* watchTenantDataActions() {
  while (true) {
    // @ts-ignore Redux saga does not have types for RTK
    const currentAlertsActions: {
      payload: { dataKey: DataKey; dict: DataTenantDict };
    } = yield* take((action: Action) => {
      return (
        (action.type ===
          gmActions.updateSubtenantDataIncrementallySuccess.type ||
          action.type === gmActions.fetchSubtenantDataSuccess.type) &&
        // @ts-ignore Redux saga does not have types for RTK
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        action?.payload?.dataKey === GM_DATA_KEYS.ALERTS
      );
    });
    yield* call(onAlertsLoaded, currentAlertsActions.payload);
  }
}
