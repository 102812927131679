import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { MainTitle, PageWrapper } from 'src/components/styled';
import Table from 'src/components/CrudCommonComponents/Table';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import permissions from 'src/permissions';
import { hasAnyPermissions } from 'src/utils/permissions';
import {
  PATIENTS_TABLE_ACTIONS_KEYS,
  patientFormModes,
  getPatientPageColumns,
} from '../modules/constants';
import PatientModal from './PatientModal';
import HistoryModal from '../HistoryModal';

const PatientsTable = styled(Table)`
  .ant-table-row {
    cursor: ${props => (props.clickable ? 'pointer' : '')};
  }
`;

const Patients = ({
  fetchAllPatients,
  patientsList,
  deletePatient,
  createPatient,
  updatePatient,
  isLoading,
  isHistoryModalVisible,
  openHistoryModal,
  closeHistoryModal,
  intl,
  modalStatus,
  setModalStatus,
}) => {
  const [activeRow, setActiveRow] = useState({});
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);

  const clickable = hasAnyPermissions(permissions.VIEW_PATIENTS_MEDICAL_INFO);

  const columns = getPatientPageColumns(intl);

  const ACTION_PERMISSION_MAP = {
    [permissions.VIEW_PATIENTS_MEDICAL_INFO]: [
      {
        key: PATIENTS_TABLE_ACTIONS_KEYS.VIEW,
        name: intl.formatMessage(messages.view),
        onClick: (event, { record }) => {
          event.stopPropagation(); // in order to prevent the history modal from opening
          _onViewRow(record);
        },
      },
    ],
    [permissions.VIEW_PATIENTS_DELETE_PATIENT]: [
      {
        key: PATIENTS_TABLE_ACTIONS_KEYS.DELETE,
        name: intl.formatMessage(messages.delete),
        onClick: (event, { record }) => {
          event.stopPropagation(); // in order to prevent the history modal from opening
          setActiveRow(record);
          setIsDeleteConfirmationVisible(true);
        },
      },
    ],
  };
  const actions = [
    {
      key: PATIENTS_TABLE_ACTIONS_KEYS.EDIT,
      name: intl.formatMessage(messages.edit),
      onClick: (event, { record }) => {
        event.stopPropagation(); // in order to prevent the history modal from opening
        setActiveRow(record);
        setIsEditModalVisible(true);
      },
    },
  ];
  Object.keys(ACTION_PERMISSION_MAP).forEach(permission => {
    const hasPermission = hasAnyPermissions(permission);
    if (hasPermission) {
      actions.unshift(...ACTION_PERMISSION_MAP[permission]);
    }
  });

  const _onViewRow = record => {
    setActiveRow(record);
    setIsHistoryModalVisible(true);
  };
  const _onRowRender = (record, rowIndex) => ({
    onClick: () => {
      clickable
        ? _onViewRow(record)
        : () => {
            return;
          };
    },
  });

  useEffect(() => {
    fetchAllPatients();
  }, [fetchAllPatients]);

  const setIsHistoryModalVisible = value => {
    if (value) {
      openHistoryModal();
    } else {
      closeHistoryModal();
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setTableData(
        patientsList.map(patient => ({
          key: patient.id,
          ...patient,
        })),
      );
    }
  }, [patientsList, isLoading]);

  return (
    <PageWrapper>
      <MainTitle>
        <FormattedMessage {...messages.patients} />
      </MainTitle>
      <PatientsTable
        loading={isLoading}
        data={tableData}
        columns={columns}
        actions={
          hasAnyPermissions(permissions.VIEW_PATIENTS_ADD_BUTTON)
            ? actions
            : null
        }
        withSearch
        searchPlaceholder={intl.formatMessage(messages.searchPlaceholder)}
        withAddButton={hasAnyPermissions(permissions.VIEW_PATIENTS_ADD_BUTTON)}
        addButtonOnClick={() => {
          setIsAddModalVisible(true);
        }}
        onRow={_onRowRender}
        clickable={clickable}
      />
      <PatientModal
        isModalVisible={isAddModalVisible}
        setIsModalVisible={value => setIsAddModalVisible(value)}
        onSubmit={createPatientData => {
          setIsAddModalVisible(false);
          createPatient(createPatientData);
        }}
        mode={patientFormModes.ADD}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
      />
      <ConfirmationModal
        isModalVisible={isDeleteConfirmationVisible}
        setIsModalVisible={value => {
          setIsDeleteConfirmationVisible(value);
        }}
        onOk={() => {
          deletePatient(activeRow.id);
        }}
        message={intl.formatMessage(messages.deleteConfirmation)}
      />
      <PatientModal
        isModalVisible={isEditModalVisible}
        setIsModalVisible={value => setIsEditModalVisible(value)}
        onSubmit={editPatientData => {
          updatePatient({
            id: activeRow.id,
            data: editPatientData,
          });
          setIsEditModalVisible(false);
        }}
        mode={patientFormModes.EDIT}
        currentPatient={activeRow}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
      />
      <HistoryModal
        isModalVisible={isHistoryModalVisible}
        setIsModalVisible={value => setIsHistoryModalVisible(value)}
        patientDetails={activeRow}
      />
    </PageWrapper>
  );
};

const messages = defineMessages({
  patients: {
    defaultMessage: 'Monitored Persons',
  },
  id: {
    defaultMessage: 'ID',
  },
  fullName: {
    defaultMessage: 'Full Name',
  },
  dateOfBirth: {
    defaultMessage: 'Date Of Birth',
  },
  searchPlaceholder: {
    defaultMessage: 'Search monitored person by id or name',
  },
  delete: {
    defaultMessage: 'Delete',
  },
  edit: {
    defaultMessage: 'Edit',
  },
  view: {
    defaultMessage: 'View',
  },
  deleteConfirmation: {
    defaultMessage: 'Are you sure you want to delete?',
  },
});

Patients.propTypes = {
  intl: PropTypes.object.isRequired,
  patientsList: PropTypes.array.isRequired,
  fetchAllPatients: PropTypes.func.isRequired,
  deletePatient: PropTypes.func.isRequired,
  createPatient: PropTypes.func.isRequired,
  updatePatient: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isHistoryModalVisible: PropTypes.bool.isRequired,
  openHistoryModal: PropTypes.func.isRequired,
  closeHistoryModal: PropTypes.func.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
};

export default injectIntl(Patients);
