import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DatePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { dateTimeFormat } from 'src/utils/graphsUtils/graphConstants';
import ActionButton from '../general-ui/Buttons/ActionButton';

const { RangePicker } = DatePicker;
export const StyledRangePicker = styled(RangePicker)`
  margin-right: 5px;
  border-color: ${props => props.theme.colors.historyDateRangeBorder};
  border-radius: ${props => props.theme.layout.defaultBorderRadius};
`;
const DatesWrapper = styled.div`
  .ant-picker-input input {
    font-size: 0.7rem;
  }
  .ant-picker {
    height: 1.7rem !important;
    line-height: 1.7rem !important;
  }
`;
const DatesRange = ({
  onApplyPressed,
  intl,
  defaultDatesRange,
  applyButton,
}) => {
  useEffect(() => {
    if (defaultDatesRange?.from && defaultDatesRange?.to) {
      setFromDate(defaultDatesRange.from);
      setToDate(defaultDatesRange.to);
    }
  }, [defaultDatesRange]);
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const onDatesSelected = dateMoments => {
    setFromDate(dateMoments?.[0]);
    setToDate(dateMoments?.[1]);
    !applyButton && onApplyPressed(dateMoments?.[0], dateMoments?.[1]);
  };
  const _onApplyPressed = () => {
    onApplyPressed(fromDate, toDate);
  };
  return (
    <DatesWrapper>
      <StyledRangePicker
        format={dateTimeFormat}
        onChange={onDatesSelected}
        showTime={{
          defaultValue: [
            moment('00:00:00', 'HH:mm:ss'),
            moment('00:00:00', 'HH:mm:ss'),
          ],
        }}
        value={[fromDate, toDate]}
      />
      {applyButton && (
        <ActionButton onClick={_onApplyPressed}>
          {intl.formatMessage(messages.apply)}
        </ActionButton>
      )}
    </DatesWrapper>
  );
};
const messages = defineMessages({
  apply: {
    defaultMessage: 'Apply',
  },
});
DatesRange.propTypes = {
  intl: PropTypes.object.isRequired,
  onApplyPressed: PropTypes.func.isRequired,
  defaultDatesRange: PropTypes.object,
  applyButton: PropTypes.bool,
};
DatesRange.defaultProps = {
  applyButton: true,
  defaultDatesRange: null,
};

export default injectIntl(DatesRange);
