import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { selectors as alertSelectors } from 'src/redux/data/alerts';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { selectors as subscriberSelectors } from 'src/redux/data/subscribers';
import { selectors as gmSelectors } from 'src/redux/data/groupManager';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import { mapThresholdsArrayToState } from 'src/components/AlertSettingsComponents/AlertThresholdsForm';
import { UUID } from 'src/types/utility';
import {
  computeSettingsType,
  getGMSubtenantSubscriptionDetails,
  getGMUserSubscriptionDetails,
  getSubtenantBaselineThresholds,
  getSubtenantThresholds,
} from 'src/routes/GMDashboard/modules/settingsUtils';
import {
  SETTINGS_TYPE,
  SubtenantSettingsType,
} from 'src/routes/GMDashboard/modules/constants';
import { mapBaselineThresholdsArrayToState } from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';

type OwnProps = {
  subtenantId: UUID;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const gmUserId = loggedInUserSelectors.getUserId(state);
  const allThresholds = alertSelectors.selectThresholds(state);
  const allBaselineThresholds = alertSelectors.selectBaselineThresholds(state);
  const allSubscribers = subscriberSelectors.selectSubscribersList(state);
  const thresholdsDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.ALERT_THRESHOLDS,
  );
  const baselineThresholdsDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.BASELINE_ALERT_THRESHOLDS,
  );
  const gmThresholds = mapThresholdsArrayToState(
    allThresholds.filter(threshold => !thresholdsDict[threshold.id]),
  );
  const gmBaselineThresholds = mapBaselineThresholdsArrayToState(
    allBaselineThresholds.filter(
      threshold => !baselineThresholdsDict[threshold.id],
    ),
  );
  const subscribersDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.SUBSCRIBERS,
  );
  const gmSubscribers = allSubscribers.filter(
    subscriber => !subscribersDict[subscriber.id],
  );
  const gmSubscriptionDetails = getGMUserSubscriptionDetails(
    gmUserId ?? '',
    gmSubscribers,
  );
  const activityAlertSettings =
    alertSelectors.selectActivityAlertSettings(state);
  const isTenantUnique = (id: UUID) => {
    return !!activityAlertSettings?.uniqueChildTenantIds?.includes(id);
  };
  const subtenantSettingsType: SubtenantSettingsType = isTenantUnique(
    ownProps.subtenantId,
  )
    ? SETTINGS_TYPE.UNIQUE
    : computeSettingsType(
        {
          gm: gmThresholds,
          subtenant: getSubtenantThresholds(
            ownProps.subtenantId,
            allThresholds,
            thresholdsDict,
          ),
        },
        {
          gm: gmBaselineThresholds,
          subtenant: getSubtenantBaselineThresholds(
            ownProps.subtenantId,
            allBaselineThresholds,
            baselineThresholdsDict,
          ),
        },
        {
          gm: gmSubscriptionDetails,
          subtenant: getGMSubtenantSubscriptionDetails(
            gmUserId || '',
            ownProps.subtenantId,
            allSubscribers,
            subscribersDict,
          ),
        },
      );

  return {
    subtenantSettingsType,
  };
};

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type Props = OwnProps & PropsFromRedux;

export default Connector;
