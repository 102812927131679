export const SW_VERSIONS_FILE_FIELDS = {
  PACK_VERSION: 'packVersion',
  PACK_ID: 'packID',
  DATE: 'date',
  JSON: 'json',
};

export const DEVICE_TYPE = {
  DEVICE_130H: '130H',
  DEVICE_130H_PLUS: '130H-plus',
};

export const PROTOCOL = {
  HTTP: 'HTTP',
  HTTPS: 'HTTPS',
};

export const REGEX = {
  SEM_VERSION_EX:
    /^([0-9]|[1-9][0-9]*)\.([0-9]|[1-9][0-9]*)\.([0-9]|[1-9][0-9]*)(?:-([0-9A-Za-z-]+(?:\.[0-9A-Za-z-]+)*))?(?:\+[0-9A-Za-z-]+)?$/,
  UUID_EX:
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/,
  JSON_VERSION_EX: /\b\d\.\d\b/,
};
