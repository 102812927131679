import { defineMessages } from 'react-intl';

export const fileMessages = defineMessages({
  // error
  downloadFileError: {
    defaultMessage: 'Could not download file',
  },

  // success
});
