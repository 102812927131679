import React, { useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { deepEqual } from 'src/utils/comparators';
import { useDetectClickOutside } from '../hooks';
import {
  PatientSettingsTooltip,
  StyledCheckboxText,
  StyledContainer,
  StyledOpenBtn,
} from './styled';
import Connector, { Props } from './Connector';
import Checkbox from 'src/components/general-ui/Checkbox';
import AccessControl from 'src/components/AccessControl';
import permissions from 'src/permissions';

const PatientSettings = ({
  children,
  patientId,
  isVacancyAlertActive,
  setPatientVacancyAlertEnabled,
}: Props): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useDetectClickOutside(ref, isVisible, () => setIsVisible(false));

  const renderTooltipContent = () => (
    <div ref={ref}>
      <StyledContainer>
        <StyledCheckboxText>
          <FormattedMessage {...messages.vacancyNotification} />
        </StyledCheckboxText>
        <AccessControl
          permissions={[permissions.ALERT_SETTINGS_PATIENTS_ALERT_TYPES_UPDATE]}
        >
          <Checkbox
            checked={isVacancyAlertActive}
            handleChange={value =>
              setPatientVacancyAlertEnabled({
                patientId,
                isBedExitAlertEnabled: value,
              })
            }
          />
        </AccessControl>
      </StyledContainer>
    </div>
  );

  return (
    <PatientSettingsTooltip
      title={renderTooltipContent()}
      placement="bottom"
      open={isVisible}
    >
      <StyledOpenBtn onClick={() => setIsVisible(true)}>
        {children}
      </StyledOpenBtn>
    </PatientSettingsTooltip>
  );
};

const messages = defineMessages({
  vacancyNotification: {
    defaultMessage: 'BedExit',
  },
});

const MemoisedPatientSettings = React.memo(
  PatientSettings,
  (oldProps, newProps) => deepEqual(oldProps, newProps),
);

export default Connector(MemoisedPatientSettings);
