/* eslint-disable no-useless-concat */
/* eslint-disable no-param-reassign */
import CryptoJS from 'crypto-js';
import { HRI_NOTIFICATION_TYPES } from 'src/utils/graphsUtils/graphConstants';
import { notifyUserByActionTypeAndCode } from './errorHandling/notifications';
import { fileActions } from './constants';
import BackendService from '../services/BackendService';
import { MANAGMENT_NOTIFICATION_TYPES } from '../redux/data/managment/modules/constants';
import {
  DEVICE_TYPE,
  PROTOCOL,
  REGEX,
  SW_VERSIONS_FILE_FIELDS,
} from 'src/routes/FirmwareManager/Versions/constants';

export function initFileDownloadUsingAnchorElement(url, downloadedFileName) {
  let link;
  try {
    link = document.createElement('a');
    document.body.appendChild(link);
    link.style = 'display: none';
    link.href = url;
    if (downloadedFileName) {
      link.download = downloadedFileName;
    }
    link.target = '_blank';
    link.click();
  } catch (error) {
    console.error('error in init file download using anchor element', error);
    notifyUserByActionTypeAndCode(
      fileActions.DOWNLOAD,
      downloadedFileName,
      error,
    );
  } finally {
    link && link.remove();
  }
}

export function createAndDownloadFileWithParsing(
  fileContent,
  downloadedFileName,
) {
  const file = new Blob(
    [
      JSON.stringify(fileContent)
        .replaceAll('\\n', '\n')
        .replaceAll('\\r', '')
        .replaceAll('"', ''),
    ],
    {
      type: 'text/plain',
    },
  );
  initFileDownloadUsingAnchorElement(
    URL.createObjectURL(file),
    downloadedFileName,
  );
}
const getAndSortArray = objArray => {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  return array.sort((a, b) => a.index - b.index);
};

const ConvertToCSV = objArray => {
  const array = getAndSortArray(objArray);
  const header =
    `${Object.keys(array[0])
      .map(value => `"${value}"`)
      .join(',')}` + '\r\n';

  return array.reduce((row, next) => {
    row +=
      `${Object.values(next)
        .map(value => `"${value}"`)
        .join(',')}` + '\r\n';
    return row;
  }, header);
};
const mapDeviceStatusToCSVState = {
  0: 'running',
  1: 'empty',
  3: 'low respiration',
  4: 'warming up',
  5: 'disabled',
  6: 'motion',
  7: 'speaking',
};
const getValueOrNaN = (obj, index = 0, defaultVal = 'NaN') =>
  obj ? obj[index] : defaultVal;

const ConvertVSToCSV = objArray => {
  const array = getAndSortArray(objArray);
  const header =
    'TIME, INDEX, HR, HR_QUALITY, RR, RR_QUALITY, IE, RAbin, STATE,\r\n';
  return array.reduce((row, next) => {
    const state = next.patientState
      ? mapDeviceStatusToCSVState[next.patientState[0]]
      : 'NaN';
    row += `${next?.time},${next?.index}, ${getValueOrNaN(next?.hr)}, \
    ${getValueOrNaN(next?.hr, 2)},${getValueOrNaN(next?.rr)},${getValueOrNaN(
      next?.rr,
      2,
    )}, \
    ${getValueOrNaN(next?.ie)},${getValueOrNaN(next?.raBin)},${state},\r\n`;
    return row;
  }, header);
};
const convertDataToCSVWithoutTitles = objArray => {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  return array.reduce((myStr, next) => {
    Object.keys(next).forEach(key => {
      myStr += `"${key}","${next[key]}"\r`;
    });
    return myStr;
  }, '');
};

const filteredFields = ['key', 'hriDataPresignedUrl', 'signedUrl'];
export const getHriRecordDetails = hriRecordData =>
  Object.entries(hriRecordData)
    .filter(entry => !filteredFields.includes(entry[0]))
    .map(e => ({ [e[0]]: e[1] }));

export const createAndDownloadHriCSVFile = async (hriRecordData, fileName) => {
  const additionalHriDetails = getHriRecordDetails(hriRecordData);
  const url = hriRecordData?.hriDataPresignedUrl
    ? hriRecordData.hriDataPresignedUrl
    : hriRecordData.signedUrl;
  DownloadHriFile(url, fileName, additionalHriDetails);
};

export const DownloadHriFile = async (url, fileName, additionalHriDetails) => {
  let response = null;
  try {
    response = await BackendService.getHriFile(url);
    let basicCsv = '';
    if (response?.data?.hri?.length > 0) {
      const basicDataJson = JSON.stringify(response?.data?.hri);
      basicCsv = ConvertToCSV(basicDataJson);
    } else {
      additionalHriDetails.push({ hriData: 'HRI data is not available' });
    }
    const moreDetailsDataJson = JSON.stringify(additionalHriDetails);
    const moreDetailsCSV = convertDataToCSVWithoutTitles(moreDetailsDataJson);
    const finalCsv = moreDetailsCSV + basicCsv;
    const uri = `data:text/csv;charset=utf-8,${escape(finalCsv)}`;
    initFileDownloadUsingAnchorElement(uri, fileName);
  } catch (error) {
    notifyUserByActionTypeAndCode(
      HRI_NOTIFICATION_TYPES.DOWNLOAD,
      fileName,
      error,
    );
  }
};

export const createAndDownloadSessionDataCSVFile = async (
  sessionInfo,
  data,
  fwVersion,
  fileName,
) => {
  if (data?.length > 0) {
    const infoHeader = `session, ${sessionInfo?.sessionId}\r\n \
    patient,${sessionInfo?.patientId}\r\ntenantId,${sessionInfo?.tenantId}\r\n \
    deviceId,${sessionInfo?.deviceId}\r\nfwVersion${fwVersion}\r\n \
    startTime,${sessionInfo?.startTime}\r\nendTime,${sessionInfo?.endTime}\r\n`;
    const csvContent = infoHeader + ConvertVSToCSV(JSON.stringify(data));
    const uri = `data:text/csv;charset=utf-8,${escape(csvContent)}`;
    initFileDownloadUsingAnchorElement(uri, fileName);
  } else {
    notifyUserByActionTypeAndCode(
      MANAGMENT_NOTIFICATION_TYPES.NO_DATA_AVAILABLE,
      null,
      null,
    );
  }
};

export function createAndDownloadJsonContentFile(
  fileContent,
  downloadedFileName,
) {
  const file = new Blob(
    [
      JSON.stringify(fileContent)
        .replaceAll('\\n', '\n')
        .replaceAll('\\"', '"')
        .replaceAll('"{', '{')
        .replaceAll('}"', '}'),
    ],
    {
      type: 'text/plain',
    },
  );
  initFileDownloadUsingAnchorElement(
    URL.createObjectURL(file),
    downloadedFileName,
  );
}

function readFileAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = () => {
      reader.abort();
      reject(new Error('Problem parsing input file'));
    };

    reader.readAsArrayBuffer(file);
  });
}
export function getFieldFromFileText(fieldName, text) {
  const textAfterFieldName = text.substring(
    text.indexOf(`${fieldName}"`) + (fieldName.length + 1),
  );

  const matchString = '"(.*)"';
  const matcharray = textAfterFieldName.match(matchString)[1];

  const checkedSemanticVersion = matcharray.match(REGEX.SEM_VERSION_EX);
  const checkedUUID = matcharray.match(REGEX.UUID_EX);
  const checkedDate = new Date(matcharray);
  const checkedJson = matcharray.match(REGEX.JSON_VERSION_EX);

  switch (fieldName) {
    case SW_VERSIONS_FILE_FIELDS.PACK_VERSION:
      if (!checkedSemanticVersion) return;
      break;
    case SW_VERSIONS_FILE_FIELDS.PACK_ID:
      if (!checkedUUID) return;
      break;
    case SW_VERSIONS_FILE_FIELDS.DATE:
      if (isNaN(checkedDate)) return;
      break;
    case SW_VERSIONS_FILE_FIELDS.JSON:
      if (!checkedJson) return;
      break;
  }

  return matcharray;
}

export function readFileAsText(file) {
  return new Promise((resolve, rej) => {
    const reader = new FileReader();
    reader.addEventListener('load', event => {
      resolve(event.target.result);
    });
    reader.readAsText(file);
  });
}

export async function calculateFileChecksum(fileToUpload) {
  const fileContent = await readFileAsArrayBuffer(fileToUpload);
  return CryptoJS.SHA256(CryptoJS.lib.WordArray.create(fileContent)).toString(
    CryptoJS.enc.Hex,
  );
}

export const checkDeviceType = semVersion => {
  const semVersionMajor = parseInt(semVersion.split('.')[0]);
  const semVersionMinor = parseInt(semVersion.split('.')[1]);

  if (semVersionMajor === 1 && semVersionMinor <= 50) {
    return { deviceType: DEVICE_TYPE.DEVICE_130H, protocol: PROTOCOL.HTTP };
  } else if (semVersionMajor === 1 && semVersionMinor > 50) {
    return { deviceType: DEVICE_TYPE.DEVICE_130H, protocol: PROTOCOL.HTTP };
  } else if (semVersionMajor === 2) {
    return {
      deviceType: DEVICE_TYPE.DEVICE_130H_PLUS,
      protocol: PROTOCOL.HTTPS,
    };
  }
};
