export const GM_DATA_KEYS = {
  ALERTS: 'alerts',
  ALERT_SETTINGS: 'alertSettings',
  ALERT_THRESHOLDS: 'alertThresholds',
  BASELINE_ALERT_THRESHOLDS: 'baselineAlertThresholds',
  PATIENTS: 'patients',
  DEVICES: 'devices',
  ROOMS: 'rooms',
  SESSIONS: 'sessions',
  USERS: 'users',
  SUBSCRIBERS: 'subscribers',
  ACTIVITY_ALERTS_SETTINGS: 'activityAlertSettings',
} as const;

export const INVALID_CACHE_ID = 'INVALID_ID';
