/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { MainTitle, PageWrapper } from 'src/components/styled';

import ActionButton from 'src/components/general-ui/Buttons/ActionButton';
import styled from 'styled-components';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import SearchDevice from './SearchDevice';
import DatesRange from '../../../components/datesRange/DatesRange';
import Table from '../../../components/CrudCommonComponents/Table';
import TimesTempModal from './TimesTempModal';

const DeviceArea = styled.div`
  display: flex;
  gap: 20px;
`;

const ManagmentPage = ({
  intl,
  devicesList,
  onLoadManagment,
  sessionsList,
  deviceId,
  timeRange,
  onSelectDevice,
  onSelectTimesTamp,
  onApplyDevice,
  sessionsStatus,
  setRangeTimeForSession,
  onDownloadSession,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openSession, setOpenSession] = useState(null);

  useEffect(() => {
    onLoadManagment();
  }, []);

  const downloadSession = record => {
    onDownloadSession(record);
  };

  const setTimesTamp = record => {
    setOpenSession(record.sessionId);
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: intl.formatMessage(messages.sessionId),
      dataIndex: 'sessionId',
      key: 'sessionId',
      sorter: (a, b) => a.sessionId - b.sessionId,
    },
    {
      title: intl.formatMessage(messages.startSession),
      dataIndex: 'startTime',
      key: 'startTime',
      sorter: (a, b) => a.startTime - b.startTime,
    },
    {
      title: intl.formatMessage(messages.endSession),
      dataIndex: 'endTime',
      key: 'endTime',
      sorter: (a, b) => a.endTime - b.endTime,
    },
    {
      title: intl.formatMessage(messages.timesTamp),
      dataIndex: 'timesTamp',
      key: 'timesTamp',
    },
  ];

  const actions = [
    {
      key: 'setTimesTamp',
      name: intl.formatMessage(messages.setTimesTemp),
      onClick: (_, { record }) => {
        setTimesTamp(record);
      },
    },
    {
      key: 'download',
      name: intl.formatMessage(messages.download),
      onClick: (_, { record }) => {
        downloadSession(record);
      },
    },
  ];

  const disableApply =
    !timeRange || !timeRange.to || !timeRange.from || !deviceId;

  return (
    <PageWrapper>
      <MainTitle>
        <FormattedMessage {...messages.managment} />
      </MainTitle>
      <DeviceArea>
        <SearchDevice
          devicesList={devicesList}
          onSelectDevice={selectedDevice => onSelectDevice(selectedDevice)}
          selectedDevice={deviceId}
        />
        <DatesRange
          onApplyPressed={(from, to) => onSelectTimesTamp({ from, to })}
          applyButton={false}
          defaultDatesRange={timeRange}
        />
        <ActionButton disabled={disableApply} onClick={onApplyDevice}>
          <FormattedMessage {...messages.apply} />
        </ActionButton>
      </DeviceArea>
      <Table
        loading={sessionsStatus === DATA_FETCHING_STATUS.LOADING}
        data={sessionsList}
        columns={columns}
        actions={actions}
      />
      <TimesTempModal
        isModalVisible={isModalVisible}
        setIsModalVisible={modalStatus => setIsModalVisible(modalStatus)}
        setRangeTimeForSession={rangeTime =>
          setRangeTimeForSession({ openSession, rangeTime })
        }
      />
    </PageWrapper>
  );
};
const messages = defineMessages({
  managment: {
    defaultMessage: 'Management',
  },
  sessionId: {
    defaultMessage: 'Session Id',
  },
  startSession: {
    defaultMessage: 'Start session',
  },
  endSession: {
    defaultMessage: 'End session',
  },
  download: {
    defaultMessage: 'Download',
  },
  setTimesTemp: {
    defaultMessage: 'Set range',
  },
  timesTamp: {
    defaultMessage: 'Requested range',
  },
  apply: {
    defaultMessage: 'Apply',
  },
});

ManagmentPage.propTypes = {
  intl: PropTypes.object.isRequired,
  devicesList: PropTypes.array.isRequired,
  onLoadManagment: PropTypes.func.isRequired,
  sessionsList: PropTypes.array.isRequired,
  timeRange: PropTypes.object,
  deviceId: PropTypes.string,
  onSelectDevice: PropTypes.func.isRequired,
  onSelectTimesTamp: PropTypes.func.isRequired,
  onApplyDevice: PropTypes.func.isRequired,
  sessionsStatus: PropTypes.string.isRequired,
  setRangeTimeForSession: PropTypes.func.isRequired,
  onDownloadSession: PropTypes.func.isRequired,
};
ManagmentPage.defaultProps = {
  timeRange: null,
  deviceId: null,
};

export default injectIntl(ManagmentPage);
