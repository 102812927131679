import { connect } from 'react-redux';
import { actions } from '../modules/slice';
import Component from '../components/Component';

const mapStateToProps = state => ({});

const mapActionCreators = {
  ...actions,
};

export default connect(mapStateToProps, mapActionCreators)(Component);
