import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';

import permissions from 'src/permissions';
import {
  CreatePatientPayload,
  UpdatePatientPayload,
} from 'src/redux/data/patient/modules/types';
import { patientFormModes } from './constants';
import { getPatientPageColumns } from './utils';
import PatientModal from './PatientModal';
import Connector, { PropsFromRedux } from './Connector';
import { Wrapper, PatientsTable } from './styled';
import { MonitoredPatient } from './types';

type Props = PropsFromRedux & {
  intl: IntlShape;
  isMonitoredPersonsPageLoading?: boolean;
};

const MonitoredPersons = ({
  fetchAllPatients,
  fetchPatientsSessions,
  patientsList,
  createPatient,
  updatePatient,
  isLoading,
  intl,
  modalStatus,
  setModalStatus,
  stopContinuousSession,
  areMonitoredPersonsPageLoading,
}: Props): JSX.Element => {
  const [activeRow, setActiveRow] = useState<MonitoredPatient>();
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [tableData, setTableData] = useState<MonitoredPatient[]>([]);

  const columns = getPatientPageColumns(intl);
  const actions = [
    {
      key: 'edit',
      name: intl.formatMessage(messages.edit),
      onClick: (_event: Event, { record }: { record: MonitoredPatient }) => {
        setActiveRow(record);
        setIsEditModalVisible(true);
      },
      isDisabled: ({ record }: { record: MonitoredPatient }) =>
        record.isPatientDeviceDischarging,
      permissions: () => [permissions.ORGANIZATION_PATIENTS_UPDATE],
    },
    {
      key: 'discharge',
      name: intl.formatMessage(messages.discharge),
      onClick: (_event: Event, { record }: { record: MonitoredPatient }) => {
        record.deviceId && stopContinuousSession({ deviceId: record.deviceId });
      },
      isDisabled: ({ record }: { record: MonitoredPatient }) =>
        record.patientStatus !== 'ASSIGNED' ||
        record.isPatientDeviceDischarging,
      permissions: () => [permissions.TELEMETRY_STOP_CONTINUOUS_SESSION],
    },
  ];

  useEffect(() => {
    fetchAllPatients();
    fetchPatientsSessions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setTableData(
        patientsList.map(patient => ({
          key: patient.id,
          ...patient,
        })),
      );
    }
  }, [patientsList, isLoading]);

  const [tableHeight, setTableHeight] = useState(600);
  const ref = useRef<HTMLDivElement>(null);

  // To DO: to remove height calc and adjust styling to adapt from the parent's height
  useLayoutEffect(() => {
    const node = ref.current;
    const { top } = node ? node.getBoundingClientRect() : { top: 0 };
    setTableHeight(window.innerHeight - top - 130);
  }, [ref]);

  return (
    <Wrapper
      ref={ref}
      data-cy={`monitored-persons-loading=${areMonitoredPersonsPageLoading.toString()}`}
    >
      <PatientsTable
        loading={isLoading}
        data={tableData}
        columns={columns}
        scroll={{ y: tableHeight }}
        actions={actions}
        withSearch
        isSubComponent={true}
        searchPlaceholder={intl.formatMessage(messages.searchPlaceholder)}
        addButtonPermission={permissions.ORGANIZATION_PATIENTS_CREATE}
        addButtonText={intl.formatMessage(messages.addBtnLabel)}
        addButtonOnClick={() => {
          setIsAddModalVisible(true);
        }}
        rowClassName={(record: MonitoredPatient) =>
          record.isPatientDeviceDischarging ? 'discharging-patient' : ''
        }
      />
      <PatientModal
        isModalVisible={isAddModalVisible}
        setIsModalVisible={value => setIsAddModalVisible(value)}
        onSubmit={createPatientData => {
          setIsAddModalVisible(false);
          createPatient(createPatientData as CreatePatientPayload);
        }}
        mode={patientFormModes.ADD}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
      />
      <PatientModal
        isModalVisible={isEditModalVisible}
        setIsModalVisible={value => setIsEditModalVisible(value)}
        onSubmit={editPatientData => {
          updatePatient({
            id: activeRow?.id || '',
            data: editPatientData as UpdatePatientPayload['data'],
          });
          setIsEditModalVisible(false);
        }}
        mode={patientFormModes.EDIT}
        currentPatient={activeRow}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
      />
    </Wrapper>
  );
};

const messages = defineMessages({
  searchPlaceholder: {
    defaultMessage: 'Search for patient',
  },
  addBtnLabel: {
    defaultMessage: '+ Add patient',
  },
  discharge: {
    defaultMessage: 'Discharge',
  },
  edit: {
    defaultMessage: 'Edit',
  },
});

export default Connector(injectIntl(MonitoredPersons));
