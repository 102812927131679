import React, { Dispatch, SetStateAction } from 'react';

import { TenantThresholdsState } from 'src/components/AlertSettingsComponents/AlertThresholdsForm';
import { StyledAlertThresholdsForm, StyledWrapper } from './styled';
import { deepEqual } from 'src/utils/comparators';

type Props = {
  setIsSaveBtnDisabled: (isDisabled: boolean) => void;
  thresholdsData: TenantThresholdsState;
  setThresholdsData: Dispatch<SetStateAction<TenantThresholdsState>>;
};

const ThresholdSettings = ({
  thresholdsData,
  setIsSaveBtnDisabled,
  setThresholdsData,
}: Props): JSX.Element => {
  return (
    <StyledWrapper>
      <StyledAlertThresholdsForm
        thresholds={thresholdsData}
        setThresholdData={setThresholdsData}
        setHasErrors={setIsSaveBtnDisabled}
      />
    </StyledWrapper>
  );
};

export default React.memo(ThresholdSettings, (oldProps, newProps) =>
  deepEqual(oldProps, newProps),
);
