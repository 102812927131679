import React, { useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { UUID } from 'src/types/utility';

import { Label } from '../styled';
import SubtenantDisconnectionOverviewModal from './SubtenantDisconnectionOverviewModal';

type Props = {
  subtenantId: UUID;
  subtenantName: string;
  title: MessageDescriptor;
};

const SubtenantDisconnectionOverview = ({
  subtenantId,
  subtenantName,
  title,
}: Props): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  return (
    <Label withUnderline withPointer onClick={() => setIsModalVisible(true)}>
      <FormattedMessage {...title} />
      <SubtenantDisconnectionOverviewModal
        subtenantId={subtenantId}
        subtenantName={subtenantName}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </Label>
  );
};

export default SubtenantDisconnectionOverview;
