import { connect } from 'react-redux';
import { apiStatusEnum } from 'src/utils/constants';
import Hri from '../components/Hri';
import { actions, selectors } from '../modules/slice';

const mapStateToProps = (state, ownProps) => ({
  hriData: selectors.getPatientHriData(state, ownProps.patientId),
  isLoading:
    selectors.getPatientHriStatus(state, ownProps.patientId) ===
    apiStatusEnum.LOADING,
});

const mapActionCreators = {
  onHriParamsChanged: actions.onHriParamsChanged,
  downloadHri: actions.downloadHri,
};

export default connect(mapStateToProps, mapActionCreators)(Hri);
