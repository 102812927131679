import { all, takeLatest, call, put } from 'redux-saga/effects';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import {
  notifyUserByActionTypeAndCode,
  actionSuccessNotification,
  notificationsPlacementTypes,
} from 'src/utils/errorHandling/notifications';
import AppConfig from 'src/config/AppConfig';
import { calculateFileChecksum } from 'src/utils/fileUtils';
import { actions } from './slice';
import BackendService from '../../../../services/BackendService';
import { DATA_FETCHING_STATUS } from '../../constants';

function* onLoadVersionList(action) {
  try {
    yield put(actions.setVersionListStatus(DATA_FETCHING_STATUS.LOADING));
    const { versionResponse, usersResponse } = yield all({
      versionResponse: call(BackendService.getVersion),
      usersResponse: call(BackendService.getAllUsers),
    });

    const versionWithUploaderName = versionResponse.data.data.map(version => {
      const uploaderUser = usersResponse.data.data.find(
        user => user.id === version.uploaderId,
      );
      return {
        ...version,
        uploaderName: uploaderUser
          ? `${uploaderUser.firstName} ${uploaderUser.lastName}`
          : null,
      };
    });
    yield put(actions.onLoadVersionListSuccess(versionWithUploaderName));
  } catch (e) {
    console.log('Error', e);
    yield put(actions.setVersionListStatus(DATA_FETCHING_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, e);
  }
}

function* onLoadLatestVersions(action) {
  const latestFilterParams = [
    {
      prop: 'latest',
      values: [true],
    },
  ];
  try {
    yield put(actions.setLatestVersionsStatus(DATA_FETCHING_STATUS.LOADING));
    const { data } = yield call(BackendService.getVersion, latestFilterParams);

    yield put(actions.setLatestVersionsSuccess(data?.data));
  } catch (e) {
    console.log('Error', e);
    yield put(actions.setLatestVersionsStatus(DATA_FETCHING_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, e);
  }
}

function* onLoadVersionsByDeviceType(action) {
  const deviceType = action?.payload;
  const filterParams = [
    {
      prop: 'deviceType',
      values: [deviceType],
    },
    {
      prop: 'valid',
      values: [true],
    },
  ];
  try {
    yield put(
      actions.setVersionsByDeviceStatus({
        status: DATA_FETCHING_STATUS.LOADING,
        deviceType,
      }),
    );
    const { data } = yield call(BackendService.getVersion, filterParams);

    yield put(
      actions.setVersionsByDeviceSuccess({
        data: data?.data,
        deviceType,
      }),
    );
  } catch (e) {
    console.log('Error', e);
    yield put(
      actions.setVersionsByDeviceStatus({
        status: DATA_FETCHING_STATUS.ERROR,
        deviceType,
      }),
    );
    notifyUserByActionTypeAndCode(action.type, e);
  }
}

function* onUploadVersion(action) {
  const { uploadFile, versionName, versionIdFromFile, description } =
    action.payload;
  try {
    const file = uploadFile?.[0];
    const uploadUrlData = yield call(
      BackendService.getUploadUrl,
      versionIdFromFile,
    );

    const { url, versionId } = uploadUrlData.data;
    const fileToUpload = new File([file.originFileObj], versionId, {
      type: file.originFileObj.type,
    });
    yield call(BackendService.uploadFile, { url, fileToUpload });
    const checksum = yield call(calculateFileChecksum, fileToUpload);

    const data = {
      name: versionName,
      fileSize: fileToUpload.size,
      checksum,
      algorithm: 'sha256',
      applicationIds: [AppConfig.APPLICATION_ID],
      description,
    };
    yield call(BackendService.createVersion, { versionId, data });
    yield put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
    yield call(onLoadVersionList);
    actionSuccessNotification(
      action.type,
      null,
      notificationsPlacementTypes.TOP_RIGHT,
    );
  } catch (error) {
    console.log('error in upload version', error);
    yield put(actions.setModalStatus(MODAL_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, null, error);
  }
}

function* onUpdateVersion(action) {
  const { versionName, versionId, data } = action.payload;
  try {
    yield call(BackendService.updateVersion, { versionId, data });

    actionSuccessNotification(
      action.type,
      null,
      notificationsPlacementTypes.TOP_RIGHT,
    );
    yield call(onLoadVersionList);
    yield call(onLoadLatestVersions);
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, versionName, error);
  }
}

function* deleteVersion(action) {
  const { id, name } = action.payload;
  try {
    yield call(BackendService.deleteVersion, id);
    yield call(onLoadVersionList);
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, name, error);
  }
}

export default function* watchUserActions() {
  yield all([
    takeLatest(actions.onLoadVersionList, onLoadVersionList),
    takeLatest(actions.onLoadLatestVersions, onLoadLatestVersions),
    takeLatest(actions.onLoadVersionsByDeviceType, onLoadVersionsByDeviceType),
    takeLatest(actions.onUpdateVersion, onUpdateVersion),
    takeLatest(actions.onUploadVersion, onUploadVersion),
    takeLatest(actions.deleteVersion, deleteVersion),
  ]);
}
