import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import {
  actions as userActions,
  selectors as userSelectors,
} from 'src/redux/data/user';
import { selectors as loggedInUserSelector } from 'src/redux/data/loggedInUser';
import { UserType } from 'src/types/users';
import { actions, selectors } from './modules/slice';
import { UUID } from 'src/types/utility';
import { USER_TYPES } from 'src/utils/constants';

type UserTypesInvitedByGm = {
  multiTenantUserTypeId: { id: UUID } | null;
  healthProfessionalTypeId: { id: UUID } | null;
  tenantAdminTypeId: { id: UUID } | null;
  multiTenantReadOnlyUserTypeId: { id: UUID } | null;
  healthProfessionalReadOnlyTypeId: { id: UUID } | null;
};

const getUserTypesInvitedByGm = (
  userTypes: UserType[],
): UserTypesInvitedByGm => ({
  multiTenantUserTypeId:
    userTypes.find(type => type.name === USER_TYPES.MULTI_TENANT_MANAGER) ||
    null,
  multiTenantReadOnlyUserTypeId:
    userTypes.find(type => type.name === USER_TYPES.MULTI_TENANT_VIEWER) ||
    null,
  healthProfessionalTypeId:
    userTypes.find(type => type.name === USER_TYPES.HEALTH_PROFESSIONAL) ||
    null,
  healthProfessionalReadOnlyTypeId:
    userTypes.find(type => type.name === USER_TYPES.TENANT_VIEWER) || null,
  tenantAdminTypeId:
    userTypes.find(type => type.name === USER_TYPES.TENANT_ADMIN) || null,
});

const mapStateToProps = (state: RootState) => ({
  selectedTenantId: selectors.getSelectedTenantId(state),
  loggedInGroupManagerId: loggedInUserSelector.getUserTenantId(state),
  modalStatus: selectors.getModalStatus(state),
  apiStatus: selectors.getStatus(state),
  userTypesInvitedByGm: getUserTypesInvitedByGm(
    userSelectors.getUserTypes(state),
  ),
});

const mapActionCreators = {
  inviteUserByTenantId: actions.gmSettingsInviteUser,
  inviteUser: userActions.inviteUser,
  setModalStatus: actions.setModalStatus,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
