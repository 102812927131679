import { CollatorOptions } from 'intl';

export const sortWithEmptyString = (
  str1: string | undefined,
  str2: string | undefined,
  option?: CollatorOptions | undefined,
): number => {
  if (!str1) {
    return 1;
  }

  if (!str2) {
    return -1;
  }

  return option
    ? str1.localeCompare(str2, 'en', option)
    : str1.localeCompare(str2);
};

export const sortWithEmptyNumbers = (
  nr1: number | undefined,
  nr2: number | undefined,
): number => {
  return (nr1 || 0) - (nr2 || 0);
};

export const sortRoomsAndBedsWithEmptyString = (
  roomName1: string | undefined,
  bedname1: string | undefined,
  roomName2: string | undefined,
  bedname2: string | undefined,
  option?: CollatorOptions | undefined,
): number => {
  const concatRoomAndBed1 = (roomName1 || '') + ' ' + (bedname1 || '');
  const concatRoomAndBed2 = (roomName2 || '') + ' ' + (bedname2 || '');

  if (!roomName1 && !bedname1) {
    return 1;
  }

  if (!roomName2 && !bedname2) {
    return -1;
  }

  return option
    ? concatRoomAndBed1.localeCompare(concatRoomAndBed2, 'en', option)
    : concatRoomAndBed1.localeCompare(concatRoomAndBed2);
};
