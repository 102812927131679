import React from 'react';

import { SerialNumber, UUID } from 'src/types/utility';
import PatientAssigner from '../PatientAssigner';
import PatientSettings from '../PatientSettings';
import PatientStatusComponent from 'src/components/PatientStatusComponent';
import { DeviceAlerts } from 'src/routes/NursesStation/modules/types';
import { deepEqual } from 'src/utils/comparators';

type Props = {
  hasAssignedPatient: boolean;
  patientId: UUID;
  deviceId: SerialNumber;
  isDeviceConnected: boolean;
  isUnoccupied: boolean;
  ongoingDeviceAlerts: DeviceAlerts;
  hasVacancyNotificationEnabled: boolean;
  isLongOutOfBedAlertActive: boolean;
};

const DevicePatient = ({
  hasAssignedPatient,
  patientId,
  deviceId,
  isDeviceConnected,
  isUnoccupied,
  ongoingDeviceAlerts,
  hasVacancyNotificationEnabled,
  isLongOutOfBedAlertActive = false,
}: Props): JSX.Element | null => {
  if (!isDeviceConnected) {
    return null;
  }

  if (hasAssignedPatient) {
    return (
      <PatientSettings patientId={patientId}>
        <PatientStatusComponent
          isUnoccupied={isUnoccupied}
          isDeviceConnected={isDeviceConnected}
          isVacancyAlertActive={hasVacancyNotificationEnabled}
          isLongOutOfBedAlertActive={isLongOutOfBedAlertActive}
          ongoingDeviceAlerts={ongoingDeviceAlerts}
        />
      </PatientSettings>
    );
  }

  return <PatientAssigner deviceId={deviceId} />;
};

export default React.memo(DevicePatient, (oldProps, newProps) =>
  deepEqual(oldProps, newProps),
);
