import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tabs } from 'antd';
import { injectIntl } from 'react-intl';
import {
  CONTINUOUS_TABS_KEYS,
  SPOT_TABS_KEYS,
} from 'src/utils/graphsUtils/graphConstants';
import DatesRange from '../../../../components/datesRange/DatesRange';

const { TabPane } = Tabs;

const Container = styled.div`
  .ant-tabs-content-holder & {
    height: 70vh;
  }
`;

const StyledTabs = styled(Tabs)`
  height: 100%;
  .ant-tabs-content {
    height: 100%;
  }
`;

const HistoryInnerTabs = ({
  intl,
  tabs,
  activeTabKey,
  onTabChangeSync,
  filterDataByDates,
  defaultDatesRange,
}) => {
  const onApplyPressed = (fromDateParam, toDateParam) => {
    filterDataByDates(fromDateParam, toDateParam);
  };

  const tabsWithDatesRangePicker = [
    CONTINUOUS_TABS_KEYS.TABLE,
    SPOT_TABS_KEYS.TABLE,
    SPOT_TABS_KEYS.GRAPHS,
  ];
  const operations = tabsWithDatesRangePicker.includes(activeTabKey) && (
    <DatesRange
      onApplyPressed={onApplyPressed}
      defaultDatesRange={defaultDatesRange}
    />
  );

  const items = tabs.map(
    ({ title, key, contentComponent: ContentComponent }) => ({
      key: key,
      label: <span>{title}</span>,
      children: (
        <TabPane>
          <ContentComponent />
        </TabPane>
      ),
    }),
  );

  return (
    <Container>
      <StyledTabs
        type="card"
        tabBarExtraContent={operations}
        activeKey={activeTabKey}
        onChange={key => {
          onTabChangeSync(key);
        }}
        items={items}
      />
    </Container>
  );
};

HistoryInnerTabs.propTypes = {
  intl: PropTypes.object.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      contentComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    }),
  ),
  activeTabKey: PropTypes.string,
  onTabChangeSync: PropTypes.func,
  filterDataByDates: PropTypes.func.isRequired,
  defaultDatesRange: PropTypes.object,
};

HistoryInnerTabs.defaultProps = {
  tabs: [],
  activeTabKey: undefined,
  onTabChangeSync: () => {},
  defaultDatesRange: {
    from: null,
    to: null,
  },
};

export default injectIntl(HistoryInnerTabs);
