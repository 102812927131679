import React from 'react';

import SubtenantSearch from './SubtenantSearch';
import SubtenantStatus from './SubtenantStatus';
import AlertSettings from './AlertSettings';
import { SubtenantSettingsWrapper } from './styled';

const SubtenantSettings = (): JSX.Element => {
  return (
    <SubtenantSettingsWrapper>
      <SubtenantSearch />
      <SubtenantStatus />
      <AlertSettings />
    </SubtenantSettingsWrapper>
  );
};

export default React.memo(SubtenantSettings);
