import { StatisticsMeasurement } from 'src/types/individual-dashboard';
import { API_STATUS } from 'src/utils/api-constants';
import { METRIC_ENUM } from 'src/utils/graphsUtils/graphConstants';
import { DATE_TIME_FORMAT, displayInTimezone } from 'src/utils/timeUtils';
import { GraphState, StatisticsGraphs } from './types';

export const emptyMetricObject: GraphState = {
  data: {
    maxPoints: [],
    avgPoints: [],
    minPoints: [],
    minMaxPoints: [],
  },
  status: API_STATUS.LOADING,
};

export const initializedGraphsObject = Object.freeze({
  [METRIC_ENUM.RR]: { ...emptyMetricObject },
  [METRIC_ENUM.HR]: { ...emptyMetricObject },
  [METRIC_ENUM.IE]: { ...emptyMetricObject },
  [METRIC_ENUM.RA_bin]: { ...emptyMetricObject },
});

export const splitDataPointsListToMultipleListsWithTimezone = (
  dataPointsList: StatisticsMeasurement[],
  timezone: string,
  roundDigits: number,
): StatisticsGraphs => {
  const minPoints = [];
  const avgPoints = [];
  const maxPoints = [];
  const minMaxPoints = [];

  for (const dataPoint of dataPointsList) {
    const convertedTime = displayInTimezone(
      dataPoint?.time,
      timezone,
      DATE_TIME_FORMAT,
    );
    // the check if the value is less than 0, then set 0 in Y, is currently the customer's requirement. It should be deleted once the requirement is changed
    minMaxPoints.push({
      x: convertedTime,
      y: [
        dataPoint?.minimum < 0 ? 0 : Math.round(dataPoint?.minimum),
        dataPoint?.maximum < 0 ? 0 : Math.round(dataPoint?.maximum),
      ],
    });
    maxPoints.push({
      x: convertedTime,
      y: dataPoint?.average < 0 ? 0 : dataPoint?.maximum?.toFixed(roundDigits),
      sessionIds: dataPoint.sessionIds,
    });
    avgPoints.push({
      x: convertedTime,
      y: dataPoint?.average < 0 ? 0 : dataPoint?.average?.toFixed(roundDigits),
      sessionIds: dataPoint.sessionIds,
    });
    minPoints.push({
      x: convertedTime,
      y: dataPoint?.minimum < 0 ? 0 : dataPoint?.minimum?.toFixed(roundDigits),
      sessionIds: dataPoint.sessionIds,
    });
  }

  return { maxPoints, avgPoints, minPoints, minMaxPoints };
};
