import React from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { styled } from 'styled-components';

import { apiStatusEnum } from 'src/utils/constants';
import Modal from 'src/components/Modal';
import Connector, { PropsFromRedux } from './Connector';

const StiledIFrame = styled.iframe`
  position: relative;
  height: 100%;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
`;

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const ConsentModal = ({
  activeConsent,
  loginStatus,
  onConfirmConsent,
  onCancelConsent,
  logout,
  intl,
}: Props): JSX.Element => {
  const isModalVisible = !!(
    loginStatus !== apiStatusEnum.OK &&
    loginStatus !== apiStatusEnum.ERROR &&
    activeConsent
  );
  return (
    <Modal
      isModalVisible={isModalVisible}
      setIsModalVisible={() => null}
      okButtonText={intl.formatMessage(messages.accept)}
      onOk={() => {
        onConfirmConsent(activeConsent?.revisionId || '');
      }}
      onCancel={() => {
        logout();
        onCancelConsent();
      }}
      withCloseButton={false}
      // @ts-ignore Ignored until migration of Modal component to ts
      centered
      bodyStyle={{ height: '500px' }}
      width="1200px"
    >
      <StiledIFrame src={activeConsent?.url} frameBorder="0" />
    </Modal>
  );
};

const messages = defineMessages({
  accept: {
    defaultMessage: 'Accept',
  },
});

export default Connector(injectIntl(ConsentModal));
