import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';

export const STATE_KEY = 'settingsPage';

export const INITIAL_STATE: {
  status: keyof typeof DATA_FETCHING_STATUS;
  modalStatus: MODAL_STATUS;
} = {
  status: DATA_FETCHING_STATUS.LOADING,
  modalStatus: MODAL_STATUS.INITIAL,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setModalStatus(state, action: PayloadAction<MODAL_STATUS>) {
      state.modalStatus = action.payload;
    },
  },
});

const extraActions = {
  onSettingsPageMounted: createAction(`${STATE_KEY}/onSettingsPageMounted`),
};

const getState = (state: RootState) => state[STATE_KEY] || INITIAL_STATE;

export const selectors = {
  getModalStatus: createSelector(getState, state => state.modalStatus),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
