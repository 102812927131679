import React from 'react';
import styled from 'styled-components';

import {
  AverageThresholdMetric,
  BaselineMetric,
  ThresholdPreposition,
} from 'src/types/alerts';
import { PrepositionIcon } from './styled';
import { getArrowIcon } from './utils';
import { isAverageAlert } from 'src/redux/data/alerts/modules/typeGuards';

const NoWrapContainer = styled.div`
  white-space: nowrap;
`;

type Props = {
  children: JSX.Element;
  alertDisplayValue: number | null;
  alertThresholdMetric: AverageThresholdMetric | BaselineMetric | null;
  alertThresholdPreposition: ThresholdPreposition | null;
};

const DisplayValue = ({
  children,
  alertDisplayValue,
  alertThresholdMetric,
  alertThresholdPreposition,
}: Props): JSX.Element => {
  if (
    !alertDisplayValue ||
    !alertThresholdMetric ||
    !alertThresholdPreposition
  ) {
    return <NoWrapContainer>{children}</NoWrapContainer>;
  }

  return (
    <NoWrapContainer>
      <PrepositionIcon
        type={getArrowIcon(alertThresholdMetric, alertThresholdPreposition)}
        small={+isAverageAlert(alertThresholdMetric)}
      />
      {children}
    </NoWrapContainer>
  );
};

export default DisplayValue;
