import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import Connector from './Connector';
import { AlertSoundSettings } from 'src/types/alerts';
import { SoundBtnWrapper, StyledImg } from './styled';
import alertSoundOn from './resources/alert-sound-on.svg';
import alertSoundOff from './resources/alert-sound-off.svg';

type Props = {
  soundStatus: AlertSoundSettings;
  changeAlertSoundStatus: (status: AlertSoundSettings) => void;
};

const SoundSettings = ({
  soundStatus,
  changeAlertSoundStatus,
}: Props): JSX.Element => {
  const isSoundEnabled = soundStatus === AlertSoundSettings.ENABLED;

  return (
    <SoundBtnWrapper
      onClick={() => {
        changeAlertSoundStatus(
          isSoundEnabled
            ? AlertSoundSettings.DISABLED
            : AlertSoundSettings.ENABLED,
        );
      }}
      isActive={!isSoundEnabled}
    >
      <StyledImg
        src={isSoundEnabled ? alertSoundOn : alertSoundOff}
        alt={isSoundEnabled ? 'sound-on-icon' : 'sound-off-icon'}
      />
      {isSoundEnabled ? (
        <FormattedMessage {...messages.soundOn} />
      ) : (
        <FormattedMessage {...messages.soundOff} />
      )}
    </SoundBtnWrapper>
  );
};

const messages = defineMessages({
  soundOn: {
    defaultMessage: 'Sound on',
  },
  soundOff: {
    defaultMessage: 'Sound off',
  },
});

export default Connector(SoundSettings);
