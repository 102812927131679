import { useState } from 'react';

export type SortOrder = 'ASC' | 'DESC' | 'DEFAULT';

export type SortState = {
  key: string;
  order: SortOrder;
};

export type SorterDisplay = {
  key: string;
  label: string;
};

export type Sorter<T> = SorterDisplay & {
  sort: (a: T, b: T) => number;
};

export const useSort = <T>(
  items: T[],
  sorters: Sorter<T>[],
): [T[], SortState, React.Dispatch<React.SetStateAction<SortState>>] => {
  const [sortState, setSortState] = useState<SortState>({
    key: sorters[0]?.key || '',
    order: 'DEFAULT',
  });
  const { key, order } = sortState;

  const sorter = sorters.find(s => s.key === key);

  if (!sorter || order === 'DEFAULT') {
    return [items, sortState, setSortState];
  }

  const sortedItems = items.sort(sorter.sort);

  if (order === 'ASC') {
    return [sortedItems, sortState, setSortState];
  }

  return [[...sortedItems].reverse(), sortState, setSortState];
};
