import { Resource } from 'src/services/resources';

export type LoginResource = Resource & {
  headers?: Record<string, never>;
};

export type LoginResourceCreator = (...args: string[]) => Resource;

const resources = {
  SELF_REGISTRATION: {
    url: 'v1/users/selfRegistration',
    method: 'POST',
    requireAuth: false,
    headers: {},
  },
  LOGIN: {
    url: 'v2/users/login',
    method: 'POST',
    requireAuth: false,
  },
  LOGIN_WITH_TOKEN: {
    url: 'v2/users/token/login',
    method: 'POST',
    requireAuth: false,
    headers: {},
  },
  LOGOUT: {
    url: 'v2/users/logout',
    method: 'POST',
    requireAuth: true,
    headers: {},
  },
  REFRESH_TOKEN: {
    url: 'v2/users/token/refresh',
    method: 'POST',
    requireAuth: false,
    headers: {},
  },
  CHANGE_USER_PASSWORD: {
    url: 'v1/users/current/changePassword',
    method: 'PUT',
    requireAuth: true,
  },
  FORGOT_PASSWORD: {
    url: 'v2/users/self/password/forgot',
    method: 'POST',
    requireAuth: false,
    headers: {},
  },
  LOGIN_BY_PHONE_NUMBER: {
    url: 'v1/users/loginByPhoneNumber',
    method: 'POST',
    requireAuth: false,
    headers: {},
  },
  SELF_REGISTRATION_BY_PHONE_NUMBER: {
    url: 'v1/users/selfRegistrationByPhoneNumber',
    method: 'POST',
    requireAuth: false,
    headers: {},
  },
  GENERATE_LOGIN_CODE: {
    url: 'v1/users/generateLoginCode',
    method: 'POST',
    requireAuth: false,
    headers: {},
  },
  MFA_LOGIN: {
    url: 'v2/users/login/mfa',
    method: 'POST',
    requireAuth: true,
    headers: {},
  },
  MFA_RESEND_CODE: {
    url: 'v2/users/login/mfa/resend',
    method: 'POST',
    requireAuth: true,
    headers: {},
  },
  GET_UI_PERMISSIONS: {
    url: 'v2/users/self/permissions/ui',
    method: 'GET',
    requireAuth: true,
  },
  REFRESH_SUBTENANT_TOKENS: {
    url: 'v2/tenant-group/tokens',
    method: 'GET',
    requireAuth: true,
  },
} satisfies Record<string, LoginResource>;

// Here we place endpoint creators:
const resource_creators = {
  VERIFY_EMAIL: userId => ({
    url: `users/${userId}/verifyEmail`,
    method: 'POST',
    requireAuth: false,
    headers: {},
  }),
  RESET_PASSWORD: userId => ({
    url: `users/changePassword/${userId}`,
    method: 'PUT',
    requireAuth: false,
    headers: {},
  }),
} satisfies Record<string, LoginResourceCreator>;

export { resources, resource_creators };
