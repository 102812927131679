import { createSlice, createSelector, createAction } from '@reduxjs/toolkit';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { DATA_STATE_KEY } from '../../constants';
import {
  convertToDateTimeFormat,
  convertUtcToLocaleDateTime,
} from '../../parser';

export const STATE_KEY = 'managment';

export const INITIAL_STATE = {
  deviceId: null,
  timeRange: null,
  sessionsList: [],
  sessionsStatus: DATA_FETCHING_STATUS.SUCCESS,
};

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    onSelectDevice: (state, action) => {
      state.deviceId = action.payload;
    },
    onSelectTimesTamp: (state, action) => {
      state.timeRange = action.payload;
    },
    onGetSessions: (state, action) => {
      state.sessionsList = action.payload.map(session => ({
        ...session,
        startTime: convertUtcToLocaleDateTime(session.startTime),
        endTime: convertUtcToLocaleDateTime(session.endTime),
      }));
      state.sessionsStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    onApplyDevice: state => {
      state.sessionsStatus = DATA_FETCHING_STATUS.LOADING;
    },
    getSessionsFailed: state => {
      state.sessionsStatus = DATA_FETCHING_STATUS.ERROR;
    },
    setRangeTimeForSession: (state, action) => {
      const { openSession, rangeTime } = action.payload;
      const { fromDate, toDate } = rangeTime;
      const objIndex = state.sessionsList.findIndex(
        obj => obj.sessionId === openSession,
      );
      const fromDateFormatted = convertToDateTimeFormat(fromDate);
      const toDateFormatted = convertToDateTimeFormat(toDate);
      state.sessionsList[objIndex].timesTamp =
        `${fromDateFormatted} - ${toDateFormatted}`;
      state.sessionsList[objIndex].timesTampObject = {
        fromDateFormatted,
        toDateFormatted,
      };
    },
  },
  extraReducers: {},
});
/* eslint-enable no-param-reassign */

const getState = state => state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  getDeviceID: createSelector(getState, state => state.deviceId),
  getTimeRange: createSelector(getState, state => state.timeRange),
  getSessionsList: createSelector(getState, state => state.sessionsList),
  getSessionsStatus: createSelector(getState, state => state.sessionsStatus),
};

const extraActions = {
  onDownloadSession: createAction(`${STATE_KEY}/onDownloadSession`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
