import { delay, call, put, select } from 'typed-redux-saga/macro';

import outputHighEdited from '../resources/resampled_output_high_edited.mp3';
import outputLowEdited from '../resources/resampled_output_low_edited.mp3';
import beepWithoutDelay from '../resources/beepWithoutDelay233MS.mp3';
import hospitalEquipment from '../resources/hospital-equipment-synthesised240MS.mp3';
import hospitalMonitor from '../resources/hospital-monitor237MS.mp3';
import outputLow from '../resources/resampled_output_low.mp3';

import { AlertSoundSeverity } from './types';
import { LOCAL_STORAGE_ITEMS } from 'src/utils/constants';
import {
  actions as alertActions,
  selectors as alertsSelectors,
} from 'src/redux/data/alerts';

const SHORT_BEEP_DELAY = 250;
const LONG_BEEP_DELAY = 750;
const STRONG_ALERT_SOUND_INTERVAL = 3000;

let audio: HTMLAudioElement;

function* playSound() {
  const areAlertsMuted = yield* select(alertsSelectors.getAreAlertsMuted);
  if (areAlertsMuted) {
    return;
  }
  const alertSoundFile = localStorage.getItem(LOCAL_STORAGE_ITEMS.sound);

  switch (alertSoundFile) {
    case '1':
      audio = new Audio(outputHighEdited);
      break;
    case '2':
      audio = new Audio(outputLowEdited);
      break;
    case '3':
      audio = new Audio(beepWithoutDelay);
      break;
    case '4':
      audio = new Audio(hospitalMonitor);
      break;
    case '5':
      audio = new Audio(hospitalEquipment);
      break;
    default:
      audio = new Audio(outputLow);
  }
  return new Promise(resolve => {
    audio.currentTime = 0;
    audio.play().catch(e => {
      // TODO: Add a way to prompt user interaction in order to play the sound
      console.error('Error on playing sound: ', e);
    });
    audio.onended = resolve;
  });
}

// TODO: When needed remove underscore from unused param
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function* playSimpleAlertSound(_repeats: number) {
  const configuredRepetition = localStorage.getItem(
    LOCAL_STORAGE_ITEMS.repetitions,
  );

  // TODO: When needed, remove default repetition constant whivh overrides BE setting request field, `soundRepetition`
  const defaultRepetition = 1;

  const configuredDelay = localStorage.getItem(LOCAL_STORAGE_ITEMS.delay);

  const repeatSound = configuredRepetition
    ? +configuredRepetition
    : defaultRepetition;

  const delaySound = configuredDelay ? +configuredDelay : LONG_BEEP_DELAY;

  for (let i = 0; i < repeatSound; i++) {
    yield* call(playSound);
    yield* delay(delaySound);
  }
  return true;
}

export function* playStrongAlertSound() {
  while (true) {
    for (let i = 0; i < 2; i++) {
      yield* call(playSound);
      yield* delay(SHORT_BEEP_DELAY);
    }

    yield* delay(STRONG_ALERT_SOUND_INTERVAL);
  }
}

export const playAlertSound = (soundSeverity: AlertSoundSeverity) => {
  switch (soundSeverity) {
    case AlertSoundSeverity.STRONG_ALERT:
      return playStrongAlertSound;
    case AlertSoundSeverity.SIMPLE_ALERT:
      return playSimpleAlertSound;
    default:
      throw new Error(`Invalid sound severity: ${soundSeverity}!`);
  }
};

export function* toggleMute() {
  yield* put(alertActions.toggleMuteAlert());
  if (audio) {
    audio.muted = !audio.muted;
  }
}
