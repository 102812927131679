import { createAction } from '@reduxjs/toolkit';
import { UUID } from 'src/types/utility';

export const STATE_KEY = 'data';

const extraActions = {
  onLoadGroupDevices: createAction<{ id: UUID }>(
    `${STATE_KEY}/onLoadGroupDevices`,
  ),
  onLoadGroupTenants: createAction(`${STATE_KEY}/onLoadGroupTenants`),
  onLoadManagment: createAction(`${STATE_KEY}/onLoadManagment`),
  onLoadStatistics: createAction(`${STATE_KEY}/onLoadStatistics`),
  onLoadNursesStationPage: createAction(`${STATE_KEY}/onLoadNursesStationPage`),
  onLoadAlertsModal: createAction(`${STATE_KEY}/onLoadAlertsModal`),
  monitorPageMounted: createAction(`${STATE_KEY}/monitorPageMounted`),
  onLoadSMSEmailAlert: createAction(`${STATE_KEY}/onLoadSMSEmailAlert`),
};
export default extraActions;
