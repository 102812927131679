import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/redux/store';

import { combineStatusFilters, StateFilters } from '../../modules/filterUtils';
import { selectors, extraSelectors } from '../../modules/slice';
import { NurseStationDevice } from '../../modules/types';
import { sortRoomsAndBedsWithEmptyString } from 'src/utils/sorters/utils';
import { selectors as devicesSelectors } from 'src/redux/data/device';
import { selectors as patientsSelectors } from 'src/redux/data/patient';
import { selectors as roomsSelectors } from 'src/redux/data/rooms';
import { selectors as alertsSelectors } from 'src/redux/data/alerts';

const filterDevices = (
  devices: NurseStationDevice[],
  filters: StateFilters,
): NurseStationDevice[] => {
  if (!filters.status.length) {
    return devices;
  }

  const applyStatusFilters = combineStatusFilters(filters.status);

  return devices.filter(device => applyStatusFilters(device));
};

const searchDevices = (
  devices: NurseStationDevice[],
  searchQuery: string,
): NurseStationDevice[] => {
  if (!searchQuery) {
    return devices;
  }

  return devices.filter(device => {
    const { id, patientName, roomName, bedName } = device;

    if (
      patientName &&
      patientName.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      return true;
    }

    const locationName =
      roomName || bedName ? `${roomName || ''} ${bedName || ''}` : '';

    if (
      locationName &&
      locationName.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      return true;
    }

    return id.toLowerCase().includes(searchQuery.toLowerCase());
  });
};

const selectFilteredNSDevices = (state: RootState) => {
  const nsDevices = extraSelectors
    .selectNSDevices(state)
    .sort((a, b) => a.id.localeCompare(b.id));

  const filters = selectors.selectFilters(state);
  const searchQuery = selectors.selectSearchQuery(state);

  return searchDevices(filterDevices(nsDevices, filters), searchQuery);
};

const mapStateToProps = (state: RootState) => ({
  devices: selectFilteredNSDevices(state).sort((a, b) =>
    sortRoomsAndBedsWithEmptyString(
      a.roomName,
      a.bedName,
      b.roomName,
      b.bedName,
      { numeric: true },
    ),
  ),
  isDeviceCardDataLoading:
    devicesSelectors.isDeviceListLoading(state) ||
    patientsSelectors.isPatientListLoading(state) ||
    roomsSelectors.getIsLoading(state) ||
    alertsSelectors.areThresholdsLoading(state) ||
    alertsSelectors.areBaselineThresholdsLoading(state),
});

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
