import React from 'react';

import { SVGProps } from '../types';

const UpRightArrowLongIcon = ({
  width = '13',
  height = '12',
  color = '#252525',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path d="M1 11.25L11.5 0.75" stroke={color} strokeWidth="1.5" />
    <path
      d="M11.5 5.41678L11.5 0.750111L6.83333 0.750112"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);

export default UpRightArrowLongIcon;
