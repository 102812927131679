import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import * as R from 'fp-ts/lib/Record';
import * as Task from 'fp-ts/lib/Task';
import qs from 'qs';

import UmsLogic from 'src/libs/ums-js-logic';
import AppConfig from 'src/config/AppConfig';
import { DateUTC, UUID } from 'src/types/utility';
import { SubtenantTokens } from 'src/types/users';
import {
  Alert,
  AlertThreshold,
  BaselineAlertThreshold,
} from 'src/types/alerts';
import { Resource, resourceCreators, resources } from './resources';
import {
  CreateAlertThresholdResponse,
  CreateBaselineAlertThresholdResponse,
  FetchAlertLogsDataResponse,
  FetchAlertThresholdsResponse,
  FetchBaselineAlertThresholdsResponse,
  FetchDevicesLatestSessionsResponse,
  FetchDevicesResponse,
  FetchDisconnectionOverviewResponse,
  FetchInvitedUsersResponse,
  FetchPatientsLatestSessionsResponse,
  FetchSubscribersResponse,
  FetchUsersResponse,
  UpdateAlertThresholdResponse,
  UpdateBaselineAlertThresholdResponse,
  UpdateSubscribersListPayload,
  UpdateSubscribersResponse,
} from './types';
import {
  buildKeyGenerator,
  CacheRequestConfig,
  setupCache,
} from 'axios-cache-interceptor';
import { getBuildGeneratorKey, getHeaderInterpreter } from 'src/services/utils';
import { AxiosCacheInstance } from 'axios-cache-interceptor/src/cache/axios';

export const extractResponseData = <T>(
  multiTenantResponse: Record<string, AxiosResponse<T>>,
): Record<string, T> =>
  R.map((singleTenantResponse: AxiosResponse<T>) => singleTenantResponse.data)(
    multiTenantResponse,
  );

type MultiTenantResponse<T> = Promise<Record<UUID, AxiosResponse<T>>>;
type ResourceConfig = Partial<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paramsSerializer?: (params: any) => string;
}>;
class MultiTenantService {
  _axios: AxiosCacheInstance;
  _axiosInstance: AxiosInstance;

  constructor() {
    this._axiosInstance = Axios.create({
      baseURL: AppConfig.API_URL,
    });
    this._axios = setupCache(this._axiosInstance, {
      // debug: console.log,
      headerInterpreter: getHeaderInterpreter,
      generateKey: buildKeyGenerator(
        ({
          baseURL = '',
          url = '',
          method = 'get',
          params,
          data,
          headers,
        }: CacheRequestConfig) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          getBuildGeneratorKey({ baseURL, url, method, params, data, headers }),
      ),
    });
  }

  buildMultiTenantRequest = <T>(
    resourceCreator: (subtenantId: UUID) => Resource & ResourceConfig,
    subtenantIds?: string[],
    extraConfig?: CacheRequestConfig,
  ): MultiTenantResponse<T> => {
    const { tokens, tenantIds } = UmsLogic.getSubtenantTokens();

    const requestTenantIds =
      subtenantIds && subtenantIds.length ? subtenantIds : tenantIds;

    const requestTokens = R.filterWithIndex((tenantId: UUID) =>
      requestTenantIds.includes(tenantId),
    )(tokens);

    if (R.isEmpty(requestTokens)) {
      console.log(
        'Warning: No available request tokens for Multi Tenant request. No API call will be made...',
      );
    }

    const subtenantTasks = R.mapWithIndex(
      (subtenantId: UUID, tokens: SubtenantTokens) => () =>
        this._axios.request<T>({
          ...resourceCreator(subtenantId),
          ...extraConfig,
          headers: {
            Authorization: `Bearer ${tokens.accessJwt.token}`,
          },
        }),
    )(requestTokens);

    const multiTenantTask = R.sequence(Task.ApplicativePar)(subtenantTasks);

    return multiTenantTask();
  };

  fetchMultiTenantUsers = (subtenantIds?: UUID[]) =>
    this.buildMultiTenantRequest<FetchUsersResponse>(
      () => resources.getAllUsers,
      subtenantIds,
    );

  fetchMultiTenantInvitedUsers = (subtenantIds?: UUID[]) =>
    this.buildMultiTenantRequest<FetchInvitedUsersResponse>(
      () => resources.getInvitedUsers,
      subtenantIds,
    );

  fetchMultitenantDevices = (subtenantIds?: UUID[]) =>
    this.buildMultiTenantRequest<FetchDevicesResponse>(
      () => resources.getDevices,
      subtenantIds,
    );

  fetchMultitenantDeviceLatestsSessions = (subtenantIds?: UUID[]) =>
    this.buildMultiTenantRequest<FetchDevicesLatestSessionsResponse>(
      () => resourceCreators.getAllLatestSessions(),
      subtenantIds,
    );

  fetchMultitenantAlertThresholds = (subtenantIds?: UUID[]) =>
    this.buildMultiTenantRequest<FetchAlertThresholdsResponse>(
      () => resources.getVSAlertThresholds,
      subtenantIds,
    );
  fetchMultitenantActivityAlertSettings = (
    subtenantIds?: UUID[],
    extraConfig?: CacheRequestConfig,
  ) =>
    this.buildMultiTenantRequest<FetchAlertThresholdsResponse>(
      () => resources.fetchActivityAlertSettings,
      subtenantIds,
      extraConfig,
    );

  fetchMultitenantBaselineAlertThresholds = (subtenantIds?: UUID[]) =>
    this.buildMultiTenantRequest<FetchBaselineAlertThresholdsResponse>(
      () => resources.getBaselineAlertThresholds,
      subtenantIds,
    );

  fetchMultitenantSubscribersList = (subtenantIds?: UUID[]) =>
    this.buildMultiTenantRequest<FetchSubscribersResponse>(
      () => resources.getSubscribersList,
      subtenantIds,
    );

  fetchMultitenantPatientLatestSessions = (
    patientIds: UUID[],
    subtenantIds?: UUID[],
  ) =>
    this.buildMultiTenantRequest<FetchPatientsLatestSessionsResponse>(
      () =>
        resourceCreators.getPatientsLatestSessions(
          patientIds.length ? patientIds.join(',') : '',
        ),
      subtenantIds,
    );

  updateMultitenantSubscribersList = (
    subtenantIds: UUID[],
    payload: UpdateSubscribersListPayload,
  ) =>
    this.buildMultiTenantRequest<UpdateSubscribersResponse>(
      () => resources.updateSubscribersList,
      subtenantIds,
      {
        data: payload,
      },
    );

  updateMultitenantActivityAlertSettings = (
    subtenantIds: UUID[],
    payload: UpdateSubscribersListPayload,
  ) =>
    this.buildMultiTenantRequest<any>(
      () => resources.updateActivityAlertSettings,
      subtenantIds,
      {
        data: payload,
        cache: {
          update: {
            // Internally calls the storage.remove('tenant-activity-alert-settings') and lets the
            // next request be forwarded to the server without you having to
            // do any checks.
            'tenant-activity-alert-settings': 'delete',
          },
        },
      },
    );

  createMultitenantAlertThreshold = (
    subtenantIds: UUID[],
    payload: Pick<
      AlertThreshold,
      'enable' | 'metric' | 'preposition' | 'value'
    >,
  ) =>
    this.buildMultiTenantRequest<CreateAlertThresholdResponse>(
      () => resources.createAlertThreshold,
      subtenantIds,
      {
        data: payload,
      },
    );

  updateMultitenantAlertThreshold = (
    subtenantIdThresholdIdDict: Record<UUID, UUID>,
    payload: Pick<AlertThreshold, 'value' | 'enable'>,
  ) =>
    this.buildMultiTenantRequest<UpdateAlertThresholdResponse>(
      subtenantId =>
        resourceCreators.updateAlertThreshold(
          subtenantIdThresholdIdDict[subtenantId] || '',
        ),
      Object.keys(subtenantIdThresholdIdDict),
      {
        data: payload,
      },
    );

  createMultitenantBaselineAlertThreshold = (
    subtenantIds: UUID[],
    payload: Pick<
      BaselineAlertThreshold,
      | 'enable'
      | 'metric'
      | 'baselineDaysInterval'
      | 'deviationHoursInterval'
      | 'deviationPercentage'
    >,
  ) =>
    this.buildMultiTenantRequest<CreateBaselineAlertThresholdResponse>(
      () => resources.createBaselineAlertThreshold,
      subtenantIds,
      {
        data: payload,
      },
    );

  updateMultitenantBaselineAlertThreshold = (
    subtenantIdThresholdIdDict: Record<UUID, UUID>,
    payload: Partial<
      Pick<
        BaselineAlertThreshold,
        | 'enable'
        | 'baselineDaysInterval'
        | 'deviationHoursInterval'
        | 'deviationPercentage'
      >
    >,
  ) =>
    this.buildMultiTenantRequest<UpdateBaselineAlertThresholdResponse>(
      subtenantId =>
        resourceCreators.updateBaselineAlertThreshold(
          subtenantIdThresholdIdDict[subtenantId] || '',
        ),
      Object.keys(subtenantIdThresholdIdDict),
      {
        data: payload,
      },
    );
  suppressMultitenantAlerts = (subtenantIdAlertIdDict: Record<UUID, UUID>) =>
    this.buildMultiTenantRequest<Alert>(
      subtenantId =>
        resourceCreators.suppressAlertType(
          subtenantIdAlertIdDict[subtenantId] || '',
        ),
      Object.keys(subtenantIdAlertIdDict),
    );
  unSuppressMultitenantAlerts = (subtenantIdAlertIdDict: Record<UUID, UUID>) =>
    this.buildMultiTenantRequest<Alert>(
      subtenantId =>
        resourceCreators.unSuppressAlertType(
          subtenantIdAlertIdDict[subtenantId] || '',
        ),
      Object.keys(subtenantIdAlertIdDict),
    );
  clearMultitenantPatientAlerts = (
    subtenantIdAlertIdDict: Record<UUID, UUID>,
  ) =>
    this.buildMultiTenantRequest<Alert>(
      subtenantId =>
        resourceCreators.clearAllPatientAlerts(
          subtenantIdAlertIdDict[subtenantId] || '',
        ),
      Object.keys(subtenantIdAlertIdDict),
    );
  fetchMultitenantAlertLog = (
    startDateTime: DateUTC,
    endDateTime: DateUTC,
    subtenantIds?: UUID[],
    patientIds: UUID[] = [],
    page: string | number = 1,
    limit: string | number = 20,
  ) =>
    this.buildMultiTenantRequest<FetchAlertLogsDataResponse>(
      () => resources.getMultiPatientAlertLogData,
      subtenantIds,
      {
        params: { patientIds, startDateTime, endDateTime, page, limit },
        paramsSerializer: params => qs.stringify(params, { indices: false }),
      },
    );

  fetchMultitenantDisconnectionOverview = (
    continuousDisconnectionSeconds: number,
    subtenantIds?: UUID[],
    page: string | number = 1,
    limit: string | number = 20,
  ) =>
    this.buildMultiTenantRequest<FetchDisconnectionOverviewResponse>(
      () => resources.getDisconnectionOverview,
      subtenantIds,
      {
        params: { continuousDisconnectionSeconds, page, limit },
      },
    );

  inviteUserByTenantId = (formData: unknown, subtenantIds: UUID[]) => {
    return this.buildMultiTenantRequest<unknown>(
      () => resources.inviteUser,
      subtenantIds,
      {
        data: formData,
      },
    );
  };

  resendInvitationForSubtenantUsers = (
    subtenantIdInvitationIdDict: Record<UUID, UUID>,
  ) => {
    return this.buildMultiTenantRequest(
      subtenantId =>
        resourceCreators.resendInvitation(
          subtenantIdInvitationIdDict[subtenantId] || '',
        ),
      Object.keys(subtenantIdInvitationIdDict),
    );
  };

  updateUserByTenantId = (
    formData: unknown,
    userId: UUID,
    subtenantIds: UUID[],
  ) => {
    return this.buildMultiTenantRequest<unknown>(
      () => resourceCreators.updateUser(userId),
      subtenantIds,
      {
        data: formData,
      },
    );
  };

  deleteUserForSubtenant = (
    subtenantIdUserIdDict: Record<string, string | undefined>,
  ) =>
    this.buildMultiTenantRequest(
      subtenantId =>
        resourceCreators.deleteUser(subtenantIdUserIdDict[subtenantId] || ''),
      Object.keys(subtenantIdUserIdDict),
    );

  deleteInvitationForSubtenant = (
    subtenantIdInvitationIdDict: Record<string, string | undefined>,
  ) =>
    this.buildMultiTenantRequest(
      subtenantId =>
        resourceCreators.deleteInvitation(
          subtenantIdInvitationIdDict[subtenantId] || '',
        ),
      Object.keys(subtenantIdInvitationIdDict),
    );
}

export default new MultiTenantService();
