import { sortWithEmptyString } from 'src/utils/sorters/utils';
import { findFromCityStateProvince } from 'city-timezones';
import {
  getAllCountries,
  getCountry,
  getTimezone,
} from 'countries-and-timezones';

export const countriesListMap = Object.values(getAllCountries())
  //* Filters out 'Antarctica' since 'city-timezones' does not provide a timezone value for its cities
  .filter(c => c.id !== 'AQ')
  .sort((a, b) => sortWithEmptyString(a.name, b.name));

export const getCountryName = countryCode => getCountry(countryCode)?.name;

export const cityLookup = country =>
  findFromCityStateProvince(country)
    .sort((a, b) => sortWithEmptyString(a.city, b.city))
    .map(city => ({
      id: `${city.city}_${city.pop}`,
      name: `${city.city}, ${city.province || ''}`,
      ...city,
    }));

export const getTimezoneUtcOffsetStr = timezone =>
  getTimezone(timezone).utcOffsetStr;

export const getTimezoneDstOffsetStr = timezone =>
  getTimezone(timezone).dstOffsetStr;
