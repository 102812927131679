import React, { useEffect, useState } from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { MainTitle, PageWrapper } from 'src/components/styled';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import Connector from './Connector';
import Table from 'src/components/CrudCommonComponents/Table';
import { PageHeader } from './styled';
import VersionModal from './components/VersionModal';
import {
  getVersionsActions,
  getVersionsTableColumns,
  ACTION_KEYS,
} from './modules/utils';
import LatestVersionsPanel from './components/LatestVersionsPanel';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';

const Versions = ({
  intl,
  onLoadVersionList,
  onLoadLatestVersions,
  versionList,
  isLoading,
  latestVersions,
  isLoadingLatestVersion,
  onUpdateVersion,
  onUploadVersion,
  modalStatus,
  setModalStatus,
  theme,
  deleteVersion,
  versionName,
  timezone,
}) => {
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmation, setConfirmationModal] = useState({
    visible: false,
    message: null,
    action: () => {
      return;
    },
  });

  const columns = getVersionsTableColumns(intl, messages, theme, timezone);

  const actions = getVersionsActions(
    intl,
    messages,
    onUpdateVersion,
    deleteVersion,
    setConfirmationModal,
  );

  const getFilteredActionsKeys = versionData => {
    const validationActions = versionData.valid
      ? ACTION_KEYS.INVALIDATE
      : ACTION_KEYS.VALIDATE;
    const deleteAndLatestActions = !versionData.latest
      ? [ACTION_KEYS.DELETE, ACTION_KEYS.SET_AS_LATEST]
      : [];
    return [validationActions, ...deleteAndLatestActions];
  };

  useEffect(() => {
    onLoadVersionList();
    onLoadLatestVersions();
  }, [onLoadVersionList, onLoadLatestVersions]);

  useEffect(() => {
    if (!isLoading) {
      setTableData(
        versionList?.map(versionData => ({
          key: versionData.id,
          status: versionData.valid
            ? intl.formatMessage(messages.valid)
            : intl.formatMessage(messages.invalid),
          actions: getFilteredActionsKeys(versionData),
          ...versionData,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionList, onLoadVersionList, isLoading]);

  return (
    <PageWrapper>
      <PageHeader>
        <MainTitle>
          <FormattedMessage {...messages.versionsTitle} />
        </MainTitle>
        <LatestVersionsPanel
          latestVersions={latestVersions}
          isLoadingLatestVersion={isLoadingLatestVersion}
        />
      </PageHeader>

      <Table
        loading={isLoading}
        data={tableData}
        columns={columns}
        actions={actions}
        withAddButton
        addButtonOnClick={() => {
          setIsModalVisible(true);
        }}
        addButtonText={intl.formatMessage(messages.upload)}
        rowClassName={record => (record.latest ? 'rowHighlightedGreen' : '')}
      />
      <VersionModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onOk={onUploadVersion}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        versionName={versionName}
      />
      <ConfirmationModal
        isModalVisible={confirmation?.visible}
        setIsModalVisible={value => {
          setConfirmationModal({ visible: value });
        }}
        onOk={() => {
          confirmation.action();
        }}
        message={confirmation.message}
      />
    </PageWrapper>
  );
};

Versions.propTypes = {
  intl: PropTypes.object.isRequired,
  onLoadVersionList: PropTypes.func.isRequired,
  onLoadLatestVersions: PropTypes.func.isRequired,
  versionList: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
  latestVersions: PropTypes.arrayOf(PropTypes.object),
  isLoadingLatestVersion: PropTypes.bool,
  onUploadVersion: PropTypes.func.isRequired,
  onUpdateVersion: PropTypes.func.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  deleteVersion: PropTypes.func.isRequired,
  versionName: PropTypes.string,
  timezone: PropTypes.string.isRequired,
};

Versions.defaultProps = {
  versionList: [],
  versionName: null,
};

const messages = defineMessages({
  versionsTitle: {
    defaultMessage: 'Versions',
  },
  versionColumn: {
    defaultMessage: 'Version',
  },
  uploadDateColumn: {
    defaultMessage: 'Upload Date',
  },
  statusColumn: {
    defaultMessage: 'Status',
  },
  uploadedByColumn: {
    defaultMessage: 'Uploaded By',
  },
  validate: {
    defaultMessage: 'Validate',
  },
  invalidate: {
    defaultMessage: 'Invalidate',
  },
  valid: {
    defaultMessage: 'Valid',
  },
  invalid: {
    defaultMessage: 'Invalid',
  },
  upload: {
    defaultMessage: 'Upload',
  },
  description: {
    defaultMessage: 'Description',
  },
  deviceType: {
    defaultMessage: 'Device Type',
  },
  protocol: {
    defaultMessage: 'Protocol',
  },
  delete: {
    defaultMessage: 'Delete',
  },
  setAsLatest: {
    defaultMessage: 'Set as latest',
  },
  areYouSureSetAsLatest: {
    defaultMessage: 'Are you sure you want to update to the latest version?',
  },
  areYouSureYouWantToDelete: {
    defaultMessage: 'Are you sure you want to delete?',
  },
  areYouSureYouWantToValidate: {
    defaultMessage: 'Are you sure you want to Validate?',
  },
  areYouSureYouWantToInvalidate: {
    defaultMessage: 'Are you sure you want to Invalidate?',
  },
});
export default Connector(withTheme(injectIntl(Versions)));
