import TokenHandler from 'src/libs/biot-js-token-handler';

import BackendService from 'src/services/BackendService';
import { SerialNumber } from 'src/types/utility';
import { DeviceCredentials } from 'src/types/devices';
import { FetchDeviceCredentialsResponse } from 'src/services/types';

class CredentialsManager {
  _credentials: DeviceCredentials;
  _endpoint: string;
  _devicesIds: SerialNumber[];
  _tokenHandler: TokenHandler;
  _onRefreshCredentials: (credentials: DeviceCredentials) => Promise<void>;

  init = async (
    devicesIds: SerialNumber[],
    callBack: (credentials: DeviceCredentials) => Promise<void>,
  ) => {
    this._onRefreshCredentials = callBack;
    this._devicesIds = devicesIds;
    this._tokenHandler = new TokenHandler();
    this._tokenHandler.setConfig({ onRefreshToken: this._refreshCredentials });

    await this._getCredentials(devicesIds);
  };

  _getCredentials = async (deviceIds: SerialNumber[], isRefresh = false) => {
    let response = null;

    try {
      response = await BackendService.deviceCredentials({
        deviceIds,
      });

      this._handleCredentials(response?.data);

      isRefresh &&
        this._onRefreshCredentials &&
        this._onRefreshCredentials(this._credentials);
    } catch (err) {
      console.error(
        'An error occurred while trying to call deviceCredentials API. Error: ',
        err,
      );
    }
    return response;
  };

  _handleCredentials = (credentialsObj: FetchDeviceCredentialsResponse) => {
    this._credentials = credentialsObj?.credentials;
    this._endpoint = credentialsObj?.endpoint;

    this._tokenHandler.setAccessToken({
      token: this._credentials,
      expirationTime: new Date(this._credentials?.expiration),
    });
  };

  _refreshCredentials = async () => {
    await this._getCredentials(this._devicesIds, true);
  };

  refreshCredentialsIfRequired = async () => {
    if (this._credentials) {
      const credentialsExpirationTime =
        this._credentials.expiration &&
        new Date(this._credentials.expiration).getTime();
      const nowTime = Date.now();
      if (credentialsExpirationTime <= nowTime) {
        console.log(
          `Refreshing MQTT credentials. This refresh was caught by the interval check. Expiration time: [${credentialsExpirationTime}], Current time: [${nowTime}]`,
        );
        await this._refreshCredentials();
      }
    }
  };

  get credentials() {
    return this._credentials;
  }

  get endpoint() {
    return this._endpoint;
  }
}

export default new CredentialsManager();
