import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { actions as alertActions } from 'src/redux/data/alerts';
import { actions, selectors } from './modules/slice';
import { AlertSidebarKey } from './modules/tabConfig';

const mapStateToProps = (state: RootState): { activeTab: AlertSidebarKey } => ({
  activeTab: selectors.selectActiveTab(state),
});

const mapActionCreators = {
  changeSidebarTab: actions.changeSidebarTab,
  fetchHistoricalAlerts: alertActions.fetchHistoricalAlerts,
  onMount: actions.alertSidebarMounted,
  onUnmount: actions.alertSidebarUnmounted,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
