import { IntlShape, defineMessages } from 'react-intl';
import { TableColumnConfig } from 'src/types/table';

type DevicesTableItem = {
  key: string;
  manufacturerId: string;
  name: string;
  tenantName: string;
  groupName: string;
};

const messages = defineMessages({
  deviceIdColumn: {
    defaultMessage: 'Device Id',
  },
  nameColumn: {
    defaultMessage: 'Name',
  },
  tenantNameColumn: {
    defaultMessage: 'Tenant Name',
  },
  groupColumn: {
    defaultMessage: 'Group',
  },
});

export const getGroupDevicesColumns = (
  intl: IntlShape,
): TableColumnConfig<DevicesTableItem>[] => [
  {
    title: intl.formatMessage(messages.deviceIdColumn),
    dataIndex: 'manufacturerId',
    key: 'manufacturerId',
    filtered: true,
    //@ts-ignore to check why record is not safe typed
    onFilter: (value: string, record) =>
      record.manufacturerId.toLowerCase().includes(value.toLowerCase()) ||
      (record.name && record.name.toLowerCase().includes(value.toLowerCase())),
  },
  {
    title: intl.formatMessage(messages.nameColumn),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: intl.formatMessage(messages.tenantNameColumn),
    dataIndex: 'tenantName',
    key: 'tenantName',
  },
  {
    title: intl.formatMessage(messages.groupColumn),
    dataIndex: 'groupName',
    key: 'groupName',
  },
];
