import styled from 'styled-components';

export const GraphWrapper = styled.div`
  position: relative;
  height: 100%;
  canvas {
    position: absolute;
    height: 100% !important;
  }
`;

export const YAxisTitlesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: normal;
  font-size: 0.65rem;
  margin-bottom: -0.85rem;
  margin-top: 0.2rem;
`;
