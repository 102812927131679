import styled from 'styled-components';

import Table from 'src/components/CrudCommonComponents/Table';
import FormModal from 'src/components/general-ui/FormModal';

export const StyledModal = styled(FormModal)``;

export const StyledMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  margin-top: 30%;
  text-align: center;
`;

export const StyledTable = styled(Table)`
  margin-top: -1.2rem;
  height: 26.5rem;
  font-weight: 400;

  .ant-table-thead > tr > .ant-table-cell {
    font-weight: 600;
    color: #252525;
  }

  .ant-table-tbody > tr > .ant-table-cell {
    font-weight: 400;
    padding: 0.4rem 0.8rem !important;
  }

  .details-column {
    white-space: normal !important;
  }
`;
