import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { Form } from 'antd';
import Modal from 'src/components/Modal';
import { patientFormModes } from '../modules/constants';
import PatientForm from './PatientForm';

const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    overflow: auto !important;
  }
`;

const PatientModal = ({
  mode,
  isModalVisible,
  setIsModalVisible,
  onSubmit,
  currentPatient,
  intl,
  modalStatus,
  setModalStatus,
}) => {
  const [isOkLoading, setIsOkLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setIsOkLoading(false);
  }, [isModalVisible]);

  const modeToTextMap = {
    [patientFormModes.ADD]: {
      title: intl.formatMessage(messages.addPatient),
      okButtonText: intl.formatMessage(messages.add),
    },
    [patientFormModes.EDIT]: {
      title: intl.formatMessage(messages.editPatient),
      okButtonText: intl.formatMessage(messages.apply),
    },
  };

  return (
    <ModalWrapper
      title={modeToTextMap[mode].title}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      okButtonText={modeToTextMap[mode].okButtonText}
      okButtonProps={{ loading: isOkLoading }}
      onOk={() => {
        form.submit();
      }}
      bodyStyle={{ overflowY: 'scroll', overflowX: 'hidden' }}
      destroyOnClose // in order to reset the form when modal is closed
      onClose={() => {
        form.resetFields();
      }}
      modalStatus={modalStatus}
      setModalStatus={setModalStatus}
    >
      <PatientForm
        form={form}
        patient={currentPatient}
        onSubmit={values => {
          setIsOkLoading(true);
          onSubmit(values);
        }}
        mode={mode}
      />
    </ModalWrapper>
  );
};

const messages = defineMessages({
  addPatient: {
    defaultMessage: 'Add Monitored Person',
  },
  editPatient: {
    defaultMessage: 'Edit Monitored Person',
  },
  add: {
    defaultMessage: 'Add',
  },
  apply: {
    defaultMessage: 'Apply',
  },
});

PatientModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(Object.values(patientFormModes)).isRequired,
  currentPatient: PropTypes.object,
  intl: PropTypes.object.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
};

PatientModal.defaultProps = {
  currentPatient: {},
};

export default injectIntl(PatientModal);
