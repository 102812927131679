import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { UUID } from 'src/types/utility';
import {
  selectors as alertsSelectors,
  selectors as alertSelectors,
} from 'src/redux/data/alerts';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { selectors as subscriberSelectors } from 'src/redux/data/subscribers';
import {
  actions as gmActions,
  selectors as gmSelectors,
} from 'src/redux/data/groupManager';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import {
  computeSettingsType,
  getGMSubtenantSubscriptionDetails,
  getGMUserSubscriptionDetails,
  getSubtenantBaselineThresholds,
  getSubtenantThresholds,
} from 'src/routes/GMDashboard/modules/settingsUtils';
import { ActivityAlertSettingsRoot } from 'src/components/AlertSettingsComponents/ActivityAlerts/types';
import { ErrorState } from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantCard/IndividualSubtenantSettings/types';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { SubtenantItem } from 'src/routes/GMDashboard/modules/types';
import { selectors as tenantSelectors } from 'src/redux/data/tenant';
import { mapBaselineThresholdsArrayToState } from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';
import { mapThresholdsArrayToState } from 'src/components/AlertSettingsComponents/AlertThresholdsForm';

type OwnProps = {
  subtenantId: UUID;
  setOnSave: (onSave: () => void) => void;
  setErrors: React.Dispatch<React.SetStateAction<ErrorState>>;
};

const mapStateToProps = (state: RootState, { subtenantId }: OwnProps) => {
  const gmUserId = loggedInUserSelectors.getUserId(state);
  const allSubscribers = subscriberSelectors.selectSubscribersList(state);
  const subscribersDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.SUBSCRIBERS,
  );
  const gmSubtenantSubscriptionDetails = getGMSubtenantSubscriptionDetails(
    gmUserId ?? '',
    subtenantId,
    allSubscribers,
    subscribersDict,
  );
  const subtenantSubscribers = allSubscribers.filter(
    s => subscribersDict[s.id] === subtenantId,
  );
  const activityAlertSettingsFromServer = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.ACTIVITY_ALERTS_SETTINGS,
  )?.[subtenantId] as unknown as ActivityAlertSettingsRoot;
  const allThresholds = alertSelectors.selectThresholds(state);
  const thresholdsDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.ALERT_THRESHOLDS,
  );

  const allBaselineThresholds = alertSelectors.selectBaselineThresholds(state);
  const baselineThresholdsDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.BASELINE_ALERT_THRESHOLDS,
  );

  const subtenantThresholds = getSubtenantThresholds(
    subtenantId,
    allThresholds,
    thresholdsDict,
  );
  const gmSubscribers = allSubscribers.filter(
    subscriber => !subscribersDict[subscriber.id],
  );

  const subtenantBaselineThresholds = getSubtenantBaselineThresholds(
    subtenantId,
    allBaselineThresholds,
    baselineThresholdsDict,
  );

  const gmThresholds = mapThresholdsArrayToState(
    allThresholds.filter(threshold => !thresholdsDict[threshold.id]),
  );

  const gmBaselineThresholds = mapBaselineThresholdsArrayToState(
    allBaselineThresholds.filter(
      threshold => !baselineThresholdsDict[threshold.id],
    ),
  );
  const gmSubscriptionDetails = getGMUserSubscriptionDetails(
    gmUserId ?? '',
    gmSubscribers,
  );

  const subtenant: SubtenantItem = (subtenant => ({
    label: subtenant?.name ?? '',
    value: subtenant?.id ?? '',
    type: computeSettingsType(
      {
        gm: gmThresholds,
        subtenant: subtenantThresholds,
      },
      {
        gm: gmBaselineThresholds,
        subtenant: subtenantBaselineThresholds,
      },
      {
        gm: gmSubscriptionDetails,
        subtenant: gmSubtenantSubscriptionDetails,
      },
    ),
  }))(tenantSelectors.getTenant(state, subtenantId));

  const isDataLoading =
    alertsSelectors.getThresholdsStatus(state) ===
      DATA_FETCHING_STATUS.LOADING ||
    alertsSelectors.getBaselineThresholdsStatus(state) ===
      DATA_FETCHING_STATUS.LOADING ||
    subscriberSelectors.getSubscribersStatus(state) ===
      DATA_FETCHING_STATUS.LOADING;

  return {
    gmUserId,
    isDataLoading,
    gmSubtenantSubscriptionDetails,
    subtenantSubscribers,
    activityAlertSettingsFromServer,
    subtenantThresholds,
    subtenantBaselineThresholds,
    subtenant,
  };
};

const mapActionCreators = {
  fetchSubtenantData: gmActions.fetchSubtenantData,
  updateSubtenantSubscribersList: gmActions.updateSubtenantSubscribersList,
  updateSubtenantActivityAlertSettings:
    gmActions.updateSubtenantActivityAlertSettings,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type Props = OwnProps & PropsFromRedux;

export default Connector;
