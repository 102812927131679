import React from 'react';

import { SVGProps } from '../types';

const PatientBedWithBellIcon = ({
  width = '42',
  height = '29',
  color = '#252525',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <g clipPath="url(#clip0_86_56220)">
      <path
        d="M9.9 17C12.3812 17 14.4 15.3177 14.4 13.25C14.4 11.1823 12.3812 9.5 9.9 9.5C7.41881 9.5 5.4 11.1823 5.4 13.25C5.4 15.3177 7.41881 17 9.9 17ZM29.7 11H17.1C16.6028 11 16.2 11.3356 16.2 11.75V18.5H3.6V8.75C3.6 8.33562 3.19725 8 2.7 8H0.9C0.40275 8 0 8.33562 0 8.75V25.25C0 25.6644 0.40275 26 0.9 26H2.7C3.19725 26 3.6 25.6644 3.6 25.25V23H32.4V25.25C32.4 25.6644 32.8028 26 33.3 26H35.1C35.5973 26 36 25.6644 36 25.25V16.25C36 13.3503 33.1796 11 29.7 11Z"
        fill={color}
      />
      <path
        d="M31.0357 16.9753C31.204 17.9687 32.0488 18.6895 33.0443 18.6895C34.0401 18.6895 34.8851 17.9687 35.0534 16.9753L35.0637 16.9148H31.0254L31.0357 16.9753Z"
        fill="#63B7C3"
      />
    </g>
    <path
      d="M39.9837 14.0954C39.8043 13.901 39.6214 13.7367 39.4446 13.5777C38.7006 12.909 38.058 12.3315 38.058 9.93363V8.03164C38.058 5.23485 36.2427 2.78076 33.7309 2.16546C33.8316 1.95174 33.8826 1.72712 33.8826 1.49646C33.8826 0.671384 33.2624 0 32.4999 0C31.7376 0 31.1174 0.671384 31.1174 1.49646C31.1174 1.72727 31.1684 1.95186 31.2691 2.16546C28.7572 2.78076 26.942 5.23485 26.942 8.03164V9.93363C26.942 12.3315 26.2994 12.909 25.5555 13.5777C25.3786 13.7367 25.1958 13.901 25.0163 14.0954L25 14.113V16H40V14.113L39.9837 14.0954Z"
      fill="#63B7C3"
    />
    <defs>
      <clipPath id="clip0_86_56220">
        <rect width="36" height="24" fill="white" transform="translate(0 5)" />
      </clipPath>
    </defs>
  </svg>
);

export default PatientBedWithBellIcon;
