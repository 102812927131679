import styled from 'styled-components';

export const StyledBar = styled.div`
  width: 100%;
  height: 100%;
  canvas {
    padding: 0 1rem;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 80%;
`;
