import { connect } from 'react-redux';

import { selectors as patientSelectors } from 'src/redux/data/patient/modules/slice';
import dataActions from 'src/redux/data/dataActions';
import { actions, selectors } from '../modules/slice';
import {
  actions as monitorActions,
  selectors as monitorSelectors,
} from '../../../redux/data/monitor';
import {
  actions as alertActions,
  selectors as alertSelectors,
} from '../../../redux/data/alerts';
import {
  actions as roomActions,
  selectors as roomSelectors,
} from '../../../redux/data/rooms';

import Component from '../components/Component';

const mapStateToProps = state => {
  const selectedDeviceId = selectors.getDevice(state)?.manufacturerId;

  return {
    roomsList: roomSelectors.getRoomList(state),
    patientsList: patientSelectors.getPatientsList(state),
    startModePatientId: monitorSelectors.getPatientId(state, selectedDeviceId),
    selectedDevice: selectors.getDevice(state),
    patientId: monitorSelectors.getPatientId(state, selectedDeviceId),
    isActive: monitorSelectors.getIsActive(state, selectedDeviceId),
    saveRawData: selectors.getIsSaveRawData(state),
    histogramData: selectors.selectHistogramData(state),
    thresholds: alertSelectors.selectThresholds(state),
  };
};

const mapActionCreators = {
  monitorPageMounted: dataActions.monitorPageMounted,
  monitorPageUnmounted: monitorActions.monitorPageUnmounted,
  deviceNameById: actions.deviceNameById,
  getHistogramData: actions.getHistogramData,
  setPatientId: monitorActions.setPatientId,
  toggleRawData: actions.toggleRawData,
  getThresholdsList: alertActions.getThresholdsList,
  getRoomsList: roomActions.onLoadRoomsList,
  fetchPatientAlerts: alertActions.fetchPatientAlerts,
};

export default connect(mapStateToProps, mapActionCreators)(Component);
