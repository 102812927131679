import styled from 'styled-components';
import { media } from 'src/utils/mediaQueryUtils';

export const HrRrHistogramWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 150px;
  border: ${props =>
    `1px solid ${props.theme.colors.individualDashboardHeaderBorder}`};
  border-radius: 8px;
  background-color: ${props =>
    props.theme.colors.measurementCardBackgroundColor};
  height: 38%;
`;

export const GraphTitle = styled.div`
  color: ${props => props.theme.colors.individualDashboardTabsText};
  border-bottom: 1px solid
    ${props => props.theme.colors.individualDashboardHeaderBorder};
  font-weight: 600;
  padding: 0.5rem 2rem;
  font-size: 0.6rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0px 25px;
`;

export const StyledRow = styled.div`
  flex-grow: 1;
  display: flex;
  height: 80%;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  align-self: center;
`;

export const HistogramGraphTitle = styled.div`
  display: block;
  color: ${props => props.theme.colors.individualDashboardLightText};
  background-color: ${props =>
    props.theme.colors.individualDashboardBackground};
  color: ${props => props.theme.colors.individualDashboardTabsText};
  font-weight: 600;
  margin-top: 0.35rem;
  font-size: 0.5rem;
`;

export const BarWrapper = styled.div`
  height: 16vh;
  ${media.smallHeight`
    height: 13vh;
  `}
`;

export const LegendWrapper = styled.div`
  margin-bottom: 15px;
`;

export const LegendItem = styled.span`
  margin-left: 4px;
  margin-right: 17px;
  font-weight: 400;
  line-height: 10px;
  font-size: 0.5rem;
`;
