import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import {
  getFormattedLocale,
  getUrlPathWithoutLocale,
} from '../../../utils/languageUtils';

const RedirectToSupportedLocale = ({ location, match }) => {
  const {
    params: { locale },
    url,
  } = match;
  const { search } = location;

  const pathSuffix = getUrlPathWithoutLocale(locale, url);

  const redirectToLocale = getFormattedLocale(locale);
  return <Redirect to={`/${redirectToLocale}${pathSuffix}${search}`} />;
};

RedirectToSupportedLocale.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      locale: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
};

export default RedirectToSupportedLocale;
