import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { actions } from './modules/slice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = (_state: RootState) => ({});

const mapActionCreators = {
  gmDashboardPageMounted: actions.gmDashboardPageMounted,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
