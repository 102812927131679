import {
  createSlice,
  createAction,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { AlertSidebarKey, CURRENT_ALERTS } from './tabConfig';

export const STATE_KEY = 'alertSidebar';

export const INITIAL_STATE: { activeTab: AlertSidebarKey } = {
  activeTab: CURRENT_ALERTS,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    changeSidebarTab: (state, action: PayloadAction<AlertSidebarKey>) => {
      state.activeTab = action.payload;
    },
  },
});

const extraActions = {
  alertSidebarMounted: createAction(`${STATE_KEY}/alertSidebarMounted`),
  alertSidebarUnmounted: createAction(`${STATE_KEY}/alertSidebarUnmounted`),
};
const getState = (state: RootState) => state[STATE_KEY] || INITIAL_STATE;

export const selectors = {
  selectActiveTab: createSelector(getState, state => state.activeTab),
};

export const actions = { ...slice.actions, ...extraActions };
const { reducer } = slice;
export default reducer;
