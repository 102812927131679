import React from 'react';
import { injectIntl, IntlShape, MessageDescriptor } from 'react-intl';

import TabLink from './TabLink';
import { AlertSidebarHeaderWrapper, AlertSidebarTabWrapper } from './styled';

type TabConfig = {
  order: number;
  label: MessageDescriptor;
  render: () => JSX.Element;
};

interface Props<TKey extends string, TObj extends Record<TKey, TabConfig>> {
  tabKey: TKey;
  tabConfig: TObj;
  onChangeTab: (key: TKey) => void;
  intl: IntlShape;
}

const AlertSidebarHeader = <
  TKey extends string,
  TObj extends Record<string, TabConfig>,
>({
  intl,
  tabKey,
  tabConfig,
  onChangeTab,
}: Props<TKey, TObj>): JSX.Element => {
  return (
    <AlertSidebarHeaderWrapper>
      <AlertSidebarTabWrapper>
        {Object.entries(tabConfig)
          .sort((a, b) => a[1].order - b[1].order)
          .map(([key, { label }]) => (
            <TabLink
              id={key as TKey}
              key={key}
              label={intl.formatMessage(label)}
              isCurrent={key === tabKey}
              //@ts-ignore fix later
              onClick={id => onChangeTab(id)}
            />
          ))}
      </AlertSidebarTabWrapper>
    </AlertSidebarHeaderWrapper>
  );
};

export default injectIntl(AlertSidebarHeader);
