import { Alert, AlertLog, OccupancyAlert } from 'src/types/alerts';
import { convertToTimezone } from 'src/utils/timeUtils';
import dayjs from 'dayjs';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';
import { ALERT_STATUS_ENUM } from 'src/utils/constants';

// update long out of bed triggerValue
export function getAlertLogsUpdated(
  alertLogs: AlertLog[] | OccupancyAlert[] | Alert[],
  timezone: string,
) {
  return alertLogs.map(eachAlert => {
    if (
      eachAlert?.thresholdMetric !== ALERT_METRIC_ENUM.LONG_OUT_OF_BED ||
      !eachAlert.firstMeasurementTime
    ) {
      return { ...eachAlert };
    }
    const correctedFirstMeasurementTime = convertToTimezone(
      eachAlert.firstMeasurementTime ?? '',
      timezone,
    );
    const correctedEndTime =
      eachAlert.status === ALERT_STATUS_ENUM.OFF
        ? convertToTimezone(eachAlert.endTime ?? '', timezone)
        : convertToTimezone(dayjs(), timezone);
    return {
      ...eachAlert,
      triggerValue: correctedEndTime.diff(
        correctedFirstMeasurementTime,
        'hours',
      ),
    };
  });
}
