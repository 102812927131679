import { styled } from 'styled-components';

import AlertThresholdsForm from 'src/components/AlertSettingsComponents/AlertThresholdsForm';

export const StyledWrapper = styled.div``;

export const StyledTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
`;

export const MedicalAlertSettingsWrapper = styled.div`
  margin-bottom: 0.75rem;
`;

export const MedicalAlertSettingsTitle = styled.div`
  font-weight: 700;
  font-size: 0.8rem;
  margin-bottom: 0.15rem;
`;

export const TechnicalAlertSettingsTitle = styled(MedicalAlertSettingsTitle)`
  margin-top: 1.5rem;
`;

export const CheckboxesWrapper = styled.div`
  margin-top: 0.5rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const EmailSubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

export const PersonalDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 0.65rem;

  .ant-switch-checked {
    background-color: #63b7c3;
  }
`;

export const StyledAlertThresholdsForm = styled(AlertThresholdsForm)`
  column-gap: 2rem;
`;
