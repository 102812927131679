import { connect, ConnectedProps } from 'react-redux';

import {
  actions,
  selectors,
} from 'src/routes/IndividualDashboard/modules/dashboard/slice';
import { actions as alertActions } from 'src/redux/data/alerts';
import { RootState } from 'src/redux/store';

const mapStateToProps = (state: RootState) => ({
  patient: selectors.getPatientDetails(state),
  dataPointsCount: selectors.selectDataPointsCount(state),
});

const mapActionCreators = {
  getPatientMetricData: actions.getPatientMetricData,
  getAlertsLogData: actions.getAlertsLogData,
  getThresholdsList: alertActions.getThresholdsList,
};

const Connector = connect(mapStateToProps, mapActionCreators);
export type PropsFromRedux = ConnectedProps<typeof Connector>;

export default Connector;
