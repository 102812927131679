import { AverageThresholdMetric } from 'src/types/alerts';
import {
  VALID_MAX_HR,
  VALID_MAX_RR,
  VALID_MIN_HR,
  VALID_MIN_RR,
} from './constants';

const metricAllowedRanges = {
  HR: {
    min: VALID_MIN_HR,
    max: VALID_MAX_HR,
  },
  RR: {
    min: VALID_MIN_RR,
    max: VALID_MAX_RR,
  },
};

export const validateMetricValue = (
  value: string,
  metric: AverageThresholdMetric,
): boolean => {
  const numberValue = parseFloat(value);

  if (isNaN(numberValue)) {
    return false;
  }

  const { min, max } = metricAllowedRanges[metric];

  if (numberValue < min || max < numberValue) {
    return false;
  }

  return true;
};
