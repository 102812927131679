import React, { useRef } from 'react';
import styled from 'styled-components';

import Connector, { PropsFromRedux } from './Connector';
import DeviceCard from './DeviceCard';
import DeviceSettings from './DeviceSettings';
import { deepEqual } from 'src/utils/comparators';

type Props = PropsFromRedux;

const CardsWrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1rem;
  margin-top: 1.25rem;
`;

const RoomsAndBeds = ({
  devices,
  isDeviceCardDataLoading,
}: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <>
      <DeviceSettings />
      <CardsWrapper
        ref={ref}
        data-cy={`device-cards-data-loading=${isDeviceCardDataLoading.toString()}`}
      >
        {devices.map(device => (
          <DeviceCard key={device.id} {...device} />
        ))}
      </CardsWrapper>
    </>
  );
};

export default Connector(
  React.memo(RoomsAndBeds, (oldProps, newProps) =>
    deepEqual(oldProps, newProps),
  ),
);
