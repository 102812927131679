import { connect, ConnectedProps } from 'react-redux';

import {
  actions as alertActions,
  selectors as alertsSelectors,
  selectors as alertSelectors,
} from 'src/redux/data/alerts';
import { RootState } from 'src/redux/store';
import {
  ALERT_TYPES,
  AllTenantDevicesDisconnectedAlert,
  PatientAttentionItem,
} from 'src/types/alerts';
import dataSelectors from 'src/redux/data/dataSelectors';
import { selectors as patientSelectors } from 'src/redux/data/patient';
import { selectors as tenantSelectors } from 'src/redux/data/tenant';
import { Patient } from 'src/types/patients';
import { DeviceIdWithRoomAndBed } from 'src/types/devices';
import { ListTenant } from 'src/types/tenants';
import {
  AttentionListItem,
  MassDeviceDisconnectionItem,
  PatientListItem,
} from '../modules/types';
import { FetchMTMAttentionListResponse } from 'src/services/types';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';

const processAttentionListItems = (
  mtmAttentionList: FetchMTMAttentionListResponse['data'],
  patients: Patient[],
  deviceRoomAndBedList: DeviceIdWithRoomAndBed[],
  tenants: ListTenant[],
): AttentionListItem[] => {
  const patientAttentionListItems = mtmAttentionList
    .filter(attentionListItem => attentionListItem.type === ALERT_TYPES.MEDICAL)
    .map(attentionListItem => {
      const { roomName, bedName } =
        deviceRoomAndBedList.find(
          item => item.deviceId === attentionListItem.deviceId,
        ) || {};
      const { lastName, firstName } =
        patients.find(patient => patient.id === attentionListItem.patientId) ||
        {};
      const {
        patientId,
        deviceId,
        tenantId,
        notesCount,
        alertsCount,
        timestamp,
      } = attentionListItem as PatientAttentionItem;
      const tenant = tenants.find(t => t.id === tenantId);
      return {
        id: patientId,
        type: 'PATIENT_ALERTS',
        patientId,
        deviceId,
        tenantId,
        tenantName: tenant?.name || '',
        alertCounter: alertsCount,
        deviceRoomName: roomName,
        deviceBedName: bedName,
        patientFirstName: firstName || '--',
        patientLastName: lastName || '--',
        timestamp,
        notes: notesCount || {
          counters: {
            regular: 0,
            important: 0,
          },
        },
      } as PatientListItem;
    });

  const disconnectionAttentionListItems = mtmAttentionList
    .filter(attentionListItem => attentionListItem.type !== ALERT_TYPES.MEDICAL)
    .map(attentionListItem => {
      const { tenantId, id, endTime } =
        attentionListItem as AllTenantDevicesDisconnectedAlert;
      const tenant = tenants.find(t => t.id === tenantId);

      return {
        type: 'MASS_DISCONNECTION',
        id,
        tenantId,
        tenantName: tenant?.name || '',
        alertCounter: 1,
        alert: attentionListItem,
        tenant,
        patientId: null,
        deviceId: null,
        deviceRoomName: undefined,
        deviceBedName: undefined,
        patientFirstName: undefined,
        patientLastName: undefined,
        timestamp: endTime || '',
      } as MassDeviceDisconnectionItem;
    });
  return [...patientAttentionListItems, ...disconnectionAttentionListItems];
};

const mapStateToProps = (state: RootState) => ({
  alertItems: processAttentionListItems(
    alertSelectors.selectMTMAttentionList(state),
    patientSelectors.getPatientsList(state),
    dataSelectors.selectMTMAttentionListDeviceRoomAndBed(state),
    tenantSelectors.getTenantsList(state),
  ),
  metadata: alertSelectors.getAttentionListMetadata(state),
  areNewAttentionListAlertsFromLastSync:
    alertSelectors.getAreNewAttentionListAlertsFromLastSync(state),
  isLoading:
    alertsSelectors.getMtmAttentionListStatus(state) ===
    DATA_FETCHING_STATUS.LOADING,
});

const mapActionCreators = {
  fetchMTMAttentionList: alertActions.fetchMTMAttentionList,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;

export default Connector;
