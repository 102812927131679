import styled from 'styled-components';
import { Button } from 'antd';

const BorderedButton = styled(Button)`
  font-weight: bold;
  font-size: 0.7rem;
  white-space: nowrap;
  background: transparent;
  color: ${props => props.theme.colors.actionButtonBackground};
  border-color: ${props => props.theme.colors.actionButtonBackground};

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled) {
    :hover,
    :active {
      background: ${props => props.theme.colors.actionButtonBackground};
      opacity: 0.9;
      color: ${props => props.theme.colors.coloredButtonTextColor};
      border-color: ${props => props.theme.colors.coloredButtonTextColor};
    }
  }

  :focus {
    opacity: 1;
  }

  :disabled,
  :hover {
    background: ${props => props.theme.colors.buttonDisabledColor};
  }
`;

export default BorderedButton;
