import { StatisticsDateUnitTypes } from 'src/routes/IndividualDashboard/modules/statistics/types';
import { DATE_PICKER_DATE_TIME_MAP, FORMAT_MAP } from './constants';

export const _dateTypeOfTopDatePicker = (dateType: StatisticsDateUnitTypes) =>
  DATE_PICKER_DATE_TIME_MAP[dateType].topDatePicker;

export const _dateTypeOfBottomDatePicker = (
  dateType: StatisticsDateUnitTypes,
) => DATE_PICKER_DATE_TIME_MAP[dateType].bottomDatePicker;

const formatMapHandler = Object.freeze({
  get(formatMapTarget: typeof FORMAT_MAP, dateType: StatisticsDateUnitTypes) {
    // @ts-ignore Fix this on refactor
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return formatMapTarget[dateType] || undefined;
  },
});

export const proxyFormatMap = new Proxy<typeof FORMAT_MAP>(
  FORMAT_MAP,
  formatMapHandler,
);

export const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
