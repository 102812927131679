import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

import { US_FORMAT_WITHOUT_SECONDS } from 'src/utils/timeUtils';
import { getAlertLogDetails } from 'src/utils/messages/alertMessages';
import Connector, { IProps } from './Connector';
import { AlertLogTableItem } from './types';
import { getAlertsLogColumns } from './utils';
import { StyledTable } from './styled';
import { DEFAULT_PAGINATION } from 'src/utils/constants';
import { AlertNotificationStatus } from 'src/routes/NursesStation/components/RoomsAndBeds/DeviceSettings/AlertSettings/NotificationSettings/constants';

type Props = IProps & {
  intl: IntlShape;
};

const AlertLogTable = ({
  subtenantId,
  alertLogWithPatients,
  fetchSubtenantAlertLog,
  alertLogMetadata,
  isLoading,
  intl,
}: Props): JSX.Element => {
  const [tableParams, setTableParams] = useState({
    current: DEFAULT_PAGINATION.PAGE,
    pageSize: 20,
    showSizeChanger: false,
    total: 0,
  });

  useEffect(() => {
    fetchSubtenantAlertLog({
      subtenantId,
      currentPage: tableParams?.current,
      pageSize: tableParams?.pageSize,
    });
  }, [tableParams?.current, tableParams?.pageSize, subtenantId]);

  const paginationChange = (page: number) => {
    setTableParams(prevState => ({
      ...prevState,
      current: page,
    }));
  };

  useEffect(() => {
    setTableParams(prevState => ({
      ...prevState,
      total: alertLogMetadata?.totalResults || 0,
    }));
  }, [alertLogMetadata?.totalResults]);

  const cols = getAlertsLogColumns(intl);
  const alertLogItems: AlertLogTableItem[] = alertLogWithPatients.map(
    alertLog => {
      const alertLogExtraDetails =
        alertLog.status.toUpperCase() ===
        AlertNotificationStatus.SUPPRESSED.toUpperCase()
          ? `<strong> | Suppressed at ${alertLog?.lastModifiedTime}<strong>`
          : '';
      return {
        key: alertLog.id,
        id: alertLog.id,
        tenantId: alertLog.tenantId,
        patientId: alertLog.patientId,
        deviceId: alertLog.deviceId,
        thresholdMetric: alertLog.thresholdMetric,
        thresholdPreposition: alertLog.thresholdPreposition,
        firstMeasurementTime: alertLog.firstMeasurementTime
          ? dayjs(alertLog.firstMeasurementTime).format(
              US_FORMAT_WITHOUT_SECONDS,
            )
          : null,
        patientName: alertLog.patientName,
        deviceLocation: alertLog.deviceLocation,
        roomName: alertLog.roomName,
        bedName: alertLog.bedName,
        startTime: dayjs(alertLog.startTime).format(US_FORMAT_WITHOUT_SECONDS),
        endTime: alertLog.endTime
          ? dayjs(alertLog.endTime).format(US_FORMAT_WITHOUT_SECONDS)
          : '--',
        details: `${getAlertLogDetails(alertLog, intl)} ${alertLogExtraDetails}`,
        status: alertLog.status,
      };
    },
  );

  return (
    <>
      <StyledTable
        loading={isLoading}
        locale={{
          emptyText: intl.formatMessage(messages.noAlerts),
        }}
        pagination={
          tableParams?.total > tableParams.pageSize
            ? {
                ...tableParams,
                onChange: paginationChange,
              }
            : false
        }
        data={alertLogItems}
        columns={cols}
        scroll={{ y: '22rem' }}
      />
    </>
  );
};

const messages = defineMessages({
  noAlerts: {
    defaultMessage: 'No alerts in the last 24 hours.',
  },
});

export default Connector(injectIntl(AlertLogTable));
