import { connect, ConnectedProps } from 'react-redux';
import dayjs from 'dayjs';

import { RootState } from 'src/redux/store';
import { Bed, Room } from 'src/types/rooms';
import dataSelectors from 'src/redux/data/dataSelectors';
import { PatientState } from 'src/types/measurements';
import { selectors as roomsSelectors } from 'src/redux/data/rooms/modules/slice';
import {
  selectors as monitorSelectors,
  actions as monitorActions,
} from 'src/redux/data/monitor';
import {
  selectors as idbSelectors,
  actions as idbActions,
} from '../../modules/dashboard';
import { selectors as alertsSelectors } from 'src/redux/data/alerts';
import { BaselineAlert, VsAlert } from 'src/types/alerts';
import { PatientAlertsByType } from 'src/redux/data/alerts/modules/types';
import { DeviceAlerts } from 'src/routes/NursesStation/modules/types';

const getRoom = (state: RootState): Room | undefined => {
  const roomsList = roomsSelectors.getRoomList(state);
  const deviceId = idbSelectors.getDeviceId(state);

  if (!deviceId) {
    return undefined;
  }

  const room = roomsList.find(room =>
    room.beds?.find(bed => bed.deviceId === deviceId),
  );

  return room;
};

const getBed = (state: RootState, room: Room | undefined): Bed | undefined => {
  const deviceId = idbSelectors.getDeviceId(state);

  if (!deviceId) {
    return undefined;
  }

  const bed = room?.beds?.find(bed => bed.deviceId === deviceId);

  return bed;
};

const isUnoccupied = (state: RootState) => {
  const currentPatientState = monitorSelectors.getCurrentPatientStateValue(
    state,
    idbSelectors.getDeviceId(state),
  );

  return (
    (!currentPatientState && currentPatientState !== 0) ||
    currentPatientState === PatientState.EMPTY
  );
};

const getLatestAlert = (
  a1: VsAlert | BaselineAlert | undefined,
  a2: VsAlert | BaselineAlert | undefined,
): VsAlert | BaselineAlert | undefined => {
  if (!a1) {
    return a2;
  }

  if (!a2) {
    return a1;
  }

  return dayjs(a1.startTime).isAfter(a2.startTime) ? a1 : a2;
};

const selectDeviceAlerts = ({
  HR,
  RR,
  HR_BASELINE,
  RR_BASELINE,
  BED_EXIT,
  LONG_OUT_OF_BED,
  BED_EXIT_FREQUENCY,
}: PatientAlertsByType): DeviceAlerts => ({
  latestHrAlert: getLatestAlert(HR, HR_BASELINE),
  latestRrAlert: getLatestAlert(RR, RR_BASELINE),
  occupancyAlert: BED_EXIT,
  longOutOfBed: LONG_OUT_OF_BED,
  bedExitFrequency: BED_EXIT_FREQUENCY,
});

const mapStateToProps = (state: RootState) => ({
  patient: idbSelectors.getPatientDetails(state),
  patientsList: dataSelectors.selectAssignedDevicePatients(state),
  saveRawData: idbSelectors.getIsSaveRawData(state),
  deviceId: idbSelectors.getDeviceId(state),
  selectedPatientId: idbSelectors.getCurrentPatientId(state),
  room: getRoom(state),
  isUnoccupied: isUnoccupied(state),
  bed: getBed(state, getRoom(state)),
  searchFocusStatus: idbSelectors.getSearchFocusStatus(state),
  isDeviceActive: monitorSelectors.getIsActive(
    state,
    idbSelectors.getDeviceId(state),
  ),
  isDeviceConnected: idbSelectors.getDeviceConnectionStatus(state),
  isStartContinuousLoading: monitorSelectors.getIsStartContinuousLoading(
    state,
    idbSelectors.getDeviceId(state),
  ),
  isStopContinuousLoading: monitorSelectors.getIsStopContinuousLoading(
    state,
    idbSelectors.getDeviceId(state),
  ),
  isVacancyAlertActive: alertsSelectors.selectIsBedExitAlertEnabled(
    state,
    idbSelectors.getCurrentPatientId(state) ?? '',
  ),
  ongoingDeviceAlerts: selectDeviceAlerts(
    alertsSelectors.selectOngoingAlertsByPatientId(
      state,
      idbSelectors.getCurrentPatientId(state) ?? '',
    ),
  ),
  isLongOutOfBedAlertActive: alertsSelectors.selectLongOutOfBedAlertActive(
    state,
    idbSelectors.getCurrentPatientId(state) ?? '',
  ),
});

const mapActionCreators = {
  setSelectedPatientId: idbActions.setSelectedPatientId,
  onClickStartContinuous: monitorActions.onClickStartContinuous,
  onClickStopContinuous: monitorActions.onClickStopContinuous,
  onStartWithNoPatient: idbActions.setSearchFocusStatus,
  clearBedOccupancyGraph: idbActions.clearBedOccupancyGraph,
  getLatestSessions: idbActions.getLatestSessions,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
