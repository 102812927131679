import React from 'react';

import { SVGProps } from './types';

import HrAlertIcon from './alert/HrAlertIcon';
import RrAlertIcon from './alert/RrAlertIcon';
import PatietnOutOfDeviceRange from './alert/PatientOutOfDeviceRange';
import SuppressAlertIcon from './alert/SuppressAlertIcon';
import UnSuppressAlertIcon from './alert/UnSuppressAlertIcon';
import ClearAlertIcon from './alert/ClearAlertIcon';
import CrossIcon from './general/CrossIcon';
import SearchIcon from './general/SearchIcon';
import SortIcon from './general/SortIcon';
import PatientIcon from './general/PatientIcon';
import SettingsIcon from './general/SettingsIcon';
import EmptyPatientIcon from './general/EmptyPatientIcon';
import SoundOnIcon from './general/SoundOnIcon';
import SoundOffIcon from './general/SoundOffIcon';
import WifiCircleIcon from './general/WifiCircleIcon';
import WifiConnectedIcon from './general/WifiConnectedIcon';
import WifiDisconnectedIcon from './general/WifiDisconnectedIcon';
import UpArrowIcon from './arrows/UpArrowIcon';
import UpRightArrowIcon from './arrows/UpRightArrowIcon';
import UpRightArrowLongIcon from './arrows/UpRightArrowLongIcon';
import DownRightArrowIcon from './arrows/DownRightArrowIcon';
import DownRightArrowLongIcon from './arrows/DownRightArrowLongIcon';
import RightArrowIcon from './arrows/RightArrowIcon';
import DropdownArrowIcon from './arrows/DropdownArrowIcon';
import UpTrendArrowIcon from './arrows/UpTrendArrowIcon';
import DownTrendArrowIcon from './arrows/DownTrendArrowIcon';
import NoteIcon from './general/NoteIcon';
import AttentionIcon from './general/AttentionIcon';
import BellIconOutlined from './general/BellIconOutlined';
import BellIconFilled from './general/BellIconFilled';
import NotificationSettingsIcon from './general/NotificationSettingsIcon';
import WifiDisconnectedIconV2 from './general/WifiDisconnectedIconV2';
import WifiDisconnectedAlertIcon from './general/WifiDisconnectedAlertIcon';
import WifiDisconnectedAlertIconWithLine from './general/WifiDisconnectedAlertIconWithLine';
import BedIcon from './general/BedIcon';
import BedExitFrequencyIcon from './general/BedExitFrequencyIcon';
import PatientInBedIcon from './general/PatientInBedIcon';
import PatientBedWithBellIcon from './general/PatientBedWithBellIcon';
import AssignPatientBorderedRound from './general/AssignPatientBorderedRound';
import BedIconWithAlertValue from './general/BedIconWithAlertValue';
import BedIconWithFrequencyValue from './general/BedIconWithFrequencyValue';
import GatheringData from './general/GatheringData';

export type IconType =
  | 'attention-icon'
  | 'hr-alert-icon'
  | 'rr-alert-icon'
  | 'patient-out-of-device-range'
  | 'up-arrow-icon'
  | 'up-right-arrow-icon'
  | 'up-right-arrow-long-icon'
  | 'down-right-arrow-icon'
  | 'down-right-arrow-long-icon'
  | 'right-arrow-icon'
  | 'dropdown-arrow-icon'
  | 'up-trend-arrow-icon'
  | 'down-trend-arrow-icon'
  | 'suppress-alert-icon'
  | 'un-suppress-alert-icon'
  | 'clear-alert-icon'
  | 'cross-icon'
  | 'note-icon'
  | 'settings-icon'
  | 'notification-settings-icon'
  | 'patient-icon'
  | 'empty-patient-icon'
  | 'sound-on-icon'
  | 'sound-off-icon'
  | 'search-icon'
  | 'bell-icon-outlined'
  | 'bell-icon-filled'
  | 'sort-icon'
  | 'wifi-circle-icon'
  | 'wifi-disconnected-icon'
  | 'wifi-disconnected-icon-v2'
  | 'wifi-disconnected-alert-icon'
  | 'wifi-disconnected-alert-icon-with-line'
  | 'bed-icon'
  | 'bed-exit-frequency-icon'
  | 'patient-in-bed-icon'
  | 'patient-bed-with-bell-icon'
  | 'wifi-connected-icon'
  | 'assign-patient-bordered-round'
  | 'bed-icon-with-alert-value'
  | 'bed-icon-with-frequency-value'
  | 'gathering-data-icon'
  | 'wifi-connected-icon';

export interface Props extends SVGProps {
  type: IconType;
}

const SvgComponent = ({ type, ...svgProps }: Props): JSX.Element | null => {
  switch (type) {
    case 'attention-icon':
      return <AttentionIcon {...svgProps} />;
    case 'hr-alert-icon':
      return <HrAlertIcon {...svgProps} />;
    case 'rr-alert-icon':
      return <RrAlertIcon {...svgProps} />;
    case 'patient-out-of-device-range':
      return <PatietnOutOfDeviceRange {...svgProps} />;
    case 'suppress-alert-icon':
      return <SuppressAlertIcon {...svgProps} />;
    case 'un-suppress-alert-icon':
      return <UnSuppressAlertIcon {...svgProps} />;
    case 'clear-alert-icon':
      return <ClearAlertIcon {...svgProps} />;
    case 'up-arrow-icon':
      return <UpArrowIcon {...svgProps} />;
    case 'up-right-arrow-icon':
      return <UpRightArrowIcon {...svgProps} />;
    case 'up-right-arrow-long-icon':
      return <UpRightArrowLongIcon {...svgProps} />;
    case 'down-right-arrow-icon':
      return <DownRightArrowIcon {...svgProps} />;
    case 'down-right-arrow-long-icon':
      return <DownRightArrowLongIcon {...svgProps} />;
    case 'up-trend-arrow-icon':
      return <UpTrendArrowIcon {...svgProps} />;
    case 'down-trend-arrow-icon':
      return <DownTrendArrowIcon {...svgProps} />;
    case 'dropdown-arrow-icon':
      return <DropdownArrowIcon {...svgProps} />;
    case 'right-arrow-icon':
      return <RightArrowIcon {...svgProps} />;
    case 'cross-icon':
      return <CrossIcon {...svgProps} />;
    case 'note-icon':
      return <NoteIcon {...svgProps} />;
    case 'settings-icon':
      return <SettingsIcon {...svgProps} />;
    case 'notification-settings-icon':
      return <NotificationSettingsIcon {...svgProps} />;
    case 'patient-icon':
      return <PatientIcon {...svgProps} />;
    case 'empty-patient-icon':
      return <EmptyPatientIcon {...svgProps} />;
    case 'sound-on-icon':
      return <SoundOnIcon {...svgProps} />;
    case 'sound-off-icon':
      return <SoundOffIcon {...svgProps} />;
    case 'search-icon':
      return <SearchIcon {...svgProps} />;
    case 'bell-icon-outlined':
      return <BellIconOutlined {...svgProps} />;
    case 'bell-icon-filled':
      return <BellIconFilled {...svgProps} />;
    case 'sort-icon':
      return <SortIcon {...svgProps} />;
    case 'wifi-circle-icon':
      return <WifiCircleIcon {...svgProps} />;
    case 'wifi-connected-icon':
      return <WifiConnectedIcon {...svgProps} />;
    case 'wifi-disconnected-icon':
      return <WifiDisconnectedIcon {...svgProps} />;
    case 'wifi-disconnected-icon-v2':
      return <WifiDisconnectedIconV2 {...svgProps} />;
    case 'wifi-disconnected-alert-icon':
      return <WifiDisconnectedAlertIcon {...svgProps} />;
    case 'wifi-disconnected-alert-icon-with-line':
      return <WifiDisconnectedAlertIconWithLine {...svgProps} />;
    case 'bed-icon':
      return <BedIcon {...svgProps} />;
    case 'bed-exit-frequency-icon':
      return <BedExitFrequencyIcon {...svgProps} />;
    case 'gathering-data-icon':
      return <GatheringData {...svgProps} />;
    case 'patient-in-bed-icon':
      return <PatientInBedIcon {...svgProps} />;
    case 'patient-bed-with-bell-icon':
      return <PatientBedWithBellIcon {...svgProps} />;
    case 'assign-patient-bordered-round':
      return <AssignPatientBorderedRound {...svgProps} />;
    case 'bed-icon-with-alert-value':
      return <BedIconWithAlertValue {...svgProps} />;
    case 'bed-icon-with-frequency-value':
      return <BedIconWithFrequencyValue {...svgProps} />;

    default:
      return null;
  }
};

const Icon = (props: Props): JSX.Element | null => {
  return <SvgComponent {...props} />;
};

export default Icon;
