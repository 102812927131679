import { css } from 'styled-components';

export const FlexRowVerticalCenter = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FlexRowVerticalBaseline = css`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const JustifyBetween = css`
  justify-content: space-between;
`;

export const FlexColVerticalCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FontSize10 = css`
  font-size: 0.5rem;
`;

export const FontSize15 = css`
  font-size: 0.7rem;
`;

export const TextCenter = css`
  text-align: center;
`;

export const Ml10 = css`
  margin-left: 0.5rem;
`;

export const Mr40 = css`
  margin-right: 2rem;
`;
