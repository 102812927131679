import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { actions as sessionActions } from 'src/redux/data/sessions';
import { actions, selectors } from './modules/slice';

const mapStateToProps = (state: RootState) => ({
  activeTab: selectors.selectActiveTab(state),
});

const mapActionCreators = {
  nurseStationPageMounted: actions.nurseStationPageMounted,
  changeActiveTab: actions.changeActiveTab,
  fetchPatientsSessions: sessionActions.fetchPatientsLatestSessions,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
