import { defineMessages } from 'react-intl';

import { NurseStationTab } from './types';

const messages = defineMessages({
  roomsAndBeds: {
    defaultMessage: 'Rooms & Beds',
  },
  monitoredPersons: {
    defaultMessage: 'Monitored Persons',
  },
});

export const NURSE_STATION_TABS: NurseStationTab[] = [
  {
    key: 'rooms-and-beds',
    labelMessage: messages.roomsAndBeds,
  },
  {
    key: 'monitored-persons',
    labelMessage: messages.monitoredPersons,
  },
];

export const DEFAULT_TAB: NurseStationTab['key'] = 'rooms-and-beds';
