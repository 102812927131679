import { all, call, put, takeLatest } from 'typed-redux-saga/macro';

import {
  actionSuccessNotification,
  notificationsPlacementTypes,
  notifyUserByActionTypeAndCode,
} from 'src/utils/errorHandling/notifications';
import permissions from 'src/permissions';
import BackendService from 'src/services/BackendService';
import { hasAllPermissions } from 'src/utils/permissions';
import { actions as deviceActions } from 'src/redux/data/device';
import { actions } from './slice';
import { NEW_BED_ID, NEW_ROOM_ID } from './constants';

export function* loadRoomsList() {
  try {
    yield* put(actions.onRoomListLoading());
    const { data } = yield* call(BackendService.getRooms);
    yield* put(actions.onLoadRoomListSuccess(data.rooms));
  } catch (e) {
    yield* put(actions.onLoadRoomListFail());
    // TODO: Handle errors
    // notifyUserByActionTypeAndCode(action.type, e);
  }
}
// eslint-disable-next-line
function* createRoom(action: any) {
  // eslint-disable-next-line
  const { manufacturerId, editDeviceData } = action.payload;
  const { room } = editDeviceData; // eslint-disable-line

  if (!room) {
    return;
  }

  try {
    yield* call(BackendService.createRoom, {
      name: room.label, // eslint-disable-line
      beds: [
        {
          name: editDeviceData.bed.label, // eslint-disable-line
          deviceId: manufacturerId, // eslint-disable-line
        },
      ],
    });
    yield* call(loadRoomsList);
  } catch (error) {
    // eslint-disable-next-line
    notifyUserByActionTypeAndCode(action.type, editDeviceData?.name, error);
  }
}

// eslint-disable-next-line
function* addBed(action: any) {
  // eslint-disable-next-line
  const { editDeviceData } = action.payload;
  // eslint-disable-next-line
  if (!editDeviceData?.bed?.label || !editDeviceData.room.id) {
    return;
  }

  try {
    return yield* call(BackendService.createBedInRoom, {
      roomId: editDeviceData.room.id, // eslint-disable-line
      name: editDeviceData.bed.label, // eslint-disable-line
    });
  } catch (error) {
    // eslint-disable-next-line
    notifyUserByActionTypeAndCode(action.type, editDeviceData?.name, error);
    return;
  }
}

// eslint-disable-next-line
function* editRoom(action: any) {
  if (hasAllPermissions(permissions.VIEW_ROOMS_DEVICE_MODAL)) {
    const { editDeviceData } = action.payload; // eslint-disable-line
    if (editDeviceData.bed === undefined) return; // eslint-disable-line
    // eslint-disable-next-line
    if (editDeviceData.room?.value === NEW_ROOM_ID) {
      yield* call(createRoom, action);
    } else {
      let response;
      // eslint-disable-next-line
      if (editDeviceData.bed?.value === NEW_BED_ID) {
        response = yield* call(addBed, action);
      }
      yield* put(
        actions.onAssignBed({
          room: editDeviceData.room, // eslint-disable-line
          bed: {
            name: response?.data.name || editDeviceData.bed.label, // eslint-disable-line
            deviceId: editDeviceData.manufacturerId, // eslint-disable-line
            id: response?.data.id || editDeviceData.bed?.value, // eslint-disable-line
          },
        }),
      );
    }
  }
}

function* newCreateRoom(action: ReturnType<typeof actions.onCreateRoom>) {
  try {
    yield* call(BackendService.createRoom, action.payload);
    yield* call(loadRoomsList);
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, action.payload.name, error);
  }
}
// TODO check this for cleaning, seems it's not more used
function* newEditRoom(action: ReturnType<typeof actions.onEditRoom>) {
  const updatedRoom = action.payload;

  try {
    yield* call(BackendService.updateRoom, updatedRoom.id, updatedRoom);
    yield* call(loadRoomsList);
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, updatedRoom.name, error);
  }
}

function* newAddBed(action: ReturnType<typeof actions.onCreateBed>) {
  const { room, bed } = action.payload;

  try {
    yield* call(BackendService.createBedInRoom, {
      roomId: room.id,
      name: bed.name,
    });
    yield* call(loadRoomsList);
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, room.name, error);
  }
}

function* assignBed(action: ReturnType<typeof actions.onAssignBed>) {
  const { room, bed } = action.payload;
  if (!bed.id) {
    return;
  }
  try {
    yield* call(BackendService.assignDeviceToBed, {
      bedId: bed.id,
      deviceId: bed.deviceId as string,
    });
    yield* call(loadRoomsList);
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, room.name, error);
  }
}

function* onDeleteRoom(action: ReturnType<typeof actions.onDeleteRoom>) {
  const roomId = action.payload;
  try {
    yield* call(BackendService.deleteRoom, roomId);
    yield* call(loadRoomsList);
    actionSuccessNotification(
      action.type,
      null,
      notificationsPlacementTypes.TOP_RIGHT,
    );
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, roomId, error);
  }
}

export default function* watchRoomActions() {
  yield* all([
    takeLatest(actions.onLoadRoomsList, loadRoomsList),
    takeLatest(actions.onCreateRoom, newCreateRoom),
    takeLatest(actions.onEditRoom, newEditRoom),
    takeLatest(actions.onDeleteRoom, onDeleteRoom),
    takeLatest(actions.onCreateBed, newAddBed),
    takeLatest(actions.onAssignBed, assignBed),
    // @ts-ignore TODO: Fix this after device slice is converted to TS
    takeLatest(deviceActions.editDevice, editRoom),
  ]);
}
