import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
  current,
} from '@reduxjs/toolkit';
import * as R from 'fp-ts/lib/Record';

import { RootState } from 'src/redux/store';
import { UpdateSubscribersListPayload } from 'src/services/types';
import { AlertThreshold, BaselineAlertThreshold } from 'src/types/alerts';
import { UUID } from 'src/types/utility';
import { API_STATUS } from 'src/utils/api-constants';
import { DATA_STATE_KEY } from '../../constants';
import { GM_DATA_KEYS, INVALID_CACHE_ID } from './constants';
import { DataKey, DataTenantDict, MTMFetchDataPayload } from './types';
import { SelectedLevelAlertSettings } from 'src/components/AlertSettingsComponents/ActivityAlerts/types';

export const STATE_KEY = 'group-manager';

type State = {
  [K in DataKey]: {
    tenantDict: DataTenantDict;
    status: API_STATUS;
  };
};

export const INITIAL_STATE: State = Object.values(GM_DATA_KEYS).reduce(
  (acc, dataKey) => ({
    ...acc,
    [dataKey]: {
      tenantDict: {},
      status: API_STATUS.LOADING,
    },
  }),
  {} as State,
);

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    fetchSubtenantDataSuccess: (
      state,
      action: PayloadAction<{ dataKey: DataKey; dict: DataTenantDict }>,
    ) => {
      const { dataKey, dict } = action.payload;

      //* when fetch all is made, cache is invalidated
      state[dataKey].tenantDict = {
        ...dict,
      };
      state[dataKey].status = API_STATUS.OK;
    },
    fetchSubtenantDataFailed: (state, action: PayloadAction<DataKey>) => {
      const dataKey = action.payload;

      state[dataKey].status = API_STATUS.ERROR;
    },
    fetchSingleSubtenantDataSuccess: (
      state,
      action: PayloadAction<{ dataKey: DataKey; dict: DataTenantDict }>,
    ) => {
      const { dataKey, dict } = action.payload;

      //* when fetch all is made cache is invalidated
      state[dataKey].tenantDict = {
        ...dict,
      };
      state[dataKey].status = API_STATUS.OK;
    },
    fetchSingleSubtenantDataFailed: (state, action: PayloadAction<DataKey>) => {
      const dataKey = action.payload;

      state[dataKey].status = API_STATUS.ERROR;
    },
    updateFetchSubtenantDataStatus: (
      state,
      action: PayloadAction<{ dataKey: DataKey; status: API_STATUS }>,
    ) => {
      const { dataKey, status } = action.payload;

      state[dataKey].status = status;
    },
    updateSubtenantDataIncrementallySuccess: (
      state,
      action: PayloadAction<{ dataKey: DataKey; dict: DataTenantDict }>,
    ) => {
      const { dataKey, dict } = action.payload;

      state[dataKey].tenantDict = {
        ...state[dataKey].tenantDict,
        ...dict,
      };
      state[dataKey].status = API_STATUS.OK;
    },
    updateSubtenantDataWithCacheInvalidationSuccess: (
      state,
      action: PayloadAction<{
        dataKey: DataKey;
        subtenantIds: UUID[];
        dict: DataTenantDict;
      }>,
    ) => {
      const { dataKey, subtenantIds, dict } = action.payload;

      const stateWithInvalidatedCache = R.map((subtenantId: UUID) =>
        subtenantIds.includes(subtenantId) ? INVALID_CACHE_ID : subtenantId,
      )(current(state[dataKey].tenantDict));

      state[dataKey].tenantDict = {
        ...stateWithInvalidatedCache,
        ...dict,
      };
      state[dataKey].status = API_STATUS.OK;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      actions.fetchSubtenantData,
      (state, action: PayloadAction<MTMFetchDataPayload>) => {
        const { dataKey } = action.payload;

        state[dataKey].status = API_STATUS.LOADING;
      },
    );
  },
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

const getDataKey = (_state: RootState, dataKey: DataKey) => dataKey;

export const selectors = {
  selectDataStatus: createSelector(
    getState,
    getDataKey,
    (state, dataKey) => state[dataKey].status,
  ),
  selectDataTenantDict: createSelector(
    getState,
    getDataKey,
    (state, dataKey) => state[dataKey].tenantDict,
  ),
};

const extraActions = {
  fetchSubtenantData: createAction<MTMFetchDataPayload>(
    `${STATE_KEY}/fetchSubtenantData`,
  ),
  fetchMultitenantPatients: createAction<UUID[]>(
    `${STATE_KEY}/fetchMultitenantPatients`,
  ),
  fetchMultitenantPatientById: createAction<Record<UUID, UUID>>(
    `${STATE_KEY}/fetchMultitenantPatientById`,
  ),
  createSubtenantAlertThresholds: createAction<{
    subtenantIds: UUID[];
    data: Pick<AlertThreshold, 'enable' | 'metric' | 'preposition' | 'value'>;
  }>(`${STATE_KEY}/createSubtenantAlertThresholds`),
  editSubtenantAlertThresholds: createAction<{
    subtenantIdThresholdIdDict: Record<UUID, UUID>;
    data: Pick<AlertThreshold, 'value' | 'enable'>;
  }>(`${STATE_KEY}/editSubtenantAlertThresholds`),
  createSubtenantBaselineAlertThresholds: createAction<{
    subtenantIds: UUID[];
    data: Pick<
      BaselineAlertThreshold,
      | 'enable'
      | 'metric'
      | 'baselineDaysInterval'
      | 'deviationHoursInterval'
      | 'deviationPercentage'
    >;
  }>(`${STATE_KEY}/createSubtenantBaselineAlertThresholds`),
  editSubtenantBaselineAlertThresholds: createAction<{
    subtenantIdThresholdIdDict: Record<UUID, UUID>;
    data: Partial<
      Pick<
        BaselineAlertThreshold,
        | 'enable'
        | 'baselineDaysInterval'
        | 'deviationHoursInterval'
        | 'deviationPercentage'
      >
    >;
  }>(`${STATE_KEY}/editSubtenantBaselineAlertThresholds`),
  updateSubtenantSubscribersList: createAction<{
    subtenantIds: UUID[];
    data: UpdateSubscribersListPayload;
  }>(`${STATE_KEY}/updateSubtenantSubscribersList`),
  suppressSubtenantAlert: createAction<Record<UUID, UUID>>(
    `${STATE_KEY}/suppressSubtenantAlert`,
  ),
  unSuppressSubtenantAlert: createAction<Record<UUID, UUID>>(
    `${STATE_KEY}/unSuppressSubtenantAlert`,
  ),
  clearSubtenantPatientAlerts: createAction<Record<UUID, UUID>>(
    `${STATE_KEY}/clearSubtenantPatientAlerts`,
  ),
  updateSubtenantActivityAlertSettings: createAction<{
    subtenantIds: UUID[];
    data: SelectedLevelAlertSettings;
  }>(`${STATE_KEY}/updateSubtenantActivityAlertSettings`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
