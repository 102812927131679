import { defineMessages } from 'react-intl';

export const PATIENTS_TABLE_COLUMNS_KEYS = {
  PATIENT_ID: 'PATIENT_ID',
  FULL_NAME: 'FULL_NAME',
  DATE_OF_BIRTH: 'DATE_OF_BIRTH',
};

export const PATIENTS_TABLE_ACTIONS_KEYS = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
};

export const addPatientFormFields = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PATIENT_ID: 'patientId',
  EMAIL: 'email',
  PHONE: 'phone',
  ADDRESS: 'address',
  GENDER: 'gender',
  DATE_OF_BIRTH: 'dateOfBirth',
};

export const genderOptions = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

export const addPatientFormFieldsLabelsMessages = defineMessages({
  firstName: {
    defaultMessage: 'First Name',
  },
  lastName: {
    defaultMessage: 'Last Name',
  },
  id: {
    defaultMessage: 'ID',
  },
  email: {
    defaultMessage: 'Email',
  },
  phone: {
    defaultMessage: 'Phone Number',
  },
  dateOfBirth: {
    defaultMessage: 'Date Of Birth',
  },
  gender: {
    defaultMessage: 'Gender',
  },
  address: {
    defaultMessage: 'Address',
  },
});

export const addPatientFormFieldsPlaceholdersMessages = defineMessages({
  firstName: {
    defaultMessage: 'First Name',
  },
  lastName: {
    defaultMessage: 'Last Name',
  },
  id: {
    defaultMessage: 'ID',
  },
  email: {
    defaultMessage: 'Email',
  },
  phone: {
    defaultMessage: 'Phone Number',
  },
  dateOfBirth: {
    defaultMessage: 'Date Of Birth',
  },
  gender: {
    defaultMessage: 'Select gender',
  },
  address: {
    defaultMessage: 'Address',
  },
});

export const patientFormModes = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

export const patientFormDateFormat = 'YYYY-MM-DD';

export const calculateAge = dateOfBirth => {
  const ageDifMs = Date.now() - dateOfBirth.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

/* eslint-disable no-template-curly-in-string */
export const patientFormValidateMessages = {
  required: '${label} is required!',
  pattern: {
    mismatch: '${label} is not a valid ${label}!',
  },
  whitespace: '${label} must not be empty',
}; /* eslint-enable no-template-curly-in-string */

const patientPageColumnsMessages = defineMessages({
  id: {
    defaultMessage: 'ID',
  },
  fullName: {
    defaultMessage: 'Full Name',
  },
  dateOfBirth: {
    defaultMessage: 'Date Of Birth (Age)',
  },
});

export const getPatientPageColumns = intl => [
  {
    title: intl.formatMessage(patientPageColumnsMessages.id),
    dataIndex: 'patientId',
    key: PATIENTS_TABLE_COLUMNS_KEYS.PATIENT_ID,
    filtered: true,
    onFilter: (value, record) =>
      record.patientId.toLowerCase().includes(value.toLowerCase()) ||
      record.fullName.toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => a.patientId.localeCompare(b.patientId),
  },
  {
    title: intl.formatMessage(patientPageColumnsMessages.fullName),
    dataIndex: 'fullName',
    key: PATIENTS_TABLE_COLUMNS_KEYS.FULL_NAME,
    sorter: (a, b) => a.fullName.localeCompare(b.fullName),
    render: (_, record) => record.fullName,
  },
  {
    title: intl.formatMessage(patientPageColumnsMessages.dateOfBirth),
    dataIndex: 'dateOfBirth',
    key: PATIENTS_TABLE_COLUMNS_KEYS.DATE_OF_BIRTH,
    sorter: (a, b) => new Date(a.dateOfBirth) - new Date(b.dateOfBirth),
    render: (_, record) =>
      record.dateOfBirth &&
      `${record.dateOfBirth} (${calculateAge(new Date(record.dateOfBirth))})`,
  },
];
