import { USER_TYPES } from 'src/utils/constants';
import { DEFAULT_TIMEZONE_OFFSET } from './constants';
import { LoggedInUser } from './types';

export const forceSystemAdminTimezone = (
  loggedInUser: LoggedInUser,
): LoggedInUser => {
  const { tenantDetails, userType } = loggedInUser;
  const { name } = userType;

  if (name !== USER_TYPES.SYSTEM_ADMIN) {
    return loggedInUser;
  }

  return {
    ...loggedInUser,
    tenantDetails: {
      ...tenantDetails,
      timeZoneOffset: DEFAULT_TIMEZONE_OFFSET,
    },
  };
};
