import { actions as measurementSettingsActions } from 'src/redux/data/settings/modules/slice';
import { actions as settingsPageActions } from 'src/routes/Settings/modules/slice';
import { intl, messages } from '../messages';

export const settingsActionsToErrorNotificationMessagesMap = {
  [measurementSettingsActions.onUpdateMeasurementSettings.type]: (
    quality,
    _,
  ) => ({
    message: intl.formatMessage(messages.updateSettingsError),
    description: `${intl.formatMessage(
      messages.updateSettingsError,
    )} ${quality}`,
  }),
};

export const settingsActionsToSuccessNotificationMessagesMap = {
  [measurementSettingsActions.onUpdateMeasurementSettings.type]: (
    groupId,
    _,
  ) => ({
    message: intl.formatMessage(messages.updateSettingsSuccess),
  }),
};
