import React from 'react';
import moment from 'moment';

import { displayInTimezone, DATE_TIME_FORMAT } from 'src/utils/timeUtils';
import LongTableColumn from 'src/components/CrudCommonComponents/LongTableColumn';

const convertNullToEmptyString = string => string ?? '';

const sortByKey = (a, b, field) =>
  convertNullToEmptyString(a[field]).localeCompare(
    convertNullToEmptyString(b[field]),
  );

export const ACTION_KEYS = {
  VALIDATE: 'valid',
  INVALIDATE: 'invalid',
  DELETE: 'delete',
  SET_AS_LATEST: 'setAsLatest',
};

export const getVersionsTableColumns = (intl, messages, theme, timezone) => [
  {
    title: intl.formatMessage(messages.versionColumn),
    dataIndex: 'name',
    key: 'version',
    render: name => <LongTableColumn text={name} />,
    sorter: (a, b) => sortByKey(a, b, 'name'),
  },
  {
    title: intl.formatMessage(messages.description),
    dataIndex: 'description',
    key: 'description',
    render: description => <LongTableColumn text={description} />,
    sorter: (a, b) => sortByKey(a, b, 'description'),
  },
  {
    title: intl.formatMessage(messages.deviceType),
    dataIndex: 'deviceType',
    key: 'deviceType',
  },
  {
    title: intl.formatMessage(messages.protocol),
    dataIndex: 'protocol',
    key: 'protocol',
  },
  {
    title: intl.formatMessage(messages.uploadDateColumn),
    dataIndex: 'creationTime',
    key: 'uploadDate',
    sorter: (a, b) =>
      moment(a.creationTime).unix() - moment(b.creationTime).unix(),
    render: (text, record) => {
      return {
        children: (
          <div>
            {displayInTimezone(record.creationTime, timezone, DATE_TIME_FORMAT)}
          </div>
        ),
      };
    },
  },

  {
    title: intl.formatMessage(messages.uploadedByColumn),
    dataIndex: 'uploaderName',
    key: 'uploadedBy',
    sorter: (a, b) => sortByKey(a, b, 'uploaderName'),
  },
  {
    title: intl.formatMessage(messages.statusColumn),
    dataIndex: 'status',
    key: 'status',
    sorter: (a, b) => sortByKey(a, b, 'status'),
    render: (text, record) => {
      return {
        props: {
          style: {
            color: record.valid
              ? theme.colors.actionValidButtonBackgroud
              : theme.colors.actionInvalidButtonBackgroud,
          },
        },
        children: <div>{text}</div>,
      };
    },
  },
];

export const getVersionsActions = (
  intl,
  messages,
  onUpdateVersion,
  deleteVersion,
  setConfirmationModal,
) => [
  {
    key: ACTION_KEYS.DELETE,
    name: intl.formatMessage(messages.delete),
    onClick: (_, { record }) => {
      setConfirmationModal({
        visible: true,
        message: intl.formatMessage(messages.areYouSureYouWantToDelete),
        action: () => deleteVersion(record),
      });
    },
  },
  {
    key: ACTION_KEYS.VALIDATE,
    name: intl.formatMessage(messages.validate),
    isDisabled: ({ record }) => record.valid,
    onClick: (_, { record }) => {
      setConfirmationModal({
        visible: true,
        message: intl.formatMessage(messages.areYouSureYouWantToValidate),
        action: () =>
          onUpdateVersion({
            versionName: record.name,
            versionId: record.key,
            data: { valid: true },
          }),
      });
    },
  },
  {
    key: ACTION_KEYS.INVALIDATE,
    isDisabled: ({ record }) => !record.valid,
    name: intl.formatMessage(messages.invalidate),
    onClick: (_, { record }) => {
      setConfirmationModal({
        visible: true,
        message: intl.formatMessage(messages.areYouSureYouWantToInvalidate),
        action: () =>
          onUpdateVersion({
            versionName: record.name,
            versionId: record.key,
            data: { valid: false },
          }),
      });
    },
  },
  {
    key: ACTION_KEYS.SET_AS_LATEST,
    name: intl.formatMessage(messages.setAsLatest),
    onClick: (_, { record }) => {
      setConfirmationModal({
        visible: true,
        message: intl.formatMessage(messages.areYouSureSetAsLatest),
        action: () =>
          onUpdateVersion({
            versionName: record.name,
            versionId: record.key,
            data: { latest: true },
          }),
      });
    },
  },
];
