import { connect } from 'react-redux';
import Component from '../components/ContinuousContent';
import { actions, selectors } from '../modules/slice';
import {
  actions as monitorActions,
  selectors as monitorSelectors,
} from '../../../redux/data/monitor';
import { selectors as alertSelectors } from 'src/redux/data/alerts';
import { selectors as measurementSettingsSelectors } from '../../../redux/data/settings';
import { selectors as deviceSelectors } from '../../../redux/data/device';

const mapStateToProps = (state, ownProps) => {
  const selectedDeviceId = selectors.getDevice(state)?.manufacturerId;
  const isStartContinuousLoading = monitorSelectors.getIsStartContinuousLoading(
    state,
    selectedDeviceId,
  );

  return {
    isStartContinuousLoading,
    isActive: monitorSelectors.getIsActive(state, selectedDeviceId),
    isStopContinuousLoading: monitorSelectors.getIsStopContinuousLoading(
      state,
      selectedDeviceId,
    ),
    shouldDisplayHighQualityOnly:
      measurementSettingsSelectors.getShouldDisplayHighQualityOnly(state),
    isDeviceConnected: deviceSelectors.getIsDeviceConnected(
      state,
      selectedDeviceId,
    ),
    isStopButtonDisabled: isStartContinuousLoading,
    spotData: monitorSelectors.getSpotData(state, selectedDeviceId),
    currentHrQuality: monitorSelectors.getCurrentHrQuality(
      state,
      selectedDeviceId,
    ),
    currentHrValue: monitorSelectors.getCurrentHrValue(state, selectedDeviceId),
    currentRrQuality: monitorSelectors.getCurrentRrQuality(
      state,
      selectedDeviceId,
    ),
    currentRrValue: monitorSelectors.getCurrentRrValue(state, selectedDeviceId),
    currentIERatioValue: monitorSelectors.getCurrentIERatioValue(
      state,
      selectedDeviceId,
    ),
    currentRabinValue: monitorSelectors.getCurrentRabinValue(
      state,
      selectedDeviceId,
    ),
    deviceStatus: monitorSelectors.getDeviceStatus(state, selectedDeviceId),
    patientState: monitorSelectors.getCurrentPatientStateValue(
      state,
      selectedDeviceId,
    ),
    isBedExitAlertEnabled: alertSelectors.selectIsBedExitAlertEnabled(
      state,
      ownProps.patientId,
    ),
    isBedExitAlertActive: alertSelectors.selectIsBedExitAlertActive(
      state,
      ownProps.patientId,
    ),
  };
};

const mapActionCreators = {
  onClickStartContinuous: monitorActions.onClickStartContinuous,
  onClickStopContinuous: monitorActions.onClickStopContinuous,
  onStartWithNoPatient: actions.setSearchFocusStatus,
};

export default connect(mapStateToProps, mapActionCreators)(Component);
