import styled from 'styled-components';

import Tooltip from 'src/components/general-ui/Tooltip';
import Icon from 'src/components/general-ui/Icon';

export const SubtenantSettingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;

export const SubtenantSettingItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.3rem 0.6rem;
  font-family: ${props => props.theme.fonts.secondary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.6rem;
  color: #252525;
  text-transform: uppercase;
  cursor: pointer;
`;

export const StyledDropdownIcon = styled(Icon)`
  width: 0.6rem;
  height: 0.4rem;
  margin-left: 0.5rem;
`;

export const TooltipWithoutArrow = styled(Tooltip)`
  .ant-tooltip-arrow {
    display: none;
  }
  div.ant-tooltip-inner {
    font-family: ${props => props.theme.fonts.primary};
    color: #252525;
    padding: 0;

    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.0946402);
    border-radius: 3px;
  }
`;
