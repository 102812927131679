import { connect } from 'react-redux';
import {
  selectors as patientDataSelectors,
  actions as patientDataActions,
} from 'src/redux/data/patient';
import {
  selectors as historyModalSelectors,
  actions as historyModalActions,
} from '../HistoryModal/modules/slice';
import Component from '../components/Component';
import { DATA_FETCHING_STATUS } from '../../../redux/data/constants';

const mapStateToProps = state => ({
  patientsList: patientDataSelectors.getPatientsList(state),
  isLoading:
    patientDataSelectors.getStatus(state) === DATA_FETCHING_STATUS.LOADING,
  isHistoryModalVisible: historyModalSelectors.getIsModalVisible(state),
  modalStatus: patientDataSelectors.getModalStatus(state),
});

const mapActionCreators = {
  ...patientDataActions,
  openHistoryModal: historyModalActions.openModal,
  closeHistoryModal: historyModalActions.closeModal,
};

export default connect(mapStateToProps, mapActionCreators)(Component);
