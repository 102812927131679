import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from 'antd';

export const CustomTooltip = React.forwardRef(
  ({ className, children, ...restProps }, ref) => (
    <Tooltip ref={ref} overlayClassName={className} {...restProps}>
      {children}
    </Tooltip>
  ),
);

CustomTooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  restProps: PropTypes.object,
};

CustomTooltip.defaultProps = {
  className: '',
  restProps: {},
};

export const StyledTooltip = styled(CustomTooltip)`
  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tooltip-inner {
    width: 180px;
  }
`;
