import React from 'react';

import BedOccupancyBox from '../BedOccupancy/_BedOccupancy';
import ContinuousMeasurements from '../ContinuousMeasurements';
import HrRrHistogramBox from '../HrRrHistogram';
import MainGraph from '../MainGraph/_MainGraph';
import {
  DashboardTabWrapper,
  DashboardRightSide,
  DashboardLeftSide,
  EmptyBoxWrapper,
} from './styled';
import Connector, { PropsFromRedux } from './Connector';
import { Result } from 'antd';
import { extractQpPatientId } from 'src/routes/IndividualDashboard/modules/dashboard/utils';
import { intl } from 'src/utils/errorHandling/messages';
import RouterLink from 'src/components/RouterLink';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import { defineMessages, FormattedMessage } from 'react-intl';
import { IndividualDashboardWrapper } from 'src/routes/IndividualDashboard/Component';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';

type Props = PropsFromRedux & { hasPageError?: (error: boolean) => void };

const DashboardTab = ({
  isLoading,
  isDashboardLoading,
  isPatientListLoading,
  patientsList,
  selectedPatientId,
  hasPageError,
}: Props): JSX.Element => {
  if (isLoading || isPatientListLoading) {
    return <LoadingOverlay />;
  }

  const assignedPatient = patientsList?.find(
    patient => patient.id === selectedPatientId,
  );

  if (!extractQpPatientId() || !assignedPatient) {
    hasPageError?.(true);
    return (
      <IndividualDashboardWrapper>
        <Result
          status="404"
          title={intl.formatMessage(messages.patientNotFoundTitle)}
          subTitle={intl.formatMessage(messages.patientNotFoundSubTitle)}
          extra={
            <RouterLink to={`/${appRoutes.NURSES_STATION}`}>
              <FormattedMessage {...messages.goToNursesStation} />
            </RouterLink>
          }
        />
      </IndividualDashboardWrapper>
    );
  }
  hasPageError?.(false);
  return (
    <DashboardTabWrapper
      data-cy={`dashboard-loading=${isDashboardLoading.toString()}`}
    >
      <DashboardLeftSide>
        <ContinuousMeasurements />
        <MainGraph />
      </DashboardLeftSide>
      <DashboardRightSide>
        <EmptyBoxWrapper />
        <BedOccupancyBox />
        <HrRrHistogramBox />
      </DashboardRightSide>
    </DashboardTabWrapper>
  );
};

const messages = defineMessages({
  patientNotFoundTitle: {
    defaultMessage:
      "Unfortunately, there are no records for the patient your're looking for.",
  },
  patientNotFoundSubTitle: {
    defaultMessage:
      'Go back to Nurses Station and double check the information.',
  },
  goToNursesStation: {
    defaultMessage: 'Go to Nurses Station',
  },
});

export default Connector(React.memo(DashboardTab));
