import Icon from 'src/components/general-ui/Icon';
import { styled } from 'styled-components';

export const DeviceMeasurementsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 9rem;
`;

export const BedExitAlertWrapper = styled.div<{
  background: string;
  border: string;
}>`
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 700;
  color: #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.1rem 1rem;
  background: ${props => props.background};
  border: 1px solid ${props => props.border};
  border-radius: 0.2rem;
`;

export const MeasurementBox = styled.div<{
  isLeft?: boolean;
  backgroundColor: string;
  borderColor: string;
}>`
  padding: 0.3rem 0.8rem 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;

  background: ${props => props.backgroundColor};
  border: 1px solid ${props => props.borderColor};
  ${props =>
    props.isLeft
      ? `border-right: 0.5px solid ${props.borderColor};`
      : `border-left: 0.5px solid ${props.borderColor};`};
  border-radius: ${props =>
    props.isLeft ? '0.2rem 0rem 0rem 0.2rem' : '0rem 0.2rem 0.2rem 0rem'};
`;

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledTitleMetric = styled.div<{ color: string }>`
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: ${props => props.color};
  white-space: nowrap;
`;

export const StyledTitleUnit = styled.div<{ color: string }>`
  font-weight: 400;
  font-size: 0.45rem;
  line-height: 0.45rem;
  color: ${props => props.color};
  margin-bottom: 0.4rem;
  white-space: nowrap;
`;

export const StyledValue = styled.div<{
  color: string;
  isGatheringInfo?: boolean;
}>`
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 700;
  font-size: 0.8rem;
  color: ${props => props.color};
  line-height: ${props => (props?.isGatheringInfo ? 0 : `35px`)};
  min-height: 35px;
`;

export const PrepositionIcon = styled(Icon)<{
  small?: number;
}>`
  width: ${props => (props.small ? '0.5rem' : '0.75rem')};
  height: ${props => (props.small ? '0.5rem' : '0.75rem')};
  margin-right: 0.3rem;
  margin-left: ${props => (props.small ? '-0.8rem' : '-1.05rem')};
  margin-bottom: ${props => (props.small ? '0rem' : '-0.1rem')};
`;
