import { defineMessages } from 'react-intl';

export const deviceMessages = defineMessages({
  // error
  privateKeyDownloadError: {
    defaultMessage: 'failed to download private key for device',
  },
  endPointUrlDownloadError: {
    defaultMessage: 'failed to download endPointUrl for device',
  },
  detachDeviceError: {
    defaultMessage: 'Could not detach device',
  },
  assignDeviceError: {
    defaultMessage: 'Could not assign device',
  },
  editDeviceError: {
    defaultMessage: 'Could not edit device',
  },
  deleteDeviceError: {
    defaultMessage: 'Could not delete device',
  },
  createDeviceError: {
    defaultMessage: 'Could not create device',
  },
});
