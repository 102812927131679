import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';
import { MainTitle, WideWrapper, Row } from 'src/components/styled';
import { HeaderLayout } from './styled';
import SelectBox from '../../../components/general-ui/SelectBox';

const Title = styled(MainTitle)`
  font-size: 20px;
  margin-bottom: 0;
  margin-right: 10px;
  width: auto;
  display: flex;
`;

const StyledSelect = styled(WideWrapper)`
  height: 31px;
  align-self: center;
  margin-left: 15px;
`;

const BoldTitleItem = styled.span`
  font-weight: 600;
  color: ${props => props.theme.colors.personalMonitorPatientName};
  display: inline-block;
`;

const TitleItem = styled.span`
  font-weight: 500;
  display: inline-block;
`;

const SpacedItem = styled.span`
  margin-left: 10px;
`;

const SpacingItem = styled.span`
  margin: 0 10px;
`;

const Header = ({
  deviceId,
  deviceName,
  shouldStart,
  getPatients,
  patientsList,
  selectedPatient,
  setSelectedPatient,
  intl,
  searchFocusStatus,
  onStartWithNoPatient,
  room,
  bed,
  patientName,
  isDeviceConnected,
  deviceStatus,
  previouslySelectedPatient,
}) => {
  const searchPatient = useRef(null);

  useEffect(() => {
    searchFocusStatus && searchPatient.current.focus(searchFocusStatus);
  }, [searchFocusStatus]);

  useEffect(getPatients, []);

  const onPatientSelect = patient => {
    setSelectedPatient(patient);
  };

  const onPatientClear = () => {
    setSelectedPatient(null);
  };

  return (
    <>
      <HeaderLayout>
        <Title centerItems={!!patientName}>
          <TitleItem>
            {' '}
            {intl.formatMessage(messages.device)}: {deviceName || deviceId}
          </TitleItem>
          <SpacingItem>|</SpacingItem>
          <TitleItem>
            <span>
              {intl.formatMessage(messages.room)}:{' '}
              {room && patientName
                ? room
                : intl.formatMessage(messages.notAssigned)}
            </span>
            <SpacedItem>
              {intl.formatMessage(messages.bed)}:{' '}
              {bed && patientName
                ? bed
                : intl.formatMessage(messages.notAssigned)}
            </SpacedItem>
          </TitleItem>
          {patientName && <SpacingItem>|</SpacingItem>}
          <TitleItem>
            <BoldTitleItem>{patientName || ''}</BoldTitleItem>
          </TitleItem>
        </Title>
        <Row>
          <StyledSelect>
            <SelectBox
              onSelect={onPatientSelect}
              onClear={onPatientClear}
              disabled={shouldStart}
              selectedOption={selectedPatient}
              ref={searchPatient}
              onBlur={() => onStartWithNoPatient(false)}
              optionsList={patientsList}
              placeholder={intl.formatMessage(messages.search)}
            />
          </StyledSelect>
        </Row>
      </HeaderLayout>
    </>
  );
};

Header.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceName: PropTypes.string,
  intl: PropTypes.object.isRequired,
  shouldStart: PropTypes.bool.isRequired,
  getPatients: PropTypes.func.isRequired,
  patientsList: PropTypes.array,
  startModePatientId: PropTypes.string,
  selectedPatient: PropTypes.string,
  setSelectedPatient: PropTypes.func.isRequired,
  searchFocusStatus: PropTypes.bool.isRequired,
  onStartWithNoPatient: PropTypes.func.isRequired,
  isDeviceConnected: PropTypes.bool,
  deviceStatus: PropTypes.string,
  room: PropTypes.string,
  bed: PropTypes.string,
  patientName: PropTypes.string,
  previouslySelectedPatient: PropTypes.string.isRequired,
};

Header.defaultProps = {
  patientsList: [],
  startModePatientId: null,
  selectedPatient: null,
  deviceName: null,
  deviceStatus: '',
  isDeviceConnected: false,
  roomsList: [],
  room: null,
  bed: null,
  patientName: null,
};

const messages = defineMessages({
  device: {
    defaultMessage: 'Device',
  },
  search: {
    defaultMessage: 'Search monitored person (name or ID)',
  },
  bed: {
    defaultMessage: 'Bed',
  },
  room: {
    defaultMessage: 'Room',
  },
  notAssigned: {
    defaultMessage: 'N/A',
  },
});

export default injectIntl(Header);
