import styled from 'styled-components';

import Tooltip from 'src/components/general-ui/Tooltip';
import Icon from 'src/components/general-ui/Icon';

export const StyledInfo = styled.div`
  font-weight: 400;
  font-size: 0.55rem;
  text-align: center;
  letter-spacing: -0.5px;
  color: #818181;

  border-top: 0.5px solid #979797;
  border-bottom: 0.5px solid #979797;
  padding: 0.6rem 0rem;
`;

export const PatientTooltip = styled(Tooltip)`
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: #d8d8d8;
  }
  div.ant-tooltip-inner {
    font-family: ${props => props.theme.fonts.primary};
    color: #252525;
    padding: 0;
    height: 16rem;
    width: 11rem;

    background: #ffffff;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    border-left: 0.5px solid #dddddd;
    border-right: 0.5px solid #dddddd;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.125109);
    border-radius: 3px;
  }
`;

export const ItemsWrapper = styled.div`
  height: 8rem;
  overflow-y: scroll;
`;

export const StyledItem = styled.div<{
  isSelected: boolean;
  isAssigned: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 400;
  font-size: 0.65rem;
  padding: 0.3rem 0.75rem;
  cursor: pointer;

  &:hover {
    background: #f5f7f9;
  }

  ${props =>
    props.isSelected
      ? `background: #F5F7F9;
        border: 1px solid #DDDDDD;
        font-weight: 500;`
      : ''}

  ${props => (props.isAssigned ? 'color: #D3444F;' : '')}
`;

export const StyledOccupiedText = styled.div`
  background: #ffffff;
  border: 0.7px solid #e3e3e7;
  border-radius: 0.425rem;
  padding: 0.1rem 0.35rem;
  font-weight: 500;
  font-size: 0.45rem;
  text-transform: uppercase;
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  background: #ffffff;
  border: 1px solid #a5a5a5;
  border-radius: 2px;
  margin: 0.6rem 0.5rem;
`;

export const StyledIcon = styled(Icon)`
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.4rem;
`;

export const StyledInput = styled.input`
  background-color: transparent;
  border: none;
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-size: 0.6rem;
  font-weight: 400;
  color: #5a5a5a;
  padding: 0.2rem;
  flex-grow: 1;
  width: 100%;

  :focus-visible {
    outline-color: none;
    outline-style: none;
  }
`;
