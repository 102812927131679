import React, { useCallback, useEffect, useState } from 'react';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import { PropsFromRedux } from 'src/components/Sidebars/AlertSidebarGroupManager/CurrentAlerts/Connector';
import { PaginationProps } from 'antd';
import { PageMetaDataType } from 'src/types/utility';
import { debounce } from 'lodash';

type MTMCurrentAlertsHook = {
  currentPage: number;
  searchQuery: string;
  alertsMetadata: {
    page: PageMetaDataType;
    sort: unknown;
  };
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPaginationChange: PaginationProps['onChange'];
  onRefreshHandler: () => void;
};
export const useGetServerCalls = (
  fetchSubtenantData: PropsFromRedux['fetchSubtenantData'],
  alertItemsMetadata: PropsFromRedux['alertItemsMetadata'],
): MTMCurrentAlertsHook => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [alertsMetadata, setAlertsMetadata] = useState<{
    page: PageMetaDataType;
    sort: unknown;
  }>({ page: { totalResults: 0, page: 0, limit: 0 }, sort: null });
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    fetchSubtenantData({
      dataKey: GM_DATA_KEYS.ALERTS,
      shouldUpdateMetadata: true,
      shouldResetData: true,
    });
  }, []);

  useEffect(() => {
    if (!alertItemsMetadata) {
      return;
    }
    setAlertsMetadata(alertItemsMetadata);
  }, [alertItemsMetadata]);

  const onRefreshHandler = () => {
    setCurrentPage(1);
    setSearchQuery('');
    setTimeout(() => {
      fetchSubtenantData({
        dataKey: GM_DATA_KEYS.ALERTS,
        shouldResetData: true,
        shouldUpdateMetadata: true,
      });
    }, 800);
  };
  const onPaginationChange: PaginationProps['onChange'] = page => {
    setCurrentPage(page);
    fetchSubtenantData({
      dataKey: GM_DATA_KEYS.ALERTS,
      pagination: {
        page,
        limit: alertsMetadata?.page?.limit,
      },
      searchQuery,
      shouldUpdateMetadata: true,
      shouldResetData: true,
    });
  };

  const performSearch = useCallback(
    (searchQuery: string) => {
      setCurrentPage(1);
      fetchSubtenantData({
        dataKey: GM_DATA_KEYS.ALERTS,
        pagination: {
          page: 1,
          limit: alertsMetadata?.page?.limit,
        },
        searchQuery,
        shouldUpdateMetadata: true,
        shouldResetData: true,
      });
    },
    [alertsMetadata?.page?.limit],
  );

  const debouncedSearch = useCallback(debounce(performSearch, 300), [
    alertsMetadata?.page?.limit,
  ]);

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
    if (value.length && value.length < 3) {
      return;
    }
    // @ts-ignore ignore it for now
    debouncedSearch(value);
  };
  return {
    currentPage,
    searchQuery,
    alertsMetadata,
    onSearchChange,
    onPaginationChange,
    onRefreshHandler,
  };
};
