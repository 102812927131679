import React, { useState } from 'react';
import { Steps } from 'antd';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';

import { StepsContent, StepsAction, WizardWrapper } from './style';
import BorderedButton from 'src/components/general-ui/Buttons/BorderedButton';
import { wizardMessages } from '../CustomersTable/constants';
import { getWizardSteps } from './stepsConfig';
import Connector, { PropsFromRedux } from './Connector';
import { MODAL_MODES } from 'src/utils/constants';

export type Props = PropsFromRedux & {
  intl: IntlShape;
  modalMode: string;
};

const { Step } = Steps;

const CustomerStepWizard = ({
  intl,
  modalMode,
  isLoadingForm,
  createCustomer,
  customerForm,
}: Props): JSX.Element => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const steps = getWizardSteps(intl, modalMode);

  const CurrentStepContent: React.FC | undefined = steps[currentStep]?.content;

  const onNext = () => {
    if (modalMode === MODAL_MODES.ADD) {
      createCustomer();
    } else {
      setCurrentStep(currentStep => currentStep + 1);
    }
  };

  const organizationId = customerForm?.emrMappings?.find(
    emr => emr.organizationId,
  );

  const customerName = customerForm.name;

  const disabledNextBtn =
    isLoadingForm ||
    steps.length === currentStep ||
    !organizationId ||
    !customerName;

  return (
    <WizardWrapper>
      <Steps current={currentStep} className="customer-wizard-steps">
        {steps.map(item => (
          <Step key={item.key} title={item.title} />
        ))}
      </Steps>
      <StepsContent>
        {CurrentStepContent && <CurrentStepContent />}
      </StepsContent>
      <StepsAction>
        <BorderedButton
          disabled={currentStep === 0}
          onClick={() => setCurrentStep(currentStep => currentStep - 1)}
        >
          <FormattedMessage {...wizardMessages.previous} />
        </BorderedButton>
        <BorderedButton
          disabled={disabledNextBtn}
          loading={isLoadingForm}
          onClick={onNext}
        >
          <FormattedMessage {...wizardMessages.next} />
        </BorderedButton>
      </StepsAction>
    </WizardWrapper>
  );
};

export default Connector(injectIntl(CustomerStepWizard));
