import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '../styled';
import { ActionButton, Separator } from './styled';
import AccessControl from 'src/components/AccessControl';

const ActionsRow = ({ actions, rowProps }) => {
  const filteredActions = actions.filter(
    action => !(action.isHidden ? action.isHidden(rowProps) : false),
  );
  const actionsDisplayed = rowProps.record?.actions
    ? filteredActions.filter(action =>
        rowProps.record?.actions.includes(action.key),
      )
    : filteredActions;

  return (
    <Row>
      {actionsDisplayed.map((action, index) => (
        <Row key={action.key}>
          <AccessControl
            permissions={action.permissions ? action.permissions(rowProps) : []}
          >
            <ActionButton
              onClick={event => action.onClick(event, rowProps)}
              disabled={action.isDisabled && action.isDisabled(rowProps)}
            >
              {action.name}
            </ActionButton>
          </AccessControl>
          {index < actionsDisplayed.length - 1 && <Separator />}
        </Row>
      ))}
    </Row>
  );
};

ActionsRow.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      onClick: PropTypes.func,
      isDisabled: PropTypes.func,
    }),
  ).isRequired,
  rowProps: PropTypes.object.isRequired,
};

export default ActionsRow;
