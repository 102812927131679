import { styled } from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

import Icon from 'src/components/general-ui/Icon';

export const DeviceLegendWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0.3rem 0.6rem;
  font-family: ${props => props.theme.fonts.secondary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.6rem;
  color: ${props => props.theme.colors.legendText};
  text-transform: uppercase;
  cursor: pointer;
`;

export const LegendModal = styled(Modal)`
  .ant-modal-title {
    font-size: 1.2rem;
  }

  .ant-modal-content {
    padding: 2rem;
    width: 45rem;
  }
`;

export const Subtitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin: 1rem 0 1.2rem 0;
`;

export const StyledInfoIcon = styled(InfoCircleOutlined)`
  width: 0.75rem;
  height: 0.8rem;
  margin-right: 0.2rem;
`;

export const List = styled.div<{
  direction: 'row' | 'column';
  maxHeight?: string;
  wrap?: 'wrap' | 'nowrap';
}>`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: space-between;
  flex-wrap: ${props => props.wrap || 'nowrap'};
  width: 100%;
  max-height: ${props => props.maxHeight || 'fit-content'};
  padding-left: 0;
`;

export const ListItem = styled.div<{ direction: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: ${props => (props.direction === 'column' ? '2rem' : 0)};
  font-size: 0.7rem;
  color: ${props => props.theme.colors.defaultTextColor};
  font-style: normal;
  font-weight: 400;
  line-height: 0.9rem;

  .legend-desc {
    margin-bottom: 0.5rem;
  }
`;

export const IconWithValue = styled.div`
  p {
    margin: -0.2rem 1rem 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 0.6rem;
    color: ${props => props.theme.colors.importantNotes};
    text-align: center;
  }
`;

export const StyledIcon = styled(Icon)`
  min-width: 1rem;
  width: 1.5rem;
  height: 1rem;
  margin-right: 1rem;
`;

export const StyledImg = styled.img`
  height: 4rem;
`;
