import React, { useEffect } from 'react';

import SidebarHeader from '../components/SidebarHeader';
import { ALERT_SIDEBAR_GM_TABS } from './modules/tabConfig';
import Connector, { PropsFromRedux } from './Connector';
import { AlertSidebarWrapper } from '../styled';
import { useDocumentVisibility } from 'src/utils/hooks/useDocumentVisibility';

type Props = PropsFromRedux;

const AlertSidebarGroupManager = ({
  activeTab,
  changeSidebarTab,
  onMount,
  onUnmount,
}: Props) => {
  const isDocumentVisible = useDocumentVisibility();

  useEffect(() => {
    if (!isDocumentVisible) {
      return;
    }
    onMount();
    return () => {
      onUnmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDocumentVisible]);

  return (
    <AlertSidebarWrapper data-cy="alerts-sidebar-gm">
      <SidebarHeader
        tabKey={activeTab}
        tabConfig={ALERT_SIDEBAR_GM_TABS}
        onChangeTab={id => {
          changeSidebarTab(id as typeof activeTab);
        }}
      />
      {ALERT_SIDEBAR_GM_TABS[activeTab].render()}
    </AlertSidebarWrapper>
  );
};

export default Connector(AlertSidebarGroupManager);
