export const graphProperties = {
  MONITOR_DELAY: 1000,
  HR_MIN_VALUE: 0,
  HR_MAX_VALUE: 160,
  RR_MIN_VALUE: 0,
  RR_MAX_VALUE: 40,
  HR_STEP_SIZE: 20,
  RR_STEP_SIZE: 10,
};

export const graphDataTypesEnum = Object.freeze({
  HR_DATA: 'hrData',
  RR_DATA: 'rrData',
});

export const MONITOR_CHECK_STATUS_TIME = 5 * 1000;

export const measurementUnits = {
  BPM: 'BPM', // FIXME: I think this should be moved to intl
  BRPM: 'BRPM',
  MS: 'ms',
  AU: 'au',
};

export const notificationKey = 'indicationModal';
