import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import Modal from './index';

const ConfirmationModal = ({
  isModalVisible,
  setIsModalVisible,
  onOk,
  message,
  intl,
  title,
  onCancel,
}) => (
  <Modal
    title={title || intl.formatMessage(messages.title)}
    isModalVisible={isModalVisible}
    setIsModalVisible={setIsModalVisible}
    okButtonText={intl.formatMessage(messages.yes)}
    cancelText={intl.formatMessage(messages.no)}
    onOk={onOk}
    onCancel={onCancel}
    shouldCloseOnOk
  >
    <span>{message || intl.formatMessage(messages.message)}</span>
  </Modal>
);

const messages = defineMessages({
  yes: {
    defaultMessage: 'Yes',
  },
  no: {
    defaultMessage: 'No',
  },
  title: {
    defaultMessage: 'Confirm',
  },
  message: {
    defaultMessage: 'Are you sure?',
  },
});

ConfirmationModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  intl: PropTypes.object.isRequired,
  message: PropTypes.node,
  title: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  message: null,
  title: null,
  onCancel: () => {},
};

export default injectIntl(ConfirmationModal);
