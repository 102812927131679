import styled from 'styled-components';

export const StyledWrapper = styled.div`
  height: fit-content;
  margin-top: 0.5rem;
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  margin: 0.75rem 0;
`;

export const CheckboxesWrapper = styled.div`
  margin-top: 0.5rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
