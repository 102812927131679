import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import Table from 'src/components/CrudCommonComponents/Table';

const SpotTable = ({ data, isLoading }) => {
  const intl = useIntl();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (!isLoading && data) {
      setTableData(
        data.map((spotMeasurement, index) => ({
          key: `spot-measurements-row-${index}`,
          ...spotMeasurement,
          heartRate: spotMeasurement?.heartRate?.toFixed(),
          respirationRate: spotMeasurement?.respirationRate?.toFixed(),
        })),
      );
    }
  }, [data, isLoading]);

  const columns = [
    {
      title: intl.formatMessage(messages.measurementDate),
      dataIndex: 'startTime',
      key: 'measurementDate',
      sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime),
      defaultSortOrder: 'descend',
    },
    {
      title: intl.formatMessage(messages.heartRate),
      dataIndex: 'heartRate',
      key: 'heartRate',
      sorter: (a, b) => a.heartRate - b.heartRate,
    },
    {
      title: intl.formatMessage(messages.respirationRate),
      dataIndex: 'respirationRate',
      key: 'respirationRate',
      sorter: (a, b) => a.respirationRate - b.respirationRate,
    },
    // {
    //   title: intl.formatMessage(messages.sessionId),
    //   dataIndex: 'sessionId',
    //   key: 'sessionId',
    //   sorter: (a, b) => a.sessionId?.localeCompare(b.sessionId),
    // },
  ];

  return <Table data={tableData} columns={columns} loading={isLoading} />;
};

SpotTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};

SpotTable.defaultProps = {
  data: [],
};

const messages = defineMessages({
  measurementDate: {
    defaultMessage: 'Measurement Date',
  },
  heartRate: {
    defaultMessage: 'Heart Rate',
  },
  respirationRate: {
    defaultMessage: 'Respiration Rate',
  },
  sessionId: {
    defaultMessage: 'Session ID',
  },
});

export default SpotTable;
