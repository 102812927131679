import { connect, ConnectedProps } from 'react-redux';

import {
  selectors as customersSelectors,
  actions as customersActions,
} from 'src/redux/data/customers';
import { RootState } from 'src/redux/store';

const mapStateToProps = (state: RootState) => ({
  customersList: customersSelectors.selectCustomersList(state),
  isLoading: customersSelectors.isLoadingCustomers(state),
  customerForm: customersSelectors.selectEditableData(state),
});

const mapActionCreators = {
  getCustomers: customersActions.getCustomersList,
  createCustomer: customersActions.createCustomer,
  editCustomer: customersActions.editCustomer,
  resetFormAndCurrentCustomer: customersActions.resetFormAndCurrentCustomer,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
