import React from 'react';

import { SVGProps } from '../types';

const BedIconWithAlertValue = ({
  width = '175',
  height = '90',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    {...rest}
  >
    <g>
      <path
        fill="#fefdfd"
        d="M -0.5,-0.5 C 51.5,-0.5 103.5,-0.5 155.5,-0.5C 155.5,36.1667 155.5,72.8333 155.5,109.5C 103.5,109.5 51.5,109.5 -0.5,109.5C -0.5,72.8333 -0.5,36.1667 -0.5,-0.5 Z"
      />
    </g>
    <g>
      <path
        fill="#ba2b1e"
        d="M 47.5,36.5 C 48.4907,30.021 48.824,23.3543 48.5,16.5C 55.8333,16.5 63.1667,16.5 70.5,16.5C 83.4395,15.6159 95.4395,18.4492 106.5,25C 107.787,26.4095 108.954,27.9095 110,29.5C 110.333,25.1667 110.667,20.8333 111,16.5C 112.587,15.273 113.921,15.6063 115,17.5C 115.667,32.1667 115.667,46.8333 115,61.5C 113.644,62.3802 112.144,62.7135 110.5,62.5C 110.5,58.8333 110.5,55.1667 110.5,51.5C 80.1667,51.5 49.8333,51.5 19.5,51.5C 19.5,55.1667 19.5,58.8333 19.5,62.5C 17.8561,62.7135 16.3561,62.3802 15,61.5C 14.3333,41.5 14.3333,21.5 15,1.5C 16.3561,0.619828 17.8561,0.286495 19.5,0.5C 19.5,12.5 19.5,24.5 19.5,36.5C 28.8333,36.5 38.1667,36.5 47.5,36.5 Z"
      />
    </g>
    <g>
      <path
        fill="#ba2b1e"
        d="M 28.5,13.5 C 40.6659,13.185 45.1659,18.8517 42,30.5C 35.9534,36.7284 29.6201,37.0617 23,31.5C 19.0892,23.7329 20.9226,17.7329 28.5,13.5 Z"
      />
    </g>
    <g>
      <path
        fill="#ce6a66"
        d="M 70.5,16.5 C 63.1667,16.5 55.8333,16.5 48.5,16.5C 48.824,23.3543 48.4907,30.021 47.5,36.5C 47.5,29.5 47.5,22.5 47.5,15.5C 55.3516,15.1746 63.0182,15.508 70.5,16.5 Z"
      />
    </g>
    <g>
      <path
        fill="#faeeee"
        d="M 19.5,44.5 C 49.8333,44.5 80.1667,44.5 110.5,44.5C 110.5,45.5 110.5,46.5 110.5,47.5C 80.1667,47.5 49.8333,47.5 19.5,47.5C 19.5,46.5 19.5,45.5 19.5,44.5 Z"
      />
    </g>
    <g>
      <path
        fill="#bf382b"
        d="M 107.5,73.5 C 114.78,72.3869 119.28,75.3869 121,82.5C 122.95,90.4553 121.117,97.2887 115.5,103C 107.273,104.972 102.44,101.805 101,93.5C 99.3067,85.4152 101.473,78.7485 107.5,73.5 Z"
      />
    </g>
    <g>
      <path
        fill="#bf392c"
        d="M 128.5,73.5 C 137.578,73.6873 139.912,77.854 135.5,86C 126.987,87.4715 124.154,83.9715 127,75.5C 127.717,74.9558 128.217,74.2891 128.5,73.5 Z"
      />
    </g>
    <g>
      <path
        fill="#bd3326"
        d="M 34.5,74.5 C 39.3288,77.1634 42.9955,80.9967 45.5,86C 42.5467,91.4052 39.2134,96.5718 35.5,101.5C 34.1667,100.833 33.1667,99.8333 32.5,98.5C 34.375,95.2489 36.375,92.0823 38.5,89C 26.5046,88.5001 14.5046,88.3335 2.5,88.5C 2.5,86.8333 2.5,85.1667 2.5,83.5C 14.1714,83.6665 25.8381,83.4999 37.5,83C 35.2589,81.2609 33.5922,79.0942 32.5,76.5C 33.2427,75.8182 33.9094,75.1515 34.5,74.5 Z"
      />
    </g>
    <g>
      <path
        fill="#c03a2d"
        d="M 80.5,73.5 C 85.5,73.5 90.5,73.5 95.5,73.5C 95.5,74.8333 95.5,76.1667 95.5,77.5C 91.4862,77.3345 87.4862,77.5012 83.5,78C 82.3977,80.4009 82.231,82.9009 83,85.5C 95.2059,83.5117 99.2059,88.5117 95,100.5C 89.3913,104.716 84.0579,104.383 79,99.5C 78.51,98.2068 78.3433,96.8734 78.5,95.5C 81.1721,97.3164 84.0054,98.9831 87,100.5C 93.2387,99.0186 94.9054,95.352 92,89.5C 91.2496,88.8742 90.4162,88.3742 89.5,88C 85.8297,87.7208 82.163,87.8875 78.5,88.5C 78.8783,83.42 79.545,78.42 80.5,73.5 Z"
      />
    </g>
    <g>
      <path
        fill="#c13c30"
        d="M 147.5,73.5 C 149.355,73.272 150.188,73.9387 150,75.5C 144,84.1667 138,92.8333 132,101.5C 130.043,104.019 128.709,104.019 128,101.5C 134.72,92.2867 141.22,82.9534 147.5,73.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fcf7f6"
        d="M 129.5,76.5 C 131.144,76.2865 132.644,76.6198 134,77.5C 134.758,79.7695 134.591,81.9361 133.5,84C 132.216,84.6838 131.049,84.5171 130,83.5C 129.503,81.1902 129.337,78.8568 129.5,76.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fdfafa"
        d="M 108.5,76.5 C 112.683,75.8456 115.516,77.5123 117,81.5C 117.667,86.1667 117.667,90.8333 117,95.5C 114.168,101.201 110.502,101.867 106,97.5C 103.336,89.9281 104.169,82.9281 108.5,76.5 Z"
      />
    </g>
    <g>
      <path
        fill="#df9d9c"
        d="M 61.5,79.5 C 61.5,82.1667 61.5,84.8333 61.5,87.5C 58.8333,87.5 56.1667,87.5 53.5,87.5C 55.6074,86.5318 57.9407,86.1984 60.5,86.5C 60.1984,83.9407 60.5318,81.6074 61.5,79.5 Z"
      />
    </g>
    <g>
      <path
        fill="#bb2b20"
        d="M 61.5,79.5 C 62.5,79.5 63.5,79.5 64.5,79.5C 64.5,82.1667 64.5,84.8333 64.5,87.5C 67.1667,87.5 69.8333,87.5 72.5,87.5C 72.5,88.5 72.5,89.5 72.5,90.5C 69.8333,90.5 67.1667,90.5 64.5,90.5C 64.5,93.1667 64.5,95.8333 64.5,98.5C 63.5,98.5 62.5,98.5 61.5,98.5C 61.5,95.8333 61.5,93.1667 61.5,90.5C 58.8333,90.5 56.1667,90.5 53.5,90.5C 53.5,89.5 53.5,88.5 53.5,87.5C 56.1667,87.5 58.8333,87.5 61.5,87.5C 61.5,84.8333 61.5,82.1667 61.5,79.5 Z"
      />
    </g>
    <g>
      <path
        fill="#be3529"
        d="M 143.5,90.5 C 150.276,89.7764 153.11,92.7764 152,99.5C 148.829,104.414 145.163,104.747 141,100.5C 139.861,96.5789 140.695,93.2456 143.5,90.5 Z"
      />
    </g>
    <g>
      <path
        fill="#f8e7e6"
        d="M 145.5,92.5 C 149.146,93.4615 150.313,95.7949 149,99.5C 145.775,102.313 143.942,101.48 143.5,97C 143.519,95.167 144.185,93.667 145.5,92.5 Z"
      />
    </g>
  </svg>
);

export default BedIconWithAlertValue;
