import React from 'react';

import { SVGProps } from '../types';

const WifiConnecteddIcon = ({
  width = '21',
  height = '20',
  color = '#000',
  viewBox = '512 512',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    height={height}
    width={width}
    version="1.1"
    id="_x32_"
    viewBox={`0 0 ${viewBox}`}
    xmlSpace="preserve"
    className={className}
    {...rest}
  >
    <g>
      <path
        fill={color}
        d="M506.392,169.051c-138.136-138.08-362.658-138.08-500.784,0c-7.477,7.476-7.477,19.589,0,27.066
		c7.476,7.477,19.589,7.477,27.066,0c61.6-61.582,142.37-92.321,223.317-92.34c80.965,0.019,161.735,30.759,223.335,92.34
		c7.468,7.477,19.59,7.477,27.066,0C513.869,188.64,513.869,176.527,506.392,169.051z"
      />
      <path
        fill={color}
        d="M73.284,236.717c-7.487,7.486-7.487,19.589,0,27.076c7.476,7.467,19.589,7.476,27.066,0
		c42.936-42.945,99.209-64.339,155.65-64.367c56.442,0.018,112.705,21.44,155.651,64.367c7.477,7.467,19.59,7.467,27.066,0
		c7.478-7.477,7.478-19.599,0-27.076C337.891,135.947,174.1,135.956,73.284,236.717z"
      />
      <path
        fill={color}
        d="M140.958,304.392c-7.486,7.486-7.486,19.588,0,27.076c7.468,7.476,19.59,7.476,27.067,0
		c24.281-24.273,56.039-36.357,87.974-36.375c31.936,0.018,63.684,12.102,87.975,36.375c7.478,7.476,19.599,7.476,27.067,0
		c7.476-7.478,7.476-19.599,0-27.076C307.534,240.941,204.456,240.951,140.958,304.392z"
      />
      <path
        fill={color}
        d="M204.54,395.04L256,446.51l51.46-51.451C279.029,366.638,232.952,366.638,204.54,395.04z"
      />
    </g>
  </svg>
);

export default WifiConnecteddIcon;
