import * as M from 'fp-ts/lib/Monoid';
import * as R from 'fp-ts/lib/Record';
import * as Ord from 'fp-ts/lib/Ord';
import { Predicate, getMonoidAny } from 'fp-ts/lib/Predicate';
import { defineMessages, MessageDescriptor } from 'react-intl';

import { SubTenantOverview } from './types';

export enum TenantStatusFilterOption {
  ACTIVE_ALERTS = 'ACTIVE_ALERTS',
  LAST_DAY_ALERTS = 'LAST_DAY_ALERTS',
  DISCONNECTED_DEVICES = 'DISCONNECTED_DEVICES',
}

export type TenantStatusFilter = {
  filter: 'status';
  value: TenantStatusFilterOption;
};

export type DeviceFilter = TenantStatusFilter;

export type StateFilters = {
  status: TenantStatusFilterOption[];
};

export type DisplayStatusFilter = {
  label: MessageDescriptor;
  displayOrder: number;
  value: TenantStatusFilterOption;
};

const { ACTIVE_ALERTS, LAST_DAY_ALERTS, DISCONNECTED_DEVICES } =
  TenantStatusFilterOption;

const messages = defineMessages({
  activeAlerts: {
    defaultMessage: 'Active Alerts',
  },
  lastDayAlerts: {
    defaultMessage: 'Alerts on last 24 hours',
  },
  disconnectedDevices: {
    defaultMessage: 'Disconnected devices',
  },
});

export type FilterItem = {
  displayOrder: number;
  label: MessageDescriptor;
  filter: Predicate<SubTenantOverview>;
};

const hasActiveAlerts: Predicate<SubTenantOverview> = subtenant =>
  subtenant.hasActiveAlert;
const hasAlertsInLastDay: Predicate<SubTenantOverview> = subtenant =>
  subtenant.lastDayAlertsCounter > 0;
const hasDisconnectedDevices: Predicate<SubTenantOverview> = subtenant =>
  subtenant.disconnectedAssignedDevicesCounter > 0 ||
  subtenant.disconnectedUnassignedDevicesCounter > 0;

export const STATUS_FILTERS: Record<TenantStatusFilterOption, FilterItem> = {
  [ACTIVE_ALERTS]: {
    displayOrder: 1,
    label: messages.activeAlerts,
    filter: hasActiveAlerts,
  },
  [LAST_DAY_ALERTS]: {
    displayOrder: 2,
    label: messages.lastDayAlerts,
    filter: hasAlertsInLastDay,
  },
  [DISCONNECTED_DEVICES]: {
    displayOrder: 3,
    label: messages.disconnectedDevices,
    filter: hasDisconnectedDevices,
  },
};

const monoidPredicateAny: M.Monoid<Predicate<SubTenantOverview>> =
  getMonoidAny();

export const combineStatusFilters = (
  filters: TenantStatusFilterOption[],
): Predicate<SubTenantOverview> => {
  const predicates = filters.map(filter => STATUS_FILTERS[filter].filter);

  return M.concatAll(monoidPredicateAny)(predicates);
};

export const DISPLAY_STATUS_FILTERS = R.collect(Ord.trivial)(
  (key: TenantStatusFilterOption, item: FilterItem) => ({
    value: key,
    label: item.label,
    displayOrder: item.displayOrder,
  }),
)(STATUS_FILTERS);
