import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { TenantSubscriptionDetails } from 'src/routes/GMDashboard/modules/types';
import DisconnectionAlertsSettingsForm from 'src/components/AlertSettingsComponents/DisconnectionAlertsSettingsForm';
import { ErrorState } from '../../types';
import { TechnicalAlertSettingsTitle } from './styled';
import { deepEqual } from 'src/utils/comparators';

type Props = {
  subscriptionDetails: TenantSubscriptionDetails;
  setSubscriptionDetails: React.Dispatch<
    React.SetStateAction<TenantSubscriptionDetails>
  >;
  setErrors: React.Dispatch<React.SetStateAction<ErrorState>>;
  isFormEnabled?: boolean;
};

const MTMTechnicalAlerts = ({
  subscriptionDetails,
  setSubscriptionDetails,
  setErrors,
  isFormEnabled = true,
}: Props): JSX.Element => {
  return (
    <>
      <TechnicalAlertSettingsTitle>
        <FormattedMessage {...messages.technicalAlerts} />
      </TechnicalAlertSettingsTitle>
      <DisconnectionAlertsSettingsForm
        technicalSubscriptionDetails={subscriptionDetails.TECHNICAL}
        handleSubscriptionDetails={technicalSubscriptionDetails => {
          setSubscriptionDetails(prevState => ({
            ...prevState,
            TECHNICAL: technicalSubscriptionDetails,
          }));
        }}
        setHasErrors={isError =>
          setErrors(prevState => ({
            ...prevState,
            technicalAlerts: isError,
          }))
        }
        isFormEnabled={isFormEnabled}
      />
    </>
  );
};

export const messages = defineMessages({
  technicalAlerts: {
    defaultMessage: 'Technical Alerts (Disconnected Devices)',
  },
});

export default React.memo(MTMTechnicalAlerts, (oldProps, newProps) =>
  deepEqual(oldProps, newProps),
);
