import React, { useState, useEffect } from 'react';
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  IntlShape,
} from 'react-intl';
import { Checkbox, Collapse } from 'antd';

import { MainTitle, PageWrapper } from 'src/components/styled';
import Spacer from 'src/components/Spacer';
import AlertThresholdSettingsCard from 'src/components/AlertSettingsComponents/AlertThresholdSettingsCard';
import AlertNotificationSettings from './components/AlertNotificationSettings';
import Connector, {
  PropsFromRedux,
  DisplayMetricThresholds,
} from './Connector';
import {
  CollapseStyled,
  SettingsSection,
  SectionHeader,
  SectionBody,
  AlertBody,
} from './styled';
import { ALERT_METRIC_PREPOSITION_ENUM } from 'src/redux/data/constants';
import permissions from 'src/permissions';
import AccessControl from 'src/components/AccessControl';

const { Panel } = Collapse;
const panelKeys = {
  ALERT_PANEL_KEY: 'alert-panel',
  MEASUREMENT_SETTINGS_PANEL_KEY: 'high-quality-panel',
  SMS_EMAIL_ALERTS_PANEL_KEY: 'sms-email-alerts',
};

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const SettingsPage = ({
  alertThresholds,
  createAlertThreshold,
  editAlertThreshold,
  onUpdateMeasurementSettings,
  isLoadingSettings,
  shouldDisplayHighQualityOnly,
  onSettingsPageMounted,
  intl,
}: Props): JSX.Element => {
  useEffect(() => {
    onSettingsPageMounted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const thresholdsList: DisplayMetricThresholds[] = [
    {
      metric: 'HR',
      title: intl.formatMessage(messages.hr),
      belowThreshold: {
        preposition: 'BELOW',
        value: 0,
        enable: false,
        updateAlert: createAlertThreshold,
      },
      aboveThreshold: {
        preposition: 'ABOVE',
        value: 0,
        enable: false,
        updateAlert: createAlertThreshold,
      },
    },
    {
      metric: 'RR',
      title: intl.formatMessage(messages.rr),
      belowThreshold: {
        preposition: 'BELOW',
        value: 0,
        enable: false,
        updateAlert: createAlertThreshold,
      },
      aboveThreshold: {
        preposition: 'ABOVE',
        value: 0,
        enable: false,
        updateAlert: createAlertThreshold,
      },
    },
  ];
  const [alertsEnabled, setAlertsEnabled] = useState(true);
  const [displayThresholds, setDisplayThresholds] = useState(thresholdsList);

  const disableAlerts = () => {
    displayThresholds.forEach(threshold => {
      const { metric } = threshold;
      const {
        id: belowId,
        preposition: belowPreposition,
        value: belowValue,
      } = threshold.belowThreshold;
      threshold.belowThreshold.updateAlert({
        id: belowId || '',
        preposition: belowPreposition,
        value: belowValue,
        metric,
        enable: !alertsEnabled,
      });
      const {
        id: aboveId,
        preposition: abovePreposition,
        value: aboveValue,
      } = threshold.aboveThreshold;
      threshold.aboveThreshold.updateAlert({
        id: aboveId || '',
        preposition: abovePreposition,
        value: aboveValue,
        metric,
        enable: !alertsEnabled,
      });
    });
  };
  const onToggleAlertsCheckbox = () => {
    setAlertsEnabled(!alertsEnabled);
    disableAlerts();
  };

  const onToggleHighQualityCheckbox = () => {
    onUpdateMeasurementSettings({ highQuality: !shouldDisplayHighQualityOnly });
  };

  useEffect(() => {
    updateDisplayAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertThresholds]);

  const updateDisplayAlerts = () => {
    alertThresholds.forEach(threshold => {
      const { preposition, metric } = threshold;
      const alertMetric = displayThresholds.find(
        alert => alert.metric === metric,
      );
      if (!alertMetric) {
        return;
      }

      const key = `${
        preposition === ALERT_METRIC_PREPOSITION_ENUM.ABOVE ? 'above' : 'below'
      }Threshold` as const;
      alertMetric[key] = {
        ...alertMetric[key],
        value: threshold.value,
        enable: threshold.enable,
        id: threshold.id,
        updateAlert: editAlertThreshold,
      };
    });
    setDisplayThresholds([...displayThresholds]);
  };

  return (
    <PageWrapper>
      <MainTitle>
        <FormattedMessage {...messages.settings} />
      </MainTitle>
      <SettingsSection>
        <CollapseStyled
          defaultActiveKey={[panelKeys.SMS_EMAIL_ALERTS_PANEL_KEY]}
        >
          <Panel
            key={panelKeys.MEASUREMENT_SETTINGS_PANEL_KEY}
            header={
              <SectionHeader>
                <FormattedMessage {...messages.measurmentSettings} />
              </SectionHeader>
            }
          >
            <SectionBody>
              <FormattedMessage {...messages.highQuality} />
              <Spacer flex={'1 0 0.2rem'} />
              <AccessControl
                permissions={[permissions.TELEMETRY_SETTINGS_UPDATE]}
              >
                <Checkbox
                  checked={shouldDisplayHighQualityOnly}
                  onChange={onToggleHighQualityCheckbox}
                  disabled={isLoadingSettings}
                />
              </AccessControl>
            </SectionBody>
          </Panel>
          <Panel
            key={panelKeys.ALERT_PANEL_KEY}
            header={
              <SectionHeader>
                <FormattedMessage {...messages.alerts} />
                <Checkbox
                  checked={alertsEnabled}
                  onChange={onToggleAlertsCheckbox}
                />
              </SectionHeader>
            }
          >
            <>
              <AlertBody>
                <SectionBody>
                  {displayThresholds.map(alert => (
                    <AlertThresholdSettingsCard
                      key={`${alert.metric}-Card`}
                      {...alert}
                    />
                  ))}
                </SectionBody>
              </AlertBody>
            </>
          </Panel>
          <Panel
            key={panelKeys.SMS_EMAIL_ALERTS_PANEL_KEY}
            header={
              <SectionHeader>
                <FormattedMessage {...messages.smsEmailAlerts} />
              </SectionHeader>
            }
          >
            <AlertNotificationSettings />
          </Panel>
        </CollapseStyled>
      </SettingsSection>
    </PageWrapper>
  );
};

const messages = defineMessages({
  settings: {
    defaultMessage: 'Settings',
  },
  alerts: {
    defaultMessage: 'Alerts',
  },
  hr: {
    defaultMessage: 'Heart Rate',
  },
  rr: {
    defaultMessage: 'Respiration Rate',
  },
  measurmentSettings: {
    defaultMessage: 'Measurement Settings',
  },
  highQuality: {
    defaultMessage: 'Display Only High Quality Vital Signs:',
  },
  smsEmailAlerts: {
    defaultMessage: 'SMS/Email Alerts',
  },
});

export default Connector(injectIntl(SettingsPage));
