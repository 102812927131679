import React from 'react';

import { SVGProps } from '../types';

const BedIcon = ({
  width = '32',
  height = '19',
  color = '#252525',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      d="M0.742268 19C1.15206 19 1.48454 18.7245 1.48454 18.3849V15.5827H30.5155V18.3849C30.5155 18.7245 30.8479 19 31.2577 19C31.6675 19 32 18.7245 32 18.3849V5.39928C32 5.05969 31.6675 4.78417 31.2577 4.78417C30.8479 4.78417 30.5155 5.05969 30.5155 5.39928V8.20144H1.48454V0.615108C1.48454 0.275517 1.15206 0 0.742268 0C0.332474 0 0 0.275517 0 0.615108V18.3849C0 18.7245 0.332474 19 0.742268 19ZM1.48454 14.3525V13.5324H30.5155V14.3525H1.48454ZM30.5155 9.43165V12.3022H1.48454V9.43165H30.5155Z"
      fill={color}
    />
  </svg>
);

export default BedIcon;
