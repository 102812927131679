import { connect } from 'react-redux';
import Component from '../components/Component';

const mapStateToProps = (state, ownProps) => ({
  patientUniqueId: ownProps.patientDetails?.id,
  patientId: ownProps.patientDetails?.patientId,
  patientName: `${ownProps.patientDetails?.firstName} ${ownProps.patientDetails?.lastName}`,
});

const mapActionCreators = {};

export default connect(mapStateToProps, mapActionCreators)(Component);
