import React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

import PageSummaryPanel, {
  connectedDevicesIcon,
  devicesIcon,
  dischargedPatientsIcon,
  eventsIcon,
  newPatientsIcon,
  patientsIcon,
} from 'src/components/PageSummaryPanel';
import Connector, { PropsFromRedux } from './Connector';

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const messages = defineMessages({
  totalPatients: {
    defaultMessage: 'Total Patients',
  },
  totalPatientsTooltip: {
    defaultMessage: 'Total patients in current tenant',
  },
  lastDayAlertEvents: {
    defaultMessage: 'Last 24h Events',
  },
  lastDayAlertEventsTooltip: {
    defaultMessage:
      'Total active and not active alerts that occurred in the last 24 hours',
  },
  totalDevices: {
    defaultMessage: 'Total Devices',
  },
  totalDevicesTooltip: {
    defaultMessage: 'Total devices assigned to current tenant',
  },
  connectedDevices: {
    defaultMessage: 'Connected Devices',
  },
  connectedDevicesTooltip: {
    defaultMessage: 'Total devices currently connected to Neteera Services',
  },
  assignedPatients: {
    defaultMessage: 'Assigned Patients',
  },
  assignedPatientsTooltip: {
    defaultMessage: 'Total patients currently ongoing a monitoring session',
  },
  lastDayDischarges: {
    defaultMessage: 'Last 24h Discharges',
  },
  lastDayDischargesTooltip: {
    defaultMessage:
      'Total patients whose monitoring sessions were stopped in the last 24 hours',
  },
});

const getStatisticsItems = ({
  totalDevices,
  connectedDevices,
  totalPatients,
  assignedPatients,
  lastDayDischarges,
  lastDayAlertEvents,
  intl,
}: Props) => [
  {
    key: 'total-devices',
    order: 1,
    icon: devicesIcon,
    title: intl.formatMessage(messages.totalDevices),
    tooltip: intl.formatMessage(messages.totalDevicesTooltip),
    value: totalDevices.toString(),
  },
  {
    key: 'connected-devices',
    order: 2,
    icon: connectedDevicesIcon,
    title: intl.formatMessage(messages.connectedDevices),
    tooltip: intl.formatMessage(messages.connectedDevicesTooltip),
    value: connectedDevices.toString(),
  },
  {
    key: 'total-patients',
    order: 3,
    icon: patientsIcon,
    title: intl.formatMessage(messages.totalPatients),
    tooltip: intl.formatMessage(messages.totalPatientsTooltip),
    value: totalPatients.toString(),
  },
  {
    key: 'assigned-patients',
    order: 4,
    icon: newPatientsIcon,
    title: intl.formatMessage(messages.assignedPatients),
    tooltip: intl.formatMessage(messages.assignedPatientsTooltip),
    value: assignedPatients.toString(),
  },
  {
    key: 'discharged-patients',
    order: 5,
    icon: dischargedPatientsIcon,
    title: intl.formatMessage(messages.lastDayDischarges),
    tooltip: intl.formatMessage(messages.lastDayDischargesTooltip),
    value: lastDayDischarges.toString(),
  },
  {
    key: 'total-events',
    order: 6,
    icon: eventsIcon,
    title: intl.formatMessage(messages.lastDayAlertEvents),
    tooltip: intl.formatMessage(messages.lastDayAlertEventsTooltip),
    value: lastDayAlertEvents.toString(),
  },
];

const SummaryPanel = (props: Props): JSX.Element => {
  return <PageSummaryPanel statisticsItems={getStatisticsItems(props)} />;
};

export default Connector(injectIntl(SummaryPanel));
