import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { actions, selectors } from './modules/slice';
import { AlertSidebarGMKey } from './modules/tabConfig';

const mapStateToProps = (
  state: RootState,
): { activeTab: AlertSidebarGMKey } => ({
  activeTab: selectors.selectActiveTab(state),
});

const mapActionCreators = {
  changeSidebarTab: actions.changeSidebarTab,
  onMount: actions.alertSidebarMounted,
  onUnmount: actions.alertSidebarUnmounted,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
