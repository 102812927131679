import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import logo from 'src/images/Neteera_Logo_no_background.png';
import whiteLogo from 'src/images/neteera_logo_white.png';
import { media } from 'src/utils/mediaQueryUtils';
import AppVersion from 'src/components/AppVersion';
import Box from 'src/components/Box';

const Version = styled(AppVersion)`
  font-size: 10px;
  color: white;
`;

const normalCSS = css`
  img {
    width: 100%;
    height: 100%;
    ${media.extraSmall`
      width: 70%;
      height: 70%;
  `}
  }
`;

const smallCSS = css`
  width: 36px;

  img {
    height: 100%;
  }
`;

const ImgContainer = styled(Box).attrs(props => ({
  centered: true,
  gutter: props.gutter,
}))`
  position: relative;
  text-align: center;
`;

const Mask = styled.div`
  overflow: hidden;
  max-width: ${props => props.maxWidth || '200px'};

  ${props => (props.isSmall ? smallCSS : normalCSS)};
`;

const Logo = props => {
  const { className, isSmall, isWhite, maxWidth, ...rest } = props;

  return (
    <ImgContainer className={className} small={isSmall} {...rest}>
      <Mask sk small={isSmall} maxWidth={maxWidth}>
        <img src={isWhite ? whiteLogo : logo} alt="Neteera" />
      </Mask>

      <Version small={isSmall} />
    </ImgContainer>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  isSmall: PropTypes.bool,
  isWhite: PropTypes.bool,
  maxWidth: PropTypes.string,
  gutter: PropTypes.number,
};
Logo.defaultProps = {
  gutter: 8,
};

Logo.defaultProps = {
  className: null,
  isSmall: false,
  isWhite: false,
  maxWidth: null,
};

export default Logo;
