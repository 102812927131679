import styled from 'styled-components';
import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button/button';

const DefaultButtonWithIcon = styled(Button)`
  background: ${props => props.theme.colors.buttonWithIconBackground};
  color: ${props => props.theme.colors.coloredButtonWithIcon};
  border-color: ${props => props.theme.colors.coloredButtonWithIcon};
  font-weight: bold;
  font-size: 0.7rem;
  white-space: nowrap;
  &.ant-btn-primary:not(:disabled):not(.ant-btn-disabled) {
    &:hover,
    &:active {
      background: ${props => props.theme.colors.buttonWithIconBackground};
      color: ${props => props.theme.colors.coloredButtonWithIcon};
      opacity: 0.7;
    }
  }

  :focus {
    opacity: 0.9;
  }
  .ant-btn-icon {
    margin-right: 0.2rem;
  }
`;
const ButtonWithIcon = ({ children, ...rest }: ButtonProps) => (
  <DefaultButtonWithIcon type="text" {...rest}>
    {children}
  </DefaultButtonWithIcon>
);
export default ButtonWithIcon;
