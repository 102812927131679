import React, { useEffect } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { Tooltip } from 'antd';

import Spinner from 'src/components/general-ui/Spinner';
import { BoldItem, ThinItem } from 'src/components/styled';
import startButton from '../../resources/startButton.svg';
import stopButton from '../../resources/stopButton.svg';

import {
  PatientDetailsHeader,
  Title,
  SmallTitle,
  VerticalSeparator,
  PatientInformationSide,
  SpacedItem,
  OptionsSide,
  ActionButton,
  PatientInfoWrapper,
  PatientIconStatusWrapper,
  FlexColWrapper,
  StyledIcon,
  IconText,
  LocationItem,
  RoomLocationWrap,
  StyledParagraph,
} from './styled';
import PatientSelector from './components/PatientSelector';
import Connector, { PropsFromRedux } from './Connector';
import { calculatePatientAge } from './utils';
import PatientStatusComponent from 'src/components/PatientStatusComponent';
import EllipsisWithSuffix from 'src/components/general-ui/EllipsisComponent/EllipsisComponent';
import permissions from 'src/permissions';
import AccessControl from 'src/components/AccessControl';

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const PageHeader = ({
  patient,
  room,
  bed,
  patientsList,
  setSelectedPatientId,
  isDeviceActive,
  selectedPatientId,
  deviceId,
  onClickStartContinuous,
  onClickStopContinuous,
  onStartWithNoPatient,
  saveRawData,
  searchFocusStatus,
  isDeviceConnected,
  isStopContinuousLoading,
  isStartContinuousLoading,
  isUnoccupied,
  clearBedOccupancyGraph,
  getLatestSessions,
  intl,
  isVacancyAlertActive,
  ongoingDeviceAlerts,
  isLongOutOfBedAlertActive,
}: Props): JSX.Element => {
  const assignedPatient = patientsList
    .filter(patient => patient.deviceId)
    .find(patient => patient.id === selectedPatientId);

  useEffect(() => {
    getLatestSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { fullName, gender, dateOfBirth } = patient || {};
  const handleStop = (event: React.MouseEvent<HTMLImageElement>) => {
    getLatestSessions();

    if (event) {
      event.stopPropagation();
    }
    if (isDeviceActive) {
      onClickStopContinuous({ deviceId });
    }
  };
  const handleStart = (event: React.MouseEvent<HTMLImageElement>) => {
    if (isStartContinuousLoading || !isDeviceConnected || assignedPatient) {
      return;
    }

    event.stopPropagation();
    if (selectedPatientId) {
      onClickStartContinuous({
        deviceId,
        patientId: selectedPatientId,
        saveRawData,
      });
    } else {
      onStartWithNoPatient(true);
    }
  };
  const getTooltipText = (): string => {
    if (isStartContinuousLoading) {
      return intl.formatMessage(messages.tooltipStartLoadingDisabled);
    }

    if (!isDeviceConnected) {
      return intl.formatMessage(messages.tooltipDeviceDisconnectedDisabled);
    }

    return '';
  };

  return (
    <PatientDetailsHeader>
      <PatientInformationSide>
        <PatientInfoWrapper>
          <StyledParagraph
            ellipsis={{
              rows: 1,
              tooltip: fullName,
            }}
          >
            <Title>{fullName}</Title>
          </StyledParagraph>

          <SmallTitle>
            <LocationItem>
              <RoomLocationWrap>
                {room?.name ? (
                  <EllipsisWithSuffix rows={1}>{room?.name}</EllipsisWithSuffix>
                ) : (
                  <FormattedMessage {...messages.notAssigned} />
                )}
              </RoomLocationWrap>
            </LocationItem>
            &nbsp;
            <ThinItem>
              <RoomLocationWrap>
                <FormattedMessage {...messages.bed} />
              </RoomLocationWrap>
            </ThinItem>
            &nbsp;
            <BoldItem>
              {bed?.name ? (
                <EllipsisWithSuffix rows={1}>{bed?.name}</EllipsisWithSuffix>
              ) : (
                <FormattedMessage {...messages.notAssigned} />
              )}
            </BoldItem>
          </SmallTitle>
          <VerticalSeparator>|</VerticalSeparator>
          <SmallTitle>
            <BoldItem>
              <FormattedMessage {...messages.age} />:{' '}
            </BoldItem>
            <ThinItem>
              {' '}
              <SpacedItem>{calculatePatientAge(dateOfBirth)}</SpacedItem>
            </ThinItem>
          </SmallTitle>
          <VerticalSeparator>|</VerticalSeparator>
          <SmallTitle>
            <BoldItem>
              <FormattedMessage {...messages.gender} />:{' '}
            </BoldItem>
            <ThinItem>
              <SpacedItem>{gender ? gender.toLowerCase() : '-'}</SpacedItem>
            </ThinItem>
          </SmallTitle>
        </PatientInfoWrapper>
        <PatientIconStatusWrapper>
          {isDeviceConnected && isDeviceActive && (
            <FlexColWrapper>
              <PatientStatusComponent
                isUnoccupied={isUnoccupied}
                isDeviceConnected={isDeviceConnected}
                isVacancyAlertActive={isVacancyAlertActive}
                ongoingDeviceAlerts={ongoingDeviceAlerts}
                isLongOutOfBedAlertActive={!!isLongOutOfBedAlertActive}
                itemsAlignment="row"
              />
              <IconText>
                {isUnoccupied ? (
                  <FormattedMessage {...messages.unoccupied} />
                ) : (
                  <FormattedMessage {...messages.occupied} />
                )}
              </IconText>
            </FlexColWrapper>
          )}

          <FlexColWrapper>
            <StyledIcon
              opacity={isDeviceConnected ? '1' : '0.5'}
              type={
                isDeviceConnected
                  ? 'wifi-connected-icon'
                  : 'wifi-disconnected-icon'
              }
            />
            <IconText>
              {isDeviceConnected ? (
                <FormattedMessage {...messages.connected} />
              ) : (
                <FormattedMessage {...messages.disconnected} />
              )}
            </IconText>
          </FlexColWrapper>
        </PatientIconStatusWrapper>
      </PatientInformationSide>
      <OptionsSide>
        <PatientSelector
          patientsList={patientsList}
          setSelectedPatientId={id => {
            setSelectedPatientId(id);
            clearBedOccupancyGraph();
          }}
          patientId={selectedPatientId}
          disabled={
            isDeviceActive ||
            isStartContinuousLoading ||
            isStopContinuousLoading
          }
          searchFocusStatus={searchFocusStatus}
          onStartWithNoPatient={onStartWithNoPatient}
        />
        {(isStopContinuousLoading || isStartContinuousLoading) && (
          <Spinner
            customStyling={{
              position: 'absolute',
              marginLeft: '0.75rem',
              marginTop: '0.35rem',
              fontSize: '1.4rem',
            }}
          />
        )}
        {isDeviceActive || isStopContinuousLoading ? (
          <AccessControl
            permissions={[permissions.TELEMETRY_STOP_CONTINUOUS_SESSION]}
          >
            <ActionButton
              isDisabled={isStopContinuousLoading}
              src={stopButton}
              onClick={handleStop}
              alt="stop-button"
            />
          </AccessControl>
        ) : (
          <AccessControl
            permissions={[permissions.TELEMETRY_START_CONTINUOUS_SESSION]}
          >
            <Tooltip title={getTooltipText()} placement="topLeft">
              <ActionButton
                isDisabled={
                  isStartContinuousLoading ||
                  !isDeviceConnected ||
                  !!assignedPatient
                }
                src={startButton}
                onClick={handleStart}
                alt="play-button"
              />
            </Tooltip>
          </AccessControl>
        )}
      </OptionsSide>
    </PatientDetailsHeader>
  );
};

const messages = defineMessages({
  age: {
    defaultMessage: 'Age',
  },
  gender: {
    defaultMessage: 'Gender',
  },
  bed: {
    defaultMessage: 'Bed',
  },
  notAssigned: {
    defaultMessage: 'N/A',
  },
  tooltipStartLoadingDisabled: {
    defaultMessage: 'Device is loading...',
  },
  tooltipDeviceDisconnectedDisabled: {
    defaultMessage: 'Device is disconnected',
  },
  connected: { defaultMessage: 'Connected' },
  disconnected: { defaultMessage: 'Disconnected' },
  occupied: { defaultMessage: 'Occupied' },
  unoccupied: { defaultMessage: 'Unoccupied' },
});

export default Connector(injectIntl(PageHeader));
