import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/CrudCommonComponents/Table';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { MainTitle, PageWrapper } from 'src/components/styled';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import GroupModal from './components/GroupModal/GroupModal';
import UpdateVersionModal from './components/UpdateVersionModal';
import Connector from './Connector';
import { deepEqual } from 'src/utils/comparators';
import { DEFAULT_PAGINATION, USER_TYPES } from 'src/utils/constants';

const groupModalModes = {
  EDIT: 'edit',
  ADD: 'add',
};

const actionsKeys = {
  EDIT: 'edit',
  UPDATE_VERSION: 'updateVersion',
  DELETE: 'delete',
  UPDATE_DEFAULT_VERSION: 'updateDefaultVersion',
};
const Groups = ({
  intl,
  onLoadGroupsList,
  groupsList,
  groupsListMetadata,
  isLoading,
  onEditGroup,
  modalStatus,
  setModalStatus,
  onCreateGroup,
  onDeleteGroup,
  onUpdateGroupVersion,
  isDeviceListLoading,
  devicesList,
  tenantSelectOptions,
  originalDevicesList,
  onLoadGroupClients,
  onLoadGroupDevices,
  onLoadGroupTenants,
  searchAllDevices,
  loggedInUserType,
  searchAdminGroupsList,
}) => {
  const [tableData, setTableData] = useState([]);
  const [activeActionRow, setActiveActionRow] = useState({});
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState(false);
  const [isUpdateVersionModalVisible, setIsUpdateVersionModalVisible] =
    useState(false);
  const activeRowGroupId = activeActionRow.id;
  const [tableParams, setTableParams] = useState({
    current: DEFAULT_PAGINATION.PAGE,
    pageSize: DEFAULT_PAGINATION.LIMIT,
    showSizeChanger: false,
    total: 0,
  });

  const [mode, setMode] = useState(groupModalModes.ADD);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState([]);
  const userType = loggedInUserType?.name;
  const paginationChange = page => {
    setTableParams(prevState => ({
      ...prevState,
      current: page,
    }));
  };

  useEffect(() => {
    onLoadGroupTenants();
  }, [onLoadGroupTenants]);

  useEffect(() => {
    originalDevicesList?.length > 0 && onLoadGroupClients();
  }, [originalDevicesList, onLoadGroupClients]);

  useEffect(() => {
    setTableParams(prevState => ({
      ...prevState,
      total: groupsListMetadata?.page?.totalResults || 0,
    }));
  }, [groupsListMetadata?.page?.totalResults]);

  useEffect(() => {
    onLoadGroupsList({
      currentPage: tableParams?.current - 1,
      pageSize: tableParams?.pageSize,
    });
  }, [tableParams?.current, tableParams?.pageSize, onLoadGroupsList]);

  useEffect(() => {
    if (!isLoading) {
      setTableData(
        groupsList.map(groupData => ({
          key: groupData.id,
          ...groupData,
          name: groupData.name.trim(),
          actions:
            groupData.id === 'default'
              ? [actionsKeys.UPDATE_DEFAULT_VERSION]
              : [
                  actionsKeys.EDIT,
                  actionsKeys.UPDATE_VERSION,
                  actionsKeys.DELETE,
                ],
        })),
      );
    }
  }, [groupsList, onLoadGroupsList, isLoading]);

  useEffect(() => {
    searchKeyword.length !== 0 && searchAdminGroupsList(searchKeyword);
    !searchKeyword.length &&
      onLoadGroupsList({
        currentPage: tableParams?.current - 1,
        pageSize: tableParams?.pageSize,
      });
  }, [
    searchKeyword,
    onLoadGroupsList,
    searchAdminGroupsList,
    tableParams?.current,
    tableParams?.pageSize,
  ]);

  const converNullToEmptyString = string => string || '';
  const sorterCompare = (a, b, field) =>
    converNullToEmptyString(a[field]).localeCompare(
      converNullToEmptyString(b[field]),
    );
  const columns = [
    {
      title: intl.formatMessage(messages.name),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => sorterCompare(a, b, 'name'),
    },
    {
      title: intl.formatMessage(messages.version),
      dataIndex: 'versionName',
      key: 'swVersion',
      sorter: (a, b) => sorterCompare(a, b, 'versionName'),
    },
    {
      title: intl.formatMessage(messages.versionPlus),
      dataIndex: 'versionPlusName',
      key: 'swVersionPlus',
      sorter: (a, b) => sorterCompare(a, b, 'versionPlusName'),
    },
  ];

  const actions = [
    {
      key: actionsKeys.EDIT,
      name: intl.formatMessage(messages.edit),
      onClick: (_, { record }) => {
        setActiveActionRow(record);
        setMode(groupModalModes.EDIT);
        setIsModalVisible(true);
      },
    },
    {
      key: actionsKeys.UPDATE_VERSION,
      name: intl.formatMessage(messages.updateVersion),
      onClick: (_, { record }) => {
        setActiveActionRow(record);
        setIsUpdateVersionModalVisible(true);
      },
    },
    {
      key: actionsKeys.DELETE,
      name: intl.formatMessage(messages.delete),
      onClick: (_, { record }) => {
        setActiveActionRow(record);
        setIsDeleteConfirmationVisible(true);
      },
    },
  ];

  const onUpdateVersion = updateVersionData =>
    onUpdateGroupVersion({
      updateVersionData,
      groupId: activeRowGroupId,
    });

  const onGroupModalOkClickMapper = {
    [groupModalModes.EDIT]: data => {
      onEditGroup({
        ...data,
        name: data.name.trim(),
        groupId: activeRowGroupId,
      });
    },
    [groupModalModes.ADD]: data => {
      onCreateGroup({ ...data, name: data.name.trim() });
    },
  };

  return (
    <PageWrapper>
      <MainTitle>
        <FormattedMessage {...messages.groups} />
      </MainTitle>
      <Table
        loading={isLoading}
        data={tableData}
        columns={columns}
        actions={actions}
        withAddButton
        addButtonOnClick={() => {
          setMode(groupModalModes.ADD);
          setIsModalVisible(true);
          setActiveActionRow({});
        }}
        withSearch
        searchFunction={
          userType === USER_TYPES.SYSTEM_ADMIN && setSearchKeyword
        }
        pagination={
          tableParams && tableParams.total > tableParams.pageSize
            ? {
                ...tableParams,
                onChange: paginationChange,
              }
            : false
        }
      />
      {isModalVisible && (
        <GroupModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          onOk={onGroupModalOkClickMapper[mode]}
          selectedGroup={activeActionRow}
          mode={mode}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          onLoadDeviceList={onLoadGroupDevices}
          isLoading={isDeviceListLoading}
          tenantSelectOptions={tenantSelectOptions}
          devicesList={devicesList}
          groupModalModes={groupModalModes}
          searchAllDevices={searchAllDevices}
        />
      )}
      {isUpdateVersionModalVisible && (
        <UpdateVersionModal
          isModalVisible={isUpdateVersionModalVisible}
          setIsModalVisible={setIsUpdateVersionModalVisible}
          onOk={onUpdateVersion}
          selectedGroup={activeActionRow}
          mode={groupModalModes.EDIT}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
        />
      )}
      <ConfirmationModal
        isModalVisible={isDeleteConfirmationVisible}
        setIsModalVisible={setIsDeleteConfirmationVisible}
        onOk={() => {
          onDeleteGroup(activeRowGroupId);
        }}
        message={intl.formatMessage(messages.deleteConfirmation)}
      />
    </PageWrapper>
  );
};
Groups.propTypes = {
  onLoadGroupsList: PropTypes.func.isRequired,
  searchAdminGroupsList: PropTypes.func.isRequired,
  groupsList: PropTypes.array.isRequired,
  groupsListMetadata: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onEditGroup: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  onCreateGroup: PropTypes.func.isRequired,
  onDeleteGroup: PropTypes.func.isRequired,
  onUpdateGroupVersion: PropTypes.func.isRequired,
  onEditApp: PropTypes.func.isRequired,
  isDeviceListLoading: PropTypes.string,
  devicesList: PropTypes.array.isRequired,
  originalDevicesList: PropTypes.array.isRequired,
  onLoadGroupClients: PropTypes.func.isRequired,
  onLoadGroupDevices: PropTypes.func.isRequired,
  onLoadGroupTenants: PropTypes.func.isRequired,
  tenantSelectOptions: PropTypes.array.isRequired,
  searchAllDevices: PropTypes.func.isRequired,
  onChangeAssignedDevices: PropTypes.func.isRequired,
  onChangeUnassignedDevices: PropTypes.func.isRequired,
  groupSelectedDevices: PropTypes.object,
  loggedInUserType: PropTypes.object,
};

const messages = defineMessages({
  groups: {
    defaultMessage: 'Groups',
  },
  edit: {
    defaultMessage: 'Edit',
  },
  updateVersion: {
    defaultMessage: 'Update Version',
  },
  delete: {
    defaultMessage: 'Delete',
  },
  name: {
    defaultMessage: 'Name',
  },
  version: {
    defaultMessage: '130H SW Version',
  },
  versionPlus: {
    defaultMessage: '130H-Plus SW Version',
  },
  deleteConfirmation: {
    defaultMessage: 'Are you sure you want to delete?',
  },
});

export default Connector(
  injectIntl(
    React.memo(Groups, (oldProps, newProps) => deepEqual(oldProps, newProps)),
  ),
);
