import { connect } from 'react-redux';

import {
  selectors as tenantDataSelectors,
  actions as tenantDataActions,
} from 'src/redux/data/tenant';
import {
  selectors as customersSelectors,
  actions as customersActions,
} from 'src/redux/data/customers';
import { selectors as loggedInUserDataSelectors } from 'src/redux/data/loggedInUser';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { NETEERA_TENANT } from 'src/utils/constants';
import Component from '../components/Component';

const mapStateToProps = state => {
  const searchResults = tenantDataSelectors.getSearchResults(state);
  return {
    tenantsList:
      searchResults.length > 0
        ? searchResults
        : tenantDataSelectors.getPaginatedTenants(state).data,
    tenantsTotalCount:
      tenantDataSelectors.getPaginatedTenants(state).pageMetadata.totalResults,

    excludedFromDeleteTenants: [
      loggedInUserDataSelectors.getUserTenantId(state),
      NETEERA_TENANT,
    ],
    mtmTenants: tenantDataSelectors
      .getTenantsList(state)
      .filter(tenant => tenant.group === true),
    isLoading:
      tenantDataSelectors.getStatus(state) === DATA_FETCHING_STATUS.LOADING ||
      tenantDataSelectors.isLoadingSearch(state),
    modalStatus: tenantDataSelectors.getModalStatus(state),
    customersList: customersSelectors.selectCustomersList(state),
  };
};

const mapActionCreators = {
  ...tenantDataActions,
  getCustomers: customersActions.getCustomersList,
};

export default connect(mapStateToProps, mapActionCreators)(Component);
