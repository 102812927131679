import React, { useCallback, useEffect, useState } from 'react';
import { PropsFromRedux } from './Connector';
import { PaginationProps } from 'antd';
import { PageMetaDataType } from 'src/types/utility';
import { debounce } from 'lodash';

type MTMCurrentAlertsHook = {
  currentPage: number;
  searchQuery: string;
  attentionListMetadata: {
    page: PageMetaDataType;
    sort: unknown;
  };
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPaginationChange: PaginationProps['onChange'];
  onRefreshHandler: () => void;
};
export const useGetServerCalls = (
  fetchMTMAttentionList: PropsFromRedux['fetchMTMAttentionList'],
  metadata: PropsFromRedux['metadata'],
): MTMCurrentAlertsHook => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [attentionListMetadata, setAttentionListMetadata] = useState<{
    page: PageMetaDataType;
    sort: unknown;
  }>({ page: { totalResults: 0, page: 0, limit: 0 }, sort: null });
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    fetchMTMAttentionList({ shouldUpdateMetadata: true });
  }, []);

  useEffect(() => {
    if (!metadata) {
      return;
    }
    setAttentionListMetadata(metadata);
  }, [metadata]);

  useEffect(() => {
    if (!attentionListMetadata || attentionListMetadata?.page?.page < 2) {
      return;
    }
    fetchMTMAttentionList({
      pagination: {
        page: attentionListMetadata?.page?.page,
        limit: attentionListMetadata?.page?.limit,
      },
      shouldUpdateMetadata: true,
    });
  }, [attentionListMetadata?.page?.page]);

  const onRefreshHandler = () => {
    setCurrentPage(1);
    setSearchQuery('');
    setTimeout(() => {
      fetchMTMAttentionList({
        shouldUpdateMetadata: true,
      });
    }, 800);
  };
  const onPaginationChange: PaginationProps['onChange'] = page => {
    setCurrentPage(page);
    fetchMTMAttentionList({
      pagination: {
        page,
        limit: attentionListMetadata?.page?.limit,
      },
      searchQuery,
      shouldUpdateMetadata: true,
    });
  };

  const performSearch = useCallback(
    (searchQuery: string) => {
      setCurrentPage(1);
      fetchMTMAttentionList({
        pagination: {
          page: 1,
          limit: attentionListMetadata?.page?.limit,
        },
        searchQuery,
        shouldUpdateMetadata: true,
      });
    },
    [attentionListMetadata?.page?.limit],
  );

  const debouncedSearch = useCallback(debounce(performSearch, 300), [
    attentionListMetadata?.page?.limit,
  ]);

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
    if (value.length && value.length < 3) {
      return;
    }
    // @ts-ignore ignore it for now
    debouncedSearch(value);
  };
  return {
    currentPage,
    searchQuery,
    attentionListMetadata,
    onSearchChange,
    onPaginationChange,
    onRefreshHandler,
  };
};
