import React from 'react';
import { Route } from 'react-router-dom';

import AlertSidebar from 'src/components/Sidebars/AlertSidebar';
import AlertSidebarGroupManager from 'src/components/Sidebars/AlertSidebarGroupManager';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import { hasGMPermissions } from 'src/utils/permissions';

const Sidebar = (): JSX.Element => {
  if (hasGMPermissions()) {
    return (
      <Route
        key="alert-sidebar-group-manager"
        path={[
          `/:locale/${appRoutes.DASHBOARD}`,
          `/:locale/${appRoutes.GROUP_MANAGER_USERS}`,
        ]}
        component={AlertSidebarGroupManager}
      />
    );
  }

  return (
    <Route
      key="alert-sidebar"
      path={[
        `/:locale/${appRoutes.NURSES_STATION}`,
        `/:locale/${appRoutes.INDIVIDUAL_DASHBOARD}`,
      ]}
      component={AlertSidebar}
    />
  );
};

export default Sidebar;
