import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import styled from 'styled-components';
import {
  SPOT_TABS_KEYS,
  METRIC_ENUM,
} from 'src/utils/graphsUtils/graphConstants';
import HistoryInnerTabs from './HistoryInnerTabs';
import SpotGraphs from './SpotGraphs';
import Table from './SpotTable';

const Container = styled.div``;

const initialState = {
  activeInnerTab: SPOT_TABS_KEYS.TABLE,
  graphData: {},
  datesRange: { from: null, to: null },
};

const Spot = ({
  patientId,
  onSpotHistoryMount,
  spotMeasurementsData,
  isLoading,
}) => {
  const intl = useIntl();
  const [activeInnerTab, setActiveInnerTab] = useState(
    initialState.activeInnerTab,
  );
  const [datesRange, setDatesRange] = useState(initialState.datesRange);
  const [graphData, setGraphData] = useState(initialState.graphData);

  useEffect(() => {
    onSpotHistoryMount({
      patientId,
      startTime: datesRange.from,
      endTime: datesRange.to,
    });
  }, [onSpotHistoryMount, patientId, datesRange]);

  useEffect(() => {
    const data = {
      [METRIC_ENUM.HR]: {
        points:
          spotMeasurementsData?.map(e => ({
            x: e.startTime,
            y: e.heartRate,
          })) || [],
      },
      [METRIC_ENUM.RR]: {
        points:
          spotMeasurementsData?.map(e => ({
            x: e.startTime,
            y: e.respirationRate,
          })) || [],
      },
    };
    setGraphData(data);
  }, [spotMeasurementsData]);

  const onTabChange = tabKey => {
    setActiveInnerTab(tabKey);
  };

  const filterDataByDates = useCallback(
    (from, to) => {
      setDatesRange({ from, to });
    },
    [setDatesRange],
  );

  const innerTabs = [
    {
      key: SPOT_TABS_KEYS.TABLE,
      title: intl.formatMessage(messages.tableTabTitle),
      contentComponent: () => (
        <Table
          patientId={patientId}
          data={spotMeasurementsData}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: SPOT_TABS_KEYS.GRAPHS,
      title: intl.formatMessage(messages.graphsTabTitle),
      contentComponent: () => (
        <SpotGraphs
          data={graphData}
          startDateTime={datesRange.from}
          endDateTime={datesRange.to}
          isLoading={isLoading}
        />
      ),
    },
  ];

  return (
    <Container>
      <HistoryInnerTabs
        filterDataByDates={filterDataByDates}
        tabs={innerTabs}
        activeTabKey={activeInnerTab}
        onTabChangeSync={onTabChange}
        defaultDatesRange={datesRange}
      />
    </Container>
  );
};

Spot.propTypes = {
  patientId: PropTypes.string.isRequired,
  spotMeasurementsData: PropTypes.array,
  onSpotHistoryMount: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

Spot.defaultProps = {
  spotMeasurementsData: [],
};

const messages = defineMessages({
  tableTabTitle: {
    defaultMessage: 'Table',
  },
  graphsTabTitle: {
    defaultMessage: 'Graphs',
  },
});

export default Spot;
