export type phoneCountry = { dialCode: string; format: string };

export const validatePhoneNumber = (
  inputNumber: string,
  country: phoneCountry,
  countries: Array<phoneCountry>,
) => {
  const selectedCountry = countries?.filter(
    val => val.dialCode === country.dialCode,
  );
  if (!selectedCountry || !selectedCountry[0]) {
    return false;
  }
  const phoneLength = Math.ceil(selectedCountry[0]?.format.length / 2);
  if (
    inputNumber &&
    inputNumber?.replace(country.dialCode, '')?.trim() === ''
  ) {
    return false;
  }
  if (inputNumber.length !== phoneLength) {
    return false;
  }
  return true;
};
