import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';

import { EmrDataParamaters } from './Constants';
import {
  StyledTitle,
  StyledWrapper,
  StyledParameterTitle,
  ParametersWrapper,
} from './styled';
import { EmrDataParamtersType } from 'src/routes/Customers/modules/types';

const EmrFrequencySettings = (): JSX.Element => {
  const [emrFrequencySetting, setFrequencySetting] =
    useState<EmrDataParamtersType>({});

  const handleChange = (emrParameter: string) => (value: string) => {
    setFrequencySetting(prevState => ({
      ...prevState,
      [emrParameter]: {
        ...prevState[emrParameter],
        value,
      },
    }));
  };

  console.log(emrFrequencySetting);

  return (
    <StyledWrapper>
      <StyledTitle>
        <FormattedMessage {...messages.emrFrequencyTitle} />
      </StyledTitle>
      {EmrDataParamaters.map(emrParamter => (
        <ParametersWrapper key={emrParamter.label}>
          <StyledParameterTitle>{emrParamter.label}</StyledParameterTitle>
          {console.log(emrFrequencySetting[emrParamter.value]?.value)}
          <Cron
            value={emrFrequencySetting[emrParamter.value]?.value || '* * * * *'}
            setValue={handleChange(emrParamter.value)}
            humanizeValue={true}
            clearButton={true}
            clockFormat={'12-hour-clock'}
            defaultPeriod={'day'}
            leadingZero={['month-days']}
            className="styled-cron-component"
            mode={'single'}
            //TODO: handle when month is sleected to disable week-days
            allowedDropdowns={[
              'period',
              'months',
              'month-days',
              'week-days',
              'hours',
              'minutes',
            ]}
            allowedPeriods={['month', 'week', 'day']}
            clearButtonProps={{
              type: 'default',
            }}
          />
          {/* <p style={{ marginTop: 20 }}>Selected period: {selectedPeriod}</p>
          {displayErrorText && (
            <p style={{ marginTop: 20 }}>
              Error: {error ? error.description : 'undefined'}
            </p>
          )} */}
        </ParametersWrapper>
      ))}
    </StyledWrapper>
  );
};

const messages = defineMessages({
  emrFrequencyTitle: {
    defaultMessage: 'Select EMR Update frequency',
  },
  every: {
    defaultMessage: 'Every',
  },
  on: {
    defaultMessage: 'on',
  },
  at: {
    defaultMessage: 'at',
  },
});

export default EmrFrequencySettings;
