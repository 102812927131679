import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  text-align: center;
`;

const Loader = ({ isSpinning }) => (
  <SpinnerWrapper>
    <Spin spinning={isSpinning} />
  </SpinnerWrapper>
);

Loader.propTypes = {
  isSpinning: PropTypes.bool,
};

Loader.defaultProps = {
  isSpinning: true,
};

export default Loader;
