import React from 'react';
import { ModalProps, Popconfirm } from 'antd';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';

import {
  ModalTitle,
  StyledModal,
  ModalFooter,
  CancelBtn,
  SaveBtn,
} from './styled';

type Props = ModalProps & {
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  title: string;
  withSaveBtn?: boolean;
  withCancelBtn?: boolean;
  saveBtnProps?: Partial<{
    label: string;
    isDisabled: boolean;
    onClick: () => void;
  }>;
  cancelBtnProps?: Partial<{
    label: string;
    isDisabled: boolean;
    onClick: () => void;
  }>;
  children: React.ReactNode;
  className?: string;
  intl: IntlShape;
  withSaveConfirmation?: {
    confirmationTitle?: string;
    confirmationText?: string;
  } | null;
};

const FormModal = ({
  isModalVisible,
  setIsModalVisible,
  title,
  children,
  withSaveBtn = false,
  withCancelBtn = false,
  saveBtnProps,
  cancelBtnProps,
  intl,
  className,
  withSaveConfirmation,
  ...restProps
}: Props): JSX.Element => {
  const {
    label: saveBtnLabel,
    onClick: onClickSave,
    ...saveBtnRestProps
  } = saveBtnProps || {};
  const {
    label: cancelBtnLabel,
    onClick: onClickCancel,
    ...cancelBtnRestProps
  } = cancelBtnProps || {};

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    if (cancelBtnRestProps.isDisabled) {
      return;
    }
    e.stopPropagation();
    onClickCancel && onClickCancel();
    setIsModalVisible(false);
  };
  const handleSave = (e: React.MouseEvent<HTMLElement>) => {
    if (saveBtnRestProps.isDisabled) {
      return;
    }
    e.stopPropagation();
    onClickSave && onClickSave();
    setIsModalVisible(false);
  };

  return (
    <StyledModal
      title={<ModalTitle>{title}</ModalTitle>}
      open={isModalVisible}
      className={className}
      footer={
        withCancelBtn || withSaveBtn ? (
          <ModalFooter>
            {withCancelBtn && (
              <CancelBtn onClick={handleCancel} {...(cancelBtnRestProps || {})}>
                {cancelBtnLabel || intl.formatMessage(messages.cancel)}
              </CancelBtn>
            )}
            {withSaveBtn && withSaveConfirmation && (
              <Popconfirm
                title={withSaveConfirmation.confirmationTitle}
                description={withSaveConfirmation.confirmationText}
                //@ts-ignore fix later
                onConfirm={handleSave}
                okText={intl.formatMessage(messages.yes)}
                cancelText={intl.formatMessage(messages.no)}
                disabled={saveBtnRestProps.isDisabled}
              >
                <SaveBtn {...(saveBtnRestProps || {})}>
                  {saveBtnLabel || intl.formatMessage(messages.save)}
                </SaveBtn>
              </Popconfirm>
            )}
            {withSaveBtn && !withSaveConfirmation && (
              <SaveBtn onClick={handleSave} {...(saveBtnRestProps || {})}>
                {saveBtnLabel || intl.formatMessage(messages.save)}
              </SaveBtn>
            )}
          </ModalFooter>
        ) : null
      }
      onCancel={handleCancel}
      closable={true}
      centered={true}
      {...restProps}
    >
      {children}
    </StyledModal>
  );
};

const messages = defineMessages({
  save: {
    defaultMessage: 'Save',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  yes: {
    defaultMessage: 'Yes',
  },
  no: {
    defaultMessage: 'No',
  },
});

export default injectIntl(FormModal);
