import { IntlShape } from 'react-intl';

import { MODAL_MODES } from 'src/utils/constants';
import { wizardMessages } from '../CustomersTable/constants';
import CustomerForm from './steps/CustomerForm';
import FacilitiesFieldMapping from './steps/FacilityMapping';
import EmrFrequencySettings from './steps/EmrFrequencySettings';

type StepsData = { key: number; title: string; content: React.FC }[];

export const getWizardSteps = (
  intl: IntlShape,
  modalMode: string,
): StepsData => [
  {
    key: 1,
    title:
      modalMode === MODAL_MODES.ADD
        ? intl.formatMessage(wizardMessages.addCustomer)
        : intl.formatMessage(wizardMessages.editCustomer),
    content: CustomerForm,
  },
  {
    key: 2,
    title: intl.formatMessage(wizardMessages.mapFacilities),
    content: FacilitiesFieldMapping,
  },
  {
    key: 3,
    title: intl.formatMessage(wizardMessages.emrUpdateFrequency),
    content: EmrFrequencySettings,
  },
];
