import { actions as groupActions } from 'src/redux/data/groups/modules/slice';
import { intl, messages } from '../messages';

export const groupActionsToErrorNotificationMessagesMap = {
  [groupActions.onCreateGroup.type]: (groupId, _) => ({
    message: intl.formatMessage(messages.createGroupError),
    description: `${intl.formatMessage(messages.createGroupError)} ${groupId}`,
  }),
  [groupActions.onDeleteGroup.type]: (groupId, _) => ({
    message: intl.formatMessage(messages.deleteGroupError),
    description: `${intl.formatMessage(messages.deleteGroupError)} ${groupId}`,
  }),
  [groupActions.onEditGroup.type]: (groupId, _) => ({
    message: intl.formatMessage(messages.editGroupError),
    description: `${intl.formatMessage(messages.editGroupError)} ${groupId}`,
  }),
  [groupActions.onUpdateGroupVersion.type]: (groupId, _) => ({
    message: intl.formatMessage(messages.updateVersionError),
    description: `${intl.formatMessage(
      messages.updateVersionError,
    )} ${groupId}`,
  }),
  [groupActions.onEditApp.type]: (appId, _) => ({
    message: intl.formatMessage(messages.editAppError),
    description: intl.formatMessage(messages.editAppError),
  }),
};

export const groupActionsToSuccessNotificationMessagesMap = {
  [groupActions.onCreateGroup.type]: (groupId, _) => ({
    message: intl.formatMessage(messages.createGroupSuccess),
  }),
  [groupActions.onDeleteGroup.type]: (groupId, _) => ({
    message: intl.formatMessage(messages.deleteGroupSuccess),
  }),
  [groupActions.onEditGroup.type]: (groupId, _) => ({
    message: intl.formatMessage(messages.editGroupSuccess),
  }),
  [groupActions.onUpdateGroupVersion.type]: (groupId, _) => ({
    message: intl.formatMessage(messages.updateVersionSuccess),
  }),
  [groupActions.onEditApp.type]: (appId, _) => ({
    message: intl.formatMessage(messages.editAppSuccess),
  }),
};
