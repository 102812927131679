import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import {
  DEFAULT_TIMEZONE_OFFSET,
  STANDARD_TIMEZONE_ID,
} from 'src/redux/data/loggedInUser/modules/constants';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(duration);

export const API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const US_FORMAT_WITHOUT_SECONDS = 'MM-DD-YYYY HH:mm';

export const nowUTC = () => dayjs().utc();
export const nowLocal = () => dayjs();
export const localTimezone = () => dayjs.tz.guess();
export const toDayJs = (dateTime: string) => dayjs(dateTime);
export const toUTC = (time: string | Dayjs) => dayjs(time).utc();
export const isInLast24Hours = (time: string, isUtc = true): boolean => {
  const utcTime = isUtc ? dayjs.utc(time) : toUTC(time);

  return utcTime.isAfter(nowUTC().subtract(1, 'd'));
};

export const toApiCallFormat = (time: string | Dayjs) =>
  toUTC(time).format(API_DATE_FORMAT);
export const toDisplayFormat = (time: string, format = DATE_TIME_FORMAT) =>
  dayjs(time).format(format);

export const getTimezoneId = (timeZoneId?: string | null) =>
  timeZoneId || STANDARD_TIMEZONE_ID;

export const getUtcTimezoneOffsetInMinutes = (
  utcOffsetString: string,
): number => {
  const sign = utcOffsetString.slice(0, 1);
  const offset = utcOffsetString.slice(1);
  const tz = dayjs(offset, 'HH:mm');
  const minutesOffset = tz.hour() * 60 + tz.minute();

  return sign === '+' ? minutesOffset : -minutesOffset;
};

export const parseUtcOffsetToHours = (timeZoneId: string | null): string => {
  if (!timeZoneId) {
    return DEFAULT_TIMEZONE_OFFSET as string;
  }

  const timezoneDateString = dayjs().tz(timeZoneId).format();

  return dayjs().tz(timeZoneId).format().slice(-6, timezoneDateString.length);
};

export const convertToTimezone = (
  dateTime: string | Dayjs,
  timezone: string | null | undefined = '',
  isUtc = true,
): dayjs.Dayjs => {
  let dateByTimezone;
  if (timezone) {
    dateByTimezone = isUtc
      ? dayjs.utc(dateTime).tz(timezone)
      : dayjs(dateTime).tz(timezone);
  } else {
    dateByTimezone = dayjs.utc(dateTime);
  }

  const tzUtcOffset = dateByTimezone.utcOffset();

  return isUtc
    ? dayjs.utc(dateTime).utcOffset(tzUtcOffset)
    : dayjs(dateTime).utcOffset(tzUtcOffset);
};

//* Get date and time in a specific timezone
//* Performs no time value conversion only dayjs timezone conversion
export const parseInTimezone = (dateTime: string | Dayjs, timeZone: string) => {
  const utcOffset = dayjs(dateTime).tz(timeZone).utcOffset();
  return utcOffset
    ? dayjs(dateTime).utcOffset(utcOffset, true)
    : dayjs(dateTime).utc(true);
};

//* Supports conversion only for:
//* - UTC time (if flag isUtc is true)
//* - Local Time (if flag isUtc is false)
export const displayInTimezone = (
  dateTime: string,
  timezone: string,
  format: string,
  isUtc = true,
): string => convertToTimezone(dateTime, timezone, isUtc).format(format);
