import { actions as authActions } from 'src/redux/data/auth/modules/slice';
import { tokenStatusEnum } from 'src/utils/constants';
import { intl, messages } from '../messages';

export const authErrorCodes = {
  TOKEN_NOT_VALID: 'TOKEN_NOT_VALID',
};

export const authActionToErrorNotificationMessagesMap = {
  [authActions.logout.type]: (_, errorCode) => {
    const notificationMessageForErrorCode = {
      [authErrorCodes.TOKEN_NOT_VALID]: {
        message: intl.formatMessage(messages.logoutErrorMessage),
        description: intl.formatMessage(messages.tokenErrorDescription),
      },
    };
    const notificationMessageForErrorCodeHandler = {
      get(mapTarget, error) {
        return (
          mapTarget[error] || {
            message: intl.formatMessage(messages.logoutErrorMessage),
            description: intl.formatMessage(messages.logoutErrorDescription),
          }
        );
      },
    };
    const proxyNotificationMessageForErrorCode = new Proxy(
      notificationMessageForErrorCode,
      notificationMessageForErrorCodeHandler,
    );
    return proxyNotificationMessageForErrorCode[errorCode];
  },
  [tokenStatusEnum.TOKEN_NOT_VALID]: (_, __) => ({
    message: intl.formatMessage(messages.refreshTokenErrorMessage),
    description: intl.formatMessage(messages.refreshTokenErrorDescription),
  }),
  [authActions.mfaLogin.type]: (_, __) => ({
    message: intl.formatMessage(messages.mfaValidationFailed),
    description: intl.formatMessage(messages.mfaValidationFailedDescription),
  }),
};

export const authActionToSuccessNotificationMessagesMap = {
  [authActions.resetPassword.type]: _ => ({
    message: intl.formatMessage(messages.resetPasswordSuccessMessage),
    description: intl.formatMessage(messages.resetPasswordSuccessDescription),
  }),
  [authActions.mfaResendCodeLogin.type]: _ => ({
    message: intl.formatMessage(messages.mfaResentCodeSuccessMessage),
    description: intl.formatMessage(messages.mfaResentCodeSuccessDescription),
  }),
};
