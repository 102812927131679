import React from 'react';
import { Checkbox, Form, Input } from 'antd';
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  IntlShape,
} from 'react-intl';

import { API_STATUS } from 'src/utils/api-constants';
import { apiStatusEnum, emailPattern } from 'src/utils/constants';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import PasswordLayout, {
  ADDITIONAL_CONTENT_POSITION,
} from 'src/components/PasswordForm/PasswordLayout';
import {
  CenteredContainer,
  FormItem,
  AuthButton,
  MessageArea,
  ErrorText,
  SmallNavigationLink,
} from '../components/styled';
import ConsentModal from '../Consent';

type Props = {
  email: string;
  onFinish: (values: {
    email: string;
    password: string;
    rememberMe: boolean;
  }) => void;
  loginStatus: API_STATUS | null;
  isConsentApiFailed: boolean;
  intl: IntlShape;
};

const LoginForm = ({
  email,
  onFinish,
  loginStatus,
  isConsentApiFailed,
  intl,
}: Props): JSX.Element => {
  const isLoading = loginStatus === apiStatusEnum.LOADING;
  const isLoginSuccess = loginStatus === apiStatusEnum.OK;

  return (
    <CenteredContainer>
      <PasswordLayout
        formComponent={
          <Form layout="vertical" onFinish={onFinish}>
            <FormItem
              data-cy="login-email-field"
              label={intl.formatMessage(messages.email)}
              name="email"
              initialValue={email}
              hasFeedback
              rules={[
                {
                  pattern: emailPattern,
                  required: true,
                  message: `${intl.formatMessage(messages.invalidEmail)}`,
                },
              ]}
            >
              <Input bordered={false} readOnly />
            </FormItem>

            <FormItem
              data-cy="login-password-field"
              label={intl.formatMessage(messages.password)}
              name="password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage(messages.requiredPassword)}`,
                },
              ]}
            >
              <Input.Password />
            </FormItem>

            <Form.Item name="rememberMe" valuePropName="checked">
              <Checkbox>
                <FormattedMessage {...messages.rememberMe} />
              </Checkbox>
            </Form.Item>

            <Form.Item data-cy="login-submit-btn">
              <AuthButton isLoading={!!loginStatus && isLoading}>
                <FormattedMessage {...messages.submit} />
              </AuthButton>
            </Form.Item>
            <MessageArea>
              {loginStatus &&
                !isLoginSuccess &&
                !isLoading &&
                !isConsentApiFailed && (
                  <ErrorText data-cy="login-error-text">
                    <FormattedMessage {...messages.errorText} />
                  </ErrorText>
                )}

              {isConsentApiFailed && (
                <ErrorText>
                  <FormattedMessage {...messages.consentError} />
                </ErrorText>
              )}
            </MessageArea>
          </Form>
        }
        additionalContentComponent={
          <SmallNavigationLink
            to={`${appRoutes.FORGOT_PASSWORD}`}
            data-cy="login-forgot-password-btn"
          >
            <FormattedMessage {...messages.forgotPassword} />
          </SmallNavigationLink>
        }
        additionalContentPosition={ADDITIONAL_CONTENT_POSITION.END}
        innerTitle={messages.innerTitle}
      />
      <ConsentModal />
    </CenteredContainer>
  );
};

const messages = defineMessages({
  email: {
    defaultMessage: 'Email',
  },
  password: {
    defaultMessage: 'Password',
  },
  errorText: {
    defaultMessage: 'Could not login - wrong email or password',
  },
  invalidEmail: {
    defaultMessage: 'Invalid email address',
  },
  requiredPassword: {
    defaultMessage: 'Password is required',
  },
  rememberMe: {
    defaultMessage: 'Remember me for 12 hours',
  },
  forgotPassword: {
    defaultMessage: 'Forgot your password?',
  },
  submit: {
    defaultMessage: 'Login',
  },
  consentError: {
    defaultMessage: 'unable to load consents, please login again ',
  },
  innerTitle: {
    defaultMessage:
      'To access patient details, please enter your Neteera HealthGate password',
  },
});

export default injectIntl(LoginForm);
