import React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

import { specialMeasurementValues } from 'src/redux/data/monitor/modules/constants';
import {
  displayMeasurement,
  displaySpotMeasurement,
} from 'src/redux/data/monitor/modules/utils';
import { MeasurementType } from 'src/redux/data/monitor/modules/types';
import {
  Card,
  CardTitle,
  StyledMeasurementCard,
  MeasurementLogo,
  MeasurementWrapper,
  MeasurementGroup,
  MeasurementValue,
  MeasurementUnit,
} from './styled';
import { isDeviceGatheringData } from 'src/routes/NursesStation/components/RoomsAndBeds/DeviceMeasurements/utils';
import { PatientState } from 'src/types/measurements';
import { StyledGatherInfoIcon } from 'src/components/PatientStatusComponent/styled';

type Props = {
  key: string;
  title: string;
  unit: string;
  logoSrc: string;
  measurement: string | undefined;
  spotMeasurement: string | null | undefined;
  quality: boolean | undefined;
  patientStateValue: PatientState | null | undefined;
  shouldDisplayHighQualityOnly: boolean;
  measurementType: MeasurementType;
  intl: IntlShape;
};

const MeasurementCard = ({
  title,
  measurement,
  unit,
  quality,
  logoSrc,
  shouldDisplayHighQualityOnly,
  intl,
  measurementType,
  spotMeasurement,
  patientStateValue,
}: Props): JSX.Element => {
  const isDataUnreliable =
    measurement === specialMeasurementValues.DATA_UNRELIABLE ||
    (shouldDisplayHighQualityOnly && !quality);
  const isSpotDataUnreliable =
    spotMeasurement === specialMeasurementValues.DATA_UNRELIABLE;

  const renderSpotCard = () => (
    <Card>
      <MeasurementLogo src={logoSrc} alt={title} />
      <MeasurementWrapper>
        <CardTitle>
          {title} {intl.formatMessage(messages.spot)}
        </CardTitle>
        <MeasurementGroup>
          <MeasurementValue
            isSmallText={isSpotDataUnreliable}
            isHighQuality={quality}
          >
            {isDeviceGatheringData(
              measurement,
              !!quality,
              patientStateValue,
              shouldDisplayHighQualityOnly,
            ) ? (
              <StyledGatherInfoIcon type={'gathering-data-icon'} />
            ) : (
              displaySpotMeasurement(spotMeasurement || '', intl)
            )}
          </MeasurementValue>
          <MeasurementUnit
            isHidden={isSpotDataUnreliable || spotMeasurement === null}
          >
            {unit}
          </MeasurementUnit>
        </MeasurementGroup>
      </MeasurementWrapper>
    </Card>
  );

  const renderContinuousCard = () => (
    <Card>
      <MeasurementLogo src={logoSrc} alt={title} />
      <MeasurementWrapper>
        <CardTitle>{title}</CardTitle>
        <MeasurementGroup>
          <MeasurementValue isHighQuality={quality}>
            {isDeviceGatheringData(
              measurement,
              !!quality,
              patientStateValue,
              shouldDisplayHighQualityOnly,
            ) ? (
              <StyledGatherInfoIcon type={'gathering-data-icon'} />
            ) : (
              displayMeasurement(
                measurement || '',
                !!quality,
                shouldDisplayHighQualityOnly,
                intl,
                measurementType,
              )
            )}
          </MeasurementValue>
          <MeasurementUnit isHidden={isDataUnreliable || measurement === null}>
            {unit}
          </MeasurementUnit>
        </MeasurementGroup>
      </MeasurementWrapper>
    </Card>
  );
  return (
    <StyledMeasurementCard>
      {spotMeasurement ? renderSpotCard() : renderContinuousCard()}
    </StyledMeasurementCard>
  );
};

const messages = defineMessages({
  spot: {
    defaultMessage: 'Spot',
  },
});

export default injectIntl(MeasurementCard);
