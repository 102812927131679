import { useEffect, useState } from 'react';
import { ALL_TENANTS_OPTION } from 'src/routes/GMDashboard/modules/constants';
import {
  SubtenantItem,
  TenantSubscriptionDetails,
} from 'src/routes/GMDashboard/modules/types';
import { DataTenantDict } from 'src/redux/data/groupManager/modules/types';
import { getGMSubtenantSubscriptionDetails } from 'src/routes/GMDashboard/modules/settingsUtils';
import { UUID } from 'src/types/utility';
import { Subscriber } from 'src/types/subscribers';

type Props = {
  gmSubscriptionDetails: TenantSubscriptionDetails;
  selectedSubtenants: SubtenantItem[];
  gmUserId?: UUID;
  allSubscribers: Subscriber[];
  subscribersDict: DataTenantDict;
};

export const useSubscriptions = ({
  gmSubscriptionDetails,
  selectedSubtenants,
  gmUserId,
  allSubscribers,
  subscribersDict,
}: Props) => {
  const [subscriptionDetails, setSubscriptionDetails] =
    useState<TenantSubscriptionDetails>(gmSubscriptionDetails);

  useEffect(() => {
    if (selectedSubtenants.length === 1) {
      const selectedTenantId = selectedSubtenants[0]?.value;

      const selectedSubscriptionDetails =
        !selectedTenantId || selectedTenantId === ALL_TENANTS_OPTION
          ? gmSubscriptionDetails
          : getGMSubtenantSubscriptionDetails(
              gmUserId ?? '',
              selectedTenantId,
              allSubscribers,
              subscribersDict,
            );

      setSubscriptionDetails(selectedSubscriptionDetails);

      return;
    }

    setSubscriptionDetails(gmSubscriptionDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubtenants]);

  return {
    subscriptionDetails,
    setSubscriptionDetails,
  };
};
