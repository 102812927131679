import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { UUID } from 'src/types/utility';
import {
  selectors as alertsSelectors,
  selectors as alertSelectors,
} from 'src/redux/data/alerts';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { selectors as subscriberSelectors } from 'src/redux/data/subscribers';
import {
  actions as gmActions,
  selectors as gmSelectors,
} from 'src/redux/data/groupManager';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import {
  getGMSubtenantSubscriptionDetails,
  getSubtenantBaselineThresholds,
  getSubtenantThresholds,
} from 'src/routes/GMDashboard/modules/settingsUtils';
import { ErrorState } from '../types';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';

type OwnProps = {
  subtenantId: UUID;
  setOnSave: (onSave: () => void) => void;
  setErrors: React.Dispatch<React.SetStateAction<ErrorState>>;
};

const mapStateToProps = (state: RootState, { subtenantId }: OwnProps) => {
  const gmUserId = loggedInUserSelectors.getUserId(state);
  const allThresholds = alertSelectors.selectThresholds(state);
  const allBaselineThresholds = alertSelectors.selectBaselineThresholds(state);
  const allSubscribers = subscriberSelectors.selectSubscribersList(state);
  const thresholdsDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.ALERT_THRESHOLDS,
  );
  const baselineThresholdsDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.BASELINE_ALERT_THRESHOLDS,
  );
  const subscribersDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.SUBSCRIBERS,
  );
  const subtenantThresholds = getSubtenantThresholds(
    subtenantId,
    allThresholds,
    thresholdsDict,
  );
  const subtenantBaselineThresholds = getSubtenantBaselineThresholds(
    subtenantId,
    allBaselineThresholds,
    baselineThresholdsDict,
  );
  const gmSubtenantSubscriptionDetails = getGMSubtenantSubscriptionDetails(
    gmUserId ?? '',
    subtenantId,
    allSubscribers,
    subscribersDict,
  );
  const subtenantSubscribers = allSubscribers.filter(
    s => subscribersDict[s.id] === subtenantId,
  );

  const isDataLoading =
    alertsSelectors.getThresholdsStatus(state) ===
      DATA_FETCHING_STATUS.LOADING ||
    alertsSelectors.getBaselineThresholdsStatus(state) ===
      DATA_FETCHING_STATUS.LOADING ||
    subscriberSelectors.getSubscribersStatus(state) ===
      DATA_FETCHING_STATUS.LOADING;

  return {
    subtenantThresholds,
    subtenantBaselineThresholds,
    gmSubtenantSubscriptionDetails,
    subtenantSubscribers,
    gmUserId,
    isDataLoading,
  };
};

const mapActionCreators = {
  fetchSubtenantData: gmActions.fetchSubtenantData,
  createSubtenantAlertThresholds: gmActions.createSubtenantAlertThresholds,
  editSubtenantAlertThresholds: gmActions.editSubtenantAlertThresholds,
  createSubtenantBaselineAlertThresholds:
    gmActions.createSubtenantBaselineAlertThresholds,
  editSubtenantBaselineAlertThresholds:
    gmActions.editSubtenantBaselineAlertThresholds,
  updateSubtenantSubscribersList: gmActions.updateSubtenantSubscribersList,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type Props = OwnProps & PropsFromRedux;

export default Connector;
