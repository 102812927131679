import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { MODAL_STATUS } from 'src/components/Modal/constants';
import { RootState } from 'src/redux/store';
import { ListTenant } from 'src/types/tenants';
import {
  MetaDataType,
  PageMetaDataType,
  SortableFields,
  SortOrder,
  UUID,
} from 'src/types/utility';
import { DATA_STATE_KEY, DATA_FETCHING_STATUS } from '../../constants';
import { FetchMTMSubtenantsAlertCounters } from 'src/services/types';

export const STATE_KEY = 'tenant';

export const INITIAL_STATE: {
  tenantsList: ListTenant[];
  tenantsAlertCounters: {
    data: FetchMTMSubtenantsAlertCounters;
    status: keyof typeof DATA_FETCHING_STATUS | null;
  };
  paginatedTenants: {
    data: ListTenant[];
    pageMetadata: PageMetaDataType;
  };
  tenantsListMetadata: MetaDataType;
  tenantsSearchResults: {
    data: ListTenant[];
    status: keyof typeof DATA_FETCHING_STATUS | null;
  };
  status: keyof typeof DATA_FETCHING_STATUS;
  modalStatus: keyof typeof MODAL_STATUS;
} = {
  tenantsList: [],
  tenantsAlertCounters: {
    data: {
      tenants: {},
      totals: {
        currentAlertsCount: 0,
        attentionListAlertsCount: 0,
        onStatusAlertsCount: 0,
      },
    },
    status: null,
  },
  paginatedTenants: {
    data: [],
    pageMetadata: {
      totalResults: 0,
      page: 0,
      limit: 0,
    },
  },
  tenantsListMetadata: {
    page: {
      totalResults: 0,
      page: 0,
      limit: 0,
    },
    sort: [
      {
        order: SortOrder.desc,
        prop: SortableFields.creationTime,
      },
    ],
  },
  tenantsSearchResults: {
    data: [],
    status: null,
  },
  status: DATA_FETCHING_STATUS.LOADING,
  modalStatus: MODAL_STATUS.INITIAL,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setTenantsList(state, action: PayloadAction<ListTenant[]>) {
      state.tenantsList = action.payload;
    },
    setTenantsListMetadata(state, action: PayloadAction<MetaDataType>) {
      state.tenantsListMetadata = { ...action.payload };
    },
    setTenantsAlertCounters(
      state,
      action: {
        payload: FetchMTMSubtenantsAlertCounters;
      },
    ) {
      state.tenantsAlertCounters.data = action.payload;
    },
    setPaginatedTenants(
      state,
      action: PayloadAction<{
        data: ListTenant[];
        pageMetadata: PageMetaDataType;
      }>,
    ) {
      state.paginatedTenants = { ...action.payload };
    },
    setSearchedTenants(
      state,
      action: {
        payload: {
          data: ListTenant[];
          status: keyof typeof DATA_FETCHING_STATUS | null;
        };
      },
    ) {
      state.tenantsSearchResults = { ...action.payload };
    },
    setStatus(state, action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>) {
      state.status = action.payload;
    },
    setTenantsAlertCountersStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.tenantsAlertCounters.status = action.payload;
    },
    setModalStatus(state, action: PayloadAction<keyof typeof MODAL_STATUS>) {
      state.modalStatus = action.payload;
    },
  },
  extraReducers: {},
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

const getTenantId = (_state: RootState, tenantId: UUID) => tenantId;

export const selectors = {
  getTenantsList: createSelector(getState, state => state.tenantsList),
  getTenantsAlertCounters: createSelector(
    getState,
    state => state.tenantsAlertCounters.data,
  ),
  getTenantsListMetadata: createSelector(
    getState,
    state => state.tenantsListMetadata,
  ),
  getPaginatedTenants: createSelector(
    getState,
    state => state.paginatedTenants,
  ),
  getSearchResults: createSelector(
    getState,
    state => state.tenantsSearchResults.data,
  ),
  isLoadingSearch: createSelector(
    getState,
    state => state.tenantsSearchResults.status === DATA_FETCHING_STATUS.LOADING,
  ),
  getTenant: createSelector(getState, getTenantId, (state, tenantId) =>
    state.tenantsList.find(t => t.id === tenantId),
  ),
  getStatus: createSelector(getState, state => state.status),
  getModalStatus: createSelector(getState, state => state.modalStatus),
};

const extraActions = {
  getTenantsList: createAction(`${STATE_KEY}/getTenantsList`),
  getPaginatedTenants: createAction<PageMetaDataType>(
    `${STATE_KEY}/getPaginatedTenants`,
  ),
  searchTenants: createAction<string>(`${STATE_KEY}/searchTenants`),
  getSubtenantsList: createAction<UUID>(`${STATE_KEY}/getSubtenantsList`),
  getSubtenantsAlertCounters: createAction(
    `${STATE_KEY}/getSubtenantsAlertCounters`,
  ),
  deleteTenant: createAction<UUID>(`${STATE_KEY}/deleteTenant`),
  createTenant: createAction(`${STATE_KEY}/createTenant`),
  editTenant: createAction(`${STATE_KEY}/editTenant`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
