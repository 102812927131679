import { actions as alertsActions } from 'src/redux/data/alerts/modules/slice';
import { intl, messages } from '../messages';

export const alertsActionsToErrorNotificationMessagesMap = {
  [alertsActions.createAlert.type]: (quality, _) => ({
    message: intl.formatMessage(messages.settingsError),
    description: `${intl.formatMessage(messages.createAlertsError)} ${quality}`,
  }),
  [alertsActions.editAlert.type]: (alertsLevel, _) => ({
    message: intl.formatMessage(messages.settingsError),
    description: `${intl.formatMessage(messages.editAlertsError)}`,
  }),
};

export const alertsActionsToSuccessNotificationMessagesMap = {
  [alertsActions.createAlert.type]: (groupId, _) => ({
    message: intl.formatMessage(messages.createAlertsSuccess),
  }),
  [alertsActions.editAlert.type]: (data, _) => ({
    message: intl.formatMessage(messages.editAlertsSuccess),
  }),
  // TODO: Update later
  // [alertsActions.updateSubscribers.type]: (data, _) => ({
  //   message: intl.formatMessage(messages.updateSMSEmailAlertSettingsSuccess),
  // }),
};
