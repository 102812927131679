import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import AppLayout from 'src/components/AppLayout';

function PrivateRoute({ component: Component, authed, premission, ...rest }) {
  const {
    computedMatch: {
      params: { locale },
    },
  } = rest;
  const _renderComponent = props => {
    if (!authed) {
      return (
        <Redirect
          to={{ pathname: `/${locale}/login`, state: { from: props.location } }}
        />
      );
    }

    if (!premission) {
      return (
        <Redirect
          to={{ pathname: `/${locale}/home`, state: { from: props.location } }}
        />
      );
    }

    return (
      <AppLayout>
        <Component {...props} />
      </AppLayout>
    );
  };

  return <Route {...rest} render={_renderComponent} />;
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
  location: PropTypes.object,
  authed: PropTypes.bool,
  premission: PropTypes.bool.isRequired,
};

PrivateRoute.defaultProps = {
  authed: false,
  location: {},
};

export default PrivateRoute;
