import React from 'react';
import {
  VersionPanelWrap,
  Box,
  VersionLabel,
  VersionValue,
} from './latestVersionPane.styled';
import { FormattedMessage, defineMessages } from 'react-intl';
import Loader from 'src/components/general-ui/Loader';

type Props = {
  latestVersions: { name: string; deviceType: string }[];
  isLoadingLatestVersion: boolean;
};

const LatestVersionsPanel = ({
  latestVersions,
  isLoadingLatestVersion,
}: Props) => (
  <VersionPanelWrap>
    <FormattedMessage {...messages.title} />
    <Box>
      {isLoadingLatestVersion && <Loader />}
      {latestVersions
        ? latestVersions.map(version => (
            <span key={version.name}>
              <VersionLabel>{version.deviceType}</VersionLabel>
              <VersionValue>{version.name}</VersionValue>
            </span>
          ))
        : '-'}
    </Box>
  </VersionPanelWrap>
);

const messages = defineMessages({
  title: {
    defaultMessage: 'Latest Versions',
  },
});

export default LatestVersionsPanel;
