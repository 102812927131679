import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import { InputNumber, Checkbox, Tooltip } from 'antd';

import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';
import {
  HR_DEFAULT_THRESHOLDS,
  RR_DEFAULT_THRESHOLDS,
  THRESHOLDS_REFFERENCES,
  VALIDATION_ERROR_IDS,
} from './constants';
import {
  BoxTitle,
  AlertBox,
  ThresholdWrapper,
  ButtonsWrapper,
  AlertButton,
  ThresholdsBox,
  TreshholdsValues,
} from './styled';

const AlertThresholdSettingsCard = ({
  title,
  metric,
  belowThreshold,
  aboveThreshold,
  intl,
}) => {
  const [above, setAbove] = useState({
    enable: aboveThreshold.enable,
    value: aboveThreshold.value,
  });
  const [below, setBelow] = useState({
    enable: belowThreshold.enable,
    value: belowThreshold.value,
  });
  const [validationError, setValidationError] = useState(null);
  const [tresholdErrorOrigin, setTresholdErrorOrigin] = useState(null);
  const DEFAULT_THRESHOLDS =
    metric === ALERT_METRIC_ENUM.HR
      ? HR_DEFAULT_THRESHOLDS
      : RR_DEFAULT_THRESHOLDS;

  useEffect(() => {
    setAbove({ ...aboveThreshold });
  }, [aboveThreshold]);

  useEffect(() => {
    setBelow({ ...belowThreshold });
  }, [belowThreshold]);

  const clearErrorValidation = () => {
    setValidationError(null);
    setTresholdErrorOrigin(null);
  };

  const onCancel = () => {
    setBelow({ ...belowThreshold });
    setAbove({ ...aboveThreshold });
    clearErrorValidation();
  };
  const onApply = () => {
    if (
      (DEFAULT_THRESHOLDS.MIN > below.value ||
        below.value > DEFAULT_THRESHOLDS.MAX) &&
      below.enable
    ) {
      setTresholdErrorOrigin(THRESHOLDS_REFFERENCES.BELOW);
      setValidationError(VALIDATION_ERROR_IDS.VALUE_EXCEEDED);
      return;
    }
    if (
      (DEFAULT_THRESHOLDS.MIN > above.value ||
        above.value > DEFAULT_THRESHOLDS.MAX) &&
      above.enable
    ) {
      setTresholdErrorOrigin(THRESHOLDS_REFFERENCES.ABOVE);
      setValidationError(VALIDATION_ERROR_IDS.VALUE_EXCEEDED);
      return;
    }
    if (below.value === above.value && below.enable && above.enable) {
      setTresholdErrorOrigin(THRESHOLDS_REFFERENCES.ABOVE);
      setTresholdErrorOrigin(THRESHOLDS_REFFERENCES.BELOW);
      setValidationError(VALIDATION_ERROR_IDS.MIN_GAP);
      return;
    }
    if (below.value > above.value && below.enable && above.enable) {
      setTresholdErrorOrigin(THRESHOLDS_REFFERENCES.BELOW);
      setValidationError(VALIDATION_ERROR_IDS.BELOW_AND_ABOVE_REVERSED);
      return;
    }
    belowThreshold.updateAlert({
      id: belowThreshold?.id,
      metric,
      preposition: belowThreshold.preposition,
      value: below.value,
      enable: below.enable,
    });
    aboveThreshold.updateAlert({
      id: aboveThreshold?.id,
      metric,
      preposition: aboveThreshold.preposition,
      value: above.value,
      enable: above.enable,
    });
  };

  return (
    <AlertBox>
      <BoxTitle> {title} </BoxTitle>
      <ThresholdsBox>
        <TreshholdsValues>
          <FormattedMessage {...messages.allowedRange} />
        </TreshholdsValues>
        <TreshholdsValues>
          [{DEFAULT_THRESHOLDS.MIN} - {DEFAULT_THRESHOLDS.MAX}]
        </TreshholdsValues>
      </ThresholdsBox>
      <ThresholdWrapper>
        <Checkbox
          checked={below.enable}
          onChange={() => {
            clearErrorValidation();
            setBelow({ ...below, enable: !below.enable });
          }}
        >
          <FormattedMessage {...messages.below} />
        </Checkbox>
        <Tooltip
          title={
            validationError && intl.formatMessage(messages[validationError])
          }
          open={
            !!validationError &&
            tresholdErrorOrigin === THRESHOLDS_REFFERENCES.BELOW
          }
          placement="left"
        >
          <InputNumber
            value={below.value}
            disabled={!below.enable}
            onChange={value => {
              clearErrorValidation();
              setBelow({ ...below, value });
            }}
          />
        </Tooltip>
      </ThresholdWrapper>
      <ThresholdWrapper>
        <Checkbox
          checked={above.enable}
          onChange={() => {
            clearErrorValidation();
            setAbove({ ...above, enable: !above.enable });
          }}
        >
          <FormattedMessage {...messages.above} />
        </Checkbox>
        <Tooltip
          title={
            validationError && intl.formatMessage(messages[validationError])
          }
          open={
            !!validationError &&
            tresholdErrorOrigin === THRESHOLDS_REFFERENCES.ABOVE
          }
          placement="left"
        >
          <InputNumber
            value={above.value}
            disabled={!above.enable}
            onChange={value => {
              clearErrorValidation();
              setAbove({ ...above, value });
            }}
          />
        </Tooltip>
      </ThresholdWrapper>

      <ButtonsWrapper>
        <AlertButton onClick={onCancel}>
          <FormattedMessage {...messages.cancel} />
        </AlertButton>
        <AlertButton onClick={onApply}>
          <FormattedMessage {...messages.apply} />
        </AlertButton>
      </ButtonsWrapper>
    </AlertBox>
  );
};
const messages = defineMessages({
  below: {
    defaultMessage: 'Below',
  },
  above: {
    defaultMessage: 'Above',
  },
  apply: {
    defaultMessage: 'Apply',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  allowedRange: {
    defaultMessage: 'Allowed range:',
  },
  minGap: {
    defaultMessage: 'The minimum gap between thresholds is 1',
  },
  valueExceeded: {
    defaultMessage: 'The value is out of valid range',
  },
  belowAndAboveReversed: {
    defaultMessage: 'Below value cannot be higher than above value',
  },
});

AlertThresholdSettingsCard.propTypes = {
  intl: PropTypes.object.isRequired,
  belowThreshold: PropTypes.object.isRequired,
  aboveThreshold: PropTypes.object.isRequired,
  metric: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default withTheme(injectIntl(AlertThresholdSettingsCard));
