import React from 'react';

import { IconType } from 'src/components/general-ui/Icon';
import {
  PatientStatusWrap,
  StyledIcon,
  StyledBellIconOutlined,
  StyledAlertIndicator,
} from './styled';
import { checkPatientHasAlert } from 'src/routes/NursesStation/components/RoomsAndBeds/DeviceCard/utils';
import { DeviceAlerts } from 'src/routes/NursesStation/modules/types';

type Props = {
  isDeviceConnected: boolean;
  isUnoccupied: boolean;
  isVacancyAlertActive: boolean;
  isLongOutOfBedAlertActive?: boolean;
  ongoingDeviceAlerts: DeviceAlerts;
  itemsAlignment?: 'row' | 'column';
};

const getIconColors = (
  hasActiveAlert: boolean,
  hasSuppressedAlert: boolean,
): { fillColor: string } => {
  if (hasActiveAlert) {
    return {
      fillColor: '#CA0000',
    };
  }

  if (hasSuppressedAlert) {
    return {
      fillColor: '#FFAD0D',
    };
  }

  return {
    fillColor: '#252525',
  };
};

const PatientStatusComponent = ({
  isUnoccupied,
  isDeviceConnected,
  ongoingDeviceAlerts,
  isVacancyAlertActive,
  isLongOutOfBedAlertActive = false,
  itemsAlignment = 'column',
}: Props) => {
  const { bedTimeBaseline, bedExitFrequency } = ongoingDeviceAlerts;
  const hasActiveAlert = checkPatientHasAlert(
    'ON',
    isUnoccupied,
  )(ongoingDeviceAlerts);

  const hasSuppressedAlert = checkPatientHasAlert(
    'SUPPRESSED',
    true,
  )(ongoingDeviceAlerts);

  const { fillColor } = getIconColors(hasActiveAlert, hasSuppressedAlert);

  const getPatientIcon = (isUnoccupied: boolean): IconType[] => {
    const arrayIcons = [];

    isUnoccupied || isLongOutOfBedAlertActive
      ? arrayIcons.push('bed-icon')
      : arrayIcons.push('patient-in-bed-icon');

    if (bedExitFrequency) {
      arrayIcons.push('bed-exit-frequency-icon');
    }

    return arrayIcons as IconType[];
  };

  const alertValue = bedExitFrequency
    ? bedExitFrequency?.triggerValue
    : `${bedTimeBaseline?.triggerValue ?? '-'}%`;

  return (
    <PatientStatusWrap itemsAlignment={itemsAlignment}>
      {getPatientIcon(isUnoccupied).map(icon => (
        <div key={icon}>
          <StyledIcon key={icon} type={icon} color={fillColor} />
          {icon === 'bed-exit-frequency-icon' && (
            <StyledAlertIndicator color={fillColor}>
              {alertValue}
            </StyledAlertIndicator>
          )}
        </div>
      ))}
      {isVacancyAlertActive && (
        <StyledBellIconOutlined
          opacity={isDeviceConnected ? '1' : '0.5'}
          type="bell-icon-filled"
          color="#63B7C3"
        />
      )}
    </PatientStatusWrap>
  );
};

export default PatientStatusComponent;
