import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { CreateNotePayload } from 'src/services/types';
import { Note, NoteCounterMetadata } from 'src/types/notes';
import { UUID } from 'src/types/utility';
import { API_STATUS } from 'src/utils/api-constants';
import { DATA_STATE_KEY } from '../../constants';
import { mergeArraysWithUniqueIds } from '../../dataUtils';

export const STATE_KEY = 'notes';

export const INITIAL_STATE: {
  notes: {
    data: Record<UUID, Note[]>;
    metadata: {
      counters: Record<UUID, NoteCounterMetadata>;
    };
    status: API_STATUS;
  };
} = {
  notes: {
    data: {},
    metadata: {
      counters: {},
    },
    status: API_STATUS.LOADING,
  },
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    fetchPatientNotesSuccess: (
      state,
      action: PayloadAction<{ patientId: UUID; notes: Note[] }>,
    ) => {
      const { patientId, notes } = action.payload;

      state.notes.data[patientId] = mergeArraysWithUniqueIds(
        state.notes.data[patientId] || [],
        notes,
      );
      state.notes.status = API_STATUS.OK;
    },
    fetchPatientsNoteCounterMetadataSuccess: (
      state,
      action: PayloadAction<Record<UUID, NoteCounterMetadata>>,
    ) => {
      state.notes.metadata.counters = {
        ...state.notes.metadata.counters,
        ...action.payload,
      };
    },
    fetchPatientNotesFailed: state => {
      state.notes.status = API_STATUS.ERROR;
    },
    createNoteSuccess: (state, action: PayloadAction<Note>) => {
      const newNote = action.payload;
      const { patientId } = newNote;
      const oldNotes = state.notes.data[newNote.patientId] || [];

      state.notes.data[patientId] = mergeArraysWithUniqueIds(oldNotes, [
        newNote,
      ]);

      state.notes.status = API_STATUS.OK;
    },
    createNoteFailed: state => {
      state.notes.status = API_STATUS.ERROR;
    },
  },
  extraReducers: {},
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

const getPatientId = (_state: RootState, patientId: UUID) => patientId;

const getNoteId = (_state: RootState, _patientId: UUID, noteId: UUID) => noteId;

export const selectors = {
  selectPatientNotes: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state.notes.data[patientId] || [],
  ),
  selectPatientNote: createSelector(
    getState,
    getPatientId,
    getNoteId,
    (state, patientId, noteId) =>
      (state.notes.data[patientId] || []).find(n => n.id === noteId),
  ),
};

const extraActions = {
  fetchPatientNotes: createAction<UUID>(`${STATE_KEY}/fetchPatientNotes`),
  fetchPatientsNoteCounterMetadata: createAction<UUID[]>(
    `${STATE_KEY}/fetchPatientsNoteCounterMetadata`,
  ),
  createNote: createAction<CreateNotePayload>(`${STATE_KEY}/createNote`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
