import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import { ThemeProvider } from 'styled-components';

import theme from 'src/themes/theme';
import { customTheme } from 'src/themes/customTheme';
import history from 'src/utils/history';
import PrivateRoute from '../containers/privateRouteContainer';
import AuthRoute from './AuthRoute';

import RedirectToDefaultLocale from './RedirectToDefaultLocale';
import RedirectToSupportedLocale from './RedirectToSupportedLocale';
import { appRoutes } from '../modules/routesUtils';
import {
  getSupportedLocalesRegularExp,
  DEFAULT_LOCALE,
} from '../../../utils/languageUtils';

import MonitorPage from '../../Monitor';
import TenantsPage from '../../Tenants';
import DevicesPage from '../../Devices';
import PatientsPage from '../../Patients';
import UsersPage from '../../Users';
import ManagmentPage from '../../Managment';
import NursesStationPage from '../../NursesStation';
import IndividualDashboard from '../../IndividualDashboard';

import ControlSMSEmailAlerts from 'src/routes/Auth/ControlSMSEmailAlerts';

import SettingsPage from '../../Settings';
import Login from '../../Auth/Login';
import Groups from '../../FirmwareManager/Groups';
import Versions from '../../FirmwareManager/Versions';
import ForgotPassword from '../../Auth/ForgotPassword';
import ResetPassword from '../../Auth/ResetPassword';
import AcceptInvitation from '../../Auth/AcceptInvitation';

import { AppWrapper, SpinBox } from './styled';
import GlobalStyle from '../../../global-styles';
import LanguageProvider from '../../../components/LanguageProvider';
import Sidebar from 'src/routes/Sidebar';
import GMDashboardPage from 'src/routes/GMDashboard';
import GMSettingsPage from 'src/routes/GMSettings';
import CustomersPage from 'src/routes/Customers';
import RedirectToLandingPage from './RedictToLandingPage';
import PersonalAlertDetails from 'src/routes/Auth/PersonalAlertDetails';

const Root = props => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onAppStart, []);

  function onAppStart() {
    props.onAppStart();
  }

  const {
    isLoggedIn,
    isAppLoading,
    monitorPremission,
    devicesPremission,
    tenantsPremission,
    customersPremission,
    patientsPremission,
    usersPremission,
    firmwareManagerPermission,
    managmentPermission,
    medicalInfoPermissions,
    // individualDashboardPermissions,
    settingsPermission,
    gmPermissions,
    gmUserPermissions,
  } = props;
  // TODO: fix permitted redirect!!!
  const _appRoutesSwitch = () => (
    <LanguageProvider>
      <Router history={history}>
        <Switch>
          <AuthRoute path={`/:locale/${appRoutes.LOGIN}`} component={Login} />
          <AuthRoute
            path={`/:locale/${appRoutes.FORGOT_PASSWORD}`}
            component={ForgotPassword}
          />
          <AuthRoute
            path={`/:locale/${appRoutes.SET_PASSWORD}`}
            component={ResetPassword}
          />
          <AuthRoute
            path={`/:locale/${appRoutes.ACCEPT_INVITATION}`}
            component={AcceptInvitation}
          />
          <AuthRoute
            exact
            path={`/:locale/${appRoutes.SUPPRESS_ALERTS}`}
            component={ControlSMSEmailAlerts}
          />
          <AuthRoute
            path={`/:locale/${appRoutes.PERSONAL_ALERT_DETAILS}`}
            component={PersonalAlertDetails}
            displayLayoutTitle={false}
          />
          <PrivateRoute
            authed={isLoggedIn}
            premission={devicesPremission}
            exact
            path={`/:locale/${appRoutes.DEVICES}`}
            component={DevicesPage}
          />
          <PrivateRoute
            authed={isLoggedIn}
            premission={tenantsPremission}
            exact
            path={`/:locale/${appRoutes.TENANTS}`}
            component={TenantsPage}
          />
          <PrivateRoute
            authed={isLoggedIn}
            premission={customersPremission}
            exact
            path={`/:locale/${appRoutes.CUSTOMERS}`}
            component={CustomersPage}
          />
          <PrivateRoute
            authed={isLoggedIn}
            premission={monitorPremission}
            exact
            path={`/:locale/${appRoutes.MONITOR}/:id`}
            component={MonitorPage}
          />
          <PrivateRoute
            authed={isLoggedIn}
            premission={patientsPremission}
            exact
            path={`/:locale/${appRoutes.PATIENTS}`}
            component={PatientsPage}
          />
          <PrivateRoute
            authed={isLoggedIn}
            premission={usersPremission}
            exact
            path={`/:locale/${appRoutes.USERS}`}
            component={UsersPage}
          />
          <PrivateRoute
            authed={isLoggedIn}
            premission={firmwareManagerPermission}
            exact
            path={`/:locale/${appRoutes.GROUPS}`}
            component={Groups}
          />
          <PrivateRoute
            authed={isLoggedIn}
            premission={firmwareManagerPermission}
            exact
            path={`/:locale/${appRoutes.VERSIONS}`}
            component={Versions}
          />
          <PrivateRoute
            authed={isLoggedIn}
            premission={managmentPermission}
            exact
            path={`/:locale/${appRoutes.MANAGEMENT}`}
            component={ManagmentPage}
          />
          <PrivateRoute
            authed={isLoggedIn}
            premission={medicalInfoPermissions}
            exact
            path={`/:locale/${appRoutes.NURSES_STATION}`}
            component={NursesStationPage}
          />
          <PrivateRoute
            authed={isLoggedIn}
            //TO DO: to replace with permissions when included in UM DB
            premission={medicalInfoPermissions}
            exact
            path={`/:locale/${appRoutes.INDIVIDUAL_DASHBOARD}`}
            component={IndividualDashboard}
          />
          <PrivateRoute
            authed={isLoggedIn}
            premission={settingsPermission}
            exact
            path={`/:locale/${appRoutes.SETTINGS}`}
            component={SettingsPage}
          />
          <PrivateRoute
            authed={isLoggedIn}
            premission={gmPermissions}
            exact
            path={`/:locale/${appRoutes.DASHBOARD}`}
            component={GMDashboardPage}
          />
          <PrivateRoute
            authed={isLoggedIn}
            premission={gmUserPermissions}
            exact
            path={`/:locale/${appRoutes.GROUP_MANAGER_USERS}`}
            component={GMSettingsPage}
          />
          {/* default redirection */}
          <RedirectToLandingPage from="/:locale" />
          <Redirect from="/:locale" to={`/:locale/${appRoutes.TENANTS}`} />
          <Redirect from="/:locale" to={`/:locale/${appRoutes.USERS}`} />
          <Redirect from="/:locale" to={`/:locale/${appRoutes.PATIENTS}`} />
        </Switch>
      </Router>
    </LanguageProvider>
  );
  return isAppLoading ? (
    <SpinBox centered>
      <Spin spinning size="large" />
    </SpinBox>
  ) : (
    <ConfigProvider theme={customTheme}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router history={history}>
          <AppWrapper>
            <Switch>
              {/* no locale */}
              {RedirectToDefaultLocale()}

              {/* supported locale */}
              <Route
                sensitive
                path={`/:locale(${getSupportedLocalesRegularExp()})`}
                component={_appRoutesSwitch}
              />

              {/* not supported locale ( wrong syntax / not exist ) */}
              <Route path="/:locale" component={RedirectToSupportedLocale} />

              {/* default redirection */}
              <Redirect from="/*" to={`/${DEFAULT_LOCALE}/`} />
            </Switch>
            {isLoggedIn && (
              <LanguageProvider>
                <Sidebar />
              </LanguageProvider>
            )}
          </AppWrapper>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

Root.propTypes = {
  onAppStart: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
  isAppLoading: PropTypes.bool,
  monitorPremission: PropTypes.bool.isRequired,
  devicesPremission: PropTypes.bool.isRequired,
  tenantsPremission: PropTypes.bool.isRequired,
  patientsPremission: PropTypes.bool.isRequired,
  usersPremission: PropTypes.bool.isRequired,
  firmwareManagerPermission: PropTypes.bool.isRequired,
  managmentPermission: PropTypes.bool.isRequired,
  medicalInfoPermissions: PropTypes.bool.isRequired,
  individualDashboardPermissions: PropTypes.bool.isRequired,
  settingsPermission: PropTypes.bool.isRequired,
  gmPermissions: PropTypes.bool.isRequired,
  gmUserPermissions: PropTypes.bool.isRequired,
  customersPremission: PropTypes.bool.isRequired,
};

Root.defaultProps = {
  isLoggedIn: false,
  isAppLoading: false,
};

export default React.memo(Root);
