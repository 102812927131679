import { defineMessages } from 'react-intl';

export const authMessages = defineMessages({
  // error
  logoutErrorMessage: {
    defaultMessage: 'Failed to logout',
  },
  logoutErrorDescription: {
    defaultMessage: 'Failed to logout, try again later',
  },
  tokenErrorDescription: {
    defaultMessage: 'Refresh the page and try again',
  },
  resetPasswordErrorMessage: {
    defaultMessage: 'Reset password failed',
  },
  resetPasswordErrorDescription: {
    defaultMessage: 'Could not reset password',
  },
  refreshTokenErrorMessage: {
    defaultMessage: 'Token Expired',
  },
  refreshTokenErrorDescription: {
    defaultMessage: 'Try to login again',
  },
  sendingResetPasswordEmailFailed: {
    defaultMessage: 'Could not send email',
  },
  mfaValidationFailed: {
    defaultMessage: 'Code verification failed.',
  },
  mfaValidationFailedDescription: {
    defaultMessage: 'Please try again',
  },

  // success
  resetPasswordSuccessMessage: {
    defaultMessage: 'Password set successfully',
  },
  mfaResentCodeSuccessMessage: {
    defaultMessage: 'Code sent successfully',
  },
  mfaResentCodeSuccessDescription: {
    defaultMessage: 'Try to login with your new code',
  },
  resetPasswordSuccessDescription: {
    defaultMessage: 'Try to login with your new password',
  },
  sendingResetPasswordEmailSuccess: {
    defaultMessage: 'Email sent',
  },
});
