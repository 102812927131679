import React from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import AlertModal from 'src/components/Modal/AlertModal';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import PasswordLayout, {
  ADDITIONAL_CONTENT_POSITION,
} from 'src/components/PasswordForm/PasswordLayout';
import {
  CenteredContainer,
  SmallButtonLink,
  SmallNavigationLink,
} from '../components/styled';
import ConsentModal from '../Consent';

import Connector, { PropsFromRedux } from './Connector';
import LoginForm from 'src/routes/Auth/Login/LoginForm';
import MfaForm from 'src/routes/Auth/Login/MfaForm';

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const LoginPage = ({
  login,
  intl,
  loginStatus,
  userIsIdle,
  onIdle,
  isConsentApiFailed,
  mfaPhone,
  isMfaRequired,
  mfaLogin,
  mfaResendCodeLogin,
}: Props): JSX.Element => (
  <CenteredContainer>
    <PasswordLayout
      formComponent={
        !isMfaRequired ? (
          <LoginForm
            login={login}
            intl={intl}
            loginStatus={loginStatus}
            isConsentApiFailed={isConsentApiFailed}
          />
        ) : (
          <MfaForm
            mfaLogin={mfaLogin}
            mfaPhone={mfaPhone}
            intl={intl}
            loginStatus={loginStatus}
          />
        )
      }
      additionalContentComponent={
        !isMfaRequired ? (
          <SmallNavigationLink
            to={`${appRoutes.FORGOT_PASSWORD}`}
            data-cy="login-forgot-password-btn"
          >
            <FormattedMessage {...messages.forgotPassword} />
          </SmallNavigationLink>
        ) : (
          <SmallButtonLink onClick={() => mfaResendCodeLogin()}>
            <FormattedMessage {...messages.resendCode} />
          </SmallButtonLink>
        )
      }
      additionalContentPosition={ADDITIONAL_CONTENT_POSITION.END}
      innerTitle={messages.innerTitle}
    />

    {/* FIXME: Maybe move to root and change to alert modal with only ok button */}
    <AlertModal
      isModalVisible={userIsIdle}
      setIsModalVisible={() => {
        onIdle(false);
      }}
      onOk={() => onIdle(false)}
      message={intl.formatMessage(messages.loginAlert)}
      dataCy="idle-prompt"
    />
    <ConsentModal />
  </CenteredContainer>
);

const messages = defineMessages({
  forgotPassword: {
    defaultMessage: 'Forgot your password?',
  },
  resendCode: {
    defaultMessage: 'Resend the code',
  },
  loginAlert: {
    defaultMessage: 'Your Session has expired, please login again',
  },
  innerTitle: {
    defaultMessage: 'Please log in',
  },
});

export default Connector(injectIntl(LoginPage));
