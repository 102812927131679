import { defineMessages, IntlShape } from 'react-intl';

import { displayInTimezone, getTimezoneId } from 'src/utils/timeUtils';
import { updateText } from 'src/utils/stringUtils';
import { AlertLogItem } from './types';
import { Tenant } from 'src/types/tenants';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';

export const messages = defineMessages({
  HR: {
    defaultMessage: 'HR',
  },
  RR: {
    defaultMessage: 'RR',
  },
  HR_BASELINE: {
    defaultMessage: 'HR',
  },
  RR_BASELINE: {
    defaultMessage: 'RR',
  },
  BED_EXIT: {
    defaultMessage: 'BedExit',
  },
  unassignedDeviceDisconnectedInfo: {
    defaultMessage: 'Unassigned disconnected device',
  },
  assignedDeviceDisconnectedInfo: {
    defaultMessage: 'Patient-assigned disconnected device',
  },
  allDevicesDisconnectedInfo: {
    defaultMessage: 'All devices are disconnected',
  },
  ABOVE: {
    defaultMessage: 'ABOVE',
  },
  BELOW: {
    defaultMessage: 'BELOW',
  },
  BPM: {
    defaultMessage: 'bpm',
  },
  BRPM: {
    defaultMessage: 'brpm',
  },
  room: {
    defaultMessage: 'R',
  },
  bed: {
    defaultMessage: 'Bed',
  },
  anAverageOf: {
    defaultMessage: 'An Average of',
  },
  is: {
    defaultMessage: 'is',
  },
  for: {
    defaultMessage: 'for',
  },
  of: {
    defaultMessage: 'of',
  },
  from: {
    defaultMessage: 'from',
  },
  hours: {
    defaultMessage: 'hours',
  },
  hour: {
    defaultMessage: 'hour',
  },
  min: {
    defaultMessage: 'min',
  },
  high: {
    defaultMessage: 'High',
  },
  low: {
    defaultMessage: 'Low',
  },
  days: {
    defaultMessage: 'days',
  },
  baseline: {
    defaultMessage: 'baseline',
  },
  since: {
    defaultMessage: 'since',
  },
  on: {
    defaultMessage: 'on',
  },
  or: {
    defaultMessage: 'or',
  },
  and: {
    defaultMessage: 'and',
  },
  out: {
    defaultMessage: 'out',
  },
  more: {
    defaultMessage: 'more',
  },
  alert: {
    defaultMessage: 'Alert',
  },
  onLastHours: {
    defaultMessage: 'in the last 24 hours',
  },
  baselineDeviationText: {
    defaultMessage: 'baseline deviation',
  },
  vacantStation: {
    defaultMessage: 'BedExit',
  },
  endedIn: {
    defaultMessage: 'Ended in',
  },
  stationIsReoccupied: {
    defaultMessage: 'when station was reoccupied',
  },
  activity: {
    defaultMessage: 'Activity',
  },
  notification: {
    defaultMessage: 'Notification',
  },
  bedTimeHoursDiffer: {
    defaultMessage: 'Bed-Time hours differs from last',
  },
  frequentBedExitsBetween: {
    defaultMessage: 'frequent Bed Exits between',
  },
  patient: {
    defaultMessage: 'Patient',
  },
});

const metricToUnit = Object.freeze({
  HR: 'BPM',
  RR: 'BRPM',
  HR_BASELINE: 'BPM',
  RR_BASELINE: 'BRPM',
});

export const getTooltipMessage = (
  alertItem: AlertLogItem,
  tenant: Tenant | undefined,
  intl: IntlShape,
): string => {
  const {
    startTime,
    endTime,
    thresholdMetric,
    deviceRoomName,
    deviceBedName,
    patientFirstName,
    patientLastName,
    reminderCount,
    deviceId,
  } = alertItem;

  const timezone = getTimezoneId(tenant?.timeZoneId);

  const timestampText = displayInTimezone(startTime, timezone, 'D MMM HH:mm');
  const locationText = updateText(
    ' |',
    `${updateText(
      ` ${intl.formatMessage(messages.room)} `,
      deviceRoomName,
    )}${updateText(` ${intl.formatMessage(messages.bed)} `, deviceBedName)}`,
  );
  const patientText = updateText(
    ' |',
    `${updateText(' ', patientFirstName)}${updateText(' ', patientLastName)}`,
  );

  if (thresholdMetric === 'ALL_DEVICES_DISCONNECTED') {
    const timestamp = displayInTimezone(
      startTime,
      timezone,
      'MM-DD-YYYY HH:mm',
    );
    const endTimestamp = endTime
      ? displayInTimezone(endTime, timezone, 'MM-DD-YYYY HH:mm')
      : 'N/A';
    const { city, countryCode } = tenant?.address || {};
    const locationText = `${timestamp} ${city || ''}, ${
      countryCode || ''
    }, ${timezone}.`;
    const infoText = `All devices on tenant ${
      tenant?.tenantName || ''
    } were disconnected until ${endTimestamp}. Please verify WiFi/power stability.`;

    return `${locationText} ${infoText}`;
  }

  if (thresholdMetric === 'DEVICE_DISCONNECTED') {
    const timestamp = displayInTimezone(
      startTime,
      timezone,
      'MM-DD-YYYY HH:mm',
    );

    return `Device ${deviceId} on Room ${deviceRoomName || ''} Bed ${
      deviceBedName || ''
    } Last connection to cloud on ${timestamp}.`;
  }

  if (thresholdMetric === 'BED_EXIT') {
    const endTimeStamp = endTime
      ? displayInTimezone(endTime, timezone, 'HH:mm')
      : 'N/A';

    return `${timestampText}${locationText}${patientText} | ${intl.formatMessage(
      messages.vacantStation,
    )}. ${intl.formatMessage(
      messages.endedIn,
    )} ${endTimeStamp} ${intl.formatMessage(messages.stationIsReoccupied)}`;
  }

  if (thresholdMetric === 'HR_BASELINE' || thresholdMetric === 'RR_BASELINE') {
    const {
      thresholdPreposition,
      deviationPercentage,
      deviationHours,
      baselineValue,
      baselineDaysInterval,
    } = alertItem;

    return `${timestampText} ${locationText} ${patientText} 
    | ${intl.formatMessage(
      thresholdPreposition === 'ABOVE' ? messages.high : messages.low,
    )} ${intl.formatMessage(messages[thresholdMetric])} - ${intl.formatMessage(
      messages.baselineDeviationText,
    )} ${intl.formatMessage(messages.of)} ${Math.floor(
      Math.abs(deviationPercentage),
    )}% ${intl.formatMessage(
      messages.for,
    )} ${deviationHours} ${intl.formatMessage(
      messages.hours,
    )} ${intl.formatMessage(
      messages.from,
    )} ${baselineDaysInterval} ${intl.formatMessage(
      messages.days,
    )} ${intl.formatMessage(messages.baseline)}
    ${Math.round(baselineValue)}. ${intl.formatMessage(
      messages[thresholdMetric],
    )} ${intl.formatMessage(
      messages.baselineDeviationText,
    )} ${intl.formatMessage(
      messages.alert,
    )} #${reminderCount} ${intl.formatMessage(messages.onLastHours)}.`;
  }

  if (thresholdMetric === 'HR' || thresholdMetric === 'RR') {
    const {
      averageInterval,
      thresholdPreposition,
      thresholdValue,
      averageValue,
    } = alertItem;

    const averageDetailsText = updateText(
      ' |',
      ` ${intl.formatMessage(messages.anAverageOf)} ${Math.round(
        averageInterval / 60,
      )} ${intl.formatMessage(messages.min)} ${intl.formatMessage(
        messages.is,
      )} ${intl.formatMessage(
        messages[thresholdPreposition],
      )} ${thresholdValue} ${intl.formatMessage(
        messages[metricToUnit[thresholdMetric]],
      )} (${Math.round(averageValue)} ${intl.formatMessage(
        messages[metricToUnit[thresholdMetric]],
      )})`,
    );

    const alertReminderNumberText = updateText(
      ' | ',
      `${intl.formatMessage(
        thresholdPreposition === 'ABOVE' ? messages.high : messages.low,
      )} ${intl.formatMessage(messages[thresholdMetric])} ${intl.formatMessage(
        messages.alert,
      )} #${reminderCount} ${intl.formatMessage(messages.onLastHours)}.`,
    );

    return `${timestampText}${locationText}${patientText} ${averageDetailsText} ${alertReminderNumberText}`;
  }

  if (thresholdMetric === ALERT_METRIC_ENUM.LONG_OUT_OF_BED) {
    const { triggerValue } = alertItem;

    const averageDetailsText = `| ${intl.formatMessage(messages.patient)} ${intl.formatMessage(messages.is)} ${intl.formatMessage(messages.out)} ${intl.formatMessage(messages.of)} ${intl.formatMessage(messages.bed)} ${intl.formatMessage(messages.for)} ${triggerValue} ${intl.formatMessage(triggerValue === 1 ? messages.hour : messages.hours)} ${intl.formatMessage(messages.or)} ${intl.formatMessage(messages.more)}`;

    return `${timestampText}${locationText}${patientText} ${averageDetailsText}`;
  }

  if (thresholdMetric === ALERT_METRIC_ENUM.BED_TIME_BASELINE) {
    const { deviationPeriodDays = 0, triggerValue } = alertItem;

    const averageDetailsText = `| ${intl.formatMessage(messages.activity)} ${intl.formatMessage(messages.notification)} | ${triggerValue ?? '-'}% ${intl.formatMessage(messages.bedTimeHoursDiffer)} ${deviationPeriodDays} ${intl.formatMessage(messages.days)} ${intl.formatMessage(messages.baseline)}`;

    return `${timestampText}${locationText}${patientText} ${averageDetailsText}`;
  }

  if (thresholdMetric === ALERT_METRIC_ENUM.BED_EXIT_FREQUENCY) {
    const { triggerValue } = alertItem;
    const startDateStamp = displayInTimezone(startTime, timezone, 'MM-DD-YYYY');
    const startTimeStamp = displayInTimezone(startTime, timezone, 'HH:MM');
    const endDateStamp = endTime
      ? displayInTimezone(endTime, timezone, 'MM-DD-YYYY')
      : '';
    const endTimeStamp = endTime
      ? displayInTimezone(endTime, timezone, 'HH:MM')
      : '';

    const averageDetailsText = `| ${intl.formatMessage(messages.activity)} ${intl.formatMessage(messages.alert)} | ${triggerValue} ${intl.formatMessage(messages.frequentBedExitsBetween)} ${startTimeStamp} ${intl.formatMessage(messages.on)} ${startDateStamp}  ${intl.formatMessage(messages.and)} ${endTimeStamp} ${intl.formatMessage(messages.on)} ${endDateStamp}`;

    return `${timestampText}${locationText}${patientText} ${averageDetailsText}`;
  }

  return '';
};
