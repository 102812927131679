/**
 * Create the store with dynamic reducers
 */
import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import history from 'src/utils/history';

import createRootReducer from './reducer';
import saga from './saga';

const createStore = () => {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middleware = [sagaMiddleware, routerMiddleware(history)];
  const reducer = createRootReducer();

  const store = configureStore({
    reducer,
    middleware,
    preloadedState: {},
  });

  // Extensions
  sagaMiddleware.run(saga);
  // @ts-ignore TODO: Fix this later
  store.runSaga = sagaMiddleware.run; // eslint-disable-line @typescript-eslint/unbound-method
  // @ts-ignore TODO: Fix this later
  store.injectedReducers = {}; // Reducer registry
  // @ts-ignore TODO: Fix this later
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  /* HMR DISABLE
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createRootReducer(store.injectedReducers));
    });
  } */

  return store;
};

const store = createStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
