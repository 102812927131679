import styled from 'styled-components';

export const TitleWrapper = styled.div`
  grid-column: span 12;
`;

export const DashboardTabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #eff8f9;

  padding: 0.5rem;
  padding-bottom: 0px;
  height: calc(100vh - 180px);
`;

export const DashboardLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  width: 65%;
`;

export const DashboardRightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  width: 33%;
  gap: 3%;
`;

export const EmptyBoxWrapper = styled.div`
  border: ${props =>
    `1px solid ${props.theme.colors.individualDashboardHeaderBorder}`};
  padding-top: 10px;
  border-radius: 8px;
  background-color: ${props =>
    props.theme.colors.measurementCardBackgroundColor};
  height: 17%;
`;
