import React from 'react';

import { SVGProps } from '../types';

const AttentionIcon = ({
  width = '14',
  height = '14',
  color = '#CC3311',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <circle cx="7" cy="7" r="6.5" fill="#FDEDE9" stroke={color} />
    <mask
      id="mask0_1148_63406"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="14"
      height="14"
    >
      <circle cx="7" cy="7" r="6.5" fill="#FFAD0D" stroke="#252525" />
    </mask>
    <g mask="url(#mask0_1148_63406)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9998 3.5C7.38641 3.5 7.6998 3.86562 7.6998 4.31667V7.58333C7.6998 8.03438 7.38641 8.4 6.9998 8.4C6.61319 8.4 6.2998 8.03438 6.2998 7.58333V4.31667C6.2998 3.86562 6.61319 3.5 6.9998 3.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.6998 9.79961C7.6998 9.413 7.38641 9.09961 6.9998 9.09961C6.61319 9.09961 6.2998 9.413 6.2998 9.79961C6.2998 10.1862 6.61319 10.4996 6.9998 10.4996C7.38641 10.4996 7.6998 10.1862 7.6998 9.79961Z"
        fill={color}
      />
    </g>
  </svg>
);

export default AttentionIcon;
