import { connect } from 'react-redux';
import { apiStatusEnum } from 'src/utils/constants';
import Spot from '../components/Spot';
import { actions, selectors } from '../modules/slice';

const mapStateToProps = (state, ownProps) => ({
  spotMeasurementsData: selectors.getPatientSpotMeasurementsData(
    state,
    ownProps.patientId,
  ),
  isLoading:
    selectors.getPatientSpotMeasurementsStatus(state, ownProps.patientId) ===
    apiStatusEnum.LOADING,
});

const mapActionCreators = {
  onSpotHistoryMount: actions.onSpotHistoryMount,
};

export default connect(mapStateToProps, mapActionCreators)(Spot);
