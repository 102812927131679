import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  devices: {
    defaultMessage: 'Devices',
  },
  searchPlaceholder: {
    defaultMessage: 'Search by id or name',
  },
  deleteConfirmation: {
    defaultMessage: 'Are you sure you want to delete?',
  },
  detachConfirmation: {
    defaultMessage:
      "Are you sure you want to detach this device from it's current tenant?",
  },
  manufacturerId: {
    defaultMessage: 'Serial Number',
  },
  name: {
    defaultMessage: 'Name',
  },
  tenant: {
    defaultMessage: 'Tenant',
  },
  actions: {
    defaultMessage: 'Actions',
  },
  deleteDevice: {
    defaultMessage: 'Delete device',
  },
  editDevice: {
    defaultMessage: 'Edit device',
  },
  detachTenant: {
    defaultMessage: 'Detach tenant',
  },
  assignTenant: {
    defaultMessage: 'Assign tenant',
  },
  dashboard: {
    defaultMessage: 'Dashboard',
  },
  version: {
    defaultMessage: 'FW version',
  },
  group: {
    defaultMessage: 'Group',
  },
  info: {
    defaultMessage: 'Info',
  },
  room: {
    defaultMessage: 'Room',
  },
  bed: {
    defaultMessage: 'Bed',
  },
});
