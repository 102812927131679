import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'src/themes/theme';
import { injectIntl, defineMessages } from 'react-intl';

const StyledFormItem = styled(Form.Item)`
  margin: 0;
`;

const StyledInput = styled(Input)`
  width: 2rem;
  position: absolute;
  top: 0;
`;

const StyledCheckCircleTwoTone = styled(CheckCircleTwoTone)`
  padding-left: 0.2rem;
  position: absolute;
  right: 20px;
  top: 0.2rem;
`;

const StyledCloseCircleTwoTone = styled(CloseCircleTwoTone)`
  padding-left: 0.4rem;
  position: absolute;
  right: 0;
  top: 0.2rem;
`;

const EditableCell = ({
  intl,
  editable,
  children,
  dataIndex,
  record,
  updateElement,
  EditableContext,
  isRequired,
  disabled,
  validatePattern,
  cellName,
  ...restProps
}) => {
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      updateElement(record.id, values[cellName]);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  if (editable && !disabled) {
    childNode = editing ? (
      <StyledFormItem
        onBlur={() => {
          toggleEdit();
        }}
      >
        <StyledFormItem
          name={cellName}
          rules={[
            {
              required: isRequired,
              message: intl.formatMessage(messages.invalidErrorMessage),
              pattern: validatePattern,
            },
          ]}
        >
          <StyledInput ref={inputRef} />
        </StyledFormItem>
        <StyledCheckCircleTwoTone
          twoToneColor={theme.colors.saveEditMode}
          onMouseDown={save}
        />
        <StyledCloseCircleTwoTone
          twoToneColor={theme.colors.cancelEditMode}
          onMouseDown={toggleEdit}
        />
      </StyledFormItem>
    ) : (
      <div
        className="editable-cell-value-wrap"
        onClick={toggleEdit}
        onKeyPress={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const messages = defineMessages({
  invalidErrorMessage: {
    defaultMessage: 'Please insert a valid value',
  },
});

EditableCell.propTypes = {
  intl: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  updateElement: PropTypes.func,
  EditableContext: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  validatePattern: PropTypes.string,
  cellName: PropTypes.string,
};

EditableCell.defaultProps = {
  editable: false,
  dataIndex: null,
  record: {},
  updateElement: () => {},
  isRequired: true,
};

export default injectIntl(EditableCell);
