import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Neteera Healthgate automatic alert',
  },
  high: {
    defaultMessage: 'High',
  },
  low: {
    defaultMessage: 'Low',
  },
  bpm: {
    defaultMessage: 'BPM',
  },
  brpm: {
    defaultMessage: 'BrPM',
  },
  hr: {
    defaultMessage: 'HR',
  },
  rr: {
    defaultMessage: 'RR',
  },
  above: {
    defaultMessage: 'above',
  },
  below: {
    defaultMessage: 'below',
  },
  threshold: {
    defaultMessage: 'threshold',
  },
  alert: {
    defaultMessage: 'Alert',
  },
  baselineOfLast24h: {
    defaultMessage: 'Baseline of last 24h:',
  },
  anAverageOf: {
    defaultMessage: 'An Average of',
  },
  hours: {
    defaultMessage: 'hours',
  },
  idNumber: {
    defaultMessage: 'ID Number: {patientId}',
  },
  dob: {
    defaultMessage: 'Date of birth:',
  },
  room: {
    defaultMessage: 'Room:',
  },
  bed: {
    defaultMessage: 'Bed:',
  },
  timezone: {
    defaultMessage: 'Timezone:',
  },
  errorText: {
    defaultMessage:
      'There was a problem fetching alert data, please try again.',
  },
  dataNotFound: {
    defaultMessage: 'No alert found for given id.',
  },
  notAvailable: {
    defaultMessage: 'N/A',
  },
  vacantStation: {
    defaultMessage: 'BedExit',
  },
});
