import {
  all,
  takeLatest,
  call,
  put,
  fork,
  select,
} from 'typed-redux-saga/macro';

import BackendService from 'src/services/BackendService';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { toApiCallFormat } from 'src/utils/timeUtils';
import { DateUTC, UUID } from 'src/types/utility';
import { actions } from './slice';
import { StatisticsMetricGraphType } from './types';

function* getPatientSingleGraphPointsByDateTime({
  patientId,
  startTime,
  endTime,
  metric,
}: {
  patientId: UUID;
  startTime: DateUTC;
  endTime: DateUTC;
  metric: StatisticsMetricGraphType;
}) {
  try {
    const { data } = yield* call(BackendService.getDateTimeGraphPoints, {
      patientId,
      startTime,
      endTime,
      metric,
    });
    const timezone = yield* select(loggedInUserSelectors.getUserTenantTimezone);

    yield* put(
      actions.getDateTimeGraphPointsSuccess({
        patientId,
        startTime,
        endTime,
        metric,
        data: data.continuousMeasurements,
        timezone,
      }),
    );
  } catch (error) {
    console.error(
      `An error occurred while trying to fetch ${metric} graph points for patient id: ${patientId} from date: ${startTime} to date: ${endTime} .\n Error:`,
      error,
    );
    yield* put(
      actions.getDateTimeGraphPointsFail({
        patientId,
        startTime,
        endTime,
        metric,
      }),
    );
  }
}

function* getPatientDateTimeGraphsPoints(
  action: ReturnType<typeof actions.viewPatientDateTimeGraphs>,
) {
  const { patientId, startTime, endTime, metrics } = action.payload;

  for (const metric of metrics) {
    if (startTime && endTime) {
      yield* fork(getPatientSingleGraphPointsByDateTime, {
        patientId,
        startTime: toApiCallFormat(startTime),
        endTime: toApiCallFormat(endTime),
        metric,
      });
    }
  }
}

export default function* watchStatisticsActions() {
  yield* all([
    takeLatest(
      actions.viewPatientDateTimeGraphs,
      getPatientDateTimeGraphsPoints,
    ),
  ]);
}
