import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { roundToNDecimals } from 'src/utils/dataUtils';
import {
  StyledAverageValuesCard,
  StyledBar,
  CardStyled,
  AverageWrapper,
  AverageStyled,
  MeasurementUnit,
} from './styledAverageValues';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const formatBarLabelWithResolution = value => {
  if (value <= 0) {
    return `${0}%`;
  }

  if (value <= 10) {
    return `${roundToNDecimals(value, 1)}%`;
  }

  return `${roundToNDecimals(value, 0)}%`;
};

const graphOptions = {
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 2,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      anchor: 'end',
      align: 'top',
      offset: -3,
      formatter: value => formatBarLabelWithResolution(value),
      font: {
        weight: 'bold',
      },
    },
  },
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 20,
      bottom: 5,
    },
  },
  scales: {
    xAxes: {
      display: true,
      ticks: {
        font: {
          size: 13,
        },
      },
    },
    yAxes: {
      display: false,
    },
  },
};

const AverageValuesCard = ({
  key,
  data,
  averageValue,
  measurementUnit,
  averageStatisticsXAxis,
}) => {
  const barData = {
    labels: averageStatisticsXAxis.map(item => item.name),
    datasets: [
      {
        data: data.map(item => item.value),
        backgroundColor: averageStatisticsXAxis.map(item => item.color),
        minBarLength: 2,
      },
    ],
  };
  return (
    <StyledAverageValuesCard key={key}>
      <AverageWrapper>
        <AverageStyled>{Math.round(averageValue)}</AverageStyled>
        <MeasurementUnit>{measurementUnit}</MeasurementUnit>
      </AverageWrapper>
      <CardStyled>
        <StyledBar
          options={graphOptions}
          data={barData}
          plugins={[ChartDataLabels]}
        />
      </CardStyled>
    </StyledAverageValuesCard>
  );
};

AverageValuesCard.propTypes = {
  key: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  averageValue: PropTypes.number,
  averageStatisticsXAxis: PropTypes.array.isRequired,
  measurementUnit: PropTypes.string.isRequired,
};

AverageValuesCard.defaultProps = {
  key: null,
  averageValue: 0,
};

export default injectIntl(AverageValuesCard);
