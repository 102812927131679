import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { actions as alertActions } from 'src/redux/data/alerts';
import { actions as gmActions } from 'src/redux/data/groupManager';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = (_state: RootState) => ({});

const mapActionCreators = {
  createGMAlertThreshold: alertActions.createAlert,
  editGMAlertThreshold: alertActions.editAlert,
  createSubtenantAlertThresholds: gmActions.createSubtenantAlertThresholds,
  editSubtenantAlertThresholds: gmActions.editSubtenantAlertThresholds,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
