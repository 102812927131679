import { actions as SMSEmailAlertsActions } from 'src/redux/data/SMSEmailAlerts/modules/slice';

import { intl, messages } from '../messages';

export const SMSEmailAlertsActionsToSuccessNotificationMessagesMap = {
  [SMSEmailAlertsActions.updateSubscribersList.type]: (groupId, _) => ({
    message: intl.formatMessage(messages.updateSubscribersListSuccess),
  }),
};

export const SMSEmailAlertsActionsErrorNotificationMessagesMap = {
  VALIDATION_ERROR: (value, _) => ({
    message: intl.formatMessage(messages.validationError),
  }),
  EXECUTION_FAILED_ERROR: () => ({
    message: intl.formatMessage(messages.generalErrorMessage),
  }),
};
