import React from 'react';

import { SVGProps } from '../types';

const UpArrowIcon = ({
  width = '9',
  height = '11',
  color = '#fff',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      d="M8.7802 5.21633C8.48723 5.52015 8.01261 5.52015 7.71966 5.21633L5.24996 2.65516V10.1109C5.24996 10.5408 4.91451 10.8887 4.49996 10.8887C4.08541 10.8887 3.74996 10.5408 3.74996 10.1109V2.65516L1.28025 5.21633C0.987286 5.52015 0.512668 5.52015 0.219719 5.21633C-0.0732303 4.91251 -0.073249 4.42032 0.219719 4.11652L3.96971 0.227648C4.11619 0.075737 4.30809 -0.000217438 4.5 -0.000217438C4.69191 -0.000217438 4.88377 0.075737 5.03028 0.227648L8.78027 4.11652C9.07324 4.42033 9.07324 4.91253 8.78027 5.21633H8.7802Z"
      fill={color}
    />
  </svg>
);

export default UpArrowIcon;
