import React from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';

import { SubTenantOverview } from 'src/routes/GMDashboard/modules/types';
import Icon from 'src/components/general-ui/Icon';
import { Column, Label, Value, VerticalRule } from './styled';
import SubtenantDisconnectionOverview from './SubtenantDisconnectionOverview';

const DevicesStatisticsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.25rem 0rem;
  background: #f4f6f8;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.4rem;
`;

const StyledIcon = styled(Icon)`
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.15rem;
`;

const StyledAlertIcon = styled(Icon)`
  width: 1.25rem;
  height: 1.2rem;
  margin-right: 0.15rem;
`;

type Props = Pick<
  SubTenantOverview,
  | 'id'
  | 'name'
  | 'totalDevicesCounter'
  | 'disconnectedAssignedDevicesCounter'
  | 'disconnectedUnassignedDevicesCounter'
>;

const DevicesStatistics = ({
  id,
  name,
  totalDevicesCounter,
  disconnectedAssignedDevicesCounter,
  disconnectedUnassignedDevicesCounter,
}: Props): JSX.Element => {
  const isAlertForDisconnectedAssignedDevices =
    disconnectedAssignedDevicesCounter > 0;
  const isAlertForDisconnectedUnassignedDevices =
    disconnectedUnassignedDevicesCounter > 0;

  return (
    <DevicesStatisticsWrapper>
      <Column width="26%">
        <Label>
          <FormattedMessage {...messages.totalDevices} />
        </Label>
        <Value>{totalDevicesCounter}</Value>
      </Column>
      <VerticalRule />
      <Column width="37%">
        <SubtenantDisconnectionOverview
          subtenantId={id}
          subtenantName={name}
          title={messages.disconnectedAssignedDevices}
        />
        <Value isAlert={isAlertForDisconnectedAssignedDevices}>
          <StyledAlertIcon
            type="wifi-disconnected-alert-icon-with-line"
            color={
              isAlertForDisconnectedAssignedDevices ? '#CA0000' : '#252525'
            }
            altColor={
              isAlertForDisconnectedAssignedDevices ? '#CC3311' : '#252525'
            }
            fillAltColor={
              isAlertForDisconnectedAssignedDevices ? '#FDEDE9' : '#fff'
            }
          />
          {disconnectedAssignedDevicesCounter}
        </Value>
      </Column>
      <VerticalRule />
      <Column width="37%">
        <SubtenantDisconnectionOverview
          subtenantId={id}
          subtenantName={name}
          title={messages.disconnectedUnassignedDevices}
        />
        <Value isAlert={isAlertForDisconnectedUnassignedDevices}>
          <StyledIcon
            type="wifi-disconnected-icon-v2"
            color={
              isAlertForDisconnectedUnassignedDevices ? '#CA0000' : '#252525'
            }
          />
          {disconnectedUnassignedDevicesCounter}
        </Value>
      </Column>
    </DevicesStatisticsWrapper>
  );
};

const messages = defineMessages({
  totalDevices: {
    defaultMessage: 'Total Devices',
  },
  disconnectedAssignedDevices: {
    defaultMessage: 'Disconnected & Assigned',
  },
  disconnectedUnassignedDevices: {
    defaultMessage: 'Disconnected & Unassigned',
  },
});

export default DevicesStatistics;
