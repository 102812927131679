import React from 'react';
import { defineMessages, FormattedMessage, IntlShape } from 'react-intl';

import { sortWithEmptyString } from 'src/utils/sorters/utils';
import { handleClickSubtenantPatientName } from 'src/routes/GMDashboard/modules/utils';
import { TableColumnConfig } from 'src/types/table';
import { DisconnectionOverviewTableItem } from './types';
import { BoldItem, LocationItem, StyledPatientName } from './styled';

const messages = defineMessages({
  patientName: {
    defaultMessage: 'Patient Name',
  },
  deviceSerialNumber: {
    defaultMessage: 'Device S/N',
  },
  deviceStatus: {
    defaultMessage: 'Device Status',
  },
  roomAndBed: {
    defaultMessage: 'Room/Bed',
  },
  room: {
    defaultMessage: 'R',
  },
  bed: {
    defaultMessage: 'B',
  },
  lastConnectionTime: {
    defaultMessage: 'Last Connection Time',
  },
  assigned: {
    defaultMessage: 'Assigned',
  },
  unassigned: {
    defaultMessage: 'Unassigned',
  },
});

export const getDisconnectionOverviewColumns = (
  intl: IntlShape,
): TableColumnConfig<DisconnectionOverviewTableItem>[] => [
  {
    title: intl.formatMessage(messages.deviceSerialNumber),
    dataIndex: 'deviceId',
    key: 'deviceId',
    filtered: true,
    sorter: (a, b) => sortWithEmptyString(a.deviceId, b.deviceId),
  },
  {
    title: intl.formatMessage(messages.roomAndBed),
    dataIndex: 'deviceLocation',
    key: 'roomAndBed',
    filtered: true,
    sorter: (a, b) =>
      sortWithEmptyString(a.deviceLocation, b.deviceLocation, {
        numeric: true,
      }),
    render: (_, record) => (
      <div>
        <BoldItem>
          <FormattedMessage {...messages.room} />
        </BoldItem>
        &nbsp;
        <LocationItem>{record.roomName}</LocationItem>
        &nbsp;
        <BoldItem>
          <FormattedMessage {...messages.bed} />
        </BoldItem>
        &nbsp;
        <LocationItem>{record.bedName}</LocationItem>
      </div>
    ),
  },
  {
    title: intl.formatMessage(messages.deviceStatus),
    dataIndex: 'deviceStatus',
    key: 'deviceStatus',
    filtered: true,
    sorter: (a, b) => sortWithEmptyString(a.deviceStatus, b.deviceStatus),
    render: (_, record) => (
      <FormattedMessage
        {...(record.deviceStatus === 'assigned'
          ? messages.assigned
          : messages.unassigned)}
      />
    ),
  },
  {
    title: intl.formatMessage(messages.patientName),
    dataIndex: 'patientName',
    key: 'patientName',
    filtered: true,
    sorter: (a, b) => sortWithEmptyString(a.patientName, b.patientName),
    render: (_, { tenantId, patientId, patientName }) =>
      patientId && patientName ? (
        <StyledPatientName
          onClick={() => handleClickSubtenantPatientName(tenantId, patientId)}
        >
          {patientName}
        </StyledPatientName>
      ) : (
        'N/A'
      ),
  },
  {
    title: intl.formatMessage(messages.lastConnectionTime),
    dataIndex: 'lastConnectionTime',
    key: 'lastConnectionTime',
    filtered: true,
    sorter: (a, b) =>
      sortWithEmptyString(
        a.lastConnectionTime || '',
        b.lastConnectionTime || '',
      ),
  },
];
