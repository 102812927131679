import React, { useState } from 'react';
import { defineMessages } from 'react-intl';
import TokenOperation from 'src/libs/js-token-operation';

import appConfig from 'src/config/AppConfig';
import {
  tokenStatusEnum,
  TOKEN_STATUS_COMPONENT_MAPPER,
  tokenOperationErrorToStatus,
} from 'src/utils/constants';
import { actionSuccessNotification } from 'src/utils/errorHandling/notifications';
import { actions } from 'src/redux/data/auth/modules/slice';
import { CenteredContainer } from '../components/styled';
import PasswordForm from '../../../components/PasswordForm';

const ResetPasswordPage = () => {
  const [status, setStatus] = useState<keyof typeof tokenStatusEnum>(
    tokenStatusEnum.LOADING,
  );
  const [password, setPassword] = useState<string | null>(null);
  const [shouldExecute, setShouldExecute] = useState(false);

  const handleSubmit = (values: { password: string }) => {
    setPassword(values.password);
    setShouldExecute(true);
    setStatus(tokenStatusEnum.SUBMITTING_BODY);
  };

  const onExecuteSuccess = () => {
    setStatus(tokenStatusEnum.SUCCESS);
    actionSuccessNotification(actions.resetPassword.type, null);
  };

  const onExecuteFail = (error: unknown) => {
    setStatus(
      tokenOperationErrorToStatus(error, tokenStatusEnum.GENERAL_ERROR),
    );
  };

  const onValidateFail = (error: unknown) => {
    setStatus(
      tokenOperationErrorToStatus(error, tokenStatusEnum.VALIDATE_FAILED),
    );
  };

  const onValidateSuccess = () => {
    setStatus(tokenStatusEnum.VALIDATED);
  };

  return (
    <CenteredContainer>
      <TokenOperation
        baseURL={`${appConfig.API_URL}/ums`}
        shouldExecute={shouldExecute}
        onExecuteSuccess={onExecuteSuccess}
        onExecuteFail={onExecuteFail}
        onValidateFail={onValidateFail}
        onValidateSuccess={onValidateSuccess}
        body={{ password }}
      />
      <PasswordForm
        onFinish={handleSubmit}
        status={status}
        statusComponentMapper={TOKEN_STATUS_COMPONENT_MAPPER(messagesStatus)}
        isLoading={status === tokenStatusEnum.SUBMITTING_BODY}
        isDisabled={
          status === tokenStatusEnum.LOADING ||
          status === tokenStatusEnum.SUBMITTING_BODY
        }
        submitMessage={messages.submitMessage}
      />
    </CenteredContainer>
  );
};

const messagesStatus = defineMessages({
  validationFailed: {
    defaultMessage: 'Could not reset password with the given details',
  },
  tokenError: {
    defaultMessage: 'Your invitation has expired',
  },
  generalError: {
    defaultMessage: 'Could not reset password',
  },
});
const messages = defineMessages({
  submitMessage: {
    defaultMessage: 'Reset Password',
  },
});

export default ResetPasswordPage;
