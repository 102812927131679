import styled from 'styled-components';
import { Space } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

export const SpacePadded = styled(Space)`
  padding: 0 0.5rem;
`;

export const StyledOptionLabel = styled.span`
  color: #252525;
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  margin-right: 0.5rem;
`;

export const ErrorText = styled.div`
  font-size: 0.65rem;
  color: #cc3311;
`;

export const DeleteIcon = styled(DeleteOutlined)`
  color: #cc3311;
`;

export const AddIcon = styled(PlusOutlined)`
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #304e64;
  border-radius: 0.9rem;
`;
