import { styled } from 'styled-components';

export const CardWrapper = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  padding: 0.3rem 0.5rem 0.5rem 0.5rem;
  background: #fefeff;
  box-shadow: 0px 3px 2px rgba(78, 83, 96, 0.15);
  border-radius: 0.5rem;
  color: #252525;
  min-height: 9rem;
  width: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledDeviceLocation = styled.div<{
  isDisabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 0.65rem;

  ${props =>
    props.isDisabled
      ? `
    color: #767881;
    opacity: 0.8;
      `
      : ''}
`;

export const RoomLocationItem = styled.span`
  width: 6rem;
  text-wrap: wrap;
  font-weight: 400;

  & .ant-typography {
    margin-bottom: 0;
  }
`;

export const BedLocationItem = styled.div`
  width: 6rem;

  & .ant-typography {
    margin-bottom: 0;
  }

  .bed-name-value {
    font-weight: 700;
    padding-left: 0.2rem;
  }
`;

export const LocationText = styled.span`
  font-weight: 500;
`;

export const DeviceInfoWrapper = styled.div`
  min-height: 115px;
  padding-top: 0.1rem;
`;

export const StyledDeviceInfo = styled.div<{ hasAssignedPatient?: boolean }>`
  & div {
    width: 100%;
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 400;
    font-size: 0.65rem;
    line-height: 0.65rem;
    text-decoration-line: underline;
    color: #767881;
    ${props => (props.hasAssignedPatient ? 'cursor: pointer;' : '')}
  }
`;
