import React, { useState, useRef, useEffect } from 'react';
import { Divider, Input, Button, Select } from 'antd';
import type { InputRef } from 'antd';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
} from 'react-intl';

import MultiSelect, {
  MultiSelectItem,
} from 'src/components/general-ui/MultiSelect';
import { emailPattern } from 'src/utils/constants';
import {
  ErrorText,
  SpacePadded,
  StyledOptionLabel,
  DeleteIcon,
  AddIcon,
} from './styled';
import { deepEqual } from 'src/utils/comparators';

const { Option } = Select;

type Props = {
  emails: string[];
  selectedEmails: string[];
  handleChange: (emails: string[]) => void;
  placeholder?: string;
  inputPlaceholder?: string;
  disabled?: boolean;
};

const MultiSelectAdder = ({
  emails,
  selectedEmails,
  handleChange,
  disabled,
  placeholder,
  inputPlaceholder,
}: Props): JSX.Element => {
  const [emailOptions, setEmailOptions] = useState<string[]>(emails);
  const [selectedItems, setSelectedItems] = useState<string[]>(selectedEmails);
  const [newOption, setNewOption] = useState<string>('');
  const [inputError, setInputError] = useState<MessageDescriptor | undefined>();
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    setEmailOptions(emails);
  }, [emails]);

  useEffect(() => {
    setSelectedItems(selectedEmails);
  }, [selectedEmails]);

  const onOptionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewOption(event.target.value);
    setInputError(undefined);
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    e.preventDefault();

    if (!newOption) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);

      setInputError(messages.emailCannotBeEmpty);
      return;
    }

    if (!emailPattern.test(newOption)) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);

      setInputError(messages.inputIsNotAnEmail);
      return;
    }

    if (emailOptions.includes(newOption)) {
      setInputError(messages.emailAlreadyInList);
      return;
    }

    setEmailOptions([...emailOptions, newOption]);
    setNewOption('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const deleteItem = (value: string) => {
    setSelectedItems(selectedItems.filter(email => email !== value));
    setEmailOptions(emailOptions.filter(email => email !== value));

    handleChange(selectedItems.filter(email => email !== value));
  };

  const onChange = (ids: string[]) => {
    setSelectedItems(ids);
    handleChange(ids);
  };

  const customOptionRender = ({ value, label }: MultiSelectItem) => (
    <Option value={value} label={label} key={value}>
      <StyledOptionLabel>{label}</StyledOptionLabel>
      <Button
        type="text"
        icon={<DeleteIcon />}
        onClick={() => deleteItem(value)}
      />
    </Option>
  );

  return (
    <MultiSelect
      selectedItems={selectedItems}
      options={emailOptions.map(email => ({ label: email, value: email }))}
      handleChange={onChange}
      disabled={disabled}
      placeholder={placeholder}
      showSearch={false}
      showArrow={true}
      customOptionRender={customOptionRender}
      dropdownRender={menu => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <SpacePadded>
            <Input
              ref={inputRef}
              value={newOption}
              placeholder={inputPlaceholder}
              status={inputError && 'error'}
              onChange={onOptionNameChange}
              required
            />
            <Button type="text" icon={<AddIcon />} onClick={addItem} />
            {inputError ? (
              <ErrorText>
                <FormattedMessage {...inputError} />
              </ErrorText>
            ) : null}
          </SpacePadded>
        </>
      )}
    />
  );
};

const messages = defineMessages({
  emailCannotBeEmpty: {
    defaultMessage: 'Email cannot be empty!',
  },
  emailAlreadyInList: {
    defaultMessage: 'Email already in list!',
  },
  inputIsNotAnEmail: {
    defaultMessage: 'Input is not an email!',
  },
});

export default React.memo(MultiSelectAdder, (oldProps, newProps) =>
  deepEqual(oldProps, newProps),
);
