import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Line } from 'react-chartjs-2';
import styled, { withTheme } from 'styled-components';
import { Typography, Spin } from 'antd';
import moment from 'moment';
import {
  METRIC_ENUM,
  chartOptionsByMetricType,
} from 'src/utils/graphsUtils/graphConstants';
import { DarkChartBox } from 'src/components/styled';
import { calculateTrendLine } from '../../../../utils/graphsUtils/graphsParser';
import 'chartjs-adapter-moment';

const { Title } = Typography;

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const ChartBox = styled(DarkChartBox)`
  width: 45%;
  text-align: center;
  h4 {
    font-size: 1rem;
  }
`;
const SpotGraphs = ({
  intl,
  theme,
  data,
  startDateTime,
  endDateTime,
  isLoading,
}) => {
  const createMetricDataPoints = metric => {
    const metricData = data?.[metric];
    return {
      labels: metricData?.points.slice().map(point => moment(point.x)),
      datasets: [
        {
          label: '',
          data: metricData?.points.slice(),
          borderColor: theme.colors.historyGraphMaxLine,
          backgroundColor: theme.colors.historyGraphMaxTick,
          fill: false,
          pointRadius: 3,
          tension: 0.3,
        },
        {
          label: intl.formatMessage(messages.trendLine),
          data: calculateTrendLine(
            metricData?.points.slice().filter(p => p.y !== null),
          ),
          borderColor: theme.colors.avgTrendLine,
          backgroundColor: theme.colors.avgTrendLine,
          fill: false,
          pointRadius: 3,
          tension: 0.3,
        },
      ],
    };
  };

  const rr = {
    data: createMetricDataPoints(METRIC_ENUM.RR),
    options: chartOptionsByMetricType()[METRIC_ENUM.RR],
  };
  const hr = {
    data: createMetricDataPoints(METRIC_ENUM.HR),
    options: chartOptionsByMetricType()[METRIC_ENUM.HR],
  };

  return (
    <Container>
      {isLoading ? (
        <Spin spinning={isLoading} />
      ) : (
        <>
          <ChartBox>
            <Title level={4}>
              <FormattedMessage {...messages.heartRate} />
            </Title>
            <Line data={hr.data} options={hr.options} />
          </ChartBox>
          <ChartBox>
            <Title level={4}>
              <FormattedMessage {...messages.respirationRate} />
            </Title>
            <Line data={rr.data} options={rr.options} />
          </ChartBox>
        </>
      )}
    </Container>
  );
};

const messages = defineMessages({
  heartRate: {
    defaultMessage: 'Heart Rate',
  },
  respirationRate: {
    defaultMessage: 'Respiration Rate',
  },
  trendLine: {
    defaultMessage: 'Trend',
  },
});

SpotGraphs.propTypes = {
  startDateTime: PropTypes.object,
  endDateTime: PropTypes.object,
  data: PropTypes.object,
  theme: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
};

SpotGraphs.defaultProps = {
  data: {},
  startDateTime: null,
  endDateTime: null,
};

export default withTheme(injectIntl(SpotGraphs));
