import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { convertUtcToLocaleDateTime } from 'src/redux/data/parser';

dayjs.extend(utc);

export const parseGetAllSessionResponse = responseData =>
  responseData.map(data => ({
    ...data,
    startTime: convertUtcToLocaleDateTime(data.startTime),
    endTime: convertUtcToLocaleDateTime(data.endTime),
  }));

export const parseGetSpotMeasurementsResponse = responseData =>
  responseData?.map(data => ({
    ...data,
    startTime: convertUtcToLocaleDateTime(data.startTime),
  }));
export const parseHriDataResponse = responseData =>
  responseData?.map(data => ({
    ...data,
    startTime: convertUtcToLocaleDateTime(data.startTime),
  }));
