import { createSlice, createAction } from '@reduxjs/toolkit';

export const STATE_KEY = 'devicePage';

export const INITIAL_STATE = {};

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {},
});
/* eslint-enable no-param-reassign */
const extraActions = {
  onLoadDevicesListPage: createAction<{ page: number; limit: number }>(
    `${STATE_KEY}/onLoadDevicesListPage`,
  ),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
