import { defineMessages, MessageDescriptor } from 'react-intl';
import { SubtenantSettingsType } from 'src/routes/GMDashboard/modules/constants';

export const messages = defineMessages({
  allTenants: {
    defaultMessage: 'All Tenants',
  },
  unique: {
    defaultMessage: 'Unique',
  },
  collective: {
    defaultMessage: 'Collective',
  },
  notSet: {
    defaultMessage: 'Unset',
  },
  multiselectPlaceholder: {
    defaultMessage: 'Choose Tenant...',
  },
  settingsDoNoMatch: {
    defaultMessage:
      'Selected subtenants settings do no match. Please select subtenants with matching settings in order to edit them.',
  },
});

export const typeToColor: { [K in SubtenantSettingsType]: string } = {
  COLLECTIVE: 'green',
  UNIQUE: '#63B7C3',
  NOT_SET: '#767881',
};

export const typeToMessage: {
  [K in SubtenantSettingsType]: MessageDescriptor;
} = {
  COLLECTIVE: messages.collective,
  UNIQUE: messages.unique,
  NOT_SET: messages.notSet,
};
