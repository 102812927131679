import React from 'react';

import { SVGProps } from '../types';

const RightArrowIcon = ({
  width = '19',
  height = '10',
  color = 'black',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      d="M0 4.98535H17.5M17.5 4.98535L13.5 1.50098M17.5 4.98535L13.5 9.49902"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
);

export default RightArrowIcon;
