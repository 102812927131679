import { fontFamily } from './theme';

export const customTheme = {
  token: {
    fontFamily: fontFamily.primary,
  },
  components: {
    Input: {
      fontFamily: fontFamily.primary,
      algorithm: true,
    },
    Select: {
      fontFamily: fontFamily.primary,
      algorithm: true,
    },
    Button: {
      fontFamily: fontFamily.primary,
      algorithm: true,
    },
    Table: {
      fontFamily: fontFamily.primary,
      fontWeight: 500,
      fontWeightStrong: 500,
      headerSplitColor: 'rgba(0,0,0,.06)',
      lineHeight: '0.9rem',
      algorithm: true,
    },
    Tabs: {
      fontFamily: fontFamily.primary,
    },
    DatePicker: {
      fontFamily: fontFamily.primary,
    },
    Tooltip: {
      fontFamily: fontFamily.primary,
      colorBgSpotlight: '#ffffff',
      colorTextLightSolid: '#252525',
    },
  },
};
