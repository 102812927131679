import { useEffect } from 'react';

export const useDetectClickOutside = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  isVisible: boolean,
  handler: () => void,
) => {
  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        isVisible &&
        ref?.current &&
        !ref?.current?.contains(e.target as Node)
      ) {
        handler();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
};
