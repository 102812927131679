import { connect } from 'react-redux';

import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { actions, selectors } from 'src/redux/data/version';

const mapStateToProps = state => ({
  isLoading: selectors.getIsLoading(state),
  versionList: selectors.getVersionsList(state),
  latestVersions: selectors.getLatestVersions(state),
  isLoadingLatestVersion: selectors.isLoadingLatestVersion(state),
  modalStatus: selectors.getModalStatus(state),
  timezone: loggedInUserSelectors.getUserTenantTimezone(state),
});

const mapActionCreators = {
  ...actions,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export default Connector;
