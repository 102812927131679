import { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray';

export const CUSTOM_SELECTOR_KEY = 'custom';

export type TimeBtnProps = {
  key: 'last24Hours' | 'last72Hours' | 'last7days';
  value: 24 | 72 | 7;
  unit: 'hour' | 'day';
};

export type ActiveBtnKey = TimeBtnProps['key'] | typeof CUSTOM_SELECTOR_KEY;

export const TIME_BUTTONS = [
  {
    key: 'last24Hours',
    value: 24,
    unit: 'hour',
  },
  {
    key: 'last72Hours',
    value: 72,
    unit: 'hour',
  },
  {
    key: 'last7days',
    value: 7,
    unit: 'day',
  },
] satisfies NonEmptyArray<TimeBtnProps>;

export const INITIAL_TIME_INTERVAL = TIME_BUTTONS[0];
