import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';

const StyledButton = styled.button`
  font-family: ${props => props.theme.fonts.primary};
  border: none;
  cursor: pointer;
  background: transparent;
  color: ${props => props.theme.colors.defaultTextColor};
  width: ${props => props.width};

  padding: ${props => props.theme.layout.defaultButtonPadding};
  border-radius: ${props => props.theme.layout.defaultBorderRadius};

  transition: 300ms;

  &:hover,
  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:disabled {
    cursor: unset;
    opacity: 0.8;
  }
`;

const StyledLoader = styled(LoadingOutlined)`
  margin-right: 5px;
`;

const TransparentButton = ({ isLoading = false, children, ...rest }) => (
  <StyledButton {...rest}>
    {isLoading && <StyledLoader />}
    {children}
  </StyledButton>
);

TransparentButton.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.node,
  ]),
};

TransparentButton.defaultProps = {
  isLoading: null,
  children: null,
};

export default TransparentButton;
