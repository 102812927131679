import { IntlShape, defineMessages } from 'react-intl';
import { ColumnsType } from 'antd/lib/table';

export const getIntegratedEMRsColumns = (
  intl: IntlShape,
): ColumnsType<{
  emrType: string;
  organizationId: string;
  integrationType: string;
}> => [
  {
    title: intl.formatMessage(messages.emr),
    dataIndex: 'emrType',
    key: 'emrType',
  },
  {
    title: intl.formatMessage(messages.organizationId),
    dataIndex: 'organizationId',
    key: 'organizationId',
    // @ts-ignore Custom logic
    editable: true,
  },
  {
    title: intl.formatMessage(messages.type),
    dataIndex: 'integrationType',
    key: 'integrationType',
  },
];

const messages = defineMessages({
  emr: {
    defaultMessage: 'EMR',
  },
  organizationId: {
    defaultMessage: 'Organization Id',
  },
  type: {
    defaultMessage: 'Type',
  },
});
