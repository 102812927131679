import React from 'react';
import { useIdleTimer } from 'react-idle-timer';

import history from 'src/utils/history';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import neteeraLogo from './resources/neteera-logo.svg';
import {
  StyledLayout,
  StyledHeader,
  StyledLogo,
  StyledContent,
} from './components/styled';
import UserMenu from './components/UserMenu';
import NavigationMenu from './components/NavigationMenu';
import Connector, { PropsFromRedux } from './Connector';
import APP_CONFIG from 'src/config/AppConfig';
import { hasGMPermissions } from 'src/utils/permissions';
import SoundSettings from '../SoundSettings';

interface Props extends PropsFromRedux {
  children: React.ReactNode;
}

const AppLayout = ({
  children,
  loggedInUserName,
  loggedInUserType,
  userTenantName,
  userTenantTimezone,
  logout,
  onIdle,
}: Props): JSX.Element => {
  const handleOnIdle = (event?: Event) => {
    if (
      hasGMPermissions() ||
      history.location.pathname.includes(appRoutes.NURSES_STATION)
    ) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log('user is idle', event);
    logout();
    onIdle(true);
  };

  useIdleTimer({
    timeout: APP_CONFIG.SESSION_TIMEOUT_SEC * 1000,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <StyledLayout data-cy="application-content">
      <StyledHeader data-cy="application-header">
        <StyledLogo src={neteeraLogo} alt="Neteera" data-cy="neteera-logo" />
        <NavigationMenu loggedInUserType={loggedInUserType} />
        <SoundSettings />
        <UserMenu
          loggedInUserName={loggedInUserName}
          userTenantName={userTenantName}
          userTenantTimezone={userTenantTimezone}
          logout={logout}
        />
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
    </StyledLayout>
  );
};

export default Connector(AppLayout);
