import {
  all,
  takeLatest,
  call,
  put,
  select,
  takeEvery,
} from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import history from 'src/utils/history';
import AppConfig from 'src/config/AppConfig';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import BackendService from 'src/services/BackendService';
import { ContinuousMeasurement } from 'src/types/measurements';
import { actions as sessionsActions } from 'src/redux/data/sessions';
import { selectors as patientsSelectors } from 'src/redux/data/patient';
import { actions as singleMonitorActions } from 'src/routes/Monitor/modules/slice';
import { fetchPatientsList } from 'src/redux/data/patient/modules/saga';
import { actions as appActions } from '../../app';
import { initMonitorActionType } from './constants';
import {
  isSessionLessThenFiveMinutes,
  isDeviceActive,
  notIn,
  removeDuplicatedPatientsAndDevices,
} from './utils';
import { LivePatientDevice } from './types';
import { actions, selectors } from './slice';

// import { extractSessions } from './monitorUtils';
// import { createAndDownloadHriCSVFile } from 'src/utils/fileUtils';

function* startContinuous(
  action: ReturnType<typeof actions.onClickStartContinuous>,
) {
  const { deviceId, patientId, saveRawData } = action.payload;
  try {
    const { data } = yield* call(
      BackendService.startContinuous,
      deviceId,
      patientId,
      saveRawData,
    );

    // TODO: Move this to monitor utils where stop is handled
    yield* put(actions.onStartContinuousSuccess(data));
    yield* put(sessionsActions.onSessionStarted(data));
  } catch (error) {
    yield* put(
      actions.onStartContinuousFail({
        actionType: action.type,
        deviceId,
        error,
      }),
    );
  }
}

function* stopContinuous(
  action: ReturnType<typeof actions.onClickStopContinuous>,
) {
  const { deviceId } = action.payload;
  const hriEnabled = AppConfig.HRI_ENABLED;
  const startTime = yield* select(selectors.getStartTime, deviceId);

  // TODO: Check if needed anymore
  if (hriEnabled && startTime && isSessionLessThenFiveMinutes(startTime)) {
    yield* put(singleMonitorActions.onChangeStopConfirmVisibleModal(true));
    return;
  }

  try {
    yield* call(BackendService.stopContinuous, deviceId);
  } catch (error) {
    yield* put(
      actions.onStopContinuousFail({
        actionType: action.type,
        deviceId,
        error,
      }),
    );
  }
}

// TODO: Check if still neded
function* onMonitorUnfocus() {
  const activeDeviceIds = yield* select(selectors.getActiveDeviceIds);

  yield* put(actions.unfocus({ activeDeviceIds }));
}

// TODO: Handle this with _onHriReceived
function* onHriResult(action: ReturnType<typeof actions.onHriResult>) {
  const { data, deviceId } = action.payload;
  if (data?.statusCode) {
    //
    console.log('HRI failed');
    yield* put(
      actions.onHRIFailed({
        actionType: action.type,
        deviceIds: deviceId,
        error: data.statusCode,
      }),
    );
  }
}

function* verifyPatients(action: PayloadAction<ContinuousMeasurement[]>) {
  try {
    const measurements = action.payload;
    const activeDevices = yield* select(selectors.getActiveDeviceIds);
    const livePatientsAndDevices = measurements
      .map(m => ({ patientId: m.patientId, deviceId: m.deviceId }))
      .filter(removeDuplicatedPatientsAndDevices)
      .filter(livePatientDevice =>
        isDeviceActive(livePatientDevice, activeDevices),
      );
    const storedPatientsDevices = (yield* select(
      selectors.getPatientsAndDevices,
    )) as LivePatientDevice[];
    const patientDevicesMismatch = livePatientsAndDevices.filter(
      measuredPatientDeviceTuple =>
        measuredPatientDeviceTuple.patientId !==
        storedPatientsDevices.find(
          storedPatientDeviceTuple =>
            storedPatientDeviceTuple.deviceId ===
            measuredPatientDeviceTuple.deviceId,
        )?.patientId,
    );

    if (patientDevicesMismatch.length > 0) {
      const patientsList = yield* select(patientsSelectors.getPatientsList);

      const nonStoredPatients = patientDevicesMismatch
        .map(p => p.patientId)
        .filter(notIn(patientsList.map(p => p.id)));

      if (nonStoredPatients.length > 0) {
        yield* call(fetchPatientsList);
      }

      yield* put(actions.setPatientsForDevices(patientDevicesMismatch));
    }
  } catch (error) {
    console.log('error in verifyPatients', error);
  }
}

// TODO: See if it makes sense to add extra conditions here
// history.location.pathname.includes(appRoutes.NURSES_STATION) ||
// history.location.pathname.includes(appRoutes.INDIVIDUAL_DASHBOARD)
function* shouldDeviceBeMonitoring(
  action: PayloadAction<ContinuousMeasurement[]>,
) {
  if (history.location.pathname.includes(appRoutes.MONITOR)) {
    const measurements = action.payload;
    const stoppingDevices = yield* select(selectors.getAllStoppingDeviceIds);
    const activeDevices = yield* select(selectors.getActiveDeviceIds);

    const errorDevices = measurements
      .map(m => m.deviceId)
      .filter(notIn(activeDevices))
      .filter(notIn(stoppingDevices));

    if (errorDevices.length > 0) {
      yield* put(
        actions.onReceivedDataFromInactiveSession({
          actionType: initMonitorActionType,
        }),
      );
    }
  }
}

// TODO: Remove this if not required
// function* startSpot(action) {
//   // TODO: complete this saga implementation when api will work
//   const { deviceId, patientId } = action.payload;
//   try {
//     console.log(
//       `starting spot for device ${deviceId} and patient ${patientId}`,
//     );
//     yield call(BackendService.startSpot, deviceId, patientId);
//   } catch (error) {
//     console.log('An error occurred while trying to start spot: ', error);
//   }
// }

// TODO: Remove this if not required
// function* onMonitorRefocus(action) {
//   const deviceIds = yield select(selectors.getDeviceIds);
//   try {
//     const devicesStatusesResponse = yield call(
//       BackendService.getAllLatestSessions,
//     );
//     const sessions = extractSessions(devicesStatusesResponse, deviceIds);
//     yield put(sessionsActions.fetchSessionsSuccess({ sessions }));
//   } catch {
//     console.log(
//       `An error occurred when getting device status for deviceIds: [${deviceIds}]`,
//     );
//   }
// }

// TODO: Remove this if not required
// // eslint-disable-next-line require-yield
// function* downloadHriCsvFile(action) {
//   if (action.payload) {
//     createAndDownloadHriCSVFile(action.payload.record.data, 'hriData.csv', []);
//   }
// }

export default function* watchMonitorActions() {
  yield* all([
    takeEvery(actions.onClickStartContinuous, startContinuous),
    takeLatest(actions.onClickStopContinuous, stopContinuous),
    takeLatest(actions.gotContinuousDataFromOnlineMonitor, verifyPatients),
    takeLatest(actions.onHriResult, onHriResult),
    takeLatest(
      actions.gotContinuousDataFromOnlineMonitor,
      shouldDeviceBeMonitoring,
    ),
    takeLatest(appActions.onAppUnfocused, onMonitorUnfocus),
    // takeLatest(actions.startSpot, startSpot),
    // takeLatest(actions.onDownloadHri, downloadHriCsvFile),
  ]);
}
