import { all, call, put, takeLatest } from 'typed-redux-saga/macro';

import BackendService from 'src/services/BackendService';
import { actions as idbActions } from 'src/routes/IndividualDashboard/modules/dashboard/slice';
import { actions } from './slice';

function* fetchPatientsSessions(
  action: ReturnType<typeof actions.fetchPatientsLatestSessions>,
) {
  const patientIds = action.payload;
  const idsQS = patientIds.length ? action.payload.join(',') : '';

  try {
    const { data } = yield* call(
      BackendService.getPatientsLatestSessions,
      idsQS,
    );
    yield* put(actions.fetchPatientsLatestSessionsSuccess(data.sessions));
  } catch (error) {
    console.error('error in fetchPatientSessions: ', error);
    yield* put(actions.fetchPatientsLatestSessionsFailed());
  }
}

function* fetchDevicesLatestSessions() {
  try {
    const { data } = yield* call(BackendService.getAllLatestSessions);
    yield* put(actions.fetchDevicesLatestSessionsSuccess(data));
  } catch (e) {
    console.error('error in fetchDevicesLatestSessions: ', e);
    yield* put(actions.fetchDevicesLatestSessionsFailed());
  }
}

export default function* watchSessionsActions() {
  yield* all([
    takeLatest(actions.fetchPatientsLatestSessions, fetchPatientsSessions),
    takeLatest(actions.fetchDevicesLatestSessions, fetchDevicesLatestSessions),
    takeLatest(idbActions.getLatestSessions, fetchDevicesLatestSessions),
  ]);
}
