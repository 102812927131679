import { defineMessages } from 'react-intl';

export const managmentMessages = defineMessages({
  // error
  downloadSessionError: {
    defaultMessage: 'Could not download session data',
  },
  noDataAvailable: {
    defaultMessage: 'No data available',
  },
});
