import React from 'react';

import { SVGProps } from '../types';

const WifiDisconnectedAlertIconWithLine = ({
  width = '26',
  height = '23',
  color = '#CA0000',
  altColor = '#CC3311',
  fillAltColor = '#FDEDE9',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <g clipPath="url(#clip0_1748_18634)">
      <path
        d="M11.0698 16.33C11.9093 16.33 12.5898 15.6495 12.5898 14.81C12.5898 13.9706 11.9093 13.29 11.0698 13.29C10.2303 13.29 9.5498 13.9706 9.5498 14.81C9.5498 15.6495 10.2303 16.33 11.0698 16.33Z"
        fill={color}
      />
      <path
        d="M5.08988 16.33C4.91988 16.33 4.74988 16.27 4.61988 16.15C4.41988 15.97 4.37988 15.76 4.37988 15.61C4.37988 15.43 4.43988 15.27 4.55988 15.14L17.7699 0.24C17.9099 0.09 18.1099 0 18.3099 0C18.4799 0 18.6499 0.06 18.7799 0.18C19.0799 0.44 19.1099 0.89 18.8399 1.2L5.62988 16.08C5.48988 16.24 5.29988 16.32 5.08988 16.32V16.33Z"
        fill={color}
      />
      <path
        d="M15.3102 11.7C14.4102 10.8 13.2702 10.23 12.0302 10.04L10.9902 11.21C12.2402 11.19 13.4902 11.65 14.4402 12.6C14.5502 12.72 14.7102 12.78 14.8802 12.78C15.0502 12.78 15.2002 12.71 15.3202 12.6V12.58C15.5602 12.34 15.5602 11.96 15.3202 11.72H15.3102V11.7Z"
        fill={color}
      />
      <path
        d="M4.00968 9.79023H4.02968V9.80023C4.26968 10.0302 4.64968 10.0202 4.88968 9.79023V9.77024C6.45968 8.22024 8.44968 7.39023 10.4797 7.26023L11.5797 6.02023C11.4097 6.02023 11.2397 5.99023 11.0597 5.99023C8.40968 5.99023 5.91968 7.01023 4.03968 8.87023H4.02968C4.02968 8.87023 4.00968 8.90023 4.00968 8.91023L3.96968 8.95023C3.75968 9.19023 3.76968 9.56023 3.99968 9.79023H4.00968Z"
        fill={color}
      />
      <path
        d="M18.1198 8.88977C17.1898 7.96977 16.1098 7.24977 14.9398 6.75977L14.0498 7.75977C15.2198 8.17977 16.3198 8.84977 17.2498 9.78977C17.3598 9.90977 17.5198 9.96977 17.6898 9.96977C17.8598 9.96977 18.0098 9.89977 18.1298 9.78977V9.76977C18.3698 9.52977 18.3698 9.14977 18.1298 8.90977H18.1198V8.88977Z"
        fill={color}
      />
      <path
        d="M20.9393 6.10066H20.9193V6.09066C19.9293 5.11066 18.8393 4.31066 17.6693 3.68066L16.8193 4.64066C17.9893 5.23066 19.0793 6.01066 20.0593 6.98066C20.1793 7.10066 20.3293 7.16066 20.4993 7.16066C20.6693 7.16066 20.8093 7.10066 20.9193 6.99066H20.9393C21.1793 6.73066 21.1793 6.34066 20.9393 6.10066Z"
        fill={color}
      />
      <path
        d="M13.7597 3.56021L14.6897 2.51021C10.0297 1.26021 4.84973 2.45021 1.19973 6.10021L1.04973 6.36021C0.979733 6.58021 1.04973 6.82021 1.19973 6.98021H1.21973V6.99021C1.45973 7.22021 1.83973 7.21021 2.07973 6.98021C5.24973 3.82021 9.67973 2.68021 13.7597 3.56021Z"
        fill={color}
      />
    </g>
    <circle
      cx="18.3965"
      cy="16"
      r="6.5"
      fill={fillAltColor}
      stroke={altColor}
    />
    <mask
      id="mask0_1748_18634"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="11"
      y="9"
      width="15"
      height="14"
    >
      <circle cx="18.3965" cy="16" r="6.5" fill="#FFAD0D" stroke="#252525" />
    </mask>
    <g mask="url(#mask0_1748_18634)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3963 12.5C18.7829 12.5 19.0963 12.8656 19.0963 13.3167V16.5833C19.0963 17.0344 18.7829 17.4 18.3963 17.4C18.0097 17.4 17.6963 17.0344 17.6963 16.5833V13.3167C17.6963 12.8656 18.0097 12.5 18.3963 12.5Z"
        fill={altColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0963 18.8001C19.0963 18.4135 18.7829 18.1001 18.3963 18.1001C18.0097 18.1001 17.6963 18.4135 17.6963 18.8001C17.6963 19.1867 18.0097 19.5001 18.3963 19.5001C18.7829 19.5001 19.0963 19.1867 19.0963 18.8001Z"
        fill={altColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_1748_18634">
        <rect width="21.12" height="16.33" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WifiDisconnectedAlertIconWithLine;
