import React from 'react';

import { SVGProps } from '../types';

const GatheringData = ({
  width = '33',
  height = '35',
  color = '#292D32',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      d="M16.4985 20.9709C18.306 20.9709 19.7712 19.4171 19.7712 17.5001C19.7712 15.5833 18.306 14.0293 16.4985 14.0293C14.6912 14.0293 13.2261 15.5833 13.2261 17.5001C13.2261 19.4171 14.6912 20.9709 16.4985 20.9709Z"
      fill={color}
    />
    <path
      opacity="0.4"
      d="M27.5004 27.3439C27.2804 27.3439 27.0741 27.2709 26.8816 27.1251C26.4279 26.7605 26.3316 26.0751 26.6754 25.5939C28.3391 23.2459 29.2191 20.4459 29.2191 17.5001C29.2191 14.5543 28.3391 11.7544 26.6754 9.40644C26.3316 8.92519 26.4279 8.23974 26.8816 7.87516C27.3354 7.51058 27.9816 7.61264 28.3254 8.09389C30.2641 10.821 31.2816 14.0731 31.2816 17.5001C31.2816 20.9272 30.2641 24.1793 28.3254 26.9064C28.1191 27.198 27.8166 27.3439 27.5004 27.3439Z"
      fill={color}
    />
    <path
      opacity="0.4"
      d="M5.5 27.3439C5.18375 27.3439 4.88124 27.198 4.67499 26.9064C2.73624 24.1793 1.71875 20.9272 1.71875 17.5001C1.71875 14.0731 2.73624 10.821 4.67499 8.09389C5.01874 7.61264 5.66501 7.51058 6.11876 7.87516C6.57251 8.23974 6.66876 8.92519 6.32501 9.40644C4.66126 11.7544 3.78125 14.5543 3.78125 17.5001C3.78125 20.4459 4.66126 23.2459 6.32501 25.5939C6.66876 26.0751 6.57251 26.7605 6.11876 27.1251C5.94001 27.2709 5.72 27.3439 5.5 27.3439Z"
      fill={color}
    />
    <path
      opacity="0.4"
      d="M23.099 23.8436C22.879 23.8436 22.6728 23.7707 22.4803 23.6249C22.0265 23.2603 21.9303 22.5749 22.274 22.0936C23.2228 20.7665 23.7178 19.1769 23.7178 17.4999C23.7178 15.8228 23.2228 14.2333 22.274 12.9062C21.9303 12.4249 22.0265 11.7395 22.4803 11.3749C22.934 11.0103 23.5803 11.1124 23.924 11.5937C25.134 13.3145 25.7803 15.3561 25.7803 17.4999C25.7803 19.6436 25.134 21.6999 23.924 23.4061C23.7178 23.6978 23.4153 23.8436 23.099 23.8436Z"
      fill={color}
    />
    <path
      opacity="0.4"
      d="M9.90001 23.8436C9.58376 23.8436 9.28126 23.6978 9.07501 23.4061C7.86501 21.6853 7.21875 19.6436 7.21875 17.4999C7.21875 15.3561 7.86501 13.2999 9.07501 11.5937C9.41876 11.1124 10.065 11.0103 10.5187 11.3749C10.9725 11.7395 11.0687 12.4249 10.725 12.9062C9.77624 14.2333 9.28125 15.8228 9.28125 17.4999C9.28125 19.1769 9.77624 20.7665 10.725 22.0936C11.0687 22.5749 10.9725 23.2603 10.5187 23.6249C10.34 23.7707 10.12 23.8436 9.90001 23.8436Z"
      fill={color}
    />
  </svg>
);

export default GatheringData;
