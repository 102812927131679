import { call, put, take, all } from 'typed-redux-saga/macro';

import { actions as alertActions } from 'src/redux/data/alerts';
import { actions as gmActions } from 'src/redux/data/groupManager';
import { UUID } from 'src/types/utility';
import { GM_DATA_KEYS } from './constants';
import { hasGMPermissions } from 'src/utils/permissions';
import { filterDuplicates } from 'src/utils/dataUtils';
import { ALERT_TYPES } from 'src/types/alerts';
import { FetchMTMAttentionListResponse } from 'src/services/types';

function* onAttentionListLoaded(payload: {
  mtmAttentionList: FetchMTMAttentionListResponse['data'];
  shouldResetData?: boolean | undefined;
}) {
  if (!hasGMPermissions()) {
    return;
  }
  const { mtmAttentionList } = payload;
  const attentionListPatientIds = filterDuplicates(
    mtmAttentionList
      .filter(alert => alert.type === ALERT_TYPES.MEDICAL)
      .reduce((acc: UUID[], alert) => {
        return [...acc, alert.patientId || ''];
      }, []),
  );
  const attentionListDeviceIds = filterDuplicates(
    mtmAttentionList
      .filter(elem => elem.type === ALERT_TYPES.MEDICAL)
      .reduce((acc: UUID[], alert) => {
        return [...acc, alert.deviceId || ''];
      }, []),
  );
  yield* all([
    put(gmActions.fetchMultitenantPatients(attentionListPatientIds)),
    put(
      gmActions.fetchSubtenantData({
        dataKey: GM_DATA_KEYS.ROOMS,
        payload: attentionListDeviceIds,
      }),
    ),
  ]);
}

export default function* watchTenantDataActions() {
  while (true) {
    const attentionListAction = yield* take(
      alertActions.fetchMTMAttentionListSuccess,
    );
    const { payload } = attentionListAction;
    yield* call(onAttentionListLoaded, payload);
  }
}
