import React, { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { UUID } from 'src/types/utility';

import { Label } from '../styled';
import SubtenantAlertLogModal from './SubtenantAlertLogModal';

type Props = {
  subtenantId: UUID;
  subtenantName: string;
};

const SubtenantAlerts = ({
  subtenantId,
  subtenantName,
}: Props): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  return (
    <Label withUnderline withPointer onClick={() => setIsModalVisible(true)}>
      <FormattedMessage {...messages.alerts} />
      <SubtenantAlertLogModal
        subtenantId={subtenantId}
        subtenantName={subtenantName}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </Label>
  );
};

const messages = defineMessages({
  alerts: {
    defaultMessage: 'Alerts',
  },
});

export default SubtenantAlerts;
