import styled from 'styled-components';
import { TimePicker, Tooltip } from 'antd';

export const CheckboxesWrapper = styled.div`
  margin-top: 0.5rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const FieldsOnSameRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  .ant-form-item-explain {
    position: absolute;
    min-width: 380px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  .ant-form-item {
    margin: 0;
    &.ant-form-item-has-error {
      input {
        border: 1px solid #ca0000;
      }
      .ant-picker-input input {
        border: none;
      }
    }
    input:disabled {
      opacity: 0.4;
    }
  }
  &:has(input[aria-invalid='true']) {
    margin-bottom: 20px;
  }
`;

export const StyledTimePicker = styled(TimePicker)`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  width: 6rem;
  height: 2rem;
  border-radius: 0;
  ${props => (props.width ? `width: ${props.width};` : '')}
  outline: none;
  padding-right: calc(12px + 0.5rem);
  .ant-picker-input input {
    font-size: 1.1rem;
    font-weight: 400;
    font-size: 1.1rem;
    text-align: center;
  }
`;
export const StyledQuestionTooltip = styled(Tooltip)`
  cursor: pointer;
  margin-left: auto;
`;
export const AlertSettingsBoldText = styled.div`
  font-weight: 700;
`;
