import { connect, ConnectedProps } from 'react-redux';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';

import {
  selectors as customersSelectors,
  actions as customersActions,
} from 'src/redux/data/customers';
import {
  selectors as usersSelectors,
  actions as usersActions,
} from 'src/redux/data/user';
import { RootState } from 'src/redux/store';

const mapStateToProps = (state: RootState) => {
  return {
    editableData: customersSelectors.selectEditableData(state),
    mtmUsers: usersSelectors.getMtmUsers(state).data,
    isLoadingMtmUsers:
      usersSelectors.getMtmUsers(state).status === DATA_FETCHING_STATUS.LOADING,
  };
};

const mapActionCreators = {
  handleChangeForm: customersActions.handleChangeForm,
  fetchMtmUsers: usersActions.fetchMtmUsers,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
