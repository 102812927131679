import React from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { UUID } from 'src/types/utility';
import DisconnectionOverviewTable from './DisconnectionOverviewTable';

import FormModal from 'src/components/general-ui/FormModal';

type Props = {
  subtenantId: UUID;
  subtenantName: string;
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  intl: IntlShape;
};

const SubtenantDisconnectionOverviewModal = ({
  subtenantId,
  subtenantName,
  isModalVisible,
  setIsModalVisible,
  intl,
}: Props): JSX.Element => {
  return (
    <FormModal
      title={`${subtenantName} ${intl.formatMessage(messages.title)}`}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      destroyOnClose={true}
      width="60rem"
    >
      <DisconnectionOverviewTable subtenantId={subtenantId} />
    </FormModal>
  );
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Disconnection Log',
  },
});

export default injectIntl(SubtenantDisconnectionOverviewModal);
