import React from 'react';

import { SVGProps } from '../types';

const WifiDisconnectedIconV2 = ({
  width = '22',
  height = '17',
  color = '#252525',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <g clipPath="url(#clip0_1445_21504)">
      <path
        d="M11.4663 16.33C12.3058 16.33 12.9863 15.6495 12.9863 14.81C12.9863 13.9706 12.3058 13.29 11.4663 13.29C10.6268 13.29 9.94629 13.9706 9.94629 14.81C9.94629 15.6495 10.6268 16.33 11.4663 16.33Z"
        fill={color}
      />
      <path
        d="M5.48637 16.33C5.31637 16.33 5.14637 16.27 5.01637 16.15C4.81637 15.97 4.77637 15.76 4.77637 15.61C4.77637 15.43 4.83637 15.27 4.95637 15.14L18.1664 0.24C18.3064 0.09 18.5064 0 18.7064 0C18.8764 0 19.0464 0.06 19.1764 0.18C19.4764 0.44 19.5064 0.89 19.2364 1.2L6.02637 16.08C5.88637 16.24 5.69637 16.32 5.48637 16.32V16.33Z"
        fill={color}
      />
      <path
        d="M15.7067 11.7C14.8067 10.8 13.6667 10.23 12.4267 10.04L11.3867 11.21C12.6367 11.19 13.8867 11.65 14.8367 12.6C14.9467 12.72 15.1067 12.78 15.2767 12.78C15.4467 12.78 15.5967 12.71 15.7167 12.6V12.58C15.9567 12.34 15.9567 11.96 15.7167 11.72H15.7067V11.7Z"
        fill={color}
      />
      <path
        d="M4.40616 9.78999H4.42616V9.79999C4.66616 10.03 5.04616 10.02 5.28616 9.78999V9.76999C6.85616 8.21999 8.84616 7.38999 10.8762 7.25999L11.9762 6.01999C11.8062 6.01999 11.6362 5.98999 11.4562 5.98999C8.80616 5.98999 6.31616 7.00999 4.43616 8.86999H4.42616C4.42616 8.86999 4.40616 8.89999 4.40616 8.90999L4.36616 8.94999C4.15616 9.18999 4.16616 9.55999 4.39616 9.78999H4.40616Z"
        fill={color}
      />
      <path
        d="M18.5163 8.88952C17.5863 7.96952 16.5063 7.24952 15.3363 6.75952L14.4463 7.75952C15.6163 8.17952 16.7163 8.84952 17.6463 9.78952C17.7563 9.90952 17.9163 9.96952 18.0863 9.96952C18.2563 9.96952 18.4063 9.89952 18.5263 9.78952V9.76952C18.7663 9.52952 18.7663 9.14952 18.5263 8.90952H18.5163V8.88952Z"
        fill={color}
      />
      <path
        d="M21.3358 6.10042H21.3158V6.09042C20.3258 5.11042 19.2358 4.31042 18.0658 3.68042L17.2158 4.64042C18.3858 5.23042 19.4758 6.01042 20.4558 6.98042C20.5758 7.10042 20.7258 7.16042 20.8958 7.16042C21.0658 7.16042 21.2058 7.10042 21.3158 6.99042H21.3358C21.5758 6.73042 21.5758 6.34042 21.3358 6.10042Z"
        fill={color}
      />
      <path
        d="M14.1562 3.55997L15.0862 2.50997C10.4262 1.25997 5.24622 2.44997 1.59622 6.09997L1.44622 6.35997C1.37622 6.57997 1.44622 6.81997 1.59622 6.97997H1.61622V6.98997C1.85622 7.21997 2.23622 7.20997 2.47622 6.97997C5.64622 3.81997 10.0762 2.67997 14.1562 3.55997Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1445_21504">
        <rect
          width={width}
          height={height}
          fill="white"
          transform="translate(0.396484)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default WifiDisconnectedIconV2;
