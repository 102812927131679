import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { selectors as alertsSelectors } from 'src/redux/data/alerts';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';

const mapStateToProps = (state: RootState) => ({
  areAlertSettingsDataLoading:
    alertsSelectors.getThresholdsStatus(state) ===
      DATA_FETCHING_STATUS.LOADING ||
    alertsSelectors.getBaselineThresholdsStatus(state) ===
      DATA_FETCHING_STATUS.LOADING ||
    alertsSelectors.getActivityAlertSettingsStatus(state) ===
      DATA_FETCHING_STATUS.LOADING,
});

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
