import React, { Dispatch, SetStateAction } from 'react';
import { ErrorState } from 'src/routes/NursesStation/components/RoomsAndBeds/DeviceSettings/AlertSettings/types';
import ThresholdSettings from 'src/routes/NursesStation/components/RoomsAndBeds/DeviceSettings/AlertSettings/ThresholdSettings';
import BaselineThresholdSettings from 'src/routes/NursesStation/components/RoomsAndBeds/DeviceSettings/AlertSettings/BaselineThresholdSettings';
import { TenantThresholdsState } from 'src/components/AlertSettingsComponents/AlertThresholdsForm';
import { TenantBaselineThresholdsState } from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';
import { deepEqual } from 'src/utils/comparators';

type Props = {
  setErrors: React.Dispatch<SetStateAction<ErrorState>>;
  thresholdsData: TenantThresholdsState;
  setThresholdsData: Dispatch<SetStateAction<TenantThresholdsState>>;
  baselineThresholdsData: TenantBaselineThresholdsState;
  setBaselineThresholdData: Dispatch<
    SetStateAction<TenantBaselineThresholdsState>
  >;
};

const MedicalAlerts = ({
  thresholdsData,
  setThresholdsData,
  baselineThresholdsData,
  setBaselineThresholdData,
  setErrors,
}: Props): JSX.Element => {
  return (
    <>
      <ThresholdSettings
        thresholdsData={thresholdsData}
        setThresholdsData={setThresholdsData}
        setIsSaveBtnDisabled={(isDisabled: boolean) =>
          setErrors(prevState => ({
            ...prevState,
            thresholds: isDisabled,
          }))
        }
      />
      <BaselineThresholdSettings
        baselineThresholdsData={baselineThresholdsData}
        setBaselineThresholdData={setBaselineThresholdData}
        setIsSaveBtnDisabled={(isDisabled: boolean) =>
          setErrors(prevState => ({
            ...prevState,
            baselineThresholds: isDisabled,
          }))
        }
      />
    </>
  );
};

export default React.memo(MedicalAlerts, (oldProps, newProps) =>
  deepEqual(oldProps, newProps),
);
