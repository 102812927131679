import React from 'react';

import StatisticsCard from './StatisticsCard';
import { PanelWrapper } from './styled';

type StatisticItem = {
  key: string;
  order: number;
  icon: string;
  title: string;
  tooltip?: string;
  value: string;
};

type Props = {
  statisticsItems: StatisticItem[];
  isAltDisplay?: boolean;
};

const PageSummaryPanel = ({
  statisticsItems,
  isAltDisplay,
}: Props): JSX.Element => {
  return (
    <PanelWrapper>
      {statisticsItems
        .sort((a, b) => a.order - b.order)
        .map(({ key, title, icon, value, tooltip }) => (
          <StatisticsCard
            key={key}
            icon={icon}
            title={title}
            value={value}
            tooltip={tooltip}
            isAltDisplay={isAltDisplay}
          />
        ))}
    </PanelWrapper>
  );
};

export default PageSummaryPanel;

export * from './resources/MTM';
export * from './resources/NS';
