import { connect } from 'react-redux';

import {
  selectors as devicesSelectos,
  actions as deviceActions,
} from 'src/redux/data/device';
import { selectors as tenantsSelectors } from 'src/redux/data/tenant';
import {
  actions as groupActions,
  selectors as groupSelectors,
} from 'src/redux/data/groups';
import {
  actions as versionActions,
  selectors as versionSelectors,
} from 'src/redux/data/version';
import dataActions from 'src/redux/data/dataActions';
import { DATA_FETCHING_STATUS } from '../../../redux/data/constants';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';

const mapStateToProps = state => {
  const originalDevicesList = devicesSelectos.getDevicesSearchResults(state);
  const groupClients = groupSelectors.getGroupClients(state);
  const tenantsList = tenantsSelectors.getTenantsList(state);
  const devicesList = originalDevicesList.map(device => ({
    ...device,
    groupName: groupClients.find(client => client.id === device.manufacturerId)
      ?.groupName,
    groupId: groupClients.find(client => client.id === device.manufacturerId)
      ?.groupId,
    tenantName: tenantsList.find(tenant => tenant.id === device.tenantId)?.name,
  }));

  // difference between MTM and normal tenant entity is the flag group: true
  const normalTenants = tenantsList.filter(tenant => !tenant.group);

  const tenantSelectOptions = normalTenants.map(tenant => ({
    value: tenant.id,
    label: tenant.name,
  }));
  const statusesArray = [
    tenantsSelectors.getStatus(state),
    groupSelectors.getGroupClientsStatus(state),
  ];
  const modalErrorStatus = statusesArray.find(
    status => status === DATA_FETCHING_STATUS.ERROR,
  );

  return {
    groupsList: groupSelectors.getGroupList(state),
    groupsListMetadata: groupSelectors.getGroupListMetadata(state),
    isLoading: groupSelectors.getIsLoading(state),
    isLoadingVersionsByDeviceType:
      versionSelectors.isLoadingVersionsByDeviceType(state),
    modalStatus: modalErrorStatus || groupSelectors.getModalStatus(state),
    latestVersions: versionSelectors.getLatestVersions(state),
    versionsByDeviceType_130H:
      versionSelectors.getVersionsByDeviceType_130H(state),
    versionsByDeviceType_130H_Plus:
      versionSelectors.getVersionsByDeviceType_130H_Plus(state),
    originalDevicesList,
    devicesList,
    tenantSelectOptions,
    isDeviceListLoading: devicesSelectos.getDevicesSearchStatus(state),
    loggedInUserType: loggedInUserSelectors.getLoggedInUserType(state),
  };
};

const mapActionCreators = {
  ...groupActions,
  onLoadLatestVersions: versionActions.onLoadLatestVersions,
  onLoadVersionsByDeviceType: versionActions.onLoadVersionsByDeviceType,
  onLoadGroupDevices: dataActions.onLoadGroupDevices,
  onLoadGroupTenants: dataActions.onLoadGroupTenants,
  searchAllDevices: deviceActions.searchAllDevicesAdmin,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export default Connector;
