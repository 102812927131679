import styled from 'styled-components';

export const StyledWrapper = styled.div``;

export const StyledThresholdsWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 3fr;
  gap: 0.5rem;
  color: #252525;
`;

export const StyledText = styled.div`
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 2rem;
  word-break: normal;
`;

export const StyledIconWrapper = styled.div`
  width: 7.6rem;
  height: 6.7rem;
  background: #eff8f9;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledIcon = styled.img<{
  w: string;
  h: string;
  background: string;
}>`
  background: ${props => props.background};
  width: ${props => props.w};
  heigth: ${props => props.h};
`;

export const StyledIconText = styled.div`
  font-weight: 400;
  font-size: 0.55rem;
  margin-top: 0.7rem;
`;

export const StyledInfo = styled(StyledText)`
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 1.2rem;
  text-align: center;
`;
