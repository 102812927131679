import { connect, ConnectedProps } from 'react-redux';

import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { RootState } from 'src/redux/store';

const mapStateToProps = (state: RootState) => ({
  tenant: loggedInUserSelectors.getUserTenant(state),
});

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;

export default Connector;
