import { defineMessages } from 'react-intl';

export const customersTableMessages = defineMessages({
  customerName: {
    defaultMessage: 'Name',
  },
  emr: {
    defaultMessage: 'EMR',
  },
  integrationType: {
    defaultMessage: 'Integration Type',
  },
  numberOfTenants: {
    defaultMessage: 'Number of Tenants',
  },
  tenantsName: {
    defaultMessage: 'Tenants Name',
  },
  numberOfUsers: {
    defaultMessage: 'Number of Users',
  },
  numberOfDevices: {
    defaultMessage: 'Number of Devices',
  },
});

export const customersTableActions = defineMessages({
  edit: {
    defaultMessage: 'Edit',
  },
  delete: {
    defaultMessage: 'Delete',
  },
});

export const wizardMessages = defineMessages({
  previous: {
    defaultMessage: 'Previous',
  },
  next: {
    defaultMessage: 'Next',
  },
  done: {
    defaultMessage: 'Done',
  },
  addCustomer: {
    defaultMessage: 'Add Customer',
  },
  editCustomer: {
    defaultMessage: 'Edit Customer',
  },
  mapFacilities: {
    defaultMessage: 'Map Facilites',
  },
  emrUpdateFrequency: {
    defaultMessage: 'EMR Update Frequency',
  },
});
