import { fromEquals, struct } from 'fp-ts/lib/Eq';

import {
  EditableThreshold,
  TenantThresholdsState,
} from 'src/components/AlertSettingsComponents/AlertThresholdsForm';
import { ThresholdState } from 'src/components/AlertSettingsComponents/AlertThresholdsForm/types';
import {
  EditableBaselineThreshold,
  TenantBaselineThresholdsState,
} from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';
import {
  TenantSubscriptionDetails,
  MedicalSubscriptionDetails,
  TechnicalSubscriptionDetails,
  ActivitySubscriptionDetails,
} from './types';

const eqThresholdItem = fromEquals<EditableThreshold>(
  (t1, t2) => t1.value === t2.value,
);

const eqThresholdState = struct<ThresholdState>({
  ABOVE: eqThresholdItem,
  BELOW: eqThresholdItem,
});

export const eqTenantThresholds = struct<TenantThresholdsState>({
  HR: eqThresholdState,
  RR: eqThresholdState,
});

const eqBaselineThresholdItem = fromEquals<EditableBaselineThreshold>(
  (t1, t2) => {
    if (t1.enable !== t2.enable) {
      return false;
    }

    if (!t1.enable) {
      return true;
    }

    return (
      t1.baselineDaysInterval === t2.baselineDaysInterval &&
      t1.deviationHoursInterval === t2.deviationHoursInterval &&
      t1.deviationPercentage === t2.deviationPercentage
    );
  },
);

export const eqTenantBaselineThresholds = struct<TenantBaselineThresholdsState>(
  {
    HR_BASELINE: eqBaselineThresholdItem,
    RR_BASELINE: eqBaselineThresholdItem,
  },
);

const eqMedicalSubscriptionDetails = fromEquals<MedicalSubscriptionDetails>(
  (s1, s2) =>
    s1.isAssigned === s2.isAssigned &&
    s1.withPersonalDetails === s2.withPersonalDetails,
);
const eqActivitySubscriptionDetails = fromEquals<ActivitySubscriptionDetails>(
  (s1, s2) => s1.isAssigned === s2.isAssigned,
);

const eqTechnicalSubscriptionDetails = fromEquals<TechnicalSubscriptionDetails>(
  (s1, s2) =>
    s1.isAssigned === s2.isAssigned &&
    s1.summaryReportEnabled === s2.summaryReportEnabled &&
    s1.summaryReportTime === s2.summaryReportTime &&
    s1.singleDisconnectionAllowedType === s2.singleDisconnectionAllowedType &&
    s1.emailCcEnabled === s2.emailCcEnabled &&
    s1.emailCcRecipients === s2.emailCcRecipients,
);

const eqMedicalStructSubscriptionDetails = struct<{
  SMS: MedicalSubscriptionDetails;
  EMAIL: MedicalSubscriptionDetails;
}>({
  SMS: eqMedicalSubscriptionDetails,
  EMAIL: eqMedicalSubscriptionDetails,
});

const eqTechnicalStructSubscriptionDetails = struct<{
  SMS: TechnicalSubscriptionDetails;
  EMAIL: TechnicalSubscriptionDetails;
}>({
  SMS: eqTechnicalSubscriptionDetails,
  EMAIL: eqTechnicalSubscriptionDetails,
});

const eqActivityStructSubscriptionDetails = struct<{
  SMS: ActivitySubscriptionDetails;
  EMAIL: ActivitySubscriptionDetails;
}>({
  SMS: eqActivitySubscriptionDetails,
  EMAIL: eqActivitySubscriptionDetails,
});

export const eqTenantSubscriptionDetails = struct<TenantSubscriptionDetails>({
  MEDICAL: eqMedicalStructSubscriptionDetails,
  TECHNICAL: eqTechnicalStructSubscriptionDetails,
  ACTIVITY: eqActivityStructSubscriptionDetails,
});
