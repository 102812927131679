import React, { useEffect, useState } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

import { UUID } from 'src/types/utility';
import { StyledModal } from './styled';
import { ErrorState } from './types';
import { StyledTabs } from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/styled';
import { SUBTENANT_ALERT_SETTINGS_TABS } from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantCard/IndividualSubtenantSettings/utils';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import Connector, { PropsFromRedux } from './SubtenantAlertSettingsConnector';
import { TenantSubscriptionDetails } from 'src/routes/GMDashboard/modules/types';
import { MTMAlertSettingsOnSaveHandlers } from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/types';
import { deepEqual } from 'src/utils/comparators';
import { getTenantSubscribersWithGM } from 'src/routes/GMDashboard/modules/settingsUtils';
import dayjs from 'dayjs';
import { TenantThresholdsState } from 'src/components/AlertSettingsComponents/AlertThresholdsForm';
import { TenantBaselineThresholdsState } from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';
import {
  ActivityAlertSettings,
  SelectedLevelAlertSettings,
} from 'src/components/AlertSettingsComponents/ActivityAlerts/types';
import { defaultActivityFormData } from 'src/components/AlertSettingsComponents/ActivityAlerts/utils';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';
import { StyledTitle } from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantCard/IndividualSubtenantSettings/SubtenantCombinedSettings/styled';

type Props = PropsFromRedux & {
  subtenantId: UUID;
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  intl: IntlShape;
};
const DEFAULT_SAVE_HANDLERS: MTMAlertSettingsOnSaveHandlers = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveAlertThresholds: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveBaselineThresholds: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveSubscribers: () => {},
};
const SubtenantAlertSettingsModal = ({
  gmUserId,
  subtenant,
  subtenantId,
  isModalVisible,
  setIsModalVisible,
  fetchSubtenantData,
  gmSubtenantSubscriptionDetails,
  updateSubtenantSubscribersList,
  subtenantSubscribers,
  updateSubtenantActivityAlertSettings,
  activityAlertSettingsFromServer,
  subtenantThresholds,
  subtenantBaselineThresholds,
  isDataLoading,
  intl,
}: Props): JSX.Element => {
  const [_onSave, setOnSave] = useState<() => void>();
  const [isActivityFormValid, setIsActivityFormValid] = useState(true);
  const [errors, setErrors] = useState<ErrorState>({
    thresholds: false,
    baselineThresholds: false,
    technicalAlerts: false,
  });
  const [subscriptionDetails, setSubscriptionDetails] =
    useState<TenantSubscriptionDetails>(gmSubtenantSubscriptionDetails);
  const [onSaveHandlers, setOnSaveHandlers] =
    useState<MTMAlertSettingsOnSaveHandlers>(DEFAULT_SAVE_HANDLERS);
  const [thresholdsData, setThresholdsData] =
    useState<TenantThresholdsState>(subtenantThresholds);
  const [baselineThresholdsData, setBaselineThresholdsData] =
    useState<TenantBaselineThresholdsState>(subtenantBaselineThresholds);
  const [activityAlertSettings, setActivityAlertSettings] = useState<
    ActivityAlertSettings | SelectedLevelAlertSettings
  >(activityAlertSettingsFromServer?.activityAlertSettings);

  useEffect(() => {
    fetchSubtenantData({
      subtenantIds: [subtenantId],
      dataKey: GM_DATA_KEYS.ALERT_THRESHOLDS,
    });
    fetchSubtenantData({
      subtenantIds: [subtenantId],
      dataKey: GM_DATA_KEYS.BASELINE_ALERT_THRESHOLDS,
    });
    fetchSubtenantData({
      subtenantIds: [subtenantId],
      dataKey: GM_DATA_KEYS.SUBSCRIBERS,
    });
    fetchSubtenantData({
      subtenantIds: [subtenantId],
      dataKey: GM_DATA_KEYS.ACTIVITY_ALERTS_SETTINGS,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setThresholdsData(subtenantThresholds);
  }, [JSON.stringify(subtenantThresholds)]);

  useEffect(() => {
    setBaselineThresholdsData(subtenantBaselineThresholds);
  }, [JSON.stringify(subtenantBaselineThresholds)]);

  useEffect(() => {
    setSubscriptionDetails(gmSubtenantSubscriptionDetails);
  }, [JSON.stringify(gmSubtenantSubscriptionDetails)]);

  useEffect(
    () =>
      setOnSaveHandlers(prevState => ({
        ...prevState,
        saveSubscribers: () =>
          updateSubtenantSubscribersList({
            subtenantIds: [subtenantId],
            data: getTenantSubscribersWithGM(
              subtenantSubscribers,
              gmUserId ?? '',
              subscriptionDetails,
            ),
          }),
      })),
    [subscriptionDetails, subtenantSubscribers, gmUserId],
  );

  useEffect(() => {
    if (!activityAlertSettingsFromServer?.activityAlertSettings) {
      return;
    }
    const { selectedLevelAlertSettings } =
      activityAlertSettingsFromServer.activityAlertSettings;
    setActivityAlertSettings(
      selectedLevelAlertSettings?.outOfBedAlertSettings
        ? {
            ...selectedLevelAlertSettings,
            // tenantIds: tenantIds?.[0],
            outOfBedAlertSettings: {
              ...selectedLevelAlertSettings.outOfBedAlertSettings,
              bedtimeExitAlertSettings: {
                ...selectedLevelAlertSettings.outOfBedAlertSettings
                  .bedtimeExitAlertSettings,
                startTime: dayjs(
                  selectedLevelAlertSettings.outOfBedAlertSettings
                    .bedtimeExitAlertSettings.startTime,
                  'HH',
                ),
                endTime: dayjs(
                  selectedLevelAlertSettings.outOfBedAlertSettings
                    .bedtimeExitAlertSettings.endTime,
                  'HH',
                ),
              },
            },
          }
        : {
            ...defaultActivityFormData,
            tenantIds: [subtenantId],
          },
    );
  }, [activityAlertSettingsFromServer]);

  return (
    <StyledModal
      data-cy={`mtm-subtenant-settings-loading=${isDataLoading.toString()}`}
      title={intl.formatMessage(messages.alerts)}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      withCancelBtn={true}
      withSaveBtn={true}
      destroyOnClose={true}
      saveBtnProps={{
        isDisabled:
          errors.thresholds ||
          errors.baselineThresholds ||
          !isActivityFormValid ||
          errors.technicalAlerts,
        onClick: () => {
          Object.values(onSaveHandlers).forEach(handler => {
            handler();
          });
          updateSubtenantActivityAlertSettings({
            subtenantIds: [subtenantId],
            data: {
              ...(activityAlertSettings as SelectedLevelAlertSettings),
              outOfBedAlertSettings: {
                ...(activityAlertSettings as SelectedLevelAlertSettings)
                  .outOfBedAlertSettings,
                bedtimeExitAlertSettings: {
                  ...(activityAlertSettings as SelectedLevelAlertSettings)
                    .outOfBedAlertSettings.bedtimeExitAlertSettings,
                  startTime: dayjs(
                    (activityAlertSettings as SelectedLevelAlertSettings)
                      .outOfBedAlertSettings.bedtimeExitAlertSettings.startTime,
                  ).format('HH:mm'),
                  endTime: dayjs(
                    (activityAlertSettings as SelectedLevelAlertSettings)
                      .outOfBedAlertSettings.bedtimeExitAlertSettings.endTime,
                  ).format('HH:mm'),
                },
              },
            },
          });
        },
      }}
    >
      <StyledTabs
        destroyInactiveTabPane
        items={SUBTENANT_ALERT_SETTINGS_TABS.map(
          ({
            key,
            labelMessage,
            component: Component,
            editablePermissions,
          }) => ({
            key: key,
            label: <span>{intl.formatMessage(labelMessage)}</span>,
            children: (
              <>
                {isDataLoading && <LoadingOverlay />}
                <StyledTitle>{subtenant.label}</StyledTitle>
                <Component
                  setOnSaveHandlers={setOnSaveHandlers}
                  subtenantId={subtenantId}
                  setOnSave={setOnSave}
                  setErrors={setErrors}
                  thresholdsData={thresholdsData}
                  setThresholdsData={setThresholdsData}
                  baselineThresholdsData={baselineThresholdsData}
                  setBaselineThresholdsData={setBaselineThresholdsData}
                  gmSubtenantSubscriptionDetails={
                    gmSubtenantSubscriptionDetails
                  }
                  subscriptionDetails={subscriptionDetails}
                  setSubscriptionDetails={setSubscriptionDetails}
                  activityAlertSettings={activityAlertSettings}
                  setActivityAlertSettings={setActivityAlertSettings}
                  setIsFormValid={setIsActivityFormValid}
                  editablePermissions={editablePermissions}
                />
              </>
            ),
          }),
        )}
      />
    </StyledModal>
  );
};

const messages = defineMessages({
  alerts: {
    defaultMessage: 'Alert Settings',
  },
});

export default Connector(
  injectIntl(
    React.memo(SubtenantAlertSettingsModal, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
