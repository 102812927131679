import React from 'react';

import { SVGProps } from '../types';

const ClearAlertIcon = ({
  width = '512',
  height = '512',
  color = '#333333',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <g id="Layer_1" />
    <g id="Layer_2" fill={color}>
      <g>
        <g>
          <path
            className="st0"
            d="M388.31,110.71h-63.59C323.89,73.51,293.39,43.5,256,43.5s-67.89,30.01-68.72,67.21h-63.59     c-24.34,0-44.08,19.73-44.08,44.08v1.25c0,24.34,19.73,44.08,44.08,44.08h264.61c24.34,0,44.08-19.73,44.08-44.08v-1.25     C432.38,130.44,412.65,110.71,388.31,110.71z M256,75.05c19.99,0,36.35,15.86,37.16,35.66h-74.33     C219.65,90.91,236.01,75.05,256,75.05z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M123.7,231.66c-1.49,0-2.97-0.04-4.43-0.13v183.29c0,29.64,24.03,53.67,53.67,53.67h166.11     c29.64,0,53.67-24.03,53.67-53.67V231.54c-1.46,0.09-2.94,0.13-4.43,0.13H123.7z M230.95,409.91c0,8.71-7.07,15.78-15.78,15.78     c-8.72,0-15.78-7.07-15.78-15.78V294.28c0-8.72,7.06-15.78,15.78-15.78c8.71,0,15.78,7.06,15.78,15.78V409.91z M312.6,409.91     c0,8.71-7.06,15.78-15.78,15.78c-8.71,0-15.78-7.07-15.78-15.78V294.28c0-8.72,7.07-15.78,15.78-15.78     c8.72,0,15.78,7.06,15.78,15.78V409.91z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ClearAlertIcon;
