import React, { useState, useEffect, useRef } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { Select, Input, SelectProps } from 'antd';
import { RefSelectProps } from 'antd/lib/select';

import { PatientWithAssignedDevice } from 'src/types/patients';
import {
  PatientSelectorWrapper,
  PatientDetails,
  PatientName,
  PatientStatus,
} from '../styled';
import { PropsFromRedux } from '../Connector';

const { Option } = Select;
const { Search } = Input;

type Props = Pick<
  PropsFromRedux,
  'patientsList' | 'searchFocusStatus' | 'onStartWithNoPatient'
> & {
  patientId: string | null;
  setSelectedPatientId: (id: string) => void;
  intl: IntlShape;
  disabled: boolean;
};

const renderPopupContainer: SelectProps['getPopupContainer'] = trigger =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  trigger;

const PatientSelector = ({
  intl,
  patientsList,
  setSelectedPatientId,
  patientId,
  disabled,
  searchFocusStatus,
  onStartWithNoPatient,
}: Props): JSX.Element => {
  const [patients, setPatients] = useState<PatientWithAssignedDevice[]>([]);

  const searchPatient = useRef<RefSelectProps>(null);

  useEffect(() => {
    // @ts-ignore Select Props are not working properly
    searchFocusStatus && searchPatient.current?.focus(searchFocusStatus);
  }, [searchFocusStatus]);

  useEffect(() => {
    setPatients(patientsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(patientsList)]);

  const onSearch = (value: string) =>
    setPatients(
      patientsList.filter(p =>
        p.fullName.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  return (
    <PatientSelectorWrapper
      placeholder={intl.formatMessage(messages.selectPatient)}
      getPopupContainer={renderPopupContainer}
      value={patientId}
      disabled={disabled}
      ref={searchPatient}
      onBlur={() => onStartWithNoPatient(false)}
      onSelect={(id: unknown) => setSelectedPatientId(id as string)}
      dropdownRender={menu => (
        <>
          <div className="search-input">
            <Search
              placeholder={intl.formatMessage(messages.search)}
              onChange={e => onSearch(e.target.value)}
            />
          </div>
          {menu}
          <div className="dropdown-info-text">
            {intl.formatMessage(messages.startSearching)}
          </div>
        </>
      )}
    >
      {patients.map(patient => (
        <Option key={patient.id} value={patient.id}>
          <PatientDetails>
            <PatientName>{patient.fullName}</PatientName>
            {patient.deviceId && (
              <PatientStatus>
                {intl.formatMessage(messages.assigned)}
              </PatientStatus>
            )}
          </PatientDetails>
        </Option>
      ))}
    </PatientSelectorWrapper>
  );
};

const messages = defineMessages({
  selectPatient: {
    defaultMessage: 'Select Patient',
  },
  startSearching: {
    defaultMessage: 'Start searching to get accurate results',
  },
  search: {
    defaultMessage: 'Search',
  },
  assigned: {
    defaultMessage: 'ASSIGNED',
  },
});

export default injectIntl(PatientSelector);
