import { defineMessages } from 'react-intl';

export const settingsMessages = defineMessages({
  // error
  updateSettingsError: {
    defaultMessage: 'Could not update measurement settings',
  },
  updateSettingsSuccess: {
    defaultMessage: 'Successfully update measurement settings',
  },
  invalidLevels: {
    defaultMessage: 'Visual Alert value cannot exceed Sound Alert Value',
  },
  settingsError: {
    defaultMessage: 'Could not update settings',
  },
});
