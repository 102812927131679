import { RootState } from 'src/redux/store';
import { selectors as alertSelectors } from 'src/redux/data/alerts';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { UUID } from 'src/types/utility';
import { mergeArraysWithUniqueIds } from 'src/redux/data/dataUtils';
import { correctAlertLogTimestamps } from 'src/redux/data/alerts/modules/utils';
import { selectors as individualDashboardSelectors } from 'src/routes/IndividualDashboard/modules/dashboard/slice';
import { AlertLog } from 'src/types/alerts';
import {
  isMedicalAlert,
  isMedicalAlertLog,
} from 'src/redux/data/alerts/modules/typeGuards';

export const selectAlertLogItems = (
  state: RootState,
  patientId: UUID,
): AlertLog[] => {
  const timezone = loggedInUserSelectors.getUserTenantTimezone(state);

  const ongoingAlertLogs = alertSelectors
    .selectAlerts(state)
    .filter(alert => alert.patientId === patientId && alert.status !== 'OFF')
    .filter(isMedicalAlert)
    .map(alert => ({
      ...alert,
      tenantId: '',
    }));
  const lastDayAlertLogs = individualDashboardSelectors
    .selectAlertsLog(state)
    .filter(isMedicalAlertLog);

  const totalAlertLogs = [
    ...mergeArraysWithUniqueIds(lastDayAlertLogs, ongoingAlertLogs),
  ].sort((a, b) => b.startTime.localeCompare(a.startTime));

  return totalAlertLogs.map(alertLog =>
    correctAlertLogTimestamps(alertLog, timezone),
  );
};
