import React from 'react';

import { SVGProps } from '../types';

const SoundOnIcon = ({
  width = '25',
  height = '24',
  color = '#767881',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.98492 14.9509C6.11278 14.8941 5.45044 14.1433 5.50292 13.2709V10.6709C5.45044 9.79849 6.11278 9.04766 6.98492 8.9909H8.09092C8.42246 8.98881 8.74208 8.867 8.99092 8.6479L10.5339 7.3169C11.003 6.94728 11.6485 6.8965 12.1696 7.18823C12.6907 7.47997 12.9848 8.0568 12.9149 8.6499V15.2919C12.9848 15.885 12.6907 16.4618 12.1696 16.7536C11.6485 17.0453 11.003 16.9945 10.5339 16.6249L8.98892 15.2939C8.74008 15.0748 8.42046 14.953 8.08892 14.9509H6.98492Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.365 7.08694C18.1989 6.86634 17.8854 6.82216 17.6648 6.98826C17.4442 7.15436 17.4 7.46785 17.5661 7.68845L18.365 7.08694ZM17.5661 16.2539C17.4 16.4745 17.4442 16.788 17.6648 16.9541C17.8854 17.1202 18.1989 17.0761 18.365 16.8554L17.5661 16.2539ZM15.8553 8.20725C15.675 7.99808 15.3593 7.97468 15.1501 8.15497C14.941 8.33526 14.9175 8.65098 15.0978 8.86014L15.8553 8.20725ZM15.0978 15.0822C14.9175 15.2914 14.941 15.6071 15.1501 15.7874C15.3593 15.9677 15.675 15.9443 15.8553 15.7351L15.0978 15.0822ZM17.5661 7.68845C19.4756 10.2244 19.4756 13.718 17.5661 16.2539L18.365 16.8554C20.5426 13.9634 20.5426 9.97903 18.365 7.08694L17.5661 7.68845ZM15.0978 8.86014C16.6389 10.6479 16.6389 13.2945 15.0978 15.0822L15.8553 15.7351C17.7197 13.5722 17.7197 10.3702 15.8553 8.20725L15.0978 8.86014Z"
      fill={color}
    />
  </svg>
);

export default SoundOnIcon;
