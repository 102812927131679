import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import Modal from 'src/components/Modal';
import DatesRange from '../../../components/datesRange/DatesRange';

const TimesTempModal = ({
  isModalVisible,
  setIsModalVisible,
  intl,
  setRangeTimeForSession,
}) => {
  const [rangeTime, setRangeTime] = useState(null);

  return (
    <Modal
      title={intl.formatMessage(messages.setTimesTamp)}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      onOk={() => {
        setIsModalVisible(false);
        setRangeTimeForSession(rangeTime);
      }}
    >
      <DatesRange
        onApplyPressed={(fromDate, toDate) =>
          setRangeTime({ fromDate, toDate })
        }
        applyButton={false}
      />
    </Modal>
  );
};

const messages = defineMessages({
  setTimesTamp: {
    defaultMessage: 'Set requested range',
  },
});

TimesTempModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  setRangeTimeForSession: PropTypes.func.isRequired,
};

export default injectIntl(TimesTempModal);
