import { styled, css } from 'styled-components';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

export const ArrowStyle = css`
  color: ${props => props.theme.colors.dateTypeButtonBackground};
`;

export const RightArrow = styled(RightOutlined)`
  ${ArrowStyle}

  &.anticon {
    ${props =>
      props.disabled &&
      `
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
    `}
  }
`;

export const LeftArrow = styled(LeftOutlined)`
  ${ArrowStyle}
`;

export const StyledDateTypePicker = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
`;

export const DatePickerWithArrows = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.2rem;
  font-family: ${props => props.theme.fonts.secondary};
`;
