import { all, takeLatest, call, put, select } from 'redux-saga/effects';

import BackendService from 'src/services/BackendService';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import { actions, selectors } from './slice';
import { createAndDownloadSessionDataCSVFile } from '../../../../utils/fileUtils';
import { convertLocaleDateTimeToUtc } from '../../parser';

function* onApplyDevice(action) {
  const deviceId = yield select(selectors.getDeviceID);
  const timeRange = yield select(selectors.getTimeRange);
  const startDateTime = convertLocaleDateTimeToUtc(timeRange?.from);
  const endDateTime = convertLocaleDateTimeToUtc(timeRange?.to);
  try {
    const sessions = yield call(BackendService.getSessionsForDevice, {
      deviceId,
      startDateTime,
      endDateTime,
    });
    yield put(actions.onGetSessions(sessions?.data?.continuousSessions));
  } catch (error) {
    console.log('error in get sessions for device');
    yield put(actions.getSessionsFailed());
  }
}

function* onDownloadSession(action) {
  const { sessionId, timesTampObject } = action.payload;
  try {
    const startTime = timesTampObject?.fromDateFormatted
      ? convertLocaleDateTimeToUtc(timesTampObject.fromDateFormatted)
      : null;
    const endTime = timesTampObject?.toDateFormatted
      ? convertLocaleDateTimeToUtc(timesTampObject.toDateFormatted)
      : null;
    const response = yield call(BackendService.getSessionDataToDownload, {
      sessionId,
      startTime,
      endTime,
    });
    const deviceResponse = yield call(
      BackendService.getDeviceById,
      action.payload.deviceId,
    );
    yield call(
      createAndDownloadSessionDataCSVFile,
      action.payload,
      response?.data?.measurements,
      deviceResponse?.data?.data[0]?.fwVersion,
      `session ${sessionId}.csv`,
    );
  } catch (error) {
    console.log('error in download session CSV', error);
    notifyUserByActionTypeAndCode(action.type, sessionId, error);
  }
}
export default function* watchTenantActions() {
  yield all([
    takeLatest(actions.onApplyDevice, onApplyDevice),
    takeLatest(actions.onDownloadSession, onDownloadSession),
  ]);
}
