import { actions as patientDataActions } from 'src/redux/data/patient/modules/slice';
import { intl, messages } from '../messages';

export const patientErrorCodes = {
  PATIENT_NOT_FOUND: 'PATIENT_NOT_FOUND',
  PATIENT_ALREADY_EXIST: 'PATIENT_ALREADY_EXIST',
};
export const patientActionToErrorNotificationMessagesMap = {
  [patientDataActions.deletePatient.type]: (_, __) => ({
    message: intl.formatMessage(messages.deletePatientError),
    description: intl.formatMessage(messages.deletePatientError),
  }),
  [patientDataActions.updatePatient.type]: (patientId, errorCode) => {
    const notificationMessageForErrorCode = {
      [patientErrorCodes.PATIENT_NOT_FOUND]: {
        message: intl.formatMessage(messages.userNotFoundError),
        description: `${patientId} ${intl.formatMessage(
          messages.userNotFoundError,
        )}`,
      },
    };
    const notificationMessageForErrorCodeHandler = {
      get(mapTarget, error) {
        return (
          mapTarget[error] || {
            message: intl.formatMessage(messages.updatePatientError),
            description: `${intl.formatMessage(
              messages.updatePatientError,
            )} ${patientId}`,
          }
        );
      },
    };
    const proxyNotificationMessageForErrorCode = new Proxy(
      notificationMessageForErrorCode,
      notificationMessageForErrorCodeHandler,
    );
    return proxyNotificationMessageForErrorCode[errorCode];
  },
  [patientDataActions.createPatient.type]: (patientId, errorCode) => {
    const notificationMessageForErrorCode = {
      [patientErrorCodes.PATIENT_ALREADY_EXIST]: {
        message: intl.formatMessage(messages.patientAlreadyExistsError),
        description: `${patientId} ${intl.formatMessage(
          messages.patientAlreadyExistsError,
        )}`,
      },
    };
    const notificationMessageForErrorCodeHandler = {
      get(mapTarget, error) {
        return (
          mapTarget[error] || {
            message: intl.formatMessage(messages.createPatientError),
            description: `${intl.formatMessage(
              messages.createPatientError,
            )} ${patientId}`,
          }
        );
      },
    };
    const proxyNotificationMessageForErrorCode = new Proxy(
      notificationMessageForErrorCode,
      notificationMessageForErrorCodeHandler,
    );
    return proxyNotificationMessageForErrorCode[errorCode];
  },
};

export const patientActionToSuccessNotificationMessagesMap = {};
