import React from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import Loader from 'src/components/general-ui/Loader';

import SelectBox from 'src/components/general-ui/SelectBox';
import { Version } from 'src/types/groups';
import { UUID } from 'src/types/utility';

type VersionType = Version & { description: string };

type Props = {
  intl: IntlShape;
  loading: boolean;
  versions: VersionType[];
  defaultValue: string;
  onChange(id: UUID | null): UUID;
  deviceType: string;
  disabled: boolean;
  onLoadVersionsByDeviceType: (deviceType: string) => void;
};

const SelectVersion = ({
  versions,
  loading,
  defaultValue,
  onChange,
  deviceType,
  disabled,
  onLoadVersionsByDeviceType,
  intl,
}: Props): JSX.Element => {
  const getVersionOptionLabel = (version: VersionType) => {
    return version.description
      ? `${version.name} ${version.description}`
      : version.name;
  };

  const validVersions = versions?.filter(version => version.valid);

  const versionOptions = validVersions?.map(version => ({
    value: version.id,
    label: getVersionOptionLabel(version),
  }));

  return (
    <SelectBox
      defaultValue={defaultValue}
      options={versionOptions}
      handleChange={onChange}
      placeholder={intl.formatMessage(messages.selectVersion)}
      disabled={disabled}
      showSearch={false}
      suffixIcon={null}
      onFocus={() => !versions && onLoadVersionsByDeviceType(deviceType)}
      notFoundContent={loading ? <Loader /> : null}
    />
  );
};

const messages = defineMessages({
  selectVersion: {
    defaultMessage: 'Select Version',
  },
});

export default injectIntl(SelectVersion);
