import styled from 'styled-components';

import Tooltip from 'src/components/general-ui/Tooltip';

export const StyledOpenBtn = styled.div`
  cursor: pointer;
  align-self: flex-start;
`;

export const StyledContainer = styled.div`
  font-size: 0.65rem;
  padding: 0.6rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCheckboxText = styled.div`
  margin-right: 0.5rem;
`;

export const PatientSettingsTooltip = styled(Tooltip)`
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: #d8d8d8;
  }
  div.ant-tooltip-inner {
    font-family: ${props => props.theme.fonts.primary};
    color: #252525;
    padding: 0;
    height: 2.25rem;
    width: 9rem;

    background: #ffffff;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    border-left: 0.5px solid #dddddd;
    border-right: 0.5px solid #dddddd;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.125109);
    border-radius: 3px;
  }
`;
