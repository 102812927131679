import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { Form, Input } from 'antd';
import Modal from 'src/components/Modal';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import Table from 'src/components/CrudCommonComponents/Table';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import { styled } from 'styled-components';
import SelectTenantBox from '../SelectTenantBox';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { getGroupDevicesColumns } from './utils';
import { deepEqual } from 'src/utils/comparators';

const StyleQuestion = styled.div`
  font-size: 18px;
  text-align: center;
`;
const StyleDescription = styled.div`
  font-size: 18px;
  margin-bottom: 18px;
`;
const GroupModal = ({
  isModalVisible,
  setIsModalVisible,
  onOk,
  mode,
  modalStatus,
  setModalStatus,
  intl,
  devicesList = [],
  onLoadDeviceList,
  selectedGroup,
  tenantSelectOptions,
  isLoading,
  groupModalModes,
  searchAllDevices,
}) => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedDevicetoConfirm, setSelectedDeviceToConfirm] = useState();
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [selectedTenantId, setTenantId] = useState(null);
  const [searchKeyWord, setSearchFilter] = useState(null);

  const [newSelectedDevices, setNewSelectedDevices] = useState([]);
  const [unselectedDevices, setUnselectedDevices] = useState([]);

  useEffect(() => {
    if (isModalVisible && selectedTenantId) {
      onLoadDeviceList(selectedTenantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible, selectedTenantId]);

  const onChangeTenantId = tenantId => {
    setTenantId(tenantId);
    if (!tenantId) {
      setTableData([]);
    }
  };

  const resetData = useCallback(() => {
    form.resetFields();
    setTenantId(null);
    setSearchFilter(null);
    setTableData([]);
    setNewSelectedDevices([]);
    setUnselectedDevices([]);
    setSelectedRowKeys([]);
  }, [form]);

  useEffect(() => {
    if (isModalVisible) {
      resetData();
    }
  }, [isModalVisible, resetData]);

  useEffect(() => {
    setSelectedRowKeys([
      ...newSelectedDevices,
      ...devicesList
        .filter(
          device =>
            !!selectedGroup.id &&
            device.groupId === selectedGroup.id &&
            !unselectedDevices.includes(device.manufacturerId),
        )
        .map(device => device.manufacturerId),
    ]);
  }, [devicesList]);

  useEffect(() => {
    if (isModalVisible && (selectedTenantId || searchKeyWord)) {
      if (modalStatus === MODAL_STATUS.INITIAL) {
        setTableData(
          devicesList.map(device => ({
            key: device.manufacturerId,
            ...device,
          })),
        );
      } else if (
        modalStatus === MODAL_STATUS.SUCCESS ||
        modalStatus === MODAL_STATUS.ERROR
      ) {
        setIsModalVisible(false);
      }
    }
    if (!selectedTenantId && !searchKeyWord) {
      setTableData([]);
    }
  }, [
    isModalVisible,
    selectedTenantId,
    searchKeyWord,
    modalStatus,
    setIsModalVisible,
    mode,
    groupModalModes.EDIT,
    devicesList,
    selectedGroup.id,
  ]);

  const onSelectChange = (record, selected, selectedRows) => {
    const isRecordCheckedOnDB = record.groupId;
    if (
      selected &&
      isRecordCheckedOnDB &&
      isRecordCheckedOnDB !== selectedGroup.id
    ) {
      setSelectedDeviceToConfirm(record);
      setIsConfirmationModalVisible(true);
      return;
    }

    if (isRecordCheckedOnDB && !selected) {
      setUnselectedDevices(prevState => [...prevState, record.key]);
    }
    if (isRecordCheckedOnDB && selected) {
      setUnselectedDevices(prevState =>
        prevState.filter(id => id !== record.key),
      );
    }
    if (!isRecordCheckedOnDB && selected) {
      setNewSelectedDevices(prevState => [...prevState, record.key]);
    }
    if (!isRecordCheckedOnDB && !selected) {
      setNewSelectedDevices(prevState =>
        prevState.filter(id => id !== record.key),
      );
    }

    setSelectedRowKeys(
      selectedRows.filter(row => !!row?.key).map(row => row.key),
    );
  };

  const modeToPropsMap = {
    [groupModalModes.ADD]: {
      title: intl.formatMessage(messages.addGroup),
      okButtonText: intl.formatMessage(messages.add),
      initialValues: {},
    },
    [groupModalModes.EDIT]: {
      title: intl.formatMessage(messages.editGroup),
      okButtonText: intl.formatMessage(messages.apply),
      initialValues: { name: selectedGroup.name },
    },
  };

  const columns = getGroupDevicesColumns(intl);

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectChange,
    type: 'checkbox',
    fixed: true,
    columnTitle: intl.formatMessage(messages.assign),
  };
  const confirmationMessage = (
    <div>
      <StyleDescription>
        {`${selectedDevicetoConfirm?.name} 
      ${intl.formatMessage(messages.confirmationChooseDeviceDescription)} 
      ${selectedDevicetoConfirm?.groupName}`}
      </StyleDescription>
      <StyleQuestion>
        {intl.formatMessage(messages.confirmationChooseDeviceQuestion)}
      </StyleQuestion>
    </div>
  );

  const onHandleSubmit = values => {
    setIsModalVisible(false);
    if (!newSelectedDevices.length && !unselectedDevices.length) {
      return;
    }
    onOk({
      ...values,
      newDevicesToBeHandle: {
        clientIds: newSelectedDevices,
        unassignedClientIds: unselectedDevices,
      },
    });
  };

  const onModalClose = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onSearchAllDevices = filteredValue => {
    if (filteredValue) {
      searchAllDevices(filteredValue);
    }
    setSearchFilter(filteredValue);
  };

  return (
    <>
      <Modal
        width={1000}
        title={modeToPropsMap[mode].title}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        okButtonText={modeToPropsMap[mode].okButtonText}
        onOk={form.submit}
        onClose={onModalClose}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onHandleSubmit}
          initialValues={modeToPropsMap[mode].initialValues}
          validateMessages={deviceFormValidateMessages}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: intl.formatMessage(messages.groupNameError),
              },
            ]}
            label={intl.formatMessage(messages.nameFieldLabel)}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <SelectTenantBox
            tenantSelectOptions={tenantSelectOptions}
            onChangeTenantId={id => onChangeTenantId(id)}
          />

          <Table
            locale={{ emptyText: intl.formatMessage(messages.emptyDevices) }}
            withSearch
            searchFunction={onSearchAllDevices}
            searchAligned="right"
            searchPlaceholder={intl.formatMessage(messages.searchPlaceholder)}
            columnTitle={intl.formatMessage(messages.assign)}
            loading={isLoading === DATA_FETCHING_STATUS.LOADING}
            data={tableData}
            columns={columns}
            rowSelection={rowSelection}
            scroll={{ x: 'max-content', y: '300px' }}
          />
        </Form>
      </Modal>
      <ConfirmationModal
        isModalVisible={isConfirmationModalVisible}
        setIsModalVisible={setIsConfirmationModalVisible}
        title={intl.formatMessage(messages.confirmationChooseDeviceTitle)}
        onOk={() => {
          setNewSelectedDevices(prevRows => [
            ...prevRows,
            selectedDevicetoConfirm.key,
          ]);
          setSelectedRowKeys(prevRows => [
            ...prevRows,
            selectedDevicetoConfirm.key,
          ]);
          setSelectedDeviceToConfirm(null);
        }}
        message={confirmationMessage}
      />
    </>
  );
};

const messages = defineMessages({
  addGroup: {
    defaultMessage: 'Add New Group',
  },
  add: {
    defaultMessage: 'Add',
  },
  editGroup: {
    defaultMessage: 'Edit Group',
  },
  assign: {
    defaultMessage: 'Assign',
  },
  apply: {
    defaultMessage: 'Apply',
  },
  deviceIdColumn: {
    defaultMessage: 'Device Id',
  },
  nameColumn: {
    defaultMessage: 'Name',
  },
  groupColumn: {
    defaultMessage: 'Group',
  },
  nameFieldLabel: {
    defaultMessage: 'Name',
  },
  manufacturerIdError: {
    defaultMessage: 'No spaces allowed',
  },
  searchPlaceholder: {
    defaultMessage: 'Device Id/Device Name',
  },
  groupNameError: {
    defaultMessage: 'Name is required!',
  },
  confirmationChooseDeviceTitle: {
    defaultMessage: 'Warning',
  },
  confirmationChooseDeviceDescription: {
    defaultMessage: 'device is already assigned to group',
  },
  confirmationChooseDeviceQuestion: {
    defaultMessage: 'Are you sure you want to continue?',
  },
  tenantNameColumn: {
    defaultMessage: 'Tenant Name',
  },
  emptyDevices: {
    defaultMessage:
      'There are no devices, please choose a Tenant or search by device',
  },
});

/* eslint-disable no-template-curly-in-string */
export const deviceFormValidateMessages = {
  required: '${label} is required!',
};
/* eslint-enable no-template-curly-in-string */

GroupModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  devicesList: PropTypes.array.isRequired,
  selectedGroup: PropTypes.object,
  onLoadDeviceList: PropTypes.func.isRequired,
  isLoading: PropTypes.string,
  groupModalModes: PropTypes.object.isRequired,
  tenantSelectOptions: PropTypes.array.isRequired,
  searchAllDevices: PropTypes.func,
};

GroupModal.defaultProps = {
  selectedGroup: {},
};

export default injectIntl(
  React.memo(GroupModal, (oldProps, newProps) => deepEqual(oldProps, newProps)),
);
