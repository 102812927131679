import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
// import 'chartjs-plugin-zoom';
import { Line, Chart } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import StreamingPlugin from 'chartjs-plugin-streaming';

import { graphDataTypesEnum } from '../modules/constants';

const ChartBox = styled.div`
  height: calc((100% - 80px) / 2);
  .chartjs-render-monitor {
    height: 100%;
  }
`;

const MonitorPage = React.memo(props => {
  Chart.register(StreamingPlugin);
  const {
    theme,
    dataType,
    measurementType,
    datasetLabel,
    minValue,
    maxValue,
    durationInMs,
    shouldStart,
    delayInMs,
    OnlineMonitor,
    stepSize,
    deviceId,
    shouldDisplayHighQualityOnly,
  } = props;

  const labelFontColor = theme.colors.graphAxisLabels;
  const dotsOnlyLabel = 'DOTS_ONLY';
  const data = {
    datasets: [
      {
        label: datasetLabel,
        data: [],
        fill: false,
        backgroundColor: theme.colors.graphTick,
        borderColor: theme.colors.graphLine,
        tension: 0.5,
      },
      {
        label: dotsOnlyLabel,
        data: [],
        fill: false,
        backgroundColor: theme.colors.graphTick,
        borderColor: theme.colors.graphLine,
        tension: 0.5,
        showLine: false,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        filter: chart => chart.dataset.label.localeCompare(dotsOnlyLabel),
      },
    },
    scales: {
      xAxes: {
        type: 'realtime',
        reverse: true,
        realtime: {
          pause: !shouldStart,
          duration: durationInMs,
          delay: delayInMs,
          refresh: 1000,
          onRefresh: chart => {
            if (shouldStart) {
              const mqttData = OnlineMonitor.getDataForDevice(
                dataType,
                deviceId,
              );
              const filterdMqttData = mqttData.filter(
                pointData =>
                  // TODO: Check if can remove this
                  // checkMeasurementIsValid(
                  //   Number(pointData.y),
                  //   measurementType,
                  // ) &&
                  !shouldDisplayHighQualityOnly ||
                  pointData.isQualityMeasurement,
              );
              chart.data.datasets.map(d => d.data.push(...filterdMqttData));
            }
          },
        },
        ticks: {
          color: labelFontColor,
        },
        gridLines: {
          display: false,
        },
      },
      yAxes: {
        type: 'linear',
        beginAtZero: true,
        min: minValue,
        max: maxValue,
        ticks: {
          color: labelFontColor,
          autoSkip: false,
          stepSize,
        },
        gridLines: {
          display: false,
        },
      },
    },
    interaction: {
      intersect: false,
    },
    maintainAspectRatio: false,
  };
  return (
    <ChartBox>
      <Line data={data} options={options} />
    </ChartBox>
  );
});
MonitorPage.propTypes = {
  theme: PropTypes.object.isRequired,
  dataType: PropTypes.oneOf(Object.values(graphDataTypesEnum)).isRequired,
  measurementType: PropTypes.string.isRequired,
  datasetLabel: PropTypes.string.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  durationInMs: PropTypes.number,
  delayInMs: PropTypes.number,
  shouldStart: PropTypes.bool,
  OnlineMonitor: PropTypes.object.isRequired,
  stepSize: PropTypes.number,
  deviceId: PropTypes.string.isRequired,
  shouldDisplayHighQualityOnly: PropTypes.bool,
};

MonitorPage.defaultProps = {
  durationInMs: 50000, // 20 seconds
  delayInMs: 1000, // 3 seconds
  shouldStart: false,
  stepSize: 20,
  shouldDisplayHighQualityOnly: false,
};

export default withTheme(MonitorPage);
